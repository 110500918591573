// @flow
import React from 'react';
import LinkAsButton from '../../LinkAsButton';
import styled, { css } from 'styled-components';
import { colors } from '../../AccountHeader/styles';
import FlightConfirmIcon from '../../../../assets/svg/inline/flight-questionaire-alert.inline.svg';

import {
  SURVEY_MPI_OPEN,
  SURVEY_MPI_OK,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_CONFIRMATION_REQUIRED,
  SURVEY_CLOSED,
} from '../../../config/constants';

type Props = {
  meta: {
    buttonText: string,
    headline: string,
    status: string,
    text: string,
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${colors.lightBlue4};
  box-sizing: border-box;
  padding: 28px;

  ${p =>
    p.active &&
    css`
      background-color: ${colors.lightBlue7};
    `};
`;

const Icon = styled.div`
  font-size: 36px;
  margin-right: 14px;

  &::before {
    position: relative;
    top: -3px;

    @media screen and (max-width: 644px) {
      top: -6px;
    }
  }
`;

const ConfirmIconWrapper = styled.div`
  margin-right: 14px;
`;

const Headline = styled.h2`
  margin: 0 0 5px;
`;

const ButtonWrapper = styled.div`
  padding-top: 20px;

  .button {
    @media screen and (max-width: 400px) {
      font-size: 12px;
      padding: 7px 8px;
    }
  }
`;

export default function Teaser(props: Props) {
  const { meta } = props;

  if (!meta.status || meta.status === SURVEY_CLOSED) {
    return null;
  }

  let teaserActive = false;
  if (
    meta.status === SURVEY_MPI_OPEN ||
    meta.status === SURVEY_MPI_NOT_OK ||
    meta.status === SURVEY_MPI_OK ||
    meta.status === SURVEY_MPI_CONFIRMATION_REQUIRED
  ) {
    teaserActive = true;
  }

  const icon =
    meta.status && meta.status === SURVEY_MPI_OK
      ? 'flight-questionaire-done'
      : meta.status !== SURVEY_MPI_CONFIRMATION_REQUIRED
        ? 'flight-questionaire'
        : 'icon-tile-flight-confirm';

  return (
    <div className="flight">
      <Wrapper active={teaserActive}>
        {icon === 'icon-tile-flight-confirm' ? (
          <ConfirmIconWrapper>
            <FlightConfirmIcon />
          </ConfirmIconWrapper>
        ) : (
          <Icon className={`icon ${icon}`} />
        )}

        <div>
          <Headline>{meta.headline}</Headline>
          <div dangerouslySetInnerHTML={{ __html: meta.text }} />
          {teaserActive ? (
            <ButtonWrapper>
              <LinkAsButton dark link="/buchungsdetails/vip-tarif/1/vip-gaeste">
                {meta.buttonText}
              </LinkAsButton>
            </ButtonWrapper>
          ) : null}
        </div>
      </Wrapper>
    </div>
  );
}
