// @flow
import { observable, action, computed } from 'mobx';
import type { ApiType } from '../api/config';

import Config from '../models/Config';

// 2019 October: currently not in use. Keep for future use.
export default class ConfigStore {
  @observable config = null;

  api: ApiType;

  constructor(api: ApiType) {
    this.api = api;
  }

  @computed
  get isLoading(): boolean {
    return this.config === null;
  }

  @action
  fetch() {
    this.api.get().then((response: Config) => {
      this.receiveResponse(response);
    });
  }

  @action
  receiveResponse(response: Config) {
    try {
      this.config = new Config(response);
    } catch (exc) {
      // ...
    }
  }
}
