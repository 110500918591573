// @flow
import { encode, decode } from './base64';
const storage = window.sessionStorage;

const setItem = (key: string, value: string) => {
  try {
    storage.setItem(encode(key), encode(value));
    return true;
  } catch (exc) {
    console.log(exc);
    return false;
  }
};

const getItem = (key: string): ?string => {
  try {
    const value = storage.getItem(encode(key));
    return value ? decode(value) : null;
  } catch (exc) {
    console.log(exc);
    return null;
  }
};

const removeItem = (key: string) => {
  try {
    storage.removeItem(encode(key));
  } catch (exc) {
    console.log(exc);
  }
};

const clear = () => {
  try {
    storage.clear();
  } catch (exc) {
    console.log(exc);
  }
};

export { setItem, getItem, removeItem, clear };
