//@flow
import React from 'react';

const LoadingIndicator = () => (
  <div className="loading-indicator">
    <span />
  </div>
);

export default LoadingIndicator;
