// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BlockListItem from './BlockListItem';

const BlockList = ({ children, className, inverted, title }) => (
  <div
    className={classNames({
      'block-list': true,
      inverted,
      [className]: !!className,
    })}
  >
    {title ? <h4 className="block-list__title">{title}</h4> : null}
    <ul>{children}</ul>
  </div>
);

BlockList.propTypes = {
  // List content should only contain List.Item components
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  inverted: PropTypes.bool,
};

BlockList.Item = BlockListItem;

export default BlockList;
