// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';
import Slider from 'react-slick';
import Badge from '../Badge';

import { itsUrlWithSize } from '../../utils/image';
// import { isTouchScreen } from '../../utils/touch';
import sliderSettings from '../../config/sliderSettings';

type Props = {
  data: [],
  badge?: String,
};

class HighlightTile extends Component<Props> {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  state = {
    activeSlide: 1,
    dragging: false,
  };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { data, badge } = this.props;

    const defaultConfig = {
      ...sliderSettings,
      dots: true,
    };

    const sliderConfig = {
      ...defaultConfig,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 1200,
      fade: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      arrows: false,
      beforeChange: (current, next) => {
        this.setState({ activeSlide: next + 1, dragging: true });
      },
      afterChange: () => this.setState({ dragging: false }),
      appendDots: () => (
        <div>
          <div className="highlight slick-arrow slick-prev" onClick={this.previous} />
          {this.state.activeSlide}/{data.length}
          <div className="highlight slick-arrow slick-next" onClick={this.next} />
        </div>
      ),
      responsive: [
        {
          breakpoint: 644,
          settings: {
            centerMode: true,
            fade: false,
            autoplay: false,
            dots: false,
            speed: 500,
            swipeToSlide: true,
            variableWidth: true,
            draggable: true,
          },
        },
        {
          breakpoint: 400,
          settings: {
            centerMode: true,
            fade: false,
            autoplay: false,
            dots: false,
            speed: 500,
            swipeToSlide: false,
            draggable: true,
          },
        },
      ],
    };

    return (
      <div className={`tile full highlight-wrapper${badge ? ' has-badge' : ''}`}>
        <div className="highlight-headline">
          <div className="text">Unsere Highlights</div>
          <div className="count">
            {this.state.activeSlide}/{data.length}
          </div>
        </div>
        <div
          className={classNames({
            'slider-container': true,
            'highlight-tile': true,
            'large-tile': false,
          })}
        >
          <Slider ref={c => (this.slider = c)} {...sliderConfig}>
            {data.map((item, i) => (
              <div key={i} className={`wrapper`}>
                <Link
                  to={`/highlights/${item.id}`}
                  onClick={e => {
                    if (this.slider.state && this.slider.state.breakpoint) {
                      this.state.dragging && e.preventDefault();
                    }
                  }}
                >
                  <img src={itsUrlWithSize(item.imageUri, 480, 270)} alt={item.headline} />
                  <div className="tile__clamp" dangerouslySetInnerHTML={{ __html: item.headline }} />
                </Link>
              </div>
            ))}
          </Slider>
        </div>
        {badge && <Badge>{badge}</Badge>}
      </div>
    );
  }
}

export default HighlightTile;
