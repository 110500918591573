// @flow
import React, { Component } from 'react';
import { autorun } from 'mobx';
import Button from '../Button';

import type SpaBookingRequest from '../../models/BookingRequest/SpaBookingRequest';
import type RestaurantBookingRequest from '../../models/BookingRequest/RestaurantBookingRequest';
import type VacancyStore from '../../stores/VacancyStore';
import type RestaurantOffer from '../../models/RestaurantOffer';
import type SpaOffer from '../../models/SpaOffer';
import type SpaTreatmentStatusList from '../../models/SpaBalconyCabin/SpaTreatmentStatusList';

type Props = {
  bookable: RestaurantOffer | SpaOffer,
  bookingRequest: SpaBookingRequest | RestaurantBookingRequest,
  vacancyStore?: VacancyStore,
  onCancel?: ?Function,
  onBooking?: ?Function,
  spaTreatmentStatusList: ?SpaTreatmentStatusList,
  cruiseIsRunning?: boolean,
};

// eslint-disable-next-line no-unused-vars
export default class VacancyBookingAssistant<T> extends Component<Props> {
  constructor(props: Props) {
    super(props);
    // This method will check if bookingRequest has selectedDay and will be called when ever
    // that day changes
    autorun(() => {
      const { bookingRequest, bookable, vacancyStore } = props;
      if (bookingRequest && bookingRequest.selectedDay) {
        const vacancy = bookable.vacancyOfDay(bookingRequest.selectedDay);
        if (vacancy) {
          vacancyStore
            .fetch(vacancy, this.vacancyType)
            .then(vacancies => bookingRequest.setVacancies(vacancies));
        }
      }
    });
  }

  get vacancyType(): string {
    throw new Error('implement me in child class');
  }

  renderBaseInfo() {
    throw new Error('implement me in child class');
  }

  renderDayChosenView() {
    throw new Error('implement me in child class');
  }

  renderNoVacanciesAvailable(message: string = 'Derzeit nicht reservierbar') {
    return (
      <div className="l-right">
        <Button dark big disabled>
          {message}
        </Button>
      </div>
    );
  }

  render() {
    const { bookable, bookingRequest } = this.props;

    if (
      !bookingRequest.selectedDay ||
      (!bookable.vacancyOfDay(bookingRequest.selectedDay) && bookable.inclusive)
    ) {
      return this.renderBaseInfo(true);
    }

    if (!bookable.vacancyOfDay(bookingRequest.selectedDay)) {
      return this.renderNoVacanciesAvailable(
        `${bookable.getBookableStatusForDay(bookingRequest.selectedDay)}`
      );
    }

    return this.renderDayChosenView();
  }
}
