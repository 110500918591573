// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';

import Badge from '../Badge';
import ListItemHeader from './ListItemHeader';
import ListItemText from './ListItemText';

const ListItem = ({ children, link, badge, disabled, onClick }) => (
  <div className="list__item">
    <Link
      to={link}
      className={classNames({
        list__item__link: true,
        'has-badge': !!badge,
        disabled,
      })}
      onClick={onClick}
    >
      {children}
    </Link>
    {badge ? <Badge unavailable={!!disabled}>{badge}</Badge> : null}
  </div>
);

ListItem.propTypes = {
  // Content of List.Item should only include component that start with 'List.'
  children: PropTypes.node.isRequired,
  // Clicking on a listitem will open this link
  link: PropTypes.string.isRequired,
  // Use this if you want to show a badge, omit this if you don't want a badge
  badge: PropTypes.string,
  onClick: PropTypes.func,
};

ListItem.Text = ListItemText;
ListItem.Header = ListItemHeader;

export default ListItem;
