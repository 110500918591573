// @flow
export const FALLBACK_ERROR_MESSAGE =
  'Ihre Reservierungsanfrage konnte leider nicht bearbeitet werden. Bitte versuchen Sie es später erneut.';

export const TRIGGERED = 'TRIGGERED';
export const IGNORED = 'IGNORED';
export const FAILED = 'FAILED';

export const ATTENDS = 'ATTENDS';
export const NOT_ATTENDS = 'NOT_ATTENDS';
export const INCOMPLETE = 'INCOMPLETE';

export const PRELIMINARY = 'PRELIMINARY';

export const BOOKABLE = 'BOOKABLE';
export const NOT_MORE_BOOKABLE = 'NOT_MORE_BOOKABLE';
export const NOT_YET_BOOKABLE = 'NOT_YET_BOOKABLE';

export const LEGAL_NOTE_IDS = {
  inclusiveExcursionNotRefundable: 'inclusiveExcursionNotRefundable',
  inclusiveExcursionIncludesBabies: 'inclusiveExcursionIncludesBabies',
};
