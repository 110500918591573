// @flow

import { getItem, setItem, removeItem, clear } from './sessionStorage';

/**
 * TUICUNIT-911
 * */
const pinKey = (mpi: number): string => {
  return `pin-${mpi}`;
};

const urlKey = (mpi: number): string => {
  return `url-${mpi}`;
};

const pinStorage = {
  /**
   * pin
   */
  getPin: (mpi: number): ?string => {
    return getItem(pinKey(mpi));
  },
  setPin: (mpi: number, pin: string): boolean => {
    if (mpi && pin) {
      return setItem(pinKey(mpi), pin);
    }
    return false;
  },
  removePin: (mpi: number) => {
    removeItem(pinKey(mpi));
  },

  /**
   * navigation target url
   */
  getUrl: (mpi: number): ?string => {
    return getItem(urlKey(mpi));
  },
  setUrl: (mpi: number, url: ?string): boolean => {
    if (mpi && url) {
      return setItem(urlKey(mpi), url);
    }
    return false;
  },
  removeUrl: (mpi: number) => {
    removeItem(urlKey(mpi));
  },

  /**
   * API error
   */
  setApiPinError: (data: Object) => {
    setItem('api-pin-error', JSON.stringify(data));
  },
  getApiPinError: (): ?Object => {
    const error = getItem('api-pin-error');
    return error ? JSON.parse(error) : null;
  },
  removeApiPinError: () => {
    removeItem('api-pin-error');
  },

  /**
   * active process
   */
  setActiveProcess: (data: Object) => {
    setItem('active-process', JSON.stringify(data));
  },
  getActiveProcess: (): ?Object => {
    const process = getItem('active-process');
    return process ? JSON.parse(process) : null;
  },
  removeActiveProcess: () => {
    removeItem('active-process');
  },

  clear: () => {
    clear();
  },
};

export default pinStorage;
