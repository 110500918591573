// @flow

import fetchForUser from '../utils/fetchForUser';

import type User from '../models/User';
import type Brochure from '../models/Brochure';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (tripCode: string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/brochure.json`
    : `/api/excursion/brochure/${tripCode}`;

export default {
  get: (user: User, tripCode: string) => {
    return fetchForUser(user, getUrl(tripCode));
  },
};

export type ApiType = {
  get: (User, string) => Promise<Brochure>,
};
