// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';

export default {
  // The url, and the field that has to be send in body, will be delivered via
  // a feed, so they have to be added here as well
  send: (user: User, email: string) =>
    fetchForUser(user, '/api/land/email', {
      method: 'post',
      body: JSON.stringify({
        email,
      }),
    }),
};

// export const mockApi = {
//   send: () =>
//     new Promise(function(resolve) {
//       resolve();
//     }),
// };
