//@flow
import React, { Component } from 'react';
import browserHistory from 'react-router/lib/browserHistory';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import LinkAsButton from '../LinkAsButton';
import Headline from '../Headline';
import Picture from '../Picture';
//import ServiceInfo from './ServiceInfo';

import track from '../../tracking';
import { loadImg } from '../../utils/image';
import Button from '../Button';

type Props = {};

/**
 * @see js/components/pages/decorators/preload.js:145
 * */

@inject('authStore')
@observer
export default class PageNotFound extends Component<Props> {
  componentDidMount() {
    track.pageNotFound(document.referrer);
    track.pageView(window.location.href, ['Seite nicht gefunden'], 'content');
  }

  // TUICUNIT-3782
  @autobind
  onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  render() {
    return (
      <div className="page not-found">
        <div className="l-row">
          <div className="l-col double">
            <Headline title="Kein Land in Sicht" subtitle="Leider konnten wir die gewünschte Seite nicht finden." />
            <Picture src={loadImg('404/error-page_meer_06.png')} />

            <div className="l-right">
              <p>
                <Button dark onClick={browserHistory.goBack}>
                  Vorherige Seite
                </Button>
              </p>
              <p>
                {this.props.authStore.user && this.props.authStore.user.backLink ? (
                  <Button dark onClick={this.onLogoutClick}>
                    Zur Startseite
                  </Button>
                ) : (
                  <LinkAsButton dark link="/">
                    Zur Startseite
                  </LinkAsButton>
                )}
              </p>
            </div>
          </div>
          <div className="l-col">
            <h2>Unser Serviceteam</h2>
            <Picture src={loadImg('c/service-272x115.jpg')} />
            <p>
              Bei Fragen zu Ihrer bereits gebuchten Kreuzfahrt wenden Sie sich bitte vertrauensvoll an Ihr Reisebüro.
            </p>
            <div className="divider" />
            <p>Unser Serviceteam steht Ihnen bei Fragen zu unserer Website gerne zur Verfügung.</p>
            <div className="l-right">
              <LinkAsButton newPage link="https://www.meinschiff.com/kontakt/">
                Kontakt
              </LinkAsButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
