// @flow

// import { Component } from 'react';

// TUICROLLOUTS-627 dismantling for 1.10.0.
//    Restore for 1.11.0 if 1.10.0 was released

import React, { Component } from 'react';
import ShipIcon from '../../assets/svg/inline/benefits-ship-white.inline.svg';
import ExcursionsIcon from '../../assets/svg/inline/excursions-white.inline.svg';
import DeviceIcon from '../../assets/svg/inline/benefits-device-white.inline.svg';
import config, { getServerEnv } from '../config';

const benefits = [
  {
    icon: ShipIcon,
    text: 'Alle Reisen im Überblick',
  },
  {
    icon: ExcursionsIcon,
    text: 'Landausflüge, SPA & Sportangebote, etc. bequem für alle Reisen reservieren',
  },
  /*{
    icon: PersonIcon,
    text: 'Ihr persönlicher Zugang – viele weitere Funktionen geplant',
  },*/
  {
    icon: DeviceIcon,
    text: 'Verfügbar für PC, Smartphone und Tablet',
  },
];

type Props = {};

export default class LoginTeaser extends Component<Props> {
  render() {
    // TUICROLLOUTS-627 dismantling for 1.10.0.
    //    Restore for 1.11.0 if 1.10.0 was released
    // return null;
    const env = getServerEnv();

    const registrationUrl =
      env === 'production'
        ? 'https://konto.meinschiff.com/auth/sign-up?app=travel&intended=%2Fmeine-reisen'
        : 'https://konto.aws.test.meinschiff.com/auth/sign-up?app=travel&intended=%2Fmeine-reisen';

    const loginUrl =
      env === 'production'
        ? 'https://konto.meinschiff.com/auth/sign-in?app=travel&intended=%2Fmeine-reisen'
        : 'https://konto.aws.test.meinschiff.com/auth/sign-in?app=travel&intended=%2Fmeine-reisen';

    return (
      <div className="login-teaser">
        <div className="new-label">NEU</div>
        <div className="overhead">
          <span className="no-wrap">
            Das <em>Mein Schiff ® </em>- Konto
          </span>
        </div>
        <h1>
          Ein Zugang zu all <span className="no-wrap">Ihren Reisen</span>
        </h1>
        <div className="table">
          <div className="table-row">
            <div className="table-cell left">
              <h3>
                <strong>Ihre Vorteile</strong>
              </h3>
              {benefits.map((b, i) => (
                <div key={i} className="benefit-container">
                  <div className="benefit">
                    <div className="icon-container">
                      <b.icon />
                    </div>
                    <span className="text">{b.text}</span>
                  </div>
                </div>
              ))}
            </div>

            <div className="table-cell right">
              <h3>
                Registrieren Sie sich für Ihr{' '}
                <span className="no-wrap">
                  <strong>
                    <em>Mein Schiff ® </em>- Konto
                  </strong>
                </span>{' '}
                oder melden Sie sich an
              </h3>
              <div className="buttons">
                {config && (
                  <a href={registrationUrl} className="button">
                    Registrieren
                  </a>
                )}
                {config && (
                  <a href={loginUrl} className="button">
                    Anmelden
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
