// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import { userShape } from '../../utils/PropTypes';

import BookedPackage from '../../models/BookedPackage';

import BaseList from './BaseList';

const ParkingBookingList = ({ header, bookings, user, onCancelBooking }) => {
  return (
    <BaseList
      header={header}
      bookings={bookings}
      user={user}
      onCancelBooking={onCancelBooking}
      title={booking =>
        `${booking.displayName}${
          booking.prelimenary ? ' (in Bearbeitung)' : ''
        }`
      }
      info={() => user.displayName}
    />
  );
};

ParkingBookingList.propTypes = {
  header: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.instanceOf(BookedPackage)).isRequired,
  onCancelBooking: PropTypes.func.isRequired,
  user: userShape,
};

export default ParkingBookingList;
