// TODO: add flow types

export default (onPause, onResume) => {
  function handleVisiblityChange() {
    if (document.hidden) {
      onPause();
    } else {
      onResume();
    }
  }

  document.addEventListener('visibilitychange', handleVisiblityChange, false);
  return {
    stop: function() {
      document.removeEventListener('visibilitychange', handleVisiblityChange);
    },
  };
};
