//@flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import LoadingIndicator from '../LoadingIndicator';
import InfoBox from '../InfoBox';
import Button from '../Button';

type SurveyDetailProps = {
  masterStore: MasterStore,
  params: { surveyId: string, surveyTitle: string },
};

@inject('masterStore')
@observer
export default class SurveyDetail extends Component<SurveyDetailProps> {
  static breadcrumb = ({ params: { surveyTitle } }: SurveyDetailProps) => <BreadcrumbLink text={surveyTitle} />;

  scripts = {
    webcomponents: '/survey-api/js/webcomponents-bundle.js',
    customElements: '/survey-api/js/custom-elements-es5-adapter.js',
    survey: '/survey-api/tuic-survey.js',
  };

  state = {
    // attempt is used to re-render the component
    attempt: 0,
    // state of the scripts, can be 'idle', 'loading', 'loaded', 'error'
    state_webcomponents: 'idle',
    state_customElements: 'idle',
    state_survey: 'idle',
  };

  trackSentry = (scriptName, state, optionalData = undefined) => {
    if (window.Raven) {
      window.Raven.captureMessage(`SurveyDetail: Script ${scriptName} is in state ${state}`, optionalData);
    }
  };

  /**
   * Used to load a script by its name
   */
  loadScript = (scriptName) => {
    let timerId;
    this.setState({ [`state_${scriptName}`]: 'loading' });

    const script = document.createElement('script');
    script.src = this.scripts[scriptName];
    script.async = true;

    script.onload = () => {
      clearTimeout(timerId);
      this.setState({ [`state_${scriptName}`]: 'loaded' });
    };
    script.onerror = () => {
      clearTimeout(timerId);
      this.trackSentry(scriptName, 'error');
      this.setState({ [`state_${scriptName}`]: 'error' });
    };

    timerId = setTimeout(() => {
      this.removeScript(scriptName);
      this.setState({ [`state_${scriptName}`]: 'idle' });
    }, 5000);

    document.head.appendChild(script);
  };

  /**
   * Used to remove a script from the DOM by its name
   */
  removeScript = (scriptName) => {
    const script = document.querySelector(`script[src="${this.scripts[scriptName]}"]`);
    if (script) {
      script.remove();
    }
  };

  componentDidMount() {
    this.loadScripts();
  }

  oneOfScriptsHasState = (state) => {
    return Object.keys(this.state)
      .filter((name) => name.startsWith('state_'))
      .some((key) => this.state[key] === state);
  };

  allOfScriptsHasState = (state) => {
    return Object.keys(this.state)
      .filter((name) => name.startsWith('state_'))
      .every((key) => this.state[key] === state);
  };

  loadScripts = () => {
    this.setState({ attempt: this.state.attempt + 1 });
    const stateToRefresh = ['idle', 'error'];

    if (stateToRefresh.includes(this.state.state_webcomponents)) {
      this.loadScript('webcomponents');
    }

    if (stateToRefresh.includes(this.state.state_customElements)) {
      this.loadScript('customElements');
    }

    if (stateToRefresh.includes(this.state.state_survey)) {
      this.loadScript('survey');
    }
  };

  render() {
    let content = <LoadingIndicator />;

    if (this.oneOfScriptsHasState('error')) {
      content = (
        <div>
          <div style={{ marginLeft: '12px', marginRight: '12px' }}>
            <InfoBox>
              Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie den Support.
            </InfoBox>
          </div>
          <div className="">
            <Button onClick={() => this.loadScripts()}>Erneut versuchen</Button>
          </div>
        </div>
      );
    }

    if (this.allOfScriptsHasState('loaded')) {
      content = (
        <tuic-survey
          api-base-url="/survey-api/"
          close-url={this.props.masterStore.isInAppView ? undefined : '/'}
          locale="de-DE"
          survey-id={this.props.params.surveyId}
          user-age={this.props.masterStore.user.age}
        />
      );
    }

    return <div className="survey-detail-page">{content}</div>;
  }
}
