// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

const ListItemHeader = ({ children }) => (
  <div className="list__item__headline">{children}</div>
);

ListItemHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ListItemHeader;
