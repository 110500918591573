// TODO: add flow types

export const type = {
  any(value) {
    return value;
  },

  array(value) {
    if (value === null || value === undefined) return [];
    if (!Array.isArray(value)) throw new Error(`${value} is not an array`);
    return value;
  },

  arrayOf(reviver) {
    return value => type.array(value).map(reviver);
  },

  instance(Type) {
    return value => new Type(value);
  },
};

export function required(reviver) {
  return function(value) {
    if (value === null) {
      throw new Error('value must not be null');
    }
    if (typeof value === 'undefined') {
      throw new Error('value must not be undefined');
    }
    return reviver(value);
  };
}

export function optional(reviver) {
  return function(value) {
    if (value === null || typeof value === 'undefined') return value;
    return reviver(value);
  };
}

export function defaulted(reviver, defaultValue) {
  return function(value) {
    if (value === null || typeof value === 'undefined') {
      return defaultValue;
    }
    return reviver(value);
  };
}

export function assign(obj, data, revivers) {
  Object.keys(revivers).forEach(key => {
    const reviver = revivers[key];
    if (typeof reviver !== 'function') {
      throw new Error(`${key}: ${typeof reviver} is not a function`);
    }
    try {
      obj[key] = reviver(data[key]);
    } catch (err) {
      throw new Error(`${key}: ${err.message}`);
    }
  });
}
