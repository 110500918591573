// @flow

const ROOT = '/';

const paths = {
  LANDINGPAGE: ROOT,
  DOE: '/programm',
  DBE: '/programm',
  VENUE: '/tischreservierung',
  SHORE_EXCURSION: '/landausfluege',
  SPA: '/schoenheit-und-gesundheit',
};

const categoryMap = {
  reisemedien: 'reisemedien',
  internet: 'internet-an-bord',
  package: 'pakete',
  newspaper: 'zeitungen',
  'package-newspaper': 'pakete-und-zeitungen',
  'beauty-and-health': 'schoenheit-und-gesundheit',
  parkservice: 'parkservice',
};

const serviceUrlGetters = {
  DOE: (data: Object): string => {
    const { id, type } = data;
    return `${paths[type]}/${id || '?s=1'}`;
  },

  DBE: (data: Object): string => {
    const { id, type } = data;
    return `${paths[type]}/${id || '?s=1'}`;
  },

  PACKAGE: (data: Object): string => {
    const { categories, id } = data;

    if (!categories) return ROOT;

    let pkgId = id;
    let path = '';
    categories.forEach(category => {
      if (category === 'newspaper') {
        pkgId = null;
      }
      path = `${path}/${categoryMap[category] || category}`;
    });

    return `${path}/${pkgId || ''}`;
  },

  SPA: (data: Object): string => {
    const { categories, id, type } = data;

    if (!categories) return paths[type];
    categories[0] = categoryMap[categories[0]] || categories[0];

    let path = '';
    categories.forEach(category => {
      path = `${path}/${category}`;
    });

    return `${path}/${id || ''}`;
  },

  VENUE: (data: Object): string => {
    const { id, type } = data;
    return `${paths[type]}/${id || ''}`;
  },

  SHORE_EXCURSION: (data: Object): string => {
    const { categories, id, type } = data;
    if (!categories || !categories[0]) return paths[type];

    return `${paths[type]}/${categories[0]}/${id || ''}`;
  },

  LANDINGPAGE: (data: Object): string => {
    const { type } = data;
    return paths[type];
  },
};

export const serviceUrlMapping = (data: Object | null): string => {
  if (!data || !data.type) return ROOT;
  const getter = serviceUrlGetters[data.type];
  return getter ? getter(data) : ROOT;
};

/**
 * TUICMA-55
 *
 * PKG
 * /redirect/ancillaryDetails?AncillaryCode=PKG-10000033&AncillaryTypeCode=PKG&AncillaryStartDateTime=20181005121500
 * /redirect/ancillaryDetails?AncillaryCode=PKG-10000005&AncillaryTypeCode=PKG&AncillaryStartDateTime=20181005121500
 * /redirect/ancillaryDetails?AncillaryCode=PKG-10000179&AncillaryTypeCode=PKG&AncillaryStartDateTime=20181005121500
 *
 * SPA:
 * Bsp.: /redirect/ancillaryDetails?AncillaryCode=SPA-10003907
 * /schoenheit-und-gesundheit/spa/Kosmetik/10003907
 * /schoenheit-und-gesundheit/spa/Körperanwendungen/10005657
 * /schoenheit-und-gesundheit/spa/Massagen/10003892
 * /schoenheit-und-gesundheit/spa/Zu%20zweit%20entspannen/10003938
 * /schoenheit-und-gesundheit/spa/Sauna/10003941
 * /schoenheit-und-gesundheit/spa/Anwendung%20für%20Teens/10003961
 * /schoenheit-und-gesundheit/spa/Männer/10003909
 * /schoenheit-und-gesundheit/spa/Entspannungslogen/10007111
 *
 *
 * ACHTUNG die Entspannungslogen sind noch zu klären:
 *
 * /schoenheit-und-gesundheit/Entspannungslogen/10003943 geht auf
 * /schoenheit-und-gesundheit/spa/Entspannungslogen/10003943
 *
 * Frisör:
 * Bsp.: /redirect/ancillaryDetails?AncillaryCode=SPA-10004111
 *
 * /schoenheit-und-gesundheit/hairdresser/10004111
 * /schoenheit-und-gesundheit/hairdresser/10004095
 * /schoenheit-und-gesundheit/hairdresser/10004114
 *
 * Fitness:
 * Bsp.: /redirect/ancillaryDetails?AncillaryCode=SPA-10003985
 *
 * /fitness/Gesundheitschecks/10003985
 * /fitness/Gesundheitschecks/10003981
 * /fitness/Personal%20Training/10003996
 *
 * SHX:
 * Bsp.:  /redirect/ancillaryDetails?AncillaryCode=SHX-10170733&AncillaryTypeCode=SHX&AncillaryStartDateTime=20181005121500
 *        /redirect/ancillaryDetails?AncillaryCode=SHX-10179502&AncillaryTypeCode=SHX&AncillaryStartDateTime=20181005121500
 *
 * /landausfluege/5/10170733
 *
 * DBE:
 * Bsp.: /redirect/ancillaryDetails?AncillaryCode=DBE-10202426
 * /programm/10202426
 *
 */
