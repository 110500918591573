const urlParams = new URLSearchParams(document.location.search);

const inAppConsentQueryParam = urlParams.get('inAppConsent');

/**
 * @description This module exports the value of the inAppConsent query parameter.
 * @returns {string} 'on' if the inAppConsent query parameter is 'on', 'off' if it is 'off', and null otherwise.
 */
const inAppConsent = inAppConsentQueryParam === 'no' ? 'no' : inAppConsentQueryParam === 'yes' ? 'yes' : null;

module.exports = inAppConsent;
