//@flow
/**
 * Adds width and height attributes to its images urls
 */
const itsUrlWithSize = (src: ?string, width: number, height: number): ?string =>
  src ? `${src}&width=${width}&height=${height}` : null;

//TODO flow: module.ignore_non_literal_requires=true (https://github.com/facebook/flow/issues/269) - drawbacks?
const loadImg = (img: string): string => require(`../../assets/img/${img}`);

export { itsUrlWithSize, loadImg };
