// @flow
import * as React from 'react';

import Picture from './Picture';

import { itsUrlWithSize } from '../utils/image';

type ITSDefinition = {
  src: string,
  width?: number,
  height?: number,
  smallWidth?: number,
  smallHeight?: number,
  squareSize?: number,
};

const ITSPicture = ({
  src,
  width = 544,
  height = 324,
  smallWidth = 272,
  smallHeight = 153,
  squareSize = 236,
  ...props
}: ITSDefinition) => (
  <Picture
    src={itsUrlWithSize(src, width, height) || ''}
    smallSrc={itsUrlWithSize(src, smallWidth, smallHeight) || ''}
    squareSrc={itsUrlWithSize(src, squareSize, squareSize)}
    {...props}
  />
);

export default ITSPicture;
