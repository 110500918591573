// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import ItineraryListItem from './ItineraryListItem';

@inject('masterStore')
@observer
export default class DayChooser extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    masterStore: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.masterStore.reloadItinerary();
  }

  render() {
    const { model, title, onSelect } = this.props;
    const { masterData, itinerary } = this.props.masterStore;
    const itineraryList = itinerary.list;
    const bookings = masterData.getAllBookings();

    return (
      <div>
        <h2>{title}</h2>

        <div className="l-row">
          <div className="l-col double">
            <div className="l-mod l-block">
              {itineraryList.map((day, index) => {
                const status = model.getBookableStatusForDay(day);
                // const disabled = !!status;
                return (
                  <ItineraryListItem
                    showEmpty
                    services={day.getBookingsForItinerary(bookings[day.date])}
                    // disabled={disabled}
                    badge={status ? status : null}
                    day={day}
                    data={{
                      date: day.displayItineraryDate,
                      title: day.port.name,
                      imageSrc: day.image,
                      imageAlt: day.hint,
                    }}
                    onClick={() => onSelect(day, index)}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
