const getSlideDirection = (oldIndex, newIndex) => {
  let direction = oldIndex < newIndex ? 1 : -1;
  return Math.abs(oldIndex - newIndex) > 1 ? direction * -1 : direction;
};

const getSlideDirectionString = (oldIndex, newIndex) => {
  return getSlideDirection(oldIndex, newIndex) > 0 ? 'rechts' : 'links';
};

export { getSlideDirection, getSlideDirectionString };
