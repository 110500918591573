// @flow
import React from 'react';

import { itsUrlWithSize } from '../utils/image';

type Props = {
  className?: string,
  src: string,
  alt?: string,
};

const HeroImage = ({ src, alt, className }: Props) => (
  <div className={`hero-image ${className || ''}`}>
    <div className="hero-image__placeholder">
      <img
        className="hero-image__image"
        src={itsUrlWithSize(src, 912, 465)}
        alt={alt}
      />
    </div>
  </div>
);

export default HeroImage;
