// @flow
import React from 'react';

type Props = {
  noIcon?: boolean,
  className?: string,
  displayPhoneNumber?: ?string,
  phoneNumber: string | number,
};

const PhoneBlock = ({
  noIcon,
  className,
  displayPhoneNumber,
  phoneNumber,
}: Props) => (
  <div className={`phone-mail-block ${className || ''}`}>
    <a href={`tel:${phoneNumber}`}>
      {noIcon ? null : <span className="icon call" />}
      <span className="text">{displayPhoneNumber || phoneNumber}</span>
    </a>
  </div>
);

export default PhoneBlock;
