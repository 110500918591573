
// Generates the legacy tracking parameter 'page_type'
// @todo: Clearify if this is actually still required with GA4!
export function generateLegacyPageType(path: string, title: string):string  {
  // Home page - logged out
  // Note: The home page is usually not accessible while logged out
  if (path === '' && title === '') {
    return 'home_logged_out';
  }

  // Home page - logged in
  if (path === '/') {
    return 'home_logged_in';
  }

  // Manifest pages
  if (path.indexOf('/manifest') === 0) {
    return 'generic_pageview';
  }

  // Checkin pages
  if (path.indexOf('/checkin') === 0) {
    return 'generic_pageview';
  }

  // Generic content pages
  if (
    path.indexOf('/reiseplan') === 0 ||
    path.indexOf('/buchungsdetails') === 0 ||
    path.indexOf('/weitere-informationen') === 0 ||
    path.indexOf('/inklusiv-landausfluege') === 0 ||
    path.indexOf('/highlights') === 0
  ) {
    return 'generic_pageview';
  }

  return 'overview_pages';
}
