// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import { bookingRequestShape } from '../../utils/PropTypes';

import ItineraryDay from '../../models/ItineraryDay';

import Headline from '../Headline';
import LinkAsButton from '../LinkAsButton';
import track from '../../tracking';
import Button from '../Button';

@inject('authStore', 'breadcrumbRouterStore')
@observer
export default class BookingConfirmationSuccess extends Component {
  static propTypes = {
    day: PropTypes.instanceOf(ItineraryDay),
    bookingRequest: bookingRequestShape.isRequired,
  };

  get title() {
    return this.props.bookingRequest.getText('success', 'title');
  }

  get text() {
    return this.props.bookingRequest.getText('success', 'text');
  }

  // TUICUNIT-3782
  @autobind
  onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  componentDidMount() {
    // Page View Tracking
    const titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles.length) {
      track.pageView(window.location.pathname, [...titles, 'bestaetigung'], 'checkout ');
    } else {
      const { day, bookingRequest } = this.props;
      if (day) {
        track.pageView(
          window.location.pathname,
          ['Programm', day?.port?.name, bookingRequest?.bookable?.title, 'bestaetigung'],
          'checkout '
        );
      }
    }
  }

  render() {
    const { day, bookingRequest } = this.props;
    const { selectedDay, bookingToCancel } = bookingRequest;
    let { bookable } = bookingRequest || {};
    const subtitleDay = day || (bookingToCancel && bookingToCancel.day) || selectedDay;

    let title = bookable.title || '';
    // TUICUNIT-560 temporary storno hack
    if (bookingRequest.isCancellation && bookingRequest) {
      bookable = bookingToCancel || bookable;
      title = bookable.displayName || '';
    }

    return (
      <div className="booking-confirmation">
        <Headline title={this.title} subtitle={subtitleDay ? subtitleDay.subtitleLine : null} />

        <h2 dangerouslySetInnerHTML={{ __html: title }} />
        <p dangerouslySetInnerHTML={{ __html: this.text }} />

        <div className="booking-confirmation__buttons">
          <p>
            {this.props.authStore.user && this.props.authStore.user.backLink ? (
              <Button dark onClick={this.onLogoutClick}>
                Zur Startseite
              </Button>
            ) : (
              <LinkAsButton link="/" dark>
                Zur Startseite
              </LinkAsButton>
            )}
          </p>
        </div>
      </div>
    );
  }
}
