// @flow
import SpaTreatmentStatus from './SpaTreatmentStatus';

export default class SpaTreatmentStatusList {
  type: string;
  list: SpaTreatmentStatus[];

  constructor(data: SpaTreatmentStatusList) {
    this.type = data.type || '';
    this.list =
      data &&
      data.list &&
      data.list.map(status => new SpaTreatmentStatus(status));
  }
}
