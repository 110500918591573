// @flow

import React, { Component } from 'react';

import LoadingIndicator from '../LoadingIndicator';
import CatalogTile from '../tiles/CatalogTile';

import type DailyEvent from '../../models/DailyEvent';
import type Booking from '../../models/Booking';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';
import { inject } from 'mobx-react';

type Props = {
  urlBase: string,
  loading?: boolean,
  events: ?(DailyEvent[]),
  bookings: Booking[],
};

@inject('breadcrumbRouterStore')
export default class EventList extends Component<Props> {
  bookingMap = {};
  triggered = false;

  constructor(props, context) {
    super(props, context);
    Array.isArray(props.bookings) &&
      props.bookings.forEach((booking) => (this.bookingMap[parseInt(booking.typeId, 10)] = booking));
  }

  render() {
    const { loading, events, urlBase } = this.props;

    if (loading) return <LoadingIndicator />;
    const hash = window.location.hash || '';

    if (this.triggered === false) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = events.map((item: DailyEvent) => ({
        code: item.id,
        name: item.name,
        discount: '0.00',
        startDate: tealiumDateFromString(item.startDate),
        value: tealiumValue(String(item.priceAdult ?? 0)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
        category: item.category,
        categoryId: item.categoryId,
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="l-tiles">
        {events && events.length ? (
          events.map((event, index) => (
            <div
              key={`${index}-${event.eventId}`}
              onClick={() =>
                tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {
                  category: event.category,
                  categoryId: event.categoryId,
                })
              }
            >
              <CatalogTile
                category={event.category}
                highlight={!!event.highlight}
                booking={this.bookingMap[event.eventId]}
                link={`${urlBase}/${event.eventId}${hash}`}
                imageSrc={event.imageSrc}
                title={event.title}
                unavailableBadge={!event.isBookable && event.badgeText ? event.badgeText : ''}
                price={event.retailPrice}
                zeroPriceMeansInclusive
                footer={
                  <div className="date-discription">
                    {event.displayTime} <span className="desktop">| {event.displayDuration}</span> | {event.location}
                  </div>
                }
              />
            </div>
          ))
        ) : (
          <p>Für Ihre Auswahl liegt aktuell kein Programm vor.</p>
        )}
      </div>
    );
  }
}
