// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import LabeledInputBlockColumn from './Column';

const LabeledInputBlock = ({ icon, title, description, children }) => (
  <div className="l-row">
    <div className="l-col left l-mod-sub">
      <div className="labeled-input-block__head">
        <i className={`labeled-input-block__icon icon ${icon}`} />
        <span className="labeled-input-block__label">{title}</span>
      </div>
      {description ? (
        <p dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </div>
    {children}
  </div>
);

LabeledInputBlock.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
};

LabeledInputBlock.Column = LabeledInputBlockColumn;

export default LabeledInputBlock;
