// @flow

// TUICUNIT-911
import {
  MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN,
  MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_DATA_PRELIMINARY,
  MASTERDATA_STATUS_CHECKIN_MANIFEST_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_NOT_SCHENGEN_RESIDENT,
  MASTERDATA_STATUS_CHECKIN_PAYMENT_NOT_COMPLETED,
  MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION,
} from '../../../../config/constants';

import type BoardingPass from '../../../../models/BoardingPass';
import type PartyMember from '../../../../models/PartyMember';
import pinStorage from '../../../../utils/pinStorage';
import { openPdf } from '../../../../utils/navigation';
import isInAppView from '../../../../utils/isInAppView';

const getCheckinStatusText = (reason: ?string): string => {
  switch (reason) {
    case null:
      return 'Online Check-in möglich.';
    case MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN:
      return 'Erfolgreich eingecheckt.';
    case MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY:
      return 'Der Schiffspass ist in Bearbeitung und steht in wenigen Minuten hier zur Verfügung.';
    case MASTERDATA_STATUS_CHECKIN_MANIFEST_AND_PAYMENT_NOT_COMPLETED:
      return 'Für den Online Check-in füllen Sie bitte das Schiffsmanifest vollständig aus und hinterlegen ein Zahlungsmittel.';
    case MASTERDATA_STATUS_CHECKIN_MANIFEST_NOT_COMPLETED:
      return 'Für den Online Check-in füllen Sie bitte das Schiffsmanifest vollständig aus.';
    case MASTERDATA_STATUS_CHECKIN_PAYMENT_NOT_COMPLETED:
      return 'Für den Online Check-in hinterlegen Sie bitte ein Zahlungsmittel.';
    case MASTERDATA_STATUS_CHECKIN_NOT_SCHENGEN_RESIDENT:
      return 'Der Online Check-in ist nur für Bürger eines Schengen-Landes möglich. Bitte checken Sie am Anreisetag direkt am Terminal ein.';
    case MASTERDATA_STATUS_CHECKIN_MANIFEST_DATA_PRELIMINARY:
      return 'Der Online Check-in ist zur Zeit nicht möglich, da sich Ihre Schiffsmanifestdaten in Bearbeitung befinden.';
    case MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION:
      return '';
    default:
      return 'Online Check-in zur Zeit nicht möglich.';
  }
};

const isCheckedIn = (partyMember: ?PartyMember): boolean => {
  return (
    !!partyMember &&
    (partyMember.checkinCompleted || partyMember.checkinStatusReason === MASTERDATA_STATUS_CHECKIN_DATA_PRELIMINARY)
  );
};

const isDisabled = (partyMember: PartyMember): boolean => {
  const preliminary = partyMember.checkinPreliminary;
  return preliminary || !partyMember.pinAvailable;
};

const userMustEnterPin = (partyMember: PartyMember): boolean => {
  return partyMember.pinAvailable && !pinStorage.getPin(partyMember.mpi);
};

const userEnteredWrongPin = (partyMember: PartyMember, boardingPass: ?BoardingPass): boolean => {
  return !!(partyMember.pinAvailable && pinStorage.getPin(partyMember.mpi) && !boardingPass);
};

const userJustEnteredPin = (partyMember: PartyMember): boolean => {
  const process = pinStorage.getActiveProcess();
  return !!process && process.mpi === partyMember.mpi;
};

// open-link process
const openBoardingPassLink = async (boardingPass: BoardingPass, target: ?string, winName: ?string) => {
  let link;
  let print = false;
  switch (target) {
    case 'pdfLink': {
      link = boardingPass.pdfLink;
      break;
    }
    case 'pdfLinkPrint': {
      link = boardingPass.pdfLinkPrint;
      print = true;
      break;
    }
    case 'walletLink': {
      link = boardingPass.walletLink;
      break;
    }
    case 'walletLinkGoogle': {
      const urlDocumentResponse = await fetch(boardingPass.googleWalletLink);
      const urlDocumentJson = await urlDocumentResponse.json();

      link = urlDocumentJson.link;

      break;
    }
    default:
      break;
  }

  if (!link) {
    return;
  }

  if (isInAppView) {
    window.location.href = link;
  } else {
    openPdf(link, print, winName);
  }
};

export {
  getCheckinStatusText,
  isCheckedIn,
  isDisabled,
  userMustEnterPin,
  userEnteredWrongPin,
  userJustEnteredPin,
  openBoardingPassLink,
};
