// @flow
import includes from 'lodash/includes';
import { computed } from 'mobx';

import { formatPrice } from '../../utils/price';
import { infoLine } from '../../utils/string';

import type PartyMember from '../../models/PartyMember';

import type {
  TravelpriceServiceItem,
  DiscountPrice,
} from '../../api/boardAccount';

export default class ServiceItem {
  mpis: number[];
  date: ?string;
  name: string;
  description: ?string;
  amount: ?number;
  amountText: string;
  travelparty: PartyMember[];
  operatorAddress1: ?string;
  operatorCity: ?string;
  operatorCode: ?string;
  operatorCountry: ?string;
  operatorEmail: ?string;
  operatorName: ?string;
  operatorPhone: ?string;
  operatorPostalCode: ?string;
  showAmount: boolean;
  tourCode: ?string;
  type: ?string;
  prices: DiscountPrice[];

  constructor(data: TravelpriceServiceItem, travelparty: PartyMember[]) {
    Object.assign(this, data);
    this.travelparty = travelparty;
  }

  get participants(): PartyMember[] {
    return this.travelparty.filter(p => includes(this.mpis, p.mpi));
  }

  get displayAmount(): string {
    const { amount } = this;
    return typeof amount === 'number' && !Number.isNaN(amount)
      ? formatPrice(amount, true)
      : '';
  }

  get displayOperatorCode(): string {
    const { operatorCode } = this;
    return operatorCode ? `Veranstalternummer: ${operatorCode}` : '';
  }

  @computed
  get operatorLine1(): string {
    return infoLine([this.operatorName, this.displayOperatorCode]);
  }

  @computed
  get operatorLine2(): string {
    return infoLine([
      this.operatorPostalCode,
      this.operatorCity,
      this.operatorCountry,
      this.operatorPhone,
      this.operatorEmail,
    ]);
  }
}
