// @flow

import React, { Component } from 'react';

import RestaurantsIcon from '../../../assets/svg/inline/restaurants.inline.svg';
import SportsIcon from '../../../assets/svg/inline/sports.inline.svg';
import FitnessIcon from '../../../assets/svg/inline/fitness.inline.svg';
import LandIcon from '../../../assets/svg/inline/land.inline.svg';

import TeaserTile from '../tiles/TeaserTile';

import type ItineraryDay from '../../models/ItineraryDay';
import type { TilesStoreType } from '../../stores/TilesStore';

type Props = {
  day: ItineraryDay,
  tiles?: TilesStoreType | null,
};

export default class TeaserStripForDay extends Component<Props> {
  renderLandTeasers() {
    const { day, tiles } = this.props;

    return (
      <div className="l-row">
        <div className="l-col">
          <TeaserTile
            link={`/landausfluege/${day.dayNumber}`}
            Icon={LandIcon}
            disabled={day.isPast || !tiles || !tiles.landausfluege || !tiles.landausfluege.enabled}
            badge={tiles && tiles.landausfluege && tiles.landausfluege.notification}
          >
            {`Landausflüge in ${day.port.name}`}
          </TeaserTile>
        </div>
        <div className="l-col">
          <TeaserTile
            link="/schoenheit-und-gesundheit"
            Icon={SportsIcon}
            disabled={!tiles || !tiles.schoenheitGesundheit || !tiles.schoenheitGesundheit.enabled}
            badge={tiles && tiles.schoenheitGesundheit && tiles.schoenheitGesundheit.notification}
          >
            Schönheit & Gesundheit
          </TeaserTile>
        </div>
      </div>
    );
  }

  renderSeaTeasers() {
    const { tiles } = this.props;
    return (
      <div className="l-row">
        <div className="l-col">
          <TeaserTile
            link="/fitness"
            Icon={FitnessIcon}
            disabled={!tiles || !tiles.fitness || !tiles.fitness.enabled}
            badge={tiles && tiles.fitness && tiles.fitness.notification}
          >
            Fitness
          </TeaserTile>
        </div>
        <div className="l-col">
          <TeaserTile
            link="/tischreservierung"
            Icon={RestaurantsIcon}
            disabled={!tiles || !tiles.tischreservierung || !tiles.tischreservierung.enabled}
            badge={tiles && tiles.tischreservierung && tiles.tischreservierung.notification}
          >
            {'Tisch\u00ADreservierung'}
          </TeaserTile>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section>
        <h1>Weitere Informationen</h1>

        {this.props.day.isLandDay
          ? this.renderLandTeasers()
          : this.renderSeaTeasers()}
      </section>
    );
  }
}
