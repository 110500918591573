// @flow

import React from 'react';
import classNames from 'classnames';
import FormRadio from '../../../form/Radio';

export default class Radio extends FormRadio {
  render() {
    const { name, label, className, checked } = this.props;

    return (
      <div
        className={classNames({
          'form-radio': true,
          [className]: !!className,
        })}
      >
        <div className="radio-button">
          <input
            checked={checked}
            name={name}
            className="form-radio__input"
            type="radio"
            ref={ref => {
              this._ref = ref;
            }}
            onChange={this.handleChange}
          />
          <div className="check" />
        </div>
        <span className="radio-label">{label}</span>
      </div>
    );
  }
}
