// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

const SectionsList = ({ sections }) => (
  <div className="daily-catalog-tiles">
    {sections.map((section, index) => (
      <div className="daily-catalog-tiles__item" key={index}>
        <h4 className="daily-catalog-tiles__item__title">{section.header}</h4>
        {section.content}
      </div>
    ))}
  </div>
);

SectionsList.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ).isRequired,
};

export default SectionsList;
