//@flow
import Cookie from 'js-cookie';
const COOKIE_NAME = 'tuic-mrl-opt-out';

const isUrlOptOut = (): boolean => {
  return document.location.search.indexOf('tracking=false') >= 0;
};

const isUrlOptIn = (): boolean => {
  return document.location.search.indexOf('tracking=true') >= 0;
};

const updateOptOutCookie = () => {
  if (isUrlOptOut()) {
    Cookie.set(COOKIE_NAME, true, { expires: 365 * 10 });
  } else if (isUrlOptIn()) {
    Cookie.remove(COOKIE_NAME);
  }
};

const isOptOutCookieSet = (): boolean => {
  return Cookie.get(COOKIE_NAME) === 'true';
};

const isOptOut = (): boolean => {
  updateOptOutCookie();
  return isOptOutCookieSet() || isUrlOptOut();
};

/**
 * Opt Out
 * TUICUNIT-582
 * */
export { isOptOut };
