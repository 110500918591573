// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import { inject } from 'mobx-react';
import { autobind } from 'core-decorators';

import { bookingRequestShape } from '../../utils/PropTypes';
import tracking from '../../tracking';

@inject('authStore')
export default class BreadcrumbLink extends Component {
  static propTypes = {
    path: PropTypes.string,
    text: PropTypes.string,
    bookingRequest: bookingRequestShape,
  };

  static contextTypes = {
    setBreadcrumbTitle: PropTypes.func,
  };

  // TUICUNIT-3782
  @autobind onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  render() {
    const { path, text, bookingRequest } = this.props;
    const { setBreadcrumbTitle } = this.context;
    if (setBreadcrumbTitle) setBreadcrumbTitle(path, text, bookingRequest);

    // TUICUNIT-3782
    if (text === 'Meine Reise' && this.props.authStore.user && this.props.authStore.user.backLink) {
      return (
        <Link
          to=""
          className="breadcrumb__crumb"
          onClick={() => {
            tracking.click('breadcrumb.link.' + text);
            this.onLogoutClick();
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Link>
      );
    }

    return (
      <Link
        to={path}
        className="breadcrumb__crumb"
        onClick={() => {
          tracking.click('breadcrumb.link.' + text);
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Link>
    );
  }
}
