// @flow

import React from 'react';
import styled, { css } from 'styled-components';

import NavEntries from '../NavEntries';
import { type NavigationItemList } from '../../../api/navigation';
import { font, dimensions } from '../styles';

const DesktopMenu = styled.div`
  position: absolute;
  z-index: 1;
  top: 40px;
  right: 0;
  width: 180px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.16);
  max-height: 200px;
  transition: max-height 300ms ease-in-out, opacity 300ms ease-in-out;

  flex: 1;
  ${p =>
    !p.open &&
    css`
      max-height: 0;
      opacity: 0;
    `}

  .nav-entry {
    ${font({ size: '20px', spacing: '0.4px' })}
    display: block;
    margin: 20px;
    height: 22px;

    :hover {
      ${font({ size: '21px', spacing: '0.4px' })}
    }

    :active {
      opacity: 0.7;
    }
  }

  @media (max-width: ${dimensions.mobileL}px) {
    display: none;
  }
}
`;

interface Props {
  open: boolean;
  navigation: NavigationItemList;
}

export default function Desktop({ open = false, navigation }: Props) {
  return (
    <DesktopMenu open={open}>
      <NavEntries model={navigation} />
    </DesktopMenu>
  );
}
