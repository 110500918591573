// @flow
export default class Brochure {
  type: string;
  headline: string;
  text: string;
  pdfUrlPath: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
