// @flow

import fetchForUser from '../utils/fetchForUser';
import { getPinHeader } from './utils/';
import type User from '../models/User';

export default {
  // The url, and the field that has to be send in body, will be delivered via
  // a feed, so they have to be added here as well
  validateSepa: (user: User, data: any) =>
    fetchForUser(user, `/api/land/payment/validatesepa`, {
      method: 'post',
      body: JSON.stringify(data),
    }),
  save: (user: User, mpi: number | string, data: any) =>
    fetchForUser(user, `/api/land/payment/info/store/${mpi}`, {
      ...getPinHeader(mpi),
      method: 'post',
      body: JSON.stringify(data),
    }),
  info: (user: User, mpi: number | string) =>
    fetchForUser(user, `/api/land/payment/info/${mpi}`, {
      ...getPinHeader(mpi),
    }),
  configuration: (user: User) =>
    fetchForUser(user, '/api/land/payment/configuration'),
};

// export const mockApi = {
//   // MockApi allows only DE calls :)
//   validateSepa: (user, data) =>
//     fetchForUser(
//       user,
//       data.iban.indexOf('DE') === 0
//         ? '/mock/sepa/success.json'
//         : '/mock/sepa/error.json'
//     ),
//   save: user => fetchForUser(user, '/mock/payment/saveSuccess.json'),
//   configuration: user =>
//     fetchForUser(user, '/mock/creditcard/configuration.json'),
// };
