// @flow

import React from 'react';

import CheckIcon from '../../assets/svg/inline/ic-check-rund.inline.svg';

type Props = {
  benefits: string[],
};

const BenefitList = ({ benefits }: Props) => (
  <ul className="benefit-list">
    {benefits.map((benefit, index) => (
      <li key={index} className="benefit-list__item">
        <span className="svg">
          <CheckIcon className="inline-svg" />
        </span>
        <span>{benefit}</span>
      </li>
    ))}
  </ul>
);

export default BenefitList;
