// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import { autobind } from 'core-decorators';

type Props = {
  children: Node,
  header: string | Node,
  expanded?: boolean,
  onChange?: ?(isExpanded: boolean) => mixed,
};

class AccordionItemSingleMode extends Component<Props, State> {
  get className(): string {
    const { expanded } = this.props;
    return `accordion-item accordion-item${expanded ? '--expanded' : ''}`;
  }

  @autobind
  toggle() {
    const { onChange, expanded } = this.props;
    if (onChange) onChange(!expanded);
  }

  render() {
    const { expanded, header, children } = this.props;

    return (
      <div
        className={classNames({
          'accordion-item': true,
          'accordion-item--expanded': expanded,
        })}
      >
        <h2 className="accordion-item__header" onClick={this.toggle}>
          {header}
        </h2>
        <div className="accordion-item__content">{children}</div>
      </div>
    );
  }
}

export default AccordionItemSingleMode;
