// TODO: add flow types

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { bookingRequestShape } from '../../utils/PropTypes';
import { formatPrice } from '../../utils/price';
import InfoBox from '../InfoBox';
import { BOOKING_DELETED } from '../../models/BookingRequest/constants';

export default class BookingTable extends Component {
  static propTypes = {
    bookingRequest: bookingRequestShape.isRequired,
    conflicts: PropTypes.arrayOf(PropTypes.any),
    showChildInfo: PropTypes.bool,
  };

  findConflict(mpi) {
    const { conflicts } = this.props;
    return conflicts ? conflicts.find((m) => m.conflictedMpis.indexOf(parseInt(mpi, 10)) > -1) : false;
  }

  render() {
    const { bookingRequest } = this.props;
    const bookingContext = bookingRequest.bookingContextName || '';

    return (
      <div>
        <div className={`booking-table ${bookingContext}`}>
          <div className="booking-table__header">
            <div className="booking-table__row">
              <div className="booking-table__cell" colSpan={2}>
                {bookingRequest.getText('listingType')}
              </div>
              <div className="booking-table__cell empty" />
              <div className="booking-table__cell empty" />
            </div>
          </div>

          {bookingRequest.itemsToBook.map((item) => {
            const hasPriceTag = !!item.priceTag;
            const priceTag = item.priceTag || {};
            const hasRefund = !!priceTag.refund && priceTag.userPrice !== priceTag.original;

            return (
              <div
                key={item.name}
                className={classNames({
                  'booking-table__body': true,
                  'is-cancellation': item.type === BOOKING_DELETED,
                  'no-padding': !hasPriceTag,
                })}
              >
                {hasPriceTag && <div className="booking-table__spacer-row" />}
                {hasPriceTag && !hasRefund && (
                  <div className="booking-table__row">
                    <div
                      className="booking-table__cell booking-table__name bold discount"
                      dangerouslySetInnerHTML={{
                        __html: item.name,
                      }}
                    />
                    <div className="booking-table__cell discount">
                      <div className="big">{priceTag.priceLabels[0]}</div>
                    </div>
                    <div className="booking-table__cell booking-table__price discount">
                      <div className="big">{formatPrice(item.price, true)}</div>
                    </div>
                  </div>
                )}

                {hasPriceTag && hasRefund && (
                  <div className="booking-table__row no-border">
                    <div
                      className="booking-table__cell booking-table__name bold discount"
                      dangerouslySetInnerHTML={{
                        __html: item.name,
                      }}
                    />
                    <div className="booking-table__cell discount">{priceTag.originalLabel}</div>
                    <div className="booking-table__cell booking-table__price discount">
                      {formatPrice(priceTag.original, true)}
                    </div>
                  </div>
                )}

                {hasPriceTag && hasRefund && (
                  <div className="booking-table__row no-border">
                    <div className="booking-table__cell empty discount" />
                    <div className="booking-table__cell discount">{priceTag.refundText}</div>
                    <div className="booking-table__cell booking-table__price discount">
                      {formatPrice(-1 * priceTag.refund, true)}
                    </div>
                  </div>
                )}

                {hasPriceTag && hasRefund && (
                  <div className="booking-table__row">
                    <div className="booking-table__cell empty discount" />
                    <div className="booking-table__cell discount">
                      <div className="big">{priceTag.priceLabels[0]}</div>
                    </div>
                    <div className="booking-table__cell booking-table__price discount">
                      <div className="big">{formatPrice(item.price, true)}</div>
                    </div>
                  </div>
                )}

                {!hasPriceTag && (
                  <div className="booking-table__row">
                    <div
                      className="booking-table__cell booking-table__name"
                      dangerouslySetInnerHTML={{
                        __html: item.name,
                      }}
                    />
                    <div className="booking-table__cell empty" />
                    <div className="booking-table__cell booking-table__price">
                      {bookingRequest.showIndividualPrices && item.price !== null ? (
                        bookingRequest.bookable.isInclusive ? (
                          <span>Inklusivleistung</span>
                        ) : (
                          <span>{formatPrice(item.price)}</span>
                        )
                      ) : null}
                    </div>
                  </div>
                )}

                {!hasPriceTag && (
                  <div className="booking-table__row">
                    <div className="booking-table__cell booking-table__info">{item.info}</div>
                    <div className="booking-table__cell empty" />
                    <div className="booking-table__cell empty" />
                  </div>
                )}

                {item.personHeight ? (
                  <div className="booking-table__row">
                    <div className="booking-table__cell booking-table__info">Größe: {item.personHeight} cm</div>
                    <div className="booking-table__cell empty" />
                    <div className="booking-table__cell empty" />
                  </div>
                ) : null}
                {this.findConflict(item.mpi) ? (
                  <div className="booking-table__row">
                    <div className="booking-table__cell booking-table__error">
                      Sie haben bereits eine Leistung in diesem Zeitraum reserviert. Bitte wählen Sie einen neuen Termin
                      aus.
                    </div>
                    <div className="booking-table__cell empty" />
                    <div className="booking-table__cell empty" />
                  </div>
                ) : null}
              </div>
            );
          })}
          <div className="booking-table__footer">
            <div className="booking-table__row">
              <div className="booking-table__cell booking-table__name">
                {bookingRequest.isCancellation ? '' : 'Gesamtpreis'}
              </div>
              <div className="booking-table__cell empty" />
              {bookingRequest.isCancellation ? (
                <div className="booking-table__cell booking-table__cancel-footer">
                  Ihr Bordkonto wird nicht belastet.
                </div>
              ) : (
                bookingRequest.total !== null && (
                  <div className="booking-table__cell booking-table__price">{formatPrice(bookingRequest.total)}</div>
                )
              )}
            </div>
          </div>
        </div>
        {this.props.showChildInfo ? (
          <InfoBox noIcon className="infobox__smaller">
            <strong>Hinweis:</strong> Bitte beachten Sie, dass Kinder nur in Begleitung einer vorher benannten
            erwachsenen Aufsichtsperson oder den Erziehungsberechtigten an Landausflügen teilnehmen dürfen. Jugendliche
            ab 16 Jahren können mit Genehmigung der Erziehungsberechtigten auch unbegleitet an den meisten Landausflügen
            teilnehmen. Die Genehmigung für Kinder und Jugendliche unter 18 Jahren muss zum Landgang ausgefüllt an der
            Rezeption vorliegen.
          </InfoBox>
        ) : null}
      </div>
    );
  }
}
