// @flow
// TUICUNIT-2789
import React from 'react';
import InfoIcon from '../../../assets/svg/inline/ic-info.inline.svg';
import type { RailVoucher } from '../../stores/ArrivalDepartureStore';

type Props = {
  railVoucher: RailVoucher,
};

export default function RailVoucherView(props: Props) {
  const { railVoucher } = props;
  return railVoucher && railVoucher.text ? (
    <div className={`rail-fly-voucher l-col double${railVoucher.url ? ' active' : ''}`}>
      <div className="l-mod l-block">
        <div className="infobox svg">
          <div className="icon-wrapper">
            <InfoIcon className="inline-svg" />
          </div>
          <div>
            <h2>{railVoucher.headline}</h2>
            <div dangerouslySetInnerHTML={{ __html: railVoucher.text }} />
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
