// @flow
import { observable, computed, action } from 'mobx';
import union from 'lodash/union';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import BookingRequestBase from './BookingRequestBase';

import { BOOKING_ADDED, BOOKING_DELETED, BOOKING_UNCHANGED } from './constants';
import type PriceTypes from '../PriceTypes';
import { PRICE_TYPES } from '../PriceTypes';

import PartyMember from '../../models/PartyMember';
import Excursion from '../../models/Excursion';
import ItineraryDay from '../../models/ItineraryDay';
import type IncludedShoreExDetail from '../../models/IncludedShoreEx/IncludedShoreExDetail';

import type {
  BookingType,
  IGeneralBookingData,
  IGeneralItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
} from '../../types/booking';

export default class ExcursionBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      success: {
        ...BookingRequestBase.TEXTS.create.success,
        text: 'Vielen Dank für Ihre Anfrage. Wir überprüfen aktuell für Sie das Kontingent. Dies kann mehrere Minuten in Anspruch nehmen. Bei Verfügbarkeit sehen Sie die bestätigte Reservierung in Ihrem Reiseplan.',
      },
    },
    cancellation: {
      ...BookingRequestBase.TEXTS.cancellation,
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: (bookingRequest) =>
          `Möchten Sie den ${bookingRequest.bookable.bookingName} wirklich für folgende Reiseteilnehmer stornieren?`,
      },
    },
  };

  @observable selectedMpis = [];
  @observable acceptedOperator = false;
  travelParty: PartyMember[];

  constructor(travelParty: PartyMember[], bookable: Excursion | IncludedShoreExDetail) {
    super(travelParty, bookable);
    this.travelParty = travelParty;
    if (bookable) {
      this.selectedMpis = bookable.bookedMpis || [];
      if (bookable.isPriceBulk) {
        this.selectedMpis = [this.requestor.mpi];
      }
      // TUICUNIT-2638: waiting list, mixed group dirty crap
      if (bookable.exbooking) {
        this.selectedMpis = bookable.exbooking.mpis || [];
      }
    }
  }

  @computed get requestor(): PartyMember {
    return this.travelParty.find((member) => member.requestor) || this.travelParty[0];
  }

  @computed get date(): string {
    return this.bookable.date;
  }

  @computed get priceTypes(): PriceTypes {
    return this.bookable.getPriceTypes();
  }

  getPrice(mpi: number) {
    const member = this.travelParty.find((m) => m.mpi === mpi);
    return member ? this.getPriceForMember(member) : 0;
  }

  getPriceForMember(member: PartyMember) {
    const ageOnDate = member.ageOnDate(this.bookable.date);

    try {
      return member._isBaby(ageOnDate)
        ? 0
        : this.priceTypes.getPrice(member.guestType.priceModel, member._isChild(ageOnDate), this.bookable.isPriceBulk);
    } catch (exc) {
      return 0;
    }
  }

  @computed get selectedDay(): ?ItineraryDay {
    return this.bookable && this.bookable.day;
  }

  @action toggleSelection(mpi: number, selected: boolean) {
    this.selectedMpis = selected ? union(this.selectedMpis, [mpi]) : without(this.selectedMpis, mpi);
  }

  @action cancelBooking(): void {
    this.selectedMpis = [];

    // TUICUNIT-2638: waiting list, mixed group dirty crap
    if (this.bookable.exbooking) {
      this.bookable.exbooking.mpis.forEach((mpi) => {
        this.toggleSelection(mpi, false);
      });
    } else {
      this.bookable.bookedMpis.forEach((mpi) => {
        this.toggleSelection(mpi, false);
      });
    }
  }

  @action setAcceptation(value: boolean) {
    this.acceptedOperator = value;
  }

  @computed get isChangeRequest(): boolean {
    if (!this.bookable.isBooked) return false;

    if (this.unchangedItems.length > 0) {
      return this.addedItems.length > 0 || this.deletedItems.length > 0;
    } else {
      return this.addedItems.length > 0 && this.deletedItems.length > 0;
    }
  }

  @computed get isCancellation(): boolean {
    return (
      !!this.bookable.isBooked &&
      this.unchangedItems.length === 0 &&
      this.addedItems.length === 0 &&
      this.deletedItems.length > 0
    );
  }

  // private
  @computed get unchangedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter((b) => b.type === BOOKING_UNCHANGED);
  }

  // private
  @computed get addedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter((b) => b.type === BOOKING_ADDED);
  }

  // private
  @computed get deletedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter((b) => b.type === BOOKING_DELETED);
  }

  createItemToBook(mpi: number, type: BookingType) {
    const member = this.travelParty.find((p) => p.mpi === mpi);
    if (!member) return {};

    const ageOnDate = member.ageOnDate(this.bookable.date);
    const priceType = member._isChild(ageOnDate) ? PRICE_TYPES.child : PRICE_TYPES.adult;
    const priceTag = this.priceTypes ? this.priceTypes.getPriceInfos(priceType, [member]) : null;

    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAgeOnDate(this.bookable.date) : '',
      price: member ? this.getPriceForMember(member) : 0,
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
      personHeight: member ? member.personHeight : null,
      priceTag: priceTag ? priceTag[0] : null,
    };
  }

  @computed get itemsToBook(): IGeneralItemToBook[] {
    const items = [];
    const selectedMpis = this.selectedMpis.slice();
    let bookedMpis = [];

    // TUICUNIT-2638: waiting list, mixed group dirty crap
    if (this.bookable.exbooking) {
      bookedMpis = this.bookable.exbooking.mpis;
    } else {
      bookedMpis = this.bookable.bookedMpis;
    }

    this.travelParty
      .map((p) => p.mpi)
      .forEach((mpi) => {
        const isSelected = includes(selectedMpis, mpi);
        const isBooked = includes(bookedMpis, mpi);
        if (isBooked && isSelected) {
          items.push(this.createItemToBook(mpi, BOOKING_UNCHANGED));
        } else if (isBooked) {
          items.push(this.createItemToBook(mpi, BOOKING_DELETED));
        } else if (isSelected) {
          items.push(this.createItemToBook(mpi, BOOKING_ADDED));
        }
      });

    return items;
  }

  @computed get total(): number {
    if (this.bookable.isPriceBulk) {
      return this.priceTypes.getPrice(this.requestor.guestType.priceModel, false, true);
    }

    // sum up the individual prices
    return this.selectedMpis.reduce((total, mpi) => total + this.getPrice(mpi), 0);
  }

  @computed get dataHasChanged(): boolean {
    // TUICUNIT-2638: waiting list, mixed group dirty crap
    if (this.bookable.exbooking) {
      return !isEqual(Array.from(this.selectedMpis), this.bookable.exbooking.mpis);
    }
    return !isEqual(Array.from(this.selectedMpis), this.bookable.bookedMpis);
  }

  @computed get isReady(): boolean {
    // check if acceptedOperator is required and if so, if it is set
    // prettier-ignore
    const baseCheck = ((!this.bookable.isExternalOperator || !this.bookable.requiresTourSalesTermsNotice || this.acceptedOperator) && this.dataHasChanged);

    return baseCheck && (this.selectedMpis.length > 0 || this.bookable.isBooked);
  }

  @computed get bookingData(): IGeneralBookingData[] {
    const { tourSalesTermsId, requiresTourSalesTermsNotice } = this.bookable || {};

    const sendTourSalesTermsId = tourSalesTermsId && requiresTourSalesTermsNotice;

    return this.addedItems.length > 0
      ? [
          {
            type: 'excursion',
            vacancyId: this.bookable.vacancyId,
            participants: this.addedItems.map((b) => {
              return {
                mpi: parseInt(b.mpi, 10),
                personHeight: parseInt(b.personHeight, 10),
              };
            }),
            ...(sendTourSalesTermsId ? { tourSalesTermsId } : {}),
          },
        ]
      : [];
  }

  @computed get cancelData(): IBookingCancelationData[] {
    if (!this.bookable.isBooked) return [];

    const mpisToCancel = this.deletedItems.map((b) => b.mpi);
    let bookedIds = [];
    // TUICUNIT-2638: waiting list, mixed group dirty crap
    if (this.bookable && this.bookable.exbooking) {
      bookedIds = this.bookable.exbooking.bookingId || [];
    } else if (this.bookable && this.bookable.booking) {
      bookedIds = this.bookable.booking.bookingId || [];
    }

    return mpisToCancel.map((mpi) => ({
      type: 'excursion',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const { name, bookingId, analyticsCategory, date } = this.bookable;
    let products = [];
    if (!this.isCancellation) {
      const addedMembers = this.travelParty.filter((m) => includes(this.selectedMpis, m.mpi));
      const adultsCount = addedMembers.filter((m) => !m._isChild(m.ageOnDate(date))).length;
      const childrenCount = addedMembers.filter((m) => m._isChild(m.ageOnDate(date))).length;

      if (adultsCount > 0) {
        products.push({
          id: `${bookingId}`,
          name,
          category: analyticsCategory,
          quantity: adultsCount,
          price: this.bookable.priceAdult,
        });
      }
      if (childrenCount > 0) {
        products.push({
          id: `${bookingId}`,
          name,
          category: analyticsCategory,
          quantity: childrenCount,
          price: this.bookable.priceChild || this.bookable.priceAdult,
        });
      }
    }

    return {
      products,
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}-${this.selectedMpis.length}`,
              revenue: this.total,
            },
          ],
      refunds:
        this.isCancellation || this.isChangeRequest
          ? [
              {
                id: `${bookingId}-${this.bookable.bookedMpis.length}`,
              },
            ]
          : [],
    };
  }

  setPersonHeight(mpi: number, personHeight: string) {
    const member = this.travelParty.find((p) => p.mpi === mpi);
    if (member) member.personHeight = personHeight;
  }

  get bookingContextName(): string {
    return 'excursion';
  }
}
