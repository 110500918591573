// @flow

import React, { Component } from 'react';
import Bus from './Bus';
import Flight from './Flight';
import Train from './Train';

import type ArrivalDepartureItem from '../../../models/ArrivalDeparture/ArrivalDepartureItem';

type Props = {
  item: ArrivalDepartureItem,
  expanded: boolean,
};

export default class ArrivalDepartureListItem extends Component<Props> {
  render() {
    const { item, expanded } = this.props;
    if (!item) return null;

    if (item.isFlight) {
      return <Flight item={item} expanded={expanded} />;
    }

    if (item.isBus) {
      return <Bus item={item} expanded={expanded} />;
    }

    if (item.isTrain) {
      return <Train item={item} expanded={expanded} />;
    }

    return null;
  }
}
