// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mockUser = false;
const mockGeneral = false;

const getUrlUser = (): string =>
  (mockUser || mockAll) && isLocal ? `/mock/tiles/tilesconfig.json` : `/api/land/tilesconfig`;
const getUrlGeneral = (masterData: MasterData): string => {
  const { ed, dd, shipId, tripCode } = masterData;
  return (mockGeneral || mockAll) && isLocal
    ? `/mock/tiles/cruisetiles.json`
    : `/api/land/cruisetiles/${tripCode}/${ed}/${dd}/${shipId}`;
};

const mockHighlights = false;
const getHighlightsUrl = (masterData: MasterData): string =>
  (mockHighlights || mockAll) && isLocal
    ? `/mock/highlights_TUICUNIT-2920.json`
    : `/api/land/highlights/${masterData.ed}/${masterData.dd}/${masterData.shipId}`;

export default {
  // new with TUICUNIT-2844
  getUser: (user: User) => fetchForUser(user, getUrlUser()),
  getGeneral: (masterData: MasterData) =>
    fetch(getUrlGeneral(masterData)).then(response => {
      if (response.ok) {
        return response.json();
      }
      return {};
    }),
  getHighlights: (user: User, masterData: MasterData) => fetchForUser(user, getHighlightsUrl(masterData)),
};

export type TileType = {
  title: ?string,
  visible: boolean,
  enabled: boolean,
  image: ?string,
  notification: ?string,
};

export type ApiResponse = {
  // tilesconfig
  Einreisebestimmungen: TileType,
  OnlineCheckIn: TileType,
  SchiffsManifest: TileType,
  Zahlungsmittel: TileType,
  bookingDetails: TileType,
  checkin: TileType,
  excursions: TileType,
  includedShoreEx: TileType,
  manifest: TileType,
  spaBalconyCabin: TileType,

  //cruisetiles
  AnUndAbreise: TileType,
  AnUndAbreiseinformationen: TileType,
  AnwendungenFuerKidsUndTeens: TileType,
  Entspannungslogen: TileType,
  Erinnerungsstuecke: TileType,
  FAQ: TileType,
  Friseur: TileType,
  GaestelisteAufFacebook: TileType,
  Gesundheitschecks: TileType,
  InformationenZuIhremSchiff: TileType,
  Kosmetik: TileType,
  Koerperanwendungen: TileType,
  Massagen: TileType,
  Maenner: TileType,
  Newsletter: TileType,
  Pakete: TileType,
  PaketeUndZeitungen: TileType,
  PermanentMakeUpAndMore: TilesStore,
  PersonalTraining: TileType,
  Reisepreis: TileType,
  Reiseunterlagen: TileType,
  Sauna: TileType,
  SpaUndMeer: TileType,
  Thalasso: TileType,
  VerantwortungsvollesReisen: TileType,
  Zeitungen: TileType,
  ZuZweitEntspannen: TileType,
  Zusatzleistungen: TileType,
  fitness: TileType,
  furtherInformation: TileType,
  healthAndBeauty: TileType,
  highlight: TileType,
  internet: TileType,
  parking: TileType,
  program: TileType,
  reiseversicherung: TileType,
  tableReservation: TileType,
  travelPlan: TileType,
};

export type ApiType = {
  // this is the old way
  deprecated_get: User => Promise<ApiResponse>,
  // new with TUICUNIT-2844
  getUser: User => Promise<ApiResponse>,
  getGeneral: MasterData => Promise<ApiResponse>,
};
