// @flow
import { computed } from 'mobx';

import {
  PAYMENT_OPTION_SEPA,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_MASTER_CARD,
  PAYMENT_OPTION_AMERICAN_EXPRESS,
  PAYMENT_OPTION_CASH,
} from '../config/constants';
import { obfuscateCreditCardNumber, obfuscateIban } from '../utils/payment';

type SEPA = {
  type: string, // kind of payment, here SEPA
  iban: string, // the iban of the guest
  mandate?: any, // ? missing in docs (probably string)
  creditorIdentifier?: any, // ? missing in docs (probably string)
};

type CreditCard = {
  type: string, // kind of payment, here CreditCars
  ccNumber: string, // creditcard number
  ccBrand: string, // creditcard brand e.g. VISA, American Express
  ccExpirationDate: string, // expiration date, eg. 2020-05-01 00:00:00
  ccCustomer: string, // customer name
};

export type PaymentData = {
  type: string, // kind of payment
  displayName: string,
  ccNumber?: string, // creditcard number
  ccBrand?: string, // creditcard brand e.g. VISA, American Express
  ccExpirationDate?: string, // expiration date, eg. 2020-05-01 00:00:00
  ccCustomer?: string, // customer name
  iban?: string, // the iban of the guest
  mandate?: any, // ? missing in docs (probably string)
  creditorIdentifier?: any, // ? missing in docs (probably string)
};

type PaymentInfoDataType = {
  payer: number,
  paymentData: ?PaymentData,
  paysFor: number[],
  paysForPossibleMpis: number[],
  ownPaymentMethod?: boolean,
  preliminary?: boolean,
  digitalInvoiceEnabled?: boolean,
  digitalInvoiceConsent?: boolean,
  digitalInvoiceEmail?: string,
};

/**
 * This abstracts away the details of already entered payment info
 */
export default class PaymentInfo {
  data: ?PaymentInfoDataType;
  mpi: number;

  constructor(mpi: number, data: ?PaymentInfoDataType) {
    this.mpi = mpi;
    this.data = data;
  }

  get isPaid(): boolean {
    return this.isSelfPayer || this.isPayedBySomeone;
  }

  get isSelfPayer(): boolean {
    if (!this.data || !this.data.payer) return false;
    return this.mpi === this.data.payer;
  }

  get isPayedBySomeone(): boolean {
    return !this.isSelfPayer && !!this.payerMpi;
  }

  get paysForOthers(): boolean {
    return !!this.data && !!this.data.paysFor && this.data.paysFor.length > 0;
  }

  get payerMpi(): ?number {
    return this.data && this.data.payer;
  }

  get paymentData(): ?PaymentData {
    return this.data && this.data.paymentData;
  }

  get paysFor(): number[] {
    return (this.data && this.data.paysFor) || [];
  }

  get paysForPossibleMpis(): number[] {
    return (this.data && this.data.paysForPossibleMpis) || [];
  }

  get paymentType(): ?string {
    return this.paymentData && (this.paymentData.ccBrand || this.paymentData.type);
  }

  get payedMpis(): number[] {
    if (!this.data || !this.payerMpi) return [];
    return [this.payerMpi, ...this.paysFor];
  }

  containsPaymentFor(mpi: number): boolean {
    return this.payedMpis.includes(mpi);
  }

  @computed
  get displayInfos(): Object[] {
    if (!this.paymentData) {
      return [];
    }

    const { displayName } = this.paymentData;

    const baseInfo = [{ key: 'Zahlungsart', value: displayName }];

    switch (this.paymentType) {
      case PAYMENT_OPTION_CASH:
        return baseInfo;
      case PAYMENT_OPTION_VISA:
      case PAYMENT_OPTION_MASTER_CARD:
      case PAYMENT_OPTION_AMERICAN_EXPRESS: {
        const { ccExpirationDate, ccCustomer, ccBrand, ccNumber } = this.paymentData; // as CreditCard

        const validUntil = ccExpirationDate ? new Date(ccExpirationDate) : null;

        return [
          ...baseInfo,
          {
            key: 'Karteninhaber',
            value: ccCustomer,
          },
          {
            key: 'Kartentyp',
            value: ccBrand,
          },
          {
            key: 'Kartennummer',
            value: obfuscateCreditCardNumber(ccNumber),
          },
          {
            key: 'Gültig bis (Monat / Jahr)',
            value: validUntil ? `${validUntil.getMonth() + 1} / ${validUntil.getFullYear()}` : '',
          },
        ];
      }
      case PAYMENT_OPTION_SEPA: {
        const { iban, creditorIdentifier, mandate } = this.paymentData; // as SEPA
        return [
          ...baseInfo,
          {
            key: 'IBAN',
            value: obfuscateIban(iban),
          },
          {
            key: 'Gläubiger ID',
            value: creditorIdentifier,
          },
          {
            key: 'SEPA-Mandat',
            value: mandate,
          },
        ];
      }
      default:
        return [];
    }
  }
}
