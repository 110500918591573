const faqs = [
  {
    type: 'flight',
    headline: 'An- und Abreise mit dem Flugzeug',
    section: [
      {
        q: 'Wann erfahre ich meine Flugzeiten?',
        a:
          'Die Fluginformationen werden frühestens 4 Monate und bis spätestens 7 Tage vor Ihrem jeweiligen Hin- und Rückflug im Meine Reise Portal unter Buchungsdetails / An- und Abreise angezeigt. Alle Informationen erhalten Sie mit Ihren digitalen Reiseunterlagen 7 Tage vor Reisebeginn.',
      },
      {
        q: 'Kann ich vorab Sonderleistungen für meinen Flug anmelden?',
        a:
          'Informationen zu Sonderleistungen wie zum Beispiel Einstiegshilfe am Flughafen, Essen an Bord, Übergepäck, Familienservice etc. erhalten Sie unter <a rel="noopener noreferrer" href="https://www.meinschiff.com/an-und-abreise/flug" target="_blank">https://www.meinschiff.com/an-und-abreise/flug</a>',
      },
      {
        q: 'Kann ich einen Online Check-in oder einen Vorabend Check-in vornehmen?',
        a:
          'Ob ein Online Check-in oder ein Vorabend Check-in für die von Ihnen gebuchten Flüge möglich ist, können Sie sobald die Fluginformationen vorliegen unter <a rel="noopener noreferrer" href="https://www.meinschiff.com/an-und-abreise/flug" target="_blank">https://www.meinschiff.com/an-und-abreise/flug</a> den Flugverbindungsübersichten entnehmen.',
      },
      {
        q: 'Ist eine Umbuchung in die Premium Economy Class oder Business Class möglich?',
        a:
          'Für viele Reiseziele bieten wir Flüge in der Premium Economy Class oder der Business Class an. Ob für Ihre Flüge eine Umbuchung auf die gewünschte Serviceklasse möglich ist, können Sie in Ihrem Reisebüro erfragen.',
      },
      {
        q: 'Ist die Bahnanreise zum Flughafen bei Fluganreise inklusive?',
        a:
          'Bei Buchung einer TUI Cruises Kreuzfahrt im Pro und Plus Tarif inklusive internationalen Flugs, ist der Gutschein für das Rail&Fly-Ticket zu allen innerhalb Deutschlands gelegenen Abflughäfen (inkl. Basel) in der 2. Klasse Inklusive. Bei Buchung des PUR Tarifs kann ein Rail&Fly gegen Aufpreise hinzugebucht werden. Bei allen anderen ausländischen Flughäfen ist das Rail&Fly-Ticket nicht gültig, auch nicht für die innerdeutsche Strecke bis zur Grenze. Für einreisende Gäste aus dem Ausland über TUI Cruises gilt für Abflüge ab deutschen Flughäfen das Rail&Fly-Ticket ab der deutschen Grenze. <br>Tickets für die 1. Klasse sind gegen Aufpreis buchbar. Für Gäste der Business Class ist die 1. Klasse inklusive. Bitte beachten Sie, dass das Rail&Fly-Ticket nicht für unsere Flüge und Reisen ab/bis Hamburg, Kiel, Bremerhaven und Warnemünde erhältlich ist.',
      },
      {
        q: 'Kann ich das Rail&Fly-Ticket auch für die 1. Klasse erwerben?',
        a:
          'Gegen einen Aufpreis ist der Erwerb von Tickets der 1. Klasse möglich. Weitere Informationen dazu finden Sie unter folgendem Link: <a rel="noopener noreferrer" target="_blank" href="https://www.meinschiff.com/an-und-abreise/bahn/zug-zum-flug">https://www.meinschiff.com/an-und-abreise/bahn/zug-zum-flug</a>',
      },
      {
        q: 'Wie lange ist das Rail&Fly-Ticket gültig?',
        a:
          'Sie können die Tickets am Tag vor dem Abflugtermin, am Abflugtag selbst sowie am Tag der Rückkehr und am Tag danach nutzen. Zwischen Hin- und Rückreise dürfen maximal zwölf Monate liegen.',
      },
      {
        q: 'Kann ich mit dem Rail&Fly-Ticket einen Zwischenstopp einlegen?',
        a:
          'Das Rail&Fly-Ticket erlaubt Zwischenstopps bei der Reise zum Flughafen. Diese sind jedoch ausschließlich in Richtung des Ziels möglich und erlauben keine Umwege. Zwischenstopps, die diese Voraussetzungen erfüllen sind auf dem Hin- sowie auch auf dem Rückweg möglich.',
      },
      {
        q: 'Können meine Mitreisenden und ich den Rail&Fly-Service auch getrennt nutzen?',
        a:
          'Für Abreisen ab dem 04.10.2022 ist das Einlösen der Rail&Fly-Gutscheincodes pro einzelnem Teilnehmer möglich. Wählen Sie dazu einfach im Einlöseprozess (über ihr Meine Reise Portal) die jeweilige Person aus und buchen Sie ein Einzelticket.',
      },
    ],
  },
  {
    type: 'train',
    headline: 'An- & Abreise mit der Bahn',
    section: [
      {
        q: 'Wann erhalte ich einen Gutscheincode?',
        a: 'Sie erhalten nach Buchung (frühestens 6 Monate vor Kreuzfahrtbeginn) Ihrer An- und Abreise über das Meine Reise Portal einen Gutscheincode pro Teilnehmer und Strecke.',
      },
      {
        q: 'Bekommt jeder Reisende einen eigenen Gutschein?',
        a: 'Kinder von 0 bis einschließlich 5 Jahren reisen generell kostenfrei und benötigen keinen Gutscheincode.<br><br>Kinder zwischen 6 und einschließlich 14 Jahren reisen in Begleitung Erwachsener kostenfrei und benötigen einen Gutscheincode, der zusammen mit dem Gutscheincode des Erwachsenen eingelöst werden muss.<br><br>Kinder ab 15 Jahre gelten als Erwachsene und erhalten einen Gutscheincode.',
      },
      {
        q: 'Welche Züge kann ich nutzen?',
        a: '<b>Die Flexpreis Touristik Kreuzfahrt-Fahrkarte</b> berechtigt die Reisenden zur Fahrt: im <b>Fernverkehr</b> u.a. mit dem ICE, ECE, TGV, railjet express, railjet, IC und EC im <b>Nahverkehr</b> u.a. mit dem IRE, RE, RB, IRE oder S-Bahn sowie in ausgewählten NE-Bahnen Weitere Informationen zum Geltungsbereich der Fahrkarte erhalten Sie unter <a rel="noopener noreferrer" href="https://www.bahnanreise.de/geltungsbereich" target="_blank">www.bahnanreise.de/geltungsbereich</a>',
      },
      {
        q: 'Wie lange ist das Ticket gültig und können Zwischenstopps eingelegt werden?',
        a: 'Die Gültigkeit der Fahrkarte beträgt ab dem aufgedruckten Geltungstag für Reisen bis 100 km 1 Tag. Bei Reisen bis 100 km ist die Fahrt an dem auf der Fahrkarte angegebenen Tag anzutreten. Für Reisen über 100 km beträgt die Gültigkeit 2 Tage und endet um 3 Uhr des auf den letzten Geltungstag folgenden Tag. Die Fahrten können aber jeweils unterbrochen und am nächsten Tag fortgesetzt werden, solange sich Start- und Zielbahnhof nicht ändern.',
      },
      {
        q: 'Können meine Mitreisenden und ich den Rail&Cruise-Service auch getrennt nutzen?',
        a: 'Ja, das ist möglich.',
      },
      {
        q: 'Bekomme ich Rabatt mit der BahnCard?',
        a: 'Nein, auf das Rail & Cruise-Ticket gibt es keinen BahnCard-Rabatt.',
      },
      {
        q: 'Kann ich Sitzplätze reservieren?',
        a: 'Sowohl in der 2. wie auch in der 1. Klasse ist die Sitzplatzreservierung (in Fernverkehrszügen) kostenfrei im Ticket enthalten. Die Umbuchung im Fernverkehr ist für die gebuchten Sitzplätze ausgeschlossen.',
      },
      {
        q: 'Ist das City- Ticket im Preis inklusive?',
        a: 'Das <a rel="noopener noreferrer" target="_blank" href="https://www.bahn.de/angebot/weiterreise/city-ticket?dbkanal_007=L01_S01_D001_KIN_-ta-NAVIGATION-cityticket_LZ01">City-Ticket</a> ist im Preis inklusive - sowohl am Startort wie auch am Zielort.',
      },
      {
        q: 'Wie kann ich meine Verbindung ändern?',
        a: 'Gäste, die den Gutscheincode bereits in eine Fahrkarte umgewandelt haben, können den Gutscheincode erneut bis einen Tag vor dem Reisedatum einlösen und z. B. einen anderen Abfahrtsbahnhof wählen. Dazu gibt der Gast den vorhandenen Gutscheincode erneut ein, wählt dann „geänderte Bahnreisepläne?“ aus und bestätigt, dass durch die Neubuchung die alte Fahrkarte storniert wird und bucht erneut eine Fahrkarte.',
      },
      {
        q: 'Wie kann ich stornieren?',
        a: 'Stornierungen können über das TUI Cruises Kundencenter getätigt werden. Es gelten die allgemeinen Stornobedingungen von TUI Cruises unter <a rel="noopener noreferrer" target="_blank" href="https://www.meinschiff.com/agb">https://www.meinschiff.com/agb</a>.',
      },
    ],
  },
  {
    type: 'bus',
    headline: 'An- & Abreise mit dem Bus',
    section: [
      {
        q: 'Wann erfahre ich meine Abfahrtzeiten?',
        a: 'Die Abfahrtszeiten finden Sie hier in Meine Reise sowie vor Reisebeginn in Ihren Reiseunterlagen.',
      },
      {
        q: 'Kann ich eine Sitzplatzreservierung vornehmen?',
        a:
          'Sie haben im Bus freie Sitzplatzwahl. Eine Sitzplatzreservierung wird seitens TUI Cruises nicht vorgenommen. Für Kleinkinder ist ein Sitzplatz im Bus zwingend erforderlich (gebührenfrei). Bitte beachten Sie, dass die Mitnahme einer eigenen Sitzschale notwendig ist.',
      },
      {
        q: 'Wie sind die Busse ausgestattet?',
        a:
          'Ihre Anfahrt zum Abfahrtshafen erfolgt in Reisebussen mit folgender Ausstattung: Klimaanlage mit Einzelplatzbelüftung, Leselampen an jedem Platz, Bordküche mit Kaffeemaschine, Bord-WC/Waschraum, Sicherheitsgurte an jedem Sitzplatz.',
      },
    ],
  },
];

export default faqs;
