// @flow
import React, { Component } from 'react';

import FormRadio from '../../../components/form/Radio';
import { PAYMENT_TYPE_CREDIT_CARD } from '../../../config/constants';

type OptionType = {
  type: string,
  label: string,
  icon: string,
  disabled: boolean,
};
type Props = {
  options: Object[],
  selected: ?string,
  disabled: ?boolean,
  name: string,
  onChange: string => void,
};

export default class ManifestPaymentOptions extends Component<Props> {
  isChecked(option: OptionType) {
    const { selected } = this.props;
    return selected === option.type || (selected === PAYMENT_TYPE_CREDIT_CARD && selected === option.type);
  }

  render() {
    const { options, name, onChange, disabled } = this.props;

    return (
      <div className="payment-options">
        {options.map((option: OptionType, index: number) => (
          <label
            className={`payment-options__item${option.disabled && option.disabled === true ? ' disabled' : ''}`}
            key={index}
          >
            <i className={`payment-options__image icon ${option.icon}`} />
            <span className="payment-options__label">{option.label}</span>
            <FormRadio
              name={name}
              value={option.type}
              onChange={() => onChange(option.type)}
              checked={this.isChecked(option)}
              disabled={option.disabled || disabled}
            />
          </label>
        ))}
      </div>
    );
  }
}
