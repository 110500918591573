// @flow

import React from 'react';
import styled from 'styled-components';

import UserName from './UserName';
import { dimensions } from '../styles';

import ArrowIcon16 from '../../../../assets/svg/inline/arrow-icon-16.inline.svg';
import UserIcon32 from '../../../../assets/svg/inline/user-icon-32.inline.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  @media (max-width: ${dimensions.mobileL}px) {
    display: none;
  }
`;

const Arrow = styled.div`
  transition: all 250ms;
  transform: rotateX(${p => (p.flip ? 180 : 0)}deg);
  width: 16px;
  height: 16px;
  margin: 4px 0 0 4px;

  svg {
    transform: rotateZ(90deg);
  }
`;

interface Props {
  userName?: string;
  open: boolean;
}

export default function MenuButton({ userName, open = false }: Props) {
  return (
    <Wrapper>
      <UserIcon32 />
      {userName && <UserName name={userName} />}
      <Arrow flip={open}>
        <ArrowIcon16 />
      </Arrow>
    </Wrapper>
  );
}
