// @flow
import * as React from 'react';
import {
  SURVEY_NOT_YET_OPENED,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_OK,
  SURVEY_MPI_CONFIRMATION_REQUIRED,
} from '../../config/constants';

import Tile from './Tile';

import type VipPartyMemberType from '../../stores/FlightPreferenceStore';

type Props = {
  travelParty: VipPartyMemberType[],
  partyMember: VipPartyMemberType,
  linkBase?: string,
  state?: string,
};

const getBadge = (partyMember: VipPartyMemberType): ?string => {
  return partyMember.reason;
};

const FlightPreferenceTile = ({ travelParty, partyMember, state }: Props) => {
  const index = travelParty.indexOf(partyMember);
  if (index === -1) return null;
  const disabled =
    partyMember.status === SURVEY_NOT_YET_OPENED ||
    partyMember.tile.active === false;
  const url = `/buchungsdetails/vip-tarif/1/vip-gaeste/${index}`;
  const icon =
    state && (state === SURVEY_MPI_NOT_OK || state === SURVEY_MPI_OK)
      ? 'flight-questionaire-done'
      : state !== SURVEY_MPI_CONFIRMATION_REQUIRED
        ? 'flight-questionaire'
        : 'icon-tile-flight-confirm';

  return (
    <Tile
      link={url}
      title="Flugwünsche"
      icon={icon}
      badge={getBadge(partyMember)}
      disabled={disabled}
    />
  );
};

export default FlightPreferenceTile;
