// @flow

export default class SpaCategory {
  imageUri: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get imageSrc(): string {
    return this.imageUri;
  }
}
