// @flow
import * as React from 'react';

import validators, { validateWith, validateWithAny } from '../../../utils/forms/validators';

import Headline from '../../../components/Headline';
import LabeledInputBlock from '../../../components/LabeledInputBlock';
import Form from '../../../components/form/Form';
import FormInput from '../../../components/form/Input';
import CheckboxGroupItem from '../../../components/CheckboxGroupItem';
import Button from '../../../components/Button';
import FAQTeaser from '../../../components/CheckIn/FAQTeaser';
import ContentBox from '../../../components/ContentBox';
import BenefitList from '../../../components/BenefitList';
import MembersSelect from './MembersSelect';
import { MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN } from '../../../config/constants';

import type CheckInRequest from '../../../models/CheckInRequest';
import type PartyMember from '../../../models/PartyMember';
import type MasterData from '../../../models/MasterData';
import type { TimeSloteStore, TimeSloteBookingType } from '../../../stores/TimeSloteStore';

type NotReadyToCheckInType = {
  reason: string,
  reasonText: string,
  travelParty: PartyMember[],
};
type Props = {
  checkInRequest: CheckInRequest,
  membersReadyToCheckIn: PartyMember[],
  membersNotReadyToCheckIn: Object[],
  onSubmit: () => mixed,
  masterData: MasterData,
  timeSloteStore: TimeSloteStore,
};

const validationRules = {
  email: [
    validateWith(validators.isRequired, 'Bitte geben Sie Ihre E-Mail-Adresse an.'),
    validateWith(validators.isEmail, 'Bitte geben Sie Ihre E-Mail-Adresse an.'),
  ],
  mobile: [
    validateWithAny(
      [[validators.isLength, { min: 8 }], (x) => x === '0049'],
      'Bitte geben Sie eine gültige Mobilnummer an.'
    ),
    validateWith(
      validators.isCheckInPhone,
      {
        onlyDigits: true,
        international: true,
      },
      'Bitte geben Sie eine gültige Mobilnummer an.'
    ),
  ],

  members: [
    (members) => {
      if (!members.length) {
        return {
          ok: false,
          error: 'Bitte wählen Sie mind. eine Person aus, welche Sie einchecken möchten.',
        };
      }
      if (members.some((member) => member.dontCheck === true)) {
        return { ok: true };
      }
      /* TUICUNIT-3368: Check-in for Groups (@_@): need sometimes is this flag also for vip not set ¯\_:(_/¯ */
      if (members.some((member) => member.dontCheck === undefined)) {
        return { ok: true };
      }

      // TUICUNIT-1993
      const checkTimeSlote = members.every((m) => {
        if (m.timeSlote && m.timeSlote.state) {
          return true;
        } else if (m.arrivalAt) {
          return true;
        } else {
          return false;
        }
      });

      if (checkTimeSlote === false) {
        return {
          ok: false,
          error: 'Bitte wählen Sie ein Check-in Zeitraum aus.',
        };
      }

      if (members.some((member) => member.arrivalAt && (!member.arrivalAt.where || !member.arrivalAt.time))) {
        return {
          ok: false,
          error: 'Bitte geben Sie für jede ausgewählte Person den Ankunftsort und die Ankunftszeit an.',
        };
      }
      return { ok: true };
    },
  ],
};

const getPersonSelectDescription = (
  boardingTime: ?string,
  timeSlotsIsLoading: boolean,
  hasTimeSlots: boolean
): string => {
  const hint =
    'Bitte wählen Sie aus, welche Teilnehmer Sie einchecken möchten und geben Sie für eine bessere Planbarkeit des Check-ins vor Ort die ungefähre Ankunftszeit aller Personen an.';
  const boardingTextFallback =
    'Tipp: Die frühestmögliche Zeit für den Check-in an Bord entnehmen Sie bitte Ihren Reiseunterlagen.';
  const boardingText = 'Die frühestmögliche Zeit für den Check-in an Bord ist um [time] Uhr';

  return `${hint}<br><br>${
    timeSlotsIsLoading || hasTimeSlots
      ? ''
      : boardingTime
      ? boardingText.replace('[time]', boardingTime)
      : boardingTextFallback
  }`;
};

const getCheckInLabel = (bookingList: TimeSloteBookingType, partyMember: PartyMember) => {
  let result = null;

  if (bookingList) {
    const find = bookingList.find((item) => item.mpi === partyMember.mpi);
    if (find) {
      result = <p style={{ marginBottom: 12 }}>{find.label}</p>;
    }
  }

  if (!result && partyMember.arrivalAtDisplay) {
    result = <p style={{ marginBottom: 12 }}>{partyMember.arrivalAtDisplay}</p>;
  }

  return result;
};

const CheckInProcessForm = ({
  checkInRequest,
  membersReadyToCheckIn,
  membersNotReadyToCheckIn,
  onSubmit,
  masterData,
  timeSloteStore,
}: Props) => {
  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  checkInRequest.setGroupBooking(masterData.isGroupBooking);

  return (
    <div>
      <Headline title="Online Check-in" />
      <div className="l-row" style={{ marginBottom: 60 }}>
        <div className="l-col double l-mod-sub">
          <p className="l-mod">
            Damit Ihr Wohlfühlurlaub noch entspannter beginnt, checken Sie hier bereits vor der Reise ein.
          </p>
          <FAQTeaser enabled />
        </div>
        <div className="l-col right">
          <ContentBox title="Vorteile des Online Check-ins">
            <BenefitList
              benefits={[
                'Bis 0 Uhr am Tag Ihres Kreuzfahrtbeginns möglich',
                'Bequem von zu Hause',
                'Individuelle Benachrichtigung bei Freigabe Ihrer Kabine',
                "Schiffspass zum selbst ausdrucken oder bequem auf's Smartphone",
              ]}
            />
          </ContentBox>
        </div>
      </div>
      <Form onSubmit={onSubmit} validationRules={validationRules}>
        <LabeledInputBlock
          icon="check-in"
          title="Kontaktdaten"
          description="Teilen Sie uns Ihre Kontaktdaten mit, an die wir Ihnen nach erfolgreichem Online Check-in den Schiffspass sowie weitere Informationen schicken sollen."
        >
          <LabeledInputBlock.Column>
            <FormInput
              mandatory
              name="email"
              label="E-Mail"
              onChange={checkInRequest.changeEmail}
              value={checkInRequest.formData.email}
            />
            <p className="annotation">
              Bitte geben Sie Ihre E-Mail-Adresse an, an die wir Ihnen nach dem Online Check-in den Schiffspass zusenden
              sollen.
            </p>
          </LabeledInputBlock.Column>
          <LabeledInputBlock.Column>
            <FormInput
              trim
              name="mobile"
              label="Mobilnummer"
              onChange={checkInRequest.changeMobile}
              value={checkInRequest.formData.mobile}
              placeholder="0049"
            />
            <p className="annotation">
              Bitte geben Sie Ihre Mobilnummer im Format 0049170123456 ohne Leer- und Sonderzeichen an.
            </p>
            <p className="annotation">
              Falls Sie am Tag der Anreise kostenlos per SMS über die Verfügbarkeit Ihrer Kabine informiert werden
              wollen, geben Sie hier optional Ihre Mobilfunknummer an.
            </p>
          </LabeledInputBlock.Column>
        </LabeledInputBlock>
        <hr />
        <LabeledInputBlock
          icon="people"
          title="Personenauswahl"
          description={getPersonSelectDescription(
            masterData.earliestBoardingTime,
            timeSloteStore.isLoading,
            timeSloteStore.hasTimeSlots
          )}
        >
          <LabeledInputBlock.Column>
            <MembersSelect
              masterData={masterData}
              name="members"
              members={membersReadyToCheckIn}
              selectedMembers={checkInRequest.formData.selectedMembers.slice()}
              onChange={checkInRequest.selectMembers}
            />
          </LabeledInputBlock.Column>
          <LabeledInputBlock.Column>
            {membersNotReadyToCheckIn.map((notReady: NotReadyToCheckInType, index: number) => (
              <div key={index}>
                <p>{notReady.reasonText}</p>
                {notReady.travelParty.map((partyMember) => (
                  <div key={partyMember.mpi}>
                    <CheckboxGroupItem value={partyMember.mpi} disabled>
                      {partyMember.displayName}
                    </CheckboxGroupItem>
                    {notReady.reason === MASTERDATA_STATUS_CHECKIN_ALREADY_CHECKED_IN &&
                    !masterData.isGroupBookingBlocked ? (
                      <span>{getCheckInLabel(timeSloteStore.getBookingList, partyMember)}</span>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </LabeledInputBlock.Column>
        </LabeledInputBlock>
        <div className="l-right">
          <Button big dark type="submit">
            Daten überprüfen
          </Button>
        </div>
        <p className="annotation">*Pflichtfelder</p>
      </Form>
    </div>
  );
};

export default CheckInProcessForm;
