// @flow

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import type ItineraryDay from '../models/ItineraryDay';
import { isLocal, mockAll } from './utils';

const ENDPOINT = '/api/land/excursion';

const mock = false;
const getListUrl = (
  itineraryDay: ItineraryDay,
  masterData: MasterData
): string => {
  if ((mock || mockAll) && isLocal) {
    return `/mock/excursion/TUICUNIT-1577/excursion-port.json`;
  }

  const isTransferDay = itineraryDay.isArrival || itineraryDay.isDeparture;

  // see TUICMRL-1149
  const endpoint = isTransferDay
    ? ENDPOINT
    : `${ENDPOINT}/overview/${masterData.ed}/${masterData.dd}/${
        masterData.shipId
      }`;

  return `${endpoint}?portId=${itineraryDay.locationId}&date=${
    itineraryDay.date
  }`;
};

const getDetailUrl = (excursionId: number | string) => {
  const mockThis = false;
  return (mockThis || mockAll) && isLocal
    ? `/mock/excursion/TUICUNIT-3573.json`
    : `${ENDPOINT}/detail/${excursionId}`;
};

export const sanitizers = {
  getList: data => camelCaseKeys(data, true),
  getDetails: data => camelCaseKeys(data, true),
};

export default sanitized(
  {
    getList: (user: User, itineraryDay: ItineraryDay, masterData: MasterData) =>
      fetchForUser(user, getListUrl(itineraryDay, masterData)),
    getDetails: (user: User, excursionId: number | string) =>
      fetchForUser(user, getDetailUrl(excursionId)),
  },
  sanitizers
);
