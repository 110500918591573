// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import type ExcursionOperator from '../models/ExcursionOperator';
import { isLocal, mockAll } from './utils';

export interface IIncludedShoreExListItem {
  masterExcursionId: number;
  name: string;
  description?: string;
  descriptionHtml?: string;
  images: $ReadOnlyArray<{
    ['url' | 'image']: string,
    comment?: string,
  }>;
  badge?: string;
  operators: Object[];
  port: IIncludedShoreExPort;
  type: 'inclusiveMasterExcursion';
  isBooked?: boolean;
  +displayOperator?: ?ExcursionOperator;
  _displayOperator?: ?ExcursionOperator;
  operatorClassName?: string;
}

export interface IIncludedShoreExPort {
  type: 'port';
  portId: number;
  name: string;
  description: string;
}

export interface IIncludedShoreExListing {
  type: string;
  headline: string;
  description: string;
  list: IIncludedShoreExListItem[];
}

export interface IIncludedShoreExDetail {
  id: number;
  bookingCode: string;
  name: string;
  headline?: string;
  description?: string;
  descriptionHtml?: string;
  notes?: string;
  difficulty?: 'CHALLENGING' | 'EASY' | 'EXTRA_EASY' | 'MODERATE' | 'SPORTIVE';
  difficultyDisplay: string;
  difficultyDescription: string;
  duration: string;
  catering: string;
  operators: Object[]; // TODO this can be a more specific interface
  // TODO images section should be a reusable interface part
  images: $ReadOnlyArray<{
    ['url' | 'image']: string,
    comment?: string,
  }>;
  type?: string;
  +displayOperator?: ?ExcursionOperator;
  _displayOperator?: ?ExcursionOperator;
  +operatorClassName?: string;
  +difficultyNumber: Function;
  generalWalkingInfo: ?string;
}

export interface IInclusiveBookingStatus {
  type?: string;
  masterExcursionId: number;
  status: string;
  statusText: string;
}

export interface IBookingOptionConflict {
  bookingOptionId: string;
  conflict: string;
  conflictText: string;
  type: string;
}

export interface IBookingOptionConflictList {
  type: string;
  list: IBookingOptionConflict[];
}

export interface IMpiReservationStatus {
  type?: string | any;
  mpi: number;
  bookingOptionId: string;
  status: string;
  statusText: number;
  bookingOptionConflictList: IBookingOptionConflictList;
}

export interface IBookingOption {
  type?: string;
  excursionId: number;
  bookingOptionId: string;
  date: string;
  time: string;
  vacancyCount: number;
}

export interface IInclusiveBookingStatusList {
  type?: string;
  list: IInclusiveBookingStatus[];
}

export interface ILegalNotice {
  type: string;
  note: string;
  legalNoteId: string;
}

export interface IInclusiveBookingDetailStatus {
  mpiBookingList: {
    type?: string,
    list: IMpiReservationStatus[],
  };
  inclusiveBooking: IInclusiveBookingStatus;
  bookingOptionList: {
    type?: string,
    list: IBookingOption[],
  };
  legalNoteList: {
    type: string,
    list: ILegalNotice[],
  };
}

export interface IMpiBookingUpdateRequest {
  type?: string;
  mpi: number;
  bookingOptionId: string;
}

export interface IInclusiveBookingUpdateRequest {
  type?: string;
  list: IMpiBookingUpdateRequest[];
}

export interface IMpiBookingUpdateResponse {
  type?: string;
  result: string;
  mpi: number;
}

export interface IInclusiveBookingUpdateResponse {
  type?: string;
  list: IMpiBookingUpdateResponse[];
}

export type ErrorResponse = {
  error: string,
};

const ENDPOINT = '/api/booking/v1/excursions/inclusive';

const getListingUrl = (masterData: MasterData): string => {
  const { edFull, ddFull, shipId } = masterData;
  const params = `?embarkationDate=${edFull}&debarkationDate=${ddFull}&shipId=${shipId}`;
  const mock = false;

  return (mock || mockAll) && isLocal
    ? `/mock/includedShoreEx/Artmann/listing.json`
    : `${ENDPOINT}${params}`;
};
const getDetailsUrl = (masterExcursionId: number): string => {
  const mock = false;

  return (mock || mockAll) && isLocal
    ? `/mock/includedShoreEx/Artmann/detail-${masterExcursionId}.json`
    : `${ENDPOINT}/${masterExcursionId}`;
};
const getListingBookingStatusUrl = (): string => {
  const mock = false;

  return (mock || mockAll) && isLocal
    ? `/mock/includedShoreEx/Artmann/listing-status.json`
    : `${ENDPOINT}/booking`;
};
const getBookingStatusUrl = (masterExcursionId: number): string => {
  const mock = false;

  return (mock || mockAll) && isLocal
    ? `/mock/includedShoreEx/Artmann/status-${masterExcursionId}.json`
    : `${ENDPOINT}/booking/${masterExcursionId}`;
};

/**
 *  https://confluence.cellular.de/pages/viewpage.action?pageId=57311236
 *  https://tuic-dev-shore-booking.intranet.cellular.de/swagger-ui/index.html
 * */
export default {
  getListing: (user: User, masterData: MasterData) => {
    return fetchForUser(user, getListingUrl(masterData));
  },
  getDetails: (user: User, masterExcursionId: number) => {
    return fetchForUser(user, getDetailsUrl(masterExcursionId));
  },
  getListingStatus: (user: User) => {
    return fetchForUser(user, getListingBookingStatusUrl());
  },
  getDetailStatus: (user: User, masterExcursionId: number) => {
    return fetchForUser(user, getBookingStatusUrl(masterExcursionId));
  },
  setBookingStatus: (
    user: User,
    masterExcursionId: number,
    data: IInclusiveBookingUpdateRequest
  ) => {
    return fetchForUser(user, getBookingStatusUrl(masterExcursionId), {
      method: 'post',
      body: JSON.stringify(data),
    });
  },
};

export type ApiType = {
  getListing: (User, MasterData) => Promise<?IIncludedShoreExListing>,
  getDetails: (User, number) => Promise<?IIncludedShoreExDetail>,
  getListingStatus: User => Promise<?IInclusiveBookingStatusList>,
  getDetailStatus: (User, number) => Promise<?IInclusiveBookingDetailStatus>,
  setBookingStatus: (
    User,
    number,
    IInclusiveBookingUpdateRequest
  ) => Promise<void>, // TODO use IInclusiveBookingUpdateResponse | ErrorResponse
};
