// @flow
export const jsLocalDateTime = (date: string): Date => {
  const timezone_offset_min = new Date(date).getTimezoneOffset();
  let offset_hrs = parseInt(Math.abs(timezone_offset_min / 60));
  let offset_min = Math.abs(timezone_offset_min % 60);
  let timezone_standard = '';

  if (offset_hrs < 10) {
    offset_hrs = '0' + offset_hrs;
  }

  if (offset_min < 10) {
    offset_min = '0' + offset_min;
  }
  // getTimezoneOffset returns an offset which is positive if the local timezone is behind UTC and vice-versa.
  // So add an opposite sign to the offset
  // If offset is 0, it means timezone is UTC
  if (timezone_offset_min < 0) {
    timezone_standard = '+' + offset_hrs + ':' + offset_min;
  } else if (timezone_offset_min > 0) {
    timezone_standard = '-' + offset_hrs + ':' + offset_min;
  } else if (timezone_offset_min == 0) {
    timezone_standard = 'Z';
  }

  // Timezone difference in hours and minutes
  // String such as +05:30 or -06:00 or Z
  return new Date(`${date}${timezone_standard}`);
};
