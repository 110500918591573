// @flow
import React from 'react';
import AccordionSingleMode from '../AccordionSingleMode';
import LinkAsButton from '../../components/LinkAsButton';

// TUICUNIT-2027
const PortalIntroCheckin = () => (
  <div className="portal-intro pin">
    <div>Im Meine Reise Portal planen Sie Ihren Wohlfühlurlaub bequem von zu Hause aus.</div>
    <br />
    <div className="icon-headline">
      <span className="icon info" />
      <h2>Check-in: Ab sofort kontaktlos!</h2>
    </div>
    <p>
      <span className="bold">
        Der Online Check-in ist ab sofort verpflichtend. Bitte beachten Sie, dass die Kachel für den Online Check-in
        ggf. erst kurzfristig und bis 0 Uhr am Tag Ihres Kreuzfahrtbeginns verfügbar ist.
      </span>
      <br />
      <br />
      Alle benötigten Dokumente und Informationen werden so bereits erfasst. Mit den neu eingeführten Abläufen und
      Maßnahmen ermöglichen wir Ihnen einen kontaktlosen Check-in.
    </p>
    <AccordionSingleMode
      items={[
        {
          header: 'Wichtig zu wissen',
          content: (
            <p>
              Es wird festgelegte Zeitfenster für Ihren Check-in geben, um die Anzahl der Personen zu begrenzen, die
              sich gleichzeitig im Hafenterminal aufhalten. Bitte haben Sie Verständnis, dass der Zutritt zum
              Hafenterminal außerhalb dieses Zeitraums nicht möglich ist.
            </p>
          ),
        },
        {
          header: 'Individuell anreisende Gäste',
          content: (
            <p>
              Wählen Sie einfach online im Meine Reise Bereich ein verfügbares Zeitfenster aus. Sofern Sie keinen
              Zeitraum auswählen, wird Ihnen unmittelbar vor Reisebeginn automatisch ein Zeitfenster zugewiesen.
            </p>
          ),
        },
        {
          header: 'Gäste mit einem TUI Cruises Anreisepaket (Bus oder Flug)',
          content: (
            <p>
              Allen Gästen mit einer über TUI Cruises gebuchten Anreise (Bus oder Flug) steht die gesamte Check-in Zeit
              zur Verfügung - ohne festgelegtes Zeitfenster. Gleiches gilt für unsere Suiten und Junior Suiten Gäste
              sowie für die gebuchte VIP Tarifoption.
            </p>
          ),
        },
        {
          header: 'Weitere Informationen',
          content: (
            <span>
              <p>
                Bei verspäteter Anreise ermöglichen wir Ihnen das nächstmögliche Check-in Zeitfenster. In diesem Fall
                kann es jedoch zu längeren Wartezeiten außerhalb des Hafenterminals kommen. Gerne bieten wir Ihnen in
                den deutschen Häfen unsere Shuttlebusse vom Bahnhof zum Hafenterminal an: Die Busse fahren
                kontinuierlich ab einer halben Stunde vor Beginn Ihres festgelegten Check-in Zeitfensters.
              </p>
              <p className="l-right">
                <LinkAsButton link="http://www.meinschiff.com/faq/an-und-abreise" newPage>
                  Weitere Informationen
                </LinkAsButton>
              </p>
            </span>
          ),
        },
      ]}
    />
  </div>
);

export default PortalIntroCheckin;
