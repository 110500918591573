// @flow
import type MasterData from '../models/MasterData';
import type Booking from '../models/Booking';

export default {
  urlForBooking: (masterData: MasterData, booking: Booking) =>
    fetch(
      `/api/land/myday/image/${booking.typeId}/${booking.type}/${
        masterData.ed
      }/${masterData.dd}/${masterData.shipId}`
    )
      .then(response => response.json())
      .then(json => json.imageUrl)
      .catch(() => ''),
};
