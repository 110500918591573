// @flow
import { observable, action, computed } from 'mobx';
import formatDate from 'date-fns/format';
import union from 'lodash/union';
import without from 'lodash/without';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import PaymentInfo, { type PaymentData } from '../models/PaymentInfo';
import PapagenaResponse from '../models/PapagenaResponse';

import { obfuscateCreditCardNumber } from '../utils/payment';
import { b64EncodeUnicode } from '../utils/base64';
import type PartyMember from '../models/PartyMember';

import {
  PAYMENT_OPTION_SEPA,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_MASTER_CARD,
  PAYMENT_OPTION_AMERICAN_EXPRESS,
  PAYMENT_OPTION_CASH,
  PAYMENT_TYPE_CREDIT_CARD,
} from '../config/constants';
const POSSIBLE_OPTIONS = [
  PAYMENT_OPTION_SEPA,
  PAYMENT_OPTION_VISA,
  PAYMENT_OPTION_MASTER_CARD,
  PAYMENT_OPTION_AMERICAN_EXPRESS,
  PAYMENT_OPTION_CASH,
];

// Those values are needed to preselect the right card in Papagena Popup
const PAPAGENA_CREDITCARD_MAPPING = {
  [PAYMENT_OPTION_VISA]: 'VISA',
  [PAYMENT_OPTION_MASTER_CARD]: 'MasterCard',
  [PAYMENT_OPTION_AMERICAN_EXPRESS]: 'AMEX',
};

// cellular backend response of "/land/payment/configuration"
type CellularBEConfig = {
  haendlerPAP: string,
  creditCardType: string, // => Type
  orderDesc?: string,
};

type ConfigDataType = CellularBEConfig & {
  UD?: string,
  billToCustomer: {
    consumer: {
      firstName: string,
      lastName: string,
      birthDate?: string,
    },
    email?: string,
  },
  billingAddress?: {
    city?: string,
    country?: {
      countryA3: string,
    },
    addressLine1?: {
      street: string,
    },
    postalCode?: string,
  },
};

// papagena request config
type PapagenaConfig = {
  haendlerPAP: string,
  Type: string,
  billToCustomer: string,
  billingAddress?: string,
  UD?: string,
  OrderDesc?: string,
};

type CreditCardInfo = {
  displayCustomer: string,
  displayCardNumber: string,
  displayValidUntilMonth: string,
  displayValidUntilYear: string,
};

type Params = {
  info: ?PaymentInfo,
  debarkDate: number,
  config: ConfigDataType,
};

export default class PaymentStoreRequest {
  member: PartyMember;
  travelParty: PartyMember[];
  debarkDate: number;

  @observable hasChanged = false;
  @observable selectedOption = null;
  @observable sepaAgreement = false;
  @observable ccResponse: ?PapagenaResponse = null;
  @observable payForOthers = true;
  @observable info: ?PaymentInfo = null;
  @observable payForMpis = [];
  @observable paysForPossibleMpis = [];
  @observable digitalInvoiceEnabled = false;
  @observable digitalInvoiceMail = '';
  @observable digitalInvoiceConsent = false;
  @observable additionalRequestData = {};
  @observable
  config: PapagenaConfig = {
    haendlerPAP: '',
    Type: '',
    billToCustomer: '',
    billingAddress: null,
  };

  @observable sepaValidationData = {};
  @observable transId = null;

  @observable notAgreedToSepaError = false;
  @observable error = null;
  @observable digitalInvoiceError = null;
  @observable isDone = false;
  @observable isSending = false;
  @observable reenterPaying = false;

  constructor(member: PartyMember, travelParty: PartyMember[], { config, debarkDate, info }: Params) {
    this.member = member;
    this.travelParty = travelParty;
    this.debarkDate = debarkDate;
    info = info || new PaymentInfo(this.member.mpi, null);
    this.setPaymentInfo(info, false);
    if (config) this.setConfig(config);
    this.createNewTransactionId(false);

    if (info.data.digitalInvoiceEnabled) {
      this.digitalInvoiceEnabled = info.data.digitalInvoiceEnabled;
    }
    if (info.data.digitalInvoiceConsent) {
      this.digitalInvoiceConsent = info.data.digitalInvoiceConsent;
    }
    if (info.data.digitalInvoiceEmail) {
      this.digitalInvoiceMail = info.data.digitalInvoiceEmail;
    }
  }

  @action
  setHasChanged(changed: boolean) {
    this.hasChanged = changed;
  }

  @action
  startRequest() {
    this.isSending = true;
    this.cleanError();
  }

  @action
  receiveError(err: string) {
    this.isSending = false;
    this.setError(err);
  }

  receiveDigitalInvoiceError(err: string) {
    this.isSending = false;
    this.setDigitalInvoiceError(err);
  }

  @action
  setDone() {
    this.isDone = true;
    this.isSending = false;
  }

  @action
  setConfig(config: ConfigDataType) {
    this.config = {
      haendlerPAP: config.haendlerPAP,
      Type: config.creditCardType,
      billToCustomer: b64EncodeUnicode(JSON.stringify(config.billToCustomer)),
      billingAddress: config.billingAddress ? b64EncodeUnicode(JSON.stringify(config.billingAddress)) : null,
      OrderDesc: config.orderDesc,
    };
  }

  @action
  setPaymentInfo(info: ?PaymentInfo, hasChanged: ?boolean) {
    this.info = info;
    if (!info) {
      return;
    }
    this.paysForPossibleMpis = info.paysForPossibleMpis;

    if (info.paysForOthers) {
      // If the user already has said he pays for someone, we reactivate that field
      this.payForMpis = info.paysFor;

      this.payForOthers = true;
    }
    if (info.paymentType === 'SEPA') {
      this.additionalRequestData.iban = info.paymentData && info.paymentData.iban ? info.paymentData : '';
      this.sepaAgreement = true;
    }

    this.setHasChanged(hasChanged !== false);
  }

  @action
  changeOption(option: string) {
    if (!POSSIBLE_OPTIONS.includes(option)) {
      throw new Error(`Unsupported payment option: ${option}`);
    }

    this.selectedOption = option;
    this.setHasChanged(true);
    this.cleanError();
  }

  @action
  toggleSepaAgreement(value: boolean) {
    this.sepaAgreement = value;
    this.setHasChanged(true);
  }

  @action
  receivePapagenaResponse(response: Object) {
    this.cleanError();
    if (this.isCreditCard) {
      this.ccResponse = new PapagenaResponse(response, this.member);
      if (!this.ccResponse.isSuccess) {
        if (!this.ccResponse) return;
        this.setError(this.ccResponse.errorMessage);
      }
      if (
        this.ccResponse.displayValidUntilYear != '' &&
        this.ccResponse.displayValidUntilMonth != '' &&
        !this.validateCreditCardDate(this.ccResponse.displayValidUntilYear, this.ccResponse.displayValidUntilMonth)
      ) {
        this.setError(
          'Die Kreditkarte muss im Folgemonat des Reiseendes gültig sein. Bitte aktualisieren Sie die Karteninformationen mit gültigen Daten.'
        );
        this.ccResponse = null;
      }
    }
  }

  @action
  receiveSEPAValidationResponse(data: Object) {
    if (this.isSepa) {
      this.sepaValidationData = data;
    }
  }

  @action
  saveRequestData(key: string, value: any) {
    this.additionalRequestData[key] = value;
    this.setHasChanged(true);
  }

  @action
  removeCreditCardInfo() {
    this.ccResponse = null;
    this.setPaymentInfo(null);
  }

  validateCreditCardDate(year: string, month: string) {
    const validUntil = new Date(parseInt(year), parseInt(month) - 1);
    let mustBeValidUntil = new Date(this.debarkDate + 7 * 24 * 60 * 60 * 1000);
    mustBeValidUntil = new Date(mustBeValidUntil.getFullYear(), mustBeValidUntil.getMonth());
    return validUntil >= mustBeValidUntil;
  }

  @action
  validateData(): any {
    this.notAgreedToSepaError = false;
    return new Promise(
      action((resolve, reject) => {
        if (this.isSepa) {
          this.notAgreedToSepaError = !this.sepaAgreement;
          if (this.notAgreedToSepaError) {
            reject();
          } else {
            resolve();
          }
        } else if (this.isCreditCard) {
          // We should either have a token because the response already was sent, or previously submitted values
          if (this.ccResponse) {
            if (this.ccResponse.isSuccess) {
              resolve();
            } else {
              reject('papagena error');
            }
          } else if (!this.paymentData || this.paymentData.type !== PAYMENT_TYPE_CREDIT_CARD) {
            reject('error: ccResponse falsy');
          } else {
            resolve();
          }
        } else if (this.isCash) {
          resolve();
        } else {
          reject('error: payment method not supported');
        }
      })
    );
  }

  @action
  reenterPayingInfo() {
    this.reenterPaying = true;
    this.setHasChanged(true);
  }

  @computed
  get hasCreditCardInfo(): boolean {
    return this.ccResponse !== null || !!this.paymentData;
  }

  @computed
  get creditCardInfo(): ?(PapagenaResponse | CreditCardInfo) {
    if (!this.hasCreditCardInfo) return null;
    if (this.ccResponse) {
      return this.ccResponse;
    }

    if (!this.paymentData || (this.paymentData.type && this.paymentData.type.toLowerCase() === 'cash')) {
      return null;
    }

    const validUntil = this.paymentData.ccExpirationDate ? new Date(this.paymentData.ccExpirationDate) : null;

    return {
      displayCustomer: this.customerName || '',
      displayCardNumber: obfuscateCreditCardNumber(this.paymentData.ccNumber || ''),
      displayValidUntilMonth: validUntil ? `${validUntil.getMonth() + 1}` : '',
      displayValidUntilYear: validUntil ? `${validUntil.getFullYear()}` : '',
    };
  }

  @computed
  get paymentData(): ?PaymentData {
    return this.info && this.info.paymentData;
  }

  @computed
  get hasOwnPayment(): boolean {
    return !!(this.info && this.info.isSelfPayer);
  }

  @computed
  get isPayedBySomeone(): boolean {
    return !!(this.info && this.info.isPayedBySomeone);
  }

  @computed
  get payer(): ?PartyMember {
    return this.isPayedBySomeone
      ? this.travelParty.find((member: PartyMember) => (this.info ? member.mpi === this.info.payerMpi : false))
      : null;
  }

  @computed
  get hasEnteredCreditCard(): boolean {
    return (
      !!(this.ccResponse && this.ccResponse.isSuccess) ||
      (this.paymentData && this.paymentData.type === PAYMENT_TYPE_CREDIT_CARD) ||
      false
    );
  }

  @computed
  get alreadyPaid(): boolean {
    return !!(this.info && this.info.isPaid);
  }

  @computed
  get showPayingForm(): boolean {
    return !this.isPayedBySomeone || this.reenterPaying;
  }

  @computed
  get customerName(): string {
    return this.member.displayName;
  }

  @computed
  get requestData(): ?Object {
    const mpis = [this.member.mpi, ...(this.payForOthers ? this.payForMpis.slice() : [])];
    if (this.isCash) {
      return {
        type: 'Cash',
        mpis,
      };
    }

    const data = {
      mpis,
      digitalInvoiceConsent: this.digitalInvoiceConsent,
      digitalInvoiceEmail: this.digitalInvoiceMail,
    };

    if (this.isCreditCard) {
      return {
        type: 'CreditCard',
        ...(this.ccResponse ? this.ccResponse.data : {}),
        ...(!this.ccResponse && this.paymentData
          ? {
              ...omit(this.paymentData, ['type', 'displayName']),
              ccExpirationDate:
                this.paymentData && this.paymentData.ccExpirationDate
                  ? formatDate(this.paymentData.ccExpirationDate, 'YYYYMM')
                  : '',
              ccCustomer: this.customerName,
            }
          : {}),
        ...data,
      };
    }
    if (this.isSepa) {
      return {
        type: 'SEPA',
        ...data,
        ...this.additionalRequestData,
        ...pick(this.sepaValidationData, ['mandate', 'token']),
        sepaAgreement: this.sepaAgreement,
        customer: this.customerName,
      };
    }
    return null;
  }

  // Data that is needed to make the request to Papagena
  @computed
  get creditCardPopupData(): Object {
    if (!this.isCreditCard) return {};

    // TUICUNIT-2432: remove empty billingAddress
    if (!this.config.billingAddress) {
      delete this.config.billingAddress;
    }

    return {
      ...this.config,
      TransID: this.transId,
      CCSelect: PAPAGENA_CREDITCARD_MAPPING[this.selectedOptionOrDefault],
      Customer: this.customerName,
    };
  }

  @action
  createNewTransactionId(hasChanged: ?boolean) {
    this.transId = `trans${new Date().getTime()}`;
    this.setHasChanged(hasChanged !== false);
  }

  @computed
  get selectedOptionOrDefault(): string {
    return this.selectedOption || (this.info && this.info.paymentType ? this.info.paymentType : '');
  }

  @computed
  get isSepa(): boolean {
    return this.selectedOptionOrDefault === PAYMENT_OPTION_SEPA;
  }

  @computed
  get isCash(): boolean {
    return this.selectedOptionOrDefault === PAYMENT_OPTION_CASH;
  }

  @computed
  get isCreditCard(): boolean {
    return (
      this.selectedOptionOrDefault === PAYMENT_OPTION_VISA ||
      this.selectedOptionOrDefault === PAYMENT_OPTION_MASTER_CARD ||
      this.selectedOptionOrDefault === PAYMENT_OPTION_AMERICAN_EXPRESS
    );
  }

  @computed
  get isReadyToSave(): boolean {
    return (
      !!this.selectedOptionOrDefault &&
      (this.isSepa || this.isCash || this.hasEnteredCreditCard) &&
      (!this.payForOthers || this.payForMpis.length > 0)
    );
  }

  get otherTravelPartyMember(): PartyMember[] {
    return this.travelParty.filter((m) => m.mpi !== this.member.mpi);
  }

  @action
  cleanError() {
    this.error = null;
    this.digitalInvoiceError = null;
  }

  @action
  setError(err: ?string) {
    this.error = err;
  }

  @action
  setDigitalInvoiceError(err: ?string) {
    this.digitalInvoiceError = err;
  }

  @action
  togglePayForOthers(value: boolean) {
    this.payForOthers = value;
    if (!value) this.payForMpis = [];
    this.setHasChanged(true);
  }

  @action
  payForMember(mpi: number, value: boolean) {
    this.payForMpis = value ? union(this.payForMpis.slice(), [mpi]) : without(this.payForMpis.slice(), mpi);
    this.setHasChanged(true);
  }

  @action
  setDigitalInvoiceMail(email: string) {
    this.digitalInvoiceMail = email;
    this.setHasChanged(true);
  }

  @action
  setDigitalInvoiceConsent(value: boolean) {
    this.digitalInvoiceConsent = value;
    this.setHasChanged(true);
  }
}
