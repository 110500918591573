const getKeyCode = e => {
  if (e.key !== undefined) {
    return e.key;
  }

  if (e.keyIdentifier !== undefined) {
    return e.keyIdentifier;
  }

  if (e.keyCode !== undefined) {
    return e.keyCode;
  }
};

const isEnterKey = e => {
  const code = getKeyCode(e);
  return code === 13 || code === 'Enter';
};

const isDeleteKey = e => {
  const code = getKeyCode(e);
  return code === 8 || code === 'Backspace';
};

export { getKeyCode, isEnterKey, isDeleteKey };
