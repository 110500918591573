// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import Accordion from '../../../Accordion';
import AccordionItem from '../../../AccordionItem';
import FlightInfoItem from './FlightInfoItem';

import type ArrivalDepartureItem from '../../../../models/ArrivalDeparture/ArrivalDepartureItem';

type Props = {
  item: ArrivalDepartureItem,
  expanded: boolean,
};

export default class Flight extends Component<Props> {
  get header(): Node {
    const { item } = this.props;

    return (
      <div>
        <div
          className={classNames({
            'arrival-departure-item__date': true,
            empty: !item.date && !item.departureDate,
          })}
        >
          {item.date || item.departureDate || ' - '}
        </div>
        <div className="arrival-departure-item__title">
          <span
            dangerouslySetInnerHTML={{ __html: item.fromName || '&nbsp;' }}
          />
          <i className="icon take-off" />
        </div>
        {item.toName && (
          <div className="arrival-departure-service__time time">
            {item.isPublishedFlight &&
              item.departureTime && (
                <span className="bold">{item.departureTime} </span>
              )}
            Abflug nach{' '}
            <span dangerouslySetInnerHTML={{ __html: item.toName }} />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { item, expanded } = this.props;

    return (
      <div className="arrival-departure-item services flight">
        {item.isValidFlight ? (
          <Accordion>
            <AccordionItem header={this.header} expanded={expanded}>
              <FlightInfoItem flight={item} />
            </AccordionItem>
          </Accordion>
        ) : (
          this.header
        )}
        <div
          className="common-hint"
          dangerouslySetInnerHTML={{ __html: item.hint }}
        />
      </div>
    );
  }
}
