// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import { bookingRequestShape } from '../../utils/PropTypes';

import ItineraryDay from '../../models/ItineraryDay';

import Headline from '../Headline';
import LinkAsButton from '../LinkAsButton';
import BlockList from '../lists/BlockList';
import Button from '../Button';

@inject('authStore')
@observer
export default class BookingConfirmationSuccess extends Component {
  static propTypes = {
    day: PropTypes.instanceOf(ItineraryDay),
    bookingRequest: bookingRequestShape.isRequired,
  };

  get title() {
    return this.props.bookingRequest.getText('success', 'title');
  }

  get text() {
    return this.props.bookingRequest.getText('success', 'text');
  }

  // TUICUNIT-3782
  @autobind
  onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  render() {
    const { day, bookingRequest } = this.props;
    const { bookable } = bookingRequest;

    return (
      <div className="booking-confirmation">
        <Headline title={this.title} subtitle={day ? day.subtitleLine : null} />

        <h2>{bookable.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: this.text }} />
        <BlockList>
          {bookingRequest.itemsToBook.map((item) => (
            <BlockList.Item key={item.id} readOnly>
              {item.name}
            </BlockList.Item>
          ))}
        </BlockList>
        {bookingRequest.getOnFirstDay ? (
          <p>Die erste Zeitung bekommen Sie am Anreisetag, sofern sie an diesem Tag erscheint.</p>
        ) : null}
        {bookingRequest.getOnLastDay ? (
          <p>Die letzte Zeitung bekommen Sie am Abreisetag, sofern sie an diesem Tag erscheint.</p>
        ) : null}

        <div className="booking-confirmation__buttons">
          <p>
            {this.props.authStore.user && this.props.authStore.user.backLink ? (
              <Button dark onClick={this.onLogoutClick}>
                Zur Startseite
              </Button>
            ) : (
              <LinkAsButton link="/" dark>
                Zur Startseite
              </LinkAsButton>
            )}
          </p>
        </div>
      </div>
    );
  }
}
