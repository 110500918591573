// @flow

import React, { Component, type Node } from 'react';
import { inject } from 'mobx-react';
import classNames from 'classnames';
import Accordion from '../../../Accordion';
import AccordionItem from '../../../AccordionItem';
import TrainChangeView from './Change';
import LoadingIndicator from '../../../LoadingIndicator';
import DB from '../../../../../assets/svg/inline/db.inline.svg';

import type ArrivalDepartureItem from '../../../../models/ArrivalDeparture/ArrivalDepartureItem';
import type ArrivalDepartureStore from '../../../../stores/ArrivalDepartureStore';

type Props = {
  item: ArrivalDepartureItem,
  arrivalDepartureStore: ArrivalDepartureStore,
  expanded: boolean,
};

type State = {
  ticketIsLoading: Boolean,
};

@inject('arrivalDepartureStore')
export default class Train extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { ticketIsLoading: false };
  }

  get header(): Node {
    const { item } = this.props;

    return (
      <div>
        <div
          className={classNames({
            'arrival-departure-item__date': true,
            empty: !item.departureDate,
          })}
        >
          {item.departureDate || ' - '}
        </div>
        <div className="arrival-departure-item__title">
          {item.title}
          <i className="icon train" />
        </div>
        {item.departureTime && (
          <div className="arrival-departure-service__time time">
            <span className="bold">{item.departureTime}</span> Abfahrt
          </div>
        )}
      </div>
    );
  }

  render() {
    const { item, expanded } = this.props;
    return (
      <span>
        {item.isTrain ? (
          <div>
            <div className="arrival-departure-item services train">
              <Accordion>
                <AccordionItem header={this.header} expanded={expanded}>
                  {item.arrivalTime && (
                    <div className="arrival-departure-service__time time arrival">
                      <span className="bold">{item.arrivalTime}</span> Ankunft
                    </div>
                  )}
                  <div className="train-info-case">
                    <div className="top-part">
                      <div className="svg">
                        <DB viewBox="0 0 114 68" />
                      </div>
                      <div className={`${item.children === 0 ? 'only' : ''}`}>
                        Fahrgäste<br />
                        <span className="bold">Erwachsene: {item.adults}</span>
                        {item.children !== 0 ? (
                          <div className="child-small bold">
                            Kinder: {item.children}
                          </div>
                        ) : null}
                      </div>
                      {item.children !== 0 ? (
                        <div className="child-default">
                          <br />
                          <span className="bold">Kinder: {item.children}</span>
                        </div>
                      ) : null}
                    </div>
                    <div className="divider triangles" />
                    <div className="bottom-part-default">
                      {item.trainNumber ? (
                        <div>
                          Zugnummer<br />
                          <span className="bold">{item.trainNumber}</span>
                        </div>
                      ) : null}
                      {item.class ? (
                        <div className="class">
                          Klasse<br />
                          <span className="bold">{item.class}</span>
                        </div>
                      ) : null}
                      {item.orderNumber ? (
                        <div>
                          <span className="db-n-td">DB Auftragsnummer</span>
                          <span className="db-n-m">DB Auftragsnr.</span>
                          <br />
                          <span className="bold">{item.orderNumber}</span>
                        </div>
                      ) : null}
                    </div>

                    <div className="bottom-part-to-small">
                      {item.trainNumber ? (
                        <div>
                          Zugnummer:{' '}
                          <span className="bold">{item.trainNumber}</span>
                        </div>
                      ) : null}
                      {item.class ? (
                        <div>
                          Klasse: <span className="bold">{item.class}</span>
                        </div>
                      ) : null}
                      {item.orderNumber ? (
                        <div>
                          DB Auftragsnr.{' '}
                          <span className="bold">{item.orderNumber}</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="seat-info">
                    <div className="head">Sitzplatzreservierung</div>
                    Ihre gewählten Sitzplätze sind auf Ihrem Ticket vermerkt.
                    Sollten Sie bereits Änderungen über die Deutsche Bahn
                    vorgenommen haben, können diese nicht auf Ihrem Ticket
                    dargestellt werden. Es gilt daher immer die aktuelle
                    Bestätigung der DB. Bitte führen Sie Ihre
                    Reservierungsbestätigung mit sich.
                  </div>
                </AccordionItem>
              </Accordion>
              {item.filename ? (
                <div className="ticket-wrapper">
                  {this.state.ticketIsLoading ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className="ticket"
                      onClick={() => {
                        this.setState({ ticketIsLoading: true });
                        this.props.arrivalDepartureStore
                          .getTrainTicket(item.filename)
                          .then(data => {
                            var link = document.createElement('a');
                            link.href = data;
                            link.download = `${item.orderNumber || 'zug'}.pdf`;
                            link.click();
                            setTimeout(() => {
                              // For Firefox it is necessary to delay revoking the ObjectURL
                              window.URL.revokeObjectURL(data);
                              this.setState({ ticketIsLoading: false });
                            }, 100);

                            // window.open(data);
                          });
                      }}
                    >
                      <i className="icon pdf" />
                      <span>Ticket herunterladen</span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>

            {item.trainChanges.map((part, index) => {
              return <TrainChangeView key={index} item={part} />;
            })}
          </div>
        ) : (
          this.header
        )}
      </span>
    );
  }
}
