// @flow

import SpaOffer from '../SpaOffer';
import MasterStore from '../../stores/MasterStore';

export default class SpaListItem extends SpaOffer {
  imageRelative: string;

  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);
    this.imageRelative = data.imageRelative || '';
  }

  get isBooked(): boolean {
    return this.statusCode === 6;
  }

  get isPreliminary(): boolean {
    return this.statusCode === 7;
  }
}
