//@flow
import React from 'react';

type Props = {
  error?: string | boolean,
};

export default class ErrorMessage extends React.Component<Props> {
  render() {
    const { error } = this.props;

    return error ? <p className="error-message--form">{error}</p> : null;
  }
}
