// TODO: add flow types

import { observable, action } from 'mobx';

export default class ImageStore {
  masterStore = null;
  api = null;
  imageRequests = {};
  @observable images = [];

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  // @private
  fetchImage(booking) {
    if (!this.imageRequests[booking.typeId]) {
      this.imageRequests[booking.typeId] = this.api.urlForBooking(
        this.masterStore.masterData,
        booking
      );
    }
    return this.imageRequests[booking.typeId];
  }

  fetchImages(bookings) {
    Promise.all(bookings.map(booking => this.fetchImage(booking))).then(
      action(loadedImages => {
        bookings.forEach((booking, index) => {
          this.images.push({
            typeId: booking.typeId,
            url: loadedImages[index],
          });
        });
      })
    );
  }

  imageFor(typeId) {
    const image = this.images.find(img => img.typeId === typeId);
    return image ? image.url : null;
  }
}
