// @flow
const DEFAULT_ERROR_MESSAGE =
  'Der Check-in ist zur Zeit nicht möglich. Bitte versuchen Sie es später erneut.';

import type { ApiType } from '../api/checkIn';
import type MasterStore from '../stores/MasterStore';
import type CheckInRequest from '../models/CheckInRequest';

export default class CheckInStore {
  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  handleCheckInRequest(checkInRequest: CheckInRequest) {
    checkInRequest.markAsSending();
    return this.api.save(this.masterStore.user, checkInRequest).then(
      response => {
        if (
          !response ||
          (response.errors && Object.keys(response.errors).length)
        ) {
          checkInRequest.markAsErrornous(DEFAULT_ERROR_MESSAGE);
        }
        const checkedInMembers = response
          ? response.data.filter(m => m.isCheckedIn).map(m => m.mpi)
          : [];
        checkInRequest.markAsSaved(checkedInMembers);
      },
      () => {
        checkInRequest.markAsErrornous(DEFAULT_ERROR_MESSAGE);
      }
    );
  }
}
