// @flow

import React, { Component } from 'react';
import Description from './Description';
import classNames from 'classnames';

type Props = {
  showParticipants?: boolean,
  items: Object[],
  totalText: string,
  emptyText?: string,
  showAmountOverride?: boolean,
};

export default class BookingTable extends Component<Props> {
  render() {
    const {
      showParticipants,
      items,
      totalText,
      emptyText,
      showAmountOverride,
    } = this.props;

    return (
      <div className="l-block">
        <div className="booking-table additional-services l-mod">
          <div className="booking-table__header">
            <div className="booking-table__row">
              <div className="booking-table__cell">Termin</div>
              {showParticipants && (
                <div className="booking-table__cell">Teilnehmer</div>
              )}
              <div className="booking-table__cell">Beschreibung</div>
              <div className="booking-table__cell booking-table__price">
                Preis
              </div>
            </div>
          </div>
          {emptyText &&
            items.length === 0 && (
              <p className="booking-table__empty-text">{emptyText}</p>
            )}
          {items.map((item, index) => {
            const showPrices = item.prices && item.prices.length > 0;
            const showAmount =
              showAmountOverride ||
              item.showAmount === true ||
              item.showAmount === undefined ||
              item.showAmount;
            const makeBold = item.name === 'Bordguthaben';
            return (
              <div key={index} className="booking-table__body">
                <div
                  className={classNames({
                    'booking-table__row': true,
                    'no-border': showPrices,
                    bold: makeBold,
                  })}
                >
                  {item.options &&
                  item.options[0] &&
                  item.options[0] === 'hide_date' ? (
                    <div className="booking-table__cell booking-table__name">
                      &nbsp;
                    </div>
                  ) : (
                    <div className="booking-table__cell booking-table__name">
                      {item.date}
                    </div>
                  )}

                  {showParticipants && (
                    <div className="booking-table__cell booking-table__name">
                      {item.participants
                        .map(p => p.displayNameShortend)
                        .join(', ')}
                    </div>
                  )}

                  <div className="booking-table__cell booking-table__name">
                    <Description item={item} />
                  </div>

                  {!showPrices && (
                    <div className="booking-table__cell booking-table__price">
                      {showAmount ? item.displayAmount : ''}
                    </div>
                  )}
                </div>
                {showPrices &&
                  item.prices.map((p, i) => {
                    const lastItem = i === item.prices.length - 1;
                    return (
                      <div
                        key={i}
                        className={classNames({
                          'booking-table__row': true,
                          'no-border': !lastItem,
                        })}
                      >
                        <div className="booking-table__cell empty" />
                        <div className="booking-table__cell booking-table__name discount">
                          <div
                            className={classNames({
                              big: lastItem,
                            })}
                          >
                            {p.label}
                          </div>
                        </div>
                        <div className="booking-table__cell booking-table__price align-bottom discount">
                          <div
                            className={classNames({
                              big: lastItem,
                            })}
                          >
                            {p.amountText}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
          <div className="booking-table__footer">
            <div className="booking-table__row">
              <div className="booking-table__cell empty" />
              {showParticipants && (
                <div className="booking-table__cell empty" />
              )}
              <div className="booking-table__cell empty" />
              <div className="booking-table__cell booking-table__price big">
                {totalText}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
