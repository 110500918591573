// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { kebabCase } from '../../utils/kebabCase';

import preload from '../../components/pages/decorators/preload';

import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import Headline from '../Headline';
import HeroImage from '../HeroImage';
import InfoBox from '../InfoBox';
import LinkAsButton from '../LinkAsButton';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import SpaBookingAssistant from '../BookingAssistant/SpaBookingAssistant';
import BookingList from '../BookingList/SpaOffer';
import BulletList from '../lists/BulletList';
import DayChooser from '../DayChooser';

import ItineraryDayModel from '../../models/ItineraryDay';
import SpaBookingRequest from '../../models/BookingRequest/SpaBookingRequest';
import SpaOffer from '../../models/SpaOffer';

import type MasterStore from '../../stores/MasterStore';
import type OfferStore from '../../stores/OfferStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type Booking from '../../models/Booking';
import { tealiumDateFromString, tealiumIdFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';
import isInAppView from '../../utils/isInAppView';

@preload({ details: 'Beauty' }, false)
@observer
class SpaDetailsBreadcrumb extends Component<{
  details: SpaOffer,
}> {
  render() {
    const { details } = this.props;
    return <BreadcrumbLink {...this.props} text={details.headline} />;
  }
}

type Props = {
  masterStore: MasterStore,
  offerStore: OfferStore,
  bookingUiStore: BookingUiStore,
  details: SpaOffer,
  day: ?ItineraryDayModel,
  params: { packageId: string },
};

@inject('masterStore', 'offerStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ details: 'Beauty' })
@preload({ day: 'ItineraryDay' }, false, true)
@observer
export default class PageSpaDetails extends Component<Props> {
  static breadcrumb = SpaDetailsBreadcrumb;

  bookingRequest: SpaBookingRequest;

  constructor(props: Props) {
    super(props);

    const { details, masterStore, day } = this.props;
    this.bookingRequest = new SpaBookingRequest(masterStore.masterData.travelParty, details);
    if (day) {
      this.bookingRequest.chooseDay(day);
    }
  }

  @computed get bookings(): Booking[] {
    // @TODO: check if this method is really needed. Smells funny
    const { masterStore, params } = this.props;
    if (!this.bookingRequest || !this.bookingRequest.selectedDay) {
      return [];
    }
    return masterStore.masterData
      .getBookings(this.bookingRequest.selectedDay.date)
      .filter((booking) => booking.typeId === parseInt(params.packageId, 10));
  }

  @autobind openAssitantWithDay(day: ItineraryDayModel) {
    this.bookingRequest.chooseDay(day);
    this.triggerTracking();
    window.scrollTo(0, 0);
  }

  @autobind handleBookingCancelClick(booking: Booking) {
    this.bookingRequest.cancelBooking(booking);
    this.props.bookingUiStore.openBookingDialog();
  }

  componentDidMount() {
    //console.log('PRODUCT VIEW');

    // Page View Tracking
    const titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles.length) {
      tracking.pageView(window.location.pathname, titles, 'product');
    }
  }

  triggerTracking() {
    tracking.viewItem(this.props.breadcrumbRouterStore, {
      category: this.props.details.analyticsCategory,
      categoryId: tealiumIdFromString(this.props.details.analyticsCategory),
      discount: '0.00',
      code: String(this.props.details.spaId),
      name: String(this.props.details.title),
      operator: {
        code: 'intern',
        name: 'intern-meinschiff',
      },
      quantity: '1',
      startDate: tealiumDateFromString(this.bookingRequest.selectedDay.date),
      value: tealiumValue(String(this.props.details.price)),
    });
  }

  render() {
    const { details, masterStore, bookingUiStore } = this.props;
    const { masterData } = masterStore;

    // @todo: Only trigger on the right views.
    // @todo: Add request page view tracking ('reservierungsanfrage')
    // @todo: Add confirmation page view tracking ('bestätigung')
    tracking.pageView(
      window.location.pathname,
      'schoenheit-gesundheit|spa-meer|' + kebabCase(details.category) + '|' + kebabCase(details.headline)
    );

    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        <div className="page page-internet">
          <Headline title={details.headline} />

          <HeroImage src={details.imageSrc} alt="" className="l-mod-sub" />

          <div className="l-row">
            <div className="l-col double">
              <BookingList
                header="Bereits reservierte Termine für diese Leistung:"
                bookings={this.bookings}
                masterData={masterData}
                onCancelBooking={this.handleBookingCancelClick}
              />
              <p>{details.description}</p>
              {details.hint ? (
                <p>
                  <strong>Besonderer Hinweis:</strong> {details.hint}
                </p>
              ) : null}
              {details.minimumAge ? (
                <p>
                  <strong>Mindestalter:</strong> {details.minimumAge} Jahre
                </p>
              ) : null}
              {details.pdfLink ? (
                <InfoBox noIcon>
                  <BulletList
                    title="Wichtiges vor der Anwendung"
                    bullets={[
                      'Lesen Sie sich das PDF-Dokument aufmerksam durch',
                      'Am Anreisetag bestätigen Sie uns bitte, dass die aufgeführten Kontraindikationen bei Ihnen nicht zutreffen und Sie die Anwendung durchführen können',
                      'Sollten eine oder mehrere Kontraindikationen Ihrer Wunschanwendung zutreffen, bitten wir Sie die Anwendung selbstständig zu stornieren und eine Alternativanwendung zu reservieren; wir beraten Sie auch gern an Bord, um Ihre individuellen Bedürfnisse zu berücksichtigen',
                      'Es gelten die allgemeinen SPA & Sport Stornierungsbedingungen',
                    ]}
                  />

                  <LinkAsButton dark asAnchor={isInAppView} newPage={!isInAppView} link={details.pdfLink}>
                    PDF herunterladen
                  </LinkAsButton>
                </InfoBox>
              ) : null}
            </div>
            <div className="l-col right l-right">
              {this.bookingRequest ? (
                <SpaBookingAssistant
                  bookable={details}
                  travelParty={masterData.travelParty}
                  bookingRequest={this.bookingRequest}
                  onBooking={() => {
                    tracking.addToCart(this.bookingRequest.selectedMpis.length, this.bookingRequest.bookable.price);
                    this.bookingRequest.reset();
                    bookingUiStore.openBookingDialog();
                  }}
                />
              ) : null}
            </div>
          </div>

          {!this.bookingRequest || !this.bookingRequest.selectedDay ? (
            <DayChooser
              model={details}
              title="Bitte wählen Sie den Tag aus, an dem Sie die Anwendung reservieren möchten:"
              onSelect={this.openAssitantWithDay}
            />
          ) : null}
        </div>
      </WithBookingConfirmation>
    );
  }
}
