// @flow
import { observable, action } from 'mobx';
import omit from 'lodash/omit';
import values from 'lodash/values';

import PartyMember from '../models/PartyMember';
import logger from '../utils/logger';

const stripErrorKeysFromResponse = errors =>
  Object.keys(errors).reduce(
    (m, k) => ({
      ...m,
      [k]: errors[k].error
        ? [errors[k].error]
        : Object.keys(errors[k]).reduce(
            (memo, key) => ({
              ...memo,
              [key]:
                typeof errors[k][key] === 'object'
                  ? values(errors[k][key])
                  : errors[key],
            }),
            {}
          ),
    }),
    {}
  );

export default class ManifestData {
  @observable isSending = false;
  @observable isSend = false;
  @observable isNotReady = true;
  @observable saveError: ?Object;
  @observable undefinedSaveError = false;
  @observable data: Object = {};
  options: Object;
  user: PartyMember;

  constructor(
    {
      manifestData,
      manifestOptions,
    }: { manifestData: Object, manifestOptions: Object },
    user: PartyMember
  ) {
    this.data = manifestData;
    this.options = manifestOptions;
    this.user = user;
  }

  @action
  updateData(newManifestData: Object) {
    this.data = newManifestData;
  }

  @action
  _copyData(type: string, optionId: string, keysToOmit: string[] = []) {
    const obj = this.options[type];
    if (obj && obj.copyData && obj.copyData[optionId]) {
      this.data = {
        ...this.data,
        [type]: {
          ...omit(obj.copyData[optionId], keysToOmit),
        },
      };
    }
  }

  copyContactDataFrom(contactOptionId: string, keysToOmit: string[] = []) {
    this._copyData('contact', contactOptionId, keysToOmit);
  }

  @action
  markAsSending() {
    this.isSending = true;
    this.saveError = null;
    this.undefinedSaveError = false;
  }

  @action
  makeReady() {
    this.isNotReady = false;
  }

  @action
  markAsSend() {
    this.isSending = false;
    this.isSend = true;
  }

  @action
  receiveError(error: Object) {
    const { status, response } = error;

    this.isSending = false;
    // If title is set, it is that custom error response
    // if it is not set, it might contain error messages for each manifest field
    this.undefinedSaveError =
      !!response.title || status === 502 || status === 409;

    if (this.undefinedSaveError) {
      logger.error(
        'An undefined error came back while sending manifest form',
        response,
        this.data
      );
    }
    this.saveError = this.undefinedSaveError
      ? null
      : stripErrorKeysFromResponse(response);
  }
}
