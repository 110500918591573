// @flow

import React from 'react';
import styled from 'styled-components';

import { type NavigationItemList } from '../../api/navigation';
import authApi from '../../api/authentication';
import { logoutRedirect } from '../../utils/url';
import LogoutIcon24 from '../../../assets/svg/inline/logout-icon-24.inline.svg';
import { colors, dimensions, textLink } from './styles';

import config from '../../config';

const TYPE_LOGOUT = 'logout';

const Logout = styled.button`
  ${textLink};
  color: ${colors.darkBlue};
  padding: 0;
  margin: 20px 20px 20px 18px;
  display: flex;
  align-items: center;

  span {
    margin-left: 4px;
  }

  :first-of-type {
    margin: 20px 20px 20px 18px; // do not remove!
  }

  @media (max-width: ${dimensions.mobileL}px) {
    margin: 50px 0 50px -2px;

    :first-of-type {
      margin: 50px 0 50px -2px; // do not remove!
    }
  }
`;

interface Props {
  model: NavigationItemList;
}

export default function NavEntries({ model }: Props) {
  if (!model || !model.items) return null;

  if (model.items.length <= 0) {
    // logout fallback
    return (
      <Logout
        className="logout"
        onClick={() => {
          authApi
            .signOut()
            .then(() => {
              logoutRedirect();
            })
            .catch(() => {
              logoutRedirect();
            });
        }}
      >
        <LogoutIcon24 />
        <span>Abmelden</span>
      </Logout>
    );
  }

  return model.items
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .map((nav, i) => {
      return nav.type !== TYPE_LOGOUT ? (
        <a key={i} href={`${config.account}${nav.url}`} className="nav-entry">
          {nav.name}
        </a>
      ) : (
        <Logout
          key={i}
          className="logout"
          onClick={() => {
            authApi.signOut().then(() => {
              logoutRedirect(nav.url);
            });
          }}
        >
          <LogoutIcon24 />
          <span>{nav.name}</span>
        </Logout>
      );
    });
}
