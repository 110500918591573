// @flow
import React from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import Price from '../Price';
import Duration from '../Duration';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import FormSelect from '../form/Select';
import MultiSelect from '../form/MultiSelect';
import VacancyBookingAssistant from './VacancyBookingAssistant';

import type SpaBookingRequest from '../../models/BookingRequest/SpaBookingRequest';
import type VacancyStore from '../../stores/VacancyStore';
import type SpaOffer from '../../models/SpaOffer';

type TravelParty = {
  mpi: number,
  displayName: string,
};

type Props = {
  travelParty: TravelParty[],
  bookable: SpaOffer,
  bookingRequest: SpaBookingRequest,
  vacancyStore: VacancyStore,
  onCancel: ?Function,
  onBooking: ?Function,
};

/**
 * Booking Assistant to buy all Spa Offers
 */
@inject('vacancyStore')
@observer
export default class SpaBookingAssistant extends VacancyBookingAssistant<Props> {
  get vacancyType(): string {
    return 'spa';
  }

  @autobind
  handleSelectParticipant(mpi: string) {
    this.props.bookingRequest.chooseParticipant(mpi ? parseInt(mpi, 10) : null);
  }

  @autobind
  handleChangeInParty(mpi: string, value: boolean) {
    this.props.bookingRequest.selectParticipants(parseInt(mpi, 10), value);
  }

  @autobind
  handleTimeSlotSelection(timeSlot: string) {
    this.props.bookingRequest.chooseTime(timeSlot);
  }

  @autobind
  changedCheckbox(value: boolean) {
    this.props.bookingRequest.setAcceptation(value);
  }

  @autobind
  handleBookingClick() {
    const { onBooking, bookingRequest } = this.props;

    if (onBooking) onBooking(bookingRequest);
  }

  @autobind
  handleCancelClick() {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  }

  @autobind
  handleBackClick() {
    this.props.bookingRequest.removeDay();
  }

  renderBaseInfo() {
    const { bookable, bookingRequest } = this.props;

    return (
      <div className="l-right l-mod-sub">
        {bookingRequest.selectedDay ? (
          <Price price={bookable.priceOnDay(bookingRequest.selectedDay)} label="Gesamtpreis" />
        ) : bookable.showBothPrices ? (
          <div>
            <Price price={bookable.pricePort} label="Preis Hafentag" />
            <Price price={bookable.priceSea} label="Preis Seetag" />
          </div>
        ) : (
          <Price price={bookable.smallestPrice} label="Kosten" />
        )}
        <Duration duration={bookable.duration} big />
      </div>
    );
  }

  renderParticipantChooser() {
    const { bookingRequest, bookable } = this.props;
    const options = bookingRequest.travelParty.map((member) => ({
      value: member.mpi,
      label: member.displayName,
      disabled: !member.isOldEnoughFor(bookable),
    }));

    if (bookingRequest.needsMultipleParticipants) {
      return <MultiSelect options={options} value={bookingRequest.selectedMpis} onChange={this.handleChangeInParty} />;
    }

    return (
      <FormSelect
        name="participant"
        placeholder="Teilnehmer auswählen…"
        options={options}
        onChange={this.handleSelectParticipant}
        value={bookingRequest.selectedMpis.length > 0 ? bookingRequest.selectedMpis[0] : null}
      />
    );
  }

  renderTimeSlotChooser() {
    const { vacancyStore, bookingRequest } = this.props;
    if (vacancyStore.isLoading) return <LoadingIndicator />;

    const options = bookingRequest.availableTimeSlots.map((timeSlot) => ({
      value: timeSlot,
      label: timeSlot,
    }));
    return (
      <FormSelect
        name="timeslots"
        placeholder="Uhrzeit auswählen…"
        options={options}
        onChange={this.handleTimeSlotSelection}
        value={bookingRequest.selectedTime}
      />
    );
  }

  renderDayChosenView() {
    const { bookingRequest } = this.props;

    return (
      <div>
        <h2>Bitte wählen Sie Teilnehmer und Uhrzeit aus:</h2>

        <div className="l-mod-sub">
          {this.renderParticipantChooser()}
          {bookingRequest.isPersonSelected ? this.renderTimeSlotChooser() : null}
        </div>

        {this.renderBaseInfo()}

        <div className="l-right">
          <p>
            <Button dark big disabled={!bookingRequest.isReady} onClick={this.handleBookingClick}>
              Zur Reservierung
            </Button>
          </p>
          <p>
            <Button onClick={this.handleBackClick}>zurück</Button>
          </p>
        </div>
      </div>
    );
  }
}
