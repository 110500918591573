// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import LoadingIndicator from '../LoadingIndicator';
import CatalogTile from '../tiles/CatalogTile';
import InfoBox from '../InfoBox';
import InfoBoxSvg from '../InfoBoxSvg';
import ContentOfPage from '../ContentOfPage';
import WifiIcon from '../../../assets/svg/inline/wlan.inline.svg';

import type MasterStore from '../../stores/MasterStore';
import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type { TrackingExcursion } from '../../types/tracking';
import type Package from '../../models/Package';
import { tealiumDateFromDateObject, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

type Props = {
  masterStore: MasterStore,
  packageStore: PackageStore,
};

@inject('masterStore', 'packageStore', 'breadcrumbRouterStore')
@observer
export default class PageInternet extends Component<Props> {
  static breadcrumb = 'Internet an Bord';

  componentDidMount() {
    this.store.fetchList();
  }

  @computed get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('internet');
  }

  renderAllInclusive() {
    return (
      <div className="l-row">
        <div className="l-col double">
          <InfoBox>
            <h2>Kostenloser Internetzugang für Suiten und Junior Suiten Gäste</h2>
            <p>
              Unseren Gästen der Suiten und Junior Suiten steht das Internet kostenfrei an Bord zur Verfügung. Wählen
              Sie dafür im Meine Reise Bordportal den VIP-Tarif aus.
            </p>
          </InfoBox>
        </div>
      </div>
    );
  }

  triggered = false;

  renderPackageList() {
    if (!this.store || this.store.isLoading) {
      return <LoadingIndicator />;
    }

    const { items, meta, freeDataVolumeText } = this.store;
    const { headline } = meta;
    const hasItems = items && !!items.length && !items.every((i) => i.bookingStatusCode !== 0);

    if (this.triggered === false && items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.title,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div>
        <h1>{headline}</h1>
        {!!freeDataVolumeText && (
          <InfoBoxSvg
            className="wider"
            icon={<WifiIcon className="inline-svg" />}
            text={freeDataVolumeText}
            allowedTags={['i', 'u', 's']}
          />
        )}
        {hasItems ? (
          <div className="l-tiles">
            {items
              .filter((pkg) => pkg.bookingStatusCode === 0) //Filter out all items that are not Bookable
              .map((pkg, index) => (
                <div
                  key={pkg.id}
                  onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
                >
                  <CatalogTile
                    disabled={!pkg.isBookable}
                    booking={pkg.isBooked && pkg}
                    link={`/internet-an-bord/${pkg.id}`}
                    imageSrc={pkg.imageSrc}
                    title={pkg.title}
                    description={pkg.text}
                    price={pkg.priceText}
                    unit={pkg.priceLabel}
                  />
                </div>
              ))}
          </div>
        ) : (
          <InfoBox noIcon>Zur Zeit stehen keine Internettarife zur Verfügung.</InfoBox>
        )}
      </div>
    );
  }

  render() {
    const { masterData } = this.props.masterStore;

    return (
      <div className="page page-internet">
        <ContentOfPage contentFor="internet" headlineIcon="internet">
          {masterData.getsFreeInternet ? this.renderAllInclusive() : this.renderPackageList()}
        </ContentOfPage>
      </div>
    );
  }
}
