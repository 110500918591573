// @flow

import { observable, action, computed } from 'mobx';

import BoardAccount from '../models/BoardAccount/BoardAccount';

import type MasterStore from '../stores/MasterStore';
import type {
  ApiType,
  BoardAccountDetails,
  BoardAccountTravelprice,
  BoardAccountAddons,
} from '../api/boardAccount';

export default class BoardAccountStore {
  @observable boardAccount: ?BoardAccount = null;
  @observable user = null;

  masterStore: MasterStore;
  api: ApiType;
  fetchRequest: ?Promise<void> = null;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.boardAccount === null;
  }

  @action
  fetch(): Promise<void> {
    if (!this.fetchRequest) {
      this.fetchRequest = Promise.all([
        this.api.details(this.masterStore.user),
        this.api.addons(this.masterStore.user),
        this.api.travel(this.masterStore.user),
      ]).then(
        ([bookingDetails, bookingAddons, travelPrice]: [
          BoardAccountDetails,
          BoardAccountAddons,
          BoardAccountTravelprice,
        ]) => {
          this.receiveFromServer(bookingDetails, bookingAddons, travelPrice);
          this.fetchRequest = null;
        }
      );
    }
    return this.fetchRequest;
  }

  @action
  receiveFromServer(
    bookingDetails: BoardAccountDetails,
    bookingAddons: BoardAccountAddons,
    travelPrice: BoardAccountTravelprice
  ) {
    this.boardAccount = new BoardAccount(
      bookingDetails,
      bookingAddons,
      travelPrice,
      this.masterStore.masterData.travelParty
    );
  }
}
