// @flow

import React, { Component, type Node } from 'react';
import { computed } from 'mobx';
import classNames from 'classnames';

import type PartyMember from '../../models/PartyMember';
import type PriceTypes from '../../models/PriceTypes';
import { PRICE_TYPES } from '../../models/PriceTypes';
import { formatPrice } from '../../utils/price';

type Props = {
  priceTypes?: PriceTypes,
  travelParty: PartyMember[],
  isPriceBulk: boolean,
  uniqueMode: boolean,
  date?: string,
};

export default class ExcursionPriceTypes extends Component<Props> {
  @computed
  get adults(): PartyMember[] {
    const { travelParty } = this.props;
    return travelParty.filter((t) => t.isAdult);
  }

  @computed
  get minors(): PartyMember[] {
    const { travelParty, date } = this.props;
    return travelParty.filter((t) => {
      const ageOnDate = t.ageOnDate(date || '0');
      return t._isChild(ageOnDate) && !t._isBaby(ageOnDate);
    });
  }

  @computed
  get requestor(): PartyMember[] {
    const { travelParty } = this.props;
    return travelParty.filter((t) => t.requestor);
  }

  @computed
  get uniquePriceTags(): Object[] {
    const { isPriceBulk, priceTypes } = this.props;
    if (!priceTypes) return [];

    return [
      {
        label: 'Pro Erwachsenen',
        prices: !isPriceBulk ? priceTypes.getPriceInfos(PRICE_TYPES.adult, this.adults) : [],
      },
      {
        label: 'Pro Kind',
        prices: !isPriceBulk ? priceTypes.getPriceInfos(PRICE_TYPES.child, this.minors) : [],
      },
      {
        label: 'Gesamtpreis',
        prices: isPriceBulk ? priceTypes.getPriceInfos(PRICE_TYPES.bulk, this.requestor) : [],
      },
    ].filter((p) => p.prices.length > 0);
  }

  @computed
  get priceTags(): Object[] {
    const { travelParty, date, priceTypes } = this.props;
    if (!priceTypes) return [];

    return travelParty
      .map((member) => {
        const ageOnDate = member.ageOnDate(date || '0');
        const type = member._isChild(ageOnDate) ? PRICE_TYPES.child : PRICE_TYPES.adult;

        return {
          label: member.displayName,
          prices: priceTypes.getPriceInfos(type, [member]),
        };
      })
      .filter((p) => p.prices.length > 0);
  }

  @computed
  get data(): Object[] {
    const { uniqueMode } = this.props;
    return uniqueMode ? this.uniquePriceTags : this.priceTags;
  }

  renderPriceType(p: Object, key: number): Node {
    return (
      <div key={key} className="price-type">
        {p.original && p.original !== p.userPrice && (
          <div className="row">
            <div className="text">{p.originalLabel}</div>
            <div>{formatPrice(p.original, true)}</div>
          </div>
        )}
        {p.refund && (
          <div className="row">
            <div className="text">{p.refundText}</div>
            <div>{formatPrice(-1 * p.refund, true)}</div>
          </div>
        )}
        {p.userPrice &&
          p.priceLabels.map((label, i) => (
            <div key={i} className="row big">
              <div className="text">{label}</div>
              <div>{formatPrice(p.userPrice, true)}</div>
            </div>
          ))}
      </div>
    );
  }

  render() {
    const { uniqueMode } = this.props;
    return (
      <div className="l-mod-sub">
        {this.data.map((d, i) => (
          <div key={i} className="price-types">
            <div
              className={classNames({
                label: true,
                small: !uniqueMode,
              })}
            >
              {d.label}
            </div>
            {d.prices.map((p, j) => this.renderPriceType(p, j))}
          </div>
        ))}
      </div>
    );
  }
}
