// @flow
import fetchForUser from '../utils/fetchForUser';
import { snakeCaseKeys } from '../utils/hash';

import User from '../models/User';
import MasterData from '../models/MasterData';

import * as bookingTypes from '../types/booking';

const ENDPOINT = `/api/land/bookings/combined`;

const transformResultItem = result => ({
  bookingId: result.booking_id,
  message: result.display_reason,
  hint: result.hint,
  mpis: result.mpis,
});

type IBookingRequestData = {
  toBook?: bookingTypes.IBookingData[],
  toCancel?: bookingTypes.IBookingCancelationData[],
};

export default {
  save: (
    user: User,
    masterData: MasterData,
    { toBook, toCancel }: IBookingRequestData
  ) =>
    fetchForUser(user, ENDPOINT, {
      method: 'post',
      body: JSON.stringify({
        bookings: (toBook || []).map(booking => {
          // TUICUNIT-1678 tourSalesTermsId must be camelCase
          const tourSalesTermsId = booking.tourSalesTermsId;
          const parsed = snakeCaseKeys(booking);

          if (tourSalesTermsId) {
            parsed.tourSalesTermsId = tourSalesTermsId;
            delete parsed.tour_sales_terms_id;
          }

          return parsed;
        }),
        cancellations: (toCancel || []).map(snakeCaseKeys),
      }),
    })
      .then(response => ({
        bookings: response.bookings || {},
        cancellations: response.cancellations || {},
      }))
      .then(({ bookings, cancellations }) => ({
        failed: [
          ...(bookings.failed ? bookings.failed.data : []),
          ...(cancellations.failed ? cancellations.failed.data : []),
        ].map(transformResultItem),
        succeeded: [
          ...(bookings.succeeded ? bookings.succeeded.data : []),
          ...(cancellations.succeeded ? cancellations.succeeded.data : []),
        ].map(transformResultItem),
      })),
};

// export const mockApi = {
//   save: () =>
//     new Promise(function(resolve) {
//       resolve({
//         failed: [],
//         succeeded: [],
//       });
//     }),
// };
