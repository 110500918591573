// @flow
import * as React from 'react';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';

import Badge from '../../Badge';
import TileIcon from './Icon';
import TileText from './Text';
import TileImage from './Image';
import BonusIcon from '../../../../assets/svg/inline/angebote.inline.svg';
import tracking from '../../../tracking';

export type Props = {
  title?: ?string,
  text?: ?string,
  link?: ?string,
  linkIsExternal?: ?boolean,
  onClick?: ?() => mixed,
  badge?: ?string,
  full?: ?boolean,
  noPadding?: ?boolean,
  imageSrc?: ?string,
  smallImageSrc?: ?string,
  icon?: ?string,
  disabled?: ?boolean,
  isBooked?: ?boolean,
  lineClamp?: ?number,
  bonus?: string | null,
  isCheckin?: ?boolean,
  isHighlight?: ?boolean,
};

type BonusProps = {
  text: string,
};

const Bonus = ({ text }: BonusProps) => (
  <div className="tile__bonus">
    <BonusIcon />
    {text}
  </div>
);

const TileContainer = ({ children }) => <div className="tile__details">{children}</div>;

const Tile = ({
  title,
  text,
  link,
  linkIsExternal,
  onClick,
  badge,
  full,
  noPadding,
  imageSrc,
  smallImageSrc,
  icon,
  disabled,
  isBooked,
  lineClamp,
  bonus,
  isCheckin,
  isHighlight,
}: Props) => {
  const className = classNames({
    tile: true,
    'has-badge': badge,
    'no-padding': noPadding,
    full,
    disabled,
    'is-checkin': isCheckin,
    'is-highlight': isHighlight,
  });

  if (link || onClick) {
    return (
      <Link
        to={link || '#'}
        target={linkIsExternal ? '_blank' : ''}
        className={className}
        onClick={(e) => {
          if (title) {
            tracking.click('content.tile.' + title);
          } else {
            tracking.click('content.tile');
          }
          if (onClick) {
            onClick(e);
          }
        }}
      >
        {imageSrc && <TileImage src={imageSrc} smallSrc={smallImageSrc} />}
        {icon && <TileIcon full={full} type={icon} />}
        {title && (
          <TileContainer>
            <TileText full={full} hasIcon={!!icon} lineClamp={lineClamp}>
              {title}
            </TileText>
            {bonus && !full && <Bonus text={bonus} />}
          </TileContainer>
        )}
        {bonus && full && <Bonus text={bonus} />}
        {text && (
          <TileText small full={full} hasIcon={!!icon}>
            {text}
          </TileText>
        )}
        {badge && (
          <Badge booked={!!isBooked} unavailable={!!disabled}>
            {badge}
          </Badge>
        )}
      </Link>
    );
  }
  return (
    <div className={className} onClick={onClick}>
      {imageSrc && <TileImage src={imageSrc} smallSrc={smallImageSrc} />}
      {icon && <TileIcon full={full} type={icon} />}
      {title && (
        <div className="tile__text-container">
          <TileText full={full} hasIcon={!!icon}>
            {title}
          </TileText>
          {bonus && !full && <Bonus text={bonus} />}
        </div>
      )}
      {bonus && full && <Bonus text={bonus} />}
      {text && (
        <TileText small full={full} hasIcon={!!icon}>
          {text}
        </TileText>
      )}
      {badge && (
        <Badge booked={!!isBooked} unavailable={!!disabled}>
          {badge}
        </Badge>
      )}
    </div>
  );
};

export default Tile;
