// @flow

// 2019 October: currently not in use. Keep for future use.
import type Config from '../models/Config';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/config.json`
    : `/api/config/env/mrl/config.json`;

export default {
  get: (): Promise<Config> => fetch(getUrl()).then(response => response.json()),
};

export type ApiType = {
  get: () => Promise<Config>,
};
