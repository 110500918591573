// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (tariffId: string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/preismodell/vipOption.json`
    : `/api/package/vip/cabinOption/vipPackage${tariffId}`;

export default {
  get: (user: User, tariffId: string) => fetchForUser(user, getUrl(tariffId)),
};

export type ApiResponse = {
  type: ?string,
  headline: ?string,
  subHeadline: ?string,
  description: ?string,
};

export type ApiType = {
  get: User => Promise<ApiResponse>,
};
