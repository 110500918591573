// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type BrochureStore from '../../stores/BrochureStore';
import type MasterData from '../../models/MasterData';

type Props = {
  brochureStore?: BrochureStore,
  masterData: MasterData,
};

@inject('brochureStore')
@observer
export default class Brochure extends Component<Props> {
  componentDidMount() {
    const { masterData, brochureStore } = this.props;
    const { tripCode } = masterData;

    brochureStore && brochureStore.fetch(tripCode);
  }

  render() {
    const { masterData, brochureStore } = this.props;
    const { tripCode } = masterData;

    if (!brochureStore) {
      return null;
    }

    const { brochures } = brochureStore;
    const brochure = brochures[tripCode];

    if (!brochure || !brochure.pdfUrlPath) {
      return null;
    }

    return (
      <div className="infobox brochure">
        <div className="infobox__content">
          <h2>{brochure.headline}</h2>
          <p>{brochure.text}</p>
          <a href={`/api${brochure.pdfUrlPath}`}>
            PDF jetzt ansehen
            <i className="icon pdf" />
          </a>
        </div>
      </div>
    );
  }
}
