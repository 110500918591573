// @flow
import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import OverlayView from './Overlay';
import LockBodyScroll from '../../LockBodyScroll';

import type { DigitalHealthStore } from '../../../stores/DigitalHealthStore';

type Props = {
  className?: string,
  digitalHealthStore: DigitalHealthStore,
};

export default function InfoBox(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { className, digitalHealthStore } = props;

  const toggle = useCallback(
    e => {
      e.preventDefault();
      setIsOpen(!isOpen);
    },
    [isOpen]
  );
  return (
    <div className={className}>
      <span>
        <h2>Gesundheitsfragebogen</h2>
        <p>
          Bitte beachten Sie, dass die Übermittlung Ihrer tagesaktuellen
          Gesundheitsdaten vor dem Reiseantritt zwingend erforderlich sind.
        </p>
      </span>
      <div className="link">
        <a className="button" onClick={toggle}>
          Was bedeutet das genau?
        </a>
      </div>
      <ReactModal
        appElement={document.getElementById('app')}
        isOpen={isOpen}
        className="modal health"
        overlayClassName="modal-overlay"
        onRequestClose={toggle}
        contentLabel="Gesundheitsangaben"
      >
        <OverlayView close={toggle} digitalHealthStore={digitalHealthStore} />
      </ReactModal>
      {isOpen ? <LockBodyScroll /> : ''}
    </div>
  );
}
