// @flow

import React, { Component } from 'react';
import FormSelect from './form/Select';
import type Person from '../../../models/ArrivalDeparture/Person';

type Props = {
  persons: Person[],
  onChange: number => void,
  value: ?number,
};

export default class SelectGroup extends Component<Props> {
  render() {
    const { persons, onChange, value } = this.props;

    return (
      <div className="select-group">
        <FormSelect
          name="person-select"
          placeholder=""
          onChange={(mpi: string) => {
            onChange(parseInt(mpi, 10));
          }}
          value={value}
          options={persons.map((person: Person) => {
            return {
              value: person.mpi,
              label: person.name,
            };
          })}
        />
      </div>
    );
  }
}
