// @flow
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import type { IVacancy, ITimeSlot } from '../types/vacancies';
import type { IOption } from '../types/forms';

const createDateInstance = time => new Date(`1970-01-01T${time}`);

export default class VacancyTimeSlots {
  vacancyList: IVacancy[];
  timeSlots: ITimeSlot[];

  constructor(data: Object = {}) {
    Object.assign(
      this,
      data || {
        timeSlots: [],
      }
    );
    this.vacancyList = (data.vacancyList || []).map(vacancy => ({
      ...vacancy,
      start: createDateInstance(vacancy.startTime),
      end: createDateInstance(vacancy.endTime),
    }));
  }

  get personCountOptions(): IOption[] {
    return this.timeSlots.map(v => ({
      value: String(v.persons),
      label: String(v.persons),
    }));
  }

  get allTimeSlots(): ITimeSlot[] {
    return uniq(flatten(this.timeSlots.map(t => t.timeTable.slice()))).sort();
  }

  isTimeSlotAvailableFor(time: string, personCount: number) {
    return this.timeSlotsFor(personCount).indexOf(time) !== -1;
  }

  timeSlotsFor(personCount: number): string[] {
    const result = this.timeSlots.find(t => t.persons === personCount);
    if (!result) return [];

    return result.timeTable;
  }

  vacancyFor(personCount: number, time: string) {
    const result = this.vacancyList.find(
      vacancy =>
        vacancy.vacancies > 0 &&
        vacancy.participantMin <= personCount &&
        vacancy.participantMax >= personCount &&
        vacancy.startTime === time
    );
    return result;
  }
}
