// @flow

import { useEffect, useRef } from 'react';

export default function useClickOutside(handler: () => void) {
  const ref = useRef();
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        const { target } = event;
        // Do nothing if clicking ref's element or descendent elements
        if (!target || !ref.current || ref.current.contains(target)) {
          return;
        }
        handler();
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [ref, handler]
  ); // passing it into this hook. // but to optimize you can wrap handler in useCallback before // callback/cleanup to run every render. It's not a big deal // function on every render that will cause this effect // It's worth noting that because passed in handler is a new

  return ref;
}
