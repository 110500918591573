// @flow
import { computed } from 'mobx';

import Bookable from './Bookable';

export default class SpaOfferListDetails extends Bookable {
  spaId: string;
  title: string;

  @computed get booking() {
    return this.masterStore.bookings.find((pkg) => pkg.typeId === this.spaId);
  }
}
