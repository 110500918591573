import { useEffect, useState } from 'react';

import api from '../api/navigation';

export default function useNavigation() {
  const [navigation, setNavigation] = useState();
  useEffect(() => {
    api.fetchNavigation().then(navigation => {
      if (navigation) {
        setNavigation(navigation);
      }
    });
  }, []);

  return navigation;
}
