// @flow

import find from 'lodash/find';

import type {
  IBookingOption,
  IInclusiveBookingDetailStatus,
  IInclusiveBookingStatus,
  IMpiReservationStatus,
  ILegalNotice,
} from '../../api/includedShoreEx';

export default class IncludedShoreExDetailStatus {
  mpiBookingList: IMpiReservationStatus[];
  inclusiveBooking: IInclusiveBookingStatus;
  bookingOptionList: IBookingOption[];
  legalNoteList: ILegalNotice[];

  constructor(data: IInclusiveBookingDetailStatus) {
    this.mpiBookingList = data.mpiBookingList ? data.mpiBookingList.list : [];
    this.inclusiveBooking = data.inclusiveBooking;

    this.bookingOptionList = data.bookingOptionList
      ? data.bookingOptionList.list
      : [];

    this.legalNoteList = data.legalNoteList ? data.legalNoteList.list : [];
  }

  getVacancyDate(bookingOptionId: string): string {
    const option = find(this.bookingOptionList, { bookingOptionId });
    return option ? `${option.date}, ${option.time} Uhr` : '';
  }
}
