// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import { autobind } from 'core-decorators';

type Props = {
  children: Node,
  header: string | Node,
  expanded?: boolean,
};

type State = {
  expanded: boolean,
};

export default class AccordionItem extends Component<Props, State> {
  state = {
    expanded: false,
  };

  constructor(props: Object) {
    super(props);

    this.state.expanded = !!props.expanded;
  }

  get className(): string {
    const { expanded } = this.state;
    return `accordion-item accordion-item${expanded ? '--expanded' : ''}`;
  }

  @autobind
  toggle() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { header, children } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={classNames({
          'accordion-item': true,
          'accordion-item--expanded': expanded,
        })}
      >
        <h2 className="accordion-item__header" onClick={this.toggle}>
          {header}
        </h2>
        <div className="accordion-item__content">{children}</div>
      </div>
    );
  }
}
