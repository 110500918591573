// TODO: add flow types

import { observable, computed, action } from 'mobx';

import Highlights from '../models/Highlights/Page';

export default class HighlightStore {
  @observable loading = true;
  @observable highlights = null;
  @observable notFound = false;

  masterStore = null;
  api = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading() {
    return this.loading;
  }

  @computed
  get isNotFound() {
    return this.notFound;
  }

  @action
  fetch(id) {
    this.loading = true;
    return this.api
      .get(this.masterStore.user, this.masterStore.masterData, id)
      .then(data => {
        this.receiveData(data);
      })
      .catch(() => {
        this.setNotFound();
      });
  }

  @action
  receiveData(data) {
    this.loading = false;
    if (data) {
      this.highlights = new Highlights(data, this.masterStore.itinerary) || [];
    } else {
      this.notFound = true;
    }
  }

  @action
  setNotFound() {
    this.loading = false;
    this.notFound = true;
  }
}
