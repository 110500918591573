// @flow
import User from '../models/User';

class ResponseError {
  status: number;
  response: { error: string };

  constructor(status, json) {
    this.status = status;
    this.response = json;
  }
}

/**
 * Fetches the request using isomorphic fetch and uses the given user to
 * set authorization headers
 * @param {User} user Loggedin User to use in authorization
 * @param {string} url URL to call
 * @param {object} options Passed directly to `fetch`
 * @throws ResponseError for Requests with status code >= 400
 */
export default (user: User, url: string, options: Object = {}): Promise<*> =>
  fetch(url, {
    ...options,

    headers: {
      ...options.headers,
      Authorization: `Bearer ${user.apiToken}`,
      'Content-Type': 'application/pdf',
      cache: 'no-cache',
      //responseType: 'blob',
    },
  })
    .then(response => {
      if (response.status >= 400) {
        return json => {
          throw new ResponseError(response.status, json);
        };
      }
      return response.blob();
    })
    .then(blobby => {
      let objectUrl = window.URL.createObjectURL(blobby);
      return objectUrl;
      // window.URL.revokeObjectURL(objectUrl);
    })
    .catch(response => {
      console.log(response);
    });
