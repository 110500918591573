// @flow

import React from 'react';
import FlightInfoCase from './FlightInfoCase';
import linkReplacement from '../../../../../utils/linkReplacement';

export default class Published extends FlightInfoCase {
  render() {
    const { flight } = this.props;

    const additionalServiceText = linkReplacement(flight.additionalServiceText);

    return (
      <div className="flight-info-case">
        <div className="tablet">
          <div className="line">
            {this.airlineLogo}
            {this.flightFrom}
            {this.airplaneIcon}
            {this.flightTo}
            {this.airlineName}
          </div>
          <div className="line margin top">
            {this.flightNumber}
            {this.departureDateTime}
            <div className="item empty" />
            {this.arrivalDateTime}
            {this.flightClass}
          </div>
        </div>
        <div className="mobile">
          <div className="line">{this.airlineName}</div>
          <div className="line">
            {this.flightFrom}
            {this.airplaneIcon}
            {this.flightTo}
          </div>
          <div className="line margin">
            {this.departureDateTime}
            {this.arrivalDateTime}
          </div>
          <div className="line margin top">
            {this.flightNumber}
            {this.flightClass}
          </div>
        </div>
        {this.codeShareFlight}
        <div className="divider triangles" />
        <div className="line">
          {!flight.seat && !flight.ticketNumber && !flight.ticketFileKey ? (
            !flight.isIndividualFlight || additionalServiceText ? (
              <div className="item item-90">
                <p
                  dangerouslySetInnerHTML={{
                    __html: additionalServiceText,
                  }}
                />
              </div>
            ) : (
              <div className="item item-90">
                Sitzplatzreservierungen sind möglich über die Fluggesellschaft
                unter Angabe des Filekeys
              </div>
            )
          ) : (
            <div className="item item-90">
              {this.seat}
              {this.ticketNumber}
              {this.ticketFileKey}
            </div>
          )}
          <div className="item item-10">
            <i className="icon seat" />
          </div>
        </div>
        {flight.seat || flight.ticketNumber || flight.ticketFileKey ? (
          !flight.isIndividualFlight || additionalServiceText ? (
            <div
              className="hint"
              dangerouslySetInnerHTML={{ __html: additionalServiceText }}
            />
          ) : (
            <div className="hint">
              Sitzplatzreservierungen sind möglich über die Fluggesellschaft
              unter Angabe des Filekeys
            </div>
          )
        ) : null}

        <div className="divider" />
        <div className="line margin">
          <div className="wrapper">
            <div className="info-key">Gepäck</div>
            <div className="info-value">
              {flight.baggage || 'Nicht inkludiert'}
              {flight.isIndividualFlight && (
                <div>
                  Weitere Informationen zum Freigepäck erhalten Sie direkt von
                  der Fluggesellschaft
                </div>
              )}
            </div>
          </div>
          <div className="wrapper">
            <div className="info-key">Verpflegung</div>
            <div className="info-value">
              {flight.meal
                ? flight.meal
                : flight.isIndividualFlight
                  ? 'Informationen zur Verpflegung an Bord erhalten Sie direkt von der Fluggesellschaft'
                  : 'Nicht inkludiert'}
            </div>
          </div>
          <div className="wrapper">
            <div className="info-key">Getränke</div>
            <div className="info-value">
              {flight.drink
                ? flight.drink
                : flight.isIndividualFlight
                  ? 'Informationen zu Getränken an Bord erhalten Sie direkt von der Fluggesellschaft'
                  : 'Nicht inkludiert'}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
