// @flow

export default class Identity {
  email: string;
  firstName: ?string;
  gender: ?string;
  lastName: ?string;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
