// @flow
import React from 'react';

type RegulationsSelectProps = {
  name: string,
  value: string,
  options: [
    {
      label: string,
      value: string,
    },
  ],
  onChange: (value: string) => void,
};

export default function RegulationsSelect(props: RegulationsSelectProps) {
  const { name, value, options, onChange } = props;

  const onChangeCallback = event => {
    const { options, selectedIndex } = event.target;
    const value = options[selectedIndex].value;
    if (onChange) onChange(value);
  };

  const renderOption = option => {
    return (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    );
  };

  return (
    <div className="form-dropdown">
      <label htmlFor={name} className="form-dropdown__label">
        <select
          id={name}
          className="form-dropdown__select"
          onChange={onChangeCallback}
          value={value}
        >
          {options.length > 1
            ? options.map(option => renderOption(option))
            : null}
        </select>
      </label>
    </div>
  );
}
