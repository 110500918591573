// @flow

/**
 * TUICUNIT-911
 */
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import { action } from 'mobx';
import PinBlock from './PinBlock';
import { autobind } from 'core-decorators';
import pinStorage from '../../utils/pinStorage';
import { USER_PIN_LENGTH } from '../../config/constants';
import { routerShape } from 'react-router/lib/PropTypes';

import {
  Headline,
  InfoText,
  ErrorMessage,
  MainButtons,
  InfoHeadline,
  TogglePinButton,
  BackButton,
  PinInfoHeadline,
  PinInfoText,
  PinInfoImage,
} from './PinModalElements';

import type PartyMember from '../../models/PartyMember';
import LockBodyScroll from '../LockBodyScroll';

type Props = {
  url: ?string,
  onClose?: ?() => mixed,
  onSubmit?: ?(pin: string) => mixed,
  partyMember: ?PartyMember,
  error: ?Object,
};

type State = {
  pin: string,
  showPin: boolean,
  wrongPin: boolean,
  showWrongPinMessage: boolean,
  showInfo: boolean,
};

class PinModal extends Component<Props, State> {
  static contextTypes = {
    router: routerShape,
  };

  pinBlock: window.HTMLDivElement;

  state = {
    pin: '',
    showPin: false,
    wrongPin: false,
    showWrongPinMessage: false,
    showInfo: false,
  };

  componentDidMount() {
    const { error } = this.props;
    const wrongPin = !!error && error.pinCheckErrorCode === 3;
    this.setState({
      wrongPin,
      showWrongPinMessage: wrongPin,
    });
  }

  @autobind
  @action
  close() {
    const { onClose, partyMember } = this.props;
    if (partyMember) {
      pinStorage.removeUrl(partyMember.mpi);
    }
    onClose && onClose();
  }

  @autobind
  closeAndApply() {
    const { url, partyMember, onSubmit } = this.props;
    const { pin } = this.state;

    if (onSubmit) {
      onSubmit(pin);
      return;
    }

    if (partyMember) {
      pinStorage.setPin(partyMember.mpi, pin);
      this.context.router.replace(url);
      return;
    }

    this.context.router.replace('/404');
  }

  @autobind
  focusPinBlock() {
    this.pinBlock.focus();
  }

  @autobind
  toggleInfo() {
    const { showInfo } = this.state;
    this.setState({ showInfo: !showInfo });
  }

  @autobind
  togglePin() {
    const { showPin } = this.state;
    this.setState({ showPin: !showPin });
  }

  @autobind
  onPinChange(pin: string) {
    this.setState({
      pin,
      wrongPin: false,
    });

    if (!pin) {
      this.setState({ showPin: false });
    }
  }

  @autobind
  onPinEnter(pin: string) {
    this.setState({ pin });
    if (pin.length === USER_PIN_LENGTH) {
      this.closeAndApply();
    }
  }

  render() {
    const { partyMember, url } = this.props;
    const { pin, showPin, wrongPin, showWrongPinMessage, showInfo } =
      this.state;

    return (
      <div className="modal-wrapper pin-modal">
        <ReactModal
          appElement={document.getElementById('app')}
          isOpen={true}
          className="modal"
          overlayClassName="modal-overlay pin"
        >
          <div className="modal-content">
            {!showInfo ? (
              <div className="pin-info modal-inner-content">
                <div onClick={this.focusPinBlock}>
                  <Headline partyMember={partyMember} />
                  <InfoText />
                  <InfoHeadline />
                  <PinInfoText />
                  <div className="pin-info-container">
                    <PinInfoImage />
                  </div>
                  <div className="pin-input-container">
                    <PinBlock
                      pin={pin}
                      wrongPin={wrongPin}
                      hidden={!showPin}
                      ref={(ref) => {
                        this.pinBlock = ref;
                      }}
                      onChange={this.onPinChange}
                      onEnter={this.onPinEnter}
                    />
                    <TogglePinButton
                      disabled={!pin}
                      onClick={this.togglePin}
                      showPin={showPin}
                    />
                  </div>
                  {showWrongPinMessage && <ErrorMessage />}
                </div>
                <MainButtons
                  pin={pin}
                  onSubmit={this.closeAndApply}
                  onClose={this.close}
                  url={url}
                />
              </div>
            ) : (
              <div className="pin-info modal-inner-content">
                <PinInfoHeadline />
                <PinInfoText />
                <div className="pin-info-container">
                  <PinInfoImage />
                </div>
                <BackButton onClick={this.toggleInfo} />
              </div>
            )}
          </div>
        </ReactModal>
        <LockBodyScroll />
      </div>
    );
  }
}

export default PinModal;
