// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

const InfoBox = ({ children, noIcon, className, icon }) => (
  <div className={`infobox ${className || ''}`}>
    {noIcon ? null : (
      <div className="infobox__iconholder">
        {icon ? icon : <i className="icon info" />}
      </div>
    )}
    <div className="infobox__content">{children}</div>
  </div>
);

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noIcon: PropTypes.bool,
  icon: PropTypes.node,
};

export default InfoBox;
