// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';
import Badge from '../Badge';

type Props = {
  link: string,
  disabled?: boolean,
  children: Node,
  Icon: any,
  badge?: string | null
};

const TeaserTile = ({ children, Icon, link, disabled, badge }: Props) => (
  <Link
    to={link}
    className={classNames({
      'teaser-tile': true,
      disabled: !!disabled,
    })}
  >
    <div className="teaser-tile__icon">
      <Icon />
    </div>
    <div className="teaser-tile__text">
      <div className="teaser-tile__clamp">{children}</div>
    </div>
    {badge && (
      <Badge unavailable={!!disabled}>
        {badge}
      </Badge>
    )}
  </Link>
);

export default TeaserTile;
