// @flow

import type {
  IIncludedShoreExListItem,
  IIncludedShoreExPort,
} from '../../api/includedShoreEx';
import ExcursionOperator, { STATIC_TUIC_OPERATOR } from '../ExcursionOperator';

export default class IncludedShoreExItem implements IIncludedShoreExListItem {
  masterExcursionId: number;
  name: string;
  description: string;
  descriptionHtml: string;
  images: $ReadOnlyArray<{
    // using "image" as property name instead of "url" for compatibility with
    // components like e.g. CatalogTile
    image: string,
    comment?: string,
  }>;
  operators: Object[];
  port: IIncludedShoreExPort;
  badge: string;
  type: 'inclusiveMasterExcursion';
  _displayOperator: ?ExcursionOperator;

  constructor(data: IIncludedShoreExListItem) {
    const { images } = data;

    const adaptedData = {
      ...data,
      images: images.map(image => ({
        image: image.url,
        comment: image.comment,
      })),
    };

    Object.assign(this, { ...adaptedData });

    this.setOperator();
  }

  setOperator() {
    const op =
      this.operators && this.operators.length >= 1
        ? this.operators
        : [STATIC_TUIC_OPERATOR];
    this._displayOperator = ExcursionOperator.createFromArray(op);
  }

  get displayOperator(): ?ExcursionOperator {
    return this._displayOperator;
  }
}
