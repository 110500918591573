// @flow

import React, { Component } from 'react';
import Headline from '../Headline';
import Tile from '../tiles/Tile';

import LoadingIndicator from '../LoadingIndicator';

import { loadImg } from '../../utils/image';

const loadTileImg = (img) => loadImg(`tiles/${img}.jpg`);

import MasterData from '../../models/MasterData';

import type { TilesStoreType } from '../../stores/TilesStore';

type Props = {
  masterData: MasterData,
  tiles?: TilesStoreType | null,
};

export default class Tiles extends Component<Props> {
  render() {
    const { tiles, masterData } = this.props;
    return tiles ? (
      <div className="l-tiles no-print">
        <Headline className="l-mod" title="Entdecken und reservieren Sie unser vielfältiges Angebot" />
        {tiles.landausfluege && (
          <Tile
            full
            link="/landausfluege"
            badge={tiles.landausfluege.notification}
            disabled={!tiles.landausfluege.enabled}
            bonus={masterData.bonusText || ''}
            title={tiles.landausfluege.title}
            imageSrc={loadTileImg('landausfluege-544x324')}
            smallImageSrc={loadTileImg('landausfluege-236x236')}
          />
        )}
        {tiles.schoenheitGesundheit && (
          <Tile
            full
            link="/schoenheit-und-gesundheit"
            badge={tiles.schoenheitGesundheit.notification}
            disabled={!tiles.schoenheitGesundheit.enabled}
            title={tiles.schoenheitGesundheit.title}
            imageSrc={loadTileImg('schoenheit-gesundheit-544x324')}
            smallImageSrc={loadTileImg('schoenheit-gesundheit-236x236')}
          />
        )}
        {tiles.tischreservierung && (
          <Tile
            full
            link="/tischreservierung"
            badge={tiles.tischreservierung.notification}
            disabled={!tiles.tischreservierung.enabled}
            imageSrc={loadTileImg('tischreservierung-544x324')}
            smallImageSrc={loadTileImg('tischreservierung-236x236')}
            title={tiles.tischreservierung.title}
          />
        )}
      </div>
    ) : (
      <LoadingIndicator />
    );
  }
}
