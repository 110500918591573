// @flow

import React from 'react';

import Picture from '../Picture';

type Props = {
  src: string,
  link: ?string,
};

const AppStoreTile = ({ src, link }: Props) => (
  <div className="appStoreTile">
    <a href={link} target="_blank">
      <Picture src={src} />
    </a>
  </div>
);

export default AppStoreTile;
