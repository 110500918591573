// @flow

import React, { Component } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import validators from '../../utils/forms/validators';

import InfoBox from '../InfoBox';
import FormInput from '../form/Input';
import FormButton from '../form/Button';
import LoadingIndicator from '../LoadingIndicator';

import type EmailStore from '../../stores/EmailStore';

type Props = {
  emailStore: EmailStore,
  email: string,
};

@observer
export default class ItineraryReportRequestForm extends Component<Props> {
  @observable email: ?string;
  @observable errorEmail: ?string;

  constructor(props: Props) {
    super(props);
    this.email = this.props.email;
  }

  @autobind
  @action
  changeEmail(email: string) {
    this.email = email;
    if (!validators.isEmail(email)) {
      this.errorEmail = 'Bitte geben Sie eine gültige E-Mail-Adresse an.';
    } else {
      this.errorEmail = null;
    }
  }

  @autobind
  onSubmit(event: SyntheticEvent<>) {
    event.preventDefault();
    if (this.errorEmail) {
      return;
    }
    this.props.emailStore.sendEmail(this.email);
  }

  render() {
    const { emailStore } = this.props;
    if (emailStore.saved) {
      return (
        <InfoBox className="small-icon no-center-icon">
          <h2>Vielen Dank für Ihre Anfrage!</h2>
          <p>
            Wir haben Ihnen eine Übersicht aller Reservierungen an die
            angegebene E-Mail-Adresse geschickt.
          </p>
        </InfoBox>
      );
    }
    return (
      <InfoBox className="small-icon no-center-icon">
        <form onSubmit={this.onSubmit}>
          <h2>Reservierungsübersicht per E-Mail zuschicken</h2>

          <p>
            Möchten Sie eine Übersicht all Ihrer Reservierungen per E-Mail
            erhalten? Bitte beachten Sie, dass Leistungen, die sich noch im
            Status „In Bearbeitung“ befinden, nicht korrekt in der
            Reservierungsübersicht angezeigt werden können.
          </p>

          {!emailStore.sending ? (
            <FormInput
              name="email"
              value={this.email}
              onChange={this.changeEmail}
              hasError={!!this.errorEmail}
            />
          ) : null}
          <div className="l-right">
            {emailStore.sending ? (
              <LoadingIndicator />
            ) : (
              <FormButton dark>Ja, jetzt anfordern</FormButton>
            )}
          </div>
          {this.errorEmail && (
            <span className="error-message">{this.errorEmail}</span>
          )}
        </form>
      </InfoBox>
    );
  }
}
