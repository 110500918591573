// @flow

export const PRICE_MODELS = {
  feelGoodPlus: 'FeelGoodPlus',
  feelGood: 'FeelGood',
  flex: 'Flex',
  pro: 'Pro',
  plus: 'Plus',
  pur: 'Pur',
};

export default class GuestType {
  earlyBird: boolean;
  exclusivityPackage1: boolean;
  exclusivityPackage2: boolean;
  hasTuicTravel: boolean;
  includesTuicTravelToShip: boolean;
  vipPackage1: boolean;
  vipPackage2: boolean;
  priceModel: string;

  constructor(data: Object) {
    this.earlyBird = !!data.earlyBird;
    this.exclusivityPackage1 = !!data.exclusivityPackage1;
    this.exclusivityPackage2 = !!data.exclusivityPackage2;
    this.hasTuicTravel = !!data.hasTuicTravel;
    this.includesTuicTravelToShip = !!data.includesTuicTravelToShip;
    this.vipPackage1 = !!data.vipPackage1;
    this.vipPackage2 = !!data.vipPackage2;

    this.priceModel = data.priceModel;
  }
}
