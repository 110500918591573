// TODO: add flow types

import { observable, action, computed } from 'mobx';

import RestaurantOffer from '../models/RestaurantOffer';

export default class RestaurantStore {
  @observable listLoadingError = false;
  @observable list = null;
  @observable details = {};

  masterStore = null;
  api = null;
  listRequest = null;
  detailRequest = {};

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoadingList() {
    return this.list === null;
  }

  @action
  fetchList() {
    this.listLoadingError = true;
    if (!this.listRequest) {
      this.listRequest = this.api
        .getList(this.masterStore.user, this.masterStore.masterData)
        .then(
          action(list => {
            this.list = list.map(
              data => new RestaurantOffer(data, this.masterStore)
            );
            return this.list;
          }),
          action(() => {
            this.listLoadingError = true;
            this.list = [];
            this.listRequest = null;
            return this.list;
          })
        );
    }

    return this.listRequest;
  }

  fetchDetails(id) {
    if (!this.detailRequest[id]) {
      this.detailRequest[id] = this.api
        .getDetails(this.masterStore.user, this.masterStore.masterData, id)
        .then(
          action(data => {
            this.details[id] = new RestaurantOffer(data, this.masterStore);
            return this.details[id];
          })
        );
      // After a minute we can reload the item again
      setTimeout(() => {
        this.detailRequest[id] = null;
      }, 60000);
    }
    return this.detailRequest[id];
  }
}
