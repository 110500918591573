// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';
import type { ApiType } from '../api/arrivalAndDeparture';

import ArrivalDeparture from '../models/ArrivalDeparture';

export default class ArrivalDepartureStore {
  @observable arrivalDeparture: ?ArrivalDeparture = null;

  masterStore: MasterStore;
  api: ApiType;
  @observable loading: Boolean;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
    this.railVoucher = null;
    this.loading = true;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  fetch(): Promise<void> {
    this.setLoading(true);
    return this.api
      .get(this.masterStore.user)
      .then((arrivalDeparture: ArrivalDeparture) => {
        this.receiveArrivalDeparture(arrivalDeparture);
      })
      .then(() => {
        // TUICUNIT-2789
        if (this.arrivalDeparture) {
          if (this.arrivalDeparture.transportTyps.some(i => i === 'flight')) {
            this.api.getRailVoucher(this.masterStore.user).then(data => {
              this.receiveRailVoucher(data);
            });
          }
        }
      })
      .then(() => {
        this.api.getTrain(this.masterStore.user).then(data => {
          const mergeData = this.arrivalDeparture;
          mergeData.train = data;
          this.receiveArrivalDeparture(mergeData);
          if (data.numberOfBookings !== 0 || this.arrivalDeparture.transportTyps.some(i => i === 'train')) {
            this.api.getRailVoucher(this.masterStore.user).then(data => {
              this.receiveRailVoucher(data);
              this.setLoading(false);
            });
          } else {
            this.setLoading(false);
          }
        });
      });
  }

  @action
  setLoading(state: boolean) {
    this.loading = state;
  }
  @action
  receiveArrivalDeparture(arrivalDeparture: ArrivalDeparture) {
    this.arrivalDeparture = new ArrivalDeparture(arrivalDeparture);
  }

  getTrainTicket(pdf: string) {
    return this.api.getTrainPdf(this.masterStore.user, pdf);
  }

  // TUICUNIT-2789
  @action
  receiveRailVoucher(data: RailVoucher) {
    if (data && data.text) {
      data.text = data.text.replace(/(<b>)/gi, '[b]').replace(/(<\/b>)/gi, '[/b]');
      data.text = data.text.replace(/(<a href)/gi, '[a]').replace(/(<\/a>)/gi, '[/a]');
      data.text = data.text.replace(/(<br>)/gi, '[br]');

      data.text = data.text.replace(/(<([^>]+)>)/gi, '');
      data.text = data.text.replace(/([\n\t])/gi, ' ');
      data.text = data.text.split('  ').join(' ');

      data.text = data.text.split('[b]').join('<span class="bold">');
      data.text = data.text.split('[/b]').join('</span>');
      data.text = data.text.split('[a]').join(' <a rel="noopener noreferrer" target="_blank" href');
      data.text = data.text.split('[/a]').join('</a>');
      data.text = data.text.split('[br]').join('<br>');
    }

    if (data && data.url && data.text) {
      data.text = data.text.split('{LINK|').join(`<a rel="noopener noreferrer" target="_blank" href="${data.url}">`);
      data.text = data.text.split('}').join('</a>');
    }

    // New case: available flights but no RailAndFlyVoucher
    // bewotec state is -> RailAndFlyExcluded
    // TUICUNIT-3337: RailAndFlyExcluded renamed to VouchersExcluded
    if (data && (data.state === 'RailAndFlyExcluded' || data.state === 'VouchersExcluded')) {
      data = null;
    }
    this.railVoucher = data;
  }
}

export type RailVoucher = {
  headline: ?string, // (riot)
  text: ?string, // (riot)
  variant: ?string, // (riot)
  state: ?string, // (bewotec)
  url: ?string, // (bewotec)
} | null;
