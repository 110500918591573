// @flow
import { observable, computed, action } from 'mobx';
import union from 'lodash/union';
import without from 'lodash/without';
import includes from 'lodash/includes';

import BookingRequestBase from './BookingRequestBase';

import { BOOKING_ADDED, BOOKING_DELETED } from './constants';
import {
  PACKAGE_DELIVER_ON_FIRST_DAY,
  PACKAGE_DELIVER_ON_LAST_DAY,
} from '../../config/constants';

import PartyMember from '../../models/PartyMember';
import NewspaperList from '../../models/NewspaperList';
import Booking from '../../models/Booking';

import type {
  BookingType,
  INewspaperBookingData,
  INewspaperItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
  IAnalyticsDetails,
} from '../../types/booking';

export default class NewspaperBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text: 'Wollen Sie folgende Zeitungstitel für Ihre Reise reservieren?',
      },
      success: {
        title: 'Ihre Reservierung ist eingegangen.',
        text: 'Sie erhalten von uns folgende Zeitungspakete:',
      },
    },
    cancellation: {
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text:
          'Sind Sie sicher, dass sie die Reservierung für folgenden Titel stornieren möchten?',
      },
      success: {
        title: 'Ihre Stornierungsanfrage ist eingegangen.',
        text:
          'Ihre Stornierung ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
    },
  };

  @observable selectedMpi = null;
  @observable selectedPaperIds = [];
  @observable selectedOptions = [];
  @observable bookingToCancel = null;
  @observable bookedPackages = [];

  constructor(travelparty: PartyMember[], bookable: NewspaperList) {
    super(travelparty, bookable);
    const requestor = travelparty.find(m => m.requestor);
    if (requestor) this.selectedMpi = requestor.mpi;
  }

  @action
  reset() {
    this.selectedPaperIds = [];
    this.selectedOptions = [];
    this.bookingToCancel = null;
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @action
  toggleSelection(packageId: string, value: boolean) {
    this.selectedPaperIds = value
      ? union(this.selectedPaperIds, [packageId])
      : without(this.selectedPaperIds, packageId);
  }

  @action
  toggleOption(optionName: string, value: boolean) {
    this.selectedOptions = value
      ? union(this.selectedOptions, [optionName])
      : without(this.selectedOptions, optionName);
  }

  @computed
  get isChangeRequest(): boolean {
    return false;
  }

  @computed
  get isCancellation(): boolean {
    return !!this.bookingToCancel;
  }

  @computed
  get getOnFirstDay(): boolean {
    return includes(this.selectedOptions, PACKAGE_DELIVER_ON_FIRST_DAY);
  }

  @computed
  get getOnLastDay(): boolean {
    return includes(this.selectedOptions, PACKAGE_DELIVER_ON_LAST_DAY);
  }

  createItemToBook(packageId: string, type: BookingType): INewspaperItemToBook {
    const newspaper =
      this.bookable.packages.find(p => p.id === packageId) || {};

    return {
      id: newspaper.id,
      vacancyId: newspaper.vacancyId,
      name: newspaper.name,
      price: this.bookable.price,
      error: this.errors ? this.errors : null,
      quantity: 1,
      type,
    };
  }

  @computed
  get itemsToBook(): INewspaperItemToBook[] {
    if (this.isCancellation) {
      return this.bookingToCancel
        ? [this.createItemToBook(this.bookingToCancel.typeId, BOOKING_DELETED)]
        : [];
    }
    return this.isReady
      ? this.selectedPaperIds.map(newspaperId =>
          this.createItemToBook(newspaperId, BOOKING_ADDED)
        )
      : [];
  }

  @computed
  get total(): number {
    // That is not really used in Booking Assistant
    return 0;
  }

  @computed
  get isReady(): boolean {
    return this.selectedPaperIds.length > 0;
  }

  get startDateTime(): ?Date {
    return null;
  }

  get endDateTime(): ?Date {
    return null;
  }

  @computed
  get bookingData(): INewspaperBookingData[] {
    if (this.isCancellation) return [];
    return this.itemsToBook.map(item => ({
      type: 'package',
      vacancyId: item.vacancyId,
      excludeFirstDay: !this.getOnFirstDay,
      excludeLastDay: !this.getOnLastDay,
      quantity: 1,
    }));
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.isCancellation || !this.bookingToCancel) return [];
    const mpisToCancel = this.bookingToCancel.mpis;
    const bookedIds = this.bookingToCancel.bookingId;

    return mpisToCancel.map(mpi => ({
      type: 'package',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  @computed
  get vacancyId(): string {
    return this.itemsToBook[0].vacancyId;
  }

  get analyticsDetails(): IAnalyticsDetails[] {
    return (this.bookable.packages || []).map(item => ({
      id: `${item.id}`,
      name: item.name,
      category: item.analyticsCategory,
      price: 0, // Dont track price for newspapers
    }));
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    let variant = null;
    if (this.getOnFirstDay && this.getOnLastDay) {
      variant = 'inkl. An- / Abreisetag';
    } else if (this.getOnFirstDay) {
      variant = 'inkl. Anreisetag';
    } else if (this.getOnLastDay) {
      variant = 'inkl. Abreisetag';
    }
    const bookedProducts = (this.bookable.packages || []).filter(item =>
      includes(this.selectedPaperIds, item.id)
    );
    return {
      products: bookedProducts.map(item => ({
        id: item.id,
        name: item.name,
        category: item.analyticsCategory,
        quantity: 1,
        variant,
        price: 0, // Dont track price for newspapers
      })),
      purchases: this.isCancellation
        ? []
        : bookedProducts.map(item => ({
            id: `${item.id}${this.getOnFirstDay ? 'A' : ''}${
              this.getOnLastDay ? 'D' : ''
            }`,
            revenue: 0,
          })),
      refunds:
        this.isCancellation && this.bookingToCancel
          ? [
              {
                id: `${this.bookingToCancel.typeId}${
                  this.bookingToCancel.excludesFirstDay ? '' : 'A'
                }${this.bookingToCancel.excludesLastDay ? '' : 'D'}`,
              },
            ]
          : [],
    };
  }
}
