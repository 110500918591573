// @flow

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import { isLocal, mockAll } from './utils';
import type MasterData from '../models/MasterData';
import type User from '../models/User';

const mock = false;
const getListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/restaurant/list.json`
    : `/api/land/restaurant/vacancies/${ed}/${dd}/${shipId}`;
};

const getDetailUrl = (masterData: MasterData, restaurantId: string): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/restaurant/details.json`
    : `/api/land/restaurant/detail/${restaurantId}/${ed}/${dd}/${shipId}`;
};

const unifyResultStyles = data => ({
  ...data,
  id: data.id || data.venueId,
  title: data.title || data.name,
});

export const sanitizers = {
  getList: list =>
    camelCaseKeys(list, true)
      .content.map(c => c.data[0])
      .map(unifyResultStyles),
  getDetails: response => {
    const data = unifyResultStyles(camelCaseKeys(response, true));
    // Fix wrong formatted status #TUICMRL-574
    if (data.statusCode === undefined) {
      data.statusCode = data.status;
      data.status = data.statusMessage;
    }
    return data;
  },
};

export default sanitized(
  {
    getList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getListUrl(masterData)),
    getDetails: (user: User, masterData: MasterData, restaurantId: string) =>
      fetchForUser(user, getDetailUrl(masterData, restaurantId)),
  },
  sanitizers
);
