// @flow

import React, { Component, type ComponentType } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import NotFoundPage from '../NotFound';
// import ManifestOverviewPage from '../Manifest/Overview';
import LoadingIndicator from '../../LoadingIndicator';

import InternetPackage from '../../../models/InternetPackage';
import ParkingPackage from '../../../models/ParkingPackage';
import Package from '../../../models/Package';
import TravelMediaPackage from '../../../models/TravelMediaPackage';

import type MasterStore from '../../../stores/MasterStore';
import type ManifestStore from '../../../stores/ManifestStore';
import type RestaurantStore from '../../../stores/RestaurantStore';
import type OfferStore from '../../../stores/OfferStore';
import type ExcursionStore from '../../../stores/ExcursionStore';
import type PackageStore from '../../../stores/PackageStore';
import type DailyEventsStore from '../../../stores/DailyEventsStore';
import type BoardingStore from '../../../stores/BoardingStore';
import type IncludedShoreExStore from '../../../stores/IncludedShoreExStore';

import { routerShape } from 'react-router/lib/PropTypes';
import pinStorage from '../../../utils/pinStorage';
import { pinRedirectUrl } from '../../../utils/pinRedirectUrl';

type Props = {
  masterStore: MasterStore,
  manifestStore: ManifestStore,
  restaurantStore: RestaurantStore,
  offerStore: OfferStore,
  excursionStore: ExcursionStore,
  packageStore: PackageStore,
  dailyEventsStore: DailyEventsStore,
  boardingStore: BoardingStore,
  includedShoreExStore: IncludedShoreExStore,
};

const STRATEGIES = {
  MasterStore: ({ masterStore }) =>
    masterStore.reloadMasterData().then(() => masterStore),
  ItineraryDay: ({ masterStore, params }) =>
    new Promise(function(resolve) {
      resolve(masterStore.itinerary.dayAt(params.dayIndex));
    }),
  RestaurantOffer: ({ restaurantStore, params }) =>
    restaurantStore.fetchDetails(params.venueId),
  Beauty: ({ offerStore, params }) =>
    offerStore.storeForType('beauty').fetchDetails(params.packageId),
  SpaBalconyCabin: ({ offerStore, params }) =>
    offerStore.storeForType('spaBalconyCabin').fetchDetails(params.packageId),
  IncludedShoreExDetail: ({ includedShoreExStore, params }) =>
    includedShoreExStore.fetchDetails(params.excursionId),
  IncludedShoreExDetailStatus: ({ includedShoreExStore, params }) =>
    includedShoreExStore.fetchDetailStatus(params.excursionId),
  Excursion: ({ excursionStore, params }) =>
    excursionStore.fetchDetails(params.excursionId),
  Internet: ({ packageStore, params, masterStore }) =>
    packageStore
      .storeForType('internet')
      .fetchDetails(params.packageId)
      .then(data => new InternetPackage(data, masterStore)),
  Parking: ({ packageStore, params, masterStore }) =>
    packageStore
      .storeForType('parking')
      .fetchDetails(params.packageId)
      .then(data => new ParkingPackage(data, masterStore)),
  Package: ({ packageStore, params, masterStore }) =>
    packageStore
      .storeForType('package')
      .fetchDetails(params.packageId)
      .then(data => new Package(data, masterStore)),
  TravelMedia: ({ packageStore, params, masterStore }) =>
    packageStore
      .storeForType('package')
      .fetchDetails(params.packageId)
      .then(data => new TravelMediaPackage(data, masterStore)),
  DailyEvent: ({ dailyEventsStore, params }) =>
    dailyEventsStore.fetchDetails(params.eventId),
  TravelPartyMember: ({ masterStore, params }) =>
    new Promise(function(resolve) {
      resolve(masterStore.masterData.getPartyMemberByIndex(params.memberIndex));
    }),
  UserManifest: ({ masterStore, manifestStore, params }) => {
    if (!masterStore || !masterStore.masterData) return null;
    const travelPartyMember = masterStore.masterData.getPartyMemberByIndex(
      params.memberIndex
    );
    return manifestStore.fetchUserData(travelPartyMember.mpi);
  },
};

export default (
  items: { [string]: string },
  mandatory: boolean = true,
  showLoading: ?boolean
) => (DecoratedComponent: ComponentType<any>): ComponentType<any> => {
  if (typeof showLoading === 'undefined') {
    showLoading = mandatory;
  }
  return inject(
    'masterStore',
    'manifestStore',
    'restaurantStore',
    'offerStore',
    'excursionStore',
    'packageStore',
    'dailyEventsStore',
    'boardingStore',
    'includedShoreExStore'
  )(
    observer(
      class PreloadRequirement extends Component<Props> {
        static WrappedComponent = DecoratedComponent;

        static contextTypes = {
          router: routerShape,
        };

        @observable loadedItems: { [string]: any } = {};
        @observable loadingCount: number = 0;
        @observable itemNotFound: boolean = false;

        // https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
        // @action
        // componentDidMount() {
        constructor(props: Props) {
          super(props);

          const itemsToPreload = Object.keys(items);
          this.loadingCount = itemsToPreload.length;

          itemsToPreload.forEach(propName => {
            const strategy = items[propName];
            if (!STRATEGIES[strategy]) {
              throw new Error(`Preloading strategy for ${strategy} not found.`);
            }
            const strategyResult = STRATEGIES[strategy](props);

            if (strategyResult) {
              strategyResult.then(
                action(item => {
                  this.loadedItems[propName] = item;
                  this.loadingCount--;
                  if (!item) {
                    this.itemNotFound = true;
                  }
                }),
                action(() => {
                  this.loadingCount--;
                  this.itemNotFound = true;

                  // TUICUNIT-911
                  const error = pinStorage.getApiPinError();
                  if (error) {
                    this.context.router.replace(
                      pinRedirectUrl(pinStorage.getUrl(error.mpi) || '')
                    );
                  }
                })
              );
            }
          });
        }

        render() {
          if (this.loadingCount > 0) {
            return showLoading ? <LoadingIndicator /> : null;
          }

          if (this.itemNotFound && mandatory) {
            return <NotFoundPage {...this.props} />;
          }

          const props = {
            ...this.props,
            ...this.loadedItems,
          };

          return <DecoratedComponent {...props} />;
        }
      }
    )
  );
};
