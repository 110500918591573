// @flow
import { observable, computed, action } from 'mobx';
import PackageBookingRequest from './PackageBookingRequest';
import BookingRequestBase from './BookingRequestBase';

import PartyMember from '../../models/PartyMember';
import ParkingPackage from '../../models/ParkingPackage';
import BookedPackage from '../../models/BookedPackage';
import Booking from '../../models/Booking';
import type { BookingType, IGeneralItemToBook } from '../../types/booking';
import { BOOKING_ADDED, BOOKING_DELETED } from './constants';

export default class ParkingBookingRequest extends PackageBookingRequest {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text: bookingRequest =>
          `Wollen Sie diesen ${
            bookingRequest.bookable.bookingName
          } für folgenden Reiseteilnehmer reservieren?`,
      },
      success: {
        ...BookingRequestBase.TEXTS.create.success,
        text:
          'Sobald Ihre Anfrage von uns bearbeitet wurde, wird diese in Ihrem Reiseplan angezeigt.',
      },
    },
    cancellation: {
      ...BookingRequestBase.TEXTS.cancellation,
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: bookingRequest =>
          `Möchten Sie den ${
            bookingRequest.bookable.bookingName
          } wirklich für folgende Reiseteilnehmer stornieren?`,
      },
    },
  };

  @observable acceptedOperator = false;

  constructor(
    travelParty: PartyMember[],
    bookable: ParkingPackage,
    bookedPackages: ?(BookedPackage[])
  ) {
    super(travelParty, bookable, bookedPackages);
    this.selectedCount = 1;
  }

  @computed
  get bookedPackagesOfSameType(): BookedPackage[] {
    return (this.bookedPackages || []).filter(pkg => {
      return pkg.isType(this.bookable.packageType);
    });
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @computed
  get isReady(): boolean {
    return this.acceptedOperator && !this.hasBookedSomethingOfSameType;
  }

  @action
  setAcceptation(value: boolean) {
    this.acceptedOperator = value;
  }

  createItemToBook(mpi: number, type: BookingType) {
    const member = this.travelParty.find(p => p.mpi === mpi);

    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAge : '',
      price: null,
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
    };
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] {
    if (this.isCancellation) {
      return this.bookingToCancel
        ? this.bookingToCancel.mpis.map(mpi =>
            this.createItemToBook(mpi, BOOKING_DELETED)
          )
        : [];
    }
    return this.isReady && this.selectedMpi
      ? [this.createItemToBook(this.selectedMpi, BOOKING_ADDED)]
      : [];
  }
}
