// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

const ContentBox = ({ children, title, titleIcon, className }) => (
  <div className={`content-box ${className || ''}`}>
    {title ? (
      <h2 className="content-box__title">
        {titleIcon}
        {title}
      </h2>
    ) : null}
    <div className="content-box__content">{children}</div>
  </div>
);

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.node,
};

export default ContentBox;
