// @flow
import { assign, required } from '../../utils/model';
import { formatDate } from '../../utils/date';

const TYPE_ONBOARD_EVENT = 'dailyOnboardEvent';
const TYPE_RESTAURANT = 'restaurant';

export default class HighlightItem {
  name: string;
  type: string;
  imageUri: string;
  venueId: string;
  date: Date;
  dayIndex: ?number;

  constructor(data: Object, dayIndex: ?number) {
    assign(this, data, {
      name: required(String),
      type: required(String),
      imageUri: required(String),
      venueId: required(String),
      date: String,
    });

    this.dayIndex = dayIndex;
  }

  get link(): string {
    if (this.dayIndex && this.venueId && this.type === TYPE_ONBOARD_EVENT) {
      return `/programm/${this.dayIndex}/${this.venueId}`;
    }

    if (!this.dayIndex && this.venueId && this.type === TYPE_ONBOARD_EVENT) {
      return `/programm/${this.venueId}`;
    }

    if (this.venueId && this.type === TYPE_RESTAURANT) {
      return `/tischreservierung/${this.venueId}`; // /${this.dayIndex}
    }
    return '';
  }

  get imageSrc(): string {
    return this.imageUri;
  }

  get displayTime(): string {
    if (this.date) {
      return formatDate(this.date);
    }
    return '';
  }
}
