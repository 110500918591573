// @flow
export type allowedTag = 'a' | 'b' | 'i' | 's' | 'u' | 'li' | 'ul' | 'br' | 'mailto';

function prepareText(text: string, htmlTags: Array<allowedTag>) {
  let cText = text || '';
  let allowedTags = Array.isArray(htmlTags) ? htmlTags : [];

  if (allowedTags.length <= 0) {
    return cText
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/([\n\t])/gi, ' ')
      .split('  ')
      .join(' ');
  }

  if (allowedTags.includes('mailto')) {
    cText = replaceEmailWithTag(cText);
  }

  //change allowed tags to replace later
  allowedTags.forEach((tag) => {
    if (tag === 'a') {
      cText = cText.replace(/(<a href)/gi, '[a]').replace(/(<\/a>)/gi, '[/a]');
      return;
    }
    //create Regex for opening Tags
    let regEx = new RegExp(`<${tag}>`, 'gi');
    //create Regex for closing Tags
    let regEx2 = new RegExp(`<\/${tag}>`, 'gi');

    cText = cText.replace(regEx, `[${tag}]`).replace(regEx2, `[\/${tag}]`);
  });

  //remove all Tags
  cText = cText.replace(/(<([^>]+)>)/gi, '');
  cText = cText.replace(/([\n\t])/gi, ' ');
  cText = cText.split('  ').join(' ');

  //readd allowed Tags
  cText = cText.split('[a]').join(' <a href');
  cText = cText.split('[/a]').join('</a>');
  cText = cText.split('[b]').join('<span class="bold">');
  cText = cText.split('[/b]').join('</span>');
  cText = cText.split('[i]').join('<i>');
  cText = cText.split('[/i]').join('</i>');
  cText = cText.split('[s]').join('<s>');
  cText = cText.split('[/s]').join('</s>');
  cText = cText.split('[u]').join('<u>');
  cText = cText.split('[/u]').join('</u>');
  cText = cText.split('[li]').join('<li><span>');
  cText = cText.split('[/li]').join('</span></li>');
  cText = cText.split('[ul]').join('<ul>');
  cText = cText.split('[/ul]').join('</ul>');
  cText = cText.split('[br]').join('<br>');

  cText = cText.replace(/(Mein Schiff)/gi, '<span class="nobr"><i>Mein Schiff</i></span>');
  return cText;
}

/**
 * Replaces email addresses with a custom mailto HTML tag with the custom HTML a tag from prepareText.
 *
 * @param {string} text - The input text that may contain email addresses and custom mailto HTML tags.
 * @returns {string} - The output text with email addresses and custom mailto HTML tags replaced with the custom HTML a tag from prepareText.
 */
function replaceEmailWithTag(text) {
  // The regular expression to match email addresses and custom mailto HTML tags.
  const regex = /([\w.-]+@[\w-]+(\.[\w-]+)+)\s*?<mailto:([\w.-]+@[\w-]+(\.[\w-]+)+)>/gi;

  // The replacement string to replace email addresses and custom mailto HTML tags with.
  const replacement = '[a]="mailto:$3">$1[/a]';

  // Replace email addresses and custom mailto HTML tags with proper HTML anchor tags with mailto.
  return text.replace(regex, replacement);
}

export { prepareText };
