// @flow

import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';
import { autobind } from 'core-decorators';

import { parseDate } from '../../utils/date';
import { encode, decode } from '../../utils/base64';

import Headline from '../Headline';
import Button from '../Button';
import BlockList from '../lists/BlockList';
import LoadingIndicator from '../LoadingIndicator';
import ItineraryListItem from '../ItineraryListItem';
import EventDayList from '../Program/EventDayList';

import type MasterStore from '../../stores/MasterStore';
import type DailyEventsStore from '../../stores/DailyEventsStore';
import Link from 'react-router/lib/Link';
import tracking from '../../tracking';

const TAB_DAYS = 'days';
const TAB_CATEGORIES = 'categories';

type NavigationParam = {
  day?: {
    id: number,
    date: string,
  } | null,
  category?: {
    id: number,
    name: string,
  } | null,
  selectedCategories?: number[],
} | null;

const parseHash = (hash: string): NavigationParam => {
  if (hash) {
    try {
      return JSON.parse(decode(hash.substr(1)));
    } catch (exc) {
      return null;
    }
  }
  return null;
};

const setHash = (data: NavigationParam): void => {
  try {
    window.location.hash = encode(
      JSON.stringify({
        ...(parseHash(window.location.hash) || {}),
        ...data,
      })
    );
  } catch (exc) {}
};

@inject('masterStore')
@observer
class ProgramBreadcrumb extends Component<{
  masterStore: MasterStore,
}> {
  render() {
    const { itinerary } = this.props.masterStore;

    let navParam;
    let day;
    let category;
    let hash;

    try {
      hash = window.location.hash;
      if (hash) {
        navParam = parseHash(hash);
        day = navParam && navParam.day ? itinerary.dayById(navParam.day.id) : null;
        category = navParam ? navParam.category : null;
      }
    } catch (exc) {}

    return (
      <Fragment>
        <Link
          to="/programm"
          className="breadcrumb__crumb"
          onClick={() => {
            tracking.click('breacrumb.link.Programm');
            window.location.hash = '';
          }}
        >
          Programm
        </Link>
        {day && (
          <Link
            to={`/programm${hash || ''}`}
            className="breadcrumb__crumb"
            onClick={() => tracking.click('breacrumb.link.' + day.breadcrumbTitle)}
          >
            {day.breadcrumbTitle}
          </Link>
        )}
        {hash && !day && (
          <Link
            to={`/programm${hash || ''}`}
            className="breadcrumb__crumb"
            onClick={() => tracking.click('breacrumb.link.Alle Reisetage')}
          >
            Alle Reisetage
          </Link>
        )}
        {category && (
          <Link
            to={`/programm${hash || ''}`}
            className="breadcrumb__crumb"
            onClick={() => tracking.click('breacrumb.link.' + category.name)}
          >
            {category.name}
          </Link>
        )}
      </Fragment>
    );
  }
}

type Props = {
  masterStore: MasterStore,
  dailyEventsStore: DailyEventsStore,
  location: Object,
};

@inject('masterStore', 'dailyEventsStore')
@observer
export default class PageProgram extends Component<Props> {
  static breadcrumb = ProgramBreadcrumb;

  @observable showFirstScreen: boolean = true;
  @observable selectedTab = null;

  @autobind @action onHashChange() {
    this.showFirstScreen = !window.location.hash;
  }

  @action componentDidMount() {
    const { query } = this.props.location;

    if (query && parseInt(query.s, 10) === 1) {
      this.showFirstScreen = false;
    }

    window.addEventListener('hashchange', this.onHashChange, false);

    if (!window.location.hash) {
      tracking.pageView(window.location.pathname, ['Programm']);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange, false);
  }

  UNSAFE_componentWillMount() {
    const { masterStore, dailyEventsStore } = this.props;
    masterStore.reloadItinerary();
    dailyEventsStore.fetchList().then(() => {
      const navParam = parseHash(window.location.hash);
      if (navParam) {
        const { day, category } = navParam;
        this.chooseTab(category ? TAB_CATEGORIES : TAB_DAYS);
        this.showSecondScreen({
          day: day ? day.date : undefined,
          category: category ? category.id : undefined,
        });
      }
    });
  }

  @action showSecondScreen({ day, category }: { day?: string, category?: number }) {
    const { list } = this.props.dailyEventsStore;
    list.reset();
    if (day) {
      const date = parseDate(day);
      if (date) {
        list.selectDay(date.getTime());
      }
    }
    if (category) {
      list.selectCategoryIds([category]);
    }
    this.showFirstScreen = false;
  }

  @action chooseTab(tab: string) {
    this.selectedTab = tab;
  }

  renderItinerary() {
    const { masterData, itinerary } = this.props.masterStore;
    const itineraryList = itinerary.list;
    const bookings = masterData.getAllBookings();
    return (
      <div className="l-mod">
        {itineraryList.map((day, index) => (
          <ItineraryListItem
            journeyOngoing={masterData.journeyOngoing}
            showEmpty
            services={day.getBookingsForItinerary(bookings[day.date])}
            day={day}
            data={{
              date: day.displayItineraryDate,
              title: day.port.name,
              imageSrc: day.image,
              imageAlt: day.hint,
            }}
            disabled={day.isPast}
            onClick={() => {
              setHash({
                day: {
                  id: day.id,
                  date: day.date,
                },
              });
              this.showSecondScreen({ day: day.date });
            }}
            key={index}
          />
        ))}
      </div>
    );
  }

  renderCategoryList() {
    const { dailyEventsStore } = this.props;
    return (
      <BlockList>
        {dailyEventsStore.categoryFilter.map((cat) => (
          <BlockList.Item
            key={cat.id}
            onClick={() => {
              setHash({ category: cat });
              this.showSecondScreen({ category: cat.id });
            }}
          >
            {cat.name}
          </BlockList.Item>
        ))}
      </BlockList>
    );
  }

  renderFirstScreen() {
    const { dailyEventsStore } = this.props;

    if (this.triggered) {
      this.triggered = false;
    }

    return dailyEventsStore.list ? (
      <div className="l-block">
        <div className="l-spread l-mod">
          <div className="l-spread_left">
            <Button fullWidth white={this.selectedTab !== TAB_CATEGORIES} onClick={() => this.chooseTab(TAB_DAYS)}>
              Nach Reisetag
            </Button>
          </div>
          <div className="l-spread_right">
            <Button
              fullWidth
              white={this.selectedTab === TAB_CATEGORIES}
              onClick={() => this.chooseTab(TAB_CATEGORIES)}
            >
              Nach Kategorie
            </Button>
          </div>
        </div>
        {this.selectedTab === TAB_CATEGORIES ? (
          <div>{this.renderCategoryList()}</div>
        ) : (
          <div>{this.renderItinerary()}</div>
        )}
      </div>
    ) : (
      <LoadingIndicator />
    );
  }

  triggered = false;

  renderSecondScreen() {
    const { dailyEventsStore, masterStore } = this.props;
    const { itinerary, masterData } = masterStore;
    const { list, categoryFilter } = dailyEventsStore;
    const bookings = masterData.getAllBookings();
    const navParam = parseHash(window.location.hash);
    const day = navParam && navParam.day ? itinerary.dayById(navParam.day.id) : null;

    if (this.triggered === false && day) {
      this.triggered = true;
      tracking.pageView(window.location.pathname, ['Programm', day.breadcrumbTitle]);
    }

    return list ? (
      <EventDayList
        list={list}
        bookings={bookings}
        categoryFilter={categoryFilter}
        day={day}
        selectedCategories={navParam ? navParam.selectedCategories : null}
        onSelectDay={(timeStamp?: number) => {
          if (!timeStamp) {
            return setHash({ day: null });
          }

          const { itinerary } = masterStore;
          const itineraryDay = itinerary.dayByTimeStamp(timeStamp);
          if (!itineraryDay) return;
          setHash({
            day: {
              id: itineraryDay.id,
              date: itineraryDay.date,
            },
          });
        }}
        onSelectCategories={(selectedCategories: number[]) => {
          setHash({ selectedCategories, category: null });
        }}
      />
    ) : (
      <LoadingIndicator />
    );
  }

  render() {
    return (
      <div className="page">
        <Headline title="Programm" />
        {this.showFirstScreen ? this.renderFirstScreen() : this.renderSecondScreen()}
      </div>
    );
  }
}
