// @flow
import React, { Component } from 'react';
import FormSelect from '../form/Select';

type Props = {
  name: string,
  value: string,
  onChange: () => mixed,
};

export default class PersonHeightSelect extends Component<Props> {
  min = 155;
  max = 210;

  get entries(): Object[] {
    let entries = [];
    for (let i = this.min; i <= this.max; i++) {
      entries.push({
        label: i + ' cm',
        value: i,
      });
    }
    return entries;
  }

  render() {
    const { onChange, name, value } = this.props;

    return (
      <div>
        <FormSelect
          value={value}
          name={name}
          placeholder="Körpergröße auswählen"
          options={this.entries}
          onChange={onChange}
        />
        <div className="form-select-info">
          Minimum: 155 cm / Maximum: 210 cm
        </div>
      </div>
    );
  }
}
