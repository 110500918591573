// @flow
import IncludedShoreExItem from './IncludedShoreExItem';
import type {
  IIncludedShoreExListing,
  IIncludedShoreExListItem,
} from '../../api/includedShoreEx';

export default class IncludedShoreExListing implements IIncludedShoreExListing {
  type: string;
  headline: string;
  description: string;
  list: IIncludedShoreExListItem[];

  constructor(data: IIncludedShoreExListing) {
    this.type = (data && data.type) || '';
    this.list =
      data && data.list && data.list.map(item => new IncludedShoreExItem(item));

    this.headline = data.headline;
    this.description = data.description;
  }
}
