// @flow
import * as React from 'react';

import LinkAsButton from '../../components/LinkAsButton';
import isInAppView from '../../utils/isInAppView';

type Props = {
  enabled: boolean,
};

const FAQTeaser = ({ enabled }: Props) => (
  <div className="faq">
    <h2>Benötigen Sie Hilfe?</h2>
    <LinkAsButton
      newPage={!isInAppView}
      asAnchor={isInAppView}
      disabled={!enabled}
      link="https://www.meinschiff.com/faq/an-und-abreise?id=133"
    >
      Häufige Fragen zum Online Check-In
    </LinkAsButton>
  </div>
);

export default FAQTeaser;
