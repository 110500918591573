//@flow
import React, { Component, type Node } from 'react';

type Props = {
  children?: Node,
};

export default class Breadcrumb extends Component<Props> {
  render() {
    return <div className="breadcrumb">{this.props.children}</div>;
  }
}
