// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Button = ({
  children,
  disabled,
  fullWidth,
  white,
  dark,
  big,
  ...props
}) => (
  <button
    {...props}
    className={classNames({
      button: true,
      disabled,
      'full-width': fullWidth,
      white,
      dark,
      big,
      [props.className]: !!props.className,
    })}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  white: PropTypes.bool,
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default Button;
