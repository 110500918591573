// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';

import BookingRequest from '../../models/BookingRequest/ParkingBookingRequest';

import preload from './decorators/preload';
import DetailPageLayout from '../DetailPageLayout';
import BookingList from '../BookingList/Parking';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import BookingAssistant from '../BookingAssistant/ParkingBookingAssistant';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type ParkingPackage from '../../models/ParkingPackage';
import type Booking from '../../models/Booking';
import { tealiumDateFromString, tealiumIdFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

@inject('masterStore')
@preload({ packageDetails: 'Package' }, false)
@observer
class PackageDetailsBreadcrumb extends Component<{
  masterStore: MasterStore,
  packageDetails: ParkingPackage,
}> {
  render() {
    const { packageDetails, masterStore } = this.props;
    return (
      <BreadcrumbLink
        {...this.props}
        text={packageDetails.headline}
        bookingRequest={
          new BookingRequest(masterStore.masterData.travelParty, packageDetails, masterStore.packagesOfUser)
        }
      />
    );
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  packageDetails: ParkingPackage,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ packageDetails: 'Parking' })
@observer
export default class PageParkingDetails extends Component<Props> {
  static breadcrumb = PackageDetailsBreadcrumb;

  bookingRequest: BookingRequest;

  constructor(props: Props) {
    super(props);
    const { masterStore, packageDetails } = this.props;
    this.bookingRequest = new BookingRequest(
      masterStore.masterData.travelParty,
      packageDetails,
      masterStore.packagesOfUser
    );
  }

  tracked = false;

  componentDidMount(): * {
    this.triggerTracking();
  }

  triggerTracking() {
    if (this.tracked === false) {
      this.tracked = true;
      tracking.viewItem(this.props.breadcrumbRouterStore, {
        category: this.bookingRequest.bookable.analyticsCategory,
        categoryId: tealiumIdFromString(this.bookingRequest.bookable.analyticsCategory),
        discount: '0.00',
        code: this.bookingRequest.bookable.code,
        name: this.bookingRequest.bookable.name,
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
        quantity: '1',
        startDate: tealiumDateFromString(this.bookingRequest.bookable.startDate),
        value: tealiumValue(String(this.bookingRequest.total)),
      });
    }
  }

  @autobind
  handleBookingCancelClick(pkg: Booking) {
    this.bookingRequest.cancelBooking(pkg);
    this.props.bookingUiStore.openBookingDialog();
  }

  render() {
    const { masterStore, packageDetails, bookingUiStore } = this.props;

    return packageDetails ? (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        <DetailPageLayout
          model={packageDetails}
          bookingList={() => (
            <BookingList
              header="Bereits reserviertes Paket:"
              bookings={this.bookingRequest.bookedPackagesOfSameType}
              user={masterStore.user}
              onCancelBooking={this.handleBookingCancelClick}
            />
          )}
          sidebar={(model) => (
            <BookingAssistant
              bookable={model}
              bookingRequest={this.bookingRequest}
              onBooking={() => {
                tracking.addToCart('1', this.bookingRequest.total);
                bookingUiStore.openBookingDialog();
              }}
            />
          )}
        />
      </WithBookingConfirmation>
    ) : null;
  }
}
