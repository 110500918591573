// @flow

import React from 'react';
import styled, { css } from 'styled-components';
import { colors, dimensions } from '../styles';

const Scrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 125%;
  background: ${colors.background50};
  z-index: 9;
  transition: opacity 400ms ease-in-out;

    ${p =>
      p.open
        ? css`
            opacity: 1;
          `
        : css`
            opacity: 0;
            visibility: hidden;
            left: -200%;
            transition: opacity 500ms ease-in-out, visibility 1ms ease-in 550ms,
              left 1ms ease-in 550ms;
          `}

  @media (min-width: ${dimensions.mobileL + 1}px) {
    display: none;
  }
`;

interface Props {
  open: boolean;
}

export default function MenuScrim({ open }: Props) {
  return <Scrim open={open} />;
}
