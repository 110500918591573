// @flow

import React, { Component, type Node } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

type Props = {
  children: Node,
  // If set to true, we will not add a box around that item
  plain?: boolean,
  // This text is always shown
  title?: Node,
  // This has to be a node, that we just put into content as teaserContent
  teaserContent?: Node,
  // Text of link to show more
  moreLink: string,
  // Text of link to show the less state again (if null, no less link is shown)
  lessLink?: string,
};

@observer
export default class ToggleInfoBox extends Component<Props> {
  @observable open = false;

  @action.bound
  toggle(event: SyntheticEvent<>) {
    event.preventDefault();
    this.open = !this.open;
  }

  render() {
    const {
      children,
      title,
      teaserContent,
      moreLink,
      lessLink,
      plain,
    } = this.props;
    const clsPrefix = plain ? 'morebox' : 'infobox';
    return (
      <div className={`${clsPrefix}`}>
        {title}
        {this.open ? (
          <div className={`${clsPrefix}__content`}>
            {children}
            <a
              href="#"
              className={`${clsPrefix}__content__less-link`}
              onClick={this.toggle}
            >
              {lessLink}
            </a>
          </div>
        ) : (
          <div className={`${clsPrefix}__content`}>
            {teaserContent}
            <a
              href="#"
              className={`${clsPrefix}__content__more-link`}
              onClick={this.toggle}
            >
              {moreLink}
            </a>
          </div>
        )}
      </div>
    );
  }
}
