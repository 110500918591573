// @flow
import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';

import type User from '../models/User';
import type MasterData from '../models/MasterData';
import type Bookable from '../models/Bookable';

import { isLocal, mockAll } from './utils';

const mock = false;
const getListUrl = (
  masterData: MasterData,
  vacancy: Bookable,
  type: string
): string => {
  const { ed, dd, shipId } = masterData;
  const { vacancyId, date } = vacancy;

  return (mock || mockAll) && isLocal
    ? `/mock/land/timeslots-simple.json`
    : `/api/land/${type}/timeslots/${vacancyId}/${date}/${ed}/${dd}/${shipId}`;
};

export const sanitizers = {
  getList: (data: Object) => camelCaseKeys(data, true),
};

export default sanitized(
  {
    getList: (
      user: User,
      masterData: MasterData,
      vacancy: Bookable,
      type: string
    ) => fetchForUser(user, getListUrl(masterData, vacancy, type)),
  },
  sanitizers
);
