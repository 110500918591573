// @flow

import React, { Component, type ComponentType } from 'react';
import { routerShape } from 'react-router/lib/PropTypes';
import { inject, observer } from 'mobx-react';
import { observable, action } from 'mobx';

import LoadingIndicator from '../../LoadingIndicator';

import type MasterStore from '../../../stores/MasterStore';

type Props = {
  masterStore: MasterStore,
};

export default (requirements: string[]) => (
  DecoratedComponent: ComponentType<any>
): ComponentType<any> => {
  return inject('masterStore')(
    observer(
      class MasterStoreReloader extends Component<Props> {
        static contextTypes = {
          router: routerShape,
        };

        static WrappedComponent = DecoratedComponent;

        @observable requirementsFullfilled: boolean = false;

        componentDidMount() {
          const { masterStore } = this.props;
          if (masterStore.masterData) {
            this.validateRequirements();
            return;
          }
          masterStore
            .reloadMasterData()
            .then(() => this.validateRequirements())
            .catch(() => this.setFullfilled(true));
        }

        validateRequirements() {
          const masterData = this.props.masterStore.masterData;
          if (requirements.find(requirement => !masterData[requirement])) {
            this.context.router.replace(`/`);
            return;
          }
          this.setFullfilled(true);
        }

        @action
        setFullfilled(fullfilled: boolean) {
          this.requirementsFullfilled = fullfilled;
        }

        render() {
          if (!this.requirementsFullfilled) return <LoadingIndicator />;
          return <DecoratedComponent {...this.props} />;
        }
      }
    )
  );
};
