// @flow

import { observable, action } from 'mobx';

import TypeStore from './OfferOrPackageTypeStore';
import InternetPackage from '../models/InternetPackage';
import Package from '../models/Package';
import ParkingPackage from '../models/ParkingPackage';
import PageContent from '../models/PageContent';

import type PackageApi from '../api/package';
import bookingApi from '../api/bookings';
type BookingApi = typeof bookingApi;
import type MasterStore from '../stores/MasterStore';

export default class PackageStore {
  static KLASSES = {
    internet: InternetPackage,
    newspaper: Package,
    media: Package,
    package: Package,
    parking: ParkingPackage,
  };

  @observable typeStores: { [string]: TypeStore } = {};
  @observable page = null;
  pageRequest: any;

  bookingApi: BookingApi;
  masterStore: MasterStore;
  api: PackageApi;

  constructor(
    api: PackageApi,
    bookingApi: BookingApi,
    masterStore: MasterStore
  ) {
    this.api = api;
    this.bookingApi = bookingApi;
    this.masterStore = masterStore;
  }

  storeForType(type: string): TypeStore {
    if (!this.typeStores[type]) {
      this.typeStores[type] = new TypeStore(
        this.api,
        this.masterStore,
        type,
        this.constructor.KLASSES[type]
      );
    }
    return this.typeStores[type];
  }

  fetchPage() {
    if (!this.pageRequest) {
      this.pageRequest = this.api
        .packagePage(this.masterStore.user, this.masterStore.masterData)
        .then(
          action(page => {
            this.page = new PageContent(page);
            return this.page;
          })
        );
    }
    return this.pageRequest;
  }
}
