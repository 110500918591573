// @flow
import SpaListItem from './SpaListItem';
import MasterStore from '../../stores/MasterStore';

export default class SpaBalconyCabin {
  type: string;
  title: string;
  subline: string; //TODO this should have been named subhead
  badge: ?string;
  list: SpaListItem[];
  headline: ?string;
  description: ?string;
  descriptionHtml: ?string;
  image: ?string;
  images: ?(Object[]);

  constructor(data: SpaBalconyCabin, masterStore: MasterStore) {
    this.title = data.title || '';
    this.subline = data.subline || '';
    this.badge = data.badge;
    this.image = data.image;
    this.images = data.images;
    this.description = data.description;
    this.list =
      data &&
      data.list &&
      data.list.map(pkg => new SpaListItem(pkg, masterStore));
  }
}
