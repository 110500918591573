// @flow
// @see TUICUNIT-327
const replacements = [
  {
    expression: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
    replace: (match: string): string => {
      return `<a href="${match}" target="_blank">${match.replace(
        'http://',
        ''
      )}</a>`;
    },
  },
  {
    expression: /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/gi,
    replace: (match: string): string => {
      return `<a href="mailto:${match}">${match}</a>`;
    },
  },
];

export default (text: ?string): string => {
  if (!text) return '';

  replacements.forEach(rep => {
    const matched = text.match(rep.expression);
    if (matched) {
      matched.forEach(match => {
        text = text.replace(match, rep.replace(match));
      });
    }
  });

  return text;
};
