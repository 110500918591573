// @flow
import addMonths from 'date-fns/add_months';
import subDays from 'date-fns/sub_days';
import addDays from 'date-fns/add_days';

import PartyMember from '../models/PartyMember';
import validators, { validateWith, validateWithAny } from '../utils/forms/validators';
import { isAlphanumeric } from '../utils/string';
import { adjustedDate } from '../utils/date';
import { USER_PIN_LENGTH } from '../config/constants';
import ManifestData from './ManifestData';

const REGEX_DE_ZIP_CODE = /^[\d]{5}$/;
const REGEX_AT_CH_ZIP_CODE = /^[\d]{4}$/;

// TUICMRL-1219
const CREDENTIALS_PASSPORT_PASS = 'R';

const PASSPORT_MONTHS_UNTIL_EXPIRATION = 6;
const ID_CARD_MONTHS_UNTIL_EXPIRATION = 3;
export const RIGHT_BEFORE_CRUISE_NUM_OF_DAYS = 7;

export type ValidationResultType =
  | boolean
  | {|
      ok: boolean,
      error?: string,
    |};

export type ValidateWith = (string, {}) => ValidationResultType;
export type ValidateWithAny = (string, {}, { [string]: any }) => ValidationResultType;

export default class FormValidation {
  manifestData: ManifestData;
  partyMember: PartyMember;
  passportOnly: boolean;
  embarkDate: number;
  debarkDate: number;

  constructor(
    manifestData: ManifestData,
    partyMember: PartyMember,
    {
      passportOnly,
      embarkDate,
      debarkDate,
    }: {
      passportOnly: boolean,
      embarkDate: number,
      debarkDate: number,
    }
  ) {
    this.manifestData = manifestData;
    this.partyMember = partyMember;
    this.passportOnly = passportOnly;
    this.embarkDate = embarkDate;
    this.debarkDate = debarkDate;
  }

  get zipCodeValidator(): ValidateWith {
    return (input: string, formValues: Object): ValidationResultType => {
      if (!input) return true;
      const { country } = formValues.contact;

      if (country === 'DE') {
        return REGEX_DE_ZIP_CODE.test(input)
          ? { ok: true }
          : {
              ok: false,
              error: 'Bitte geben Sie Ihre 5-stellige Postleitzahl an.',
            };
      }
      if (country === 'AT' || country === 'CH') {
        return REGEX_AT_CH_ZIP_CODE.test(input)
          ? { ok: true }
          : {
              ok: false,
              error: 'Bitte geben Sie Ihre 4-stellige Postleitzahl an.',
            };
      }
      return true;
    };
  }

  get acceptDataProtectionTermsValidator(): ValidateWith {
    return (input: string, formValues: Object): ValidationResultType => {
      const { otherInformation } = formValues;
      const { dietary, handicap } = otherInformation;
      const { allergy, allergyInfo, mealRequest, individualRequest } = dietary;

      return (!handicap &&
        (!allergy || allergy.length === 0) &&
        !allergyInfo &&
        (!mealRequest || mealRequest.length === 0) &&
        !individualRequest) ||
        !!input
        ? { ok: true }
        : {
            ok: false,
            error: 'Bitte bestätigen Sie dieses Feld.',
          };
    };
  }

  static get passportMonthsUntilExpiration(): number {
    return PASSPORT_MONTHS_UNTIL_EXPIRATION;
  }

  static get idCardMonthsUntilExpiration(): number {
    return ID_CARD_MONTHS_UNTIL_EXPIRATION;
  }

  // TUICMRL-1219
  static getCredentialRequirements(passportOnly: boolean): string {
    return passportOnly
      ? `Auf dieser Reise ist ein Reisepass notwendig, der noch
          ${FormValidation.passportMonthsUntilExpiration} Monate nach der Reise
          gültig sein muss. Ein Personalausweis bzw. eine Identitätskarte
          (Schweiz) ist nicht ausreichend.`
      : `Auf dieser Reise ist ein Reisepass, der noch mindestens
          ${FormValidation.passportMonthsUntilExpiration} Monate nach Reiseende
          gültig ist oder ein Personalausweis bzw. eine Identitätskarte
          (Schweiz), der/die noch mindestens
          ${FormValidation.idCardMonthsUntilExpiration} Monate nach Reiseende
          gültig ist, ausreichend.`;
  }

  get validUntilValidator(): ValidateWith {
    return (input: string, formValues: Object): ValidationResultType => {
      if (!input) return true;
      const { passportType } = formValues.credentials;

      const debarkDateMinusOne = subDays(this.debarkDate, 1);
      const mustBeValidUntil =
        this.passportOnly || passportType === CREDENTIALS_PASSPORT_PASS
          ? addMonths(debarkDateMinusOne, FormValidation.passportMonthsUntilExpiration)
          : addMonths(debarkDateMinusOne, FormValidation.idCardMonthsUntilExpiration);

      if (!validators.isDate(input, { after: mustBeValidUntil })) {
        return {
          ok: false,
          error: FormValidation.getCredentialRequirements(this.passportOnly),
        };
      }

      return { ok: true };
    };
  }

  get noVisaDetailsFilledValidator(): ValidateWithAny {
    return (input: string, options: {}, formValues: { entryVisa?: {} }): ValidationResultType => {
      if (typeof options.rightBeforeCruise !== 'boolean') throw new Error('Missing property options.rightBeforeCruise');

      let entryVisa: Object = formValues.entryVisa;
      let keys = Object.keys(entryVisa);
      let filtered = keys.filter((key) => !!entryVisa[key]);
      let filteredLen = filtered.length;
      const noVisa = filteredLen === 0 && !entryVisa.hasVisa;
      const emptyFields = filteredLen === 1 && filtered[0] === 'hasVisa';
      const fieldsFull = filteredLen === keys.len - 1;

      return noVisa || (!options.rightBeforeCruise && emptyFields) || (options.rightBeforeCruise && fieldsFull);
    };
  }

  get hasVisaValidator(): ValidateWithAny {
    return (input: string, options: {}, formValues: { entryVisa?: {} }): ValidationResultType => {
      const entryVisa = formValues.entryVisa || {};
      let valid = true;
      Object.keys(entryVisa).forEach((key) => {
        if (!entryVisa[key]) {
          valid = false;
        }
      });
      return valid || !entryVisa.hasVisa;
    };
  }

  get validationRules(): Object {
    const preparedEmbarkDate = new Date(this.embarkDate);

    const dateOfBirthValidationRule = {
      adult: {
        before: adjustedDate(
          new Date(new Date(preparedEmbarkDate.getTime()).setFullYear(preparedEmbarkDate.getFullYear() - 15))
        ),
      },
      child: {
        before: adjustedDate(
          new Date(new Date(preparedEmbarkDate.getTime()).setFullYear(preparedEmbarkDate.getFullYear() - 2))
        ),
        after: adjustedDate(
          new Date(new Date(preparedEmbarkDate.getTime()).setFullYear(preparedEmbarkDate.getFullYear() - 15))
        ),
      },
      baby: {
        before: adjustedDate(new Date(preparedEmbarkDate.getTime())),
        after: adjustedDate(
          new Date(new Date(preparedEmbarkDate.getTime()).setFullYear(preparedEmbarkDate.getFullYear() - 2))
        ),
      },
    };

    return {
      personalInfo: {
        salutation: [validateWith(validators.isRequired, 'Bitte geben Sie Ihre Anrede an.')],
      },
      contact: {
        streetAddress: [
          validateWith(validators.isRequired, 'Bitte geben Sie Ihre Straße und Hausnummer an.'),
          validateWith(validators.isLength, { min: 2 }, 'Bitte geben Sie Ihre Straße und Hausnummer an.'),
        ],
        zipCode: [validateWith(validators.isRequired, 'Bitte geben Sie Ihre Postleitzahl an.'), this.zipCodeValidator],
        city: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Ort an.')],
        country: [validateWith(validators.isRequired, 'Bitte wählen Sie Ihr Land aus.')],

        phone: [
          validateWithAny(
            [[validators.isLength, { min: 8 }], (x) => x === ''],
            'Bitte geben Sie eine gültige Telefonnummer an.'
          ),
          validateWith(validators.isRequired, 'Bitte geben Sie Ihre Telefonnummer an.'),
          validateWith(validators.isPhone, { onlyDigits: true }, 'Bitte geben Sie eine gültige Telefonnummer an.'),
        ],
        mobile: [
          validateWithAny(
            [[validators.isLength, { min: 8 }], (x) => x === ''],
            'Bitte geben Sie eine gültige Mobilnummer an.'
          ),
          validateWith(validators.isRequired, 'Bitte geben Sie Ihre Mobilnummer an.'),
          validateWith(
            validators.isPhone,
            { onlyDigits: true, international: true },
            'Bitte geben Sie eine gültige Mobilnummer an.'
          ),
        ],
        approveMobilePhoneDisclosure: [validateWith(validators.isRequired, 'Bitte bestätigen Sie dieses Feld.')],
        email: [
          validateWith(validators.isRequired, 'Bitte geben Sie Ihre E-Mail-Adresse an.'),
          validateWith(validators.isEmail, 'Bitte geben Sie eine gültige E-Mail-Adresse an.'),
        ],
      },
      entryVisa: {
        visaPassportNumber: [
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              validators.isRequired,
            ],
            'Bitte geben Sie Ihre Visum Nr. an.'
          ),
        ],
        visaPassportType: [
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              validators.isRequired,
            ],
            'Bitte geben Sie die Visumkategorie an.'
          ),
        ],
        visaPlaceOfIssue: [
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              validators.isRequired,
            ],
            'Bitte geben Sie den Ausstellungsort Ihres Visums an.'
          ),
        ],
        visaDateOfIssue: [
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              validators.isRequired,
            ],
            'Bitte geben Sie das Ausstellungsdatum Ihres Visums an.'
          ),
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              [validators.isDate, { before: addDays(this.embarkDate, 1) }],
            ],
            'Das Ausstellungsdatum Ihres Visums muss vor dem Reisebeginn liegen.'
          ),
        ],
        visaValidUntil: [
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              validators.isRequired,
            ],
            'Bitte geben Sie an, wie lange Ihr Visum gültig ist.'
          ),
          validateWithAny(
            [
              [
                this.noVisaDetailsFilledValidator,
                {
                  rightBeforeCruise:
                    subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                },
              ],
              [
                validators.isDate,
                {
                  after: addDays(this.embarkDate, 1),
                },
              ],
            ],
            'Die Gültigkeit Ihres Visums muss im Reisezeitraum liegen.'
          ),
        ],
        hasVisa:
          this.manifestData.data.credentials.hasVisa !== null
            ? [
                validateWithAny(
                  [
                    this.hasVisaValidator,
                    [
                      this.noVisaDetailsFilledValidator,
                      {
                        rightBeforeCruise:
                          subDays(this.embarkDate, RIGHT_BEFORE_CRUISE_NUM_OF_DAYS) < adjustedDate(new Date()),
                      },
                    ],
                  ],
                  'Indien-Visum Checkbox Fehler'
                ),
              ]
            : [],
      },
      credentials: {
        passportType: [validateWith(validators.isRequired, 'Bitte wählen Sie Ihren Ausweistyp aus.')],
        passportNumber: [
          validateWithAny(
            [[validators.isLength, { min: USER_PIN_LENGTH }]],
            'Bitte geben Sie eine gültige Ausweis- / Passnummer ohne Leer- und Sonderzeichen an.'
          ),
          validateWith(validators.isRequired, 'Bitte geben Sie Ihre Ausweis- / Passnummer an.'),
          validateWith(
            (value) => isAlphanumeric(value),
            'Bitte geben Sie eine gültige Ausweis- / Passnummer ohne Leer- und Sonderzeichen an.'
          ),
        ],
        placeOfIssue: [
          validateWith(
            validators.isRequired,
            'Bitte geben Sie den Ausstellungsort Ihres Personalausweises oder Reisepasses an.'
          ),
        ],
        dateOfIssue: [
          validateWith(
            validators.isRequired,
            'Bitte geben Sie das Ausstellungsdatum Ihres Ausweisdokuments an.'
            // this.passportOnly
            //     ? 'Bitte geben Sie das Ausstellungsdatum Ihres Reisepasses an.'
            //     : 'Bitte geben Sie das Ausstellungsdatum Ihres Personalausweises oder Reisepasses an.'
          ),
          validateWith(
            validators.isDate,
            { before: this.embarkDate },
            'Das Ausstellungsdatum Ihres Ausweisdokuments muss vor dem Reisebeginn liegen.'
            // this.passportOnly
            //     ? 'Das Ausstellungsdatum Ihres Reisepasses muss vor dem Reisebeginn liegen.'
            //     : 'Das Ausstellungsdatum Ihres Personalausweises oder Reisepasses muss vor dem Reisebeginn liegen.'
          ),
        ],
        validUntil: [
          validateWith(validators.isRequired, 'Bitte geben Sie an wie lange Ihr Ausweisdokument gültig ist.'),
          this.validUntilValidator,
        ],
        nationality: [validateWith(validators.isRequired, 'Bitte wählen Sie Ihre Nationalität aus.')],
        placeOfBirth: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Geburtsort an.')],
        dateOfBirth: [
          validateWith(validators.isRequired, 'Bitte geben Sie Ihr Geburtsdatum an.'),
          validateWith(
            validators.isDate,
            {
              ...dateOfBirthValidationRule.adult,
              // ...(this.partyMember.isChild
              ...(this.manifestData.options.personalInfo.salutationOptions.find((v) => v.value.startsWith('Kind'))
                ? dateOfBirthValidationRule.child
                : {}),
              // ...(this.partyMember.isBaby
              ...(this.manifestData.options.personalInfo.salutationOptions.find((v) => v.value.startsWith('Baby'))
                ? dateOfBirthValidationRule.baby
                : {}),
            },
            'Bitte überprüfen Sie Ihr Geburtsdatum.'
          ),
        ],
        profession: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Beruf an.')],
        entryRequirementsAccepted: [
          validateWith(validators.isRequired, 'Bitte stimmen Sie den Einreisebestimmungen zu.'),
        ],
        hasVisa:
          this.manifestData.data.credentials.hasVisa !== null
            ? [
                validateWith(
                  validators.isRequired,
                  'Bitte bestätigen Sie, dass Sie über ein gültiges Visum für die USA bzw. eine gültige ESTA Anmeldung verfügen.'
                ),
              ]
            : [],
      },
      otherInformation: {
        emergencyContact: {
          name: [validateWith(validators.isRequired, 'Bitte geben Sie Ihren Kontakt für Notfälle an.')],
          phone: [
            validateWith(validators.isRequired, 'Bitte geben Sie die Telefonnummer Ihres Kontaktes für Notfälle an.'),
            validateWithAny(
              [[validators.isLength, { min: 8 }], (x) => x === ''],
              'Bitte geben Sie eine gültige Telefonnummer an.'
            ),
            validateWith(
              validators.isPhone,
              { onlyDigits: true },

              'Bitte geben Sie eine gültige Telefonnummer an.'
            ),
          ],
        },
        pregnant24Weeks: [validateWith(validators.isRequired, 'Bitte bestätigen Sie dieses Feld.')],
        acceptHealthTerms: [validateWith(validators.isRequired, 'Bitte bestätigen Sie dieses Feld.')],
        acceptDataProtectionTerms: [this.acceptDataProtectionTermsValidator],
      },
    };
  }
}
