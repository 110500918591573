// @flow
import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import { isLocal } from './utils';

const mock = false;
const mockS = false;
const dummyFiles = ['questionnaire'];

const getStatus = () => {
  return mockS && isLocal
    ? `/mock/flightPreference/status.json`
    : `/api/package/vip/cabinOption/flightPreferences`;
};

const getUrl = currentMemberMpi => {
  return mock && isLocal
    ? `/mock/flightPreference/${dummyFiles[0]}.json`
    : `/api/booking/v1/flight-preferences/questionnaire?mpi=${currentMemberMpi}`;
};

export default {
  status: (user: User) =>
    fetchForUser(user, getStatus(), {
      differentErrorCodes: true,
    }),
  get: (user: User, currentMemberMpi: number) =>
    fetchForUser(user, getUrl(currentMemberMpi), {
      differentErrorCodes: true,
    }),
  save: (user: User, data: any) =>
    fetchForUser(
      user,
      `/api/booking/v1/flight-preferences/questionnaire/answers`,
      {
        method: 'post',
        body: JSON.stringify(data),
        differentErrorCodes: true,
      }
    ),
  confirm: (user: User, data: any) =>
    fetchForUser(
      user,
      `/api/booking/v1/flight-preferences/questionnaire/confirmation`,
      {
        method: 'post',
        body: JSON.stringify(data),
        differentErrorCodes: true,
      }
    ),
};
