// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import Accordion from '../../../Accordion';
import AccordionItem from '../../../AccordionItem';

import type ArrivalDepartureItem from '../../../../models/ArrivalDeparture/ArrivalDepartureItem';

type Props = {
  item: ArrivalDepartureItem,
  expanded: boolean,
};

export default class TrainChange extends Component<Props> {
  get header(): Node {
    const { item } = this.props;
    // console.log(item);
    return (
      <div>
        <div
          className={classNames({
            'arrival-departure-item__date': true,
            empty: !item.departureDate,
          })}
        >
          {item.departureDate || ' - '}
        </div>

        {item.arrivalTime && (
          <div className="arrival-departure-service__time time">
            <span className="bold">{item.arrivalTime || ' '}</span> Ankunft
            <i className="icon train-change" />
          </div>
        )}
        <div className="arrival-departure-item__title">{item.title}</div>
      </div>
    );
  }

  render() {
    const { item } = this.props;
    return (
      <div className="arrival-departure-item services train-change">
        {item ? (
          <Accordion>
            <AccordionItem header={this.header}>
              <div>
                {item.departureTime && (
                  <div className="arrival-departure-service__time time">
                    <span className="bold">{item.departureTime}</span>{' '}
                    {item.subtitle}
                  </div>
                )}
                <br />
                {item.trainNumber ? (
                  <span>
                    <div>Zugnummer</div>
                    <div className="bold">{item.trainNumber}</div>
                  </span>
                ) : null}
              </div>
            </AccordionItem>
          </Accordion>
        ) : (
          this.header
        )}
      </div>
    );
  }
}
