// @flow
import { observable, action, computed, toJS } from 'mobx';

import type { ArrivalAtType } from '../models/PartyMember';

export type TimeSloteType = {
  data: {
    participants: [number],
    type: string,
    vacancyId: string,
  },
  display: string | null,
  startDate: string,
  endDate: string,
};

export type SelectedMemberType = {
  mpi: number,
  arrivalAt?: ?ArrivalAtType,
  timeSlote?: ?TimeSloteType,
};
type FormDataType = {
  selectedMembers: SelectedMemberType[],
  email: string,
  mobile: string,
  isGroupBooking: boolean,
};

export default class CheckInRequest {
  @observable isSaved: boolean = false;
  @observable isSending: boolean = false;
  @observable sendError: ?string = null;
  @observable checkedInMembersMpis: number[] = [];
  @observable
  formData: FormDataType = {
    selectedMembers: [],
    email: '',
    mobile: '',
    isGroupBooking: '',
  };

  constructor(selectedMembers: SelectedMemberType[] = []) {
    this.formData.selectedMembers = selectedMembers;
  }

  @action.bound
  selectMembers(selectedMembers: SelectedMemberType[] = []) {
    this.formData.selectedMembers = selectedMembers;
  }

  @action.bound
  resetSelectedMembers() {
    this.formData.selectedMembers = [];
  }

  @action.bound
  changeEmail(value: string) {
    this.formData.email = value;
  }

  @action.bound
  changeMobile(value: string) {
    this.formData.mobile = value;
  }

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  @action.bound
  setGroupBooking(value: boolean) {
    this.formData.isGroupBooking = value;
  }

  @action
  markAsSending() {
    this.isSending = true;
    this.isSaved = false;
    this.sendError = null;
  }

  @action
  markAsSaved(mpis: number[]) {
    this.isSending = false;
    this.isSaved = true;
    this.checkedInMembersMpis = mpis;
  }

  @action
  markAsErrornous(err: string) {
    this.isSending = false;
    this.sendError = err;
  }

  @computed
  get requestData(): FormDataType {
    return {
      ...toJS(this.formData),
      mobile: this.formData.mobile !== '0049' ? this.formData.mobile : '',
    };
  }

  @computed
  get mpisToCheckIn(): number[] {
    return this.formData.selectedMembers.map(m => m.mpi);
  }

  @computed
  get checkedInMembers(): number[] {
    return this.checkedInMembersMpis.slice();
  }
}
