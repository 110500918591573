// @flow
import { observable, action, computed } from 'mobx';
import { format } from 'date-fns';

import DailyEvent from './DailyEvent';

type EventDayType = {
  date: Date,
  displayDate: string,
  events: DailyEvent[],
};

export class EventDay {
  @observable filteredCategoryIds = [];

  date: Date;
  displayDate: string;
  events: DailyEvent[];
  filteredCategoryIds: number[];

  constructor(day: EventDayType) {
    Object.assign(this, day);
    this.events = day.events.map(eventData => new DailyEvent(eventData));
  }

  @action
  selectCategoryIds(categoryIds: number[]) {
    this.filteredCategoryIds = categoryIds;
  }

  @computed
  get filteredEvents(): DailyEvent[] {
    return this._filterForCategory(this.events, this.filteredCategoryIds);
  }

  _filterForCategory(
    events: DailyEvent[],
    categoryIds: number[]
  ): DailyEvent[] {
    if (categoryIds.length === 0) return events;
    return events.filter(event => categoryIds.indexOf(event.categoryId) !== -1);
  }
}

export default class CatalogEventDaysList {
  days: EventDay[];
  @observable filteredCategoryIds: number[] = [];
  @observable filteredDay: ?number = null;

  constructor(days: Object[]) {
    this.days = days
      .map(day => new EventDay(day))
      .sort((a, b) => a.date - b.date);
  }

  @action
  reset() {
    this.selectCategoryIds([]);
    this.selectDay(null);
  }

  @action
  selectCategoryIds(categoryIds: number[]) {
    this.filteredCategoryIds = categoryIds;
    this.days.forEach(day => {
      day.selectCategoryIds(categoryIds);
    });
  }

  @action
  selectDay(timeStamp: ?number) {
    this.filteredDay = timeStamp;
  }

  @computed
  get filteredDays(): EventDay[] {
    return this._filterForDay(this.days, this.filteredDay);
  }

  _filterForDay(days: EventDay[], selectedDay: ?number): EventDay[] {
    if (selectedDay) {
      const formattedDay: string = format(selectedDay, 'YYYY-MM-DD');
      return days.filter(
        day => format(day.date, 'YYYY-MM-DD') === formattedDay
      );
    }
    return days;
  }
}
