const serviceTeam = 'unser Serviceteam';
const travelAgency = 'Ihr Reisebüro';
const unknown = 'Ihren Reisevermittler';

const addText = 'Über [agency] können Sie nach Verfügbarkeit Ihre An- und Abreise bequem hinzubuchen.';
const text = 'Über [agency] haben Sie nach Verfügbarkeit die Möglichkeit, Ihre An- und Abreise bequem zu buchen.';
const hint =
  'Je nach An-/Ablegehafen haben Sie die Möglichkeit mit dem Flugzeug, der Bahn oder dem Bus an- und abzureisen.';
const link =
  '<a href="https://www.meinschiff.com/an-und-abreise" target="_blank">Allgemeine Informationen zur An- und Abreise</a>';

const operatorInfo = {
  addArrivalDepartureHeadline: 'Möchten Sie weitere An- oder Abreiseoptionen hinzubuchen?',

  serviceTeam: {
    addHeadline: 'Möchten Sie weitere An- oder Abreiseoptionen hinzubuchen?',
    addText: `${addText.replace('[agency]', serviceTeam)} ${hint}`,

    headline: `Buchen Sie Ihre An- und Abreise über TUI Cruises`,
    text: `${text.replace('[agency]', serviceTeam)} ${hint}`,

    contactHeadline: 'Kontakt Serviceteam',
    openingTimes: 'Montag bis Freitag von 10:00 – 20:00 Uhr <br/> Samstag von 10:00 – 18:00 Uhr',
    viewMail: true,
  },

  travelAgency: {
    addHeadline: 'Möchten Sie weitere An- oder Abreiseoptionen hinzubuchen?',
    addText: `${addText.replace('[agency]', travelAgency)} ${hint}`,

    headline: 'Buchen Sie Ihre An- und Abreise',
    text: `${text.replace('[agency]', travelAgency)} ${hint}`,
    viewMail: false,
  },

  unknown: {
    addHeadline: 'Möchten Sie weitere An- oder Abreiseoptionen hinzubuchen?',
    addText: `${addText.replace('[agency]', unknown)} ${hint}`,

    headline: 'Buchen Sie Ihre An- und Abreise',
    text: `${text.replace('[agency]', unknown)} ${hint}`,
  },

  defaultLink: { link },

  hint: {
    headline: 'Hinweis zur An- und Abreise mit der Bahn',
    text:
      'Die Darstellung von gebuchten Bahnleistungen ist leider zurzeit technisch nicht möglich. Sollten Sie bereits Ihre An- und Abreise mit der Bahn gebucht haben, finden Sie die Buchungsdetails in Ihren Reiseunterlagen und auf Ihrer Buchungsbestätigung.',
  },
};

export default operatorInfo;
