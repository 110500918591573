// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import { isLocal } from './utils';

const ENDPOINT = '/api/booking/v1/check-in/timeslots';
const MOCK = false;
/* console.log(
  '%c api/timeSlote.js ',
  'background: #ff0021; color: #000000',
  MOCK
); */

const getUrl = (): string => (isLocal && MOCK ? `/mock/timeslots_empty.json` : `${ENDPOINT}`);

export default {
  get: (user: User) => fetchForUser(user, getUrl(), { getErrorCode: 500 }),
};

export type TimeSloteType = {
  vacancyId: string,
  eventId: number,
  startDate: string,
  endDate: string,
  vacancyCount: number,
};

export type BookingTimeSloteType = {
  eventId: number,
  mpi: number,
  bookingId: string,
};

export type ApiResponse = {
  bookingOptionList: {
    list: [?TimeSloteType],
  },
  mpiBookingList: {
    list: [?BookingTimeSloteType],
  },
  status?: number,
};

export type ApiType = {
  get: User => Promise<ApiResponse>,
};
