// @flow
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/TBD.json`
    : `/api/account/v1/navigation`;

const api = {
  fetchNavigation: (): Promise<NavigationItemList | JsonInternalServerError> =>
    fetch(getUrl(), {}).then(response => response.json()),
};

export default api;

export const sanitizers = {
  get: <T>(x: T) => x,
};

export type JsonInternalServerError = {
  error: string,
};

export type NavigationItem = {
  name: string,
  position: number,
  type: string,
  url: string,
};

export type NavigationItemList = {
  items: NavigationItem[],
  type: string,
};

export type ApiType = {
  fetchNavigation: () => Promise<NavigationItemList | JsonInternalServerError>,
};
