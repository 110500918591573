// @flow
import SpaOffer from '../SpaOffer';
import type MasterStore from '../../stores/MasterStore';
import type ItineraryDay from '../ItineraryDay';

export default class SpaOfferInclusive extends SpaOffer {
  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);
    this.inclusive = true;
  }

  get inclusiveLabel(): string {
    return 'Inklusivleistung';
  }

  getBookableStatusForDay(itineraryDay: ?ItineraryDay): string {
    if (this.statusCode === 7) return '';
    if (!this.isBookable && this.statusCode !== 6) return this.bookingStatus;
    if (!this.isBookableOnDay(itineraryDay))
      return 'Derzeit nicht reservierbar';
    return '';
  }
}
