// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import preload from '../../../components/pages/decorators/preload';

import Headline from '../../../components/Headline';
import InfoBox from '../../../components/InfoBox';
import BreadcrumbLink from '../../../components/breadcrumbs/BreadcrumbLink';
import ManifestForm from '../../../components/Manifest/Form/Form';
import ManifestFormSuccess from '../../../components/Manifest/Form/Success';

import type MasterStore from '../../../stores/MasterStore';
import type ManifestStore from '../../../stores/ManifestStore';

import FormValidation from '../../../models/ManifestFormValidation';
import type ManifestData from '../../../models/ManifestData';
import type PartyMember from '../../../models/PartyMember';

import pinStorage from '../../../utils/pinStorage';
import { isIE } from '../../../utils/browser';

import {
  MASTERDATA_STATUS_MANIFEST_NOT_AVAILABLE_ANYMORE_BUT_VIEWABLE,
  USER_PIN_LENGTH,
} from '../../../config/constants';
import track from '../../../tracking';
import { MissingStreetNumberPopup } from '../../Manifest/Form/MissingStreetNumberPopup';
import ContentRegulations from '../../Manifest/Regulations/Content';
import DialogRegulations from '../../Manifest/Regulations/Dialog';

type BreadcrumbProps = {
  masterStore: MasterStore,
  params: {
    memberIndex: number,
  },
};

type Props = {
  masterStore: MasterStore,
  manifestStore: ManifestStore,
  manifestData: ManifestData,
  partyMember: PartyMember,
  params: { memberIndex: number },
  route: { isCheckinSubRoute: boolean },
};

type State = {
  formValues: ?Object,
};

@inject('masterStore')
@observer
class ManifestFormBreadcrumb extends Component<BreadcrumbProps> {
  render() {
    const { masterStore } = this.props;
    if (!masterStore.masterData) return null;
    const member: ?PartyMember = masterStore.masterData.getPartyMemberByIndex(this.props.params.memberIndex);
    return member ? <BreadcrumbLink {...this.props} text={member.displayName} /> : null;
  }
}

@inject('manifestStore', 'breadcrumbRouterStore')
@preload({
  masterStore: 'MasterStore',
  manifestData: 'UserManifest',
  partyMember: 'TravelPartyMember',
})
@observer
export default class PageManifestForm extends Component<Props, State> {
  static breadcrumb = ManifestFormBreadcrumb;

  formValidation: FormValidation;
  state = {
    formValues: null,
    showPopup: false,
  };

  constructor(props: Props) {
    super(props);
    const { masterStore, manifestData, partyMember } = props;
    if (masterStore && manifestData && partyMember) {
      this.formValidation = new FormValidation(manifestData, partyMember, {
        embarkDate: masterStore.masterData.embarkDate,
        debarkDate: masterStore.masterData.debarkDate,
        passportOnly: this.onlyPassportAvailable,
      });
    }
  }

  componentDidMount() {
    if (this.currentMember) {
      pinStorage.removeUrl(this.currentMember.mpi); // TUICUNIT-911
    }

    this.props.manifestStore.fetchRegulations();
    this.setStorageListener(true);

    // Page View Tracking
    let titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles) {
      titles.pop();
      track.pageView(window.location.pathname, [
        ...titles,
        'Schiffsmanifest',
        'person-' + this.props.params.memberIndex,
      ]);
    }
  }

  componentWillUnmount() {
    this.setStorageListener(false);
  }

  setStorageListener(listen: boolean) {
    if (!isIE()) {
      if (listen) {
        window.addEventListener('storage', this.storageChanged);
      } else {
        window.removeEventListener('storage', this.storageChanged);
      }
    }
  }

  @autobind
  storageChanged() {
    if (!isIE()) {
      window.location.reload();
    }
  }

  @autobind
  onError() {
    console.log('manifest form error');
    this.setState({
      // TUICUNIT-999
      formValues: null,
    });
    window.scrollTo(0, 0);
  }

  @autobind
  onSubmit(values: Object) {
    if (!values) return;

    // TUICUNIT-4599 streetAddress should have a number at the end
    if (/^.*\s(\d+[a-zA-Z]*)$/.test(values.contact.streetAddress)) {
      // TUICUNIT-999
      this.setState((currState) => ({
        ...currState,
        formValues: values,
      }));
      this.props.manifestStore.saveUserData(this.props.manifestData, values).catch(this.onError);
    } else {
      this.setState((currState) => ({
        ...currState,
        showPopup: true,
        formValues: values,
      }));
    }
  }

  @computed
  get currentMember(): PartyMember {
    return this.props.masterStore.masterData.getPartyMemberByIndex(this.props.params.memberIndex);
  }

  get onlyPassportAvailable(): boolean {
    const { manifestData } = this.props;
    return manifestData.options.credentials.passportTypeOptions.length === 1;
  }

  render() {
    const { manifestData } = this.props;
    if (!manifestData) return null;
    const { saveError, undefinedSaveError } = manifestData;
    const isCheckinSubRoute = this.props.route.isCheckinSubRoute;
    const preliminary = this.currentMember.manifestPreliminary;
    const isInAppView = this.props.masterStore.isInAppView;
    const { regulations } = this.props.manifestStore;

    if (manifestData.isSend) {
      // const formData = manifestData.data; // TUICUNIT-999 land/shipmanifest/store delivered old data (pre-saved state)
      const formData = this.state.formValues;

      // TUICUNIT-911
      let newPin;
      let pin = null;
      let pinChanged = false;
      let oldPin = pinStorage.getPin(this.currentMember.mpi);

      if (formData) {
        const { credentials } = formData;

        if (credentials && credentials.passportNumber) {
          const passportNumber = credentials.passportNumber;
          newPin = passportNumber.substr(passportNumber.length - USER_PIN_LENGTH);

          if (oldPin !== newPin) {
            pinChanged = !!oldPin;

            pin = newPin;
            pinStorage.removePin(this.currentMember.mpi); // TUICUNIT-1001 user must enter pin after change
          }
        }
      }

      // TUICUNIT-2579: save pin in sessionStorage, enter it the first time or change it
      if (pin && this.currentMember.mpi) {
        pinStorage.setPin(this.currentMember.mpi, pin);
      }

      track.pageView(window.location.pathname + '/success', [
        'Online Check-in Übersicht',
        'Schiffsmanifest',
        'person-' + this.props.params.memberIndex,
        'Bestätigung',
      ]);

      return (
        <ManifestFormSuccess
          pin={pin}
          pinChanged={pinChanged}
          userCheckinCompleted={this.currentMember.checkinCompleted}
          isCheckinSubRoute={!!isCheckinSubRoute}
          currentMemberMpi={this.currentMember.mpi}
        />
      );
    }

    return (
      <div className="page manifest">
        <Headline
          className={preliminary ? 'l-mod-sub' : 'l-mod'}
          title={`Persönliche Daten von ${manifestData.user.displayName}`}
        />
        {preliminary && (
          <InfoBox className="l-mod">
            <h2>Hinweis</h2>
            <p>
              Eine Änderung Ihrer Schiffsmanifestdaten ist derzeit nicht möglich, da sich die Daten in Bearbeitung
              befinden. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
            </p>
          </InfoBox>
        )}
        {undefinedSaveError && (
          <p className="error-message">
            Ihr Formular konnte nicht abgeschickt werden, bitte versuchen Sie es später noch einmal.
          </p>
        )}
        <ManifestForm
          disabled={
            !this.currentMember.manifestEnabled ||
            this.currentMember.manifest.reason === MASTERDATA_STATUS_MANIFEST_NOT_AVAILABLE_ANYMORE_BUT_VIEWABLE
          }
          onSubmit={this.onSubmit}
          onError={this.onError}
          hasErrors={!!saveError}
          formValidation={this.formValidation}
          manifestData={manifestData}
          partyMember={this.currentMember}
          allowBlockwiseSubmit={this.currentMember.manifestAllowsPartialSave}
          onlyPassportAvailable={this.onlyPassportAvailable}
          isCheckinSubRoute={!!isCheckinSubRoute}
          isInAppView={isInAppView}
          onOpenNewTab={() => {
            this.setStorageListener(false);
            window.setTimeout(() => this.setStorageListener(true), 2048);
            return true;
          }}
          regulationsContent={<DialogRegulations regulations={regulations} btnText={'Zu den Einreisebestimmungen'} />}
        />
        <MissingStreetNumberPopup
          showPopup={this.state.showPopup}
          closePopup={() => {
            this.setState((currState) => ({ ...currState, showPopup: false }));
            const input = document.querySelector('#streetAddress');
            const headerMobile = document.querySelector('div.header-mobile');
            if (input) {
              window.scrollTo({
                top: input.offsetTop - 30 - (headerMobile ? headerMobile.clientHeight : 0),
                behavior: 'smooth',
              });
            }
          }}
          confirmPopup={() => {
            this.props.manifestStore.saveUserData(this.props.manifestData, this.state.formValues).catch(this.onError);
            this.setState((currState) => ({ ...currState, showPopup: false }));
          }}
        />
      </div>
    );
  }
}
