// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { itsUrlWithSize } from '../../utils/image';

import Headline from '../../components/Headline';
import LoadingIndicator from '../../components/LoadingIndicator';
import Tile from '../../components/tiles/Tile';

import type MasterStore from '../../stores/MasterStore';
import type RestaurantStore from '../../stores/RestaurantStore';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromDateObject } from '../../utils/tracking';
import tracking from '../../tracking';
import RestaurantOffer from '../../models/RestaurantOffer';

type Props = {
  masterStore: MasterStore,
  restaurantStore: RestaurantStore,
};

@inject('restaurantStore', 'masterStore', 'breadcrumbRouterStore')
@observer
export default class PageRestaurant extends Component<Props> {
  static breadcrumb = 'Tischreservierung';

  componentDidMount() {
    this.props.restaurantStore.fetchList();
  }

  badgeFor(restaurantId: string) {
    const bookingArr = this.props.masterStore.masterData.getBookingsByTypeId(restaurantId);
    if (bookingArr.length === 0) return null;
    return bookingArr[0].prelimenary ? 'in Bearbeitung' : 'Reserviert';
  }

  badgeIsBooked(restaurantId: string) {
    return this.props.masterStore.masterData.getBookingsByTypeId(restaurantId).length > 0;
  }

  triggered = false;

  render() {
    const { restaurantStore } = this.props;

    if (restaurantStore.list && this.triggered === false) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = restaurantStore.list.map((item: RestaurantOffer) => ({
        code: item.venueId,
        name: item.title,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: '0.00',
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="page page-table-reservation">
        <Headline title="Tischreservierungen" icon="table-reservation" />

        {restaurantStore.isLoadingList ? (
          <LoadingIndicator />
        ) : (
          <div className="l-tiles">
            {restaurantStore.list.map((restaurant, index) => (
              <div
                key={restaurant.id}
                onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
              >
                <Tile
                  full
                  badge={this.badgeFor(restaurant.id)}
                  isBooked={this.badgeIsBooked(restaurant.id)}
                  link={`/tischreservierung/${restaurant.id}`}
                  imageSrc={itsUrlWithSize(restaurant.imageSrc, 272, 162)}
                  smallImageSrc={itsUrlWithSize(restaurant.imageSrc, 68, 68)}
                  title={restaurant.title}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
