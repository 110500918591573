// @flow

import useLockBodyScroll from '../hooks/useLockBodyScroll';
type Options = {
  maxScreenWidth?: number;
};
export default function LockBodyScroll(options?: Options) {
  useLockBodyScroll(options);

  return null;
}
