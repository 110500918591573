// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, ApiResponse } from '../api/vipOption';

export default class VipOptionStore {
  @observable loading: boolean = false;
  @observable vipOption: ?ApiResponse = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  @action
  fetch(tariffId: string) {
    this.loading = true;
    return this.api
      .get(this.masterStore.user, tariffId)
      .then((vipOption: ApiResponse) => {
        this.receiveVipOption(vipOption);
      });
  }

  @action
  receiveVipOption(vipOption: ApiResponse) {
    this.vipOption = vipOption;
    this.loading = false;
  }
}
