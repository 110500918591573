// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Headline from '../../Headline';
import SpaCategoryTiles from '../../SpaCategoryTiles';

import type SpaStore from '../../../stores/SpaStore';

type Props = {
  spaStore: SpaStore,
  location: { pathname: string },
};

export default function spaCategories(type: string, title: string, headlineIcon: string) {
  @inject('spaStore')
  @observer
  class SpaCategories extends Component<Props> {
    static breadcrumb = title;

    componentDidMount() {
      this.props.spaStore.fetchCategories();
    }

    render() {
      const categories = this.props.spaStore.categoriesByType[type] || [];
      return (
        <div className="page">
          <Headline title={title} icon={headlineIcon} />

          <SpaCategoryTiles categories={categories} linkBase={this.props.location.pathname} />
        </div>
      );
    }
  }

  return SpaCategories;
}
