// @flow

import config from '../config';

export const logoutRedirect = (url?: string) => {
  window.location.href = url || config.logoutURL;
};

export const getUrlParameterByName = (name: string, url: string): string => {
  if (!url) {
    url = window.location.href;
  }

  const match = new RegExp('[?&#]' + name + '=([^&]*)').exec(url);
  return match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : '';
};
