// @flow
import React, { Component } from 'react';

import Price from '../Price';

type Props = {
  price: ?number,
};

export default class DailyEventPrice extends Component<Props> {
  render() {
    const { price } = this.props;

    if (price === 0) {
      return <div className="l-mod-sub price">Inklusive</div>;
    }

    if (!price) return null;

    return (
      <div className="l-mod-sub">
        <Price price={price} label="Preis" />
      </div>
    );
  }
}
