// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import type InternetBookingRequest from '../../models/BookingRequest/InternetBookingRequest';
import type Bookable from '../../models/Bookable';

import Price from '../Price';
import Button from '../Button';

type Props = {
  bookable: Bookable,
  bookingRequest: InternetBookingRequest,
  onBooking: ?Function,
};

@observer
export default class InternetBookingAssistant extends Component<Props> {
  renderButton() {
    const { bookingRequest, bookable, onBooking } = this.props;
    if (bookable.statusCode === 0) {
      return (
        <Button dark big disabled={!bookingRequest.isReady} onClick={onBooking}>
          Zur Reservierung
        </Button>
      );
    } else {
      return (
        <Button dark big disabled>
          {bookable.bookingStatus}
        </Button>
      );
    }
  }

  render() {
    const { bookingRequest, bookable } = this.props;

    return (
      <div>
        <p>
          Internetarife können nur einmalig und persönlich reserviert werden.
          Sollten Sie einen anderen Tarif wünschen, können Sie die bereits
          reservierte Leistung kostenfrei stornieren und den gewünschten Tarif
          reservieren.
        </p>
        <div className="l-right">
          {!bookingRequest.hasBookedSomethingOfSameType ? (
            <div className="l-mod-sub">
              <Price price={bookable.priceText} unit={bookable.priceLabel} />
            </div>
          ) : null}
          {this.renderButton()}
        </div>
      </div>
    );
  }
}
