// @flow
import type User from '../models/User';
import type MasterData from '../models/MasterData';

import fetchForUser from '../utils/fetchForUser';
import { getPinHeader, isLocal, mockAll } from './utils/';

const mock = false;
const getManifestUrl = (mpi: string | number): string =>
  (mock || mockAll) && isLocal
    ? `/mock/manifest/info.json`
    : `/api/land/shipmanifest/${mpi}`;
const getStoreManifestUrl = (mpi: string | number): string =>
  (mock || mockAll) && isLocal
    ? `/mock/manifest/TBD.json`
    : `/api/land/shipmanifest/store/${mpi}`;
const getCountryOptionsUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/manifest/country-options.json`
    : `/api/land/shipmanifest/countryoptions`;
const getNationalityOptionsUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/manifest/nationality-options.json`
    : `/api/land/shipmanifest/nationalityoptions`;
// regulations_1645012301_1647431501_meinschiff5
// regulations_1646221901_1647431501_meinschiff5
// regulations_fallback
// regulations   -> old
const getRegulationsUrl = (masterData: MasterData): string =>
  (mock || mockAll) && isLocal
    ? `/mock/manifest/regulations_3.json`
    : `/api/land/shipmanifest/regulations/${masterData.ed}/${masterData.dd}/${
        masterData.shipId
      }`;

export default {
  getForMpi: (user: User, mpi: string | number) =>
    Promise.all([
      fetchForUser(user, getManifestUrl(mpi), {
        ...getPinHeader(mpi),
      }),
      fetchForUser(user, getCountryOptionsUrl()),
      fetchForUser(user, getNationalityOptionsUrl()),
    ]).then(([info, countryOptions, nationalityOptions]) => {
      return {
        ...info,
        manifestOptions: {
          ...info.manifestOptions,
          contact: {
            ...info.manifestOptions.contact,
            countryOptions,
          },
          credentials: {
            ...info.manifestOptions.credentials,
            nationalityOptions,
          },
        },
      };
    }),
  sendForMpi: (user: User, mpi: string | number, data: any) => {
    if (data.entryVisa) {
      data = {
        ...data,
        entryVisa: {
          hasVisa: data.entryVisa.hasVisa,
          passportNumber: data.entryVisa.visaPassportNumber,
          placeOfIssue: data.entryVisa.visaPlaceOfIssue,
          passportType: data.entryVisa.visaPassportType,
          dateOfIssue: data.entryVisa.visaDateOfIssue,
          validUntil: data.entryVisa.visaValidUntil,
        },
      };
    }
    return fetchForUser(user, getStoreManifestUrl(mpi), {
      ...getPinHeader(mpi),
      method: 'post',
      body: JSON.stringify(data),
    });
  },
  getRegulations: (user: User, masterData: MasterData) =>
    fetchForUser(user, getRegulationsUrl(masterData)),
};
