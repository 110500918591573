// @flow
import { assign, required } from '../../utils/model';

import HighlightItem from './Item';
import Itinerary from '../../models/Itinerary';

export default class Highlights {
  headline: string;
  text: string;
  imageUri: string;
  links: any;
  date: any;
  itinerary: Itinerary;
  dayIndex: ?number;
  infoBox: ?{
    headline: string,
    text: string,
    pdfs: ?[
      {
        index: number,
        name: string,
        title: string,
        url: string,
      },
    ],
  };

  constructor(data: Object, itinerary: Itinerary) {
    assign(this, data, {
      headline: required(String),
      text: required(String),
      imageUri: String,
    });
    this.itinerary = itinerary;
    this.dayIndex = itinerary.dayIndexOf(this.date);

    this.links =
      data.links && data.links.length > -1 ? data.links.map(link => new HighlightItem(link, this.dayIndex)) : null;
    this.infoBox = data.infoBox || null;
    if (this.infoBox && this.infoBox.pdfs) {
      this.infoBox.pdfs.sort((a, b) => a.index - b.index);
    }
  }

  get imageSrc(): string {
    return this.imageUri;
  }
}
