// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import InfoList from './InfoList';
import Rating from './Rating';
import ToggleInfoBox from './ToggleInfoBox';

export default class ExcursionInfoList extends Component {
  static propTypes = {
    model: PropTypes.object.isRequired,
  };

  get infos() {
    const excursion = this.props.model;
    const operator = excursion.displayOperator;
    let operatorValue = null;

    if (operator) {
      if (operator.hasAddress) {
        operatorValue = (
          <ToggleInfoBox
            plain
            moreLink="Mehr Informationen"
            lessLink="Weniger Informationen"
            title={
              <p>
                <strong>
                  {operator.name} (Nr. {operator.code})
                </strong>
              </p>
            }
          >
            <p>
              <strong>Anschrift:</strong>
              <br />
              <span dangerouslySetInnerHTML={{ __html: operator.address }} />
            </p>
            <p>
              <strong>E-Mail:</strong>
              <br />
              {operator.email}
            </p>
            <p>
              <strong>Telephone:</strong>
              <br />
              {operator.phone}
            </p>
          </ToggleInfoBox>
        );
      } else {
        operatorValue = (
          <p>
            <strong>
              {operator.name} (Nr. {operator.code})
            </strong>
          </p>
        );
      }
    }

    let vacancy = excursion.vacancy || 0;
    return [
      { key: 'Besonderer Hinweis', value: excursion.notes },
      {
        key: 'Weiterführende Informationen',
        value: excursion.generalWalkingInfo,
      },
      {
        key: 'Level',
        value: (
          <ToggleInfoBox
            plain
            moreLink="Mehr Informationen"
            lessLink="Weniger Informationen"
            title={
              <p>
                <Rating value={excursion.difficultyNumber} />{' '}
                <strong>{excursion.difficultyDisplay}</strong>
              </p>
            }
          >
            <p dangerouslySetInnerHTML={{ __html: excursion.difficultyDescription }} />
          </ToggleInfoBox>
        ),
      },
      {
        key: 'Dauer',
        value: excursion.duration,
      },
      vacancy !== 'hidden'
        ? {
            key: 'Verfügbarkeit',
            value: `${vacancy > 20 ? '> 20' : vacancy} ${
              vacancy === 1 ? 'Platz' : 'Plätze'
            }`,
          }
        : {},
      { key: 'Kategorie', value: excursion.displayCategories },
      {
        key: 'Mindestalter',
        value: excursion.minimumAge ? `${excursion.minimumAge} Jahre` : null,
      },
      { key: 'Verpflegung', value: excursion.catering },
      { key: 'Ausflugscode', value: excursion.bookingCode },
      {
        key: 'Veranstalter',
        value: operatorValue,
      },
    ].filter(info => info.value);
  }

  render() {
    return <InfoList spaced infos={this.infos} />;
  }
}
