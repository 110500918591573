// @flow

import React, { Component, type Node } from 'react';
import Description from './Description';
import type ServiceItem from '../../../models/BoardAccount/ServiceItem';

type Props = {
  items: ServiceItem[],
  hasVip1: boolean,
  hasVip2: boolean,
};

export default class InclusiveBookingTable extends Component<Props> {
  hasVipPackage(type: number): boolean {
    const { hasVip1, hasVip2 } = this.props;
    return type === 1 ? hasVip1 : hasVip2;
  }

  renderVipOptionLinks(): Node[] {
    return [1, 2].map(
      t =>
        this.hasVipPackage(t) ? (
          <div key={t} className="booking-table__row booking-table__link">
            <div className="booking-table__cell no-border">
              Mehr über die{' '}
              <a href={`/buchungsdetails/vip-tarif/${t}`}>
                VIP-Tarifoption {t}
              </a>
            </div>
            <div className="booking-table__cell empty" />
            <div className="booking-table__cell empty" />
          </div>
        ) : null
    );
  }

  render() {
    const { items } = this.props;

    return (
      <div className="l-block">
        <div className="booking-table inclusive-booking l-mod">
          <div className="booking-table__header">
            <div className="booking-table__row">
              <div className="booking-table__cell">Beschreibung</div>
              <div className="booking-table__cell" />
              <div className="booking-table__cell" />
            </div>
          </div>

          {items.map((item, index) => (
            <div key={index} className="booking-table__body">
              <div className="booking-table__row no-border">
                <div className="booking-table__cell booking-table__name no-border">
                  <Description item={item} />
                </div>
                <div className="booking-table__cell empty no-border" />
                <div className="booking-table__cell booking-table__price no-border">
                  {item.amount ? (
                    <span className="bold">{item.displayAmount}</span>
                  ) : (
                    'inklusive'
                  )}
                </div>
              </div>
            </div>
          ))}

          {this.renderVipOptionLinks()}

          <div className="booking-table__footer">
            <div className="booking-table__row">
              <div className="booking-table__cell empty" />
              <div className="booking-table__cell empty" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
