// TODO: add flow types

import { observable } from 'mobx';

import TypeStore from './OfferOrPackageTypeStore';
import SpaOffer from '../models/SpaOffer';
import SpaOfferInclusive from '../models/SpaBalconyCabin/SpaOfferInclusive';

export default class OfferStore {
  static KLASSES = {
    beauty: SpaOffer,
    spaBalconyCabin: SpaOfferInclusive,
  };

  @observable typeStores = {};

  masterStore = null;
  api = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  storeForType(type) {
    if (!this.typeStores[type]) {
      this.typeStores[type] = new TypeStore(
        this.api,
        this.masterStore,
        type,
        this.constructor.KLASSES[type]
      );
    }
    return this.typeStores[type];
  }
}
