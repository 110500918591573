// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckboxGroupItem from './CheckboxGroupItem';

const CheckboxGroup = ({ children, className }) => (
  <fieldset
    className={classNames({
      'form-checkbox-group': true,
      [className]: !!className,
    })}
  >
    {children}
  </fieldset>
);

CheckboxGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CheckboxGroup.Item = CheckboxGroupItem;

export default CheckboxGroup;
