// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';

export default class RemainingCounterBase extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    // Number of characters user can enter
    maxLength: PropTypes.number,
    // If trueish we show the number of chars left to enter
    showRemaining: PropTypes.bool,
    onChange: PropTypes.func,
  };

  state = {
    value: this.props.value || '',
  };

  _calcCharsLeft(value) {
    return this.props.maxLength - value.length;
  }

  @autobind
  onChange(event) {
    const newValue = this.props.maxLength
      ? event.target.value.substr(0, this.props.maxLength)
      : event.target.value;
    this.setState({
      value: newValue,
    });
    if (this.props.onChange) {
      this.props.onChange(newValue);
    }
  }

  renderRemaining() {
    if (!this.props.maxLength || !this.props.showRemaining) {
      return null;
    }
    const charsLeft = this._calcCharsLeft(this.state.value);
    return <div className="form-input__remaining">{charsLeft}</div>;
  }
}
