// @flow

export const STATIC_TUIC_OPERATOR = {
  operatorCode: '50000000',
  operatorName: 'TUI Cruises GmbH',
  departments: [
    {
      departmentId: 10000968,
      address1: 'Heidenkampsweg 58',
      cityName: 'Hamburg',
      postalCode: '20097',
      phone: '+49 40 60 00 15 00 0',
      email: 'demo@marinexchange.com',
      countryName: 'Deutschland',
    },
  ],
};

type Department = {
  address1: ?string,
  address2: ?string,
  address3: ?string,
  cityName: ?string,
  departmentId: ?number,
  email: ?string,
  phone: ?string,
  postalCode: ?string,
};

const getAddress = (d: Department): string => {
  const field = (f: ?string): string => {
    return f ? `${f}<br />` : '';
  };

  const address = `${field(d.address1)}${field(d.address2)}${field(
    d.address3
  )}`;
  const pc = `${d.postalCode ? d.postalCode + ' ' : ''}`;
  return `${address}${pc}${d.cityName || ''}`;
};

export default class ExcursionOperator {
  name: string;
  code: string;
  address: string;
  email: string;
  phone: string;

  constructor(data: Object) {
    this.name = data.operatorName;
    this.code = data.operatorCode;
    const department = data.departments ? data.departments[0] : null;

    if (department) {
      this.address = getAddress(department);
      this.email = department.email;
      this.phone = department.phone;
    }
  }

  static createFromArray(data: Object[]): ?ExcursionOperator {
    if (!data || data.length === 0) return null;

    return new ExcursionOperator(data[0]);
  }

  get hasAddress(): boolean {
    return !!this.address;
  }

  get isTUIC(): boolean {
    return this.code === '50000000';
  }

  get operatorClassName(): string {
    return `operator ${this.isTUIC ? 'tuic' : 'external'}`;
  }
}
