//@flow
import React from 'react';
import Picture from './Picture';
//import ServiceInfo from './ServiceInfo';
import { loadImg } from '../utils/image';
import LinkAsButton from './LinkAsButton';

const ServiceTeamTeaser = () => (
  <div>
    <h2>Unser Serviceteam</h2>
    <Picture src={loadImg('c/service-272x115.jpg')} />
    <div className="teaser-block-top">
      <p>
        Bei Fragen zu Ihrer bereits gebuchten Kreuzfahrt wenden Sie sich bitte
        vertrauensvoll an Ihr Reisebüro.
      </p>
    </div>
    <p>
      Unser Serviceteam steht Ihnen bei Fragen zu unserer Website gerne zur
      Verfügung.
    </p>
    <div className="l-right">
      <LinkAsButton newPage link="https://www.meinschiff.com/kontakt/">
        Kontakt
      </LinkAsButton>
    </div>
  </div>
);

export default ServiceTeamTeaser;
