// @flow
import { computed } from 'mobx';
import includes from 'lodash/includes';
import parse from 'date-fns/parse';

import { DIFFICULTIES, DIFFICULTY_ANY } from '../config/constants';

import ExcursionOperator from './ExcursionOperator';
import Bookable from './Bookable';
import ItineraryDay from './ItineraryDay';
import PriceTypes from './PriceTypes';
import { prepareText } from '../utils/prepareText';
import type MasterStore from '../stores/MasterStore';
import type { INavigation, IVipStatus } from '../types/bookable';

export default class Excursion extends Bookable {
  bookingCode: string;
  categoryIds: number[];
  catering: string;
  description: string;
  descriptionHtml: ?string;
  departureTime: number;
  difficulty: string;
  difficultyDescription: string;
  difficultyDisplay: string;
  digitalSaleAllowed: boolean;
  displayDate: string;
  duration: string;
  endDate: ?string;
  endTime: ?string;
  generalWalkingInfo: ?string;
  headline: string;
  hint: ?string;
  id: number;
  image: string;
  images: ?(Object[]);
  isPriceBulk: boolean;
  itineraryDate: ?string;
  maximumAge: ?number;
  minimumAge: ?number;
  name: string;
  notes: ?string;
  operators: Object[];
  preReserve: boolean;
  priceBulkText: ?string;
  routeDays: number;
  startDate: ?string;
  startTime: ?string;
  status: string;
  statusCode: number;
  statusVip1: string;
  // statusVip2 is not provided by Backend, therefore not used yet
  statusVip2: string;
  statusCodeVip1: number;
  // statusCodeVip2 is not provided by Backend, therefore not used yet
  statusCodeVip2: number;
  statusTags: ?(string[]);
  timeOfDay: string;
  tourSalesTermsId: number;
  tourSalesTermsText: ?string;
  type: string;
  vacancy: ?number;
  vacancyId: string;
  textVip1: ?string;
  text20Percent: ?string;
  text20PercentPro: ?string;
  text10PercentPlus: ?string;
  priceTypes: PriceTypes;
  navigation: INavigation;

  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);

    if (data.priceTypes && data.priceTypes[0]) {
      this.priceTypes = new PriceTypes(data.priceTypes[0]);
    }

    if (data.difficultyDescription) {
      this.difficultyDescription = prepareText(data.difficultyDescription, ['a']);
    }

    this.text10PercentPlus = data['10PercentTextPlus'];
    this.text20Percent = data['20PercentText'];
    this.text20PercentPro = data['20PercentTextPro'];
  }

  getPriceTypes(): PriceTypes {
    return this.priceTypes;
  }

  hasStatusTag(tag: string) {
    if (!this.statusTags || !this.statusTags.length) return false;
    return this.statusTags.includes(tag);
  }

  get bookingName(): string {
    return 'Landausflug';
  }

  @computed get analyticsCategory(): string {
    if (!this.day) return 'Landausflüge';
    return `Landausflüge/${this.day.port.name}`;
  }

  @computed get day(): ?ItineraryDay {
    return this.masterStore.itinerary.dayByDate(this.date);
  }

  // Returns a number between 0 and 4
  get difficultyNumber(): number {
    return DIFFICULTIES.indexOf(this.difficulty);
  }

  get isExternalOperator(): boolean {
    if (!this.displayOperator) return false;
    return !this.displayOperator.isTUIC;
  }

  isBookableForRequestor(requestorVip?: IVipStatus): boolean {
    let statusCode;
    switch (requestorVip) {
      case 1:
        statusCode = this.statusCodeVip1;
        break;
      case 2:
        // show Statuscode 1 as long as Backend doesn't provide statusCodeVip2
        statusCode = this.statusCodeVip1;
        break;
      default:
        statusCode = this.statusCode;
        break;
    }
    return statusCode === 0;
  }

  get badgeText(): string {
    return this.status;
  }

  getBadgeText(vip?: IVipStatus): string {
    switch (vip) {
      case 1:
        return this.statusVip1 ? this.statusVip1 : this.status;
      case 2:
        // show statusVip1 as long as statusVip2 is not provided by Backend
        return this.statusVip1 ? this.statusVip1 : this.status;
      default:
        return this.status;
    }
  }

  get title(): string {
    return this.name;
  }

  get displayDuration(): string {
    return this.duration;
  }

  get date(): string {
    return this.startDate || this.itineraryDate || '';
  }

  get time(): ?string {
    return this.startTime;
  }

  get startDateTime(): ?Date {
    if (!this.date || !this.startTime) return null;
    return parse(`${this.date}T${this.startTime}:00`);
  }

  get endDateTime(): ?Date {
    if (!this.endDate || !this.endTime) return null;
    return parse(`${this.endDate}T${this.endTime}:00`);
  }

  get displayCategories(): string {
    return Object.values(this.categoryIds).join(', ');
  }

  get imageSrc(): string {
    return this.image;
  }

  get isSoldOut(): boolean {
    return this.vacancy != null && this.vacancy === 0;
  }

  matchesDifficulties(difficulties: number[] = []) {
    if (!difficulties.length || includes(difficulties, DIFFICULTY_ANY)) {
      return true;
    }
    return includes(difficulties, this.difficulty);
  }

  matchCategories(ids: ?(number | number[])) {
    if (!ids) return true;
    if (ids instanceof Array) {
      if (!ids.length) return true;
      return this.categoryIds.some((cat) => includes(ids, cat));
    }
    return includes(this.categoryIds, ids);
  }

  matches(filter: { categoryIds: ?(number[]), categoryId: ?number, difficulties: number[] }) {
    return (
      this.matchCategories(filter.categoryIds || filter.categoryId) && this.matchesDifficulties(filter.difficulties)
    );
  }

  get displayOperator(): ?ExcursionOperator {
    if (!this.operators || this.operators.length === 0) return null;

    return new ExcursionOperator(this.operators[0]);
  }

  get operatorClassName(): string {
    if (!this.displayOperator) return '';
    return `operator ${this.displayOperator.isTUIC ? 'tuic' : 'external'}`;
  }

  get requiresTourSalesTermsNotice(): boolean {
    return !!this.tourSalesTermsText;
  }

  get percentText(): ?string {
    if (!this.masterStore || !this.masterStore.masterData) return null;

    const { requestorHasFeelGoodPlusTariff, requestorHasProTariff, requestorHasPlusTariff } =
      this.masterStore.masterData;

    const text = requestorHasFeelGoodPlusTariff && this.text20Percent;
    const textPro = requestorHasProTariff && this.text20PercentPro;
    const textPlus = requestorHasPlusTariff && this.text10PercentPlus;

    return text || textPro || textPlus;
  }

  get pdfLink(): string {
    return this.navigation && this.navigation.url;
  }
}
