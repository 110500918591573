// @flow
import React from 'react';

import ContentRegulations from './Content';
import Dialog from '../../Dialog';
import Button from '../../Button';

type Props = React.ComponentPropsWithoutRef<typeof ContentRegulations> & {
  btnText?: string,
};

export default function DialogRegulations(props: Props) {
  const [open, setOpen] = React.useState(false);
  const { regulations, btnText } = props;

  if (!regulations) return null;

  return (
    <>
      <Button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          setOpen(true);

          return false;
        }}
      >
        {btnText}
      </Button>
      <Dialog headline={regulations.headline} onClose={() => setOpen(false)} open={open}>
        <ContentRegulations regulations={regulations} />
      </Dialog>
    </>
  );
}
