// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import type PackageBookingRequest from '../../models/BookingRequest/PackageBookingRequest';

import Price from '../Price';
import Button from '../Button';
import FormSelect from '../form/Select';

type Props = {
  title: ?string,
  bookingRequest: PackageBookingRequest,
  onBooking: Function,
};

@observer
export default class PackageBookingAssistant extends Component<Props> {
  @autobind
  handleChangeInCount(count: number) {
    this.props.bookingRequest.selectCount(count);
  }

  render() {
    const { bookingRequest, title, onBooking } = this.props;
    const { bookable } = bookingRequest;

    const countOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(c => ({
      label: String(c),
      value: c,
    }));

    if (!bookable.isBookable) {
      return (
        <div className="l-right">
          <Button disabled big dark>
            {bookable.bookingStatus}
          </Button>
        </div>
      );
    }

    return (
      <div>
        {title ? <h2 dangerouslySetInnerHTML={{ __html: title }} /> : null}
        <FormSelect
          name="participant"
          placeholder="Anzahl auswählen…"
          options={countOptions}
          onChange={this.handleChangeInCount}
          value={String(bookingRequest.selectedCount)}
        />
        <div className="l-right">
          <div className="l-mod-sub">
            <Price label="Gesamtpreis" price={bookingRequest.total} />
          </div>
          {bookable.statusCode === 0 ? (
            <Button
              dark
              big
              disabled={!bookingRequest.isReady}
              onClick={onBooking}
            >
              Zur Reservierung
            </Button>
          ) : (
            <Button dark big disabled>
              {bookable.bookingStatus}
            </Button>
          )}
        </div>
      </div>
    );
  }
}
