//@flow
import PropTypes from 'prop-types';
import { PropTypes as MobXPropTypes } from 'mobx-react';

import ExcursionBookingRequest from '../models/BookingRequest/ExcursionBookingRequest';
import SpaBookingRequest from '../models/BookingRequest/SpaBookingRequest';
import RestaurantBookingRequest from '../models/BookingRequest/RestaurantBookingRequest';
import DailyEventBookingRequest from '../models/BookingRequest/DailyEventBookingRequest';
import PackageBookingRequest from '../models/BookingRequest/PackageBookingRequest';
import NewspaperBookingRequest from '../models/BookingRequest/NewspaperBookingRequest';
import InternetBookingRequest from '../models/BookingRequest/InternetBookingRequest';
import BoardingPass from '../models/BoardingPass';

const userShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  apiToken: PropTypes.string.isRequired,
});

const travelPartyMemberShape = PropTypes.shape({
  mpi: PropTypes.number.isRequired,
  displayName: PropTypes.string.isRequired,
});
const travelPartyShape = PropTypes.arrayOf(travelPartyMemberShape);

const tilesConfigShape = PropTypes.object;

const bookingRequestShape = PropTypes.oneOfType([
  PropTypes.instanceOf(ExcursionBookingRequest),
  PropTypes.instanceOf(SpaBookingRequest),
  PropTypes.instanceOf(RestaurantBookingRequest),
  PropTypes.instanceOf(DailyEventBookingRequest),
  PropTypes.instanceOf(NewspaperBookingRequest),
  PropTypes.instanceOf(InternetBookingRequest),
  PropTypes.instanceOf(PackageBookingRequest),
]);

const priceShape = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

const optionsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    priority: PropTypes.bool,
  })
);

const boadingPassListShape = PropTypes.oneOfType([
  MobXPropTypes.observableArrayOf(PropTypes.instanceOf(BoardingPass)),
  PropTypes.arrayOf(PropTypes.instanceOf(BoardingPass)),
]);

export {
  userShape,
  travelPartyShape,
  travelPartyMemberShape,
  tilesConfigShape,
  bookingRequestShape,
  priceShape,
  optionsShape,
  boadingPassListShape,
};
