// @flow

import { assign, required } from '../../utils/model';

import type { ApiResponse } from '../../api/appStoreTiles';

export default class AppStoreTeaser {
  headline: string;
  imageUri: string;
  text: string;

  constructor(data: ApiResponse) {
    assign(this, data, {
      headline: required(String),
      imageUri: required(String),
      text: required(String),
    });
  }

  get imageSrc(): string {
    return this.imageUri;
  }
}
