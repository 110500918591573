// @flow
import React from 'react';

import ContentRegulations from './Content';
import Headline from '../../Headline';

type Props = React.ComponentPropsWithoutRef<typeof ContentRegulations>;

export default function ViewRegulations(props: Props) {
  const { regulations } = props;

  return (
    <div className="page page-manifest-entry-reqs">
      <Headline className="l-mod" title={regulations.headline} />
      <ContentRegulations regulations={regulations} />
    </div>
  );
}
