// @flow

import ExcursionBookingRequest from './ExcursionBookingRequest';
import PartyMember from '../PartyMember';
import IncludedShoreExDetail from '../IncludedShoreEx/IncludedShoreExDetail';
import type {
  IBookingOption,
  IMpiBookingUpdateResponse,
  IMpiReservationStatus,
  IBookingOptionConflict,
  ILegalNotice,
} from '../../api/includedShoreEx';
import IncludedBookingUpdateRequest from '../IncludedShoreEx/IncludedBookingUpdateRequest';
import BookingRequestBase from './BookingRequestBase';
import IncludedShoreExDetailStatus from '../IncludedShoreEx/IncludedShoreExDetailStatus';
import { computed } from 'mobx';

export default class IncludedShoreExBookingRequest extends ExcursionBookingRequest {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      success: {
        title: 'Ihre Reservierungsanfrage ist eingegangen.',
        text:
          'Vielen Dank für Ihre Reservierungsanfrage. Den aktuellen Status Ihrer Anfrage können Sie in Ihrem Reiseplan einsehen. Bitte beachten Sie, dass es sich bei Ihrer Reservierungsanfrage um einen Wunschtermin handelt. Wir behalten uns vor, Ihren Termin gegebenenfalls zu verschieben. Aufgrund dieser Änderungen empfehlen wir, Ihren Reiseplan vor der Reise und an Bord auf Aktualität zu prüfen. <br /><br />Vielen Dank für Ihr Verständnis.',
      },
    },
    cancellation: {
      ...BookingRequestBase.TEXTS.cancellation,
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: bookingRequest =>
          `Möchten Sie den ${
            bookingRequest.bookable.bookingName
          } wirklich für folgende Reiseteilnehmer stornieren?`,
      },
    },
  };

  bookingStatus: IncludedShoreExDetailStatus;
  bookingRequestData: IncludedBookingUpdateRequest;
  bookable: IncludedShoreExDetail | any;
  edFull: string;
  responseList: IMpiBookingUpdateResponse[];

  constructor(
    travelParty: PartyMember[],
    edFull: string,
    bookable: IncludedShoreExDetail,
    bookingStatus: IncludedShoreExDetailStatus
  ) {
    super(travelParty, bookable);
    this.bookingStatus = bookingStatus;
    this.edFull = edFull;
  }

  set responseState(list: IMpiBookingUpdateResponse[]) {
    this.responseList = list;
  }

  get responseState(): IMpiBookingUpdateResponse[] {
    return this.responseList || [];
  }

  @computed
  get bookingData(): Object[] {
    return this.bookingRequestData.list;
  }

  get bookingOptions(): IBookingOption[] {
    return this.bookingStatus ? this.bookingStatus.bookingOptionList : [];
  }

  get mpiBookings(): ?(IMpiReservationStatus[]) {
    return this.bookingStatus ? this.bookingStatus.mpiBookingList : null;
  }

  get legalNotes(): ILegalNotice[] {
    return this.bookingStatus ? this.bookingStatus.legalNoteList : [];
  }

  getLegalNoteById(legalNoteId: string): ?ILegalNotice {
    return this.legalNotes.find(n => n.legalNoteId === legalNoteId);
  }

  getBookingStateByMpi(mpi: number): ?IMpiReservationStatus {
    if (!this.mpiBookings) return null;
    return this.mpiBookings.find(t => t.mpi === mpi);
  }

  getPartyMember(mpi: number): ?PartyMember {
    if (!this.travelParty) return null;
    return this.travelParty.find(t => t.mpi === mpi);
  }

  getBookingOptionById(bookingOptionId: string): ?IBookingOption {
    if (!bookingOptionId || !this.bookingOptions) return null;
    return this.bookingOptions.find(t => t.bookingOptionId === bookingOptionId);
  }

  @computed
  get vacancies(): Object {
    const vacancies = {};

    const getVacancyCountText = (vacancyCount: ?number): string => {
      const count = vacancyCount || 0;
      const countText =
        count > 20 ? '> 20' : `${count === 0 ? 'Keine' : count}`;
      const p = count === 1 ? 'Platz' : 'Plätze';
      return `(${countText} ${p} verfügbar)`;
    };

    const deDupedBookingOptions = this.bookingOptions.filter(
      (option, index, bookingOptions) =>
        index ===
        bookingOptions.findIndex(
          o => o.bookingOptionId === option.bookingOptionId
        )
    );

    deDupedBookingOptions.forEach(bookingOption => {
      if (!vacancies[bookingOption.date]) {
        vacancies[bookingOption.date] = deDupedBookingOptions
          .filter(bo => {
            return bo.date === bookingOption.date;
          })
          .map(bo => ({
            value: bo.bookingOptionId,
            label: `${bo.time} Uhr ${getVacancyCountText(bo.vacancyCount)}`,
          }))
          .sort((a, b) => {
            // sort by time
            const ta = a.label
              .replace(' Uhr', '')
              .split(':')
              .join('');
            const tb = b.label
              .replace(' Uhr', '')
              .split(':')
              .join('');
            return ta > tb ? 1 : ta < tb ? -1 : 0;
          });
      }
    });

    return vacancies;
  }

  @computed
  get daySlots(): Object[] {
    return Object.keys(this.vacancies)
      .map(day => ({
        value: day,
        label: day,
      }))
      .sort((a, b) => {
        // sort by date
        const ta = a.label
          .split('.')
          .reverse()
          .join('');
        const tb = b.label
          .split('.')
          .reverse()
          .join('');
        return ta > tb ? 1 : ta < tb ? -1 : 0;
      });
  }

  @computed
  get itemsToBook(): Object[] {
    return this.bookingData
      .map(item => {
        const itemToBook = this.getBookingOptionById(item.bookingOptionId);
        if (itemToBook) {
          const member = this.getPartyMember(item.mpi);
          return {
            ...item,
            ...itemToBook,
            name: member ? member.displayName : '',
          };
        }
      })
      .filter(item => !!item);
  }

  @computed
  get waived(): Object[] {
    return this.bookingData
      .map(item => {
        if (!this.getBookingOptionById(item.bookingOptionId)) {
          const member = this.getPartyMember(item.mpi);
          return {
            name: member ? member.displayName : '',
            mpi: item.mpi,
          };
        }
      })
      .filter(item => !!item);
  }

  getBookingConflictsForMpi(mpi: number): IBookingOptionConflict[] {
    const state = this.getBookingStateByMpi(mpi);
    if (!state || !state.bookingOptionConflictList) return [];
    return state.bookingOptionConflictList.list || [];
  }

  getBookingConflict(
    mpi: number,
    bookingOptionId: string
  ): ?IBookingOptionConflict {
    return this.getBookingConflictsForMpi(mpi).find(
      (c: IBookingOptionConflict) => c.bookingOptionId === bookingOptionId
    );
  }
}
