// @flow

/**
 * TUICUNIT-911
 *
 * https://tuic-mrl.dev.cellular.de/api/apidoc/index.html#api-Land-manifestdetail
 */
import pinStorage from '../../utils/pinStorage';
import type User from '../../models/User';

export const getPinHeader = (mpi: number): ?Object => {
  const pin = pinStorage.getPin(mpi);

  const options = {
    pinRequired: true,
    mpi,
  };

  if (!pin) return options;

  return {
    ...options,
    headers: {
      'Tuic-Customer-Pin': pin,
    },
  };
};

export const mockAll = false;

export const isLocal =
  window.location.href.indexOf('http://local.dev.cellular.de:3000') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3005') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3050') >= 0;

// TODO reference from
//  import { isLocal } from '../utils/environment';

type Options = {
  url: string,
  fileName: string,
  contentType: string,
};

export const downloadBinaryDataAsBlob = (user: User, options: Options): Promise<*> =>
  fetch(options.url, {
    headers: {
      Authorization: `Bearer ${user.apiToken}`,
      cache: 'no-cache',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    })
    .catch((r) => console.log(r));

export const downloadBinaryData = (user: User, options: Options): Promise<*> =>
  fetch(options.url, {
    headers: {
      Authorization: `Bearer ${user.apiToken}`,
      cache: 'no-cache',
    },
  })
    // https://stackoverflow.com/questions/32545632/how-can-i-download-a-file-using-window-fetch
    .then((response) => response.blob())
    .then((blob) => {
      try {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = options.fileName;
        document.body && document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); // afterwards we remove the element again
      } catch (exc) {
        console.log(exc);
      }
    })
    .catch((r) => console.log(r));

/**
 {
  "uri": "http://tuic-api-url/currenturl/fetched",
  "method": "HTTP_VERB",
  "pin_check_error_message": "No pin provided",
  "pin_check_error_code": 2,
  "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
  "title": "Forbidden",
  "status": 403,
  "detail": "0x0003"
}

 {
  "uri": "http://tuic-api-url/currenturl/fetched",
  "method": "HTTP_VERB",
  "pin_check_error_message": "Provided pin does not match",
  "pin_check_error_code": 3,
  "type": "http://www.w3.org/Protocols/rfc2616/rfc2616-sec10.html",
  "title": "Forbidden",
  "status": 403,
  "detail": "0x0003"
}

 */
