// @flow

import React, { Component } from 'react';
import classNames from 'classnames';

type Props = {
  item: Object,
};

export default class Description extends Component<Props> {
  render() {
    const { item } = this.props;

    return (
      <div
        className={classNames({
          'has-info':
            !!item.tourCode || !!item.operatorLine1 || !!item.operatorLine2,
        })}
      >
        {item.name && (
          <div
            className="name"
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        )}
        {item.description}
        <div className="additional-info">
          {item.tourCode && <div>{item.tourCode}</div>}
          {item.operatorLine1 && (
            <div
              className="operator-line-1"
              dangerouslySetInnerHTML={{
                __html: item.operatorLine1,
              }}
            />
          )}
          {item.operatorLine1 && (
            <div
              className="operator-line-2"
              dangerouslySetInnerHTML={{
                __html: item.operatorLine2,
              }}
            />
          )}
        </div>
      </div>
    );
  }
}
