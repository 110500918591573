// @flow
import React from 'react';
import MailIcon from '../../../assets/svg/inline/mail.inline.svg';

const MailBlock = () => (
  <div className={`phone-mail-block`}>
    <a className={`text`} href="mailto:info@tuicruises.com">
      <MailIcon className="mail" />
      info@tuicruises.com
    </a>
  </div>
);

export default MailBlock;
