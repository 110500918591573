// @flow
import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import { adjustedDate } from '../utils/date';
import { isLocal, mockAll } from './utils';
import type User from '../models/User';
import type MasterData from '../models/MasterData';

const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/event/list.json`
    : `/api/land/events/categoryevents`;
const getDetailUrl = (
  masterData: MasterData,
  eventId: number | string
): string =>
  (mock || mockAll) && isLocal
    ? `/mock/event/details.json`
    : `/api/land/events/${eventId}/${masterData.ed}/${masterData.dd}/${
        masterData.shipId
      }`;

const DAY_SUB_CATEGORIES = ['morning', 'noon', 'afternoon', 'evening'];

const restructureCategoryEventsData = data => ({
  filter: {
    categories: data.categories,
    days: Object.keys(data.events),
  },
  days: Object.keys(data.events).map(date => {
    const dateData = data.events[date];
    const events = DAY_SUB_CATEGORIES.reduce(
      (memo, cat) => [
        ...memo,
        ...(dateData[cat] ? camelCaseKeys(dateData[cat].events) : []),
      ],
      []
    );
    const [day, month, year] = date.split('.');
    return {
      date: adjustedDate(new Date(year, month - 1, day)),
      displayDate: dateData.display,
      events,
    };
  }),
});

export const sanitizers = {
  get: data => (data.ship ? data : restructureCategoryEventsData(data)),
  getDetails: response => {
    const data = camelCaseKeys(response, true);
    // Fix wrong formatted status #TUICMRL-574
    if (data.statusCode === undefined) {
      data.statusCode = data.status;
      data.status = data.statusMessage;
    }
    return data;
  },
};

export default sanitized(
  {
    get: (user: User) => fetchForUser(user, getUrl()),
    getDetails: (
      user: User,
      masterData: MasterData,
      eventId: number | string
    ) => fetchForUser(user, getDetailUrl(masterData, eventId)),
  },
  sanitizers
);
