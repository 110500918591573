// @flow
import { computed } from 'mobx';

import { formatPrice } from '../utils/price';
import { INCLUSIVE_INFO } from '../config/constants';

import Bookable from './Bookable';

import type MasterStore from '../stores/MasterStore';
import type BookedPackage from './BookedPackage';

export default class Package extends Bookable {
  image: string;
  title: string;
  text: string;
  priceText: string;
  code: ?string;

  constructor(data: { priceText?: string, price?: number }, masterStore: MasterStore) {
    super(data, masterStore);
    this.name = this.name || this.headline;
    this.priceText = data.priceText || formatPrice(data.price);
  }

  // Return the masterData Package
  @computed
  get fromData(): BookedPackage {
    return this.masterStore.packages.find((pkg) => pkg.typeId === this.typeId);
  }

  get isBooked(): boolean {
    return !!this.fromData;
  }

  get prelimenary(): boolean {
    return this.fromData && this.fromData.prelimenary;
  }

  get analyticsCategory(): string {
    return 'Pakete';
  }

  get description(): string {
    return this.text;
  }

  get imageSrc(): string {
    return this.image;
  }

  get bookingName(): string {
    return this.title;
  }

  get inclusiveInfo(): Object | null {
    const inclusivityReasons = INCLUSIVE_INFO[this.category];
    if (inclusivityReasons) {
      const { requestorHasVipOption, isSuite } = this.masterStore.masterData;
      const { freeForSuites, freeForVips } = inclusivityReasons;
      if (isSuite && freeForSuites) return freeForSuites;
      if (requestorHasVipOption && freeForVips) return freeForVips;
    }
  }
}
