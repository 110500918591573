// @flow
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import fetchForUser from '../utils/fetchForUser';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (masterData: MasterData): string =>
  (mock || mockAll) && isLocal
    ? `/mock/includedShoreEx/obsolete/itinerary_TUICUNIT-1227.json`
    : `/api/land/itinerary/${masterData.ed}/${masterData.dd}/${
        masterData.shipId
      }`;

export default {
  get: (user: User, masterData: MasterData) =>
    fetchForUser(user, getUrl(masterData)),
};
