// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Picture from './Picture';
import AppStoreTile from './tiles/AppStoreTile';

import { italicShip } from '../utils/string';

import type AppTilesStore from '../stores/AppTilesStore';

type Props = {
  appStoreTilesStore: AppTilesStore | any,
};

@inject('appStoreTilesStore')
@observer
export default class AppStoreTeaser extends Component<Props> {
  static defaultProps = {
    appStoreTilesStore: null,
  };

  componentDidMount() {
    this.props.appStoreTilesStore.fetch();
  }

  render() {
    const { teaser, buttons, isLoading } = this.props.appStoreTilesStore;

    return !isLoading ? (
      <div className="l-mod-sub">
        <h2
          dangerouslySetInnerHTML={{
            __html: italicShip(teaser.headline),
          }}
        />
        <Picture src={teaser.imageSrc} />
        <p
          dangerouslySetInnerHTML={{
            __html: italicShip(teaser.text),
          }}
        />
        {buttons
          ? buttons.map(button => (
              <AppStoreTile
                key={button.url}
                src={button.imageSrc}
                link={button.url}
              />
            ))
          : null}
      </div>
    ) : null;
  }
}
