// @flow

import React, { Component } from 'react';

import type ArrivalDepartureItem from '../../../../models/ArrivalDeparture/ArrivalDepartureItem';
import classNames from 'classnames';

type Props = {
  item: ArrivalDepartureItem,
};

export default class Bus extends Component<Props> {
  render() {
    const { item } = this.props;

    return (
      <div className="arrival-departure-item bus">
        <div className="bus-header">
          <div
            className={classNames({
              'arrival-departure-item__date': true,
              empty: !item.date && !item.departureDate,
            })}
          >
            {item.date || item.departureDate || ' - '}
          </div>
          <div className="arrival-departure-item__title">
            <span dangerouslySetInnerHTML={{ __html: item.busStopName }} />
          </div>
          {item.to && (
            <div className="arrival-departure-service__time time">
              {item.departureTime && (
                <span className="bold">{item.departureTime} </span>
              )}
              Abfahrt nach{' '}
              <span dangerouslySetInnerHTML={{ __html: item.to }} />
            </div>
          )}
        </div>
        <div
          className="common-hint"
          dangerouslySetInnerHTML={{ __html: item.hint }}
        />
      </div>
    );
  }
}
