// @flow

type Period = {
  start: ?string,
  end: ?string,
};

type Cruise = {
  ship: ?string,
  period: ?Period,
};

export default class Route {
  cruise: ?Cruise;
  type: ?string;
  id: ?number;
  categories: ?Array<string>;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
