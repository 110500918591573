// @flow
import * as React from 'react';

type Props = {
  title?: string,
  bullets: string[],
};

const BulletList = ({ title, bullets }: Props) => (
  <div>
    {title && <strong>{title}</strong>}
    <ul className="bullet-list">
      {bullets.map((bullet, index) => (
        <li
          key={index}
          className="bullet-list__item"
          dangerouslySetInnerHTML={{ __html: bullet }}
        />
      ))}
    </ul>
  </div>
);

export default BulletList;
