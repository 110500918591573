// @flow

import React from 'react';

type Props = {
  className?: ?string,
  model: {
    headline: string,
    text: string,
  },
};

const InlineInfoBox = ({ model, className }: Props) => (
  <div className={`infobox ${className || ''}`}>
    <div className="infobox__iconholder inline">
      <i className="icon info" />
    </div>
    <h2 className="infobox__headline">{model.headline}</h2>
    <p className="infobox__text">{model.text}</p>
  </div>
);

export default InlineInfoBox;
