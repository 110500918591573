// @flow

import React, { Component, type Node } from 'react';
import { inject, observer } from 'mobx-react';

import Header from './components/Header';
import AccountHeader from './components/AccountHeader';
import Footer from './components/Footer';
import LoadingIndicator from './components/LoadingIndicator';
import isInAppView from './utils/isInAppView';
import type AuthStore from './stores/AuthenticationStore';

type Props = {
  children: Node,
  params: {},
  authStore: AuthStore,
};

@inject('authStore')
@observer
class DefaultLayout extends Component<Props> {
  componentDidMount() {
    // const { authStore } = this.props;
    // if (authStore.isAuthenticated) {
    //   // TUICROLLOUTS-627 dismantling for 1.10.0.
    //   //    Restore for 1.11.0 if 1.10.0 was released
    //   authStore.getIdentity().catch(error => {
    //     console.log(error);
    //   });
    // }
  }

  render() {
    const { identity, loading } = this.props.authStore;
    if (loading) return <LoadingIndicator />;
    const hasIdentity = !!identity;

    return (
      <div className="main-container">
        {hasIdentity ? (
          <AccountHeader identity={identity} />
        ) : (
          <Header params={this.props.params} isInAppView={isInAppView} />
        )}
        <main className={`main-app${isInAppView ? ' without-header' : ''}${hasIdentity ? ' has-account-header' : ''}`}>
          {this.props.children}
        </main>
        <Footer isInAppView={isInAppView} />
      </div>
    );
  }
}

export default DefaultLayout;
