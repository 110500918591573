// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import BookedPackage from '../../models/BookedPackage';
import Booking from '../../models/Booking';
import { italicShipComponent } from '../../utils/string';

import Button from '../Button';

const BookingList = ({
  header,
  copy,
  bookings,
  title,
  info,
  onCancelBooking,
  disabled,
}) =>
  bookings.length ? (
    <div
      className={classNames({
        'bookings-list': true,
      })}
    >
      {header ? <h2 className="bookings-list__header">{header}</h2> : null}
      {copy ? <p className="bookings-list__copy">{copy}</p> : null}
      {bookings.map(booking => (
        <div
          key={`${booking.typeId}_${Object.values(booking.bookingId).join(
            '-'
          )}`}
          className="bookings-list__item"
        >
          <div className="bookings-list__item__content">
            <div className="bookings-list__item__title">
              {italicShipComponent(title(booking))}
            </div>
            {info(booking) ? (
              <p className="bookings-list__item__info">{info(booking)}</p>
            ) : null}
          </div>
          <div className="bookings-list__item__button">
            <Button
              dark
              onClick={() => onCancelBooking(booking)}
              disabled={disabled || !booking.cancelable || booking.prelimenary}
            >
              Reservierung stornieren
            </Button>
          </div>
        </div>
      ))}
    </div>
  ) : null;

BookingList.propTypes = {
  header: PropTypes.string,
  copy: PropTypes.string,
  bookings: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.instanceOf(BookedPackage),
      PropTypes.instanceOf(Booking),
    ])
  ).isRequired,
  onCancelBooking: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  info: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default BookingList;
