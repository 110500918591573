// TODO: add flow types

export default function sanitized(api, sanitizers) {
  return Object.keys(api).reduce(
    (memo, key) => ({
      ...memo,
      [key]: (...args) =>
        api[key].apply(this, args).then(sanitizers[key] || (x => x)),
    }),
    {}
  );
}
