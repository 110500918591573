// @flow
// TUICUNIT-2856: All this :(
import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, ApiResponse, TileType } from '../api/tiles';

export type TilesStoreType = {
  einreisebestimmungen: TileType,
  onlineCheckIn: TileType,
  schiffsManifest: TileType,
  zahlungsmittel: TileType,
  bookingDetails: TileType,
  checkIn: TileType,
  landausfluege: TileType,
  includedShoreEx: TileType,
  manifest: TileType,
  spaBalconyCabin: TileType,

  anUndAbreise: TileType,
  anUndAbreiseinformationen: TileType,
  anwendungenFuerKidsUndTeens: TileType,
  entspannungslogen: TileType,
  erinnerungsstuecke: TileType,
  faq: TileType,
  friseur: TileType,
  gaestelisteAufFacebook: TileType,
  gesundheitschecks: TileType,
  informationenZuIhremSchiff: TileType,
  kosmetik: TileType,
  koerperanwendungen: TileType,
  massagen: TileType,
  maenner: TileType,
  newsletter: TileType,
  paketeUndZeitungen: TileType,
  pakete: TileType,
  permanentMakeUpAndMore: TileType,
  personalTraining: TileType,
  reisepreis: TileType,
  reiseunterlagen: TileType,
  sauna: TileType,
  spaUndMeer: TileType,
  thalasso: TileType,
  verantwortungsvollesReisen: TileType,
  zeitungen: TileType,
  zuZweitEntspannen: TileType,
  zusatzleistungen: TileType,
  fitness: TileType,
  weitereInformationen: TileType,
  schoenheitGesundheit: TileType,
  highlight: TileType,
  internet: TileType,
  parking: TileType,
  program: TileType,
  reiseversicherung: TileType,
  tischreservierung: TileType,
  reiseplan: TileType,
};

const defaultTilesSet = {
  einreisebestimmungen: { visible: true, enabled: true },
  checkIn: { visible: true, enabled: true },
  schiffsManifest: { visible: true, enabled: true, notification: '' },
  zahlungsmittel: { visible: true, enabled: true, notification: '' },
  bookingDetails: {
    visible: true,
    enabled: true,
    title: 'Buchung & Reiseunterlagen',
  },
  onlineCheckIn: {
    visible: true,
    enabled: true,
    title: 'Online <span>Check-in</span>',
  },
  landausfluege: { visible: true, enabled: true, title: 'Landausflüge' },
  includedShoreEx: {
    visible: false,
    enabled: false,
    title: 'Inklusiv-Landausflüge',
  },
  manifest: { visible: true, enabled: true, title: 'Schiffsmanifest' },
  spaBalconyCabin: {
    visible: false,
    enabled: false,
    title: 'SPA Balkonkabine',
  },

  anUndAbreise: { visible: true, enabled: true },
  anUndAbreiseinformationen: { visible: true, enabled: true },
  anwendungenFuerKidsUndTeens: { visible: true, enabled: true },
  entspannungslogen: { visible: true, enabled: true },
  faq: { visible: true, enabled: true },
  friseur: { visible: true, enabled: true },
  gaestelisteAufFacebook: { visible: true, enabled: true },
  gesundheitschecks: { visible: true, enabled: true },
  informationenZuIhremSchiff: { visible: true, enabled: true },
  kosmetik: { visible: true, enabled: true },
  koerperanwendungen: { visible: true, enabled: true },
  massagen: { visible: true, enabled: true },
  maenner: { visible: true, enabled: true },
  newsletter: { visible: true, enabled: true },
  pakete: { visible: true, enabled: true },
  permanentMakeUpAndMore: { visible: true, enabled: true },
  personalTraining: { visible: true, enabled: true },
  reisepreis: { visible: true, enabled: true },
  reiseunterlagen: { visible: true, enabled: true },
  sauna: { visible: true, enabled: true },
  spaUndMeer: { visible: true, enabled: true },
  thalasso: { visible: true, enabled: true },
  verantwortungsvollesReisen: { visible: true, enabled: true },
  zeitungen: { visible: true, enabled: true },
  zuZweitEntspannen: { visible: true, enabled: true },
  zusatzleistungen: { visible: true, enabled: true },
  fitness: { visible: true, enabled: true, title: 'Fitness' },
  weitereInformationen: {
    visible: true,
    enabled: true,
    title: 'Weitere Informationen',
  },
  schoenheitGesundheit: {
    visible: true,
    enabled: true,
    title: 'Schönheit & Gesundheit',
  },
  highlight: { visible: true, enabled: true, title: 'Highlights' },
  internet: { visible: true, enabled: true, title: 'Internet an Bord' },
  paketeUndZeitungen: {
    visible: true,
    enabled: true,
    title: 'Pakete & Zeitungen',
  },
  parking: { visible: true, enabled: true, title: 'Parkservice' },
  program: { visible: true, enabled: true, title: 'Programm' },
  reiseversicherung: { visible: true, enabled: true },
  tischreservierung: {
    visible: true,
    enabled: true,
    title: 'Tisch&shy;reservierung',
  },
  erinnerungsstuecke: {
    visible: true,
    enabled: true,
    title: 'Erinnerungs&shy;stücke',
  },
  reiseplan: { visible: true, enabled: true, title: 'Reiseplan' },
};

export default class TilesStore {
  @observable tiles: ?TilesStoreType = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.tiles === null;
  }

  // TUICUNIT-2844: this is the new way
  fetchAll() {
    const { user, masterData } = this.masterStore;
    return this.api.getGeneral(masterData).then((tilesGeneral: ApiResponse) => {
      this.api.getUser(user).then((tilesUser: ApiResponse) => {
        if (tilesGeneral.highlight.enabled) {
          this.fetchRequest = this.api
            .getHighlights(this.masterStore.user, this.masterStore.masterData)
            .then((data) => {
              if (Array.isArray(data)) {
                data = data.slice(0, 5);
                data.forEach((item, i) => {
                  const cut = 80;
                  let headline = this.cleanText(item.headline);
                  if (headline.length > cut) {
                    headline = headline.substring(0, cut) + '...';
                  }
                  data[i].headline = this.setMSitalic(headline);
                });
                tilesGeneral.highlight.data = data || [];
              } else {
                tilesGeneral.highlight.visible = false;
                tilesGeneral.highlight.enabled = false;
              }

              this.receiveTilesAndMerge(tilesGeneral, tilesUser || {});
            });
        } else {
          this.receiveTilesAndMerge(tilesGeneral, tilesUser || {});
        }
      });
    });
  }

  /**
   * remove all html tags
   *
   * @param {string} text
   * @returns clean String
   */
  cleanText(text: ?string) {
    let cText = text || '';
    cText = cText.replace(/(<([^>]+)>)/gi, '');
    cText = cText.replace(/([\n\t])/gi, ' ');
    cText = cText.split('  ').join(' ');
    return cText;
  }

  /**
   * set Mein Schiff into <i>
   *
   * @param {string} text
   * @returns <i>Mein Schiff</i>
   */
  setMSitalic(text: ?string) {
    let cText = text || '';
    cText = cText.replace(/(Mein Schiff)/gi, '<i>Mein Schiff</i>');

    return cText;
  }

  // User TilesData overrides General TilesData
  @action
  receiveTilesAndMerge(tilesGeneral: ApiResponse | {}, tilesUser: ApiResponse | {}) {
    const tilesGeneralMap = this.mapAPIDefaultSet(tilesGeneral);
    const tilesUserlMap = this.mapAPIDefaultSet(tilesUser);
    this.tiles = Object.assign(defaultTilesSet, tilesGeneralMap, tilesUserlMap);
  }

  mapAPIDefaultSet(rowTiles: ApiResponse) {
    const map = {};

    Object.keys(rowTiles).forEach((k: string) => {
      switch (k) {
        // tilesconfig
        case 'Einreisebestimmungen':
          map.einreisebestimmungen = rowTiles.Einreisebestimmungen;
          break;
        case 'OnlineCheckIn':
          map.onlineCheckIn = rowTiles.OnlineCheckIn;
          map.onlineCheckIn.title = defaultTilesSet.onlineCheckIn.title;
          break;
        case 'SchiffsManifest':
          map.schiffsManifest = rowTiles.SchiffsManifest;
          break;
        case 'Zahlungsmittel':
          map.zahlungsmittel = rowTiles.Zahlungsmittel;
          break;
        case 'bookingDetails':
          map.bookingDetails = rowTiles.bookingDetails;
          break;
        case 'checkin':
          map.checkIn = rowTiles.checkin;
          break;
        case 'excursions':
          map.landausfluege = rowTiles.excursions;
          break;
        case 'includedShoreEx':
          map.includedShoreEx = rowTiles.includedShoreEx;
          break;
        case 'manifest':
          map.manifest = rowTiles.manifest;
          break;
        case 'spaBalconyCabin':
          map.spaBalconyCabin = rowTiles.spaBalconyCabin;
          break;
        //cruisetiles
        case 'AnUndAbreise':
          map.anUndAbreise = rowTiles.AnUndAbreise;
          break;
        case 'AnUndAbreiseinformationen':
          map.anUndAbreiseinformationen = rowTiles.AnUndAbreiseinformationen;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'AnwendungenFuerKidsUndTeens':
          map.anwendungenFuerKidsUndTeens = {
            ...rowTiles.AnwendungenFuerKidsUndTeens,
            title: 'Anwendungen für Kids & Teens',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Entspannungslogen':
          map.entspannungslogen = {
            ...rowTiles.Entspannungslogen,
            title: 'Entspannungslogen',
          };
          break;
        case 'FAQ':
          map.faq = rowTiles.FAQ;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Friseur':
          map.friseur = {
            ...rowTiles.Friseur,
            title: 'Friseur',
          };
          break;
        case 'GaestelisteAufFacebook':
          map.gaestelisteAufFacebook = rowTiles.GaestelisteAufFacebook;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Gesundheitschecks':
          map.gesundheitschecks = {
            ...rowTiles.Gesundheitschecks,
            title: 'Gesundheitschecks',
          };
          break;
        case 'InformationenZuIhremSchiff':
          map.informationenZuIhremSchiff = rowTiles.InformationenZuIhremSchiff;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Koerperanwendungen':
          map.koerperanwendungen = {
            ...rowTiles.Koerperanwendungen,
            title: 'Körperanwendungen',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Kosmetik':
          map.kosmetik = {
            ...rowTiles.Kosmetik,
            title: 'Kosmetik',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Maenner':
          map.maenner = {
            ...rowTiles.Maenner,
            title: 'Männer',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Massagen':
          map.massagen = {
            ...rowTiles.Massagen,
            title: 'Massagen',
          };
          break;
        case 'Newsletter':
          map.newsletter = rowTiles.Newsletter;
          break;
        case 'Pakete':
          map.pakete = rowTiles.Pakete;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'PermanentMakeUpAndMore':
          map.permanentMakeUpAndMore = {
            ...rowTiles.PermanentMakeUpAndMore,
            title: 'Permanent Make-up & More',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'PersonalTraining':
          map.personalTraining = {
            ...rowTiles.PersonalTraining,
            title: 'Personal Training',
          };
          break;
        case 'Reisepreis':
          map.reisepreis = rowTiles.Reisepreis;
          break;
        case 'Reiseunterlagen':
          map.reiseunterlagen = rowTiles.Reiseunterlagen;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'Sauna':
          map.sauna = {
            ...rowTiles.Sauna,
            title: 'Sauna',
          };
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'SpaUndMeer':
          map.spaUndMeer = {
            ...rowTiles.SpaUndMeer,
            title: 'SPA & Meer',
          };
          break;
        case 'Thalasso':
          map.thalasso = rowTiles.Thalasso;
          break;
        case 'VerantwortungsvollesReisen':
          map.verantwortungsvollesReisen = rowTiles.VerantwortungsvollesReisen;
          break;
        case 'Zeitungen':
          map.zeitungen = rowTiles.Zeitungen;
          break;
        // we need the exact MXP name to find the RIOT definition
        case 'ZuZweitEntspannen':
          map.zuZweitEntspannen = {
            ...rowTiles.ZuZweitEntspannen,
            title: 'Zu zweit entspannen',
          };
          break;
        case 'Zusatzleistungen':
          map.zusatzleistungen = rowTiles.Zusatzleistungen;
          break;
        case 'fitness':
          map.fitness = rowTiles.fitness;
          break;
        case 'furtherInformation':
          map.weitereInformationen = rowTiles.furtherInformation;
          break;
        case 'healthAndBeauty':
          map.schoenheitGesundheit = rowTiles.healthAndBeauty;
          break;
        case 'highlight':
          map.highlight = rowTiles.highlight;
          break;
        case 'internet':
          map.internet = rowTiles.internet;
          break;
        case 'PaketeUndZeitungen':
          map.paketeUndZeitungen = {
            ...rowTiles.PaketeUndZeitungen,
            title: 'Pakete & Zeitungen',
          };
          break;
        case 'parking':
          map.parking = rowTiles.parking;
          break;
        case 'program':
          map.program = rowTiles.program;
          break;
        case 'reiseversicherung':
          map.reiseversicherung = rowTiles.reiseversicherung;
          break;
        case 'tableReservation':
          map.tischreservierung = rowTiles.tableReservation;
          break;
        case 'Erinnerungsstuecke':
          map.erinnerungsstuecke = {
            ...rowTiles.Erinnerungsstuecke,
            title: 'Erinnerungs&shy;stücke',
          };
          break;
        case 'travelPlan':
          map.reiseplan = rowTiles.travelPlan;
          break;
      }
    });
    return map;
  }

  /**
   * we need to filter by name because the CMS (MXP) system is not able to manage contents
   * @param {*} name
   * @returns RIOT definition on this content
   */
  getParamsByName(name: string) {
    const tiles = this.tiles;
    const findKey = Object.keys(tiles).filter((t) => tiles[t].title === name);
    if (tiles[findKey]) {
      return tiles[findKey];
    }
    return { visible: true, enabled: true };
  }
}
