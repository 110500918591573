// @flow
let account = 'https://tuic-kundenkonto.dev.cellular.de';
if (window.location.href.indexOf('http://local.dev.cellular.de') >= 0) {
  account = 'http://tuic-mr-local.dev.cellular.de:9001';
}

export default {
  trackingCode: 'qa', //'UA-6438006-17',
  cookieDomain: '.cellular.de',
  logoutURL: 'https://tuic-mrl.dev.cellular.de/login',
  account,
  accountLogin: `${account}/signin`,
  accountRegister: `${account}/signin/registration`,
  excursionMultiSelectCacheKey: 'travelPartyState',
  publicHealthQuestionnaire:
    'https://meinereisedev.cellular.de/config/fragebogen/current.pdf',
  iadvizeId: '6761',
};
