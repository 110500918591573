// @flow
import { observable, computed, action } from 'mobx';
import parse from 'date-fns/parse';

import logger from '../../utils/logger';
import { formatDate } from '../../utils/date';

import ItineraryDay from '../../models/ItineraryDay';
import PartyMember from '../../models/PartyMember';
import RestaurantOffer from '../../models/RestaurantOffer';
import Booking from '../../models/Booking';
import VacancyTimeSlots from '../../models/VacancyTimeSlots';

import BookingRequestBase from './BookingRequestBase';

import type {
  BookingType,
  IGeneralItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
  IAnalyticsDetails,
  ISeatBookingData,
} from '../../types/booking';

export default class RestaurantBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      success: {
        ...BookingRequestBase.TEXTS.create.success,
        text:
          'Ihre Reservierungsanfrage ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text: bookingRequest =>
          `Wollen Sie im Restaurant „${
            bookingRequest.bookable.name
          }“ am ${formatDate(bookingRequest.selectedDay.date)} um ${
            bookingRequest.selectedTime
          } Uhr einen Tisch für ${
            bookingRequest.selectedCount
          } Personen reservieren?`,
      },
    },
    cancellation: {
      confirmation: {
        ...BookingRequestBase.TEXTS.cancellation.confirmation,
        text: bookingRequest =>
          `Möchten Sie die Tischreservierung im Restaurant „${
            bookingRequest.bookable.name
          }“ um ${bookingRequest.bookingToCancel.startTime} Uhr für ${
            bookingRequest.bookingToCancel.seatCount
          } Personen wirklich stornieren?`,
      },
      success: {
        ...BookingRequestBase.TEXTS.cancellation.success,
        text:
          'Ihre Stornierung ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
    },
  };

  @observable selectedDay: ?ItineraryDay = null;
  @observable selectedCount = 0;
  @observable selectedTime: ?string = null;
  @observable comment = '';
  @observable vacancies = null;
  @observable errors = null;
  @observable isRequesting = false;
  @observable requestSuccessful: boolean;
  @observable bookingToCancel: ?Booking;

  constructor(
    travelParty: PartyMember[],
    bookable: RestaurantOffer,
    vacancies: ?VacancyTimeSlots
  ) {
    super(travelParty, bookable);
    this.vacancies = vacancies;
  }

  @action
  resetCancellation() {
    this.bookingToCancel = null;
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @action
  chooseDay(day: ItineraryDay) {
    this.selectedDay = day;
  }

  @action
  chooseParticipantCount(count: number) {
    this.selectedCount = count;
  }

  @action
  chooseTime(time: string) {
    this.selectedTime = time;
  }

  @action
  removeDay() {
    this.selectedDay = null;
  }

  @action
  setComment(comment: string) {
    this.comment = comment;
  }

  @action
  setVacancies(vacancies: VacancyTimeSlots) {
    this.vacancies = vacancies;
  }

  @computed
  get isCancellation(): boolean {
    return !!this.bookingToCancel;
  }

  @computed
  get isChangeRequest(): boolean {
    return false;
  }

  get showBookingTable(): boolean {
    return false;
  }

  @computed
  get timeIsAvailable(): boolean {
    // We can only calculate it if we know enough. Else we assume it is available
    if (!this.selectedTime || !this.vacancies || !this.selectedCount) {
      return true;
    }
    return this.vacancies.isTimeSlotAvailableFor(
      this.selectedTime,
      this.selectedCount
    );
  }

  createItemToBook(mpi: number, type: BookingType): IGeneralItemToBook {
    const member = this.travelParty.find(p => p.requestor);

    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAge : '',
      price: this.bookable.price || 0,
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
    };
  }

  @computed
  get total(): number {
    return this.bookable.price;
  }

  @computed
  get date(): string {
    return this.selectedDay ? this.selectedDay.date : '';
  }

  get startDateTime(): ?Date {
    if (!this.date || !this.selectedTime) return null;
    const foundVacancy = this.vacancies
      ? this.vacancies.vacancyFor(this.selectedCount, this.selectedTime)
      : null;
    if (!foundVacancy) return null;
    return parse(`${this.date}T${this.selectedTime}:00`);
  }

  get endDateTime(): ?Date {
    if (!this.startDateTime || !this.selectedTime) {
      return null;
    }
    const foundVacancy = this.vacancies
      ? this.vacancies.vacancyFor(this.selectedCount, this.selectedTime)
      : null;
    if (!foundVacancy) return null;
    return parse(`${this.date}T${foundVacancy.endTime}:00`);
  }

  @computed
  get isReady(): boolean {
    return !!(this.selectedCount && this.selectedDay && this.selectedTime);
  }

  @computed
  get bookingData(): ISeatBookingData[] {
    if (
      !this.isReady ||
      this.isCancellation ||
      !this.vacancies ||
      !this.selectedTime
    ) {
      return [];
    }
    const foundVacancy = this.vacancies.vacancyFor(
      this.selectedCount,
      this.selectedTime
    );
    if (!foundVacancy) {
      logger.error(
        'No vacancy found, but it should be',
        this.vacancies,
        this.selectedCount,
        this.selectedTime
      );
      return [];
    }

    return [
      {
        type: 'venue',
        vacancyId: foundVacancy.id,
        seatCount: this.selectedCount,
        comment: this.comment,
      },
    ];
  }

  @computed
  get vacancyId(): ?string {
    if (!this.vacancies || !this.selectedTime) return null;
    const vacancy = this.vacancies.vacancyFor(
      this.selectedCount,
      this.selectedTime
    );
    return vacancy ? vacancy.id : null;
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.isCancellation || !this.bookingToCancel) return [];
    const mpisToCancel = this.bookingToCancel.mpis;
    const bookedIds = this.bookingToCancel.bookingId;

    return mpisToCancel.map(mpi => ({
      type: 'venue',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  get analyticsDetails(): IAnalyticsDetails {
    return {
      id: `${this.bookable.id}`,
      name: this.bookable.name,
      category: this.bookable.analyticsCategory,
      price: 0,
    };
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const { name, bookingId, analyticsCategory } = this.bookable;
    return {
      products: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}`,
              name,
              category: analyticsCategory,
              quantity: this.selectedCount,
              price: 0,
            },
          ],
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}-${this.selectedCount}`,
              revenue: 0,
            },
          ],
      refunds:
        this.isCancellation &&
        this.bookingToCancel &&
        this.bookingToCancel.seatCount
          ? [
              {
                id: `${this.bookingToCancel.typeId}-${
                  this.bookingToCancel.seatCount
                }`,
              },
            ]
          : [],
    };
  }
}
