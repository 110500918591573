//@flow
import React from 'react';
import Picture from './Picture';
import LinkAsButton from './LinkAsButton';
import { loadImg } from '../utils/image';

const CabinPresentsTeaser = () => (
  <div>
    <h2>Kabinenpräsente</h2>
    <Picture src={loadImg('login/kabinenpraesente-272x115.jpg')} />
    <div className="teaser-block-top">
      <p>
        Sie möchten Ihren Verwandten, Freunden oder Bekannten an Bord eine Freude
        machen?
      </p>
      <div className="l-right" style={{ marginBottom: '12px' }}>
        <LinkAsButton
          newPage
          link="https://www.meinschiff.com/besondere-arrangements/festtage"
        >
          Jetzt bestellen
        </LinkAsButton>
      </div>
    </div>
    <p>
      Sie möchten als Reisebüropartner für einen Kunden ein Kabinenpräsent
      bestellen?
    </p>
    <div className="l-right">
      <LinkAsButton
        newPage
        link="http://meinschiff.com/infonet/kabinenpraesente/"
      >
        Jetzt bestellen
      </LinkAsButton>
    </div>
  </div>
);

export default CabinPresentsTeaser;
