// @flow
const account = 'https://meinereisen.test.meinschiff.com';
export default {
  trackingCode: 'qa', //'UA-6438006-17',
  cookieDomain: '.cellular.de',
  logoutURL: 'https://tuic-mrl.prelive.cellular.de/login',
  account,
  accountLogin: `${account}/signin`,
  accountRegister: `${account}/signin/registration`,
  excursionMultiSelectCacheKey: 'travelPartyState',
  publicHealthQuestionnaire:
    'https://meinereisee2e.cellular.de/config/fragebogen/current.pdf',
  iadvizeId: '6761',
};
