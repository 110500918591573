// @flow

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mock = false;
const getListUrl = (masterData: MasterData, type: string): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/TBD.json`
    : `/api/land/spa/${type}/${ed}/${dd}/${shipId}`;
};

export default {
  getList: (user: User, masterData: MasterData, type: string) =>
    fetchForUser(user, getListUrl(masterData, type)).then(sanitizers.getList),
};

export const sanitizers = {
  getList: data => camelCaseKeys(data, true),
};
