// @flow

import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/appStoreTiles.json`
    : `/api/land/teaser/index`;

//TODO JOK: when the fetch fails user is left without any information, logged out, unable to login
export default {
  get: (): Promise<ApiResponse> =>
    fetch(getUrl()).then(response => response.json()),
};

export type ApiResponse = {
  headline: string,
  text: string,
  documentType: 'teaser',
  imageUri: string,
  buttons: {
    type: 'navigation_button',
    imageUri: string,
    label: string,
    icon: string,
    url: string,
  }[],
};

export type ApiType = {
  get: () => Promise<ApiResponse>,
};
