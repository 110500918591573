// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import browserHistory from 'react-router/lib/browserHistory';
import { routerShape } from 'react-router/lib/PropTypes';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import Button from './Button';
import LinkAsButton from './LinkAsButton';
import tracking from '../tracking';

@inject('masterStore', 'authStore')
@observer
export default class Header extends Component {
  static propTypes = {
    masterStore: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
    isInAppView: PropTypes.bool,
  };

  static contextTypes = {
    router: routerShape,
  };

  componentDidMount() {
    const { masterStore, isInAppView } = this.props;
    masterStore.isInAppView = isInAppView;
  }

  @autobind onBackClick(event) {
    event?.preventDefault();

    browserHistory.goBack();
  }

  @autobind onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  get isLoggedIn() {
    const { user } = this.props.masterStore;
    // a logged-in user must exist and have an API token
    return !!user || (user && !!user.apiToken);
  }

  render() {
    if (this.props.isInAppView) return <span />;

    const toLink = window.location.pathname.indexOf('maintenance') >= 0 ? '/maintenance' : '/';
    const hasBacklink = !!this.props.authStore.user && !!this.props.authStore.user.backLink;

    return (
      <header className="header">
        <div className="header__wrap">
          <div className="header-mobile">
            {this.isLoggedIn ? (
              <Link
                to={toLink}
                className="header__button icon"
                onClick={() => {
                  tracking.click('navi.icon.back');
                  this.onBackClick();
                }}
              >
                {' '}
                <i className={`icon back`} />
              </Link>
            ) : null}

            {/* TUICUNIT-3782 */}
            {hasBacklink ? (
              <Link
                to={this.props.authStore.user.backLink}
                className="header__link"
                onClick={() => {
                  tracking.click('navi.icon.logout');
                }}
              >
                <div className="header__logo" />
              </Link>
            ) : (
              <Link to={toLink} className="header__link" onClick={() => tracking.click('navi.icon.home')}>
                <div className="header__logo" />
              </Link>
            )}
            {this.isLoggedIn && !hasBacklink ? (
              <Link
                to={toLink}
                className="header__button icon"
                onClick={() => {
                  tracking.click('navi.link.logout');

                  this.onLogoutClick();
                }}
              >
                {' '}
                <i className={`icon logout`} />
              </Link>
            ) : this.isLoggedIn ? (
              <div
                className="header__button icon"
                style={{
                  boxShadow: 'none',
                  borderLeft: '0',
                }}
              />
            ) : null}
          </div>
          <div className="header-desktop">
            <div className="l-spread middle">
              <div className="l-spread_left">
                {/* TUICUNIT-3782 */}
                {this.props.authStore.user && this.props.authStore.user.backLink ? (
                  <Link
                    to={toLink}
                    className="header__link"
                    onClick={() => {
                      tracking.click('header.logo');
                      this.onLogoutClick();
                    }}
                  >
                    <div className="header__logo" />
                  </Link>
                ) : (
                  <Link to={toLink} className="header__link" onClick={() => tracking.click('header.logo')}>
                    <div className="header__logo" />
                  </Link>
                )}
              </div>
              <div className="l-spread_right">
                {this.isLoggedIn ? (
                  <Button
                    dark
                    onClick={() => {
                      tracking.click(
                        'header.link.' + this.props.authStore.user.backLink ? 'Zurück zur Reiseübersicht' : 'Abmelden'
                      );
                      this.onLogoutClick();
                    }}
                  >
                    <span>{this.props.authStore.user.backLink ? 'Zurück zur Reiseübersicht' : 'Abmelden'}</span>
                  </Button>
                ) : (
                  <LinkAsButton newPage link="http://meinschiff.com">
                    <span onClick={() => tracking.click('header.button.Zur Mein Schiff Webseite')}>
                      Zur <i>Mein Schiff</i>&nbsp;&nbsp;Webseite
                    </span>
                  </LinkAsButton>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="header__border" />
      </header>
    );
  }
}
