// @flow

import fetchForUser from '../utils/fetchForUser';
import fetchPdfForUser from '../utils/fetchPdfForUser';
import type User from '../models/User';
import type ArrivalDeparture from '../models/ArrivalDeparture';
import { isLocal, mockAll } from './utils';

const dummyFiles = [
  'vg_1782695',
  'vg_1782696',
  'vg_1782697',
  'vg_1782698',
  'vg_1782699',
  'vg_1782700',
  'vg_1782701',
  'vg_1782702',
  'vg_1782703',
  'arrivalAndDepartureMock',
  'empty', // 10
  'travelplan-01',
  'travelplan-02',
  'train/outbound-more-pdfs',
  'train/inbound-more-pdfs',
  'train/not-yet',
  'train/both-simple-child',
];

const mock = false;
const getUrl = () => {
  return (mock || mockAll) && isLocal
    ? `/mock/arrivalDeparture/${dummyFiles[12]}.json` // 0 ... 12
    : `/api/customer/travelplan`;
};

const get = (user: User) => fetchForUser(user, getUrl());
export type ApiType = {
  get: User => Promise<ArrivalDeparture>,
  getTrain: User => Promise<ArrivalDeparture>,
  getRailAndFly: User => Promise<ArrivalDeparture>,
};

const mockTrain = false;
const getTrainUrl = () => {
  return (mockTrain || mockAll) && isLocal
    ? `/mock/arrivalDeparture/train/not-yet.json`
    : `/api/booking/v1/tickets/train`;
};

const getTrain = (user: User) => fetchForUser(user, getTrainUrl(), { getErrorCode: 502 });

const getTrainPdf = (user: User, pdf) => fetchPdfForUser(user, `/api/booking/v1/tickets/train/document/${pdf}`);

// TUICUNIT-2789
// 2: RailAndFlyIncluded
// 3: VouchersCreated
// 4: VouchersChangedAfterRedeeming
// 5: VouchersCancelledAfterRedeeming
const mockRailVoucher = false;
const getRailVoucherUrl = () => {
  return (mockRailVoucher || mockAll) && isLocal
    ? `/mock/arrivalDeparture/railAndFly/RailAndFlyIncluded.json`
    : `/api/booking/v1/travel-documents/rail-voucher`;
};

const getRailVoucher = (user: User) => fetchForUser(user, getRailVoucherUrl());

export default { get, getTrain, getTrainPdf, getRailVoucher };
