// @flow

import type { IInclusiveBookingStatusList } from '../../api/includedShoreEx';

export default class IncludedShoreExListingStatus
  implements IInclusiveBookingStatusList {
  constructor(data: IInclusiveBookingStatusList) {
    Object.assign(this, { ...data });
  }
}
