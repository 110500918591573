// @flow

import React, { Component } from 'react';
import Published from './Published';
import NonPublished from './NonPublished';

import type ArrivalDepartureItem from '../../../../../models/ArrivalDeparture/ArrivalDepartureItem';

type Props = {
  flight: ArrivalDepartureItem,
};

export default class FlightInfoItem extends Component<Props> {
  render() {
    const { flight } = this.props;
    if (!flight) return null;
    const FlightInfoCase = flight.isPublishedFlight ? Published : NonPublished;
    return <FlightInfoCase flight={flight} />;
  }
}
