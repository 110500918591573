// @flow
import React, { Component } from 'react';
import { action } from 'mobx';
import { observer } from 'mobx-react';

import Tile from './Tile';
import { openPdf } from '../../utils/navigation';

type Props = {
  link: ?string,
  title: ?string,
  print?: ?boolean,
  disabled: ?boolean,
  icon: ?string,
  onClick?: ?() => mixed,
};

@observer
export default class PdfTile extends Component<Props> {
  @action.bound
  handleClick(event: window.Event) {
    event.preventDefault();

    const { link, print, disabled } = this.props;
    if (!link || disabled) return;

    openPdf(link, print);
  }

  render() {
    const { title, link, print, disabled, icon, onClick } = this.props;

    return (
      <Tile
        link={link}
        linkIsExternal={!!print}
        title={title}
        disabled={disabled}
        onClick={onClick || this.handleClick}
        icon={icon}
      />
    );
  }
}
