import { useEffect } from 'react';

type Options = {
  maxScreenWidth?: number;
};
export default function useLockBodyScroll(options?: Options) {
  const { maxScreenWidth } = options || {};
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    const onResize = () => {
      if (maxScreenWidth && window.innerWidth > maxScreenWidth) {
        document.body.style.overflow = originalStyle;
        return;
      }
      document.body.style.overflow = 'hidden';
    };

    window.addEventListener('resize', onResize, false);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize, false);
      document.body.style.overflow = originalStyle;
    };
  }, [maxScreenWidth]);
}
