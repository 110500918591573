// @flow

import platform from 'platform';

export const isIE = (): boolean =>
  platform && platform.name ? platform.name.toLowerCase() === 'ie' : true;

export const isEdge = (): boolean =>
  platform && platform.name
    ? platform.name.toLowerCase() === 'microsoft edge'
    : true;

export const weakBrowser = (): boolean => isEdge() || isIE();
