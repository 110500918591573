// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import includes from 'lodash/includes';

import type NewspaperBookingRequest from '../../models/BookingRequest/NewspaperBookingRequest';
import type NewspaperList from '../../models/NewspaperList';

import Price from '../Price';
import Button from '../Button';
import CheckboxGroup from '../CheckboxGroup';

import {
  PACKAGE_DELIVER_ON_FIRST_DAY,
  PACKAGE_DELIVER_ON_LAST_DAY,
} from '../../config/constants';

type Props = {
  bookable: NewspaperList,
  bookingRequest: NewspaperBookingRequest,
  onBooking: ?Function,
  disabled: boolean,
  buttonText: string,
};

@observer
export default class NewspaperBookingAssistant extends Component<Props> {
  handleChange(packageId: string, checked: boolean) {
    this.props.bookingRequest.toggleSelection(packageId, checked);
  }

  handleOptionChange(optionName: string, checked: boolean) {
    this.props.bookingRequest.toggleOption(optionName, checked);
  }

  render() {
    const { bookingRequest, bookable, onBooking, buttonText } = this.props;

    return (
      <div>
        <h2>Zeitungspakete wählen:</h2>
        <CheckboxGroup>
          {bookable.packages.map(item => (
            <CheckboxGroup.Item
              key={item.id}
              value={item.id}
              onChange={event =>
                this.handleChange(item.id, event.currentTarget.checked)
              }
              disabled={this.props.disabled || !item.vacancies}
              checked={includes(bookingRequest.selectedPaperIds, item.id)}
            >
              {item.name}
            </CheckboxGroup.Item>
          ))}
        </CheckboxGroup>
        <h2>Optional:</h2>
        <CheckboxGroup>
          <CheckboxGroup.Item
            value={PACKAGE_DELIVER_ON_FIRST_DAY}
            onChange={event =>
              this.handleOptionChange(
                PACKAGE_DELIVER_ON_FIRST_DAY,
                event.currentTarget.checked
              )
            }
            disabled={this.props.disabled || !bookable.isAnythingBookable}
          >
            Ich möchte die erste Zeitung am Anreisetag bekommen.*
          </CheckboxGroup.Item>
          <CheckboxGroup.Item
            value={PACKAGE_DELIVER_ON_LAST_DAY}
            onChange={event =>
              this.handleOptionChange(
                PACKAGE_DELIVER_ON_LAST_DAY,
                event.currentTarget.checked
              )
            }
            disabled={this.props.disabled || !bookable.isAnythingBookable}
          >
            Ich möchte auch am Abreisetag eine Zeitung bekommen.*
          </CheckboxGroup.Item>
        </CheckboxGroup>

        <div className="l-right l-mod-sub">
          {!bookingRequest.hasBookedSomethingOfSameType ? (
            <div>
              <Price price={bookable.price} label="je Tag und Zeitung" />
            </div>
          ) : null}
          <Button
            dark
            big
            disabled={!bookingRequest.isReady}
            onClick={onBooking}
          >
            {buttonText}
          </Button>
        </div>
        <p className="annotation">
          * Dies ist nur möglich, wenn die gewünschte Zeitung auch am Tag der
          An- bzw. Abreise erscheint.
        </p>
      </div>
    );
  }
}
