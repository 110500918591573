// @flow

import * as React from 'react';

/**
 * Adds width and height attributes to its images urls
 */
export const italicShip = (text: string) =>
  text.replace(/(Mein Schiff)/g, '<i>$1</i>');

export const italicShipComponent = (text: string) => {
  const matches = text.match(/Mein Schiff (\d)+/);
  if (!matches) return text;
  const shipNumber = matches[1];
  return (
    <span>
      {text.split(/Mein Schiff \d+/).reduce((stack, text, index, array) => {
        stack.push(text);
        index < array.length - 1 &&
          stack.push(<i key={index}>Mein Schiff {shipNumber}</i>);
        return stack;
      }, [])}
    </span>
  );
};

export const isAlphanumeric = (s: ?string): boolean => {
  if (!s) s = '';
  return /^\w+$/.test(s) && s.indexOf('_') <= -1;
};

export const infoLine = (fields: any[], separator: string = ', '): string => {
  return fields
    .filter(f => !!f)
    .map(f => `<span class="field">${f}</span>`)
    .join(separator);
};

/**
 * SUPTUICMRS-2605 allow international IBANs (TUICMRL-1066, TUICROLLOUTS-525)
 *
 * https://stackoverflow.com/questions/40615902/detect-iban-in-text
 * https://www.iban.com/structure
 * https://en.wikipedia.org/wiki/International_Bank_Account_Number
 * https://www.npmjs.com/package/fast-iban
 * */
export const validateIban = (iban: string): string => {
  try {
    iban = iban.replace(/ /g, '').toUpperCase();
    const match = iban.match(/^[A-Z]{2}[0-9]{2}[0-9A-Z]{11,30}$/);
    return match ? match[0] : '';
  } catch (exc) {
    return '';
  }
};
