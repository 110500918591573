// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import LoadingIndicator from '../../../components/LoadingIndicator';
import View from '../../../components/Manifest/Regulations/View';

import type ManifestStore from '../../../stores/ManifestStore';
type Props = {
  manifestStore: ManifestStore,
};

@inject('manifestStore')
@observer
export default class PageManifestRegulations extends Component<Props> {
  static breadcrumb = 'Einreisebestimmungen';

  componentDidMount() {
    this.props.manifestStore.fetchRegulations();
  }

  render() {
    const { regulations } = this.props.manifestStore;
    if (!regulations) return <LoadingIndicator />;
    return <View regulations={regulations} />;
  }
}
