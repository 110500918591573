// TODO: add flow types

import { observable, action, computed } from 'mobx';
import groupBy from 'lodash/groupBy';

import SpaOfferListDetails from '../models/SpaOfferListDetails';

export default class SpaStore {
  spaApi = null;
  contentApi = null;
  masterStore = null;

  categoryStores = {};

  @observable categories = null;

  constructor(spaApi, contentApi, masterStore) {
    this.spaApi = spaApi;
    this.contentApi = contentApi;
    this.masterStore = masterStore;
  }

  storeForCategories(id) {
    if (!this.categoryStores[id]) {
      this.categoryStores[id] = new SpaCategoryStore(this.spaApi, this.masterStore, id);
    }
    return this.categoryStores[id];
  }

  fetchCategories() {
    if (!this.request) {
      this.request = this.contentApi.spaCategories(this.masterStore).then(
        action((categories) => {
          this.categories = categories;
          this.request = null;
        })
      );
    }
    return this.request;
  }

  @computed
  get categoriesByType() {
    if (!this.categories) return {};
    return groupBy(this.categories, 'type');
  }

  fetchCategoryData(id) {
    return new Promise((resolve) => {
      const category = this.getCategory(id);
      if (category) return resolve(category);

      this.fetchCategories().then(() => {
        resolve(this.getCategory(id));
      });
    });
  }

  getCategory(id) {
    return this.categories && this.categories.find((cat) => cat.mxpId === id);
  }
}

export class SpaCategoryStore {
  @observable allItems = null;
  @observable hint = null;
  @observable items = null;
  @observable isLoading = false;

  request = null;

  constructor(api, masterStore, division) {
    this.api = api;
    this.masterStore = masterStore;
    this.division = division; // beauty or fitness
  }

  @action
  fetch(category) {
    const { user, masterData } = this.masterStore;
    this.isLoading = true;
    if (!this.request) {
      this.request = this.api.getList(user, masterData, this.division).then(
        action((response) => {
          this.hint = response.hint;
          this.allItems = response.spaOffers.map((offer) => new SpaOfferListDetails(offer, this.masterStore));
        })
      );
    }
    this.request.then(
      action(() => {
        this.items = this.allItems.filter((offer) => offer.category === category);
        this.isLoading = false;
      })
    );
    return this.request;
  }
}
