// @flow
import React from 'react';
import AccordionSingleMode from '../AccordionSingleMode';

// TUICUNIT-981
const PortalIntroPin = () => (
  <div className="portal-intro pin">
    <div className="icon-headline">
      <span className="icon info" />
      <h2>Wir sorgen für die Sicherheit Ihrer persönlichen Daten</h2>
    </div>
    <p>
      Wir möchten Sie an dieser Stelle darüber informieren, dass wir das
      Schiffsmanifest sowie weitere Bereiche im Meine Reise Portal, die sensible
      Daten erhalten, ab sofort mit einer PIN schützen. Damit sorgen wir für die
      Sicherheit Ihrer persönlichen Daten und machen die Nutzung des Meine Reise
      Bereichs noch sicherer.
    </p>
    <AccordionSingleMode
      items={[
        {
          header: 'Welche Bereiche sind mit einer PIN geschützt?',
          content: (
            <p>
              Wir haben das Schiffsmanifest, und sofern der Online Check-in für
              Ihre Reise verfügbar ist, die Bereiche „Zahlungsmittel“,
              „Schiffspass drucken“, „Schiffpass als PDF speichern“ und „In
              Apple Wallet öffnen“ mit einer PIN geschützt.
            </p>
          ),
        },
        {
          header: 'Wie setzt sich meine PIN zusammen?',
          content: (
            <p>
              Ihre individuelle PIN ergibt sich aus den letzten vier Zeichen
              Ihres Ausweisdokuments (Reisepass oder Personalausweis), die Sie
              im Schiffsmanifest angegeben haben bzw. angeben werden.
            </p>
          ),
        },
        {
          header: 'Was ist jetzt anders für mich?',
          content: (
            <p>
              Der Bereich „Ausweis“ ist ab sofort verpflichtend im
              Schiffsmanifest auszufüllen, da die letzten vier Zeichen Ihres
              angegebenen Ausweisdokuments als PIN verwendet werden, um Ihre
              persönlichen Daten im Meine Reise Portal zu schützen. Bei jeder
              Anmeldung im Meine Reise Portal muss die PIN einmalig eingegeben
              werden, wenn Sie auf einen der geschützten Bereiche zugreifen
              möchten.
            </p>
          ),
        },
        {
          header:
            'Was passiert, wenn sich mein Ausweisdokument nachträglich ändert?',
          content: (
            <p>
              Die PIN ergibt sich immer aus dem aktuell hinterlegten
              Ausweisdokument. Sofern die Daten des Personalausweises oder
              Reisepasses geändert werden (z.B. Änderung von Reisepass auf
              Personalausweis), ändert sich auch Ihre PIN. Nach dem Speichern
              der Änderung, werden Sie darüber informiert, dass eine neue PIN
              gültig ist.
            </p>
          ),
        },
      ]}
    />
  </div>
);

export default PortalIntroPin;
