//@flow
import React, { Component, type Node } from 'react';
import Breadcrumb from '../breadcrumbs/Breadcrumb';
import PropTypes from 'prop-types';

type Props = {
  children: Node,
};

export default class PageWithBreadcrumb extends Component<Props> {
  //ATTENTION: react needs the proptypes for the context. or use a different construct with react > v16.3
  static contextTypes = {
    breadcrumbs: PropTypes.array.isRequired,
  };

  render() {
    return (
      <div>
        <Breadcrumb>{this.context.breadcrumbs}</Breadcrumb>
        {this.props.children}
      </div>
    );
  }
}
