// @flow
const plaintext = !window.btoa || !window.atob;

// encode base64
export const encode = (x: string): string => {
  if (plaintext) return x;

  try {
    return window.btoa(x) || '';
  } catch (exc) {
    console.log(exc);
    return '';
  }
};

// decode base64
export const decode = (x: string): string => {
  if (plaintext) return x;

  try {
    return window.atob(x) || '';
  } catch (exc) {
    console.log(exc);
    return '';
  }
};

// https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
export const b64EncodeUnicode = (str: string) => {
  return encode(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }
    )
  );
};
