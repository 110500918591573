// @flow

export default class SpaTreatmentStatus {
  type: string;
  spaId: number;
  mpi: number;
  status: string;
  statusCode: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
