// @flow

const pinRedirectUrl = (url: string): string => {
  if (url.indexOf('/checkin') >= 0) {
    return '/checkin';
  }
  if (url.indexOf('/payment') >= 0) {
    return '/payment';
  }

  return '/manifest';
};

export { pinRedirectUrl };
