//@flow
export const tealiumString = (value) => {
  let sanitized = value
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/&shy;/g, '');

  // Convert all to lower case
  sanitized = sanitized.toLowerCase();

  // Replace spaces with dashes
  sanitized = sanitized.replace(/\s+/g, '-');

  // Replace special characters, preserving allowed ones (., -, _, |)
  sanitized = sanitized.replace(/[^a-z0-9-_.|&]/g, '-');

  // Replace multiple dashes with a single dash
  sanitized = sanitized.replace(/-+/g, '-');

  // Remove dashes at the start or end of the string
  sanitized = sanitized.replace(/^-|-$/g, '');

  return sanitized;
};

export const tealiumDateFromString = (value: string) => {
  if (!value) {
    return tealiumDateFromDateObject(new Date());
  }
  let newValue = String(value);
  newValue = newValue.split('-').join('');
  return newValue;
};

export const tealiumUnixTimeToDate = (unixTime: number): string => {
  const date = new Date(unixTime * 1000); // Convert Unix timestamp to milliseconds

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
};
export const tealiumDateFromDateObject = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-based index
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}${month}${day}`;
};

export const tealiumValue = (value: string) => {
  return Number.parseFloat(value).toFixed(2);
};

export const tealiumIdFromString = (str: string): number => {
  let hash = 5381;
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0;
};
