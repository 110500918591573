// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import preload from '../../components/pages/decorators/preload';

import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import DetailPageLayout from '../DetailPageLayout';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import RestaurantBookingAssistant from '../BookingAssistant/RestaurantBookingAssistant';
import BookingList from '../BookingList/Restaurant';
import DayChooser from '../DayChooser';

import ItineraryDayModel from '../../models/ItineraryDay';
import RestaurantBookingRequest from '../../models/BookingRequest/RestaurantBookingRequest';
import RestaurantOffer from '../../models/RestaurantOffer';
import tracking from '../../tracking';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type Booking from '../../models/Booking';
import { tealiumDateFromString, tealiumIdFromString } from '../../utils/tracking';

@preload({ details: 'RestaurantOffer' }, false)
@observer
class RestaurantDetailsBreadcrumb extends Component<{
  details: RestaurantOffer,
}> {
  triggered = false;

  render() {
    const { details } = this.props;
    return <BreadcrumbLink {...this.props} text={details.name} />;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  details: RestaurantOffer,
  day: ?ItineraryDayModel,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ details: 'RestaurantOffer' })
@preload({ day: 'ItineraryDay' }, false, true)
@observer
export default class PageRestaurantDetails extends Component<Props> {
  static breadcrumb = RestaurantDetailsBreadcrumb;

  bookingRequest: RestaurantBookingRequest;

  constructor(props: Props) {
    super(props);

    const { details, masterStore, day } = this.props;
    this.bookingRequest = new RestaurantBookingRequest(masterStore.masterData.travelParty, details);
    if (day) {
      this.bookingRequest.chooseDay(day);
    }
  }

  triggerTracking() {
    tracking.viewItem(this.props.breadcrumbRouterStore, {
      category: this.props.details.analyticsCategory,
      categoryId: tealiumIdFromString(this.props.details.analyticsCategory),
      discount: '0.00',
      code: this.props.details.id,
      name: this.props.details.title,
      operator: {
        code: 'intern',
        name: 'intern-meinschiff',
      },
      quantity: '1',
      startDate: tealiumDateFromString(this.bookingRequest.selectedDay.date),
      value: '0.00',
    });
  }

  @computed
  get bookings(): Booking[] {
    const { masterStore } = this.props;
    if (!this.bookingRequest || !this.bookingRequest.selectedDay) {
      return [];
    }
    return masterStore.masterData.getBookings(this.bookingRequest.selectedDay.date, this.props.details.id);
  }

  @autobind
  openAssitantWithDay(day: ItineraryDayModel) {
    this.bookingRequest.chooseDay(day);
    this.triggerTracking();
    window.scrollTo(0, 0);
  }

  @autobind
  handleBookingCancelClick(booking: Booking) {
    this.bookingRequest.cancelBooking(booking);
    this.props.bookingUiStore.openBookingDialog();
  }

  @autobind
  handleBackClick() {
    this.bookingRequest.removeDay();
  }

  render() {
    const { details, masterStore, bookingUiStore } = this.props;
    const { masterData } = masterStore;

    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        <DetailPageLayout
          model={details}
          bookingList={() => (
            <BookingList
              header="Bereits reservierte Termine für diese Leistung:"
              bookings={this.bookings}
              onCancelBooking={this.handleBookingCancelClick}
            />
          )}
          sidebar={(model) =>
            this.bookingRequest ? (
              <RestaurantBookingAssistant
                bookable={model}
                travelParty={masterData.travelParty}
                bookingRequest={this.bookingRequest}
                onBooking={() => {
                  tracking.addToCart(this.bookingRequest.selectedCount, 0);
                  this.bookingRequest.resetCancellation();
                  bookingUiStore.openBookingDialog();
                }}
              />
            ) : null
          }
          bottomPart={(model) =>
            !this.bookingRequest || !this.bookingRequest.selectedDay ? (
              <DayChooser
                model={model}
                title="Bitte wählen Sie einen Tag aus, an dem Sie einen Tisch reservieren möchten:"
                onSelect={(index) => this.openAssitantWithDay(index)}
              />
            ) : null
          }
        />
      </WithBookingConfirmation>
    );
  }
}
