export const l10n = {
  survey: {
    de: {
      hintHeadline: 'Zusatzinformation',
      prev: 'Zurück',
      next: 'Weiter',
      save: 'Angaben speichern',
    },
    en: {
      hintHeadline: 'Additional information',
      prev: 'Back',
      next: 'Continue',
      save: 'Save data',
    },
  },
  surveyResult: {
    de: {
      headlineOk: 'Angaben vollständig erfasst',
      headlineNotOk: 'Wichtiger Hinweis:',
      notOkText: '',
      answerConfirm: 'Bestätigt',
      submitted: 'Zuletzt geändert',
      timeUnit: 'Uhr',
      edit: 'Angaben aktualisieren',
    },
    en: {
      headlineOk: 'Successfully completed',
      headlineNotOk: 'Important notice:',
      notOkText: '',
      answerConfirm: 'Confirmed',
      submitted: 'Last changed',
      timeUnit: 'h',
      edit: 'Change data',
    },
  },
  confirm: {
    de: {
      headline: 'Bitte überprüfen Sie Ihre Angaben',
      text: 'Sind Ihre Angaben noch aktuell?',
      buttonYes: '<span class="bold">Ja,</span> ich bestätige die Aktualität',
      buttonNo: '<span class="bold">Nein,</span> Angaben aktualisieren',
    },
    en: {
      headline: 'Please check your data',
      text: 'Are your answers still up to date?',
      buttonYes: '<span class="bold">Yes,</span> confirm data',
      buttonNo: '<span class="bold">No,</span> change data',
    },
  },
};
