// @flow

import React, { type Node } from 'react';
import { Provider } from 'mobx-react';

import contentApi from './api/content';
import masterDataApi from './api/masterData';
import itineraryApi from './api/itinerary';
import staticContentApi from './api/staticContent';
import packageApi from './api/package';
import offerApi from './api/offer';
import manifestApi from './api/manifest';
import bookingApi from './api/bookings';
import excursionApi from './api/excursion';
import restaurantApi from './api/restaurant';
import spaApi from './api/spa';
import tilesApi from './api/tiles';
import boardAccountApi from './api/boardAccount';
import imageApi from './api/image';
import vacancyApi from './api/vacancy';
import paymentApi from './api/payment';
import checkInApi from './api/checkIn';
import dailyEventsApi from './api/dailyEvents';
import highlightsApi from './api/highlights';
import appStoreTilesApi from './api/appStoreTiles';
import emailApi from './api/reservationEmail';
import boardingApi from './api/boarding';
import arrivalAndDepartureApi from './api/arrivalAndDeparture';
import spaBalconyCabinApi from './api/spaBalconyCabin';
import includedShoreExApi from './api/includedShoreEx';
import routeApi from './api/route';
import brochureApi from './api/brochure';
import configApi from './api/config';
import vipOptionApi from './api/vipOption';
import timeSloteApi from './api/timeSlote';
import digitalHealthApi from './api/digitalHealth';
import travelDocumentsApi from './api/travelDocuments';
import vipCouponApi from './api/vipCoupon';
import flightPreferenceApi from './api/flightPreference';
import spaTeaserApi from './api/spaTeaser';
import spaBeautySalonApi from './api/spaBeautySalon';
import maintenanceApi from './api/maintenance';

import { authStore } from './stores/AuthenticationStore';
import MasterStore from './stores/MasterStore';
import PackageStore from './stores/PackageStore';
import OfferStore from './stores/OfferStore';
import StaticContentStore from './stores/StaticContentStore';
import ExcursionStore from './stores/ExcursionStore';
import RestaurantStore from './stores/RestaurantStore';
import BookingStore from './stores/BookingStore';
import SpaStore from './stores/SpaStore';
import TilesStore from './stores/TilesStore';
import ManifestStore from './stores/ManifestStore';
import BoardAccountStore from './stores/BoardAccountStore';
import BookingImageStore from './stores/BookingImageStore';
import DailyEventsStore from './stores/DailyEventsStore';
import VacancyStore from './stores/VacancyStore';
import AppStoreTilesStore from './stores/AppTilesStore';
import PaymentStore from './stores/PaymentStore';
import CheckInStore from './stores/CheckInStore';
import BookingUiStore from './stores/BookingUiStore';
import HighlightStore from './stores/HighlightStore';
import EmailStore from './stores/EmailStore';
import BoardingStore from './stores/BoardingStore';
import ArrivalDepartureStore from './stores/ArrivalDepartureStore';
import RouteStore from './stores/RouteStore';
import BrochureStore from './stores/BrochureStore';
import SpaBalconyCabinStore from './stores/SpaBalconyCabinStore';
import ConfigStore from './stores/ConfigStore';
import IncludedShoreExStore from './stores/IncludedShoreExStore';
import VipOptionStore from './stores/VipOptionStore';
import TimeSloteStore from './stores/TimeSloteStore';
import DigitalHealthStore from './stores/DigitalHealthStore';
import TravelDocumentsStore from './stores/TravelDocumentsStore';
import VipCouponStore from './stores/VipCouponStore';
import FlightPreferenceStore from './stores/FlightPreferenceStore';
import SpaTeaserStore from './stores/SpaTeaserStore';
import SpaBeautySalonStore from './stores/SpaBeautySalonStore';
import MaintenanceStore from './stores/MaintenanceStore';
import BreadcrumbRouterStore from './stores/BreadcrumbRouterStore';

const masterStore = new MasterStore(
  {
    getMasterData: masterDataApi.get,
    getSurveyData: masterDataApi.getSurvey,
    getItinerary: itineraryApi.get,
  },
  authStore
);

const STORES = {
  bookingUiStore: new BookingUiStore(),
  masterStore,
  authStore,
  packageStore: new PackageStore(packageApi, bookingApi, masterStore),
  offerStore: new OfferStore(offerApi, masterStore),
  staticContentStore: new StaticContentStore(staticContentApi, masterStore),
  excursionStore: new ExcursionStore(excursionApi, masterStore),
  restaurantStore: new RestaurantStore(restaurantApi, masterStore),
  bookingStore: new BookingStore(bookingApi, masterStore),
  breadcrumbRouterStore: new BreadcrumbRouterStore(),
  spaStore: new SpaStore(spaApi, contentApi, masterStore),
  tilesStore: new TilesStore(tilesApi, masterStore),
  manifestStore: new ManifestStore(manifestApi, masterStore),
  boardAccountStore: new BoardAccountStore(boardAccountApi, masterStore),
  bookingImageStore: new BookingImageStore(imageApi, masterStore),
  vacancyStore: new VacancyStore(vacancyApi, masterStore),
  paymentStore: new PaymentStore(paymentApi, masterStore),
  checkInStore: new CheckInStore(checkInApi, masterStore),
  dailyEventsStore: new DailyEventsStore(dailyEventsApi, masterStore),
  highlightStore: new HighlightStore(highlightsApi, masterStore),
  emailStore: new EmailStore(emailApi, masterStore),
  boardingStore: new BoardingStore(boardingApi, masterStore),
  appStoreTilesStore: new AppStoreTilesStore(appStoreTilesApi, masterStore),
  arrivalDepartureStore: new ArrivalDepartureStore(
    arrivalAndDepartureApi,
    masterStore
  ),
  routeStore: new RouteStore(routeApi, masterStore),
  brochureStore: new BrochureStore(brochureApi, masterStore),
  spaBalconyCabinStore: new SpaBalconyCabinStore(
    spaBalconyCabinApi,
    masterStore
  ),
  includedShoreExStore: new IncludedShoreExStore(
    includedShoreExApi,
    masterStore
  ),
  configStore: new ConfigStore(configApi),
  vipOptionStore: new VipOptionStore(vipOptionApi, masterStore),
  timeSloteStore: new TimeSloteStore(timeSloteApi, masterStore),
  digitalHealthStore: new DigitalHealthStore(digitalHealthApi, masterStore),
  travelDocumentsStore: new TravelDocumentsStore(
    travelDocumentsApi,
    masterStore
  ),
  vipCouponStore: new VipCouponStore(vipCouponApi, masterStore),
  flightPreferenceStore: new FlightPreferenceStore(
    flightPreferenceApi,
    masterStore
  ),
  spaTeaserStore: new SpaTeaserStore(spaTeaserApi, masterStore),
  spaBeautySalonStore: new SpaBeautySalonStore(spaBeautySalonApi, masterStore),
  maintenanceStore: new MaintenanceStore(maintenanceApi, masterStore),
};

type Props = {
  children: Node,
};

export default class MobxConnection extends React.Component<Props> {
  render() {
    return <Provider {...STORES}>{this.props.children}</Provider>;
  }
}
