// @flow
import { observable, computed, action } from 'mobx';

import BookingRequestBase from './BookingRequestBase';

import { BOOKING_ADDED, BOOKING_DELETED } from './constants';

import PartyMember from '../../models/PartyMember';
import InternetPackage from '../../models/InternetPackage';
import BookedPackage from '../../models/BookedPackage';
import Booking from '../../models/Booking';

import type {
  BookingType,
  IGeneralBookingData,
  IGeneralItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
} from '../../types/booking';

export default class InternetBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text:
          'Wollen Sie dieses Internetpaket für folgenden Reiseteilnehmer reservieren?',
      },
      success: {
        ...BookingRequestBase.TEXTS.create.success,
        text:
          'Sobald Ihre Anfrage von uns bearbeitet wurde, wird diese in Ihrem Reiseplan angezeigt.',
      },
    },
    cancellation: {
      ...BookingRequestBase.TEXTS.cancellation,
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: bookingRequest =>
          `Möchten Sie den ${
            bookingRequest.bookable.bookingName
          } Service wirklich für folgende Reiseteilnehmer stornieren?`,
      },
    },
  };

  @observable selectedMpi = null;
  @observable bookingToCancel = null;
  @observable bookedPackages = [];

  constructor(
    travelparty: PartyMember[],
    bookable: InternetPackage,
    bookedPackages: ?(BookedPackage[])
  ) {
    super(travelparty, bookable);
    this.bookedPackages = bookedPackages;
    const requestor = travelparty.find(m => m.requestor);
    if (requestor) this.selectedMpi = requestor.mpi;
  }

  @computed
  get bookedPackagesOfSameType(): BookedPackage[] {
    return (this.bookedPackages || []).filter(pkg =>
      pkg.isType(this.bookable.packageType)
    );
  }

  @computed
  get hasBookedSomethingOfSameType(): boolean {
    return this.bookedPackagesOfSameType.length > 0;
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @computed
  get isChangeRequest(): boolean {
    return false;
  }

  @computed
  get isCancellation(): boolean {
    return !!this.bookingToCancel;
  }

  createItemToBook(mpi: number, type: BookingType) {
    const member = this.travelParty.find(p => p.mpi === mpi);

    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAge : '',
      price: null,
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
    };
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] {
    if (this.isCancellation) {
      return this.bookingToCancel
        ? this.bookingToCancel.mpis.map(mpi =>
            this.createItemToBook(mpi, BOOKING_DELETED)
          )
        : [];
    }
    return this.isReady && this.selectedMpi
      ? [this.createItemToBook(this.selectedMpi, BOOKING_ADDED)]
      : [];
  }

  @computed
  get total(): number {
    return this.bookable.price;
  }

  @computed
  get isReady(): boolean {
    return !this.hasBookedSomethingOfSameType;
  }

  get startDateTime(): ?Date {
    return null;
  }

  get endDateTime(): ?Date {
    return null;
  }

  @computed
  get bookingData(): IGeneralBookingData[] {
    if (this.isCancellation) return [];
    return [
      {
        type: 'package',
        vacancyId: this.bookable.vacancyId,
        quantity: 1,
      },
    ];
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.isCancellation || !this.bookingToCancel) return [];
    const mpisToCancel = this.bookingToCancel.mpis;
    const bookedIds = this.bookingToCancel.bookingId;

    return mpisToCancel.map(mpi => ({
      type: 'package',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const { headline, bookingId, analyticsCategory } = this.bookable;
    return {
      products: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}`,
              name: headline,
              category: analyticsCategory,
              quantity: 1,
              price: this.bookable.price,
            },
          ],
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}`,
              revenue: this.total,
            },
          ],
      refunds:
        this.isCancellation && this.bookingToCancel
          ? [
              {
                id: this.bookingToCancel.typeId,
              },
            ]
          : [],
    };
  }
}
