// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { observable, computed, action } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import union from 'lodash/union';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';

import { optionsShape } from '../../utils/PropTypes';

import CheckboxGroup from '../CheckboxGroup';
import ErrorMessage from './ErrorMessage';

@observer
export default class FormMultiSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    mandatory: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.any),
    options: optionsShape.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  @observable _value = [];
  @observable localError = null;

  constructor(props) {
    super(props);
    this._value = props.value ? [...props.value] : [];
  }

  componentDidMount() {
    if (this.context.registerFormElement && this.props.name) {
      this.context.registerFormElement(this.props.name, this);
    }
  }

  @computed
  get value() {
    return this._value.slice();
  }

  @action
  validate() {
    const { validateField } = this.context;

    if (validateField) {
      this.localError = validateField(this.props.name, this._value.slice());
    }
  }

  @action
  clearLocalError() {
    this.localError = null;
  }

  @action
  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this._value = this.props.value ? [...this.props.value] : [];
    }
  }

  @action
  handleChange(key, checked) {
    const { onChange } = this.props;
    this._value = checked
      ? union(this._value, [key])
      : without(this._value, key);

    if (onChange) onChange(key, checked, this._value.slice());
  }

  // TODO check if this function really works. (!isEqual)
  get hasChanged() {
    const initialValue = this.props.value || [];
    return isEqual(this.value, initialValue);
  }

  render() {
    const { className, label, mandatory, options, disabled } = this.props;

    return (
      <CheckboxGroup className={className || 'form-fieldset'}>
        {label ? (
          <label
            className={classNames({
              'form-checkbox-group__label': true,
            })}
          >
            {label}
            {mandatory ? '*' : ''}
          </label>
        ) : null}
        {options.map(option => (
          <CheckboxGroup.Item
            key={option.value}
            value={option.value}
            checked={this.value && this.value.indexOf(option.value) !== -1}
            onChange={event =>
              this.handleChange(option.value, event.currentTarget.checked)
            }
            disabled={disabled || option.disabled}
          >
            {option.label}
          </CheckboxGroup.Item>
        ))}
        <ErrorMessage error={this.localError} />
      </CheckboxGroup>
    );
  }
}
