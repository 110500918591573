// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import DailyEvent from '../../models/DailyEvent';
import BookingRequest from '../../models/BookingRequest/DailyEventBookingRequest';

import preload from '../pages/decorators/preload';
import DetailPageLayout from '../DetailPageLayout';
import ProgramEventInfoList from '../ProgramEventInfoList';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import BookingAssistant from '../BookingAssistant/DailyEventBookingAssistant';
import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import { tealiumDateFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

@preload({ dailyEvent: 'DailyEvent' }, false)
@observer
class ProgramDetailsBreadcrumb extends Component<{
  dailyEvent: DailyEvent,
}> {
  triggered = false;

  render() {
    const { dailyEvent } = this.props;
    if (!dailyEvent) {
      return null;
    }
    return <BreadcrumbLink {...this.props} text={dailyEvent.title} />;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  dailyEvent: DailyEvent,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ dailyEvent: 'DailyEvent' }, false)
@observer
export default class PageProgramDetails extends Component<Props> {
  static breadcrumb = ProgramDetailsBreadcrumb;

  bookingRequest: BookingRequest;

  constructor(props: Props) {
    super(props);

    const { bookingUiStore, masterStore, dailyEvent } = this.props;
    bookingUiStore.reset();
    this.bookingRequest = new BookingRequest(masterStore.masterData.travelParty, dailyEvent);
  }

  get subtitle(): ?string {
    const { day } = this.props.dailyEvent;
    if (!day) return;
    return day.breadcrumbTitle;

    // if (day.isArrival) {
    //   return `Anreise - ${day.formattedDate}`;
    // } else if (day.isDeparture) {
    //   return `Abreise - ${day.formattedDate}`;
    // } else {
    //   return `Tag ${day.day} - ${day.formattedDate}`;
    // }
  }

  @autobind handleBookingCancelClick() {
    this.bookingRequest.cancelBooking();
    this.props.bookingUiStore.openBookingDialog();
  }

  componentDidMount() {
    // Page View Tracking
    const { dailyEvent } = this.props;
    if (dailyEvent) {
      const { day } = dailyEvent;
      tracking.pageView(window.location.pathname, ['Programm', day.breadcrumbTitle, dailyEvent.title], 'product');
      this.triggerTracking();
    }
  }

  triggered = false;

  triggerTracking() {
    if (this.triggered === false) {
      this.triggered = true;

      tracking.viewItem(this.props.breadcrumbRouterStore, {
        category: this.props.dailyEvent.analyticsCategory,
        categoryId: this.props.dailyEvent.categoryId,
        discount: '0.00',
        code: this.props.dailyEvent.id,
        name: this.props.dailyEvent.title,
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
        quantity: '1',
        startDate: tealiumDateFromString(this.props.dailyEvent.startDate),
        value: tealiumValue(String(this.props.dailyEvent.priceAdult ?? 0)),
      });
    }
  }

  render() {
    const { masterStore, bookingUiStore, dailyEvent } = this.props;
    const { masterData } = masterStore;

    if (!dailyEvent) return null;

    const { day } = dailyEvent;
    if (!day) return null;

    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest} day={day}>
        <DetailPageLayout
          day={day}
          model={dailyEvent}
          infoList={(model) => <ProgramEventInfoList model={model} />}
          sidebar={(model) => (
            <BookingAssistant
              bookable={model}
              travelParty={masterData.travelParty}
              bookingRequest={this.bookingRequest}
              onBooking={() => {
                tracking.addToCart(this.bookingRequest.selectedMpis.length, this.bookingRequest.total);
                bookingUiStore.openBookingDialog();
              }}
              onChangeBooking={bookingUiStore.openBookingDialog}
              onCancel={this.handleBookingCancelClick}
              showParticipants={
                bookingUiStore.returnedFromConfirmation &&
                this.bookingRequest &&
                this.bookingRequest.selectedMpis &&
                this.bookingRequest.selectedMpis.length > 0
              }
            />
          )}
        />
      </WithBookingConfirmation>
    );
  }
}
