// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import type SpaBalconyCabin from '../models/SpaBalconyCabin';
import type SpaTreatmentStatusList from '../models/SpaBalconyCabin/SpaTreatmentStatusList';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import { isLocal, mockAll } from './utils';

const mock = false;
const getSpaBalconyCabinUrl = (): string =>
  (mock || mockAll) && isLocal
    ? `/mock/TBD.json`
    : `/api/package/priv/spa/list/spabalconycabin`;
const getSpaTreatmentStatusListUrl = (spaId: number): string =>
  (mock || mockAll) && isLocal
    ? `/mock/TBD.json`
    : `/api/package/priv/spa/list/reservationstatus/${spaId}`;

export const sanitizers = {
  getSpaBalconyCabin: (data: SpaBalconyCabin) => ({
    ...camelCaseKeys(data, true),
    title: data.headline,
  }),
  getSpaTreatmentStatusList: (data: SpaTreatmentStatusList) => ({
    ...camelCaseKeys(data, true),
  }),
};

export default sanitized(
  {
    getSpaBalconyCabin: (user: User) =>
      fetchForUser(user, getSpaBalconyCabinUrl()),
    getSpaTreatmentStatusList: (user: User, spaId: number) =>
      fetchForUser(user, getSpaTreatmentStatusListUrl(spaId)),
  },
  sanitizers
);

export type ApiType = {
  getSpaBalconyCabin: User => Promise<SpaBalconyCabin>,
  getSpaTreatmentStatusList: (User, number) => Promise<SpaTreatmentStatusList>,
};
