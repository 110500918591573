// @flow
import hints from './static-data/hints';
import { jsLocalDateTime } from '../../utils/timezone';

export default class ArrivalDepartureItem {
  additionalServiceText: ?string;
  airline: ?string;
  airlineLogo: ?string;
  arrivalDate: ?string;
  arrivalTime: ?string;
  baggage: ?string;
  busStopName: ?string;
  date: ?string;
  departureDate: ?string;
  departureTime: ?string;
  drink: ?string;
  flightClass: ?string;
  flightNumber: ?string;
  from: ?string;
  fromName: ?string;
  meal: ?string;
  mpis: ?(number[]);
  seat: ?string;
  ticketFileKey: ?string;
  ticketNumber: ?string;
  to: ?string;
  toName: ?string;
  type: ?string;
  via: ?string;
  viaName: ?string;
  hint: string;
  isIndividualFlight: ?boolean;
  codeShareFlight: ?string;

  trainNumber: ?string;
  orderNumber: ?string;
  filename: ?string;
  adults: ?number;
  children: ?number;
  reservationNumber: ?string;
  subtitle: ?string;
  title: ?string;
  class: ?string;

  constructor(data: Object) {
    Object.assign(this, data);

    this.hint = this.isPublishedFlight ? hints.published : hints.nonPublished;
    if (this.isBus) {
      this.hint = hints.bus;
    }

    if (this.isTrain) {
      this.hint = null;
      this.arrivalTimeOrg = this.arrivalTime;
      this.departureTimeOrg = this.departureTime;

      const dt = jsLocalDateTime(this.departureTimeOrg);
      const dFormat = this.formatDate(dt);
      this.departureTime = dFormat.time;
      this.departureDate = dFormat.date;

      const at = jsLocalDateTime(this.arrivalTimeOrg);
      const aFormat = this.formatDate(at);
      this.arrivalTime = aFormat.time;
      this.arrivalDate = aFormat.date;

      if (this.trainChanges) {
        this.trainChanges.map((item, index) => {
          this.trainChanges[index].arrivalTimeOrg = item.arrivalTime;
          this.trainChanges[index].departureTimeOrg = item.departureTime;
          const dt = jsLocalDateTime(item.departureTime);
          const dFormat = this.formatDate(dt);
          this.trainChanges[index].departureTime = dFormat.time;
          this.trainChanges[index].departureDate = dFormat.date;

          const at = jsLocalDateTime(item.arrivalTime);
          const aFormat = this.formatDate(at);
          this.trainChanges[index].arrivalTime = aFormat.time;
          this.trainChanges[index].arrivalDate = aFormat.date;
        });
      }
    }
  }

  get isBus(): boolean {
    return !!this.busStopName;
  }

  get isFlight(): boolean {
    return !!this.flightNumber;
  }

  get isTrain(): boolean {
    return !!this.trainNumber;
  }

  get isPublishedFlight(): boolean {
    return this.isFlight && this.type === 'published';
  }

  get isValidFlight(): boolean {
    return !!(
      this.isFlight &&
      this.arrivalDate &&
      this.departureDate &&
      this.flightClass &&
      this.from &&
      this.to
    );
  }

  get departureComparisonTime(): number {
    // e.g. 201907190850
    return parseInt(
      `${(this.departureDate || '0')
        .split('.')
        .reverse()
        .join('')}${(this.departureTime || '0')
        .replace(' Uhr', '')
        .replace(':', '')}`,
      10
    );
  }

  formatDate(date: Date): { time: string, date: string } {
    const h = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const m =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const dayMap = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const day = dayMap[date.getDay()];
    const dD = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const dM =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const dY = date.getFullYear();

    return {
      time: `${h || '--'}:${m || '--'} Uhr`,
      date: day ? `${day}, ${dD}.${dM}.${dY}` : '',
    };
  }
}
