// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import classNames from 'classnames';

import { optionsShape } from '../../utils/PropTypes';

import ErrorMessage from './ErrorMessage';

function Label({ name, className, children, weakBrowserMode }) {
  return weakBrowserMode ? (
    <div className={className}>{children}</div>
  ) : (
    <label htmlFor={name} className={className}>
      {children}
    </label>
  );
}

class FormSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    value: PropTypes.any,
    // If we want to show an empty option, use the placeholder
    placeholder: PropTypes.string,
    options: optionsShape.isRequired,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    mandatory: PropTypes.bool,
    // Should it be displayed with a frame around it?
    hasFrame: PropTypes.bool,
    disabled: PropTypes.bool,
    noErrorMessage: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.bool,
    weakBrowserMode: PropTypes.bool,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  state = {};

  componentDidMount() {
    this.context.registerFormElement &&
      this.context.registerFormElement(this.props.name, this);
  }

  get value() {
    return this._ref.value;
  }

  set value(value) {
    this._ref.value = value || '';
  }

  reset() {
    this.value = null;
  }

  get optionGroups() {
    const { options } = this.props;
    const hasPrioriotyItems = options.find(o => o.priority);

    if (hasPrioriotyItems) {
      return [
        options.filter(o => o.priority),
        options.filter(o => !o.priority),
      ];
    }
    return [options];
  }

  validate() {
    const { validateField } = this.context;

    if (validateField) {
      this.setState({
        localError: validateField(this.props.name, this.value),
      });
    }
  }

  clearLocalError() {
    this.setState({
      localError: false,
    });
  }

  @autobind
  onChange(event) {
    const { onChange } = this.props;
    this.validate();
    const { options, selectedIndex } = event.target;
    const label = options[selectedIndex].label;
    if (onChange) onChange(this.value, label);
  }

  @autobind
  onBlur() {
    const { onBlur } = this.props;
    this.validate();
    if (onBlur) onBlur(this.value, this);
  }

  renderOption(option) {
    return (
      <option
        key={option.value}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </option>
    );
  }

  get hasChanged() {
    return this.value !== (this.props.value || '');
  }

  render() {
    const {
      name,
      label,
      placeholder,
      value,
      mandatory,
      hasFrame,
      disabled,
      hasError,
      noErrorMessage,
      weakBrowserMode,
    } = this.props;
    const errorMessage = this.state.localError || this.props.error;
    const error = hasError || errorMessage;
    const optionGroups = this.optionGroups;

    return (
      <div
        className={classNames({
          'form-dropdown': true,
          'l-form-frame': hasFrame,
          disabled,
          error,
        })}
      >
        <Label
          weakBrowserMode={weakBrowserMode}
          htmlFor={name}
          className={classNames({
            'form-dropdown__label': true,
            disabled,
            error,
          })}
        >
          {label ? (
            <div
              className={classNames({
                'form-dropdown__label__text': true,
                'has-frame': hasFrame,
                error,
              })}
            >
              {label}
              {mandatory ? '*' : ''}
            </div>
          ) : null}
          <select
            id={name}
            ref={ref => {
              this._ref = ref;
            }}
            className={classNames({
              'form-dropdown__select': true,
              disabled,
              error,
            })}
            disabled={disabled}
            onChange={this.onChange}
            onBlur={this.onBlur}
            defaultValue={value}
          >
            {placeholder ? <option value="">{placeholder}</option> : null}
            {optionGroups.length > 1
              ? optionGroups.map((group, index) => (
                  <optgroup key={index}>
                    {group.map(this.renderOption)}
                  </optgroup>
                ))
              : optionGroups[0].map(this.renderOption)}
          </select>
        </Label>
        <ErrorMessage error={noErrorMessage ? null : errorMessage} />
      </div>
    );
  }
}

export default FormSelect;
