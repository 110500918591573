// @flow

import React, { Component } from 'react';

import InfoList from './InfoList';

import type DailyEvent from '../models/DailyEvent';

type Props = {
  model: DailyEvent,
};

export default class ProgramEventInfoList extends Component<Props> {
  get infos(): { key: string, value: ?string }[] {
    const event = this.props.model;
    let vacancy = event.vacancy || 0;
    // TUICUNIT-2387
    if (event.statusCode === 5 || event.statusCode === 1) {
      vacancy = 'hidden';
    }
    return [
      { key: 'Ort', value: event.venue },
      { key: 'Uhrzeit', value: event.displayTime },
      { key: 'Besonderer Hinweis', value: event.generalInformation },
      vacancy !== 'hidden'
        ? {
            key: 'Verfügbarkeit',
            value: `${vacancy > 20 ? '> 20' : vacancy} ${
              vacancy === 1 ? 'Platz' : 'Plätze'
            }`,
          }
        : {},
    ].filter(info => info.value);
  }

  render() {
    return <InfoList infos={this.infos} />;
  }
}
