// TODO: add flow types

import { observable, action, computed } from 'mobx';
import values from 'lodash/values';
import iso from 'iso-3166-1';

import PaymentInfo from '../models/PaymentInfo';
import PaymentStoreRequest from '../models/PaymentStoreRequest';
import { validateIban } from '../utils/string';
import { PAYMENT_OPTION_MASTER_CARD, PAYMENT_OPTION_MASTER_CARD_MC } from '../config/constants';

export default class PaymentStore {
  @observable paymentInfo = null;
  @observable paymentInfos = {};
  @observable paymentInfosLoaded = false;
  @observable sepaResponse = null;
  @observable ccResponse = null;
  @observable token = null;

  masterStore = null;
  api = null;
  infoRequest = {};
  configRequest = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action.bound
  fetchPaymentInfos(mpis) {
    this.paymentInfosLoaded = false;
    return Promise.all(mpis.map((mpi) => this.fetchPaymentInfo(mpi))).then(
      action(() => {
        this.paymentInfosLoaded = true;
      })
    );
  }

  fetchPaymentInfo(mpi) {
    if (!this.infoRequest[mpi]) {
      this.infoRequest[mpi] = this.api
        .info(this.masterStore.user, mpi)
        .then(
          action((info) => {
            // TUICUNIT-2633: overwrite MC CreditCart Brand
            if (
              info &&
              info.paymentData &&
              info.paymentData.ccBrand &&
              info.paymentData.ccBrand === PAYMENT_OPTION_MASTER_CARD_MC
            ) {
              info.paymentData.ccBrand = PAYMENT_OPTION_MASTER_CARD;
            }
            this.paymentInfo = new PaymentInfo(mpi, info);
            this.paymentInfos[mpi] = this.paymentInfo;
            this.infoRequest[mpi] = null;
            return this.paymentInfo;
          })
        )
        .catch(
          action(() => {
            this.paymentInfo = new PaymentInfo(mpi, null);
            this.paymentInfos[mpi] = this.paymentInfo;
            this.infoRequest[mpi] = null;
            return this.paymentInfo;
          })
        );
    }
    return this.infoRequest[mpi];
  }

  @computed
  get isLoadingPaymentInfo() {
    return this.paymentInfo === null;
  }

  fetchConfiguration() {
    if (!this.configRequest) {
      this.configRequest = this.api.configuration(this.masterStore.user);
    }
    return this.configRequest;
  }

  createStoreRequest(member, contact) {
    const { masterData } = this.masterStore;

    return Promise.all([this.fetchConfiguration(), this.fetchPaymentInfo(member.mpi)]).then(([config, paymentInfo]) => {
      const payerMpi = paymentInfo && member.mpi !== paymentInfo.payerMpi ? paymentInfo.payerMpi : null;

      const countryInfo = contact.country ? iso.whereAlpha2(contact.country) : null;

      // TUICUNIT-2432: billingAddress is variable, we need check all values
      let billingAddressIsVariable = null;
      if (contact.city || contact.streetAddress || contact.zipCode || countryInfo) {
        billingAddressIsVariable = {
          city: contact.city || undefined,
          country: countryInfo
            ? {
                countryA3: countryInfo.alpha3,
              }
            : undefined,
          addressLine1: contact.streetAddress
            ? {
                street: contact.streetAddress,
              }
            : undefined,
          postalCode: contact.zipCode || undefined,
        };
      }

      return new PaymentStoreRequest(member, masterData.travelParty, {
        alreadyPaidBy: payerMpi ? masterData.getPartyMember(payerMpi) : null,
        info: this.paymentInfo,
        debarkDate: masterData.debarkDate,
        config: {
          ...config,
          billToCustomer: {
            consumer: {
              firstName: member.firstName,
              lastName: member.lastName,
              birthDate:
                member.dateOfBirth && typeof member.dateOfBirth === 'string' && member.dateOfBirth.length >= 10
                  ? member.dateOfBirth.substr(0, 10)
                  : undefined,
            },
            email: contact.email || undefined,
          },
          billingAddress: billingAddressIsVariable,
        },
      });
    });
  }

  paymentInfosFor(mpis) {
    return values(
      Object.keys(this.paymentInfos).reduce((result, infoMpi) => {
        const paymentInfo = this.paymentInfos[infoMpi];
        if (mpis.some((mpi) => paymentInfo.containsPaymentFor(mpi))) {
          result[infoMpi] = paymentInfo;
        }
        return result;
      }, {})
    );
  }

  @action
  doRequest(request) {
    const catchUnknownError = () => {
      request.receiveError('Ihre Anfrage konnte nicht ausgeführt werden. Bitte versuchen Sie es später noch einmal.');
    };
    const loggedInUser = this.masterStore.user;

    if (request.digitalInvoiceEnabled) {
      if (request.digitalInvoiceMail && !request.digitalInvoiceMail.match(/^.+@.+\..+$/)) {
        // Validate mail
        request.receiveDigitalInvoiceError('Bitte geben Sie eine gültige E-Mail-Adresse an.');
        return;
      } else if (!request.digitalInvoiceMail && request.digitalInvoiceConsent) {
        request.receiveDigitalInvoiceError(
          'Um Ihre Bordabrechnung per E-Mail zu erhalten, müssen Sie eine E-Mail-Adresse angeben.'
        );
        return;
      }
    }

    request.startRequest();
    if (request.isSepa) {
      const iban = validateIban(request.requestData.iban);
      if (!iban) {
        request.receiveError('Bitte geben Sie eine gültige IBAN an.');
        return;
      }

      const validateBody = {
        iban,
        customer: request.requestData.customer,
        accountOwner: request.requestData.Customer,
      };

      this.api
        .validateSepa(loggedInUser, validateBody)
        .then(
          action((response) => {
            if (response.status === 'Error') {
              const errorMsg = response.description
                .replace(/ae/g, 'ä')
                .replace(/ue/g, 'ü')
                .replace(/oe/g, 'ö')
                .replace(/iban/g, 'IBAN');
              request.receiveError(errorMsg);
            } else {
              request.receiveSEPAValidationResponse(response);
              this.api
                .save(loggedInUser, request.member.mpi, {
                  ...request.requestData,
                  iban,
                })
                .then((saveResponse) => this.receiveSaveResponse(request, saveResponse))
                .catch(action(catchUnknownError));
            }
          })
        )
        .catch(action(catchUnknownError));
    } else if (request.isCreditCard) {
      this.api
        .save(loggedInUser, request.member.mpi, request.requestData)
        .then((response) => this.receiveSaveResponse(request, response))
        .catch(action(catchUnknownError));
    } else {
      this.api
        .save(loggedInUser, request.member.mpi, request.requestData)
        .then((response) => this.receiveSaveResponse(request, response))
        .catch(action(catchUnknownError));
    }
  }

  @action
  receiveSaveResponse(request, response) {
    if (response.status === 'ok') {
      request.setDone();
    } else {
      request.receiveError(response.message);
    }
  }
}
