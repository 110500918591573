// @flow
import React, { useState, useCallback } from 'react';

import type {
  DigitalHealthStore,
  SurveyMetaType,
} from '../../../stores/DigitalHealthStore';

type Props = {
  digitalHealthStore: DigitalHealthStore,
  close?: Function,
};

export default function Overlay(props: Props) {
  const [lang, setLang] = useState('de');
  const { digitalHealthStore, close } = props;

  const surveyMeta: SurveyMetaType = digitalHealthStore.getSurveyMeta;

  const toggle = useCallback(
    e => {
      e.preventDefault();
      setLang(lang === 'de' ? 'en' : 'de');
    },
    [lang]
  );

  return (
    <div className="modal-content health">
      <div className="modal-inner-content">
        <div className="modal-close" onClick={close} />
        <h2>
          <i className="icon healthy" />
          <span>{surveyMeta.title[lang]}</span>
          <span className="lang">
            <span className="lang-inner" onClick={toggle}>
              <span className={lang === 'de' ? 'bold' : ''}>DEU</span> |{' '}
              <span className={lang === 'en' ? 'bold' : ''}>ENG</span>
            </span>
          </span>
        </h2>
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: surveyMeta.text[lang],
          }}
        />
      </div>
    </div>
  );
}
