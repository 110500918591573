// @flow

import React from 'react';
import styled from 'styled-components';
import { colors } from './styles';

type Props = {
  url: string,
  children: any,
};

const Link = styled.a`
  text-decoration: none;
  color: ${colors.darkBlue};

  :hover {
    opacity: 0.8;
  }
`;

export default function LinkExternal({ url, children }: Props) {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
}
