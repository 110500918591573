// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router';
import track from '../../tracking';

import BreadcrumbLink from './BreadcrumbLink';
import { isBlacklistedPath } from '../../tracking/PageView';
import { inject } from 'mobx-react';

@inject('breadcrumbRouterStore')
export default class BreadcrumbRouter extends Component {
  static childContextTypes = {
    breadcrumbs: PropTypes.array,
    setBreadcrumbTitle: PropTypes.func,
  };

  state = {
    breadcrumbs: [],
  };

  paths = [];
  titles = [];
  triggered = false;

  get ready() {
    return this.titles.every(Boolean);
  }

  triggerEvent() {
    if (!this.triggered && this.ready) {
      this.triggered = true;

      // Store gathered titles for usage in other components
      this.props.breadcrumbRouterStore.set(this.titles);

      // Page View Tracking
      // Take all breadcrumb titles and remove the first element for tracking
      const titles = [...this.titles];
      titles.shift(); // Remove first element from titles list

      // Check if the path is excluded from automatic tracking and at least one of path or title is set
      const path = this.paths[this.paths.length - 1] || '';
      if (!isBlacklistedPath(path) && path !== '' && titles.length) {
        // Check for tealium to be available
        if (window.utag) {
          track.pageView(path, titles);
        } else {
          setTimeout(() => {
            track.pageView(path, titles);
          }, 100);
        }

        // @todo:  Remove after implementation!
      } else {
        //console.log('Path is excluded from tracking: ', path);
      }
    }
  }

  setBreadcrumbTitle(path, title, bookingRequest) {
    if (!title || this.ready) return;
    const i = this.paths.indexOf(path);
    this.titles[i] = title;
    this.triggerEvent();
  }

  buildPath(routes, route, params) {
    const paths = routes
      .slice(0, routes.indexOf(route) + 1)
      .map((r) => r.path)
      .filter((p) => p && p !== '/');

    const segments = paths.reduce((prev, cur) => {
      if (cur[0] === '/') return [cur.slice(1)];
      return [...prev, cur];
    }, []);

    const path = segments.join('/').replace(/:(\w+)/g, (match, name) => params[name]);

    return `/${path}`;
  }

  unwrapComponent(component) {
    if (component.WrappedComponent) {
      return this.unwrapComponent(component.WrappedComponent);
    }
    return component;
  }

  update(router) {
    const { routes, params } = router.state;
    this.paths = [];
    this.titles = [];
    this.triggered = false;
    const breadcrumbs = routes
      .map((route, index) => {
        const componentRoute = route.indexRoute || (route.path && route);
        if (!componentRoute) return;

        const component = this.unwrapComponent(componentRoute.component);
        if (!component || !component.breadcrumb) return;

        const path = this.buildPath(routes, route, params);
        const text = typeof component.breadcrumb === 'string' ? component.breadcrumb : null;

        this.paths.push(path);
        this.titles.push(text);
        const BreadcrumbComp = text ? BreadcrumbLink : component.breadcrumb;
        return <BreadcrumbComp params={router.state.params} key={index} path={path} text={text} />;
      })
      .filter(Boolean);
    this.setState({ breadcrumbs });
    this.triggerEvent();
  }

  getChildContext() {
    return {
      breadcrumbs: this.state.breadcrumbs,
      setBreadcrumbTitle: this.setBreadcrumbTitle.bind(this),
    };
  }

  render() {
    const self = this;
    return (
      <Router
        {...this.props}
        onUpdate={function () {
          // this is a function expression, so we can access the router as `this`
          self.update(this);
        }}
      />
    );
  }
}
