// @flow
const account = 'https://meinereisen.test.meinschiff.com';
export default {
  trackingCode: 'UA-TESTING',
  cookieDomain: '.meinschiff.com',
  logoutURL: 'https://meinereise.test.meinschiff.com/login',
  account,
  accountLogin: `${account}/signin`,
  accountRegister: `${account}/signin/registration`,
  excursionMultiSelectCacheKey: 'travelPartyState',
  publicHealthQuestionnaire:
    'https://meinereise.cellular.de/config/fragebogen/current.pdf',
};
