// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import Headline from '../Headline';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import BookingConfirmationSuccess from './Success';
import { FAILED } from '../../models/IncludedShoreEx/constants';

import type IncludedShoreExBookingRequest from '../../models/BookingRequest/IncludedShoreExBookingRequest';
import { formatPrice } from '../../utils/price';
import InfoBox from '../InfoBox';
import { removeItem } from '../../utils/sessionStorage';
import config from '../../config';

type Props = {
  bookingRequest: IncludedShoreExBookingRequest,
  onConfirm: () => any,
  onClose: () => any,
  showChildInfo: boolean,
};

const cacheKey = config.excursionMultiSelectCacheKey;

@observer
export default class BookingOptionsConfirmation extends Component<Props> {
  get successButtonText(): string {
    const { bookingRequest } = this.props;
    return bookingRequest.itemsToBook.length <= 0 ? 'Bestätigen' : 'Jetzt reservieren';
  }

  mpiHasError(mpi: number): boolean {
    const { bookingRequest } = this.props;
    const { hasErrors, responseState } = bookingRequest;

    return hasErrors ? !!responseState.find((r) => r.mpi === mpi && r.result === FAILED) : false;
  }

  renderWithTable() {
    const { bookingRequest, showChildInfo } = this.props;
    const { hasErrors, errors, waived, itemsToBook } = bookingRequest;

    return (
      <div>
        {hasErrors ? (
          <p className="booking-confirmation__error" dangerouslySetInnerHTML={{ __html: errors }} />
        ) : (
          <p>{bookingRequest.getText('confirmation', 'text')}</p>
        )}
        <div className="l-block">
          <div>
            <div className="booking-table booking-table__confirmation">
              <div className="booking-table__header">
                <div className="booking-table__row">
                  <div className="booking-table__cell border-bottom">{bookingRequest.getText('listingType', '')}</div>
                  <div className="booking-table__cell empty border-bottom" />
                </div>
              </div>

              {itemsToBook &&
                itemsToBook.map((item, i) => (
                  <div key={i} className="booking-table__body no-padding">
                    <div className="booking-table__row">
                      <div
                        className={classNames({
                          'booking-table__cell': true,
                          'booking-table__name': true,
                          'has-error': this.mpiHasError(item.mpi),
                        })}
                      >
                        {item.name}
                      </div>
                      <div className="booking-table__cell booking-table__price">Inklusivleistung</div>
                    </div>
                    <div className="booking-table__row">
                      <div
                        className={classNames({
                          'booking-table__cell': true,
                          'booking-table__info': true,
                          'border-bottom': i === itemsToBook.length - 1,
                        })}
                      >
                        <span>
                          {item.date}, {item.time} Uhr
                        </span>
                      </div>
                      <div
                        className={classNames({
                          'booking-table__cell': true,
                          empty: true,
                          'border-bottom': i === itemsToBook.length - 1,
                        })}
                      />
                    </div>
                  </div>
                ))}

              {waived.length > 0 && (
                <div className="booking-table__body no-padding border-bottom">
                  <div className="booking-table__row waived-table">
                    <div className="booking-table__cell booking-table__name border-bottom">
                      {waived.map((item, i) => (
                        <div
                          key={i}
                          className={classNames({
                            waived: true,
                            'has-error': this.mpiHasError(item.mpi),
                          })}
                        >
                          {item.name}
                        </div>
                      ))}
                    </div>
                    <div className="booking-table__cell booking-table__hint border-bottom">
                      Die hier genannten Reisenden nehmen nicht am Landausflug teil. Ich nehme zur Kenntnis, dass ich
                      infolge der Absage dieses inkludierten Ausflugs keine Reisepreiserstattung und kein Guthaben
                      erhalte. Auch ein Tausch mit anderen durch TUI Cruises veranstalteten oder vermittelten
                      Landausflügen ist nicht möglich. Sie haben die Möglichkeit die Absage in Ihrem Meine Reise Bereich
                      zu widerrufen, indem Sie Ihre Reservierung anpassen.
                    </div>
                  </div>
                </div>
              )}

              <div className="booking-table__footer">
                <div className="booking-table__row">
                  <div className="booking-table__cell booking-table__name">Gesamtpreis</div>
                  {bookingRequest.total !== null && (
                    <div className="booking-table__cell booking-table__price">{formatPrice(bookingRequest.total)}</div>
                  )}
                </div>
              </div>
            </div>
            {showChildInfo && (
              <InfoBox noIcon className="infobox__smaller">
                <strong>Hinweis:</strong> Bitte beachten Sie, dass Kinder nur in Begleitung einer vorher benannten
                erwachsenen Aufsichtsperson oder den Erziehungsberechtigten an Landausflügen teilnehmen dürfen.
                Jugendliche ab 16 Jahren können mit Genehmigung der Erziehungsberechtigten auch unbegleitet an den
                meisten Landausflügen teilnehmen. Die Genehmigung für Kinder und Jugendliche unter 18 Jahren muss zum
                Landgang ausgefüllt an der Rezeption vorliegen.
              </InfoBox>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { bookingRequest, onClose, onConfirm } = this.props;
    const { bookable } = bookingRequest;

    if (bookingRequest.requestSuccessful) {
      return <BookingConfirmationSuccess bookingRequest={bookingRequest} />;
    }

    return (
      <div className="booking-confirmation">
        <Headline title={bookingRequest.getText('confirmation', 'title')} subtitle={bookable.name} />

        {this.renderWithTable()}

        {bookingRequest.isRequesting ? (
          <LoadingIndicator />
        ) : (
          <div className="booking-confirmation__buttons">
            <p>
              <Button
                dark
                big
                onClick={() => {
                  bookingRequest.cleanErrors();
                  removeItem(cacheKey);
                  onConfirm && onConfirm();
                }}
              >
                {this.successButtonText}
              </Button>
            </p>
            <p>
              <Button
                onClick={() => {
                  bookingRequest.cleanErrors();
                  onClose && onClose();
                }}
              >
                Zurück
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
