export const isLocal =
  window.location.hostname.indexOf('local.dev.cellular.de') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3000') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3005') >= 0 ||
  window.location.href.indexOf('http://local.dev.cellular.de:3050') >= 0;

export const isDev = window.location.hostname.indexOf('tuic-mrl.dev.cellular.de') >= 0;

export const isTest = window.location.hostname.indexOf('meinereise.test.meinschiff.com') >= 0;

export const isProduction = window.location.hostname.indexOf('meinereise.meinschiff.com') >= 0;
