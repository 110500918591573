// @flow
import * as React from 'react';

import ContentBox from '../../../components/ContentBox';
import LinkAsButton from '../../../components/LinkAsButton';
// import BenefitList from '../../../components/BenefitList';
import FAQTeaser from '../../../components/CheckIn/FAQTeaser';
import CheckIcon from '../../../../assets/svg/inline/ic-check-rund.inline.svg';
import type { TileType } from '../../../api/tiles';

type Props = {
  allCheckedIn: boolean,
  allCheckinAble: boolean,
  checkInBlocked: boolean,
  faqTile: TileType,
};

const PageHeader = ({
  allCheckedIn,
  allCheckinAble,
  checkInBlocked,
  faqTile,
}: Props) => {
  // const allCheckedIn = travelParty.every((partyMember: PartyMember) => !partyMember.checkinAvailable || partyMember.checkinCompleted);
  // const allCheckInAble = travelParty.every((partyMember: PartyMember) => !partyMember.checkinAvailable || partyMember.checkinEnabled);
  return (
    <div className="l-row">
      {checkInBlocked && !allCheckedIn ? (
        <div className="l-col double">
          <ContentBox title="Online Check-in nicht mehr verfügbar">
            Bitte checken Sie am Anreisetag direkt am Terminal ein.
          </ContentBox>
        </div>
      ) : (
        <div className="l-col double s-mod">
          {!allCheckedIn && (
            <p>
              Damit Ihr Wohlfühlurlaub noch entspannter beginnt, checken Sie
              hier bereits vor der Reise ein. Die Voraussetzung für den Check-in
              ist das vollständig ausgefüllte Schiffsmanifest und ein
              hinterlegtes Zahlungsmittel. Der Online Check-in ist bis 0 Uhr am
              Tag Ihres Kreuzfahrtbeginns verfügbar.
            </p>
          )}
          {allCheckedIn && (
            <ContentBox
              title="Online Check-in bereits erfolgt"
              titleIcon={<CheckIcon />}
            >
              <p>
                Wir haben Ihre Daten erhalten und freuen uns, Sie bald an Bord
                begrüßen zu dürfen.
              </p>
            </ContentBox>
          )}
          {allCheckinAble &&
            !allCheckedIn && (
              <ContentBox
                title="Jetzt online einchecken"
                titleIcon={<CheckIcon />}
                className="header-checkin"
              >
                <p>
                  Checken Sie bereits jetzt ein und verkürzen Sie Ihre Wartezeit
                  am Anreisetag.
                </p>
                <LinkAsButton link="/checkin/checkin">
                  Jetzt einchecken
                </LinkAsButton>
              </ContentBox>
            )}
        </div>
      )}
      <div className="l-col right l-mod-sub">
        {faqTile.visible ? <FAQTeaser enabled={faqTile.enabled} /> : null}
      </div>
    </div>
  );
};

export default PageHeader;
