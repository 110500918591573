// @flow
// TUICUNIT-1994
import React, { Component } from 'react';
import { autobind } from 'core-decorators';

import storage from '../../utils/storage';
import LinkAsButton from '../../components/LinkAsButton';
import Button from '../../components/Button';
import PartyMember from '../../models/PartyMember';

import ClockIcon from '../../../assets/svg/inline/clock.inline.svg';
import ReminderIcon from '../../../assets/svg/inline/reminder.inline.svg';

import type { TilesStoreType } from '../../stores/TilesStore';
import type MasterData from '../../models/MasterData';

/**
 * check first view: via local storage;
 * checkin tile available: via tiles ApiResponse;
 * all travelParty member not checked in: via PartyMember;
 * masterData.checkin.enabled via MasterData
 *
 * @param {TilesStoreType} tiles
 * @param {PartyMember} travelParty
 * @param {MasterData} masterData
 *
 * @return {object} { css: (' force-reminder' || ''), state: (true || false) }
 */
const forceCheckInNeeded = (
  tiles: ?TilesStoreType,
  travelParty: PartyMember[],
  masterData: MasterData
): { css: string, state: boolean } => {
  // is it the first view? check: local storage
  const flagFirstView = storage.get('force-checkin') || false;

  // is checkin tile available? check: tilesStore
  const flagCheckinTile =
    (tiles &&
      tiles.onlineCheckIn &&
      tiles.onlineCheckIn.enabled === true &&
      tiles.onlineCheckIn.visible === true) ||
    false;

  // and all member are checked in? check: PartyMember
  const flagAllCheckedIn = travelParty.every(
    partyMember => partyMember.checkinCompleted
  );

  // MasterData checkin is enabled
  const flagMasterData =
    (masterData.checkin &&
      masterData.checkin.enabled &&
      masterData.checkin.enabled === true) ||
    false;

  // view forceCheckin: if (tilesCheckin === true && masterData.checkin === true && fistView === true && allCheckedIn === false)
  const forceCheckin =
    flagCheckinTile && flagMasterData && flagFirstView && !flagAllCheckedIn
      ? { css: ' force-reminder', state: true }
      : { css: '', state: false };
  return forceCheckin;
};

/**
 * set local storage (force-checkin: false), needed to find out if this the first view (flag)
 * @return {boolean} true
 */
const setViewed = (): boolean => {
  storage.set('force-checkin', false);
  return true;
};

const getViewed = (): boolean => {
  return storage.get('force-checkin');
};

type Props = {
  tiles: ?TilesStoreType,
  callback: () => void,
  travelParty: PartyMember[],
  masterData: MasterData,
};

type State = {
  remind: boolean,
};

class ForceCheckInInfo extends Component<Props, State> {
  timerID = null;
  state = {
    remind: false,
  };

  componentWillUnmount() {
    if (this.timerID !== null) {
      clearTimeout(this.timerID);
    }
  }

  @autobind
  handleCloseClick() {
    this.setState({
      remind: true,
    });

    const { callback } = this.props;
    this.timerID = setTimeout(() => {
      setViewed();
      callback();
    }, 2000);
  }

  render() {
    const { tiles, travelParty, masterData } = this.props;
    if (forceCheckInNeeded(tiles, travelParty, masterData).state === false) {
      return null;
    }

    const specialMember =
      masterData.requestorHasVip ||
      masterData.isSuite ||
      masterData.requestorHasTuicTravelToShip;

    return (
      <div className={`force-popup${this.state.remind ? ' reminder' : ''}`}>
        <div className="arrow-left" />
        {this.state.remind ? (
          <div className="head">
            <ReminderIcon className="reminder" />
            <h2>
              Gerne erinnern wir Sie zu einem späteren Zeitpunkt erneut an den
              verpflichtenden Online Check-in.
            </h2>
          </div>
        ) : (
          <div>
            <div className="head">
              <ClockIcon className="clock" />
              <h2>Bald geht es los, es wird Zeit einzuchecken</h2>
            </div>
            {specialMember ? (
              <span>
                Der Online Check-in ist für alle Reiseteilnehmer verpflichtend.
                Wir empfehlen Ihnen, den Online Check-in möglichst frühzeitig
                vorzunehmen, damit Sie reibungslos an Bord gehen können. Der
                Online Check-in ist bis spätestens 0 Uhr am Tag Ihres
                Kreuzfahrtbeginns verfügbar.
              </span>
            ) : (
              <span>
                Der Online Check-in ist für alle Reiseteilnehmer verpflichtend.
                Bitte schließen Sie Ihren Online Check-in bis spätestens 0 Uhr
                am Tag Ihres Kreuzfahrtbeginns ab, damit Sie Ihr gewünschtes
                Check-in Zeitfenster auswählen und somit reibungslos an Bord
                gehen können.
              </span>
            )}
            <div className="cta">
              <Button onClick={this.handleCloseClick}>
                Erledige ich später
              </Button>
              <LinkAsButton link="/checkin" dark onClick={setViewed}>
                Jetzt einchecken
              </LinkAsButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export { forceCheckInNeeded, setViewed, getViewed, ForceCheckInInfo };
