// @flow
import React, { Component } from 'react';

import Headline from '../../../components/Headline';
import LinkAsButton from '../../../components/LinkAsButton';
import { inject, observer } from 'mobx-react';
import track from '../../../tracking';

type Props = {
  isCheckinSubRoute: boolean,
  memberIndex: Number,
};

@inject('breadcrumbRouterStore')
@observer
export default class ManifestPaymentSuccess extends Component<Props> {
  componentDidMount() {
    // Page View Tracking
    let titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles) {
      titles.pop();
      track.pageView(window.location.pathname, [
        ...titles,
        'Zahlungsmittel',
        'person-' + this.props.memberIndex,
        'erfolgreich',
      ]);
    }
  }

  render() {
    const { isCheckinSubRoute } = this.props;
    return (
      <div className="page page-manifest-success">
        <div className="l-row">
          <div className="l-col double">
            <Headline title="Vielen Dank für die Hinterlegung Ihrer Zahlungsinformationen." />

            <p>Wir haben Ihre Daten gespeichert.</p>
            <p>
              Bitte beachten Sie, dass die Verarbeitung der Daten einige Minuten
              dauern kann und in dieser Zeit kein Zugriff auf die Daten möglich
              ist. Nach Beendigung der Verarbeitung können Sie Ihre Daten bei
              Bedarf bis zur Nutzung des Online Check-ins ändern.
            </p>
            <div className="l-right">
              {isCheckinSubRoute ? (
                <p>
                  <LinkAsButton
                    className="button dark big"
                    dark
                    link="/checkin"
                  >
                    Zur Check-in Übersicht
                  </LinkAsButton>
                </p>
              ) : (
                <p>
                  <LinkAsButton
                    className="button dark big"
                    dark
                    link="/payment"
                  >
                    Zur Zahlungsmittel-Übersicht
                  </LinkAsButton>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
