// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import find from 'lodash/find';

import Headline from '../../Headline';
import CatalogTile from '../../tiles/CatalogTile';
import LoadingIndicator from '../../LoadingIndicator';

import type MasterStore from '../../../stores/MasterStore';
import type IncludedShoreExStore from '../../../stores/IncludedShoreExStore';
import { getSlideDirectionString } from '../../../utils/sliderHelper';
import type { TrackingExcursion } from '../../../types/tracking';
import type Package from '../../../models/Package';
import { tealiumDateFromDateObject, tealiumValue } from '../../../utils/tracking';
import tracking from '../../../tracking';

type Props = {
  masterStore: MasterStore,
  includedShoreExStore: IncludedShoreExStore,
};

@inject('masterStore', 'includedShoreExStore', 'breadcrumbRouterStore')
@observer
export default class PageIncludedShoreEx extends Component<Props> {
  static breadcrumb = 'Inklusiv-Landausflüge';

  get store(): IncludedShoreExStore {
    return this.props.includedShoreExStore;
  }

  componentDidMount() {
    this.store && this.store.fetchListing();
    this.store && this.store.fetchListingStatus();
  }

  getBadge(masterExcursionId: number): string {
    const { listingStatus } = this.store;
    if (!listingStatus) return '';
    const statusItem = find(listingStatus.list, { masterExcursionId });
    return statusItem ? statusItem.statusText : '';
  }

  triggered = false;

  render() {
    if (!this.store || this.store.loading) {
      return <LoadingIndicator />;
    }

    const { listing } = this.store;
    if (!listing) return null;

    if (this.triggered === false && this.store.items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.name,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="page page-excursions">
        <Headline title={listing.headline} subtitle="" icon="excursion" />
        <p>{listing.description}</p>
        <div className="l-row group">
          <div className="l-col large">
            {listing &&
              listing.list &&
              Array.isArray(listing.list) &&
              listing.list.map((pkg, index) => (
                <div
                  key={pkg.masterExcursionId}
                  onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
                >
                  <CatalogTile
                    link={`/inklusiv-landausfluege/${pkg.masterExcursionId}`}
                    images={pkg.images}
                    portName={pkg.port && pkg.port.name}
                    title={pkg.name}
                    description={pkg.description}
                    nappStyle={false}
                    badge={this.getBadge(pkg.masterExcursionId)}
                    large={true}
                    imageBadge={pkg.displayOperator ? <div className={pkg.displayOperator.operatorClassName} /> : null}
                    onSlideShowChange={(oldIndex, newIndex) => {
                      tracking.event(
                        'Inklusiv-Landausflug',
                        `Listingseite_Slider_${getSlideDirectionString(oldIndex, newIndex)}`,
                        pkg.name
                      );
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
