import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { autobind } from 'core-decorators';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ReactModal from 'react-modal';
import union from 'lodash/union';
import without from 'lodash/without';

import CheckboxGroup from '../CheckboxGroup';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import LockBodyScroll from '../LockBodyScroll';

@observer
export default class ModalSelect extends Component {
  static propTypes = {
    triggerLabel: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    value: PropTypes.array,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  @observable showModal = false;
  @observable value = [];
  prevValue = [];

  @action
  componentDidMount() {
    const { name, value } = this.props;
    this.value = value || [];
    this.prevValue = value || [];

    this.context.registerFormElement &&
      this.context.registerFormElement(name, this);
  }

  @autobind
  @action
  open(e) {
    if (e) e.preventDefault();
    this.showModal = true;
  }

  @autobind
  @action
  close() {
    this.showModal = false;
    this.value = this.prevValue;
  }

  @autobind
  @action
  closeAndApply() {
    if (this.props.onChange) {
      this.props.onChange(this.value);
    }
    this.showModal = false;
    this.prevValue = this.value;
  }

  @autobind
  @action
  handleChange(key) {
    const checked = this.value.includes(key);

    this.value = checked ? without(this.value, key) : union(this.value, [key]);

    if (this.props.onChange) {
      this.props.onChange(this.value);
    }
  }

  get hasChanged() {
    const initialValue = this.props.value || [];
    const currentValue = this.value.slice();

    return !isEqual(sortBy(currentValue), sortBy(initialValue));
  }

  render() {
    const { options, triggerLabel, label, disabled } = this.props;

    const buttonLabel =
      !this.value || (this.value && this.value.length === 0)
        ? triggerLabel
        : options
            .filter(o => this.value.includes(o.value))
            .map(o => o.label)
            .join(', ');

    return (
      <div className="modal-wrapper">
        {label ? <div className="modal-trigger-label">{label}</div> : null}
        <button
          className="modal-trigger"
          onClick={this.open}
          disabled={!options.length || disabled}
        >
          {buttonLabel}
        </button>
        <ReactModal
          appElement={document.getElementById('app')}
          isOpen={this.showModal}
          onRequestClose={this.close}
          className="modal"
          contentLabel={triggerLabel}
          overlayClassName="modal-overlay"
        >
          <div className="modal-content">
            <div className="modal-close" onClick={this.close} />
            <h1>Ihre Auswahl</h1>
            <p className="modal-info">
              <span className="icon info" />Mehrfachauswahl möglich
            </p>
            <div className="modal-inner-content">
              <CheckboxGroup>
                {// eslint-disable-next-line no-shadow
                options.map(({ value, label }) => (
                  <CheckboxGroup.Item
                    key={value}
                    value={value}
                    checked={this.value && this.value.includes(value)}
                    onChange={() => this.handleChange(value)}
                  >
                    {label}
                  </CheckboxGroup.Item>
                ))}
              </CheckboxGroup>
            </div>
            <fieldset className="modal-buttons">
              <button className="dark block" onClick={this.closeAndApply}>
                Auswahl übernehmen
              </button>
              <button className="link" onClick={this.close}>
                Abbrechen
              </button>
            </fieldset>
          </div>
        </ReactModal>
        {this.showModal && <LockBodyScroll />}
      </div>
    );
  }
}
