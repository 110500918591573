// @flow
import Cookie from 'js-cookie';

import { isLocal, mockAll } from './utils';
import config from '../config';
import pinStorage from '../utils/pinStorage';

const mock = false;
const getLoginUrl = (): string => ((mock || mockAll) && isLocal ? `/mock/login.json` : `/api/land/login`);
const getTokenUrl = (tripCode: number | string): string =>
  (mock || mockAll) && isLocal ? `/mock/TBD.json` : `/api/account/v1/auth/token/${tripCode}`;
const getTokenLegacyUrl = (): string =>
  (mock || mockAll) && isLocal ? `/mock/TBD.json` : `/api/account/v1/auth/token`;
const getIdentityUrl = (): string =>
  (mock || mockAll) && isLocal ? `/mock/identity/identity-horst.json` : `/api/account/v1/auth/identity`;

const api = {
  login: (userData: UserData): Promise<ApiResponse> =>
    fetch(getLoginUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(response => response.json()),

  token: (tripData: TripData): Promise<ApiResponse> =>
    fetch(getTokenUrl(tripData.tripCode), {}).then(response => response.json()),

  tokenLegacy: (userData: UserData): Promise<ApiResponse> =>
    fetch(getTokenLegacyUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    }).then(response => response.json()),

  fetchIdentity: (): Promise<IdentityType | JsonInternalServerError> =>
    fetch(getIdentityUrl(), {}).then(response => response.json()),

  signOut: (): Promise<Empty> => {
    Cookie.remove(config.sessionCookieName, { domain: config.cookieDomain });
    Cookie.remove(config.cookieName, { domain: config.cookieDomain });
    pinStorage.clear();

    return fetch(`/api/account/v1/auth/signout`, {
      method: 'DELETE',
    });
  },
};

export default api;

export const sanitizers = {
  get: <T>(x: T) => x,
};

export type UserData = {
  firstname: string,
  lastname: string,
  processNumber: number,
  stayLoggedIn: ?boolean,
};

export type TripData = {
  tripCode: string,
};

export type IdentityType = {
  email: string,
  firstName?: string,
  gender?: string,
  lastName?: string,
};

export type JsonInternalServerError = {
  error: string,
};

type Empty = {};

export type ApiResponse = {
  token: ?string,
  detail: ?string,
  lockoutStillSeconds: ?number,
  lockoutType: ?string,
  lockoutUntilTimestamp: ?number,
  method: string,
  status: number,
  title: ?string,
  type: string,
  uri: string,
};

export type ApiType = {
  login: UserData => Promise<ApiResponse>,
  token: TripData => Promise<ApiResponse>,
  tokenLegacy: UserData => Promise<ApiResponse>,
  fetchIdentity: () => Promise<IdentityType | JsonInternalServerError>,
  signOut: () => Promise<Empty>,
};
