// @flow
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mock = false;
const getListUrl = (masterData: MasterData): string => {
  const { shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/beauty-and-health-teaser.json`
    : `/api/land/content/global/beauty-and-health-teaser/${shipId}`;
};

export default {
  getList: (masterData: MasterData) =>
    fetch(getListUrl(masterData))
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return { error: true };
      })
      .catch(() => {
        return { error: true };
      }),
};

export type ResponseType = {
  categoryNames: Array | null,
  description: string | null,
  documentType: string,
  imageUri: string,
  listingFeedUri: string,
  mxpId: ?string,
  name: string,
  type: string,
};
export type ApiResponse = [ResponseType];

export type ApiErrorResponse = {
  error: Boolean,
};

export type ApiType = {
  getList: MasterData => Promise<ApiResponse>,
};
