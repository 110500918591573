// @flow
import config from '../config';
import { isOptOut } from '../utils/optOut';
import storage from '../utils/storage';
import type MasterStore from '../stores/MasterStore';
import type BookingRequestBase from '../models/BookingRequest/BookingRequestBase';
import { trackPageView } from './PageView';
import { trackAddToCart, trackBookingRequest, trackDetailedItem, trackPurchase, trackViewListItem } from './ECommerce';
import type { ECommerceTrackingData, TrackingData, TrackingListItem } from '../types/tracking';
import { ClickTrackingData, TrackingExcursion } from '../types/tracking';
import { tealiumDateFromString, tealiumIdFromString, tealiumString } from '../utils/tracking';
import type BreadcrumbRouterStore from '../stores/BreadcrumbRouterStore';
import inAppConsent from '../utils/inAppConsent';

const cookieName = 'CONSENTMGR';

/**
 * This is the central tracking interface.
 * Use the exposed methods to track views and events.
 */
class Tracker {
  userId: ?number;
  browserLang: 'de';
  lastPageView: TrackingData | null = null;
  currentExcursion: TrackingExcursion | null = null;
  masterStore: MasterStore;

  constructor(trackingCode: string) {
    if (trackingCode && document && document.getElementsByTagName && !isOptOut()) {
      if (inAppConsent === 'yes') {
        document.cookie = `${cookieName}=consent:true`;
      } else if (inAppConsent === 'no') {
        document.cookie = `${cookieName}=consent:`;
      }

      if (!window.utag) {
        this._loadTealium(trackingCode);
      }
      this.browserLang = this._getBrowserShortLang();
    }
  }

  /**
   * Load the Tealium tracking JS
   *
   * Enable TiQ Debug-Modus:
   *    document.cookie="utagdb=true";
   *    document.cookie="tealium_site_section=meinereise";
   *
   * @private
   */
  _loadTealium(tealiumEnv: string) {
    /* eslint-disable */
    if (!isOptOut()) {
      window.utag_cfg_ovrd = {
        noview: true, cmcookiens: cookieName,
      };
      (function(a, b, c, d) {
        a = "//tags.tiqcdn.com/utag/tui-cruises/meinereise/" + tealiumEnv + "/utag.js";
        b = document;
        c = "script";
        d = b.createElement(c);
        d.src = a;
        d.type = "text/java" + c;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    }
    /* eslint-enable */
  }

  setDefaultParameter(masterStore: MasterStore) {
    let firstLoginTracking = false;
    if (!storage.get('tracking')) {
      firstLoginTracking = true;
    }
    this.masterStore = masterStore;
    storage.set('tracking', {
      tripCode: masterStore.masterData.tripCode || '',
      ddFull: masterStore.masterData.ddFull || '',
      period: masterStore.masterData.period || '',
      edFull: masterStore.masterData.edFull || '',
      tripName: masterStore.masterData.tripName || '',
      ship: masterStore.masterData.ship || '',
      userId: `${masterStore.user.id}` || '',
    });

    this.userId = `${masterStore.user.id}`;
    if (firstLoginTracking === true) {
      this.pageView('/', ['Meine Reise']);
    }
  }

  /**
   * Try to get the browser language or set "de" as fallback
   * @private
   */
  _getBrowserShortLang() {
    let lang = window.navigator.languages ? window.navigator.languages[0] : null;
    lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

    let shortLang = lang || 'de';
    if (shortLang.indexOf('-') !== -1) {
      shortLang = shortLang.split('-')[0];
    }
    if (shortLang.indexOf('_') !== -1) {
      shortLang = shortLang.split('_')[0];
    }
    return shortLang;
  }

  /*
   * ==============================
   * Page View Tracking
   * ==============================
   */
  pageView(path: string, titles: Array, category: ?string = null) {
    const pageView = trackPageView(path, titles, this.browserLang, category);
    this.lastPageView = pageView ?? null;
    return pageView;
  }

  /*
   * ==============================
   * Event Tracking legacy
   * ==============================
   */
  event(...args: any[]) {
    if (window.utag && args) {
      window.utag.link({
        event_category: args[0],
        event_action: args[1],
        event_label: args[2],
        event_name: 'MRL',
      });
    }
  }

  /*
   * ==============================
   * Error Tracking legacy
   * ==============================
   */
  /**
   * Track redirects to 404-Page
   */
  pageNotFound(referrer: string) {
    this.event('Fehler', 'Fehlerseite', referrer);
  }

  /**
   * Track MasterData-Feed fetch errors
   */
  masterDataFailed(href: string) {
    this.event('Fehler', 'Masterdata Feed', href);
  }

  /*
   * ==============================
   * E-Commerce Tracking
   * ==============================
   */

  /**
   * View list item tracking
   */

  getTariffName() {
    const loggedInfo = this.masterStore.getLoggedInUserInfo();
    if (loggedInfo) {
      if (loggedInfo.hasProTariff) {
        if (loggedInfo.hasVipOption1) {
          return 'pro-tarif-vip-tarifoption-1';
        }
        if (loggedInfo.hasVipOption2) {
          return 'pro-tarif-vip-tarifoption-2';
        }
        return 'pro-tarif';
      }
      if (loggedInfo.hasPlusTariff) {
        return 'plus-tarif';
      }
      if (loggedInfo.hasPurTariff) {
        return 'pur-tarif';
      }
    }
    return '';
  }

  // tracking call for the 'view_item_list' event
  viewListItem(breadcrumbRouterStore: BreadcrumbRouterStore, excursions: TrackingExcursion[]) {
    let top = '';
    let sub = '';

    if (breadcrumbRouterStore.titles.length && breadcrumbRouterStore.titles.length > 2) {
      top = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 2];
      sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
    } else if (breadcrumbRouterStore.titles.length === 2) {
      sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
    }

    trackViewListItem(this.lastPageView, {
      excursions,
      ship: {
        name: this.masterStore.masterData.ship,
      },
      categories: {
        top,
        sub,
      },
      cruise: {
        code: this.masterStore.masterData.tripCode,
        name: this.masterStore.masterData.tripName,
        region: this.masterStore.masterData.region,
        tariff: this.getTariffName(),
        travelPeriod: this.getTravelPeriod(),
      },
    });
  }

  // sets the last viewed item to the store for the next view call
  setCurrentListItem(
    position: number,
    breadcrumbRouterStore: BreadcrumbRouterStore,
    excursion: {
      categoryId: ?string,
      category: ?string,
    }
  ) {
    let item: TrackingListItem;

    if (excursion.category) {
      let sub = '';

      if (breadcrumbRouterStore.titles.length && breadcrumbRouterStore.titles.length >= 1) {
        sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
      }
      item = {
        page: '1',
        position: String(position),
        code: excursion.categoryId ?? tealiumIdFromString(sub),
        name: excursion.category,
      };
    } else {
      let top = '';
      let sub = '';

      if (breadcrumbRouterStore.titles.length && breadcrumbRouterStore.titles.length > 2) {
        top = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 2];
        sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
      } else if (breadcrumbRouterStore.titles.length === 2) {
        sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
      }

      const listCat = top ? `${top}.${sub}` : sub;

      const catId = tealiumIdFromString(sub);
      item = {
        page: '1',
        position: String(position),
        code: excursion.categoryId ?? catId,
        name: listCat,
      };
    }

    this.currentExcursion = item;

    storage.set('currentViewedExcursion', this.currentExcursion);
  }

  getCurrentListItem() {
    if (this.currentExcursion) {
      return this.currentExcursion;
    }

    this.currentExcursion = storage.get('currentViewedExcursion');

    return this.currentExcursion;
  }

  getTravelPeriod() {
    return `${tealiumDateFromString(this.masterStore.masterData.edFull)}-${tealiumDateFromString(
      this.masterStore.masterData.ddFull
    )}`;
  }

  currentViewedItem: ECommerceTrackingData | null = null;

  viewItem(breadcrumbRouterStore: BreadcrumbRouterStore, excursion: TrackingExcursion) {
    let top = '';
    let sub = '';
    if (breadcrumbRouterStore.titles.length && breadcrumbRouterStore.titles.length > 2) {
      top = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 2];
      sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
    } else if (breadcrumbRouterStore.titles.length === 2) {
      sub = breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 1];
    }

    let listItem = this.getCurrentListItem();

    if (!listItem || (top && !listItem.name.endsWith(top))) {
      const listCat =
        top && breadcrumbRouterStore.titles.length > 3
          ? `${breadcrumbRouterStore.titles[breadcrumbRouterStore.titles.length - 3]}.${top}`
          : top;
      const catId = tealiumIdFromString(sub);
      listItem = {
        page: '1',
        position: '1',
        code: excursion.categoryId ?? catId,
        name: listCat,
      };
      console.log('no list setting new', listItem, excursion);
      this.currentExcursion = listItem;
    }

    this.currentViewedItem = trackDetailedItem(this.lastPageView, listItem, {
      excursion,
      ship: {
        name: this.masterStore.masterData.ship,
      },
      categories: {
        top,
        sub,
      },
      cruise: {
        code: this.masterStore.masterData.tripCode,
        name: this.masterStore.masterData.tripName,
        region: this.masterStore.masterData.region,
        tariff: this.getTariffName(),
        travelPeriod: this.getTravelPeriod(),
      },
    });
  }

  currentAddedItem: ECommerceTrackingData | null = null;

  addToCart(qty: number, price: number) {
    this.currentAddedItem = trackAddToCart(this.currentViewedItem, qty, price);
  }

  purchase() {
    trackPurchase(this.currentAddedItem);
  }

  /**
   * Trigger either the tracking of a cancellation or a purchase, depending on the `modify` flag.
   */
  bookingRequest(bookingRequest: BookingRequestBase) {
    trackBookingRequest(bookingRequest, this.userId);
  }

  click(click_label: string) {
    if (this.lastPageView) {
      const trackingData: ClickTrackingData = {
        ...this.lastPageView,
        event_name: 'click',
        click_label: tealiumString(click_label),
      };

      if (window.utag) {
        window.utag.link(trackingData);
      }
    }
  }
}

const index = new Tracker(config.trackingCode);
export default index;
