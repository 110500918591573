// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import sortBy from 'lodash/sortBy';

import LoadingIndicator from './LoadingIndicator';
import Tile from './tiles/Tile';
import InfoBox from './InfoBox';

import { itsUrlWithSize } from '../utils/image';

import type SpaCategory from '../models/SpaCategory';
import type TilesStore from '../stores/TilesStore';
import type { TileType } from '../api/tiles';

type Props = {
  categories: ?(SpaCategory[]),
  tilesStore: TilesStore,
  linkBase: string,
};

@inject('tilesStore')
@observer
export default class SpaCategoryTiles extends Component<Props> {
  componentDidMount() {
    this.props.tilesStore.fetchAll();
  }

  render() {
    const { categories, linkBase, tilesStore } = this.props;
    if (tilesStore.isLoading) {
      return <LoadingIndicator />;
    }

    if (!categories) {
      return <LoadingIndicator />;
    }

    if (!categories.length) {
      return <InfoBox noIcon>Aktuell sind keine Angebote verfügbar.</InfoBox>;
    }
    return (
      <div className="l-tiles">
        {sortBy(categories, ['position']).map((category) => {
          const definition: TileType = tilesStore.getParamsByName(category.name);

          if (definition.visible === false) {
            return null;
          }
          return (
            <Tile
              full
              key={category.name}
              badge={definition.notification}
              disabled={!definition.enabled}
              link={`${linkBase}/${category.mxpId}`}
              imageSrc={itsUrlWithSize(category.imageSrc, 544, 324)}
              smallImageSrc={itsUrlWithSize(category.imageSrc, 136, 136)}
              title={category.name}
            />
          );
        })}
      </div>
    );
  }
}
