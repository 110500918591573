// @flow
import { PACKAGE_SUB_TYPE_PARKING } from '../config/constants';
import Package from './Package';

export default class ParkingPackage extends Package {
  subline: string;
  externalUrl: string;

  get packageType(): string {
    return PACKAGE_SUB_TYPE_PARKING;
  }

  get bookingName(): string {
    return 'Parkservice';
  }

  get subLine(): string {
    return this.subline;
  }

  get description(): string {
    return this.text;
  }

  get urlExtern(): string {
    return this.externalUrl;
  }
}
