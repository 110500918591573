// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { inject, observer } from 'mobx-react';

import BookingRequest from '../../models/BookingRequest/PackageBookingRequest';

import preload from './decorators/preload';
import DetailPageLayout from '../DetailPageLayout';
import BookingList from '../BookingList/Package';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import WithBookingConfirmation from '../BookingConfirmation/WithBookingConfirmation';
import BookingAssistant from '../BookingAssistant/PackageBookingAssistant';

import type MasterStore from '../../stores/MasterStore';
import type BookingUiStore from '../../stores/BookingUiStore';
import type Package from '../../models/Package';
import type Booking from '../../models/Booking';
import { tealiumDateFromDateObject, tealiumIdFromString, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

@preload({ packageDetails: 'Package' }, false)
@observer
class PackageDetailsBreadcrumb extends Component<{
  packageDetails: Package,
}> {
  render() {
    const { packageDetails } = this.props;

    return <BreadcrumbLink {...this.props} text={packageDetails.headline} />;
  }
}

type Props = {
  masterStore: MasterStore,
  bookingUiStore: BookingUiStore,
  packageDetails: Package,
};

@inject('masterStore', 'bookingUiStore', 'breadcrumbRouterStore')
@preload({ packageDetails: 'Package' })
@observer
export default class PagePackageDetails extends Component<Props> {
  static breadcrumb = PackageDetailsBreadcrumb;

  bookingRequest: BookingRequest;

  constructor(props: Props) {
    super(props);
    const { masterStore, packageDetails } = this.props;

    packageDetails.isInclusive = !!packageDetails.inclusiveInfo;

    this.bookingRequest = new BookingRequest(
      masterStore.masterData.travelParty,
      packageDetails,
      masterStore.packagesOfUser
    );
  }

  componentDidMount(): * {
    this.triggerTracking();
  }

  triggered = false;

  triggerTracking() {
    if (this.triggered === false) {
      this.triggered = true;

      tracking.viewItem(this.props.breadcrumbRouterStore, {
        category: this.props.packageDetails.analyticsCategory,
        categoryId: tealiumIdFromString(this.props.packageDetails.analyticsCategory),
        discount: '0.00',
        code: this.props.packageDetails.id,
        name: this.props.packageDetails.title,
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
        quantity: '1',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(this.props.packageDetails.price)),
      });
    }
  }

  @autobind
  handleBookingCancelClick(pkg: Booking) {
    this.bookingRequest.cancelBooking(pkg);
    this.props.bookingUiStore.openBookingDialog();
  }

  render() {
    const { masterStore, packageDetails, bookingUiStore } = this.props;
    return (
      <WithBookingConfirmation bookingRequest={this.bookingRequest}>
        <DetailPageLayout
          model={packageDetails}
          bookingList={() => (
            <BookingList
              header="Bereits reservierte Pakete:"
              bookings={this.bookingRequest.bookedPackagesOfSameType}
              user={masterStore.user}
              onCancelBooking={this.handleBookingCancelClick}
            />
          )}
          sidebar={(model) =>
            packageDetails.isInclusive ? null : (
              <BookingAssistant
                title={`Bitte wählen Sie die Anzahl für das ${model.title} aus:`}
                bookable={model}
                bookingRequest={this.bookingRequest}
                onBooking={() => {
                  tracking.addToCart(this.bookingRequest.selectedCount, this.bookingRequest.total);
                  this.bookingRequest.reset();
                  bookingUiStore.openBookingDialog();
                }}
              />
            )
          }
        />
      </WithBookingConfirmation>
    );
  }
}
