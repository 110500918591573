// @flow

import React from 'react';
import classNames from 'classnames';
import FormSelect from '../../../form/Select';

export default class Select extends FormSelect {
  render() {
    const { name, label, value } = this.props;

    const optionGroups = this.optionGroups;

    return (
      <div
        className={classNames({
          'form-dropdown': true,
        })}
      >
        <label
          htmlFor={name}
          className={classNames({
            'form-dropdown__label': true,
          })}
        >
          {label ? (
            <div
              className={classNames({
                'form-dropdown__label__text': true,
              })}
            >
              {label}
            </div>
          ) : null}
          <select
            id={name}
            ref={ref => {
              this._ref = ref;
            }}
            className={classNames({
              'form-dropdown__select': true,
            })}
            onChange={this.onChange}
            value={value}
          >
            {optionGroups.length > 1
              ? optionGroups.map((group, index) => (
                  <optgroup key={index}>
                    {group.map(this.renderOption)}
                  </optgroup>
                ))
              : optionGroups[0].map(this.renderOption)}
          </select>
        </label>
      </div>
    );
  }
}
