// TODO: add flow types

import { observable, action } from 'mobx';

import CatalogEventDaysList from '../models/CatalogEventDaysList';
import DailyEvent from '../models/DailyEvent';

export default class DailyEventsStore {
  @observable dayStores = {};
  @observable details = {};

  @observable list = null;
  @observable categoryFilter = null;

  masterStore = null;
  api = null;
  fetchListRequest = null;
  fetchDetailsRequest = {};

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action
  fetchList() {
    if (!this.fetchListRequest) {
      this.fetchListRequest = this.api.get(this.masterStore.user).then(
        action(data => {
          this.list = new CatalogEventDaysList(data.days);
          this.categoryFilter = data.filter.categories;
        })
      );
    }
    return this.fetchListRequest;
  }

  @action
  fetchDetails(eventId) {
    if (
      !this.fetchDetailsRequest[eventId] &&
      this.masterStore &&
      this.masterStore.masterData
    ) {
      this.fetchDetailsRequest[eventId] = this.api
        .getDetails(this.masterStore.user, this.masterStore.masterData, eventId)
        .then(data => {
          return this.receiveDetails(eventId, data);
        });
    }
    return this.fetchDetailsRequest[eventId];
  }

  @action
  receiveDetails(eventId, details) {
    this.details[eventId] = new DailyEvent(details, this.masterStore);
    return this.details[eventId];
  }
}
