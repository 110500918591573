// @flow

import React from 'react';
import classNames from 'classnames';

type Props = {
  src: string,
  // Source of image for small screens
  smallSrc?: ?string,
  squareSrc?: ?string,
  className?: ?string,
};

const Picture = ({ src, smallSrc, squareSrc, className }: Props) => (
  <picture
    className={classNames({
      picture: true,
      ...(className ? { [className]: !!className } : {}),
    })}
  >
    <source srcSet={src} media="screen and (min-width:644px)" />
    <source srcSet={smallSrc} media="screen and (min-width:320px)" />
    <img srcSet={squareSrc || smallSrc || src} />
  </picture>
);

export default Picture;
