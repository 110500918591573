// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Tile from '../../components/tiles/Tile';
import LoadingIndicator from '../../components/LoadingIndicator';
import Headline from '../../components/Headline';
import ErrorMessage from '../../components/form/ErrorMessage';

import type { SpaTeaserStore, DataTypes } from '../../stores/SpaTeaserStore';
import type TilesStore from '../../stores/TilesStore';
import type { TileType } from '../../api/tiles';

import { itsUrlWithSize } from '../../utils/image';

type Props = {
  spaTeaserStore: SpaTeaserStore,
  tilesStore: TilesStore,
};

@inject('spaTeaserStore', 'tilesStore')
@observer
export default class PageHealthAndBeauty extends Component<Props> {
  static breadcrumb = 'Schönheit & Gesundheit';

  componentDidMount() {
    this.props.spaTeaserStore.fetch();
    this.props.tilesStore.fetchAll();
  }

  render() {
    const { spaTeaserStore, tilesStore } = this.props;
    if (spaTeaserStore.isLoading || tilesStore.isLoading) {
      return <LoadingIndicator />;
    }

    if (spaTeaserStore.error) {
      return (
        <div className="page error">
          <ErrorMessage
            error={
              'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
            }
          />
        </div>
      );
    }
    return (
      <div className="page">
        <Headline title="Schönheit & Gesundheit" icon="health-beauty" />
        <div className="l-tiles">
          {spaTeaserStore &&
            !spaTeaserStore.error &&
            spaTeaserStore.data &&
            spaTeaserStore.data.map((content: DataTypes) => {
              const definition: TileType = tilesStore.getParamsByName(
                content.name
              );
              if (definition.visible === false) {
                return null;
              }

              return (
                <Tile
                  full
                  badge={definition.notification}
                  disabled={!definition.enabled}
                  key={content.type}
                  link={`/schoenheit-und-gesundheit/${content.type}`}
                  title={content.name}
                  imageSrc={itsUrlWithSize(content.imageUri, 544, 324)}
                  smallImageSrc={itsUrlWithSize(content.imageUri, 136, 136)}
                />
              );
            })}
        </div>
      </div>
    );
  }
}
