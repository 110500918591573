// @flow

import React, { Component } from 'react';

import FormRadio from './form/Radio';
import type Person from '../../../models/ArrivalDeparture/Person';

type Props = {
  persons: Person[],
  onChange: number => void,
  value: ?number,
};

export default class RadioGroup extends Component<Props> {
  render() {
    const { persons, onChange, value } = this.props;

    return (
      <div className="radio-group">
        {persons.map((person: Person, index: number) => {
          return (
            <label className="travel-party-options__item" key={index}>
              <FormRadio
                className="light-blue"
                name="person"
                value={person.mpi}
                onChange={onChange}
                checked={person.mpi === value}
                disabled={false}
                label={person.name}
              />
            </label>
          );
        })}
      </div>
    );
  }
}
