// TODO: add flow types

import isArray from 'lodash/isArray';
import { adjustedDate } from '../date';

function formValidator(matcher, options = {}, message) {
  return (str, formValues) =>
    matcher(str, options, formValues)
      ? { ok: true }
      : { ok: false, error: message };
}

const isRequired = str => {
  if (!str) return false;
  if (typeof str === 'boolean') return str;
  if (isArray(str)) return str.length > 0;

  return str.trim ? str.trim() !== '' : str;
};

const isLength = (str, options = {}) => {
  const min = options.min || 0;
  const max = options.max || null;
  return (
    str.length === 0 ||
    (str.length >= min && (max === null || str.length <= max))
  );
};

const isNumeric = str => {
  return !isNaN(str);
};

const EMAIL_REGEX_FROM_YT = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
const isEmail = str => !str || EMAIL_REGEX_FROM_YT.test(str);

const PHONE_REGEX_FROM_YT = /^\+?[\d-/\s]{1,49}$/;
const PHONE_REGEX_ONLY_DIGITS = /^\d{1,49}$/;
const PHONE_REGEX_ONLY_DIGITS_INTERNATIONAL = /^00\d{1,49}$/;
const isPhone = (str, options = {}) => {
  if (!str) return false;
  if (!options.onlyDigits) return PHONE_REGEX_FROM_YT.test(str);
  if (options.international) {
    return PHONE_REGEX_ONLY_DIGITS_INTERNATIONAL.test(str);
  }
  return PHONE_REGEX_ONLY_DIGITS.test(str);
};

const isCheckInPhone = (str, options = {}) => {
  if (!str || str === '') return true;
  if (!options.onlyDigits) return PHONE_REGEX_FROM_YT.test(str);
  if (options.international) {
    return PHONE_REGEX_ONLY_DIGITS_INTERNATIONAL.test(str);
  }
  return PHONE_REGEX_ONLY_DIGITS.test(str);
};

const isDate = (input, options = {}) => {
  const inputDate = adjustedDate(new Date(input));
  const isValidDate = inputDate.toString() !== 'Invalid Date';

  if (!isValidDate) return false;

  if (options.before && inputDate >= adjustedDate(new Date(options.before)))
    return false;
  if (options.after && inputDate <= adjustedDate(new Date(options.after)))
    return false;

  return true;
};

export default {
  isRequired,
  isLength,
  isEmail,
  isNumeric,
  isPhone,
  isCheckInPhone,
  isDate,
};

export const validateWith = (matcher, options, message) => {
  if (!message) {
    message = options;
    options = {};
  }
  return formValidator(matcher, options, message);
};

/**
 * Works like validateWith, except you can enter multiple validators and it will be
 * ok if one of those is ok.
 */
export const validateWithAny = (matchers, message) => {
  return (str, formValues) =>
    matchers.find(matcher => {
      if (typeof matcher === 'function') {
        return matcher(str, {}, formValues);
      } else {
        return matcher[0](str, matcher[1] || {}, formValues);
      }
    })
      ? { ok: true }
      : { ok: false, error: message };
};
