// @flow

import { css } from 'styled-components';

const dimensions = {
  mobileS: 320,
  mobileSM1: 374,
  mobileSM: 428,
  mobileM: 643,
  mobileL: 879,
  tablet: 1024,
  ticketMaxWidth: 373,
  desktopS: 1080,
  desktopM: 1440,
  desktopL: 1920,
  cruiseImage: {
    // 21:9
    width: 433,
    height: 186,
  },
  regionImage: {
    width: 373,
    height: 248,
  },
};

// TUI Cruises Logo immer 50% von Mein Schiff (Breite)
const linkedLogos = {
  myShip: css`
    width: 300px;

    @media (min-width: ${dimensions.desktopL}px) {
      width: 350px;
      height: 97px;
    }
    @media (max-width: ${dimensions.mobileL}px) {
      width: 210px;
    }
    @media (max-width: ${dimensions.mobileM}px) {
      width: 190px;
    }
    @media (max-width: ${dimensions.mobileS}px) {
      width: 160px;
    }
  `,
  tuic: css`
    width: 150px;

    @media (min-width: ${dimensions.desktopL}px) {
      width: 175px;
      height: 44px;
    }

    @media (max-width: ${dimensions.mobileL}px) {
      width: 105px;
    }
    @media (max-width: ${dimensions.mobileM}px) {
      width: 95px;
    }
    @media (max-width: ${dimensions.mobileS}px) {
      width: 80px;
    }
  `,
};

type Font = {
  size: string,
  weight?: string,
  style?: string,
  stretch?: string,
  lineHeight?: string | number,
  spacing?: string,
};

// Note:
//    The Design uses a different font than the WebFrontend.
//    Temporary Solution: Reduce the font-size 2px to fit the Design-Font
//    TODO: Agree with the customer which font to use
//
const font = (p: Font) => css`
  font-family: SourceSansPro;
  font-size: ${parseInt(p.size.replace('px', ''), 10) - 2}px;
  ${p.weight ? 'font-weight: ' + p.weight + ';' : ''}
  ${p.style ? 'font-style: ' + p.style + ';' : ''}
  ${p.stretch ? 'font-stretch: ' + p.stretch + ';' : ''}
  ${p.lineHeight ? 'line-height: ' + p.lineHeight + ';' : ''}
  ${p.spacing ? 'letter-spacing: ' + p.spacing + ';' : ''}
`;

const colors = {
  darkBlue: '#253e6a',
  darkBlue2: '#2e455b',
  lightBlue: '#85cee4',
  lightBlue2: '#929eb4',
  lightBlue3: '#d6eef4',
  lightBlue4: '#7ab7cc',
  lightBlue5: '#daeff4',
  lightBlue6: '#e7f5fa',
  lightBlue7: '#ceebf4',
  grey: '#c8ced9',
  darkBlue30: 'rgba(46, 69, 91, 0.3)',
  background50: 'rgba(37, 62, 106, 0.5)',
  errorRed: '#ee0000',
  gradient01: `linear-gradient(
    to left,
    rgba(198, 241, 255, 0),
    #c6f1ff 13%,
    #c6f1ff 87%,
    rgba(198, 241, 255, 0)
  )`,
};

const breakpoints = {
  tiny: 450,
  tablet: 768,
  desktop: 1024,
  wide: 1440,
};

const media = {
  tiny: `(max-width: ${breakpoints.tiny - 1}px)`,
  mobile: `(max-width: ${breakpoints.tablet - 1}px)`,
  tablet: `(max-width: ${breakpoints.desktop - 1}px)`,
  desktop: `(min-width: ${breakpoints.desktop}px)`,
  wide: `(min-width: ${breakpoints.wide}px)`,
};

const textLink = css`
  ${font({
    size: '16px',
    weight: 'bold',
    lineHeight: 1.25,
    style: 'italic',
  })} :hover {
    ${font({
      size: '17px',
      weight: 'bold',
      lineHeight: 1.18,
      style: 'italic',
    })};
  }

  :active {
    opacity: 0.7;
  }
`;

const space = {
  x: (times = 1) => `calc(${times} * (1vw + 8.5px))`,
  y: (times = 1) => `calc(${times} * (4vw + 26px))`,
};

export { font, colors, dimensions, linkedLogos, media, space, textLink };
