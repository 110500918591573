// @flow

export default class Person {
  name: string;
  mpi: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
