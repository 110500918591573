// @flow
import * as React from 'react';

import Headline from '../../../components/Headline';
import LinkAsButton from '../../../components/LinkAsButton';
import InfoBox from '../../../components/InfoBox';
import PinBlock from '../../../components/pin/PinBlock';
import PinIcon from '../../../../assets/svg/inline/sicherheits-pin.inline.svg';
import { PinInfoImage } from '../../pin/PinModalElements';
import pinStorage from '../../../../js/utils/pinStorage';

type Props = {
  userCheckinCompleted: boolean,
  isCheckinSubRoute: boolean,
  pinChanged: boolean,
  pin: ?string,
  currentMemberMpi: number,
};

const ManifestFormSuccess = ({
  userCheckinCompleted,
  isCheckinSubRoute,
  pinChanged,
  pin,
  currentMemberMpi,
}: Props) => {
  const storagePin = pinStorage.getPin(currentMemberMpi);
  return (
    <div className="page page-manifest-success">
      <div className="l-row">
        <div className="l-col double">
          <div>
            <Headline title="Ihre Daten sind gespeichert und geschützt" />
            {pinChanged ? (
              <p>
                Bitte beachten Sie, dass sich Ihre Sicherheits-PIN geändert hat.
                Ihre bisherigere Sicherheits-PIN ist ab sofort ungültig.
              </p>
            ) : (
              <p>
                Sie haben im Schiffsmanifest vertrauliche Daten hinterlegt. Aus
                datenschutzrechtlichen Gründen ist dieser Bereich ab sofort mit
                einer Sicherheits-PIN geschützt.
              </p>
            )}
            <InfoBox className="small-icon pin" icon={<PinIcon />}>
              {pinChanged ? (
                <h2>Ihre neue Sicherheits-PIN</h2>
              ) : (
                <h2>Ihre persönliche Sicherheits-PIN</h2>
              )}
              <p>
                Die Sicherheits-PIN ergibt sich aus den letzten 4 Zeichen der
                angegebenen Personalausweis- oder Reisepassnummer.
                <strong>
                  {' '}
                  Bitte merken Sie sich diese Sicherheits-PIN, um weiterhin
                  Zugang zu Ihren vertraulichen Daten zu haben.
                </strong>
              </p>
              <div className="pin-info-container">
                <PinInfoImage />
              </div>
              <PinBlock displayOnlyPin={pin ? pin : storagePin} />
            </InfoBox>
            <section className="l-mod">
              <div className="l-mod">
                <InfoBox className="small-icon">
                  Bitte beachten Sie, dass die Verarbeitung bis zu 15 Minuten
                  andauern kann und in dieser Zeit kein Zugriff auf die Daten
                  möglich ist. Nach Beendigung der Verarbeitung können Sie Ihre
                  Daten bei Bedarf bis 1 Tag vor Reiseantritt ändern.
                </InfoBox>

                {userCheckinCompleted && (
                  <p>
                    Ihr bereits erfolgter Check-in und der ausgestellte
                    Schiffspass bleiben auch nach der Aktualisierung Ihrer Daten
                    gültig. Sie müssen nichts weiter unternehmen.
                  </p>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="l-right l-mod">
        {isCheckinSubRoute ? (
          <LinkAsButton className="button dark big" dark link="/checkin">
            Zur Check-in Übersicht
          </LinkAsButton>
        ) : (
          <LinkAsButton className="button dark big" dark link="/manifest">
            Zur Manifest-Übersicht
          </LinkAsButton>
        )}
      </div>
    </div>
  );
};

export default ManifestFormSuccess;
