// @flow

import React from 'react';
import styled, { css } from 'styled-components';

import NavEntries from '../NavEntries';
import UserName from './UserName';
import IconWrapper from '../IconWrapper';

import { type NavigationItemList } from '../../../api/navigation';

import { font, colors, dimensions } from '../styles';

import ArrowIcon32 from '../../../../assets/svg/inline/arrow-icon-32.inline.svg';
import CloseIcon32 from '../../../../assets/svg/inline/close-icon-32.inline.svg';
import HomeIcon32 from '../../../../assets/svg/inline/home-icon-32.inline.svg';
import PhoneIcon32 from '../../../../assets/svg/inline/phone-icon-32.inline.svg';
import UserHeadIcon32 from '../../../../assets/svg/inline/user-head-icon-32.inline.svg';

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 86vw;
  height: 100vh;
  max-height: 100vh;
  z-index: 10;

  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  transform: translate3d(100%, 0, 0);
  transition: transform 300ms ease-in-out;
  background-color: white;

  ${p =>
    p.open
      ? css`
          box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.16);
          transform: translate3d(0, 0, 0);
        `
      : css`
          transition: transform 300ms ease-in-out,
            box-shadow 400ms ease-in 400ms;
        `} .nav-entry {
    display: block;
    ${font({ size: '22px' })} margin: 20px 0 25px 0;
    height: 24px;

    :hover {
      ${font({ size: '23px' })};
    }

    :active {
      opacity: 0.7;
    }
  }

  @media (min-width: ${dimensions.mobileL + 1}px) {
    display: none;
  }
`;

const Contact = styled.a`
  display: flex;
  height: 70px;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-start;

  &:nth-child(1) {
    background-color: ${colors.lightBlue};
  }

  &:nth-child(2) {
    background-color: ${colors.lightBlue3};
  }

  :hover {
    & > span {
      ${font({ size: '21px' })};
    }
  }

  :active {
    opacity: 0.7;
  }

  & > span {
    flex: 1;
    ${font({ size: '20px' })};
    margin-left: 8px;

    @media (max-width: ${dimensions.mobileS}px) {
      ${font({ size: '20px' })};
    }
  }

  @media (max-width: ${dimensions.mobileS}px) {
    padding: 0 8px;
  }
`;

const NavWrapper = styled.div`
  @media (max-width: ${dimensions.mobileL}px) {
    padding: 0 20px 0 78px;
  }

  @media (max-width: ${dimensions.mobileS}px) {
    padding-left: 64px;
  }
`;

const CloseButton = styled.button`
  display: none;

  position: absolute;
  top: 24px;
  right: 30px;

  :hover {
    opacity: 0.5;
  }

  :active {
    svg {
      width: 34px;
      height: 34px;
    }
  }

  @media (max-width: ${dimensions.mobileL}px) {
    display: block;
  }
`;

const UserNameWrapper = styled.div`
  display: none;

  @media (max-width: ${dimensions.mobileL}px) {
    margin-top: 82px;
    display: flex;
    height: 50px;
    padding: 0 20px;
    align-items: center;
    justify-content: flex-start;
  }

  @media (max-width: ${dimensions.mobileS}px) {
    padding: 0 8px;
  }
`;

interface Props {
  userName?: string;
  open: boolean;
  navigation: NavigationItemList;
}

export default function Menu({ userName, open = false, navigation }: Props) {
  return (
    <MobileMenu open={open}>
      <CloseButton>
        <IconWrapper icon={<CloseIcon32 />} />
      </CloseButton>
      <UserNameWrapper>
        <IconWrapper icon={<UserHeadIcon32 />} />
        <UserName name={userName} />
      </UserNameWrapper>
      <NavWrapper>
        <NavEntries model={navigation} />
      </NavWrapper>
      <div>
        <Contact
          href="http://meinschiff.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper icon={<HomeIcon32 />} />
          <span>meinschiff.com</span>
          <IconWrapper icon={<ArrowIcon32 />} />
        </Contact>
        <Contact href="#phone">
          <IconWrapper icon={<PhoneIcon32 />} />
          <span>Kontakt</span>
          <IconWrapper icon={<ArrowIcon32 />} />
        </Contact>
      </div>
    </MobileMenu>
  );
}
