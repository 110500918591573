// @flow
import Package from './Package';

const SUBTYPE_DVD_PREFIX = 'DVD';
const SUBTYPE_GUIDE_PREFIX = 'Reiseführer';

export default class TravelMediaPackage extends Package {
  get analyticsCategory(): string {
    return this.category;
  }

  get isDVD(): boolean {
    return Boolean(
      this.category && this.category.indexOf(SUBTYPE_DVD_PREFIX) === 0
    );
  }

  get isTravelGuide(): boolean {
    return Boolean(
      this.category && this.category.indexOf(SUBTYPE_GUIDE_PREFIX) === 0
    );
  }
}
