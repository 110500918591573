// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import type ParkingBookingRequest from '../../models/BookingRequest/ParkingBookingRequest';

import Price from '../Price';
import Button from '../Button';
import Checkbox from '../form/Checkbox';
import { autobind } from 'core-decorators';
import type Bookable from '../../models/Bookable';

type Props = {
  bookingRequest: ParkingBookingRequest,
  onBooking: Function,
  bookable: Bookable,
};

@observer
export default class ParkingBookingAssistant extends Component<Props> {
  @autobind
  changedCheckbox(value: boolean) {
    this.props.bookingRequest.setAcceptation(value);
  }

  renderButton() {
    const { bookingRequest, bookable, onBooking } = this.props;
    if (bookable.statusCode === 0) {
      return (
        <Button dark big disabled={!bookingRequest.isReady} onClick={onBooking}>
          Zur Reservierung
        </Button>
      );
    } else {
      return (
        <Button dark big disabled>
          {bookable.bookingStatus}
        </Button>
      );
    }
  }

  render() {
    const { bookingRequest, bookable } = this.props;

    return (
      <div>
        <p>
          Parkservices können nur einmalig und persönlich reserviert werden.
          Sollten Sie einen anderen Service wünschen, können Sie die bereits
          reservierte Leistung kostenfrei stornieren und den gewünschten Service
          reservieren.
        </p>
        {!bookingRequest.hasBookedSomethingOfSameType && (
          <div className="form-checkbox-fakegroup">
            <Checkbox
              name="operatorAccepted"
              label="Mir ist bekannt, dass TUI Cruises die Anfrage zur Reservierung eines Parkplatzes lediglich vermittelt und die Bereitstellung des Parkplatzes durch den Parkservice-Dienstleister am Hafen erfolgt. Mein Vertragspartner wird der örtliche Parkservice-Dienstleister, welcher die Verantwortung für die Durchführung der Parkservices trägt. Ich nehme weiter zur Kenntnis, dass TUI Cruises meinen Vor- und Nachnamen sowie meine E-Mail-Adresse an den Parkservice-Dienstleister weitergibt, damit dieser mir unter Verwendung dieser Kontaktdaten die Reservierungsbestätigung sendet."
              onChange={this.changedCheckbox}
              checked={bookingRequest.acceptedOperator}
            />
          </div>
        )}
        <div className="l-right">
          {!bookingRequest.hasBookedSomethingOfSameType && (
            <div className="l-mod-sub">
              <Price price={bookable.priceText} unit={bookable.priceLabel} />
            </div>
          )}
          {this.renderButton()}
        </div>
      </div>
    );
  }
}
