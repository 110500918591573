// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';

type Props = {
  children: Node,
  // Does it have double sized width and height?
  double?: boolean,
};

const TileGroup = ({ children, double }: Props) => (
  <div
    className={classNames({
      'tile-group': true,
      'tile-group--double': double,
    })}
  >
    {children}
  </div>
);

export default TileGroup;
