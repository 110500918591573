import React from 'react';
import Button from '../../Button';

export const MissingStreetNumberPopup = ({
  showPopup,
  closePopup,
  confirmPopup,
}: {
  showPopup: boolean,
  closePopup: () => {},
  confirmPopup: () => {},
}) => {
  return (
    <>
      {showPopup && (
        <div className="popup">
          <div className="popup-background"></div>
          <div className="popup-inner">
            <div className="popup-header">
              Sind Sie sicher, dass Ihre Adresse korrekt ist, ohne dass eine Hausnummer eingegeben wurde?
            </div>
            <div className="popup-buttons">
              <Button onClick={closePopup}>Bearbeiten</Button>
              <Button dark onClick={confirmPopup}>
                Bestätigen
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
