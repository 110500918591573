// @flow

export default class PageContent {
  name: string;
  type: string;
  headline: string;
  documentType: string;
  imageUri: ?string;
  systemName: string;
  text: string;
  attachments: { type: string, uri: string }[];
  categoryNames: ?(string[]);
  description: ?string;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get imageSrc(): ?string {
    if (this.imageUri) return this.imageUri;
    const image =
      (this.attachments &&
        this.attachments.find(a => a.type.indexOf('image/') !== -1)) ||
      false;
    return image ? image.uri : null;
  }
}
