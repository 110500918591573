// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { autobind } from 'core-decorators';
import Headline from '../Headline';
import LoadingIndicator from '../LoadingIndicator';
import InfoBox from '../InfoBox';
import Button from '../Button';
import BookingOptionsMultiSelect from './BookingOptionsMultiSelect';
import type IncludedShoreExBookingRequest from '../../models/BookingRequest/IncludedShoreExBookingRequest';
import type PartyMember from '../../models/PartyMember';
import type { IBookingOption } from '../../api/includedShoreEx';
import IncludedBookingUpdateRequest from '../../models/IncludedShoreEx/IncludedBookingUpdateRequest';
import {
  ATTENDS,
  NOT_ATTENDS,
  LEGAL_NOTE_IDS,
} from '../../models/IncludedShoreEx/constants';
import { setItem, getItem, removeItem } from '../../utils/sessionStorage';
import { isEmpty, clone } from '../../utils/objects';
import config from '../../config';

type Props = {
  bookingRequest: IncludedShoreExBookingRequest,
  onConfirm: (data: any) => any,
  onClose: () => any,
};

type State = {
  multiSelectOptions: Object[],
};

const cacheKey = config.excursionMultiSelectCacheKey;

@observer
export default class BookingOptions extends Component<Props, State> {
  initialState = {};
  travelPartyState = {};
  state = {
    multiSelectOptions: [],
  };

  componentDidMount() {
    try {
      const cachedState = getItem(cacheKey);
      if (cachedState) {
        this.travelPartyState = JSON.parse(cachedState);
      }
    } catch (exc) {
      removeItem(cacheKey);
      this.travelPartyState = {};
    }

    this.initTravelPartyState();
  }

  initTravelPartyState() {
    const { bookingRequest } = this.props;
    const { travelParty } = bookingRequest;

    let travelPartyApiState = {};

    travelParty.forEach((member: PartyMember) => {
      const mpi = +member.mpi;
      const bookingState = bookingRequest.getBookingStateByMpi(mpi);

      if (bookingState) {
        const { bookingOptionId, status } = bookingState;
        const bookingOption =
          bookingRequest.getBookingOptionById(bookingOptionId) || {};

        const notAttends = status === NOT_ATTENDS;
        const attends = status === ATTENDS;

        travelPartyApiState[mpi] = {
          checked: notAttends,
          day: attends ? bookingOption.date : null,
          bookingOptionId: attends ? bookingOptionId : null,
        };
      }
    });

    if (isEmpty(this.travelPartyState)) {
      this.travelPartyState = travelPartyApiState;
    }

    this.initialState = clone(travelPartyApiState);
    this.setState({ multiSelectOptions: this.multiSelectOptions });
  }

  getMarkedVacancies(mpi: number, vacancies: Object): Object {
    const { bookingRequest } = this.props;
    const { bookingOptions } = bookingRequest;

    const markedVacancies = clone(vacancies);

    bookingOptions.forEach((option: IBookingOption) => {
      const { date, bookingOptionId, time } = option;
      if (this.initialState[mpi].bookingOptionId === bookingOptionId) {
        const vacancy = markedVacancies[date].find(
          v => v.value === bookingOptionId
        );
        vacancy.label = `${time} Uhr (Für Sie reserviert)`;
      }
    });

    return markedVacancies;
  }

  @computed
  get multiSelectOptions(): Object[] {
    const { bookingRequest } = this.props;
    const { daySlots, travelParty, vacancies } = bookingRequest;

    return travelParty
      .map((member: PartyMember): Object => {
        const { mpi, displayName } = member;

        const currentState = this.travelPartyState[mpi];
        if (!currentState) return null; // TUICUNIT-1562
        const { bookingOptionId, checked, day } = currentState;

        return bookingRequest.getBookingStateByMpi(mpi)
          ? {
              checked,
              daySlots,
              label: displayName,
              selectedDay: day,
              selectedBookingOptionId: bookingOptionId,
              vacancies: this.getMarkedVacancies(mpi, vacancies),
              value: mpi,
            }
          : null;
      })
      .filter(item => !!item);
  }

  @computed
  get mpis(): number[] {
    return Object.keys(this.travelPartyState).map(mpi => +mpi);
  }

  get title(): string {
    return this.props.bookingRequest.getText('options', 'title');
  }

  get text(): string {
    return this.props.bookingRequest.getText('options', 'text');
  }

  @autobind
  handleCloseConfirmationClick() {
    const { bookingRequest, onClose } = this.props;

    if (onClose) onClose();
    bookingRequest.cleanErrors();
    // patch to fix display of cancellation-page when reservation-page should
    // be displayed due to not resetting bookableToCancel on BookingRequest
    // after cancelling the cancellation-page of a partizipant
    // TODO this patch & API needs to be checked for all BookingRequest
    bookingRequest.reset();
  }

  @autobind
  prepareBookingRequest() {
    const { onConfirm, bookingRequest } = this.props;

    bookingRequest.bookingRequestData = new IncludedBookingUpdateRequest(
      this.mpis
        .map((mpi: number): Object => {
          const state = this.travelPartyState[mpi];
          const { checked, bookingOptionId } = state;
          if (checked || !!bookingOptionId) {
            return {
              mpi,
              bookingOptionId: checked ? null : bookingOptionId,
            };
          }
        })
        .filter(item => !!item)
    );

    onConfirm && onConfirm();
  }

  get stateChanged(): boolean {
    let changed = false;

    for (let i = 0, n = this.mpis.length; i < n; i++) {
      const mpi = this.mpis[i];
      const a = this.initialState[mpi];
      const b = this.travelPartyState[mpi];

      if (
        a.checked !== b.checked ||
        a.day !== b.day ||
        a.bookingOptionId !== b.bookingOptionId
      ) {
        changed = true;
        break;
      }
    }

    return changed;
  }

  get vacancyCountErrors(): string[] {
    const { bookingRequest } = this.props;
    const { travelParty, bookingOptions } = bookingRequest;

    const criticalSlots = bookingOptions.filter(
      option => option.vacancyCount <= travelParty.length
    );

    const idsToBook = this.mpis
      .map(mpi => {
        const checked = this.travelPartyState[mpi].checked;
        const selectedId = this.travelPartyState[mpi].bookingOptionId;
        const apiStateId = this.initialState[mpi].bookingOptionId;
        if (!checked && selectedId !== apiStateId) {
          return selectedId;
        }
      })
      .filter(m => !!m);

    return criticalSlots
      .map((option: IBookingOption): string => {
        if (!option) return '';
        const ids = idsToBook.filter(id => id === option.bookingOptionId);
        return ids.length > option.vacancyCount
          ? `Für die ausgewählte Leistung am ${option.date} um ${
              option.time
            } Uhr sind leider nicht genug freie Plätze verfügbar. Bitte wählen Sie einen neuen Termin aus.`
          : '';
      })
      .filter(m => !!m);
  }

  get conflicts(): Object[] {
    const { bookingRequest } = this.props;
    const { travelParty } = bookingRequest;

    return travelParty
      .map((member: PartyMember): Object => {
        const mpi = +member.mpi;
        const currentState = this.travelPartyState[mpi];
        if (!currentState) return null;

        const { checked, bookingOptionId } = currentState;
        const conflict = !checked
          ? bookingRequest.getBookingConflict(mpi, bookingOptionId)
          : null;

        return conflict
          ? {
              mpi,
              message: conflict.conflictText,
            }
          : null;
      })
      .filter(m => !!m);
  }

  get stateValid(): boolean {
    const { bookingRequest } = this.props;
    let valid = true;

    for (let i = 0, n = this.mpis.length; i < n; i++) {
      const mpi = this.mpis[i];
      const { checked, day, bookingOptionId } = this.travelPartyState[mpi];
      const conflict = !checked
        ? !!bookingRequest.getBookingConflict(mpi, bookingOptionId)
        : false;

      if (conflict || (!checked && (!day || !bookingOptionId))) {
        valid = false;
        break;
      }
    }

    return valid;
  }

  multiSelectChanged() {
    this.setState({
      multiSelectOptions: this.multiSelectOptions,
    });
  }

  @autobind
  onWaiverChange(mpi: number, checked: boolean) {
    this.travelPartyState[mpi].checked = checked;
    this.multiSelectChanged();
  }

  @autobind
  onDayChange(mpi: number, day: string) {
    this.travelPartyState[mpi].day = day;
    this.travelPartyState[mpi].bookingOptionId = null;
    this.multiSelectChanged();
  }

  @autobind
  onTimeChange(mpi: number, bookingOptionId: string) {
    this.travelPartyState[mpi].bookingOptionId = bookingOptionId;
    this.multiSelectChanged();
  }

  render() {
    const { bookingRequest } = this.props;
    const { multiSelectOptions } = this.state;
    let { bookable } = bookingRequest || {};

    if (bookingRequest.isRequesting || multiSelectOptions.length <= 0) {
      return <LoadingIndicator />;
    }

    const vacancyCountErrors = this.vacancyCountErrors;
    const hasVacancyCountError = vacancyCountErrors.length > 0;

    const babiesNote = bookingRequest.getLegalNoteById(
      LEGAL_NOTE_IDS.inclusiveExcursionIncludesBabies
    );
    const notRefundableNote = bookingRequest.getLegalNoteById(
      LEGAL_NOTE_IDS.inclusiveExcursionNotRefundable
    );

    return (
      <div className="booking-options">
        <Headline title={this.title} subtitle={bookable.name} />

        <BookingOptionsMultiSelect
          options={multiSelectOptions}
          onWaiverChange={this.onWaiverChange}
          onDayChange={this.onDayChange}
          onTimeChange={this.onTimeChange}
        />

        <div className="messages">
          {this.conflicts.map((c, i) => (
            <p key={i} className="error-message">
              {c.message}
            </p>
          ))}

          {vacancyCountErrors.map((message, i) => (
            <p key={i} className="error-message">
              {message}
            </p>
          ))}

          {babiesNote && (
            <InfoBox className="no-center-icon small-icon">
              <h2>Kinder unter 4 Jahren</h2>
              <p>{babiesNote.note}</p>
            </InfoBox>
          )}

          {notRefundableNote && <p>{notRefundableNote.note}</p>}
        </div>

        <div className="booking-confirmation__buttons">
          <p>
            <Button
              dark
              big
              onClick={() => {
                setItem(cacheKey, JSON.stringify(this.travelPartyState));
                this.prepareBookingRequest();
              }}
              disabled={
                !this.stateChanged || !this.stateValid || hasVacancyCountError
              }
            >
              Weiter
            </Button>
          </p>
          <p>
            <Button
              onClick={() => {
                removeItem(cacheKey);
                this.handleCloseConfirmationClick();
              }}
            >
              Zurück
            </Button>
          </p>
        </div>
      </div>
    );
  }
}
