// @flow

import type { IIncludedShoreExDetail } from '../../api/includedShoreEx';
import ExcursionOperator from '../ExcursionOperator';
import { prepareText } from '../../utils/prepareText';
import { DIFFICULTIES } from '../../config/constants';

export default class IncludedShoreExDetail implements IIncludedShoreExDetail {
  id: number;
  bookingCode: string;
  name: string;
  headline: string;
  description: string;
  descriptionHtml: string;
  notes: string;
  difficulty: 'CHALLENGING' | 'EASY' | 'EXTRA_EASY' | 'MODERATE' | 'SPORTIVE';
  difficultyDisplay: string;
  difficultyDescription: string;
  duration: string;
  catering: string;
  images: $ReadOnlyArray<{
    // using "image" as property name instead of "url" for compatibility with
    // components like e.g. CatalogTile
    image: string,
    comment?: string,
  }>;
  operators: Object[];
  type: string;
  _displayOperator: ?ExcursionOperator;
  masterExcursionId: number;

  // flow
  isPriceBulk: any;
  date: any;
  day: any;
  tourSalesTermsId: any;
  requiresTourSalesTermsNotice: any;
  vacancyId: any;
  isBooked: any;
  booking: any;
  bookingId: any;
  analyticsCategory: any;
  generalWalkingInfo: ?string;

  constructor(data: IIncludedShoreExDetail) {
    const { images } = data;

    if (data.difficultyDescription) {
      data.difficultyDescription = prepareText(data.difficultyDescription, ['a']);
    }

    const adaptedData = {
      ...data,
      images: images.map(image => ({
        image: image.url,
        comment: image.comment,
      })),
    };

    Object.assign(this, { ...adaptedData });

    this._displayOperator = ExcursionOperator.createFromArray(this.operators);
  }

  get displayOperator(): ?ExcursionOperator {
    return this._displayOperator;
  }

  // Returns a number between 0 and 4
  get difficultyNumber(): number {
    return DIFFICULTIES.indexOf(this.difficulty);
  }

  get vacancy(): string {
    return 'hidden';
  }

  getPriceTypes(): any {}

  get bookedMpis(): number[] {
    return [];
  }

  // TODO obtain vacancies
}
