// TODO: add flow types

// Manifest: src/js/models/ManifestFormValidation.js

import mapValues from 'lodash/mapValues';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';

const validateInput = (str, validators = [], formValues) => {
  let validationResult = null;
  validators.find(validator => {
    const result = validator(str, formValues);
    if (!result.ok) {
      validationResult = result.error;
      return true;
    } else {
      return false;
    }
  });
  return validationResult;
};

const validateForm = (obj, validators = [], formValues) => {
  return mapValues(obj, (value, key) => {
    return typeof value === 'object' &&
      value !== null &&
      !isArray(value) &&
      !isDate(value)
      ? validateForm(value, validators[key], formValues)
      : validateInput(value, validators[key], formValues);
  });
};

export default {
  validateInput,
  validateForm,
};
