// @flow
import { observable, computed, action } from 'mobx';
import union from 'lodash/union';
import without from 'lodash/without';
import parse from 'date-fns/parse';
import addMinutes from 'date-fns/add_minutes';


import logger from '../../utils/logger';
import ItineraryDay from '../../models/ItineraryDay';
import PartyMember from '../../models/PartyMember';
import SpaOffer from '../../models/SpaOffer';
import Booking from '../../models/Booking';
import VacancyTimeSlots from '../../models/VacancyTimeSlots';

import BookingRequestBase from './BookingRequestBase';
import { BOOKING_ADDED, BOOKING_DELETED } from './constants';

import type {
  BookingType,
  IGeneralItemToBook,
  IGeneralBookingData,
  IBookingCancelationData,
  IPurchaseDetails,
} from '../../types/booking';

export default class SpaBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      success: {
        title: 'Ihre Reservierungsanfrage ist eingegangen.',
        text:
          'Vielen Dank für Ihre Reservierungsanfrage. Den aktuellen Status Ihrer Anfrage können Sie in Ihrem Reiseplan einsehen. Bitte beachten Sie, dass es sich bei Ihrer Reservierungsanfrage um einen Wunschtermin handelt. Wir behalten uns vor, Ihren Termin gegebenenfalls zu verschieben. Aufgrund dieser Änderungen empfehlen wir, Ihren Reiseplan vor der Reise und an Bord auf Aktualität zu prüfen.<br> <br>Vielen Dank für Ihr Verständnis.',
      },
    },
    cancellation: {
      ...BookingRequestBase.TEXTS.cancellation,
      success: {
        title: 'Ihre Stornierungsanfrage ist eingegangen.',
        text:
          'Ihre Stornierung ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
    },
  };

  @observable selectedDay: ?ItineraryDay = null;
  @observable selectedMpis: number[] = [];
  @observable selectedTime: ?string = null;
  @observable vacancies = null;
  @observable errors = null;
  @observable isRequesting = false;
  @observable requestSuccessful = false;
  @observable bookingToCancel: ?Booking = null;

  constructor(
    travelParty: PartyMember[],
    bookable: SpaOffer,
    vacancies: ?VacancyTimeSlots
  ) {
    super(travelParty, bookable);
    this.vacancies = vacancies;
  }

  @action
  reset() {
    this.bookingToCancel = null;
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @action
  chooseDay(day: ItineraryDay) {
    this.selectedDay = day;
  }

  @action
  chooseParticipant(mpi: ?number) {
    this.selectedMpis = mpi ? [mpi] : [];
  }

  @action
  selectParticipants(mpi: number, selected: boolean) {
    this.selectedMpis = selected
      ? union(this.selectedMpis, [mpi])
      : without(this.selectedMpis, mpi);
  }

  @action
  chooseTime(time: string) {
    this.selectedTime = time;
  }

  @action
  removeDay() {
    this.selectedDay = null;
  }

  @action
  setVacancies(vacancies: VacancyTimeSlots) {
    this.vacancies = vacancies;
  }

  @computed
  get showIndividualPrices(): boolean {
    return !this.needsMultipleParticipants;
  }

  @computed
  get needsMultipleParticipants(): boolean {
    return this.bookable.numberOfParticipants > 1;
  }

  @computed
  get availableTimeSlots(): string[] {
    return this.vacancies
      ? this.vacancies.timeSlotsFor(this.bookable.numberOfParticipants)
      : [];
  }

  @computed
  get isCancellation(): boolean {
    return !!this.bookingToCancel;
  }

  @computed
  get isChangeRequest(): boolean {
    return false;
  }

  @computed
  get vacancyId(): ?string {
    if (!this.vacancies || !this.selectedTime) return null;
    const vacancy = this.vacancies.vacancyFor(
      this.selectedMpis.length,
      this.selectedTime
    );
    return vacancy ? vacancy.id : null;
  }
  /*
    conflictsWithBooking (bookings: Bookings[]): boolean {
        return false;
    }
*/
  createItemToBook(
    mpi: number,
    type: BookingType,
    day: ?ItineraryDay
  ): IGeneralItemToBook {
    const member = this.travelParty.find(p => p.mpi === mpi);

    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAge : '',
      price: this.bookable.priceOnDay(day),
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
      personHeight: null,
    };
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] {
    if (this.isCancellation && this.bookingToCancel) {
      const day = this.bookingToCancel.day;
      return this.bookingToCancel.mpis.map(mpi =>
        this.createItemToBook(mpi, BOOKING_DELETED, day)
      );
    }
    return this.isReady && this.selectedDay
      ? this.selectedMpis.map(mpi =>
          this.createItemToBook(mpi, BOOKING_ADDED, this.selectedDay)
        )
      : [];
  }

  @computed
  get total(): number {
    return this.bookable.priceOnDay(this.selectedDay);
  }

  @computed
  get date(): string {
    if (!this.selectedDay) {
      return '';
    }
    return this.selectedDay.date;
  }

  get startDateTime(): ?Date {
    if (!this.date || !this.selectedTime) return null;
    // Date Perversion buggy date-fns/parse is the same like js new Date()
    // console.log(parse(`${this.date}T${this.selectedTime}:00`));
    // console.log(new Date(`${this.date}T${this.selectedTime}:00`));

    return parse(`${this.date}T${this.selectedTime}:00`);
  }

  get endDateTime(): ?Date {
    if (!this.startDateTime || !this.bookable.duration) return null;
    return addMinutes(this.startDateTime, this.bookable.duration);
  }

  @computed
  get dataHasChanged(): boolean {
    return false;
  }

  @computed
  get isPersonSelected(): boolean {
    return this.selectedMpis.length > 0;
  }

  @computed
  get isReady(): boolean {
    return !!(
      this.selectedDay &&
      this.selectedTime &&
      this.selectedMpis.length === this.bookable.numberOfParticipants
    );
  }

  @computed
  get bookingData(): IGeneralBookingData[] {
    if (
      !this.isReady ||
      this.isCancellation ||
      !this.vacancies ||
      !this.selectedTime
    ) {
      return [];
    }
    const foundVacancy = this.vacancies.vacancyFor(
      this.bookable.numberOfParticipants,
      this.selectedTime
    );
    if (!foundVacancy) {
      logger.error(
        'No vacancy found, but it should be',
        this.vacancies,
        this.bookable.numberOfParticipants,
        this.selectedTime
      );
      return [];
    }

    return [
      {
        type: 'offer',
        vacancyId: foundVacancy.id,
        participants: this.selectedMpis.slice(),
      },
    ];
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.isCancellation) return [];
    if (this.bookingToCancel) {
      const bookedIds = this.bookingToCancel.bookingId;
      const mpisToCancel = this.bookingToCancel.mpis.slice();

      return [
        {
          type: 'offer',
          bookingId: bookedIds[mpisToCancel[0]],
          mpis: mpisToCancel,
        },
      ];
    }
    return [];
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const { headline, bookingId, analyticsCategory } = this.bookable;
    return {
      products: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}`,
              name: headline,
              category: analyticsCategory,
              quantity: 1,
              price: this.bookable.priceOnDay(this.selectedDay),
            },
          ],
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}-${this.selectedMpis.length}`,
              revenue: this.total,
            },
          ],
      refunds:
        this.isCancellation && this.bookingToCancel
          ? [
              {
                id: `${this.bookingToCancel.typeId}-${
                  this.bookingToCancel.mpis.length
                }`,
              },
            ]
          : [],
    };
  }
}
