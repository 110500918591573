// @flow
// TUICUNIT-2138
import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { autobind } from 'core-decorators';

import storage from '../../utils/storage';
import LinkAsButton from '../../components/LinkAsButton';
import Button from '../../components/Button';
import PartyMember from '../../models/PartyMember';

import ReminderIcon from '../../../assets/svg/inline/reminder.inline.svg';

import type { TilesStoreType } from '../../stores/TilesStore';

import type MasterStore from '../../stores/MasterStore';

import { SURVEY_MPI_CONFIRMATION_REQUIRED } from '../../config/constants';

/**
 * check first view: via local storage;
 * checkin tile available: via tiles ApiResponse;
 * all travelParty member has checkin available : via PartyMember;
 * all travelParty member checked in: via PartyMember;
 * some travelParty member has questionnaire.status CONFIRMATION_REQUIRED via: PartyMember
 *
 * @param {TilesStoreType} tiles
 * @param {PartyMember} travelParty
 *
 * @return {object} { css: (' force-digitalHealth' || ''), state: (true || false) }
 */
const forceDigitalHealthNeeded = (
  tiles: TilesStoreType,
  travelParty: PartyMember[]
): { css: string, state: boolean } => {
  // is it the first view? check: local storage
  const flagFirstView = storage.get('force-digitalHealth') || false;

  // is checkin tile available? check: tilesStore
  let flagCheckInTile =
    (tiles.onlineCheckIn && tiles.onlineCheckIn.enabled === true) || false;

  // all member are checkin available? check: PartyMember
  const flagAllCheckInAvailable = travelParty.every(
    partyMember => partyMember.checkin.available
  );

  // all member are checked in? check: PartyMember
  const flagAllCheckIn = travelParty.every(
    partyMember => partyMember.checkinCompleted
  );

  let flagCheckInFinish = false;

  // checkInTile === true
  if (flagCheckInTile) {
    // Checkin is available and all has completet
    if (flagAllCheckInAvailable && flagAllCheckIn) {
      flagCheckInFinish = true;
    }
    // Checkin is no more available, all checkins are no longer relevant
    if (!flagAllCheckInAvailable) {
      flagCheckInFinish = true;
    }
  }

  // Survey some one has state CONFIRMATION_REQUIRED
  const flagConfirm = travelParty.some(
    partyMember =>
      partyMember.questionnaire
        ? partyMember.questionnaire.status === SURVEY_MPI_CONFIRMATION_REQUIRED
        : false
  );

  /* travelParty.forEach(f => {
    console.log(f.questionnaire.status);
  }); */
  /* const logState = {
    state: {
      flagFirstView: flagFirstView,
      flagCheckinFinish: flagCheckInFinish,
      flagConfirm: flagConfirm,
    },
    triggerView: {
      flagFirstView: 'muss true',
      flagCheckinFinish: 'muss true',
      flagConfirm: 'muss true',
      result: flagFirstView && flagCheckInFinish && flagConfirm,
    },
  };
  console.table(logState); */

  // view forceCheckin: if (fistView === true &&
  //                        flagCheckinFinish === true &&
  //                        flagConfirm == ture)
  const forceReminder =
    flagFirstView && flagCheckInFinish && flagConfirm
      ? { css: ' force-reminder', state: true }
      : { css: '', state: false };
  return forceReminder;
};

/**
 * set local storage (force-digitalHealth: false), needed to find out if this the first view (flag)
 * @return {boolean} true
 */
const setViewedDigitalHealth = (): boolean => {
  storage.set('force-digitalHealth', false);
  return true;
};

type Props = {
  tiles: ?TilesStoreType,
  callback: func,
  masterStore: MasterStore,
};

type State = {
  remind: boolean,
};

@inject('masterStore')
class ForceDigitalHealthInfo extends Component<Props, State> {
  timerID = null;
  state = {
    remind: false,
  };

  componentWillUnmount() {
    if (this.timerID !== null) {
      clearTimeout(this.timerID);
    }
  }

  @autobind
  handleCloseClick() {
    this.setState({
      remind: true,
    });

    const { callback } = this.props;
    this.timerID = setTimeout(() => {
      setViewedDigitalHealth();
      callback();
    }, 2000);
  }

  render() {
    const { tiles, travelParty, masterStore } = this.props;
    if (
      forceDigitalHealthNeeded(tiles, travelParty, masterStore.masterData)
        .state === false
    ) {
      return null;
    }

    return (
      <div
        className={`force-popup health${this.state.remind ? ' reminder' : ''}`}
      >
        <div className="arrow-left" />
        {this.state.remind ? (
          <div className="head health">
            <ReminderIcon className="reminder" />
            <h2>
              Gerne erinnern wir Sie zu einem späteren Zeitpunkt erneut an die
              Aktualisierung Ihrer Gesundheitsangaben.
            </h2>
          </div>
        ) : (
          <div>
            <div className="head">
              <i className="icon healthy" />
              <h2>Sind Ihre Gesundheitsdaten noch aktuell?</h2>
            </div>
            <span>
              Bitte prüfen und bestätigen Sie für alle Teilnehmer die Aktualität
              Ihrer Angaben im Gesundheits&shy;fragebogen.
            </span>
            <div className="cta health">
              <Button onClick={this.handleCloseClick} className="health">
                Erledige ich später
              </Button>
              <LinkAsButton
                link="/checkin"
                dark
                onClick={setViewedDigitalHealth}
              >
                Zum Gesundheits&shy;fragebogen
              </LinkAsButton>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export {
  forceDigitalHealthNeeded,
  setViewedDigitalHealth,
  ForceDigitalHealthInfo,
};
