// @flow
import { observable, action } from 'mobx';

import BoardingPass from '../models/BoardingPass';

import type MasterStore from '../stores/MasterStore';
import type { ApiType } from '../api/boarding';

const VALID_FOR_FALLBACK = 180;

export default class BoardingStore {
  @observable passes: ?(BoardingPass[]) = null;
  @observable passValidUntil = null;
  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  // TUICUNIT-2614:single ShipPasses per MPI
  @action
  passesForMpi(mpi: number) {
    if (this.passes) {
      const hasPasses = (this.passes || []).find(pass => pass.mpi === mpi);
      if (
        hasPasses &&
        this.passValidUntil &&
        new Date() < this.passValidUntil
      ) {
        return new Promise(resolve => {
          resolve(this.passes);
        });
      }
    }
    return this.api.passesForMpi(this.masterStore.user, mpi).then(
      action(response => {
        this.passes = (response.passes || []).map(
          p =>
            new BoardingPass(
              p,
              this.masterStore.masterData.getPartyMember(p.mpi)
            )
        );
        // -20 seconds Puffer -> TUICMRL-1053
        const validUntil = new Date();
        validUntil.setSeconds(
          validUntil.getSeconds() +
            ((response.validFor || VALID_FOR_FALLBACK) - 20)
        );
        this.passValidUntil = validUntil;
        return this.passes;
      })
    );
  }
}
