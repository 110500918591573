// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type PartyMember from '../models/PartyMember';
import type MasterData from '../models/MasterData';

import type { ApiType, ApiResponse } from '../api/digitalHealth';
import { l10n } from '../components/Manifest/DigitalHealth/l10n';
import { jsLocalDateTime } from '../utils/timezone';

import {
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_NOT_OK_L1,
  SURVEY_MPI_NOT_OK_L2,
  SURVEY_SINGLECHOICE,
} from '../config/constants';

const DEFAULT_ERROR_MESSAGE =
  'Der Gesundheitsfragebogen steht zur Zeit nicht zur Verfügung. Bitte versuchen Sie es später erneut.';

const INPUT_RADIO = 'RADIO_BUTTON';
const INPUT_CHECKBOX = 'CHECKBOX';
const INPUT_TEXT = 'TEXT';

export type AnswerType = {
  id: number,
  attentionLevel: string | null,
  questionId: number,
  questionType: string,
  isCorrect: boolean,
  isGiven: boolean,
  answerText: string | null,
  text: {
    de: string,
    en: string,
  },
  answerChoise: {
    de: string,
    en: string,
  },
  message: {
    state: boolean,
    de: string,
    en: string,
  },
};

export type AllWorngAnswersType = {
  attentionLevel: string | null,
  message: {
    state: boolean,
    de: string,
    en: string,
  },
};

export type QuestionType = {
  id: number,
  isRadio: boolean,
  isCheckBox: boolean,
  isText: boolean,
  questionType: string,
  questionText: {
    de: string,
    en: string,
  },
  hint: {
    de: string,
    en: string,
  } | null,
  answers: [AnswerType],
  allWorngAnswers: [AllWorngAnswersType] | null,
};

export type ErrorType = {
  status?: boolean,
  text: string,
};

export type SurveyMetaType = {
  id: number,
  title: {
    de: string,
    en: string,
  },
  text: {
    de: string,
    en: string,
  },
  submitted: string,
  status: string,
  attentionLevel: string | null,
  resultInfo: {
    description: string,
    headline: String,
  } | null,
  resultInfos: {
    headline: {
      de: string,
      en: string,
    },
    description: {
      de: string,
      en: string,
    },
  } | null,
};

export default class DigitalHealthStore {
  @observable isSending: boolean = false;
  @observable loading: boolean = false;
  @observable questions: ?ApiResponse = null;
  @observable surveyMeta: ?SurveyMetaType = null;
  @observable
  error: ?ErrorType = {
    text: DEFAULT_ERROR_MESSAGE,
  };

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  fetch(currentMember: PartyMember) {
    this.resetStates();

    if (currentMember) {
      return this.api
        .get(this.masterStore.user, currentMember.mpi)
        .then((result: ApiResponse) => {
          this.receiveSections(result);
        });
    } else {
      this.setError({ status: true });
    }
  }

  info() {
    const masterData: MasterData = this.masterStore.masterData;
    const user: PartyMember = this.masterStore.masterData.requestor;
    const data = {
      surveyId: user.digitalHealthSurveyId,
      embarkationDate: masterData.edFull,
      debarkationDate: masterData.ddFull,
      shipId: masterData.shipId,
    };
    if (user.digitalHealthSurveyId) {
      return this.api
        .info(this.masterStore.user, data)
        .then((result: ApiResponse) => {
          this.receiveSections(result);
        });
    } else {
      return false;
    }
  }

  @action
  saveData(data, currentMember: PartyMember) {
    this.startRequest();
    const answers = [];
    // TUICUNIT-2681: changed internal save structure
    Object.keys(data).forEach((qIndex: string) => {
      Object.keys(data[qIndex]).forEach((aIndex: string) => {
        if (data[qIndex][aIndex] !== null) {
          let answer = {
            id: parseInt(aIndex, 10),
          };
          if (data[qIndex][aIndex] !== aIndex) {
            answer = {
              ...answer,
              text: data[qIndex][aIndex],
            };
          }
          answers.push(answer);
        }
      });
    });

    const submitData = {
      questionnaireAnswers: [
        {
          mpi: currentMember.mpi,
          // surveyId: this.surveyMeta.id,
          answers: answers,
        },
      ],
    };
    this.api.save(this.masterStore.user, submitData).then(result => {
      if (result.status === 500) {
        this.setError(result);
      } else {
        this.fetch(currentMember);
      }
    });
  }

  @action
  confirmData(currentMember: PartyMember) {
    this.startRequest();
    const submitData = {
      mpi: currentMember.mpi,
    };
    this.api.confirm(this.masterStore.user, submitData).then(result => {
      if (result.status === 500) {
        this.setError(result);
      } else {
        this.fetch(currentMember);
      }
    });
  }

  @action
  receiveSections(result: ApiResponse) {
    if (result && result.surveyId) {
      this.questions = result.questions;
      this.surveyMeta = this.setSurveyMeta(result);
      this.loading = !result.status;
      // reset loader after submit
      if (this.isSending) {
        this.setDone();
      }
    } else {
      this.setError(result);
    }
  }

  @action
  setError(result) {
    this.error = {
      status: result ? !!result.status : true,
      text: DEFAULT_ERROR_MESSAGE,
    };
    this.questions = false;
    this.loading = false;
  }

  @action
  resetStates() {
    this.loading = true;
    this.error = {
      status: false,
    };
  }

  @action
  startRequest() {
    this.isSending = true;
  }

  @action
  setDone() {
    this.isSending = false;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }
  @computed
  get inProgress(): boolean {
    return this.isSending;
  }

  @computed
  get hasError(): boolean {
    return this.error.status || false;
  }

  @computed
  get getQuestions(): [] | [QuestionType] {
    return this.questions ? this.prepareQuestions(this.questions) : [];
  }

  @computed
  get getSurveyMeta(): {} | SurveyMetaType {
    return this.surveyMeta || {};
  }

  setSurveyMeta(data) {
    let result = {};
    if (data) {
      result = {
        id: data.surveyId,
        title: this.prepareStructur(data.title),
        text: this.prepareStructur(data.welcomeText),
        submitted: data.submitted ? this.prepareTime(data.submitted) : null,
        status: this.prepareStatus(data.status),
        attentionLevel: this.prepareAttentionLevel(data.status),
        resultInfo:
          data.resultInfo && data.resultInfo.headline
            ? {
                headline: this.prepareText(data.resultInfo.headline),
                description: this.prepareText(data.resultInfo.description),
              }
            : null,
        resultInfos:
          data.resultInfos && data.resultInfos.headline
            ? {
                headline: this.prepareStructur(data.resultInfos.headline),
                description: this.prepareStructur(data.resultInfos.description),
              }
            : null,
      };
    }
    return result;
  }

  // TUICUNIT-2681: changed api structure
  prepareQuestions(questions) {
    let result = [];
    if (Array.isArray(questions)) {
      questions.forEach(question => {
        let answers = [];
        if (Array.isArray(question.answers)) {
          question.answers.forEach(answer => {
            answers.push({
              id: answer.id,
              attentionLevel: answer.attentionLevel
                ? this.prepareLevel(answer.attentionLevel)
                : null,
              questionId: question.id,
              questionType: question.questionType || SURVEY_SINGLECHOICE,
              isCorrect: answer.isCorrect,
              isGiven: answer.isGiven || false,
              answerText: answer.answerText || null,
              text: this.prepareStructur(answer.text),
              answerChoise:
                question.visualControl === INPUT_CHECKBOX &&
                question.questionType === SURVEY_SINGLECHOICE
                  ? {
                      de: l10n.surveyResult.de.answerConfirm,
                      en: l10n.surveyResult.en.answerConfirm,
                    }
                  : this.prepareStructur(answer.text),
              message: {
                state: answer.hint !== null,
                ...this.prepareStructur(answer.hint),
              },
            });
          });
        }

        const hasWorngAnswers = answers.some(
          a => a.isGiven === true && a.isCorrect === false
        );

        let allWorngAnswers = null;
        if (hasWorngAnswers) {
          allWorngAnswers = [];
          answers.forEach(a => {
            if (a.isGiven === true && a.isCorrect === false) {
              allWorngAnswers.push({
                attentionLevel: a.attentionLevel,
                message: a.message,
              });
            }
            return null;
          });
        }

        result.push({
          id: question.id,
          isRadio: question.visualControl === INPUT_RADIO,
          isCheckBox: question.visualControl === INPUT_CHECKBOX,
          isText: question.visualControl === INPUT_TEXT,
          questionType: question.questionType || SURVEY_SINGLECHOICE,
          questionText: this.prepareStructur(question.text),
          hint:
            question.popupText && question.popupText !== null
              ? this.prepareStructur(question.popupText)
              : null,
          answers: answers,
          allWorngAnswers: allWorngAnswers,
        });
      });
    }

    return result;
  }

  prepareStructur(textBlock: [{ language: string, value: string }]) {
    const result = {};
    if (Array.isArray(textBlock)) {
      textBlock.forEach(value => {
        result[value.language] = this.prepareText(value.value);
      });
    }
    return result;
  }

  prepareTime(time: string) {
    const t = jsLocalDateTime(time);

    const h = t.getHours() < 10 ? `0${t.getHours()}` : t.getHours();
    const m = t.getMinutes() < 10 ? `0${t.getMinutes()}` : t.getMinutes();
    const dD = t.getDate() < 10 ? `0${t.getDate()}` : t.getDate();
    const dM =
      t.getMonth() + 1 < 10 ? `0${t.getMonth() + 1}` : t.getMonth() + 1;
    const dY = t.getFullYear();
    // 24.08.2020 | 14:30 Uhr
    return `${dD || '--'}.${dM || '--'}.${dY || '--'} | ${h || '--'}:${m ||
      '--'}`;
  }

  // TUICUNIT-2681/TUICUNIT-2701: more than one Not OK state, for whatever reason
  prepareStatus(state: string) {
    if (
      state === SURVEY_MPI_NOT_OK ||
      state === SURVEY_MPI_NOT_OK_L1 ||
      state === SURVEY_MPI_NOT_OK_L2
    ) {
      state = SURVEY_MPI_NOT_OK;
    }
    return state;
  }

  // TUICUNIT-2681/TUICUNIT-2701: SurveyMeta State is: SURVEY_AVAILABLE_OPEN_MPI_COMPLETE_NOT_OK_LEVEL_2
  // we need only LEVEL_1 | LEVEL_2 information
  prepareAttentionLevel(rawLevel: ?string) {
    let level = rawLevel || '';
    level = level.replace(`${SURVEY_MPI_NOT_OK}`, '');
    level = this.prepareLevel(level);
    return level;
  }

  // TUICUNIT-2681/TUICUNIT-2701: Question attentionLevel is: LEVEL_1 | LEVEL_2
  // we need level1 / level2 to use this as a css class
  prepareLevel(rawLevel: ?string) {
    let level = rawLevel || '';
    level = level.toLowerCase().replace(/(_)/gi, '');
    return level;
  }

  /**
   * remove all html tags, only -> ul, li, b, a are allowed
   *
   * @deprecated Use utilfunction prepareText instead
   * @param {string} text
   * @returns clean String
   */
  prepareText(text: ?string) {
    let cText = text || '';

    cText = cText.replace(/(<b>)/gi, '[b]').replace(/(<\/b>)/gi, '[/b]');
    cText = cText.replace(/(<ul>)/gi, '[ul]').replace(/(<\/ul>)/gi, '[/ul]');
    cText = cText.replace(/(<li>)/gi, '[li]').replace(/(<\/li>)/gi, '[/li]');
    cText = cText.replace(/(<a href)/gi, '[a]').replace(/(<\/a>)/gi, '[/a]');

    cText = cText.replace(/(<([^>]+)>)/gi, '');
    cText = cText.replace(/([\n\t])/gi, ' ');
    cText = cText.split('  ').join(' ');

    cText = cText.split('[b]').join('<span class="bold">');
    cText = cText.split('[/b]').join('</span>');
    cText = cText.split('[ul]').join('<ul>');
    cText = cText.split('[/ul]').join('</ul>');
    cText = cText.split('[li]').join('<li><span>');
    cText = cText.split('[/li]').join('</span></li>');
    cText = cText.split('[a]').join(' <a href');
    cText = cText.split('[/a]').join('</a>');

    cText = cText.replace(
      /(Mein Schiff)/gi,
      '<span class="nobr">Mein Schiff</span>'
    );

    return cText;
  }
}
