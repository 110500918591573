// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import type ArrivalDepartureItem from '../../../../../models/ArrivalDeparture/ArrivalDepartureItem';
import { loadImg } from '../../../../../utils/image';

type Props = {
  flight: ArrivalDepartureItem,
};

export default class FlightInfoItem extends Component<Props> {
  get airlineLogo(): Node {
    const { flight } = this.props;

    return (
      <div className="item airline-logo">
        <img
          className={classNames({
            'airline-svg': !!flight.airlineLogo,
          })}
          src={
            flight.airlineLogo ||
            loadImg('tiles/img-anreise-abreise-544x324.jpg')
          }
        />
      </div>
    );
  }

  get flightFromWithDate(): Node {
    const { flight } = this.props;

    return (
      <div className="item from">
        <div>{flight.from}</div>
        <div className="time">{flight.departureDate}</div>
      </div>
    );
  }

  get airplaneIcon(): Node {
    return (
      <div className="item airplane-icon">
        <i className="icon airplane" />
      </div>
    );
  }

  get flightToWithDate(): Node {
    const { flight } = this.props;

    return (
      <div className="item to">
        <div>{flight.to}</div>
        <div className="time">{flight.arrivalDate}</div>
      </div>
    );
  }

  get flightFrom(): Node {
    const { flight } = this.props;
    return <div className="item from">{flight.from}</div>;
  }

  get flightTo(): Node {
    const { flight } = this.props;
    return <div className="item to">{flight.to}</div>;
  }

  get departureDate(): Node {
    const { flight } = this.props;

    return (
      <div className="item departure">
        <div className="a">{flight.departureDate}</div>
      </div>
    );
  }

  get arrivalDate(): Node {
    const { flight } = this.props;

    return (
      <div className="item arrival">
        <div className="a">{flight.arrivalDate}</div>
      </div>
    );
  }

  get departureDateTime(): Node {
    const { flight } = this.props;

    return (
      <div className="item departure">
        <div className="a">{flight.departureDate}</div>
        <div className="b">{flight.departureTime}</div>
      </div>
    );
  }

  get arrivalDateTime(): Node {
    const { flight } = this.props;

    return (
      <div className="item arrival">
        <div className="a">{flight.arrivalDate}</div>
        <div className="b">{flight.arrivalTime}</div>
      </div>
    );
  }

  get flightNumber(): Node {
    const { flight } = this.props;

    return (
      <div className="item flight-number">
        <div className="a">Flug</div>
        <div className="b">{flight.flightNumber}</div>
      </div>
    );
  }

  get flightClass(): Node {
    const { flight } = this.props;

    return (
      <div className="item item-X">
        <div className="a">Klasse</div>
        <div className="b">{flight.flightClass}</div>
      </div>
    );
  }

  get airlineName(): Node {
    const { flight } = this.props;
    return <div className="item item-X">{flight.airline}</div>;
  }

  get seat(): Node {
    const { flight } = this.props;

    return flight.seat ? (
      <div className="a">
        Ihr Sitzplatz <span className="bold">{flight.seat}</span>
      </div>
    ) : null;
  }

  get ticketNumber(): Node {
    const { flight } = this.props;

    return flight.ticketNumber ? (
      <div className="a">
        Ihre Ticketnummer <span className="bold">{flight.ticketNumber}</span>
      </div>
    ) : null;
  }

  get codeShareFlight(): Node {
    const { flight } = this.props;

    return flight.codeShareFlight ? (
      <div className="share-flight">Durchgeführt von: {flight.codeShareFlight}</div>
    ) : null;
  }

  get ticketFileKey(): Node {
    const { flight } = this.props;

    return flight.ticketFileKey ? (
      <div className="a">
        Ihre Buchungsnummer/File Key{' '}
        <span className="bold">{flight.ticketFileKey}</span>
      </div>
    ) : null;
  }
}
