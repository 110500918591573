// @flow
import * as React from 'react';

import Tile from './Tile';

import type PartyMember from '../../models/PartyMember';

type Props = {
  travelParty: PartyMember[],
  partyMember: PartyMember,
  linkBase?: string,
  state?: string,
  configEnabled: boolean,
  configNotification: string,
  onClick: (partyMember: PartyMember, url: string) => void,
};

const ManifestTile = ({
  travelParty,
  partyMember,
  linkBase = '/manifest',
  state,
  configEnabled,
  configNotification,
  onClick,
}: Props) => {
  const index = travelParty.indexOf(partyMember);
  if (index === -1) return null;

  const url = `${linkBase}/manifest/${index}`;
  const icon = state && state === 'done' ? 'manifest-done' : 'manifest';

  let disabled = false;
  let badge = partyMember.manifestPreliminary
    ? 'In Bearbeitung'
    : partyMember.manifestCompleted ? null : 'Daten unvollständig';

  // TUICUNIT-2856 if tilesconfig enable ignore other state and badge
  if (configEnabled === false) {
    disabled = true;
    badge = configNotification;
  }

  return (
    <Tile
      title="Schiffsmanifest"
      icon={icon}
      disabled={disabled}
      badge={badge}
      onClick={e => {
        e.preventDefault();
        if (onClick) onClick(partyMember, url);
        return false;
      }}
    />
  );
};

export default ManifestTile;
