// @flow

import React, { Component } from 'react';

// TUICMA-76
const getLockOutTime = (seconds: number): number => {
  for (let i = 10; i >= 1; i--) {
    if (seconds >= i * 60 - 59 && seconds <= i * 60) return i;
  }
  return 0;
};

type Props = {
  data: ?Object,
  reloaded: boolean,
  currentTime: number,
};

export const TYPE_ANY = 'ANY_CREDENTIALS_LOCKOUT';
export const TYPE_LAST = 'LAST_CREDENTIALS_LOCKOUT';

export default class LockoutMessage extends Component<Props> {
  render() {
    const { data, reloaded, currentTime } = this.props;
    if (!data) return null;

    const { lockoutType, lockoutUntilTimestamp } = data;
    const minutes = getLockOutTime(lockoutUntilTimestamp - currentTime);

    return (
      <div className="lockout-message">
        {lockoutType === TYPE_ANY ? (
          <div className="blue">
            <div className="bold">
              Es gab zu viele fehlerhafte Anmeldungen. Sie sind aus
              Sicherheitsgründen nun für folgenden Zeitraum gesperrt:
            </div>
            <div className="minutes">
              {minutes} Minute{minutes > 1 ? 'n' : ''}
            </div>
            <div>
              Bitte versuchen Sie es nach Ablauf dieses Zeitraumes noch einmal.
            </div>
          </div>
        ) : (
          <div className="red">
            {reloaded ? null : <div>Ihre Anmeldung ist fehlgeschlagen:</div>}
            Es gab zu viele fehlerhafte Anmeldungen.
            {reloaded
              ? ' Die zuletzt genutzten Anmeldedaten sind nun aus Sicherheitsgründen '
              : ' Sie sind aus Sicherheitsgründen nun '}
            <span className="bold">
              für {minutes} Minute{minutes > 1 ? 'n' : ''} gesperrt
            </span>. Bitte versuchen Sie es danach erneut.
          </div>
        )}
      </div>
    );
  }
}
