// @flow

import { observable, action, computed } from 'mobx';

import PageContent from '../models/PageContent';

import type MasterStore from '../stores/MasterStore';
import type {
  StaticContentApi,
  StaticContentTypes,
} from '../api/staticContent';

export class StaticContentTypeStore {
  @observable content: ?(PageContent[]) = null;

  masterStore: MasterStore;
  api: StaticContentApi;
  type: string;
  request: Promise<*>;

  constructor(
    api: StaticContentApi,
    masterStore: MasterStore,
    type: StaticContentTypes
  ) {
    this.api = api;
    this.masterStore = masterStore;
    this.type = type;
  }

  @computed
  get isLoading(): boolean {
    return this.content === null;
  }

  @action
  fetch() {
    if (!this.request) {
      this.request = this.api[this.type]().then(
        action(content => {
          this.content =
            content instanceof Array
              ? content.map(c => new PageContent(c))
              : [new PageContent(content)];
          return this.content;
        })
      );
    }
    return this.request;
  }
}

export default class StaticContentStore {
  @observable contents = {};

  typeStores = {};
  masterStore: MasterStore;
  api: StaticContentApi;
  requests = {};

  constructor(api: StaticContentApi, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  storeForType(type: StaticContentTypes) {
    if (!this.typeStores[type]) {
      this.typeStores[type] = new StaticContentTypeStore(
        this.api,
        this.masterStore,
        type
      );
    }
    return this.typeStores[type];
  }

  @action
  fetch(type: StaticContentTypes) {
    if (!this.requests[type]) {
      this.requests[type] = this.api[type]().then(content => {
        return this.receiveContent(type, content);
      });
    }
    return this.requests[type];
  }

  @action
  receiveContent(type: StaticContentTypes, content: {}) {
    this.contents[type] = new PageContent(content);
    return this.contents[type];
  }
}
