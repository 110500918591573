// @flow
const account = 'https://meinereisen.meinschiff.com';
export default {
  trackingCode: 'prod', // 'UA-6438006-23',
  cookieDomain: '.meinschiff.com',
  logoutURL: 'https://meinereise.meinschiff.com/login',
  account,
  accountLogin: `${account}/signin`,
  accountRegister: `${account}/signin/registration`,
  excursionMultiSelectCacheKey: 'travelPartyState',
  publicHealthQuestionnaire:
    'https://meinereise.meinschiff.de/config/fragebogen/current.pdf',
  iadvizeId: '3833',
};
