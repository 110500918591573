// @flow
import React, { Component } from 'react';
import { observer } from 'mobx-react';

import Headline from './Headline';
import InlineInfoBox from './InlineInfoBox';
import ImageSlider from './ImageSlider';

import type ItineraryDay from '../models/ItineraryDay';

type Props = {
  day?: ItineraryDay,
  // This is a detail model
  model: Object,
  bottomPart?: Function,
  bookingList?: Function,
  infoList: Function,
  sidebar: Function,
  onSlideShowChange?: Function,
  infoBox?: Function,
};

@observer
export default class DetailPageLayout extends Component<Props> {
  get subSubTitle(): ?string {
    const { day, model, journeyOngoing } = this.props;

    // TUICUNIT-2687: display time
    if (day && model && model.type === 'excursionDetail') {
      const onCruiseOrFirstOrLastDay =
        journeyOngoing || day.isArrival || day.isDeparture;

      // before Cruise mostly
      if (model.timeOfDay && !onCruiseOrFirstOrLastDay) {
        return `${model.timeOfDay}, ${model.displayDuration}`;
      }

      // on Cruise mostly
      if (model.dispatchTime) {
        if (onCruiseOrFirstOrLastDay) {
          return `${model.dispatchTime} Uhr`;
        } else {
          return `${model.dispatchTime} Uhr, ${model.displayDuration}`;
        }
      }
      if (model.startTime) {
        if (onCruiseOrFirstOrLastDay) {
          return `${model.startTime} Uhr${
            model.endTime ? ` - ${model.endTime} Uhr` : ''
          }`;
        } else {
          return `${model.startTime} Uhr${
            model.endTime ? ` - ${model.endTime} Uhr` : ''
          }, ${model.displayDuration}`;
        }
      }
      if (model.timeOfDay) {
        return `${model.timeOfDay}`;
      }
    }

    if (!model.displayDuration) return null;
    if (model && (!model.endTime || !model.startTime)) return null;

    return `${model.timeOfDay}, ${model.displayDuration}`;
  }

  render() {
    const {
      day,
      model,
      infoList,
      bookingList,
      bottomPart,
      sidebar,
      onSlideShowChange,
      infoBox,
      excursionSubSubTitle,
    } = this.props;

    if (!model) {
      return null;
    }

    return (
      <div className="page">
        <Headline
          title={model.title}
          subtitle={day ? day.subtitleLine : null}
          subsubtitle={
            excursionSubSubTitle ? excursionSubSubTitle : this.subSubTitle
          }
        />

        <ImageSlider
          image={model.imageSrc || model.image}
          images={model.images}
          beforeChange={onSlideShowChange}
        />

        {infoBox ? infoBox(model) : null}

        {model.headline && model.headline !== model.title ? (
          <h2>{model.headline}</h2>
        ) : null}

        {model.isInclusive &&
          model.inclusiveInfo && <InlineInfoBox model={model.inclusiveInfo} />}

        <div className="l-row">
          <div className="l-col double l-mod">
            {bookingList ? bookingList(model) : null}

            {!model.isInclusive && (
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: model.descriptionHtml || model.description,
                }}
              />
            )}

            {model.hint ? (
              <p>
                <strong>{model.hint}</strong>
              </p>
            ) : null}

            {infoList ? infoList(model) : null}
          </div>
          <div className="l-col right">{sidebar ? sidebar(model) : null}</div>
        </div>
        {bottomPart ? bottomPart(model) : null}
      </div>
    );
  }
}
