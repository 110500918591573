// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import Headline from '../Headline';
import LinkAsButton from '../LinkAsButton';
import type IncludedShoreExBookingRequest from '../../models/BookingRequest/IncludedShoreExBookingRequest';
import Button from '../Button';

type Props = {
  bookingRequest: IncludedShoreExBookingRequest,
};

@inject('authStore')
@observer
export default class BookingConfirmationSuccess extends Component<Props> {
  get title() {
    return this.props.bookingRequest.getText('success', 'title');
  }

  get text() {
    return this.props.bookingRequest.getText('success', 'text');
  }

  // TUICUNIT-3782
  @autobind onLogoutClick() {
    this.props.authStore.logout().then(() => {
      // We make a hard reset here, to be sure, we don't have any states saved in some stores
      // or anywhere else
      window.location.href = this.props.authStore.user.backLink ? this.props.authStore.user.backLink : '/login';
    });
  }

  render() {
    const { bookingRequest } = this.props;

    let { bookable } = bookingRequest || {};

    return (
      <div className="booking-confirmation inclusive">
        <Headline title={this.title} subtitle={bookable.name} />
        <p dangerouslySetInnerHTML={{ __html: this.text }} />
        <div className="booking-confirmation__buttons">
          <p>
            <Button
              dark
              big
              onClick={() => {
                window.location.reload();
              }}
            >
              Zum Landausflug
            </Button>
          </p>
          <p>
            {this.props.authStore.user && this.props.authStore.user.backLink ? (
              <Button onClick={this.onLogoutClick}>Zur Startseite</Button>
            ) : (
              <LinkAsButton link="/">Zur Startseite</LinkAsButton>
            )}
          </p>
        </div>
      </div>
    );
  }
}
