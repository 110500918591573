// @flow

import React from 'react';
import styled from 'styled-components';
import { dimensions, font } from '../styles';

const Name = styled.div`
  margin: 0 8px;
  ${font({ size: '17px', weight: 'bold', spacing: '0.32px', style: 'italic' })}

  @media (max-width: ${dimensions.mobileL}px) {
    ${font({ size: '20px', weight: 'bold', style: 'italic' })}
  }

  @media (max-width: ${dimensions.mobileS}px) {
    margin-left: 6px;
  }
`;

interface Props {
  name?: string;
}

export default function UserName({ name }: Props) {
  return name ? <Name>{name}</Name> : null;
}
