// @flow

import React from 'react';
import FlightInfoCase from './FlightInfoCase';

export default class NonPublished extends FlightInfoCase {
  render() {
    return (
      <div className="flight-info-case">
        <div className="tablet">
          <div className="line">
            {this.airlineLogo}
            {this.flightFromWithDate}
            {this.airplaneIcon}
            {this.flightToWithDate}
            {this.flightClass}
          </div>
        </div>
        <div className="mobile">
          <div className="line">
            {this.flightFrom}
            {this.airplaneIcon}
            {this.flightTo}
          </div>
          <div className="line neg-margin">
            {this.departureDate}
            {this.arrivalDate}
          </div>
          <div className="line margin">{this.flightClass}</div>
        </div>
        <span className="non-published">{this.codeShareFlight}</span>
      </div>
    );
  }
}
