const hints = {
  nonPublished:
    'Aktuell sind die Flugzeiten noch nicht bekannt. Diese werden frühestens 4 Monate und bis spätestens 7 Tage vor Ihrem jeweiligen Hin- und Rückflug im Bereich Meine Reise veröffentlicht. Alle Informationen erhalten Sie mit Ihren digitalen Reiseunterlagen 7 Tage vor Reisebeginn.',
  published:
    'Wir bitten um Verständnis, dass es jederzeit zu Flugzeitenänderungen durch die Fluggesellschaft kommen kann, auf die wir keinen Einfluss haben. Es gelten die Beförderungsbedingungen der jeweiligen Fluggesellschaft.',
  bus:
    'Alle Informationen zu der genauen Haltestelle sowie den Abfahrtszeiten erhalten Sie mit Ihren Reiseunterlagen. Im Verlauf Ihrer An- und Abreise können Gäste entlang der Strecke zu- bzw. aussteigen. Änderungen des Routenverlaufs sowie der Abfahrtszeiten, auch nach Erstellung der Reiseunterlagen, behält sich TUI Cruises vor. <a rel="noopener noreferrer" href="https://www.meinschiff.com/an-und-abreise/bus" target="_blank">Weitere Informationen zur An- und Abreise mit dem Bus</a>',
};

export default hints;
