// @flow
import * as React from 'react';

import ManifestTile from '../../../components/tiles/ManifestTile';
import PaymentTile from '../../../components/tiles/ManifestPaymentTile';
import CheckinTile from '../../../components/tiles/CheckinTile';
import ContentBox from '../../../components/ContentBox';

import { MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION } from '../../../config/constants';
import type { TilesStoreType } from '../../../stores/TilesStore';

import type PartyMember from '../../../models/PartyMember';
type Props = {
  partyMember: PartyMember,
  travelParty: PartyMember[],
  tiles: TilesStoreType,
  onClick: (partyMember: PartyMember, url: string) => void,
};

const NotCheckedInMember = ({ partyMember, travelParty, tiles, onClick }: Props) => {
  if (partyMember.checkinStatusReason === MASTERDATA_STATUS_CHECKIN_TOO_CLOSE_TO_EMBARKATION) {
    return (
      <ContentBox className="content-box__smaller">Bitte checken Sie am Anreisetag direkt am Terminal ein.</ContentBox>
    );
  }
  return (
    <div className="l-tiles">
      {tiles.schiffsManifest.visible ? (
        <ManifestTile
          travelParty={travelParty}
          partyMember={partyMember}
          configEnabled={tiles.schiffsManifest.enabled}
          configNotification={tiles.schiffsManifest.notification}
          linkBase="/checkin"
          state={partyMember.manifestCompleted && !partyMember.manifestPreliminary ? 'done' : 'open'}
          onClick={onClick}
        />
      ) : null}
      {tiles.zahlungsmittel.visible ? (
        <PaymentTile
          travelParty={travelParty}
          partyMember={partyMember}
          configEnabled={tiles.zahlungsmittel.enabled}
          configNotification={tiles.zahlungsmittel.notification}
          linkBase="/checkin"
          state={partyMember.paymentCompleted && !partyMember.paymentPreliminary ? 'done' : 'open'}
          onClick={onClick}
        />
      ) : null}
      {tiles.checkIn.visible ? (
        <CheckinTile
          travelParty={travelParty}
          partyMember={partyMember}
          configEnabled={tiles.checkIn.enabled}
          configNotification={tiles.checkIn.notification}
        />
      ) : null}
    </div>
  );
};

export default NotCheckedInMember;
