// TODO: add flow types

import { observable, action } from 'mobx';

import Excursion from '../models/Excursion';

import ExcursionDayStore from './ExcursionDayStore';

export default class ExcursionStore {
  @observable dayStores = {};
  @observable details = {};

  masterStore = null;
  api = null;
  detailRequest = {};

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action
  storeForDay(itineraryDay) {
    if (!itineraryDay) return;
    const { dayNumber } = itineraryDay;

    if (!this.dayStores[dayNumber]) {
      this.dayStores[dayNumber] = new ExcursionDayStore(
        this.api,
        this.masterStore,
        itineraryDay
      );
    }
    return this.dayStores[dayNumber];
  }

  @action
  fetchDetails(id) {
    if (!this.detailRequest[id]) {
      this.detailRequest[id] = this.api
        .getDetails(this.masterStore.user, id)
        .then(data => {
          this.details[id] = new Excursion(data, this.masterStore);
          this.detailRequest[id] = null;
          return this.details[id];
        });
    }
    return this.detailRequest[id];
  }
}
