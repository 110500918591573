import Checkbox from './Checkbox';

class FormNegatedCheckbox extends Checkbox {
  get value() {
    return !this._ref.checked;
  }

  set value(value) {
    this._ref.checked = !value;
  }

  reset() {
    this._ref.checked = false;
  }

  get hasChanged() {
    return this._ref.checked !== !!this.props.checked;
  }
}

export default FormNegatedCheckbox;
