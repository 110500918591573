// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';

const LinkAsButton = ({ children, link, disabled, dark, big, newPage, onClick, asAnchor = false, ...props }) =>
  asAnchor ? (
    <a
      href={link}
      target={newPage ? '_blank' : '_self'}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={classNames({
        button: true,
        disabled,
        dark,
        big,
      })}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link
      to={link}
      target={newPage ? '_blank' : '_self'}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
      className={classNames({
        button: true,
        disabled,
        dark,
        big,
      })}
      {...props}
    >
      {children}
    </Link>
  );

LinkAsButton.propTypes = {
  children: PropTypes.node.isRequired,
  asAnchor: PropTypes.bool,
  link: PropTypes.string.isRequired, // Should the target be blank?
  newPage: PropTypes.bool,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default LinkAsButton;
