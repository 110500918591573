// @flow

const formatPrice = (
  value: ?number,
  alswaysDecimals: boolean = false
): string => {
  if (alswaysDecimals) return `${(value || 0).toFixed(2).replace('.', ',')} €`;
  if (!value) return '0 €';
  return value === Math.round(value)
    ? `${value} €`
    : `${value.toFixed(2).replace('.', ',')} €`;
};

export { formatPrice };
