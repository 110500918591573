// @flow

const openPdf = (link: string, print: boolean, winName: ?string) => {
  const win = window.open(link, winName);
  if (!win) return;
  if (print) {
    window.setTimeout(() => {
      win.print(); // does not work in firefox
    }, 1000);
  }
};

export { openPdf };
