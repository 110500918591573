// @flow
import { computed } from 'mobx';

import Bookable from './Bookable';
import MasterStore from '../stores/MasterStore';

import type ItineraryDay from './ItineraryDay';
import type { INavigation } from '../types/bookable';
import type { IBookableVacancy } from '../types/vacancies';
import type BookedPackage from './BookedPackage';

export default class SpaOffer extends Bookable {
  price: number;
  priceSea: number;
  pricePort: number;
  typeId: string;
  spaId: number;
  title: string;
  description: string;
  hint: ?string;
  image: string;
  numberOfParticipants: number;
  duration: number;
  minimumAge: ?number;
  navigation: INavigation;
  vacancies: IBookableVacancy[];

  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);
    this.name = this.headline || this.name;
    this.price = this.price || this.priceSea || this.pricePort;
    this.title = this.title || this.headline;
  }

  // @TODO: Check if this really is used, because offers should be in bookings, not in packages
  @computed
  get fromData(): BookedPackage {
    return this.masterStore.packagesOfUser.find(
      pkg => pkg.typeId === this.typeId
    );
  }

  get isBooked(): boolean {
    return !!this.fromData || this.statusCode === 6;
  }

  get isBookable(): boolean {
    return this.statusCode === 0;
  }

  get prelimenary(): boolean {
    return this.fromData && this.fromData.prelimenary;
  }

  @computed
  get isStartingPrice(): boolean {
    return Boolean(this.priceSea && this.pricePort);
  }

  @computed
  get smallestPrice(): number {
    return Math.min(
      this.priceSea || this.pricePort,
      this.pricePort || this.priceSea
    );
  }

  get imageSrc(): string {
    return this.image;
  }

  get bookingId(): number {
    return this.spaId;
  }

  get displayDuration(): string {
    return `${this.duration} Minuten`;
  }

  get pdfLink(): string {
    return this.navigation && this.navigation.url;
  }

  priceOnDay(itineraryDay: ?ItineraryDay): number {
    if (itineraryDay) {
      // SUPTUICMRS-2238
      const vacancy = this.vacancyOfDay(itineraryDay);
      if (vacancy && vacancy.price) {
        return vacancy.price;
      }
    }

    if (this.isStartingPrice && itineraryDay) {
      return itineraryDay.isSeaDay ? this.priceSea : this.pricePort;
    }

    return this.price;
  }

  vacancyOfDay(itineraryDay: ItineraryDay): IBookableVacancy | null {
    return this.vacancies.find(d => d.date === itineraryDay.date) || null;
  }

  isBookableOnDay(itineraryDay: ?ItineraryDay): boolean {
    if (!itineraryDay) return false;
    const vacancy = this.vacancyOfDay(itineraryDay);

    if (!vacancy) return false;

    return vacancy.vacancies > 0;
  }

  getBookableStatusForDay(itineraryDay: ?ItineraryDay): string {
    if (!this.isBookable && this.statusCode !== 6) return this.bookingStatus;
    if (!this.isBookableOnDay(itineraryDay))
      return 'Derzeit nicht reservierbar';
    return '';
  }
}
