// @flow
import { observable, computed, action } from 'mobx';
import union from 'lodash/union';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';

import BookingRequestBase from './BookingRequestBase';
import { BOOKING_ADDED, BOOKING_UNCHANGED, BOOKING_DELETED } from './constants';

import type PartyMember from '../../models/PartyMember';
import type DailyEvent from '../../models/DailyEvent';
import type {
  BookingType,
  IGeneralBookingData,
  IGeneralItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
} from '../../types/booking';

// This is also similar with ExcursionBookingRequest
export default class DailyEventBookingRequest extends BookingRequestBase {
  static TEXTS = {
    ...BookingRequestBase.TEXTS,
    create: {
      ...BookingRequestBase.TEXTS.create,
      success: {
        ...BookingRequestBase.TEXTS.create.success,
        text:
          'Sobald Ihre Anfrage von uns bearbeitet wurde, wird diese in Ihrem Reiseplan angezeigt.',
      },
    },
  };

  @observable selectedMpis: number[] = [];

  constructor(travelParty: PartyMember[], bookable: DailyEvent) {
    super(travelParty, bookable);
    if (bookable) {
      this.selectedMpis = bookable.bookedMpis;
    }
  }

  @action
  resetRequest() {
    this.selectedMpis = this.bookable.bookedMpis;
  }

  @computed
  get date(): string {
    return this.bookable.day ? this.bookable.day.date : '';
  }

  getPrice(mpi: number) {
    const member = this.travelParty.find(m => m.mpi === mpi);
    return member ? this.getPriceForMember(member) : 0;
  }

  getPriceForMember(member: PartyMember): number {
    // if only the adult price is set, it should be displayed for all kind of members (incl. babies)
    if (this.bookable.priceChild === null) return this.bookable.priceAdult;
    if (member._isBaby(member.ageOnDate(this.bookable.date))) return 0;
    if (member._isChild(member.ageOnDate(this.bookable.date))) {
      return this.bookable.priceChild;
    }
    return this.bookable.priceAdult;
  }

  @action
  selectParticipants(mpi: number, selected: boolean) {
    this.selectedMpis = selected
      ? union(this.selectedMpis, [mpi])
      : without(this.selectedMpis, mpi);
  }

  @action
  cancelBooking() {
    this.selectedMpis = [];
    this.bookable.bookedMpis.forEach(mpi => {
      this.selectParticipants(mpi, false);
    });
  }

  @computed
  get isChangeRequest(): boolean {
    if (!this.bookable.isBooked) return false;

    if (this.unchangedItems.length > 0) {
      return this.addedItems.length > 0 || this.deletedItems.length > 0;
    } else {
      return this.addedItems.length > 0 && this.deletedItems.length > 0;
    }
  }

  @computed
  get isCancellation(): boolean {
    return (
      this.bookable.isBooked &&
      this.unchangedItems.length === 0 &&
      this.addedItems.length === 0 &&
      this.deletedItems.length > 0
    );
  }

  // private
  @computed
  get unchangedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter(b => b.type === BOOKING_UNCHANGED);
  }

  // private
  @computed
  get addedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter(b => b.type === BOOKING_ADDED);
  }

  // private
  @computed
  get deletedItems(): IGeneralItemToBook[] {
    return this.itemsToBook.filter(b => b.type === BOOKING_DELETED);
  }

  createItemToBook(mpi: number, type: BookingType): IGeneralItemToBook {
    const member = this.travelParty.find(p => p.mpi === mpi);
    const price = member && this.getPriceForMember(member);
    return {
      mpi: member ? member.mpi : 0,
      name: member ? member.displayName : '',
      info: member ? member.displayAgeOnDate(this.bookable.date) : '',
      price: price,
      error: this.errors ? this.errors[mpi] : null,
      quantity: 1,
      type,
    };
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] {
    const items = [];
    const selectedMpis = this.selectedMpis.slice();
    const bookedMpis = this.bookable.bookedMpis;
    this.travelParty.map(p => p.mpi).forEach(mpi => {
      const isSelected = includes(selectedMpis, mpi);
      const isBooked = includes(bookedMpis, mpi);
      if (isBooked && isSelected) {
        items.push(this.createItemToBook(mpi, BOOKING_UNCHANGED));
      } else if (isBooked) {
        items.push(this.createItemToBook(mpi, BOOKING_DELETED));
      } else if (isSelected) {
        items.push(this.createItemToBook(mpi, BOOKING_ADDED));
      }
    });
    return items;
  }

  @computed
  get total(): ?number {
    if (this.selectedMpis.every(mpi => this.getPrice(mpi) === null)) {
      return null;
    }
    return this.selectedMpis.reduce(
      (total, mpi) => total + this.getPrice(mpi),
      0
    );
  }

  @computed
  get dataHasChanged(): boolean {
    return !isEqual(Array.from(this.selectedMpis), this.bookable.bookedMpis);
  }

  @computed
  get isReady(): boolean {
    return (
      this.dataHasChanged &&
      (this.selectedMpis.length > 0 || this.bookable.isBooked)
    );
  }

  @computed
  get bookingData(): IGeneralBookingData[] {
    return this.addedItems.length > 0
      ? [
          {
            type: 'event',
            vacancyId: this.bookable.vacancyId,
            participants: this.addedItems.map(b => b.mpi),
          },
        ]
      : [];
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.bookable.isBooked) return [];

    const mpisToCancel = this.deletedItems.map(b => b.mpi);
    const bookedIds = this.bookable.booking.bookingId;

    return mpisToCancel.map(mpi => ({
      type: 'event',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const { name, bookingId, analyticsCategory, date } = this.bookable;
    let products = [];
    if (!this.isCancellation) {
      const addedMembers = this.travelParty.filter(m =>
        includes(this.selectedMpis, m.mpi)
      );
      const adultsCount = addedMembers.filter(
        m => !m._isChild(m.ageOnDate(date))
      ).length;
      const childrenCount = addedMembers.filter(m =>
        m._isChild(m.ageOnDate(date))
      ).length;

      if (adultsCount > 0) {
        products.push({
          id: `${bookingId}`,
          name,
          category: analyticsCategory,
          quantity: adultsCount,
          price: this.bookable.priceAdult,
        });
      }
      if (childrenCount > 0) {
        products.push({
          id: `${bookingId}`,
          name,
          category: analyticsCategory,
          quantity: childrenCount,
          price:
            this.bookable.priceChild === null
              ? this.bookable.priceAdult
              : this.bookable.priceChild,
        });
      }
    }

    return {
      products,
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}-${this.selectedMpis.length}`,
              revenue: this.total,
            },
          ],
      refunds: this.isCancellation
        ? [
            {
              id: `${bookingId}-${this.bookable.bookedMpis.length}`,
            },
          ]
        : [],
    };
  }
}
