// @flow

import React from 'react';
import styled from 'styled-components';

import Scrim from './MenuScrim';
import Mobile from './Mobile';
import Desktop from './Desktop';
import MenuButton from './MenuButton';
import LockBodyScroll from '../../LockBodyScroll';

import { type NavigationItemList } from '../../../api/navigation';

import { dimensions } from '../styles';

const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${dimensions.mobileL}px) {
    position: static;
    display: block;
  }
`;

interface Props {
  userName?: string;
  open: boolean;
  navigation: NavigationItemList;
}

export default function Menu({ userName, open = false, navigation }: Props) {
  return (
    <MenuWrapper>
      <MenuButton open={open} userName={userName} />
      <Scrim open={open} />
      {open && <LockBodyScroll />}
      <Mobile open={open} userName={userName} navigation={navigation} />
      <Desktop open={open} navigation={navigation} />
    </MenuWrapper>
  );
}
