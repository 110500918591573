// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import type Route from '../models/Route';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (): string =>
  (mock || mockAll) && isLocal ? `/mock/route/route.json` : `/api/land/route`;

export default {
  get: (user: User, params: string) =>
    fetchForUser(user, getUrl(), {
      method: 'POST',
      body: JSON.stringify({
        representation: params,
      }),
    }),
};

export type ApiType = {
  get: (User, string) => Promise<Route>,
};
