// @flow
import { observable, computed } from 'mobx';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

import { parseDate, DATE_FORMAT_DOTTED } from '../../utils/date';
import ServiceItem from './ServiceItem';
import PartyMember from '../../models/PartyMember';

const EMPTY_EXPENSES_LIST = {
  total: 0,
  totalText: '',
  list: [],
};

type ExpensesList = {|
  total: number,
  totalText: string,
  totalText: string,
  list: ServiceItem[],
  fineprint?: ?string,
|};

type CabinInfo = {
  cabin: string,
  deck: number,
  type: string,
  isJuniorSuite: boolean,
  isRegularSuite: boolean,
  isSuite: boolean,
};

export type FlightInfoType = {
  type: 'airplane',
  headline: ?string,
  hint: ?string,
  airline: string,
  arrival: string,
  date: string,
  departure: string,
  flight: string,
  from: string,
  fromName: string,
  info: ?string,
  name: string,
  seat: string,
  to: string,
  toName: string,
  via: ?string,
  viaName: ?string,
};

export type TrainInfoType = {
  type: 'train',
  headline: ?string,
  hint: ?string,
  arrival: ?string,
  date: string,
  departure: ?string,
  from: string,
  fromName: string,
  info: ?{},
  name: string,
  seat: ?string,
  to: string,
  toName: string,
  via: ?string,
  viaName: ?string,
};

export type BusInfoType = {
  type: 'bus',
  headline: ?string,
  hint: ?string,
  arrival: ?string,
  date: string,
  departure: ?string,
  from: string,
  fromName: string,
  info: ?string,
  name: string,
  seat: ?string,
  to: string,
  toName: string,
  via: ?string,
  viaName: ?string,
};

// export type ArrivalDepartureSegmentType =
//   | FlightInfoType
//   | TrainInfoType
//   | BusInfoType;

// export type ArrivalDepartureTypeType = 'train' | 'airplane' | 'bus';

// export type ArrivalDepartureInfoType = {
//   type: string,
//   inbound: ?(ArrivalDepartureSegmentType[]),
//   outbound: ?(ArrivalDepartureSegmentType[]),
// };

type BoardAccountBookingDetails = {
  name: string,
  mpi: number,
  cabinInfo: CabinInfo,
  // arrivalDepartureInfo: ArrivalDepartureInfoType,
};

import type {
  BoardAccountDetails,
  BoardAccountTravelprice,
  BoardAccountAddons,
} from '../../api/boardAccount';

const dateSorter = (obj: { date: string }) =>
  parseDate(obj.date.split(' ')[0], DATE_FORMAT_DOTTED);

const mapCabinInfo = c => ({
  ...c,
  cabin: parseInt(c.cabin, 10),
});

export default class BoardAccount {
  @observable details: BoardAccountBookingDetails[];
  travelName: string;
  bookingTableHeadline: string;
  includedBookingTableHeadline: string;
  travelPeriod: string;
  additionalBookings: ExpensesList;
  travelExpenses: ExpensesList;
  travelParty: PartyMember[];
  includedBookings: {
    list: ServiceItem[],
  };

  constructor(
    details: BoardAccountDetails,
    addons: BoardAccountAddons,
    expenses: BoardAccountTravelprice,
    travelParty: PartyMember[]
  ) {
    this.details = details.bookingDetails;
    this.travelName = details.travelName;
    this.travelPeriod = details.travelPeriod;

    const additionalBookings = addons && addons.additionalBookings;
    const includedBookings = addons && addons.includedBookings;

    this.bookingTableHeadline = addons.headline;
    this.includedBookingTableHeadline = addons.includedHeadline;

    this.additionalBookings = additionalBookings
      ? {
          total: additionalBookings.amountTotal,
          totalText: additionalBookings.amountTotalText,
          list: sortBy(
            additionalBookings.serviceList.map(
              item => new ServiceItem(item, travelParty)
            ),
            [dateSorter]
          ),
          fineprint:
            additionalBookings.fineprint && additionalBookings.fineprint.length
              ? additionalBookings.fineprint[0]
              : null,
        }
      : EMPTY_EXPENSES_LIST;

    this.includedBookings = includedBookings
      ? {
          list: includedBookings.serviceList.map(
            item => new ServiceItem(item, travelParty)
          ),
        }
      : { list: [] };

    this.travelExpenses = expenses
      ? {
          total: expenses.amountTotal,
          totalText: expenses.amountTotal ? expenses.amountTotalText : '',
          list: sortBy(
            expenses.serviceList.map(
              item => new ServiceItem(item, travelParty)
            ),
            [dateSorter]
          ),
        }
      : EMPTY_EXPENSES_LIST;
    this.travelParty = travelParty;
  }

  @computed
  get cabins(): CabinInfo[] {
    const cabins = uniqBy(
      this.details.map(d => d.cabinInfo).map(mapCabinInfo),
      'cabin'
    );

    // Resort flex cabins to the end
    const flexCabin = cabins.find(c => c.cabin === 0);
    if (flexCabin) {
      return [...cabins.filter(c => c.cabin !== 0), flexCabin];
    }
    return cabins;
  }

  @computed
  get cabinTypes(): string[] {
    const types = this.cabins.map(c => c.type);

    // If all cabins are of same type, we just show that type once
    if (uniq(types).length === 1) {
      return uniq(types);
    }
    return types;
  }

  @computed
  get cabinNumbers(): string[] {
    return this.cabins.map(c => c.cabin);
  }
}
