// TODO: add flow types

// changed 2017-12-05 errors from object to string
const transformErrors = response => {
  // const result = {};
  let result = '';
  response.forEach(failure => {
    // failure.mpis.forEach((mpi) => { result[mpi] = failure.message; });
    result = failure.message;
  });
  return result;
};

export default class BookingStore {
  masterStore = null;
  api = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  handleBookingRequest(bookingRequest) {
    bookingRequest.cleanErrors();
    bookingRequest.markAsRequesting(true);
    return this.api
      .save(this.masterStore.user, this.masterStore.masterData, {
        toBook: bookingRequest.bookingData,
        toCancel: bookingRequest.cancelData,
      })
      .then(
        response => {
          bookingRequest.markAsRequesting(false);
          this.masterStore.reloadMasterData();
          if (response.failed && response.failed.length > 0) {
            bookingRequest.setErrors(transformErrors(response.failed));
            return false;
          } else {
            bookingRequest.markAsSuccessful();
            return true;
          }
        },
        () => {
          bookingRequest.markAsRequesting(false);
          bookingRequest.setErrors(
            'Ihre Reservierungsanfrage konnte leider nicht bearbeitet werden. Bitte wenden Sie sich an Ihre Reiseagentur.'
          );
        }
      );
  }
}
