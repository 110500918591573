// @flow
import Excursion from './Excursion';
import MasterStore from '../stores/MasterStore';

export default class ExcursionList {
  items: Excursion[];

  constructor(items: Object[] = [], masterStore: MasterStore) {
    this.items = items.map(item => new Excursion(item, masterStore));
  }

  filteredItems(filter: {
    categoryIds: ?(number[]),
    categoryId: ?number,
    difficulties: number[],
  }) {
    return this.items.filter(item => item.matches(filter));
  }
}
