// @flow

import React, { Component } from 'react';
import format from 'date-fns/format';
import { computed } from 'mobx';

import EventList from './EventList';
import SectionsList from '../lists/SectionsList';

import type { EventDay } from '../../models/CatalogEventDaysList';
import type Booking from '../../models/Booking';

type Props = {
  days: EventDay[],
  bookings: { [string]: Booking[] },
};

export default class DailyCatalogList extends Component<Props> {
  @computed get aDayContainsData(): boolean {
    return !!this.props.days.find((day) => day.filteredEvents.length > 0);
  }

  @computed get sections(): any {
    const { days, bookings } = this.props;
    return days.map((day) => ({
      header: day.displayDate,
      content: day.filteredEvents.length ? (
        <EventList
          urlBase="/programm"
          bookings={bookings[format(day.date, 'YYYY-MM-DD')] || []}
          events={day.filteredEvents}
        />
      ) : (
        <p>Für Ihre Auswahl sind an diesem Tag keine Programmpunkte verfügbar.</p>
      ),
    }));
  }

  render() {
    if (!this.aDayContainsData) return <p>Für Ihre Auswahl sind an diesem Tag keine Programmpunkte verfügbar.</p>;

    return <SectionsList sections={this.sections} />;
  }
}
