// TODO: add flow types

import { observable, action } from 'mobx';

export default class EmailStore {
  @observable sending = false;
  @observable saved = false;
  @observable errornous = false;

  masterStore = null;
  api = null;

  constructor(api, masterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action
  sendEmail(email) {
    this.sending = true;
    return this.api.send(this.masterStore.user, email).then(
      action(() => {
        this.sending = false;
        this.saved = true;
      }),
      action(() => {
        this.sending = false;
        this.errornous = true;
      })
    );
  }
}
