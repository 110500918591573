// @flow
import { assign, required } from '../../utils/model';

export default class AppStoreButton {
  url: string;
  imageUri: string;
  label: string;

  constructor(data: { url: string, imageUri: string, label: string }) {
    assign(this, data, {
      url: required(String),
      imageUri: required(String),
      label: required(String),
    });
  }

  get imageSrc(): string {
    return this.imageUri;
  }
}
