// @flow

import fetchForUser from '../utils/fetchForUser';
import sanitized from '../utils/apiSanitizer';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (masterData: MasterData, id: string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/highlights_TUICUNIT-2920.json`
    : `/api/land/highlight/${id}/${masterData.ed}/${masterData.dd}/${masterData.shipId}`;

export const sanitizers = {
  get: data => {
    // If there are no highlights on this trip it somehow returns an empty array
    // We need an error to show a not found page
    if (!data.headline) throw new Error('No Highlights');
    return data;
  },
};

export default sanitized(
  {
    get: (user: User, masterData: MasterData, id: string) => fetchForUser(user, getUrl(masterData, id)),
  },
  sanitizers
);
