export const l10n = {
  survey: {
    de: {
      hintHeadline: 'Zusatzinformation',
      prev: 'Zurück',
      next: 'Weiter',
      save: 'Angaben speichern',
    },
    en: {
      hintHeadline: 'Additional information',
      prev: 'Back',
      next: 'Continue',
      save: 'Save data',
    },
  },
  surveyResult: {
    de: {
      headlineOk: 'Angaben vollständig erfasst',
      headlineNotOk: 'Wichtiger Hinweis:',
      notOkText:
        'Die Auswertung Ihrer individuellen Antworten hat ergeben, dass Sie mindestens ein Kriterium unserer strengen Gesundheitsanforderungen für Ihre Kreuzfahrt nicht erfüllen. Bedauerlicherweise können wir Ihnen daher eine Mitreise an Bord nicht ermöglichen. Bitte wenden Sie sich für Rückfragen oder Informationen zum weiteren Vorgehen an Ihre Buchungsstelle.',
      answerConfirm: 'Bestätigt',
      submitted: 'Zuletzt geändert',
      timeUnit: 'Uhr',
      edit: 'Angaben aktualisieren',
    },
    en: {
      headlineOk: 'Successfully completed',
      headlineNotOk: 'Important notice:',
      notOkText:
        'The evaluation of your responses revealed, that at least one criteria of our strict health & safety requirements for your cruise is not being fulfilled. Unfortunately, we will therefore not be able to accommodate you on board. For further enquiries or information regarding the next steps we kindly ask you to contact your respective booking agent.',
      answerConfirm: 'Confirmed',
      submitted: 'Last changed',
      timeUnit: 'h',
      edit: 'Change data',
    },
  },
  confirm: {
    de: {
      headline: 'Bitte überprüfen Sie Ihre Angaben',
      text: 'Sind Ihre Angaben im Gesundheitsfragebogen noch aktuell?',
      buttonYes: '<span class="bold">Ja,</span> ich bestätige die Aktualität',
      buttonNo: '<span class="bold">Nein,</span> Angaben aktualisieren',
    },
    en: {
      headline: 'Please check your data',
      text: 'Is your health questionnaire still up to date?',
      buttonYes: '<span class="bold">Yes,</span> confirm data',
      buttonNo: '<span class="bold">No,</span> change data',
    },
  },
};
