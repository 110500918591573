// TODO: add flow types

import omit from 'lodash/omit';

import { authStore } from './stores/AuthenticationStore';
import storage from './utils/storage';
import { getUrlParameterByName } from './utils/url';
import { updateOldLoginCookie } from './utils/useOldLogin';

export function requireAuthentication(nextState, replace, next) {
  storage.remove('login-redirect');
  authStore
    .fetchAuthenticatedUser()
    .then((user) => {
      if (!user) {
        storage.set('login-redirect', `${window.location.pathname}${window.location.search}`);
        replace(`/login${window.location.search}`);
      }
      next();
    })
    .catch(() => {
      storage.set('login-redirect', `${window.location.pathname}${window.location.search}`);
      replace(`/login${window.location.search}`);
      next();
    });
}

export function rebuildUrlWithoutLoginInfo(pathname, query) {
  let queryString = Object.keys(
    omit(query, ['firstName', 'lastName', 'bookingNumber', 'useNewLogin', 'backLink', 'tripCode', 'pin'])
  )
    .reduce((memo, name) => [...memo, `${name}=${encodeURIComponent(query[name])}`], [])
    .join('&');

  return queryString ? `${pathname}?${queryString}` : pathname;
}

// This logs user in if the user provides valid credentials via query parameter
export function doParameterLogin(nextState, replace, next) {
  const query = nextState.location.query;
  const tripCode = getUrlParameterByName('tripCode');

  const useOldLogin = nextState.location.query.useOldLogin;

  const redirect = () => {
    replace(rebuildUrlWithoutLoginInfo(nextState.location.pathname, nextState.location.query));
    next();
  };

  const logout = (err) => {
    authStore.logout().then(() => {
      window.location.href = `/login?loginFailed=${encodeURIComponent(err.detail)}`;
    });
  };

  if (useOldLogin) {
    updateOldLoginCookie(useOldLogin === 'true');
  }

  if (tripCode) {
    redirect();
  } else if (query.firstName && query.lastName && query.bookingNumber) {
    authStore
      .login(query.firstName, query.lastName, query.bookingNumber, undefined, query.useNewLogin === 'true')
      .then(redirect)
      .catch(logout);
  } else {
    next();
  }
}
