// @flow

import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import Price from '../Price';
import Button from '../Button';

import type PartyMember from '../../models/PartyMember';
import { type IMpiReservationStatus } from '../../api/includedShoreEx';
import IncludedShoreExDetail from '../../models/IncludedShoreEx/IncludedShoreExDetail';
import IncludedShoreExDetailStatus from '../../models/IncludedShoreEx/IncludedShoreExDetailStatus';

import {
  ATTENDS,
  NOT_ATTENDS,
  PRELIMINARY,
  INCOMPLETE,
  BOOKABLE,
  NOT_MORE_BOOKABLE,
  NOT_YET_BOOKABLE,
} from '../../models/IncludedShoreEx/constants';

type Props = {
  details: IncludedShoreExDetail,
  status: IncludedShoreExDetailStatus,
  travelParty: PartyMember[],
  onBooking: ?Function,
  onChangeBooking: ?Function,
  showParticipants: ?Function,
};

type State = {
  showParticipants: ?boolean,
};

type tParticipant = IMpiReservationStatus & {
  label: string,
};

@observer
export default class InclusiveShoreExBookingAssistant extends Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showParticipants: props.showParticipants,
    };
  }

  @autobind
  openParticipants() {
    this.setState({ showParticipants: true });
  }

  renderDisabledButton() {
    const { excursionStatusText: statusText } = this;

    return (
      <Button className="l-mod-sub full-width" disabled dark big>
        {statusText}
      </Button>
    );
  }

  renderDefaultView() {
    return <Price label="Inklusivleistung" className="inclusive" price="" />;
  }

  @autobind
  handleBookingClick() {
    const { onBooking, onChangeBooking } = this.props;

    if (this.hasParticipationInfo) {
      if (onChangeBooking) onChangeBooking();
    } else {
      if (onBooking) onBooking();
    }
  }

  get excursionStatusText() {
    const { status } = this.props;
    const { inclusiveBooking } = status;
    const { statusText } = inclusiveBooking;
    return statusText;
  }

  get excursionStatus() {
    const { status } = this.props;
    const { inclusiveBooking } = status;
    const { status: excursionStatus } = inclusiveBooking;
    return excursionStatus;
  }

  filterMpisByStatusCode(excursionStatus: string): tParticipant[] {
    const { travelParty, status } = this.props;
    const { mpiBookingList } = status;

    return mpiBookingList
      .filter(mpiStatus => mpiStatus.status === excursionStatus)
      .map(mpiStatus => {
        const partyMember = travelParty.find(
          member => member.mpi === mpiStatus.mpi
        );
        const { displayName: label } = partyMember || {
          displayName: 'unbekannt',
        };
        return {
          ...mpiStatus,
          label,
          bookedDate: status.getVacancyDate(mpiStatus.bookingOptionId),
        };
      });
  }

  get mpisWithPreliminaryState(): tParticipant[] {
    return this.filterMpisByStatusCode(PRELIMINARY);
  }

  get mpisWithoutDeclaration(): tParticipant[] {
    return this.filterMpisByStatusCode(INCOMPLETE);
  }

  get mpisWithBooking(): tParticipant[] {
    return this.filterMpisByStatusCode(ATTENDS);
  }

  get mpisWithWaiver(): tParticipant[] {
    return this.filterMpisByStatusCode(NOT_ATTENDS);
  }

  get hasParticipationInfo(): boolean {
    return this.mpisWithBooking.length >= 1 || this.mpisWithWaiver.length >= 1;
  }

  get isPreliminary() {
    return (
      this.excursionStatus === PRELIMINARY ||
      this.mpisWithPreliminaryState.length >= 1
    );
  }

  get isBookable() {
    return this.excursionStatus === BOOKABLE;
  }

  get isNotMoreBookable(): boolean {
    return this.excursionStatus === NOT_MORE_BOOKABLE;
  }

  get isNotYetBookable(): boolean {
    return this.excursionStatus === NOT_YET_BOOKABLE;
  }

  get isErrorState() {
    const excursionStatus = this.excursionStatus;

    const result = excursionStatus === undefined || excursionStatus === null;
    result &&
      console.warn(
        'InclusiveShoreExBookingAssistant statusCode was undefined or null',
        typeof excursionStatus
      );

    return result;
  }

  renderNotBookable() {
    return (
      <div>
        <div>
          {this.renderDisabledButton()}
          {this.isNotMoreBookable && (
            <p>
              Bitte beachten Sie, dass die Reservierungsfrist bereits abgelaufen
              ist und eine Buchung nur noch an Bord möglich ist.
            </p>
          )}
        </div>
      </div>
    );
  }

  renderParticipantCategory(
    participants: Object[],
    label: string,
    participantsWrapper: any
  ) {
    if (participants.length <= 0) return;

    const participantsList = participants => (
      <ul className="participant-list">
        {participants.map((participant, index) => (
          <li key={index}>{participant.label}</li>
        ))}
      </ul>
    );

    return (
      <div className="participant-category-list">
        <h2>{label}</h2>
        {participantsWrapper
          ? participantsWrapper(participants, participantsList)
          : participantsList(participants)}
      </div>
    );
  }

  renderParticipants() {
    return (
      <div className="form-checkbox-fakegroup">
        <div>
          {this.renderParticipantCategory(
            this.mpisWithoutDeclaration,
            'Ohne Reservierung'
          )}

          {this.renderParticipantCategory(
            this.mpisWithBooking,
            'Reservierung',
            (options, participantsList) => {
              const dateGroups = new Set(
                options.map(option => option.bookedDate)
              );

              return (
                <ul>
                  {Array.from(dateGroups.values()).map((date, index) => (
                    <li key={index}>
                      <div className="date">{date}</div>
                      {participantsList(
                        options.filter(option => option.bookedDate === date)
                      )}
                    </li>
                  ))}
                </ul>
              );
            }
          )}

          {this.renderParticipantCategory(
            this.mpisWithWaiver,
            'Teilnahme abgesagt'
          )}
        </div>

        {this.isBookable && !this.isPreliminary ? (
          <div className="l-right">
            <Button
              dark
              big
              className="l-mod-sub"
              disabled={this.isPreliminary}
              onClick={this.handleBookingClick}
            >
              {this.hasParticipationInfo ? 'Bearbeiten' : 'Zur Reservierung'}
            </Button>
          </div>
        ) : (
          this.renderNotBookable()
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="l-col right">
        {this.renderDefaultView()}
        {!this.isErrorState ? (
          this.renderParticipants()
        ) : (
          <p>
            Entschuldigung, es gibt leider ein Problem mit dem Buchungssystem,
            bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.
          </p>
        )}
      </div>
    );
  }
}
