// @flow
import Input from './Input';
import { autobind } from 'core-decorators';

type Props = {
  validate?: ?(value: ?string, newValue: ?string) => ?string,
};

class RestrictedInput extends Input<Props> {
  @autobind
  onChange(event: Object) {
    const { value } = this.state;
    const { validate, maxLength } = this.props;
    const newValue = maxLength
      ? event.target.value.substr(0, maxLength)
      : event.target.value;
    const v = validate(value, newValue);
    this.value = v;
    this.setState({
      value: v,
    });
  }
}

export default RestrictedInput;
