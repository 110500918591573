// @flow

import { observable, action, computed } from 'mobx';

import AppStoreButton from '../models/Login/AppStoreButton';
import AppStoreTeaser from '../models/Login/AppStoreTeaser';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, ApiResponse } from '../api/appStoreTiles';

export default class AppTilesStore {
  @observable buttons = null;
  @observable teaser = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.teaser === null;
  }

  @action
  fetch() {
    this.api.get().then((response: ApiResponse) => {
      this.receiveResponse(response);
    });
  }

  @action
  receiveResponse(response: ApiResponse) {
    try {
      this.teaser = new AppStoreTeaser(response);
    } catch (exc) {
      // ...
    }

    if (response && response.buttons) {
      this.buttons = response.buttons.map(button => new AppStoreButton(button));
    }
  }
}
