// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';
import type { ApiType } from '../api/spaBalconyCabin';

import SpaBalconyCabin from '../models/SpaBalconyCabin';
import SpaTreatmentStatusList from '../models/SpaBalconyCabin/SpaTreatmentStatusList';

export default class SpaBalconyCabinStore {
  @observable spaBalconyCabin: ?SpaBalconyCabin = null;
  @observable spaTreatmentStatusList: ?SpaTreatmentStatusList = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.spaBalconyCabin === null;
  }

  fetchSpaBalconyCabin(): Promise<void> {
    return this.api
      .getSpaBalconyCabin(this.masterStore.user)
      .then((spaBalconyCabin: SpaBalconyCabin) => {
        this.receiveSpaBalconyCabin(spaBalconyCabin);
      });
  }

  fetchSpaTreatmentStatusList(spaId: number): Promise<void> {
    return this.api
      .getSpaTreatmentStatusList(this.masterStore.user, spaId)
      .then((spaTreatmentStatusList: SpaTreatmentStatusList) => {
        this.receiveSpaTreatmentStatusList(spaTreatmentStatusList);
      });
  }

  @action
  receiveSpaBalconyCabin(spaBalconyCabin: SpaBalconyCabin) {
    this.spaBalconyCabin = new SpaBalconyCabin(
      spaBalconyCabin,
      this.masterStore
    );
  }

  @action
  receiveSpaTreatmentStatusList(
    spaTreatmentStatusList: SpaTreatmentStatusList
  ) {
    this.spaTreatmentStatusList = new SpaTreatmentStatusList(
      spaTreatmentStatusList
    );
  }
}
