// @flow

import React from 'react';
import classNames from 'classnames';

import { formatPrice } from '../utils/price';

type Props = {
  price: ?string | ?number,
  // If we the price is only valid for something (kind of a prefix)
  label?: ?string,
  // If we the price is calculated per unit, add the unit here
  unit?: ?string,
  className?: ?string,
};

const Price = ({ price, label, unit, className }: Props) => (
  <div
    className={classNames({
      price: true,
      ...(className ? { [className]: !!className } : {}),
    })}
  >
    {label ? <span className="price__label">{label}</span> : null}
    {price ? <span className="price__value">{typeof price === 'number' ? formatPrice(price) : price}</span> : null}
    {unit ? <span className="price__unit">/ {unit}</span> : null}
  </div>
);

export default Price;
