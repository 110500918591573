// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';
import SpaOfferListDetails from '../models/SpaOfferListDetails';
import type { ApiType, ApiResponse } from '../api/spaBeautySalon';
export const DEFAULT_FILTER = 'Alle Leistungen';

export default class SpaBeautySalonStore {
  @observable loading: boolean = false;
  @observable error: boolean = true;
  @observable data: ?ApiResponse = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  @action
  fetch() {
    const { user, masterData } = this.masterStore;
    this.loading = true;
    return this.api.getList(user, masterData).then((data: ApiResponse) => {
      this.receiveData(data);
    });
  }

  @action
  receiveData(data: ApiResponse) {
    let spaOffers = null;
    let categories = null;
    if (!data.error && data.spaOffers && Array.isArray(data.spaOffers)) {
      spaOffers = data.spaOffers.map(
        offer => new SpaOfferListDetails(offer, this.masterStore)
      );
      if (data.filter && Array.isArray(data.filter.categories)) {
        categories = [DEFAULT_FILTER].concat(data.filter.categories);
      }
    }
    this.data = {
      spaOffers: spaOffers,
      filter: {
        categories: categories,
      },
    };
    this.error = data.error || false;
    this.loading = false;
  }
}
