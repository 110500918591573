// @flow

export const parseJWT = (jwt: string) => {
  if (!jwt) return null;
  try {
    return JSON.parse(
      window.atob(
        jwt
          .split('.')[1]
          .replace(/-/g, '+')
          .replace(/_/g, '/')
      )
    );
  } catch (exc) {
    return null;
  }
};
