// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';
import { prepareText } from '../utils/prepareText';

type Props = {
  infos: { key: string, value: Node }[],
  spaced?: boolean,
};

const InfoList = ({ infos, spaced }: Props) => (
  <div
    className={classNames({
      'info-list': true,
      spaced,
    })}
  >
    {infos.map(info => (
      <div key={info.key} className="info-list__item">
        <div className="info-list__item__key">{info.key}</div>
        {typeof info.value === 'string' || info.value instanceof String ? (
          <div
            className="info-list__item__value"
            dangerouslySetInnerHTML={{
              __html: prepareText(info.value, ['mailto']),
            }}
          />
        ) : (
          <div className="info-list__item__value">{info.value}</div>
        )}
      </div>
    ))}
  </div>
);

export default InfoList;
