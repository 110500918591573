// @flow
import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, PdfMeta } from '../api/travelDocuments';

const DEFAULT_ERROR_MESSAGE =
  'Die Reiseunterlagen steht zur Zeit nicht zur Verfügung. Bitte versuchen Sie es später erneut.';

export type AdditionalType = {
  headline: string,
  text: string,
};

export type TravelTextType = {
  headline: string,
  subline: string,
  additional?: [AdditionalType] | null,
};

export type ErrorType = {
  status?: boolean,
  text: string,
};

export default class TravelDocumentsStore {
  @observable loading: boolean = true;
  @observable documents: PdfMeta[] | null = null;
  @observable user = null;
  @observable texte: TravelTextType = null;
  @observable
  error: ?ErrorType = {
    text: DEFAULT_ERROR_MESSAGE,
  };

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  fetchData() {
    return this.api.getDocumentsText(this.masterStore.user).then((result: ApiResponse) => {
      if (result && result.additional && !this.documents) {
        // has Documents to load
        this.api.getDocumentList(this.masterStore.user).then((resultList: ApiResponse) => {
          this.receiveText(result);
          this.receiveDocumentsList(resultList);
        });
      } else {
        // nope
        this.receiveText(result);
      }
    });
  }

  fetchPdf(pdfMeta: PdfMeta, asBlob: boolean = false) {
    return asBlob
      ? this.api.getDocumentPdfAsBlob(this.masterStore.user, pdfMeta)
      : this.api.getDocumentPdf(this.masterStore.user, pdfMeta);
  }

  fetchZip(fileName: string, asBlob: boolean = false) {
    return asBlob
      ? this.api.getDocumentsZipAsBlob(this.masterStore.user, fileName)
      : this.api.getDocumentsZip(this.masterStore.user, fileName);
  }

  @action
  receiveDocumentsList(result: ApiResponse) {
    if (Array.isArray(result)) {
      this.documents = result;
    } else {
      this.setError(result);
    }
    this.loading = false;
  }

  @action
  receiveText(result: ApiResponse) {
    if (result && result.headline) {
      this.texte = {
        headline: result.headline || null,
        subline: result.subline || null,
        additional: result.additional && Array.isArray(result.additional) ? result.additional : null,
      };
    } else {
      this.setError(result);
    }
    this.loading = false;
  }

  @action
  setError(result) {
    this.error = {
      status: result && result.status ? !!result.status : true,
      text: DEFAULT_ERROR_MESSAGE,
    };
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  @computed
  get hasError(): boolean {
    return this.error.status || false;
  }
}
