// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import { getPinHeader, isLocal } from './utils';

type InfoParams = {
  surveyId: number,
  embarkationDate: string,
  debarkationDate: string,
  shipId: string,
};

const dummyFiles = ['questionnaire', 'questionnaire_TUICUNIT-2704'];

const mock = false;
const mockInfo = false;

const getUrl = currentMemberMpi => {
  return mock && isLocal
    ? `/mock/digitalHealth/${dummyFiles[1]}.json`
    : `/api/booking/v1/check-in/questionnaire?mpi=${currentMemberMpi}`;
};

const getInfoUrl = (data: InfoParams) => {
  let params = `${data.surveyId}?=`;
  params = params + `embarkationDate=${data.embarkationDate}&`;
  params = params + `debarkationDate=${data.debarkationDate}&`;
  params = params + `shipId=${data.shipId}`;
  const url =
    mockInfo && isLocal
      ? `/mock/digitalHealth/questionnaire-info.json`
      : `/api/booking/v1/check-in/questionnaire-info/${params}`;

  return url;
};

export default {
  get: (user: User, currentMemberMpi: number) =>
    fetchForUser(user, getUrl(currentMemberMpi), {
      differentErrorCodes: true,
      ...getPinHeader(currentMemberMpi),
    }),
  save: (user: User, data: any) =>
    fetchForUser(user, `/api/booking/v1/check-in/questionnaire/answers`, {
      method: 'post',
      body: JSON.stringify(data),
      differentErrorCodes: true,
    }),
  info: (user: User, data: InfoParams) =>
    fetchForUser(user, getInfoUrl(data), {
      differentErrorCodes: true,
    }),
  confirm: (user: User, data: any) =>
    fetchForUser(user, `/api/booking/v1/check-in/questionnaire/confirmation`, {
      method: 'post',
      body: JSON.stringify(data),
      differentErrorCodes: true,
    }),
};
