// @flow

import React, { Component } from 'react';
import Accordion from '../Accordion';
import AccordionItem from '../AccordionItem';
import faqs from '../../models/ArrivalDeparture/static-data/faqs';

type Props = {
  types: Array<string>,
};
export default class Faq extends Component<Props> {
  render() {
    const { types } = this.props;

    let renderData = faqs;

    if (types.length !== 0) {
      renderData = faqs.filter(e => types.indexOf(e.type) !== -1);
    }

    return renderData.map((faq, i) => (
      <div key={i}>
        {renderData.length !== 1 ? (
          <h2 className="headline">{faq.headline}</h2>
        ) : null}
        <Accordion>
          {faq.section.map((f, j) => (
            <AccordionItem key={j} header={f.q} expanded={false}>
              <p dangerouslySetInnerHTML={{ __html: f.a }} />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    ));
  }
}
