// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { computed } from 'mobx';
import { observer } from 'mobx-react';

import LoadingIndicator from '../../../components/LoadingIndicator';
import Button from '../../../components/Button';
import ContentBox from '../../../components/ContentBox';
import LinkAsButton from '../../../components/LinkAsButton';
import CheckboxGroup from '../../../components/CheckboxGroup';
import PaymentSelector from '../../../components/Manifest/Payment/PaymentSelector';

import PaymentIcon from '../../../../assets/svg/inline/icon-zahlungsart.inline.svg';
import CheckIcon from '../../../../assets/svg/inline/ic-check-rund.inline.svg';
import HintIcon from '../../../../assets/svg/inline/ic-info.inline.svg';

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
import FormCheckbox from '../../form/Checkbox';
import FormInput from '../../form/Input';

type Props = {
  paymentCanNotBeChanged: boolean,
  storeRequest: PaymentStoreRequest,
  disabled: boolean,
  preliminary: boolean,
  isInAppView: boolean,
  isCheckinSubRoute: boolean,
  onSave: () => void,
};

@observer
export default class ManifestPaymentForm extends Component<Props> {
  @autobind
  handleCheckboxPayForOthers(value: boolean) {
    this.props.storeRequest.togglePayForOthers(value);
  }

  @autobind
  selectMember(mpi: number, value: boolean) {
    this.props.storeRequest.payForMember(mpi, value);
  }

  @autobind
  setDigitalInvoiceMail(value: string) {
    this.props.storeRequest.setDigitalInvoiceMail(value);
  }

  @autobind
  setDigitalInvoiceConsent(value: boolean) {
    this.props.storeRequest.setDigitalInvoiceConsent(value);
  }

  @autobind
  handleSave() {
    this.props.onSave();
  }

  @autobind
  showPaymentForm() {
    this.props.storeRequest.reenterPayingInfo();
  }

  @computed
  get isPayedAlready(): boolean {
    const { storeRequest } = this.props;
    return storeRequest.alreadyPaid;
  }

  renderPaymentForm() {
    const { storeRequest, isCheckinSubRoute, disabled, preliminary, isInAppView } = this.props;

    const { isPayedBySomeone, digitalInvoiceEnabled } = storeRequest;

    return (
      <div className="l-col double">
        {disabled && !preliminary && isPayedBySomeone && (
          <ContentBox title="Hinweis" titleIcon={<HintIcon />}>
            <p>
              Eine Änderung Ihrer Zahldaten ist nicht mehr möglich, da ein Gast aus Ihrer Zahlungsgruppe bereits
              eingecheckt ist. Um Ihre Zahldaten zu ändern wenden Sie sich bitte an Bord an die Rezeption.
            </p>
          </ContentBox>
        )}

        <PaymentSelector storeRequest={storeRequest} isInAppView={isInAppView} disabled={disabled} />

        {
          //TUICUNIT-2000: case: this person has already selected cash. we remove other travel party persons, so they are not available for add
          !storeRequest.isCash ? this.renderPayForOthers() : ''
        }

        {digitalInvoiceEnabled && this.renderPaperlessInvoice()}

        <div className="l-right">
          {storeRequest.isSending ? (
            <LoadingIndicator />
          ) : disabled ? (
            isCheckinSubRoute ? (
              <p>
                <LinkAsButton big dark link="/checkin">
                  Zur Check-in Übersicht
                </LinkAsButton>
              </p>
            ) : (
              <p>
                <LinkAsButton big dark link="/manifest">
                  Zur Manifest-Übersicht
                </LinkAsButton>
              </p>
            )
          ) : (
            <p>
              <Button dark big onClick={this.handleSave} disabled={!storeRequest.hasChanged}>
                Zahlungsdaten speichern
              </Button>
            </p>
          )}
        </div>
      </div>
    );
  }

  renderPayForOthers() {
    const { storeRequest, disabled } = this.props;
    if (!storeRequest.otherTravelPartyMember.length) {
      return null;
    }

    return (
      <div>
        <h2>Übernahme der Bordabrechnungen weiterer Gäste</h2>
        <p>
          Bitte wählen Sie die Gäste aus, deren Bordabrechnung ebenfalls mit der hier hinterlegten Zahlungsart beglichen
          werden soll:
        </p>
        <div className="l-row">
          {storeRequest.otherTravelPartyMember.map((member) => (
            <div className="l-col" key={member.mpi}>
              <CheckboxGroup.Item
                disabled={
                  !storeRequest.payForOthers ||
                  disabled ||
                  !storeRequest.paysForPossibleMpis.length ||
                  !storeRequest.paysForPossibleMpis.includes(member.mpi)
                }
                checked={storeRequest.payForMpis.includes(member.mpi)}
                value={member.mpi}
                onChange={(event) => this.selectMember(member.mpi, event.target.checked)}
              >
                {member.displayName}
              </CheckboxGroup.Item>
            </div>
          ))}
        </div>
        <p className="annotation">
          Sollte eine Person bereits eine eigene Zahlungsart hinterlegt haben oder jemand Drittes bereits die
          Bordabrechnung übernehmen, kann diese nicht mehr ausgewählt werden. Möchten Sie dennoch die Bordabrechnung für
          diese Person übernehmen, wenden Sie sich bitte an die Rezeption an Bord. Für eine getrennte Abrechnung der
          Bordleistung, hinterlegen Sie bitte im Schiffsmanifest der Person eine Zahlungsart. Möchten Sie für andere
          Personen, die nicht über Ihre eigene Buchung laufen (separate Vorgangsnummer), die Bordabrechnung übernehmen,
          wenden Sie sich bitte an Bord an die Mitarbeiter der Rezeption.
        </p>
      </div>
    );
  }

  renderPaperlessInvoice() {
    const { storeRequest } = this.props;
    const { digitalInvoiceMail, digitalInvoiceConsent, digitalInvoiceError } = storeRequest;

    return (
      <div>
        <h2>Zustellung Ihrer Bordabrechnung</h2>
        <p>
          Bitte tragen Sie hier eine E-Mail-Adresse ein, um Ihre Bordabrechnung am Ende der Reise zugeschickt zu
          bekommen.
          <br />
          Diese E-Mail-Adresse wird ausschließlich für die Zusendung der persönlichen Bordabrechnung verwendet.
        </p>
        {!!digitalInvoiceError && <p className="error-message">{digitalInvoiceError}</p>}
        <div className="l-row">
          <div className="l-col">
            <FormInput
              disabled={false}
              label="E-Mail Adresse"
              name="mail"
              value={digitalInvoiceMail}
              onChange={(value) => {
                if (value) {
                  value = value.trim();
                }

                this.setDigitalInvoiceMail(value);
              }}
            />
          </div>
        </div>
        <div>
          <FormCheckbox
            checked={digitalInvoiceConsent}
            disabled={false}
            name="viaMail"
            label="Ich möchte meine Bordabrechnung per E-Mail erhalten"
            onChange={(value) => this.setDigitalInvoiceConsent(value)}
          />
        </div>
      </div>
    );
  }

  renderAlreadyPaidNote() {
    const { storeRequest, isCheckinSubRoute } = this.props;
    const { isPayedBySomeone, payer } = storeRequest;

    if (!isPayedBySomeone || !payer) return; // make flow happy

    const alreadyPaidNote = `${payer.displayName} hat bereits angegeben Ihre Rechnung über ${
      payer.isMale ? 'seine' : 'ihre'
    } hinterlegte Zahlungsart zu bezahlen. Möchten Sie dennoch Ihre Bordabrechnung selbst übernehmen, muss die Übernahme Ihrer Bordabrechnung durch ${
      payer.displayName
    } im Bereich Zahlungsmittel aufgehoben werden.`;

    return (
      <div className="l-col double">
        <ContentBox title="Zahlungsart bereits hinterlegt" titleIcon={<CheckIcon />}>
          <div>
            <p>{alreadyPaidNote}</p>
          </div>
          {this.props.paymentCanNotBeChanged && !this.props.disabled ? (
            <Button onClick={this.showPaymentForm}>Eigene Zahlungsart hinterlegen</Button>
          ) : null}
        </ContentBox>

        <div className="l-right">
          {isCheckinSubRoute ? (
            <p>
              <LinkAsButton link="/checkin">Zur Check-in Übersicht</LinkAsButton>
            </p>
          ) : (
            <p>
              <LinkAsButton link="/manifest">Zur Manifest-Übersicht</LinkAsButton>
            </p>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { storeRequest } = this.props;

    return (
      <div>
        <div className="l-row">
          <section className="l-col left">
            <h1 className="l-center">
              <PaymentIcon />
              <br />
              Zahlungsart
            </h1>
          </section>

          {storeRequest.showPayingForm ? this.renderPaymentForm() : this.renderAlreadyPaidNote()}
        </div>
      </div>
    );
  }
}
