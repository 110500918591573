// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import BookedPackage from '../../models/BookedPackage';

import BaseList from './BaseList';

const NewspaperPackageBookingList = ({
  header,
  bookings,
  onCancelBooking,
  disabled,
}) => (
  <BaseList
    header={header}
    bookings={bookings}
    onCancelBooking={onCancelBooking}
    title={booking =>
      `${booking.displayName}${booking.prelimenary ? ' (in Bearbeitung)' : ''}`
    }
    info={booking => {
      // Preliminary items cannot present the right info, because of some Middleware issues, so
      // we cannot show anything reliable.
      if (booking.prelimenary) return '';
      if (booking.includeFirstLastDay) return 'inkl. An- / Abreisetag';
      if (booking.includeFirstDayOnly) return 'inkl. Anreisetag';
      if (booking.includeLastDayOnly) return 'inkl. Abreisetag';
      return '';
    }}
    disabled={disabled}
  />
);

NewspaperPackageBookingList.propTypes = {
  header: PropTypes.string.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.instanceOf(BookedPackage)).isRequired,
  onCancelBooking: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default NewspaperPackageBookingList;
