// @flow
import React from 'react';
import MultiSelect from '../form/MultiSelect';
import { action } from 'mobx';
// import { observer } from 'mobx-react';
import union from 'lodash/union';
import without from 'lodash/without';
import CheckboxGroup from '../CheckboxGroup';
import ErrorMessage from '../form/ErrorMessage';
import PersonHeightSelect from './PersonHeightSelect';
import { autobind } from 'core-decorators';

// @observer
export default class ExcursionMultiSelect extends MultiSelect {
  @action
  handlePersonSelectChange(mpi: number, checked: boolean) {
    const { handlePersonSelectChange } = this.props;
    this._value = checked
      ? union(this._value, [mpi])
      : without(this._value, mpi);

    if (handlePersonSelectChange) handlePersonSelectChange(mpi, checked);
  }

  @autobind
  handlePersonHeightChange(mpi: number, personHeight: string) {
    const { handlePersonHeightChange } = this.props;
    if (handlePersonHeightChange) handlePersonHeightChange(mpi, personHeight);
  }

  render() {
    const {
      className,
      bookable,
      options,
      disabled,
      personHeightRequired,
    } = this.props;

    return (
      <CheckboxGroup className={className || 'form-fieldset'}>
        {options.map(option => {
          const mpi = option.value;
          const checked = option.checked;

          // TUICUNIT-2638: waiting list, mixed group dirty crap
          let markWait = false;
          let noToAdd = false;
          if (bookable.exbooking && bookable.exbooking.isSomeWaitListed) {
            markWait = bookable.exbooking.waitListMpis.find(i => i === mpi);
            noToAdd = !bookable.exbooking.mpis.find(i => i === mpi);
          }

          return (
            <div key={mpi} className={markWait ? 'mark-wait' : ''}>
              <CheckboxGroup.Item
                value={mpi}
                checked={checked}
                onChange={event =>
                  this.handlePersonSelectChange(
                    mpi,
                    event.currentTarget.checked
                  )
                }
                disabled={disabled || option.disabled || noToAdd}
              >
                {option.label}
              </CheckboxGroup.Item>
              {checked && personHeightRequired ? (
                <PersonHeightSelect
                  value={option.personHeight}
                  name={`person-height-select-${mpi}`}
                  onChange={personHeight =>
                    this.handlePersonHeightChange(mpi, personHeight || '')
                  }
                />
              ) : null}
            </div>
          );
        })}
        <ErrorMessage error={this.localError} />
      </CheckboxGroup>
    );
  }
}
