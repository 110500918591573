// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import ItineraryListItem from '../ItineraryListItem';

import Itinerary from '../../models/Itinerary';
import MasterData from '../../models/MasterData';
import classNames from 'classnames';
import Link from 'react-router/lib/Link';
import { itsUrlWithSize } from '../../utils/image';

export default class ExcursionItineraryList extends React.Component {
  static propTypes = {
    masterData: PropTypes.instanceOf(MasterData).isRequired,
    itinerary: PropTypes.instanceOf(Itinerary).isRequired,
  };

  renderItem(day, index) {
    const { masterData } = this.props;
    const itineraryBookings = day.getBookingsForItinerary(
      masterData.getBookings(day.date)
    );
    const services = !day.isSeaDay ? itineraryBookings : [];

    return (
      <ItineraryListItem
        journeyOngoing={masterData.journeyOngoing}
        showEmpty
        emptyText={
          day.isSeaDay
            ? 'Es finden keine Landausflüge statt.'
            : 'Sie haben noch nichts vor.'
        }
        disabled={day.isSeaDay || day.isPast}
        day={day}
        services={services}
        data={{
          date: day.displayItineraryDate,
          title: day.port.name,
          imageSrc: day.image,
          imageAlt: day.hint,
        }}
        link={`/landausfluege/${index}`}
        key={index}
      />
    );
  }

  renderTeaserItems(includedShoreEx = []) {
    return includedShoreEx.map((data, index) => {
      const link = `/inklusiv-landausfluege/${data.id}`;
      return (
        <Link
          key={`teaser-${index}`}
          to={link}
          className={classNames({
            'itinerary-item': true,
            'offer-item': false,
            highlight: false,
            services: false,
            disabled: false,
            small: false,
            teaser: true,
          })}
        >
          <img
            src={itsUrlWithSize(data.imageSrc, 604, 138)}
            alt={data.comment}
          />
          <div className="itinerary-item__title">{data.headline}</div>
          <p>Dieser Landausflug ist für Sie inklusive!</p>
          <Link to={link} className={classNames({ underline: true })}>
            Landausflug reservieren
          </Link>
        </Link>
      );
    });
  }

  render() {
    const { itinerary } = this.props;
    let lastTeaserLocationId;

    return (
      <div className="l-block">
        {itinerary.list.map((data, index) => {
          const { turnAround, type, locationId, includedShoreEx } = data;
          if (
            lastTeaserLocationId !== locationId &&
            includedShoreEx &&
            includedShoreEx.length > 0 &&
            !turnAround &&
            type === 'port'
          ) {
            lastTeaserLocationId = locationId;
            return [
              ...this.renderTeaserItems(includedShoreEx),
              this.renderItem(data, index),
            ];
          }
          return this.renderItem(data, index);
        })}
      </div>
    );
  }
}
