// @flow
import Person from './Person';
import ArrivalDepartureItem from './ArrivalDepartureItem';

import operatorInfo from './static-data/operatorInfo';

type InboundOutbound = {
  inbound: ArrivalDepartureItem[],
  outbound: ArrivalDepartureItem[],
};

const concatAndSort = (
  a1: ArrivalDepartureItem[],
  a2: ArrivalDepartureItem[],
  a3: ArrivalDepartureItem[]
): ArrivalDepartureItem[] => {
  return a1
    .concat(a2)
    .concat(a3)
    .sort((a, b) => {
      return a.departureComparisonTime - b.departureComparisonTime;
    });
};

export default class ArrivalDeparture {
  agencyPhoneNumber: ?string;
  agencyTuicInternal: ?boolean;
  callableAgencyPhoneNumber: ?string;
  participantShortList: Person[];
  flightContainer: InboundOutbound;
  busContainer: InboundOutbound;
  inboundOutbound: InboundOutbound;
  hasInbound: boolean;
  hasOutbound: boolean;
  hasData: boolean;
  hasTrain: boolean;
  operatorInfo: Object;

  constructor(data: ArrivalDeparture) {
    this.loading = !!data;

    this.agencyPhoneNumber = data.agencyPhoneNumber || null;
    this.callableAgencyPhoneNumber = this.agencyPhoneNumber
      ? this.agencyPhoneNumber.replace(/\//g, '')
      : null;

    this.participantShortList =
      data &&
      data.participantShortList &&
      data.participantShortList.map(person => new Person(person));

    this.flightContainer = {
      inbound:
        (data &&
          data.flightContainer &&
          data.flightContainer.inbound &&
          data.flightContainer.inbound.map(
            item => new ArrivalDepartureItem(item)
          )) ||
        [],
      outbound:
        (data &&
          data.flightContainer &&
          data.flightContainer.outbound &&
          data.flightContainer.outbound.map(
            item => new ArrivalDepartureItem(item)
          )) ||
        [],
    };

    this.busContainer = {
      inbound:
        (data &&
          data.busContainer &&
          data.busContainer.inbound &&
          data.busContainer.inbound.map(
            item => new ArrivalDepartureItem(item)
          )) ||
        [],
      outbound:
        (data &&
          data.busContainer &&
          data.busContainer.outbound &&
          data.busContainer.outbound.map(
            item => new ArrivalDepartureItem(item)
          )) ||
        [],
    };

    this.train = {
      /*bookingDataProcessing:
        data &&
        data.train &&
        data.train.status === 502 &&
        data.train.response &&
        data.train.response.code === 'booking_data_processing' &&
        data.train.response.error
          ? data.train.response.error
          : null,*/
      bookingDataProcessing: (data && data.train && data.train.numberOfBookings) || 0,
      numberOfBookings:
        (data && data.train && data.train.numberOfBookings) || 0,
      inbound:
        (data &&
          data.train &&
          data.train.inbound &&
          data.train.inbound.map(item => new ArrivalDepartureItem(item))) ||
        [],
      outbound:
        (data &&
          data.train &&
          data.train.outbound &&
          data.train.outbound.map(item => new ArrivalDepartureItem(item))) ||
        [],
    };

    this.inboundOutbound = {
      inbound: concatAndSort(
        this.flightContainer.inbound,
        this.busContainer.inbound,
        this.train.inbound
      ),
      outbound: concatAndSort(
        this.flightContainer.outbound,
        this.busContainer.outbound,
        this.train.outbound
      ),
    };

    this.hasInbound = this.inboundOutbound.inbound.length >= 1;
    this.hasOutbound = this.inboundOutbound.outbound.length >= 1;
    this.hasData = this.hasInbound || this.hasOutbound;
    this.hasTrain = this.train.numberOfBookings !== 0;
    this.agencyTuicInternal = !!data.agencyTuicInternal;

    this.operatorInfo = operatorInfo.unknown;
    if (this.callableAgencyPhoneNumber) {
      this.operatorInfo = this.agencyTuicInternal
        ? operatorInfo.serviceTeam
        : operatorInfo.travelAgency;
    }

    this.transportTyps = [];
    if (this.busContainer.inbound.length !== 0) {
      this.transportTyps.push('bus');
    }
    if (this.flightContainer.inbound.length !== 0) {
      this.transportTyps.push('flight');
    }
    if (this.train.inbound.length !== 0) {
      this.transportTyps.push('train');
    }

    if (this.busContainer.outbound.length !== 0) {
      this.transportTyps.push('bus');
    }
    if (this.flightContainer.outbound.length !== 0) {
      this.transportTyps.push('flight');
    }
    if (this.train.outbound.length !== 0) {
      this.transportTyps.push('train');
    }
    if (this.train.numberOfBookings !== 0) {
      this.transportTyps.push('train');
    }
  }
}
