// @flow

import type {
  IInclusiveBookingUpdateRequest,
  IMpiBookingUpdateRequest,
} from '../../api/includedShoreEx';

export default class IncludedBookingUpdateRequest
  implements IInclusiveBookingUpdateRequest {
  type: string;
  list: IMpiBookingUpdateRequest[];

  constructor(list: IMpiBookingUpdateRequest[]) {
    this.type = 'excursionInclusiveBookingUpdateRequest';

    this.list = list.map(item => {
      item.type = 'excursionMpiBookingUpdateRequest';
      return item;
    });
  }
}
