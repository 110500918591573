//@flow
import React from 'react';
import Link from 'react-router/lib/Link';
import tracking from '../tracking';

type FooterProps = {
  isInAppView?: boolean,
};

const Footer = ({ isInAppView }: FooterProps): React.ReactNode => {
  if (isInAppView) return <span />;

  return (
    <footer className="footer">
      <div className="footer--container">
        <hr />
        <div className="footer--screen">
          <div className="l-spread top">
            <div className="l-spread_left">
              <Link
                target="_blank"
                to="http://meinschiff.com/"
                onClick={() => tracking.click('footer.link.Mein Schiff.com')}
              >
                <i>Mein Schiff</i>.com
              </Link>
              <span> | </span>
              <Link
                target="_blank"
                to="http://meinschiff.com/impressum/"
                onClick={() => tracking.click('footer.link.Impressum')}
              >
                Impressum
              </Link>
              <span> | </span>
              <Link target="_blank" to="http://meinschiff.com/agb/" onClick={() => tracking.click('footer.link.AGB')}>
                AGB
              </Link>
              <span> | </span>
              <Link
                target="_blank"
                to="https://www.meinschiff.com/datenschutz/"
                onClick={() => tracking.click('footer.link.Datenschutz')}
              >
                Datenschutz
              </Link>
            </div>
            <div className="l-spread_right">
              <div className="footer__logo" />
            </div>
          </div>
        </div>
        <div className="footer--print">
          <div className="l-spread top">
            <div className="l-spread_left" />
            <div className="l-spread_right">
              <div className="footer__logo" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
