// @flow

import React, { Component, type Node } from 'react';
import { autobind } from 'core-decorators';
import classNames from 'classnames';
import { USER_PIN_LENGTH } from '../../config/constants';
import { isEnterKey, isDeleteKey } from '../../utils/keys';
import { isAlphanumeric } from '../../utils/string';

type Props = {
  pinLength: number,
  pin?: string,
  displayOnlyPin?: string,
  onChange?: ?(pin: string) => mixed,
  onEnter?: ?(pin: string) => mixed,
  hidden?: boolean,
  wrongPin?: boolean,
};

type State = {
  value: string,
};

class PinBlock extends Component<Props, State> {
  static defaultProps = {
    pinLength: USER_PIN_LENGTH,
  };

  pinInput: window.HTMLDivElement;

  state = {
    value: '',
  };

  @autobind
  focus() {
    this.pinInput && this.pinInput.focus();
  }

  validatePin(pin: ?string): boolean {
    const { pinLength } = this.props;

    return !!(pin && isAlphanumeric(pin) && pin.length <= pinLength);
  }

  setPin(c: ?string) {
    const { value } = this.state;
    const { onChange } = this.props;
    if (!c) return false;

    const pin = `${value}${c}`;
    if (this.validatePin(pin)) {
      this.setState({ value: pin });
      onChange && onChange(pin);
      return true;
    }

    return false;
  }

  componentDidMount() {
    const { pin } = this.props;
    this.setPin(pin);
    this.focus();
  }

  get pinBlocks(): Node[] {
    const { pinLength, displayOnlyPin, hidden, wrongPin } = this.props;
    const { value } = this.state;

    const length = displayOnlyPin ? displayOnlyPin.length : pinLength;
    let output = displayOnlyPin || value;

    let blocks = [];
    for (let i = 0; i < length; i++) {
      let c = output && output[i] ? output[i] : '';
      blocks.push(
        <div
          key={i}
          className={classNames({
            'pin-block': true,
            active: !displayOnlyPin && i === value.length,
            'wrong-pin': wrongPin,
          })}
          onClick={this.focus}
        >
          {hidden && (
            <strong
              className="dot"
              dangerouslySetInnerHTML={{ __html: c ? '•' : '' }} // &#8226;
            />
          )}
          {!hidden && <strong dangerouslySetInnerHTML={{ __html: c }} />}
        </div>
      );
    }

    return blocks;
  }

  render() {
    const { value } = this.state;
    const { displayOnlyPin, onChange, onEnter } = this.props;

    return (
      <div className="pin-input">
        {this.pinBlocks}
        {!displayOnlyPin && (
          <input
            value=""
            type="text"
            ref={ref => {
              this.pinInput = ref;
            }}
            onKeyUp={e => {
              if (isDeleteKey(e)) {
                const pin = value.substring(0, value.length - 1);
                this.setState({ value: pin });
                onChange && onChange(pin);
              }

              if (onEnter && isEnterKey(e)) {
                onEnter(this.state.value);
              }
            }}
            onChange={e => {
              const c = e.currentTarget.value.toUpperCase();
              return this.setPin(c);
            }}
          />
        )}
      </div>
    );
  }
}

export default PinBlock;
