// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';
import type { ApiType, ApiResponse, ResponseType } from '../api/spaTeaser';

export default class SpaTeaserStore {
  @observable loading: boolean = false;
  @observable error: boolean = true;
  @observable data: ?ApiResponse = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  @action
  fetch() {
    this.loading = true;
    return this.api
      .getList(this.masterStore.masterData)
      .then((data: ApiResponse) => {
        this.receiveData(data);
      });
  }

  @action
  receiveData(data: ApiResponse) {
    if (!data.error && Array.isArray(data)) {
      data.forEach((element: ResponseType, i) => {
        data[i].type = element.type.replace(/ /g, '');
      });
    }
    this.data = data;
    this.error = data.error || false;
    this.loading = false;
  }
}
