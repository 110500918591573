// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, ApiResponse, TimeSloteType } from '../api/timeSlote';

export type TimeSloteSelectedType = {
  value: string,
  eventId: number,
  vacancyCount: number,
  label: string,
  displayLabel: string,
  startDate: string,
  endDate: string,
};

export type TimeSloteBookingType = {
  state: boolean,
  eventId: number,
  mpi: number,
  label: string,
  display: string,
  vacancyId: string,
  booked: {
    bookingId: string,
    startDate: string,
    endDate: string,
  },
};

export default class TimeSloteStore {
  @observable slots: ?ApiResponse = null;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.slots === null;
  }

  fetch() {
    return this.api.get(this.masterStore.user).then((slots: ApiResponse) => {
      this.receiveTimeSlots(slots);
    });
  }

  @action
  receiveTimeSlots(slots: ApiResponse) {
    this.slots = slots;
  }

  @computed
  get hasTimeSlots(): boolean {
    return (
      (this.slots &&
        this.slots.bookingOptionList &&
        this.slots.bookingOptionList.list &&
        this.prepareTimeSlots(this.slots.bookingOptionList.list, true)
          .length !== 0) ||
      false
    );
  }

  @computed
  get hasError500(): boolean {
    return (
      (this.slots && this.slots.status && this.slots.status === 500) || false
    );
  }

  @computed
  get getAvailableTimeSlots() {
    return this.slots &&
      this.slots.bookingOptionList &&
      this.slots.bookingOptionList.list
      ? this.prepareTimeSlots(this.slots.bookingOptionList.list, true)
      : [];
  }

  @computed
  get getAllTimeSlots() {
    return this.slots &&
      this.slots.bookingOptionList &&
      this.slots.bookingOptionList.list
      ? this.prepareTimeSlots(this.slots.bookingOptionList.list, false)
      : [];
  }

  @computed
  get getBookingList(): [] | TimeSloteBookingType[] {
    return this.slots &&
      this.slots.mpiBookingList &&
      this.slots.mpiBookingList.list
      ? this.prepareBookedTimeSlots(this.slots.mpiBookingList.list)
      : [];
  }

  prepareTimeSlots(
    timeslots: TimeSloteType[],
    travelPartyDependent: boolean
  ): [] | TimeSloteSelectedType[] {
    let result = [];
    // const minAvailability = this.masterStore.masterData.travelParty.length || 1;

    let minAvailability = 0;
    this.masterStore.masterData.travelParty.forEach(value => {
      if (
        value.checkin.completed === false &&
        value.checkin.preliminary === false
      ) {
        minAvailability = minAvailability + 1;
      }
    });

    timeslots.forEach(slote => {
      if (travelPartyDependent && slote.vacancyCount < minAvailability) {
        return false;
      }

      let sH,
        sM,
        eH,
        eM = '00';
      if (slote.startDate && slote.endDate) {
        // don't use startDate and endDate as Date Object, safari need a timezone
        const sT = slote.startDate.split('T');
        const sTT = sT ? sT[1].split(':') : null;
        sH = sTT ? sTT[0] : '00';
        sM = sTT ? sTT[1] : '00';

        const eT = slote.endDate.split('T');
        const eTT = eT ? eT[1].split(':') : null;
        eH = eTT ? eTT[0] : '00';
        eM = eTT ? eTT[1] : '00';
      }

      const count =
        slote.vacancyCount > 20
          ? '> 20 Plätze verfügbar'
          : slote.vacancyCount === 1
            ? `${slote.vacancyCount} Platz verfügbar`
            : `${slote.vacancyCount} Plätze verfügbar`;

      result.push({
        value: slote.vacancyId,
        eventId: slote.eventId,
        label: `${sH}:${sM} - ${eH}:${eM} Uhr (${count})`,
        displayLabel: `${sH}:${sM} - ${eH}:${eM} Uhr`,
        startDate: slote.startDate,
        endDate: slote.endDate,
      });
    });
    return result;
  }

  prepareBookedTimeSlots(bookingList) {
    let result = [];
    const timeSlots = this.getAllTimeSlots;

    result = bookingList.map(item => {
      const findTimeInfo = timeSlots.find(
        slote => slote.eventId === item.eventId
      );
      return {
        state: true,
        label: `Check-in Zeitfenster: ${findTimeInfo.displayLabel}`,
        display: findTimeInfo.displayLabel,
        vacancyId: findTimeInfo.value,
        eventId: item.eventId,
        mpi: item.mpi,
        booked: {
          bookingId: item.bookingId,
          startDate: findTimeInfo.startDate,
          endDate: findTimeInfo.endDate,
        },
      };
    });

    return result;
  }
}
