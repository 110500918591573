// @flow
import React, { Component } from 'react';
import includes from 'lodash/includes';

import type Bookable from '../../models/Bookable';
import type PartyMember from '../../models/PartyMember';

type Props = {
  bookable: Bookable,
  travelParty: PartyMember[],
};

export default class ParticipantList extends Component<Props> {
  get participants(): PartyMember[] {
    const { travelParty, bookable } = this.props;

    if (!bookable || !travelParty) {
      return [];
    }

    // TUICUNIT-2638: waiting list, mixed group dirty crap
    if (
      bookable.type === 'excursionDetail' &&
      bookable.exbooking &&
      bookable.exbooking.mpis
    ) {
      return travelParty.filter(member =>
        includes(bookable.exbooking.mpis, member.mpi)
      );
    }

    return travelParty.filter(member =>
      includes(bookable.bookedMpis, member.mpi)
    );
  }

  render() {
    const { bookable } = this.props;

    return (
      <div>
        <h2>Teilnehmer</h2>
        <ul>
          {this.participants.map(participant => {
            // TUICUNIT-2638: waiting list, mixed group dirty crap
            let markWait = false;
            if (bookable.exbooking && bookable.exbooking.isSomeWaitListed) {
              markWait = bookable.exbooking.waitListMpis.find(
                i => i === participant.mpi
              );
            }
            return (
              <li
                key={participant.mpi}
                className={markWait ? 'mark-wait-list' : ''}
              >
                {participant.displayName}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
