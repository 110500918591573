// @flow

import React from 'react';
import Tile from '../../components/tiles/Tile';
import HighlightTile from '../../components/tiles/HighlightTile';
import { itsUrlWithSize, loadImg } from '../../utils/image';
import type { TilesStoreType } from '../../stores/TilesStore';

const loadTileImg = (img) => loadImg(`tiles/${img}.jpg`);

const bookingDetailsTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.bookingDetails ? (
    <Tile
      full={full === true}
      link="/buchungsdetails"
      badge={tiles.bookingDetails.notification}
      disabled={!tiles.bookingDetails.enabled}
      icon="bookingdetails"
      title={tiles.bookingDetails.title}
    />
  ) : null;

const checkinTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.onlineCheckIn ? (
    <Tile
      full={full === true}
      link="/checkin"
      badge={tiles.onlineCheckIn.notification}
      disabled={!tiles.onlineCheckIn.enabled}
      icon="check-in within-tile"
      title={tiles.onlineCheckIn.title}
      isCheckin={true}
    />
  ) : null;

const excursionTile = (tiles: TilesStoreType, full: ?boolean, bonus: string | null) =>
  tiles.landausfluege ? (
    <Tile
      full={full === true}
      link="/landausfluege"
      badge={tiles.landausfluege.notification}
      disabled={!tiles.landausfluege.enabled}
      title={tiles.landausfluege.title}
      bonus={bonus}
      imageSrc={full === true ? loadTileImg('landausfluege-544x324') : loadTileImg('landausfluege-236x236')}
      smallImageSrc={loadTileImg('landausfluege-236x236')}
    />
  ) : null;

const fitnessTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.fitness ? (
    <Tile
      full={full === true}
      link="/fitness"
      badge={tiles.fitness.notification}
      disabled={!tiles.fitness.enabled}
      title={tiles.fitness.title}
      imageSrc={full === true ? loadTileImg('fitness-544x324') : loadTileImg('fitness-236x236')}
      smallImageSrc={loadTileImg('fitness-236x236')}
    />
  ) : null;

const furtherInformationTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.weitereInformationen ? (
    <Tile
      full={full === true}
      link="/weitere-informationen"
      badge={tiles.weitereInformationen.notification}
      disabled={!tiles.weitereInformationen.enabled}
      icon="info"
      title={tiles.weitereInformationen.title}
    />
  ) : null;

const healthAndBeautyTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.schoenheitGesundheit ? (
    <Tile
      full={full === true}
      link="/schoenheit-und-gesundheit"
      badge={tiles.schoenheitGesundheit.notification}
      disabled={!tiles.schoenheitGesundheit.enabled}
      title={tiles.schoenheitGesundheit.title}
      imageSrc={
        full === true ? loadTileImg('schoenheit-gesundheit-544x324') : loadTileImg('schoenheit-gesundheit-236x236')
      }
      smallImageSrc={loadTileImg('schoenheit-gesundheit-236x236')}
    />
  ) : null;

const highlightTile = (tiles: TilesStoreType, full: ?boolean) => {
  if (!tiles.highlight) {
    return null;
  }

  if (tiles.highlight.data && tiles.highlight.data.length !== 1 && tiles.highlight.enabled && full === true) {
    return <HighlightTile data={tiles.highlight.data} badge={tiles.highlight.notification} />;
  }
  if (tiles.highlight.data && full === false) {
    return (
      <Tile
        full={full === true}
        key="highlight"
        link={`/highlights/${tiles.highlight.data[0].id}`}
        disabled={!tiles.highlight.enabled}
        badge={tiles.highlight.notification}
        imageSrc={itsUrlWithSize(tiles.highlight.data[0].imageUri, 236, 236)}
        smallImageSrc={itsUrlWithSize(tiles.highlight.data[0].imageUri, 236, 236)}
        title={tiles.highlight.data[0].headline}
        isHighlight={true}
      />
    );
  }

  if (tiles.highlight.data) {
    return (
      <Tile
        full={full === true}
        key="highlight"
        link={`/highlights/${tiles.highlight.data[0].id}`}
        badge={tiles.highlight.notification}
        disabled={!tiles.highlight.enabled}
        imageSrc={
          full === true
            ? itsUrlWithSize(tiles.highlight.data[0].imageUri, 544, 324)
            : itsUrlWithSize(tiles.highlight.data[0].imageUri, 236, 236)
        }
        smallImageSrc={itsUrlWithSize(tiles.highlight.data[0].imageUri, 236, 236)}
        title={tiles.highlight.data[0].headline}
        isHighlight={true}
      />
    );
  }
};

const includedShoreExTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.includedShoreEx ? (
    <Tile
      badge={tiles.includedShoreEx.notification}
      full={full === true}
      link="/inklusiv-landausfluege"
      disabled={!tiles.includedShoreEx.enabled}
      imageSrc={
        full === true ? loadTileImg('inklusiv-landausfluege-544x324') : loadTileImg('inklusiv-landausfluege-236x236')
      }
      smallImageSrc={loadTileImg('inklusiv-landausfluege-236x236')}
      title={tiles.includedShoreEx.title}
    />
  ) : null;

const internetTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.internet ? (
    <Tile
      full={full === true}
      link="/internet-an-bord"
      badge={tiles.internet.notification}
      disabled={!tiles.internet.enabled}
      imageSrc={loadTileImg('internet-236x236')}
      smallImageSrc={loadTileImg('internet-236x236')}
      title={tiles.internet.title}
    />
  ) : null;

const manifestTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.manifest ? (
    <Tile
      full={full === true}
      link="/manifest"
      badge={tiles.manifest.notification}
      disabled={!tiles.manifest.enabled}
      icon="manifest"
      title={tiles.manifest.title}
    />
  ) : null;

const newspaperTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.paketeUndZeitungen ? (
    <Tile
      full={full === true}
      link="/pakete-und-zeitungen"
      badge={tiles.paketeUndZeitungen.notification}
      disabled={!tiles.paketeUndZeitungen.enabled}
      imageSrc={loadTileImg('pakete-zeitungen-236x236')}
      smallImageSrc={loadTileImg('pakete-zeitungen-236x236')}
      title={tiles.paketeUndZeitungen.title}
    />
  ) : null;

const parkingTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.parking ? (
    <Tile
      full={full === true}
      link="/parkservice"
      badge={tiles.parking.notification}
      disabled={!tiles.parking.enabled}
      imageSrc={loadTileImg('parking-236x236')}
      smallImageSrc={loadTileImg('parking-236x236')}
      title={tiles.parking.title}
    />
  ) : null;

const programTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.program ? (
    <Tile
      full={full === true}
      link="/programm"
      badge={tiles.program.notification}
      disabled={!tiles.program.enabled}
      imageSrc={loadTileImg('programm-236x236')}
      smallImageSrc={loadTileImg('programm-236x236')}
      title={tiles.program.title}
    />
  ) : null;

const spaBalconyCabinTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.spaBalconyCabin ? (
    <Tile
      full={full === true}
      link="/spa-balkonkabine"
      badge={tiles.spaBalconyCabin.notification}
      disabled={!tiles.spaBalconyCabin.enabled}
      imageSrc={full === true ? loadTileImg('spabalkonkabine-544x324') : loadTileImg('spabalkonkabine-236x236')}
      smallImageSrc={loadTileImg('spabalkonkabine-236x236')}
      title={tiles.spaBalconyCabin.title}
    />
  ) : null;

const tableReservationTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.tischreservierung ? (
    <Tile
      full={full === true}
      link="/tischreservierung"
      badge={tiles.tischreservierung.notification}
      disabled={!tiles.tischreservierung.enabled}
      imageSrc={full === true ? loadTileImg('tischreservierung-544x324') : loadTileImg('tischreservierung-236x236')}
      smallImageSrc={loadTileImg('tischreservierung-236x236')}
      title={tiles.tischreservierung.title}
    />
  ) : null;

const travelMediaTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.erinnerungsstuecke ? (
    <Tile
      full={full === true}
      link="/reisemedien"
      badge={tiles.erinnerungsstuecke.notification}
      disabled={!tiles.erinnerungsstuecke.enabled}
      imageSrc={loadTileImg('dvd-reisefuehrer-236x236')}
      smallImageSrc={loadTileImg('dvd-reisefuehrer-236x236')}
      title={tiles.erinnerungsstuecke.title}
    />
  ) : null;

const travelPlanTile = (tiles: TilesStoreType, full: ?boolean) =>
  tiles.reiseplan ? (
    <Tile
      full={full === true}
      link="/reiseplan"
      badge={tiles.reiseplan.notification}
      disabled={!tiles.reiseplan.enabled}
      icon="itinerary"
      title={tiles.reiseplan.title}
    />
  ) : null;

export {
  bookingDetailsTile,
  checkinTile,
  excursionTile,
  fitnessTile,
  furtherInformationTile,
  healthAndBeautyTile,
  highlightTile,
  internetTile,
  includedShoreExTile,
  manifestTile,
  newspaperTile,
  parkingTile,
  programTile,
  spaBalconyCabinTile,
  tableReservationTile,
  travelMediaTile,
  travelPlanTile,
};
