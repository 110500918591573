// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type { ApiType, ApiResponse } from '../api/vipCoupon';

export type VipCouponType = {
  headline: ?string,
  text: string,
  code: ?string,
};

export default class VipCouponStore {
  @observable loading: boolean = false;
  @observable vipCoupon: ?VipCouponType;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  fetch() {
    return this.api
      .get(this.masterStore.user)
      .then((vipCoupon: ApiResponse) => {
        this.receiveVipCoupon(vipCoupon);
      });
  }

  @action
  receiveVipCoupon(result: ApiResponse) {
    if (result && result.text) {
      this.vipCoupon = {
        headline: this.prepareText(result.headline),
        text: this.prepareText(result.text),
        code: this.prepareText(result.code),
      };
      this.loading = false;
    }
  }

  /**
   * remove all html tags, only -> a are allowed
   *
   * @deprecated Use utilfunction prepareText instead test
   * @param {string} text
   * @returns clean String
   */
  prepareText(text: ?string) {
    let cText = text || '';
    cText = cText.replace(/(<a href)/gi, '[a]').replace(/(<\/a>)/gi, '[/a]');
    cText = cText.replace(/(<b>)/gi, '[b]').replace(/(<\/b>)/gi, '[/b]');

    cText = cText.replace(/(<([^>]+)>)/gi, '');
    cText = cText.replace(/([\n\t])/gi, ' ');
    cText = cText.split('  ').join(' ');

    cText = cText.split('[a]').join(' <a href');
    cText = cText.split('[/a]').join('</a>');
    cText = cText.split('[b]').join('<span class="bold">');
    cText = cText.split('[/b]').join('</span>');
    return cText;
  }
}
