// @flow

import React, { Component } from 'react';
import FormSelect from '../form/Select';
import CheckboxGroup from '../CheckboxGroup';
import { autobind } from 'core-decorators';
import { weakBrowser } from '../../utils/browser';
import Shiitake from 'shiitake';

type Props = {
  options: Object[],
  onWaiverChange: ?Function,
  onDayChange: ?Function,
  onTimeChange: ?Function,
};

export default class BookingOptionsMultiSelect extends Component<Props> {
  handleWaiverChange(mpi: number, checked: boolean) {
    const { onWaiverChange } = this.props;
    if (onWaiverChange) onWaiverChange(mpi, checked);
  }

  @autobind
  handleDaySelection(mpi: number, day: string) {
    const { onDayChange } = this.props;
    if (onDayChange) onDayChange(mpi, day);
  }

  @autobind
  handleTimeSlotSelection(mpi: number, bookingOptionId: string) {
    const { onTimeChange } = this.props;
    if (onTimeChange) onTimeChange(mpi, bookingOptionId);
  }

  render() {
    const { options } = this.props;

    return (
      <div className="multi-select">
        <div className="title">Teilnehmer</div>
        {options.map(
          (item, index) =>
            !item.ignore ? (
              <div key={index} className="option">
                <Shiitake className="item label" lines={2}>
                  {item.label}
                </Shiitake>
                <div className="item select">
                  <FormSelect
                    weakBrowserMode={weakBrowser()}
                    name={`days-${index}`}
                    placeholder="Reisetag wählen"
                    options={item.daySlots}
                    onChange={day => {
                      this.handleDaySelection(item.value, day);
                    }}
                    value={item.selectedDay}
                    key={`${index}-${item.selectedDay}`}
                    disabled={item.checked}
                  />
                </div>
                <div className="item select">
                  <FormSelect
                    weakBrowserMode={weakBrowser()}
                    name={`timeslots-${index}`}
                    placeholder="Uhrzeit wählen"
                    options={item.vacancies[item.selectedDay] || []}
                    onChange={bookingOptionId => {
                      this.handleTimeSlotSelection(item.value, bookingOptionId);
                    }}
                    value={item.selectedBookingOptionId || ''}
                    key={`${index}-${item.selectedBookingOptionId}`}
                    disabled={item.checked}
                  />
                </div>
                <div className="item checkbox">
                  <CheckboxGroup.Item
                    value={item.value}
                    key={`${index}-${item.value}`}
                    checked={item.checked}
                    disabled={false}
                    onChange={event =>
                      this.handleWaiverChange(
                        item.value,
                        event.currentTarget.checked
                      )
                    }
                  >
                    keine Teilnahme:
                  </CheckboxGroup.Item>
                </div>
              </div>
            ) : (
              <div key={item.value} className="option ignored">
                <div className="item label">{item.label}</div>
                <div className="item hint">{item.hint}</div>
              </div>
            )
        )}
      </div>
    );
  }
}
