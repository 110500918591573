// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';
import type { ApiType, ApiResponse } from '../api/spaTeaser';
import { prepareText } from '../utils/prepareText';

export default class MaintenanceStore {
  @observable loading: boolean = true;
  @observable error: boolean = true;
  @observable data: ?ApiResponse = null;
  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }

  @action
  fetch() {
    return this.api.get().then((data: ApiResponse) => {
      this.receiveData(data);
    });
  }

  @action
  receiveData(data: ApiResponse) {
    this.data = {
      headline: prepareText(data.headline, ['s', 'b', 'i']),
      text: prepareText(data.text, ['s', 'b', 'i', 'br']),
    };
    this.error = data.error || false;
    this.loading = false;
  }
}
