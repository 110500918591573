// @flow
import React, { Fragment } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import TileGroup from '../../components/tiles/Tile/Group';
import { forceCheckInNeeded, ForceCheckInInfo } from './ForceCheckIn';
import { forceDigitalHealthNeeded, ForceDigitalHealthInfo } from './ForceDigitalHealth';
import PartyMember from '../../models/PartyMember';
import type MasterData from '../../models/MasterData';

import type { TilesStoreType } from '../../stores/TilesStore';

import {
  bookingDetailsTile,
  checkinTile,
  excursionTile,
  fitnessTile,
  furtherInformationTile,
  healthAndBeautyTile,
  highlightTile,
  internetTile,
  includedShoreExTile,
  manifestTile,
  newspaperTile,
  parkingTile,
  programTile,
  spaBalconyCabinTile,
  tableReservationTile,
  travelMediaTile,
  travelPlanTile,
} from './Tiles';

type Props = {
  tiles: ?TilesStoreType,
  bonus: string | null,
  callback: () => void,
  travelParty: PartyMember[],
  masterData: MasterData,
};

const DashboardTiles = ({ tiles, bonus, callback, travelParty, masterData }: Props) => {
  const isMobile = useMediaQuery('screen and (max-width: 643px)');
  const isTablet = useMediaQuery('screen and (min-width: 644px) and (max-width: 951px)');
  const isDesktop = useMediaQuery('screen and (min-width: 952px)');
  if (!tiles) return null;

  const checkinVisible = tiles.onlineCheckIn && tiles.onlineCheckIn.visible;
  const highlightVisible = tiles.highlight && tiles.highlight.visible;
  const parkingVisible = tiles.parking && tiles.parking.visible;
  const spaBalconyVisible = tiles.spaBalconyCabin && tiles.spaBalconyCabin.visible;
  const includedShoreExVisible = tiles.includedShoreEx && tiles.includedShoreEx.visible;

  // TUICUNIT-1994: forceCheckin
  const forceCheckin = forceCheckInNeeded(tiles, travelParty, masterData).css;

  // TUICUNIT-2138: forceDigitalHealth
  const forceDigitalHealth = forceDigitalHealthNeeded(tiles, travelParty).css;

  return (
    <div className={`l-tiles${forceCheckin}${forceDigitalHealth}`}>
      <ForceCheckInInfo tiles={tiles} callback={callback} travelParty={travelParty} masterData={masterData} />
      <ForceDigitalHealthInfo tiles={tiles} callback={callback} travelParty={travelParty} />

      <TileGroup>
        {manifestTile(tiles, !checkinVisible)}
        {checkinVisible && checkinTile(tiles)}
      </TileGroup>

      {isMobile && (
        <Fragment>
          <TileGroup>
            {bookingDetailsTile(tiles)}
            {programTile(tiles)}
          </TileGroup>

          {(spaBalconyVisible || highlightVisible) && (
            <TileGroup>
              {spaBalconyVisible && spaBalconyCabinTile(tiles, !highlightVisible)}
              {highlightVisible && highlightTile(tiles, !spaBalconyVisible)}
            </TileGroup>
          )}
          <Fragment>
            {healthAndBeautyTile(tiles, !highlightVisible && !spaBalconyVisible)}
            {fitnessTile(tiles, !highlightVisible && !spaBalconyVisible)}
          </Fragment>
        </Fragment>
      )}

      {isTablet && (
        <Fragment>
          {spaBalconyVisible || highlightVisible ? (
            <Fragment>
              <TileGroup>
                {spaBalconyVisible && spaBalconyCabinTile(tiles, !highlightVisible)}
                {highlightVisible && highlightTile(tiles, !spaBalconyVisible)}
              </TileGroup>
              <TileGroup>
                {bookingDetailsTile(tiles)}
                {programTile(tiles)}
              </TileGroup>
              <Fragment>
                {healthAndBeautyTile(tiles)}
                {fitnessTile(tiles)}
              </Fragment>
            </Fragment>
          ) : (
            <Fragment>
              {healthAndBeautyTile(tiles, true)}
              <TileGroup>
                {bookingDetailsTile(tiles)}
                {programTile(tiles)}
              </TileGroup>
              {fitnessTile(tiles, true)}
            </Fragment>
          )}
        </Fragment>
      )}

      {isDesktop && (
        <Fragment>
          {(spaBalconyVisible || highlightVisible) && (
            <TileGroup>
              {spaBalconyVisible && spaBalconyCabinTile(tiles, !highlightVisible)}
              {highlightVisible && highlightTile(tiles, !spaBalconyVisible)}
            </TileGroup>
          )}
          <Fragment>
            {healthAndBeautyTile(tiles, !highlightVisible && !spaBalconyVisible)}
            {fitnessTile(tiles, !highlightVisible && !spaBalconyVisible)}
          </Fragment>
          <TileGroup>
            {bookingDetailsTile(tiles)}
            {programTile(tiles)}
          </TileGroup>
        </Fragment>
      )}

      <TileGroup>{travelPlanTile(tiles, true)}</TileGroup>

      <TileGroup>
        {includedShoreExVisible && includedShoreExTile(tiles)}
        {excursionTile(tiles, !includedShoreExVisible, bonus)}
      </TileGroup>

      <TileGroup>
        {parkingVisible && parkingTile(tiles)}
        {tableReservationTile(tiles, !parkingVisible)}
      </TileGroup>

      <TileGroup>
        {newspaperTile(tiles)}
        {travelMediaTile(tiles)}
      </TileGroup>

      <TileGroup>
        {internetTile(tiles)}
        {furtherInformationTile(tiles)}
      </TileGroup>
    </div>
  );
};

export default DashboardTiles;
