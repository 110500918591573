// @flow
import React, { Node } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import sliderSettings from '../config/sliderSettings';
import { itsUrlWithSize } from '../utils/image';

import HeroImage from './HeroImage';

type Props = {
  image: ?string,
  images: ?(Object[]),
  beforeChange?: ?Function,
  imageBadge?: ?Node,
};

const ImageSlider = ({ image, images, beforeChange, imageBadge }: Props) =>
  images ? (
    <div
      className={classNames({
        'slider-container': true,
        'hero-image': true,
      })}
    >
      <Slider {...sliderSettings} beforeChange={beforeChange}>
        {images.map((img, i) => (
          <div key={i}>
            <div
              className={classNames({
                'slider-item': true,
              })}
            >
              <img
                src={itsUrlWithSize(img.image, 912, 465)}
                alt={img.comment}
              />
              {img.comment && (
                <div
                  className={classNames({
                    'image-comment': true,
                  })}
                >
                  {img.comment.length > 48
                    ? `${img.comment.substring(0, 48)}...`
                    : img.comment}
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
      {imageBadge ? imageBadge : null}
    </div>
  ) : (
    <HeroImage src={image || ''} className="l-mod-sub" />
  );

export default ImageSlider;
