// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Duration = ({ duration, big, className }) => (
  <div
    className={classNames({
      duration: true,
      'duration--big': big,
      [className]: !!className,
    })}
  >
    <i className="duration__icon icon clock" />
    {duration} Minuten
  </div>
);

Duration.propTypes = {
  duration: PropTypes.number.isRequired,
  big: PropTypes.bool,
  className: PropTypes.string,
};

export default Duration;
