// @flow

import React, { Component, type Node } from 'react';
import { loadImg } from '../../utils/image';
import isInAppView from '../../utils/isInAppView';
import classNames from 'classnames';
import ArrivalDepartureListItem from './ArrivalDepartureListItem';
import type ArrivalDeparture from '../../models/ArrivalDeparture';
import type ArrivalDepartureItem from '../../models/ArrivalDeparture/ArrivalDepartureItem';
import operatorInfo from '../../models/ArrivalDeparture/static-data/operatorInfo';
import PhoneBlock from './PhoneBlock';
import MailBlock from './MailBlock';
import LinkAsButton from '../LinkAsButton';

type Props = {
  arrivalDeparture: ArrivalDeparture,
  selectedMpi: ?number,
  railVoucher?: object,
};

export default class ArrivalDepartureList extends Component<Props> {
  filterByMpi(items: ArrivalDepartureItem[]): ArrivalDepartureItem[] {
    const { selectedMpi } = this.props;

    return items
      ? items.filter((item) => {
          return item.mpis && item.mpis.indexOf(selectedMpi) >= 0;
        })
      : items;
  }

  renderArrivalDepartureBlock(items: ArrivalDepartureItem[], headline: string): Node {
    const filteredItems = this.filterByMpi(items);
    return filteredItems && filteredItems.length > 0 ? (
      <div className="l-col double arrival-departure-items">
        <div className="l-mod l-block">
          <h2 className="headline">{headline}</h2>
          <div className="arrival-departure-list">
            {filteredItems.map((item, index) => (
              <ArrivalDepartureListItem key={index} item={item} expanded={true} />
            ))}
          </div>
          <h2 className="arrival-placeholder">Ankunft</h2>
        </div>
      </div>
    ) : null;
  }

  renderSleepAndPark(): Node {
    const link = 'https://www.holidayextras.de/partner-wl/tuicruises.html?custref=login';

    return (
      <div
        key={`teaser-park-and-fly`}
        onClick={(e) => {
          e.preventDefault();

          if (isInAppView) {
            window.location.href = link;
          } else {
            window.open(link, '_blank');
          }
        }}
        className={classNames({
          'sleep-and-park-teaser': true,
        })}
      >
        <img src={loadImg('tiles/park-and-fly-teaser-616x155.jpg')} alt={'teaser'} />
        <div className="sleep-and-park-teaser__title">
          Hotel & Parken, Airport Lounges und weitere Services für Ihre An- und Abreise
        </div>
        <p />
        <LinkAsButton link={link} newPage={!isInAppView} asAnchor={isInAppView} dark={false}>
          Mehr Informationen
        </LinkAsButton>
      </div>
    );
  }

  renderAddArrivalDepartureInfo(): Node {
    const { arrivalDeparture, railVoucher } = this.props;

    if (arrivalDeparture && arrivalDeparture.hasInbound && arrivalDeparture.hasOutbound) {
      return null;
    }
    // TUICUNIT-3337
    if (arrivalDeparture && arrivalDeparture.hasData && arrivalDeparture.hasTrain && railVoucher) {
      return null;
    }

    const operator = arrivalDeparture ? arrivalDeparture.operatorInfo : operatorInfo.unknown;
    const agencyPhoneNumber = arrivalDeparture ? arrivalDeparture.agencyPhoneNumber : null;
    const callableAgencyPhoneNumber = arrivalDeparture ? arrivalDeparture.callableAgencyPhoneNumber : null;
    return (
      <div className="l-col double one-empty">
        <h2 className="contact-block-headline">{operator.addHeadline}</h2>
        <div className="no-arrival-departure" dangerouslySetInnerHTML={{ __html: operator.addText }} />

        <div style={{ marginBottom: '20px' }}>
          <LinkAsButton link={'https://www.meinschiff.com/an-und-abreise'} newPage={true} dark={true}>
            Allgemeine Informationen
          </LinkAsButton>
        </div>

        {callableAgencyPhoneNumber && (
          <div className="contact-block">
            {operator.contactHeadline && <h2>{operator.contactHeadline}</h2>}

            {operator.viewMail ? (
              <MailBlock />
            ) : (
              <PhoneBlock phoneNumber={callableAgencyPhoneNumber} displayPhoneNumber={agencyPhoneNumber} />
            )}
            {operator.openingTimes && (
              <div className="opening-times" dangerouslySetInnerHTML={{ __html: operator.openingTimes }} />
            )}
          </div>
        )}
        <div className="l-col double arrival-departure-items">
          <div className="l-mod l-block">
            <div className="arrival-departure-list">{this.renderSleepAndPark()}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { arrivalDeparture, selectedMpi } = this.props;
    const { inboundOutbound } = arrivalDeparture;
    if (!inboundOutbound || !selectedMpi) return null;

    const checkItemsI = this.filterByMpi(inboundOutbound.inbound) || [];
    const checkItemsO = this.filterByMpi(inboundOutbound.outbound) || [];
    const checkItems = checkItemsI.concat(checkItemsO);

    return (
      <div>
        {checkItems.find((i) => i.isTrain === true) ? (
          <div className="l-col double arrival-departure-items train-i">
            Bitte führen Sie Ihre Bahntickets während Ihrer An- und Abreise in ausgedruckter Form mit sich. Die DB
            Reservierungsnummer können Sie bei Bedarf Ihren Bahntickets entnehmen.
          </div>
        ) : null}
        {this.renderArrivalDepartureBlock(inboundOutbound.inbound, 'Ihre Anreise')}
        {this.renderArrivalDepartureBlock(inboundOutbound.outbound, 'Ihre Abreise')}
        {this.renderAddArrivalDepartureInfo()}
      </div>
    );
  }
}
