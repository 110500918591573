// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import type MasterStore from '../../../stores/MasterStore';
import type SpaBalconyCabinStore from '../../../stores/SpaBalconyCabinStore';
import type SpaBalconyCabin from '../../../models/SpaBalconyCabin';
import type SpaListItem from '../../../models/SpaBalconyCabin/SpaListItem';

import LoadingIndicator from '../../LoadingIndicator';
import Headline from '../../Headline';
import CatalogTile from '../../tiles/CatalogTile';
import InfoBox from '../../InfoBox';
import InfoBoxSvg from '../../InfoBoxSvg';
import InfoIcon from '../../../../assets/svg/inline/ic-info.inline.svg';
import Duration from '../../Duration';
import ImageSlider from '../../ImageSlider';

import { computed } from 'mobx/lib/mobx';

type Props = {
  masterStore: MasterStore,
  spaBalconyCabinStore: SpaBalconyCabinStore,
};

@inject('masterStore', 'spaBalconyCabinStore')
@observer
export default class PageSpaBalconyCabin extends Component<Props> {
  static breadcrumb = 'SPA Balkonkabine';

  @computed
  get store(): SpaBalconyCabinStore {
    const { spaBalconyCabinStore } = this.props;
    return spaBalconyCabinStore;
  }

  componentDidMount() {
    this.store.fetchSpaBalconyCabin();
  }

  renderPackageList(spaBalconyCabin: SpaBalconyCabin) {
    // TODO Labels (z.B: Reserviert). Ich weiß nicht, ob die bereits korrekt angezeigt werden
    const { list, subline } = spaBalconyCabin;

    if (!list || list.length === 0) {
      return (
        <InfoBox noIcon>
          Zur Zeit stehen keine SPA Pakete zur Verfügung.
        </InfoBox>
      );
    }

    return (
      <div>
        <h1>{subline}</h1>
        <div className="l-tiles">
          {list.map((pkg: SpaListItem) => (
            <CatalogTile
              key={pkg.id}
              // TODO check CatalogTile, as this is only used for preliminary and to check for "not falsy"
              // chose pkg over pkg.booking, see TUICUNIT-1397
              booking={(pkg.isPreliminary || pkg.isBooked) && pkg}
              link={`/spa-balkonkabine/${pkg.id}`}
              imageSrc={pkg.image}
              title={pkg.title}
              footer={<Duration duration={pkg.duration} />}
              zeroPriceMeansInclusive={true}
              isBooked={pkg.isBooked}
              price={pkg.price || 0}
              // see TUICUNIT-1397
              {...(pkg.isBooked || pkg.isPreliminary
                ? { isBookedBadge: pkg.status }
                : {})}
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    if (this.store.isLoading) {
      return <LoadingIndicator />;
    }

    const { spaBalconyCabin } = this.store;
    if (!spaBalconyCabin) return null;
    const { title } = spaBalconyCabin;

    return (
      <div className="page">
        <Headline className="l-mod" title={title} subtitle={''} />
        <ImageSlider {...spaBalconyCabin} />
        <p
          className="description"
          dangerouslySetInnerHTML={{
            __html:
              spaBalconyCabin.descriptionHtml || spaBalconyCabin.description,
          }}
        />

        <InfoBoxSvg
          className="no-center-icon"
          icon={<InfoIcon className="inline-svg" />}
          text="Bitte beachten Sie, dass die Reservierung Ihres inkludierten Fitness-Workshops in diesem Buchungsschritt nicht möglich ist. Bitte gehen Sie dazu auf die Meine Reise Startseite und wählen Sie im Bereich „Programm“ Ihren gewünschten Fitness-Workshop aus. Selbstverständlich werden wir Ihnen die Leistung an Bord nicht in Rechnung stellen. Die Reservierung ist außerdem natürlich auch direkt an Bord im Bereich Sport & Gesundheit möglich."
        />
        <div className="l-row group" />
        {this.renderPackageList(spaBalconyCabin)}
      </div>
    );
  }
}
