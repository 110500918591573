// @flow

import React, { Component, type Node } from 'react';
import AccordionItemSingleMode from './AccordionItemSingleMode';

type Item = {
  header: Node | string,
  content: Node | string,
};

type Props = {
  items: Item[],
  expanded?: number,
  className?: ?string,
};

type State = {
  expanded: ?number,
};

class AccordionSingleMode extends Component<Props, State> {
  state = {
    expanded: null,
  };

  constructor(props: Object) {
    super(props);
    this.state.expanded = props.expanded;
  }

  render() {
    const { items, className } = this.props;
    const { expanded } = this.state;

    return (
      <div className={`accordion ${className || ''}`}>
        {items.map((item, index) => (
          <AccordionItemSingleMode
            key={index}
            expanded={index === expanded}
            header={item.header}
            onChange={isExpanded => {
              this.setState({
                expanded: isExpanded ? index : null,
              });
            }}
          >
            {item.content}
          </AccordionItemSingleMode>
        ))}
      </div>
    );
  }
}

export default AccordionSingleMode;
