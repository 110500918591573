// @flow

import format from 'date-fns/format';
import isToday from 'date-fns/is_today';
import isTomorrow from 'date-fns/is_tomorrow';
import isYesterday from 'date-fns/is_yesterday';

import locale from 'date-fns/locale/de';

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const DATE_REGEX_DOTTED = /^\d{2}.\d{2}.\d{4}$/;

const DATE_FORMAT_DASHED = 'dashed';
const DATE_FORMAT_DOTTED = 'dotted';

// SUPTUICMRS-2547 && TUICUNIT-1370 TODO HACK: should be solved via ISO-Date by backend first
const adjustedDate = (time: number | Date | string): Date => {
  // @see: https://css-tricks.com/everything-you-need-to-know-about-date-in-javascript/
  // NOTE: MDN recommends against creating date with date strings.

  // Winterzeit: Europe/Berlin UTC+1 => 1 Hours -> 60 Minutes
  // Sommerzeit: Europe/Berlin UTC+2 => 2 Hours -> 120 Minutes
  // const tuiTimezoneOffset = 60;

  // makes this manually setting dynamic
  const date = new Date();
  // Time is irrelevant, we need only date
  const nowDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // tuiTimezoneOffset setting is now dynamic (Summer Winter Time)
  const tuiTimezoneOffset = Math.abs(nowDate.getTimezoneOffset());

  const localTimezoneOffset = new Date().getTimezoneOffset();
  const timezoneOffset = (localTimezoneOffset + tuiTimezoneOffset) * 60 * 1000;

  if (!(time instanceof Date)) {
    time = new Date(time);
  }
  return new Date(time.getTime() + timezoneOffset);
};

/**
 * Adds width and height attributes to its images urls
 */
const formatCalendar = (date: Date | string): string => {
  let prefix = 'dddd';
  if (isToday(date)) prefix = 'Heute';
  if (isTomorrow(date)) prefix = 'Morgen';
  if (isYesterday(date)) prefix = 'Gestern';

  if(prefix !== 'dddd') {
    return `${prefix}, ${format(date, 'DD.MM.', { locale })}`;
  }
  return format(date, 'dddd, DD.MM.', { locale });
  
};

const formatDate = (date: Date | string): string =>
  format(date, 'dddd, DD.MM.', { locale });

const formatTime = (date: Date): string => format(date, 'HH:mm [Uhr]');

/**
 * Parses a date in YYYY-MM-DD format
 */
const parseDate = (
  date: string,
  formatType: string = DATE_FORMAT_DASHED
): Date | null | typeof undefined => {
  if (formatType === DATE_FORMAT_DASHED) {
    if (!DATE_REGEX.test(date)) return null;
    const [year, month, day] = date.split('-');

    return adjustedDate(new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)));
  } else if (formatType === DATE_FORMAT_DOTTED) {
    if (!DATE_REGEX_DOTTED.test(date)) return null;
    const [day, month, year] = date.split('.');

    return adjustedDate(new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10)));
  }
};

// TUICUNIT-1490, TUICUNIT-891
const gmtZoneToMilliseconds = (gmtZone: ?string): number | null => {
  if (!gmtZone) return null;

  try {
    const sign = parseInt(gmtZone, 10) < 0 ? -1 : 1;
    const x = gmtZone.match(/[0-9]+/g);

    if (x && x[0]) {
      const hours = parseInt(x[0].substr(0, 2), 10);
      const minutes = parseInt(x[0].substr(2), 10);
      return sign * (hours * 60 * 60 * 1000 + minutes * 60 * 1000);
    }

    return null;
  } catch (exc) {
    return null;
  }
};

/**
 * cleanUpDateString
 * input:   2021-01-10T13:00:00+01:00[Europe/Berlin]
 * output:  2021-01-10T13:00:00+01:00
 * */
export const cleanUpDateString = (date: string): string => {
  return date.replace(/(\[\D*\])/, '');
};

/**
 * input: ISO-8601
 * */
export const getTimezoneOffsetInMinutes = (
  isoString: string
): number | null => {
  const result = /[+-][\d]{2}:[\d]{2}/.exec(isoString); // e.g. -05:00 | +03:00
  if (result && result[0]) {
    try {
      // tmp[0]: hours
      // tmp[1]: minutes
      let tmp = result[0].split(':');
      return parseInt(tmp[0], 10) * 60 + parseInt(tmp[1], 10);
    } catch (exc) {}
  }

  return null;
};

/**
 * input: ISO-8601
 * NOTE: return Date timezone will be invalid! (You can't change the timezone value.)
 * */
export const getDateUnaffectedByLocalTimezone = (
  isoString: string
): Date | null => {
  try {
    const foreignOffset = getTimezoneOffsetInMinutes(isoString);
    if (foreignOffset !== null) {
      const date = new Date(cleanUpDateString(isoString));
      const localOffset = new Date().getTimezoneOffset(); // offset in negated minutes
      date.setMinutes(date.getMinutes() + localOffset);
      date.setMinutes(date.getMinutes() + foreignOffset);
      return date;
    }
  } catch (exc) {}
  return null;
};


export {
  formatCalendar,
  formatDate,
  formatTime,
  parseDate,
  DATE_FORMAT_DASHED,
  DATE_FORMAT_DOTTED,
  adjustedDate,
  gmtZoneToMilliseconds,
};
