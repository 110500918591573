// @flow
import { observable, action } from 'mobx';

export default class BookingUiStore {
  @observable showBookingConfirmation = false;
  @observable returnedFromConfirmation = false;
  @observable showBookingOptions = false;
  @observable returnedFromOptions = false;
  @observable showBookingOptionsConfirm = false;

  @action.bound
  openBookingDialog() {
    this.showBookingOptions = false;
    this.showBookingConfirmation = true;
  }

  @action.bound
  cancelBookingDialog() {
    this.showBookingConfirmation = false;
    this.returnedFromConfirmation = true;
    this.showBookingOptions = false;
  }

  @action.bound
  openBookingOptions() {
    this.showBookingOptions = true;
  }

  @action.bound
  cancelBookingOptionsConfirm() {
    this.showBookingOptions = true;
    this.showBookingOptionsConfirm = false;
  }

  @action.bound
  cancelBookingOptions() {
    this.showBookingOptions = false;
    this.showBookingOptionsConfirm = false;
    this.returnedFromOptions = true;
  }

  @action.bound
  openBookingOptionsConfirm() {
    this.showBookingOptions = false;
    this.showBookingOptionsConfirm = true;
  }

  @action.bound
  reset() {
    this.showBookingConfirmation = false;
    this.returnedFromConfirmation = false;
    this.showBookingOptions = false;
    this.returnedFromOptions = false;
  }
}
