// @flow
import type PartyMember from '../models/PartyMember';

export default class BoardingPass {
  mpi: number;
  pdfLink: string;
  pdfLinkPrint: string;
  walletLink: string;
  googleWalletLink: string;
  member: PartyMember;

  constructor(data: Object, member: PartyMember) {
    Object.assign(this, data);
    this.member = member;
  }
}
