// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../utils/date';

import Booking from '../../models/Booking';

import BaseList from './BaseList';

const BookedSpaList = ({
  header,
  copy,
  bookings,
  masterData,
  onCancelBooking,
}) => (
  <BaseList
    header={header}
    copy={copy}
    bookings={bookings}
    onCancelBooking={onCancelBooking}
    title={(booking) =>
      `${booking.mpis
        .map((mpi) => masterData.getPartyMember(mpi).displayName)
        .join(', ')}${booking.prelimenary ? ' (in Bearbeitung)' : ''}`
    }
    info={(booking) =>
      booking.startTime
        ? `${formatDate(booking.startDate)}, ${booking.startTime} Uhr`
        : `${formatDate(booking.startDate)}`
    }
  />
);

BookedSpaList.propTypes = {
  header: PropTypes.string,
  copy: PropTypes.string,
  masterData: PropTypes.object.isRequired,
  bookings: PropTypes.arrayOf(PropTypes.instanceOf(Booking)).isRequired,
  onCancelBooking: PropTypes.func.isRequired,
};

export default BookedSpaList;
