// @flow
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (): string => {
  return (mock || mockAll) && isLocal
    ? `/mock/maintenance.json`
    : `/api/land/content/global/maintenance`;
};

export default {
  get: (): Promise<ApiResponse> =>
    fetch(getUrl()).then((response) => response.json()),
};

export type ResponseType = {
  headline: string | null,
  text: string,
};
export type ApiResponse = [ResponseType];

export type ApiErrorResponse = {
  error: Boolean,
};

export type ApiType = {
  get: () => Promise<ApiResponse>,
};
