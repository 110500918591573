// @flow

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import type User from '../models/User';
import type MasterData from '../models/MasterData';

import { isLocal, mockAll } from './utils';

export const sanitizers = {
  internetList: data => {
    const meta = data;
    const list = data.content.map(camelCaseKeys);

    return {
      list,
      meta,
    };
  },
  internetDetails: data => ({
    ...camelCaseKeys(data),
    title: data.headline,
  }),

  parkingList: data => {
    const meta = data;
    const list = data.content.map(item => item.data[0]).map(camelCaseKeys);

    return {
      meta,
      list,
    };
  },
  parkingDetails: data => ({
    ...camelCaseKeys(data),
    title: data.headline,
  }),

  packagePage: data => data,
  packageList: data => {
    const meta = data;
    const list = data.content.map(item => item.data[0]).map(camelCaseKeys);

    return {
      meta,
      list,
    };
  },
  packageDetails: data => ({
    ...camelCaseKeys(data),
    title: data.headline,
  }),

  mediaList: data => {
    const meta = data;
    const list = data.content.map(item => item.data[0]).map(camelCaseKeys);

    return {
      list,
      meta,
    };
  },

  newspaperList: data => {
    data = camelCaseKeys(data, true);
    const list = data.vacancies;
    return {
      list,
      meta: {
        ...data,
      },
    };
  },
};

const mock = false;
const getInternetListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/internet/TUICUNIT-3542.json`
    : `/api/land/internet/${ed}/${dd}/${shipId}`;
};

const getInternetDetailsUrl = (
  masterData: MasterData,
  id: number | string
): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/internet/details.json`
    : `/api/land/internet/detail/${id}/${ed}/${dd}/${shipId}`;
};

const getParkingListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/list.json`
    : `/api/land/packages/parking/${ed}/${dd}/${shipId}`;
};

const getParkingDetailsUrl = (
  masterData: MasterData,
  id: number | string
): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/packages/detail/${id}/${ed}/${dd}/${shipId}`;
};

const getPackagePageUrl = (masterData: MasterData): string => {
  const { shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/content/ship-packages/${shipId}`;
};

const getPackageListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/packages/${ed}/${dd}/${shipId}`;
};

const getPackageDetailsUrl = (
  masterData: MasterData,
  id: number | string
): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/packages/detail/${id}/${ed}/${dd}/${shipId}`;
};

const getMediaListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/packages/media/${ed}/${dd}/${shipId}`;
};

const getNewspaperListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/package/TBD.json`
    : `/api/land/packages/newspaper-list/${ed}/${dd}/${shipId}`;
};

export default sanitized(
  {
    internetList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getInternetListUrl(masterData)),
    internetDetails: (
      user: User,
      masterData: MasterData,
      id: number | string
    ) => fetchForUser(user, getInternetDetailsUrl(masterData, id)),
    parkingList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getParkingListUrl(masterData)),
    parkingDetails: (user: User, masterData: MasterData, id: number | string) =>
      fetchForUser(user, getParkingDetailsUrl(masterData, id)),
    packagePage: (user: User, masterData: MasterData) =>
      fetchForUser(user, getPackagePageUrl(masterData)),
    packageList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getPackageListUrl(masterData)),
    packageDetails: (user: User, masterData: MasterData, id: number | string) =>
      fetchForUser(user, getPackageDetailsUrl(masterData, id)),
    mediaList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getMediaListUrl(masterData)),
    newspaperList: (user: User, masterData: MasterData) =>
      fetchForUser(user, getNewspaperListUrl(masterData)),
  },
  sanitizers
);
