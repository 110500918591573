// @flow
import { observable, computed, action } from 'mobx';

import BookingRequestBase from './BookingRequestBase';

import PartyMember from '../../models/PartyMember';
import Package from '../../models/Package';
import BookedPackage from '../../models/BookedPackage';
import Booking from '../../models/Booking';

import { BOOKING_ADDED, BOOKING_DELETED } from './constants';

import type {
  BookingType,
  IGeneralBookingData,
  IGeneralItemToBook,
  IBookingCancelationData,
  IPurchaseDetails,
} from '../../types/booking';

export default class PackageBookingRequest extends BookingRequestBase {
  static TEXTS = {
    listingType: request => {
      if (request.bookable.isDVD || request.bookable.isGuide) {
        return 'Produkt';
      }
      return 'Paket';
    },
    create: {
      confirmation: {
        title: 'Ihre Reservierungsanfrage',
        text: 'Wollen Sie die folgende Leistung reservieren?',
      },
      success: {
        title: 'Ihre Reservierungsanfrage ist eingegangen.',
        text: request => {
          if (request.bookable.isDVD) {
            return 'Bitte entnehmen Sie die Abholzeit für Ihr persönliches Exemplar aus dem Tagesprogramm.';
          }
          return 'Ihre Reservierungsanfrage ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.';
        },
      },
    },
    cancellation: {
      confirmation: {
        title: 'Ihre Stornierungsanfrage',
        text: 'Wollen Sie die folgende Leistung wirklich stornieren?',
      },
      success: {
        title: 'Ihre Stornierungsanfrage ist eingegangen.',
        text:
          'Ihre Stornierung ist bei uns eingegangen. Den aktuellen Status können Sie in Ihrem Reiseplan einsehen.',
      },
    },
  };

  @observable selectedCount = 0;
  @observable bookingToCancel: ?Booking;
  @observable bookedPackages: ?(BookedPackage[]);

  selectedMpi: ?number = null;

  constructor(
    travelParty: PartyMember[],
    bookable: Package,
    bookedPackages: ?(BookedPackage[])
  ) {
    super(travelParty, bookable);
    this.bookedPackages = bookedPackages;
    const selectedUser = travelParty.find(m => m.requestor);
    this.selectedMpi = selectedUser ? selectedUser.mpi : null;
  }

  @computed
  get bookedPackagesOfSameType(): BookedPackage[] {
    return (this.bookedPackages || []).filter(
      pkg => pkg.typeId === this.bookable.typeId
    );
  }

  @computed
  get hasBookedSomethingOfSameType(): boolean {
    return this.bookedPackagesOfSameType.length > 0;
  }

  @action
  selectCount(count: number) {
    this.selectedCount = count;
  }

  @action
  reset() {
    this.bookingToCancel = null;
  }

  @action
  cancelBooking(booking: Booking) {
    this.bookingToCancel = booking;
  }

  @computed
  get isChangeRequest(): boolean {
    return false;
  }

  @computed
  get isCancellation(): boolean {
    return !!this.bookingToCancel;
  }

  createItemToBook(mpi: number, type: BookingType): IGeneralItemToBook {
    const member = this.travelParty.find(p => p.mpi === mpi);

    return {
      mpi: member ? member.mpi : 0, // 0 should not happen, but flowtype needs something
      name: this.bookable.headline,
      price: this.bookable.price,
      error: this.errors ? this.errors[mpi] : null,
      quantity:
        this.bookingToCancel && this.bookingToCancel.itemCount
          ? this.bookingToCancel.itemCount
          : this.selectedCount,
      type,
    };
  }

  @computed
  get itemsToBook(): IGeneralItemToBook[] {
    if (this.isCancellation && this.bookingToCancel) {
      return this.bookingToCancel.mpis.map(mpi =>
        this.createItemToBook(mpi, BOOKING_DELETED)
      );
    }
    return this.isReady && this.selectedMpi
      ? [this.createItemToBook(this.selectedMpi, BOOKING_ADDED)]
      : [];
  }

  @computed
  get total(): number {
    return this.bookable.price * this.selectedCount;
  }

  @computed
  get isReady(): boolean {
    return this.selectedCount > 0;
  }

  get startDateTime(): ?Date {
    return null;
  }

  get endDateTime(): ?Date {
    return null;
  }

  @computed
  get bookingData(): IGeneralBookingData[] {
    if (this.isCancellation || !this.isReady) return [];
    return [
      {
        type: 'package',
        vacancyId: this.bookable.vacancyId,
        quantity: this.selectedCount,
      },
    ];
  }

  @computed
  get cancelData(): IBookingCancelationData[] {
    if (!this.isCancellation || !this.bookingToCancel) return [];
    const mpisToCancel = this.bookingToCancel.mpis;
    const bookedIds = this.bookingToCancel.bookingId;

    return mpisToCancel.map(mpi => ({
      type: 'package',
      bookingId: bookedIds[mpi],
      mpis: [mpi],
    }));
  }

  /**
   * Returns all the details of products and purchases of all the items that will be
   * booked or cancelled.
   */
  get purchaseDetails(): IPurchaseDetails {
    const member = this.travelParty.find(p => p.requestor);
    const { headline, bookingId, analyticsCategory } = this.bookable;
    return {
      products: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}`,
              name: headline,
              category: analyticsCategory,
              quantity: this.selectedCount,
              price: member ? this.getPriceForMember(member) : 0,
            },
          ],
      purchases: this.isCancellation
        ? []
        : [
            {
              id: `${bookingId}-${this.selectedCount}`,
              revenue: this.total,
            },
          ],
      refunds:
        this.isCancellation &&
        this.bookingToCancel &&
        this.bookingToCancel.itemCount
          ? [
              {
                id: `${this.bookingToCancel.typeId}-${
                  this.bookingToCancel.itemCount
                }`,
              },
            ]
          : [],
    };
  }
}
