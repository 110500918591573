// @flow
import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import FormInput from '../../../components/form/Input';
import FormCheckbox from '../../../components/form/Checkbox';

import { obfuscateIban } from '../../../utils/payment';

import type PaymentStoreRequest from '../../../models/PaymentStoreRequest';
type Props = {
  storeRequest: PaymentStoreRequest,
  disabled: ?boolean,
};

@observer
export default class PaymentSepaOption extends Component<Props> {
  data = {};
  @observable error = null;
  @observable notAgreedError = false;

  @action
  validateForm() {
    this.notAgreedError = false;
    if (!this.props.storeRequest.sepaAgreement) {
      this.notAgreedError = true;
      return false;
    }
    return true;
  }

  handleInputChange(key: string, value: string) {
    this.props.storeRequest.saveRequestData(key, value);
  }

  @autobind
  handleAgreementChange(value: boolean) {
    this.props.storeRequest.toggleSepaAgreement(value);
  }

  render() {
    const { storeRequest, disabled } = this.props;
    const { paymentData } = storeRequest;

    return (
      <div className="l-mod-sub">
        {this.error ? <p className="error-message">{this.error}</p> : null}
        <div className="l-row">
          <div className="l-col">
            <FormInput
              disabled={disabled}
              label="IBAN"
              name="iban"
              value={paymentData && obfuscateIban(paymentData.iban)}
              onChange={value => this.handleInputChange('iban', value)}
            />
          </div>
        </div>
        <FormCheckbox
          checked={storeRequest.sepaAgreement}
          disabled={disabled}
          name="agreement"
          label="Ich ermächtige die TUI Cruises GmbH Zahlungen von meinem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von der TUI Cruises GmbH auf mein Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen."
          onChange={this.handleAgreementChange}
          hasError={storeRequest.notAgreedToSepaError}
        />
      </div>
    );
  }
}
