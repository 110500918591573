// @flow

import { observable, action } from 'mobx';
import type MasterStore from './MasterStore';
import type { ApiType } from '../api/brochure';

import Brochure from '../models/Brochure';

export default class BrochureStore {
  @observable brochures = {};
  @observable loading = true;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action
  setLoading(state: boolean) {
    this.loading = state;
  }

  fetch(tripCode: string): Promise<void> | Brochure {
    if (this.brochures[tripCode]) {
      return this.brochures[tripCode];
    }

    this.setLoading(true);
    return this.api.get(this.masterStore.user, tripCode).then(
      (brochure: Brochure) => {
        this.receiveBrochure(brochure, tripCode);
        this.setLoading(false);
      },
      () => {
        this.setLoading(false);
      }
    );
  }

  @action
  receiveBrochure(brochure: Brochure, tripCode: string) {
    this.brochures[tripCode] = new Brochure(brochure);
  }
}
