import { useState, useEffect } from 'react';

export default function useMediaQuery(query: string) {
  const [doesMatch, onSetDoesMatch] = useState(false);
  useEffect(() => {
    const onUpdateMatch = ({ matches }: MediaQueryListEvent) => {
      onSetDoesMatch(matches);
    };

    const mql: MediaQueryList = window.matchMedia(query);
    const isModern = 'addEventListener' in mql;

    if (isModern) {
      mql.addEventListener('change', onUpdateMatch);
    } else {
      mql.addListener(onUpdateMatch);
    }

    onUpdateMatch(mql);
    return () => {
      if (isModern) {
        mql.removeEventListener('change', onUpdateMatch);
      } else {
        mql.removeListener(onUpdateMatch);
      }
    };
  }, [query, onSetDoesMatch]);
  return doesMatch;
};
