// @flow

import React from 'react';
import classNames from 'classnames';

const LEVELS = [1, 2, 3, 4];

type Props = {
  value: number,
  label?: string,
};

const Rating = ({ label, value }: Props) => (
  <span className="rating">
    {label ? `${label}: ` : null}
    {LEVELS.map(level => (
      <span
        key={level}
        className={classNames({
          rating__dot: true,
          easy: level === 1 && value === 0,
          active: value >= level,
        })}
      />
    ))}
  </span>
);

export default Rating;
