// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import requirements from '../../../components/pages/decorators/requirements';
import Headline from '../../../components/Headline';
import BlockList from '../../../components/lists/BlockList';
import LinkAsButton from '../../../components/LinkAsButton';

import type MasterStore from '../../../stores/MasterStore';
import type TimeSloteStore from '../../../stores/TimeSloteStore';
import track from '../../../tracking';

type Props = {
  masterStore: MasterStore,
  timeSloteStore: TimeSloteStore,
};
@inject('masterStore', 'timeSloteStore', 'breadcrumbRouterStore')
@requirements(['checkinEnabled'])
@observer
export default class PageCheckInProcess extends Component<Props> {
  static breadcrumb = 'Bestätigung';

  componentDidMount() {
    this.props.masterStore.reloadMasterData().then(() => {
      this.props.timeSloteStore.fetch();
    });

    // Page View Tracking
    let titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles) {
      track.pageView(
        window.location.pathname,
        titles,
      );
    }
  }

  getCheckInMember() {
    const { timeSloteStore } = this.props;
    const { travelParty, isGroupBooking } = this.props.masterStore.masterData;

    const timeSloteBooking = timeSloteStore.getBookingList;

    let travelInfo = [];

    if (timeSloteStore.isLoading === true) {
      return travelInfo;
    }

    travelParty.forEach((member) => {
      if (member.checkinCompleted === true) {
        let timeInfo1 = null;
        let timeInfo2 = null;

        // tuic travel?
        if (member.hasTuicTravelToShip === true) {
          timeInfo1 = 'Anreise findet mit TUI Cruises statt';
        }

        // timslote event?
        if (!timeInfo1) {
          const bookedTimeSlote = timeSloteBooking.find((part) => part.mpi === member.mpi);
          if (bookedTimeSlote) {
            timeInfo1 = bookedTimeSlote.label;
          }
        }

        // arrivalAt?
        if (!timeInfo1) {
          if (member.arrivalAt && member.arrivalAt.where && member.arrivalAt.time && !isGroupBooking) {
            const mapping = {
              airport: 'Flughafen',
              port: 'Hafen',
            };
            const where = mapping[member.arrivalAt.where];
            if (!where) return null;
            timeInfo1 = where ? `Ankunftsort: ${where}` : '';
            timeInfo2 = `Ankunftszeit: ${member.arrivalAt.time} Uhr`;
          }
        }

        travelInfo.push({
          displayName: member.displayName,
          text1: timeInfo1,
          text2: timeInfo2,
        });
      }
    });

    return travelInfo;
  }

  render() {
    const { isLoading } = this.props.timeSloteStore;

    return !isLoading ? (
      <div className="page page-manifest-success">
        <Headline title="Online Check-in erfolgt" />
        <p>
          Vielen Dank, dass Sie online eingecheckt haben. Wir haben Ihnen den Schiffspass/die Schiffspässe an Ihre
          hinterlegte E-Mail Adresse gesendet.
        </p>
        <p>
          Sobald alle Informationen erfolgreich an das Bordsystem übermittelt wurden, haben Sie die Möglichkeit den
          Schiffspass/die Schiffspässe auf der Check-in Übersicht herunterzuladen und auszudrucken.
        </p>
        <br />
        <h2>Sie haben folgende Teilnehmer eingecheckt:</h2>
        <BlockList inverted className="checkin-summary success">
          {this.getCheckInMember().map((member, index) => {
            return (
              <BlockList.Item key={index}>
                <span className="member">{member.displayName}</span>
                <span className="note-wrapper">
                  {member.text1 && <span className="note1">{`${member.text1}`}</span>}
                  {member.text2 && <span className="note2">{`${member.text2}`}</span>}
                </span>
              </BlockList.Item>
            );
          })}
        </BlockList>
        <br />
        <div className="l-right">
          <p>
            <LinkAsButton big dark link="/checkin">
              Zur Check-in Übersicht
            </LinkAsButton>
          </p>
        </div>
      </div>
    ) : null;
  }
}
