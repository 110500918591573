// TODO: add flow types

import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

function handleHash(hash, method, recursive) {
  if (!hash || typeof hash !== 'object') {
    return hash;
  }
  if (Array.isArray(hash)) {
    return hash.map(obj => handleHash(obj, method, recursive));
  } else {
    return Object.keys(hash).reduce((memo, key) => {
      memo[method(key)] = recursive
        ? handleHash(hash[key], method, recursive)
        : hash[key];
      return memo;
    }, {});
  }
}
function camelCaseKeys(hash, recursive = false) {
  return handleHash(hash, camelCase, recursive);
}

function snakeCaseKeys(hash, recursive = false) {
  return handleHash(hash, snakeCase, recursive);
}

export { camelCaseKeys, snakeCaseKeys };
