// @flow

let envConfig;

export function getServerEnv() {
  if (window.location.host.indexOf('dev.') !== -1) return 'development';
  if (window.location.host.indexOf('meinereise.test.') !== -1) return 'stage';
  if (window.location.host.indexOf('prelive.') !== -1) return 'prelive';
  return 'production';
}

try {
  envConfig = require(`./general/${getServerEnv()}`).default;
} catch (e) {
  envConfig = {};
}

const account = 'https://meinereisen.meinschiff.com';
export default {
  trackingCode: 'qa', // 'UA-6438006-XX',
  cookieName: 'tuicUser', // cookieName has to be synced to Kundenkonto (needed for global logout)
  sessionCookieName: 'tuic-mrl',
  logoutURL: 'https://meinereise.meinschiff.com/login',
  account,
  accountLogin: `${account}/signin`,
  accountRegister: `${account}/signin/registration`,
  excursionMultiSelectCacheKey: 'travelPartyState',
  publicHealthQuestionnaire: 'https://meinereise.meinschiff.de/config/fragebogen/current.pdf',
  iadvizeId: '6761',
  ...envConfig,
};
