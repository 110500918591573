// @flow
import { computed } from 'mobx';

import type MasterStore from '../stores/MasterStore';
import type Booking from '../models/Booking';
import type { IBookableVacancy } from '../types/vacancies';

export default class Bookable {
  id: number;
  name: string;
  mpis: number;
  +price: number;
  headline: string;
  vacancyId: string;
  category: string;
  +date: string;
  status: string;
  statusCode: number;
  typeId: string;
  masterStore: MasterStore;
  vacancies: IBookableVacancy[];
  inclusive: boolean;

  // flow
  isPriceBulk: any;
  tourSalesTermsId: any;
  priceAdult: any;
  priceChild: any;

  constructor(data: Object, masterStore: MasterStore) {
    Object.assign(this, data);
    this.masterStore = masterStore;
    //this.inclusive = false;
  }

  @computed
  get booking(): Booking {
    return this.masterStore.bookings.find(
      booking => booking.typeId === this.id
    );
  }

  // TUICUNIT-2638: waiting list, mixed group dirty crap
  get exbooking(): Booking {
    if (this.type === 'excursionDetail') {
      let masterStoreBookings = JSON.parse(
        JSON.stringify(this.masterStore.bookings)
      );
      const relevanteBookings = masterStoreBookings.filter(
        i => i.typeId === this.id
      );
      const hasSomeWaitlisted = relevanteBookings.some(
        i => i.isWaitListed === true
      );
      const hasSomeprelimenary = relevanteBookings.some(
        i => i.prelimenary === true
      );

      if (hasSomeWaitlisted && relevanteBookings.length) {
        let bookingCombined = {};
        relevanteBookings.forEach((element, index) => {
          if (index === 0) {
            bookingCombined = element;
            bookingCombined.prelimenary = hasSomeprelimenary;
            bookingCombined.isSomeWaitListed = true;
            bookingCombined.waitListMpis = element.isWaitListed
              ? element.mpis
              : [];
            bookingCombined.mpis = element.mpis || [];
          } else {
            bookingCombined.bookingId = Object.assign(
              bookingCombined.bookingId,
              element.bookingId
            );
            bookingCombined.mpis = bookingCombined.mpis.concat(element.mpis);
            bookingCombined.waitListMpis = element.isWaitListed
              ? bookingCombined.waitListMpis.concat(element.mpis)
              : bookingCombined.waitListMpis;
          }
        });

        return bookingCombined;
      } else {
        return this.masterStore.bookings.find(
          booking => booking.typeId === this.id
        );
      }
    }
    return this.masterStore.bookings.find(
      booking => booking.typeId === this.id
    );
  }

  get isBooked(): boolean {
    return !!this.booking;
  }

  get prelimenary(): boolean {
    return this.booking && this.booking.prelimenary;
  }

  @computed
  get bookedMpis(): number[] {
    return this.isBooked ? this.booking.mpis : [];
  }

  get bookingId(): number {
    return this.id;
  }

  get isBookable(): boolean {
    return this.statusCode === 0;
  }

  get bookingStatusCode(): number {
    return this.statusCode;
  }

  get bookingStatus(): string {
    return this.status;
  }

  get bookingName(): string {
    throw new Error('Implement me');
  }

  get startDateTime(): ?Date {
    throw new Error('Implement me');
  }

  get endDateTime(): ?Date {
    throw new Error('Implement me');
  }

  @computed
  get analyticsCategory(): string {
    return this.category;
  }

  set isInclusive(isInclusive: boolean) {
    this.inclusive = isInclusive;
  }

  get isInclusive(): boolean {
    return this.inclusive;
  }

  getPriceTypes(): any {}
  get date(): any {}
  get day(): any {}
  get requiresTourSalesTermsNotice(): any {}
}
