// @flow
import { kebabCase as _kebabCase } from 'lodash';

export function kebabCase(text: string): string {
  if (typeof text === 'undefined') {
    return '';
  }

  const umlautMap = {
    'ä': 'ae',
    'Ä': 'Ae',
    'ü': 'ue',
    'Ü': 'Ue',
    'ö': 'oe',
    'Ö': 'Oe',
    'ß': 'ss',
  };

  for (const [key: string, value: string] of Object.entries(umlautMap)) {
    text = text.replaceAll(key, value);
  }

  return _kebabCase(text);
}
