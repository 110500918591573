// @flow

import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Menu from './Menu';
import NavEntries from './NavEntries';
import LinkExternal from './LinkExternal';

import useNavigation from '../../hooks/useNavigation';
import useClickOutside from '../../hooks/useClickOutside';

import { font, colors, dimensions, linkedLogos } from './styles';

import AtIcon24 from '../../../assets/svg/inline/at-icon-24.inline.svg';
import PhoneIcon24 from '../../../assets/svg/inline/phone-icon-24.inline.svg';
import UserButtonIcon48 from '../../../assets/svg/inline/user-button-icon-48.inline.svg';

const paneStyle = css`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${dimensions.desktopM - 1}px) {
    width: 91%;
  }
`;

const Wrapper = styled.header`
  background-color: ${colors.lightBlue};

  a {
    text-decoration: none;
    color: ${colors.darkBlue};
  }

  @media (max-width: ${dimensions.mobileL}px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4000;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.16);
  }
`;

const TopPane = styled.div`
  height: 54px;
  ${paneStyle};

  background-image: ${colors.gradient01};

  @media (max-width: ${dimensions.mobileL}px) {
    height: 0;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${dimensions.mobileL}px) {
    display: block;
  }
`;

const Contact = styled.div`
  &,
  a {
    display: flex;
    align-items: center;
  }

  a {
    margin-right: 20px;
    :hover {
      opacity: 0.8;
    }
  }

  span {
    padding: 10px 10px 10px 5px;
    ${font({ size: '18px', spacing: '0.4px' })};
  }

  @media (max-width: ${dimensions.mobileL}px) {
    display: none;
  }
`;

const BottomPane = styled.div`
  height: 79px;
  ${paneStyle};

  @media (min-width: 1920px) {
    height: 97px;
  }

  @media (max-width: ${dimensions.mobileL}px) {
    width: 100%;
    margin: 0;
    height: 70px;
  }
`;

const Empty = styled.div`
  width: 40px;
  display: none;
  flex: 1;

  @media (max-width: ${dimensions.mobileL}px) {
    display: block;
  }
`;

const LogoWrapper = styled.div`
  flex: 4;
`;

const Logo = styled.img`
  ${linkedLogos.myShip};
  height: auto;

  @media (max-width: ${dimensions.mobileL}px) {
    display: block;
    margin: 0 auto;
  }
`;

const Navigation = styled.div`
  ${font({ size: '24px', spacing: '0.48px' })} text-align: right;
  flex: 1;

  a {
    margin: 10px;

    :hover {
      opacity: 0.8;
    }
  }

  .logout {
    display: none;
  }

  @media (max-width: ${dimensions.mobileL}px) {
    display: none;
  }
`;

const scaling = keyframes`
  33% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
`;

const MobileMenuButton = styled.button`
  position: relative;

  width: 40px;
  height: 40px;

  animation-timing-function: ease-in-out;
  animation-delay: 1500ms;
  animation-duration: 2000ms;
  animation-name: ${scaling};

  border-radius: 100%;
  box-shadow: 0 3px 4px 0 rgba(46, 69, 91, 0.5);

  :active {
    opacity: 0.8;
    transform: scale(0.95);
    box-shadow: none;
  }

  svg {
    position: absolute;
    top: 0;
    left: -4px;
    z-index: 2;
  }
`;

const pulse = keyframes`
  0% {
    background: rgba(46, 69, 91, 1);
    opacity: 1;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.25);
  }
`;

const Pulse = styled.div`
  width: 60px;
  height: 60px;
  top: -9px;
  left: -10px;
  z-index: 1;
  position: absolute;
  border-radius: 100%;
  animation-timing-function: cubuc-bezier(0.5, 0.31, 0.84, 0.001);
  animation-delay: 1400ms;
  animation-duration: 3250ms;
  animation-name: ${pulse};
`;

interface Props {
  identity: any;
}

export default function Header({ identity }: Props) {
  const navigation = useNavigation();
  const [showMenu, setShowMenu] = useState(false);
  const ref = useClickOutside(() => setShowMenu(false));

  let name = '';
  if (identity) {
    name =
      identity.firstName && identity.lastName
        ? `${identity.firstName} ${identity.lastName}`
        : identity.email;
  }

  return (
    <Wrapper className="account-header">
      <TopPane>
        <Contact>
          <LinkExternal url="https://www.meinschiff.com/kontakt">
            <AtIcon24 />
            <span>Kontakt</span>
          </LinkExternal>
          <a href="tel:+4940600015111">
            <PhoneIcon24 />
            <span>040 60001-5111</span>
          </a>
        </Contact>
        <MenuWrapper
          ref={ref}
          onClick={() => setShowMenu(!showMenu)}
          onFocus={() => setShowMenu(true)}
          onBlur={() => setShowMenu(false)}
        >
          <Menu userName={name} open={showMenu} navigation={navigation} />
        </MenuWrapper>
      </TopPane>
      <BottomPane>
        <Empty />
        <LogoWrapper>
          <LinkExternal url="http://meinschiff.com/">
            <Logo src="/svg/logo-mein-schiff.svg" alt="Mein Schiff" />
          </LinkExternal>
        </LogoWrapper>
        <Navigation>
          <NavEntries model={navigation} />
        </Navigation>
        <Empty>
          <MobileMenuButton onClick={() => setShowMenu(!showMenu)}>
            <UserButtonIcon48 />
            <Pulse />
          </MobileMenuButton>
        </Empty>
      </BottomPane>
    </Wrapper>
  );
}
