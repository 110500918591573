// @flow
import formatDate from 'date-fns/format';
import ItineraryDay from './ItineraryDay';
import { format } from 'date-fns';

type ResponseObject = {
  content: {
    type: string,
    data: Object[],
  }[],
};

export default class Itinerary {
  list: ItineraryDay[];

  constructor(response: ResponseObject, journeyOngoing: ?boolean) {
    const entry = response.content.find(
      content => content.type === 'itinerarylist'
    );
    this.list = entry
      ? entry.data.map(
          (day, index, list) =>
            new ItineraryDay(day, index, list.length, !!journeyOngoing)
        )
      : [];
  }

  dayIndexOf(datetime: Date) {
    if (datetime) {
      const date = formatDate(datetime, 'YYYY-MM-DD');
      const index = this.list.map(x => x.date).indexOf(date);
      return index !== -1 ? index : null;
    }
    return null;
  }

  dayAt(dayIndex: number): ItineraryDay {
    return this.list[dayIndex];
  }

  dayByDate(date: string): ?ItineraryDay {
    const day = this.list.find(item => item.date === date);
    if (!day) {
      return null;
    }
    return day;
  }

  // TUICMRL-1176, intenary list might have multiple entries on the same day
  dayById(id: number): ?ItineraryDay {
    const day = this.list.find(item => item.id === id);
    if (!day) {
      return null;
    }
    return day;
  }

  // TUICUNIT-2910: more ports on the same day, we need calculate arrivalTime for sort Booking into correct Port
  // TUICUNIT-2938: location information comes from backend
  depcrecate_bookingForLocationUntil(list: Array): number {
    let bookingForLocationUntil = {};
    list.forEach(value => {
      const filter = list.filter(item => item.date === value.date);
      if (filter.length > 1) {
        filter.forEach((item, key) => {
          const from = filter[key - 1] ? filter[key].arrivalTime : '00:00';
          const until = filter[key + 1] ? filter[key + 1].arrivalTime : '23:59';

          bookingForLocationUntil = {
            ...bookingForLocationUntil,
            [item.id]: {
              until: parseInt(until.replace(':', ''), 10),
              from: parseInt(from.replace(':', ''), 10),
            },
          };
        });
      }
    });
    return bookingForLocationUntil;
  }

  get startHarbour(): string {
    const entry = (this.list && this.list[0]) || null;
    return (entry && entry.port && entry.port.name) || '';
  }

  get endHarbour(): string {
    if (!this.list) return '';
    const entry = this.list[this.list.length - 1];
    return (entry && entry.port && entry.port.name) || '';
  }

  dayByTimeStamp(timeStamp?: number): ?ItineraryDay {
    if (!this.list || !timeStamp) return null;
    const formattedDay: string = format(timeStamp, 'YYYY-MM-DD');
    return this.list.find(day => format(day.date, 'YYYY-MM-DD') === formattedDay);
  }
}
