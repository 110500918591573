// TODO: add flow types

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import { bookingRequestShape } from '../../utils/PropTypes';
import { formatPrice } from '../../utils/price';

import Headline from '../Headline';
import Button from '../Button';
import BlockList from '../lists/BlockList';
import LoadingIndicator from '../LoadingIndicator';
import BookingConfirmationSuccess from './NewspaperSuccess';

@observer
export default class BookingConfirmation extends Component {
  static propTypes = {
    bookingRequest: bookingRequestShape.isRequired,
    onConfirm: PropTypes.func,
    onCloseConfirmation: PropTypes.func,
  };

  get title() {
    return this.props.bookingRequest.getText('confirmation', 'title');
  }

  get text() {
    return this.props.bookingRequest.getText('confirmation', 'text');
  }

  @autobind
  handleCloseConfirmationClick() {
    const { bookingRequest, onCloseConfirmation } = this.props;

    if (onCloseConfirmation) onCloseConfirmation();
    bookingRequest.cleanErrors();
  }

  get successButtonText() {
    const { bookingRequest } = this.props;
    return bookingRequest.isCancellation || bookingRequest.isChangeRequest
      ? 'Bestätigen'
      : 'Jetzt reservieren';
  }

  render() {
    const { bookingRequest, onConfirm } = this.props;
    const { bookable } = bookingRequest;

    if (bookingRequest.requestSuccessful) {
      return <BookingConfirmationSuccess bookingRequest={bookingRequest} />;
    }

    return (
      <div className="booking-confirmation">
        <Headline title={this.title} />

        <h2>Ihre Zeitung druckfrisch auf die Kabine</h2>
        <p>{this.text}</p>
        <BlockList>
          {bookingRequest.itemsToBook.map(item => (
            <BlockList.Item key={item.id} readOnly>
              {item.name}
            </BlockList.Item>
          ))}
        </BlockList>
        {bookingRequest.errors ? (
          <p className="error-message">
            {typeof bookingRequest.errors === 'string'
              ? bookingRequest.errors
              : Object.values(bookingRequest.errors).join(', ')}
          </p>
        ) : null}

        {!bookingRequest.isCancellation ? (
          <p>
            Ihr Bordkonto wird durch die Reservierung während der Reise mit{' '}
            {formatPrice(bookable.price)} pro Erscheinungstag und Titel
            belastet.
          </p>
        ) : null}
        {bookingRequest.getOnFirstDay ? (
          <p>
            Die erste Zeitung bekommen Sie am Anreisetag, sofern sie an diesem
            Tag erscheint.
          </p>
        ) : null}
        {bookingRequest.getOnLastDay ? (
          <p>
            Die letzte Zeitung bekommen Sie am Abreisetag, sofern sie an diesem
            Tag erscheint.
          </p>
        ) : null}

        {bookingRequest.isRequesting ? (
          <LoadingIndicator />
        ) : (
          <div className="booking-confirmation__buttons">
            <p>
              <Button dark big onClick={onConfirm}>
                {this.successButtonText}
              </Button>
            </p>
            <p>
              <Button onClick={this.handleCloseConfirmationClick}>
                zurück
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
