// @flow
import {observable, action, computed} from 'mobx';

/**
 * Store to contain the current breadcrumb navigation state for usage in other components.
 */
export default class BreadcrumbRouterStore {
  @observable titles = [];


  @action
  set(titles: Array) {
    this.titles = titles;
  }

  @computed
  get getWithoutFirst(): boolean {
    const titles = [...this.titles];
    titles.shift();
    return titles;
  }
}
