// @flow
import { computed } from 'mobx';

type PDFData = {
  displayName: string,
  link: string,
  fileName: string,
  id: string,
};

class PDFLink {
  displayName: string;
  url: string;

  constructor(pdf: PDFData) {
    this.displayName = pdf.displayName;
    this.url = pdf.link;
  }
}

export default class Regulations {
  headline: string;
  texts: [
    {
      headline?: string,
      body: string,
    },
  ];
  pdfs: PDFData[];
  smallSelect: string[];

  constructor(data: Object) {
    if (
      data &&
      Array.isArray(data.texts) &&
      data.texts[0].headline === undefined
    ) {
      const tmp = [];
      tmp.push({
        headline: '',
        body: data.texts[0],
      });
      this.texts = tmp;
    } else {
      this.texts = data.texts;
    }
    this.headline = data.headline;
    this.pdfs = data.pdfs;

    if (Array.isArray(data.texts)) {
      this.smallSelect = this.texts.map((i, k) => {
        return {
          label: i.headline,
          value: k,
        };
      });
    }
  }

  @computed
  get pdfLinks(): PDFLink[] {
    return this.pdfs.map(pdf => new PDFLink(pdf));
  }

  @computed
  get count(): number {
    return this.texts.length;
  }
}
