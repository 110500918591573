// @flow
import fetchForUser from '../utils/fetchForUser';

import type User from '../models/User';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (mpi): string =>
  (mock || mockAll) && isLocal
    ? `/mock/boarding/pass_${mpi}.json`
    : `/api/land/boarding/pass/links/${mpi}`;

export default {
  // TUICUNIT-2614:single ShipPasses per MPI
  passesForMpi: (user: User, mpi: number) => fetchForUser(user, getUrl(mpi)),
};

export type ApiType = {
  passesForMpi: (user: User, mpi: number) => Promise<*>,
};
