// TODO: add flow types

const storage = window.localStorage;
const now = () => Math.round(new Date().getTime() / 1000);

export default {
  get: key => {
    if (storage) {
      try {
        const localStorage = JSON.parse(storage.getItem(key));
        if (
          !localStorage.expiration ||
          (localStorage.expiration && localStorage.expiration > now())
        ) {
          return localStorage.data;
        }
        storage.removeItem(key);
      } catch (e) {
        //
      }
    }
    return null;
  },

  // expiration time in seconds
  set: (key, data, expiration) => {
    if (storage) {
      return storage.setItem(
        key,
        JSON.stringify({
          data,
          expiration: expiration ? now() + expiration : null,
        })
      );
    }
    return false;
  },
  remove: key => {
    if (storage) {
      return storage.removeItem(key);
    }
    return false;
  },
};
