// https://tuic-stage-shore-booking.intranet.cellular.de/swagger-ui/index.html#/Travel%20Documents/getInfos
// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import { isLocal, mockAll, downloadBinaryData, downloadBinaryDataAsBlob } from './utils';

const mock = false;
const getDocumentListUrl = (): string =>
  (mock || mockAll) && isLocal ? `/mock/travelDocuments/list.json` : `/api/booking/v1/travel-documents`;

export const getDocumentPdfUrl = (documentId: string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/travelDocuments/${documentId}.pdf`
    : `/api/booking/v1/travel-documents/document/${documentId}`;

export const getDocumentsZipUrl = (): string =>
  (mock || mockAll) && isLocal ? `/mock/travelDocuments/docs.zip` : `/api/booking/v1/travel-documents/documents`;

export type PdfMeta = {
  documentId: string,
  creationDate: string,
  name: string,
  category: string,
};

export type ApiType = {
  getDocumentList: (User) => Promise<PdfMeta[]>,
  getDocumentPdf: (user: User, pdfMeta: PdfMeta) => Promise<any>,
  getDocumentPdfAsBlob: (user: User, pdfMeta: PdfMeta) => Promise<any>,
  getDocumentsZip: (user: User, fileName: string) => Promise<any>,
  getDocumentsZipAsBlob: (user: User, fileName: string) => Promise<any>,
};

export default {
  getDocumentList: (user: User) => fetchForUser(user, getDocumentListUrl()),
  getDocumentPdf: (user: User, pdfMeta: PdfMeta) =>
    downloadBinaryData(user, {
      url: getDocumentPdfUrl(pdfMeta.documentId),
      fileName: pdfMeta.name,
      contentType: 'application/pdf',
    }),
  getDocumentPdfAsBlob: (user: User, pdfMeta: PdfMeta) =>
    downloadBinaryDataAsBlob(user, {
      url: getDocumentPdfUrl(pdfMeta.documentId),
      fileName: pdfMeta.name,
      contentType: 'application/pdf',
    }),
  getDocumentsZip: (user: User, fileName: string) =>
    downloadBinaryData(user, {
      url: getDocumentsZipUrl(),
      fileName: fileName,
    }),
  getDocumentsZipAsBlob: (user: User, fileName: string) =>
    downloadBinaryDataAsBlob(user, {
      url: getDocumentsZipUrl(),
      fileName: fileName,
    }),
  getDocumentsText: (user: User) =>
    fetchForUser(user, '/api/booking/v1/travel-documents/mrl-content', {
      differentErrorCodes: true,
    }),
};
