// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

const LabeledInputBlockColumn = ({ children, className, style }) => (
  <div className={`l-col ${className || ''}`} style={style}>
    {children}
  </div>
);

LabeledInputBlockColumn.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default LabeledInputBlockColumn;
