// @flow
import logger from '../utils/logger';
import ItineraryDay from '../models/ItineraryDay';
import parse from 'date-fns/parse';

import {
  PACKAGE_DISPLAY_TYPE_SPA_INCLUSIVE,
  PACKAGE_SUB_TYPE_SPA_INCLUSIVE,
  PACKAGE_SUB_TYPE_SHORE_EX_INCLUSIVE,
  PACKAGE_DISPLAY_TYPE_SHORE_EX_INCLUSIVE,
} from '../config/constants'; // TUICUNIT-1006 & TUICUNIT-1225 hack

const TYPE_TO_URL_MAPPING: {
  [$Keys<typeof TYPE_TO_URL_MAPPING>]: string,
} = {
  offer: 'schoenheit-und-gesundheit',
  event: 'programm',
  excursion: 'landausflug',
  venue: 'tischreservierung',
  [PACKAGE_SUB_TYPE_SPA_INCLUSIVE]: 'spa-balkonkabine', // TUICUNIT-1006 hack
  [PACKAGE_SUB_TYPE_SHORE_EX_INCLUSIVE]: 'inklusiv-landausflug', // TUICUNIT-1225 hack
};

export default class Booking {
  type: string;
  subType: string;
  typeId: string;
  displayName: string;
  displayType: string;
  displayDate: string;
  itemCount: ?number;
  seatCount: ?number;
  mpis: number[];
  prelimenary: boolean;
  day: ?ItineraryDay;
  timeOfDay: ?string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  bookingId: Object;
  contentId: any;
  highlight: ?boolean;
  inclusive: ?boolean;
  masterExcursionId: ?number;
  meetingPoint: ?string;
  meetingTime: ?string;
  isWaitListed: ?boolean;
  locationId: ?number;

  /**
   * Models a Booking in the system. A booking is something that relates to a day, like
   * an offer (Spa or Fitness), Excursion or Tablereservation.
   * @param data {object} Booking Data
   * @param day {ItineraryDay} Day the booking happens
   */
  constructor(data: Object, day: ?ItineraryDay) {
    Object.assign(this, data);
    this.day = day;
  }

  get displayTime(): string {
    if (this.timeOfDay) return this.timeOfDay;
    if (this.startTime) return `${this.startTime} Uhr`;
    return '';
  }

  // TUICUNIT-2687: display time
  get excursionDisplayTime(): string {
    if (this.meetingTime) {
      return `${this.meetingTime} Uhr`;
    }
    if (this.startTime) {
      return `${this.startTime} Uhr${
        this.endTime ? ` - ${this.endTime} Uhr` : ''
      }`;
    }
    if (this.timeOfDay) {
      return this.timeOfDay;
    }
    return '';
  }
  // TUICUNIT-2687: display time
  /* TUICUNIT-3403: deprecated
  get excursionDisplayTimenNotOnJourney(): string {
    if (this.day.isArrival || this.day.isDeparture) {
      return this.excursionDisplayTime;
    }
    if (this.timeOfDay) {
      return this.timeOfDay;
    }
    return this.excursionDisplayTime;
  } */

  get displaySubType(): string {
    const { subType } = this;
    let displaySubType = '';

    // TODO JOK: check and adjust to actual backend logic, when ready
    if (subType === PACKAGE_SUB_TYPE_SHORE_EX_INCLUSIVE) {
      displaySubType = PACKAGE_DISPLAY_TYPE_SHORE_EX_INCLUSIVE;
    }
    return displaySubType && ` ${displaySubType}`;
  }

  get isExcursion(): boolean {
    return this.type === 'excursion';
  }

  get isIncludedExcursion(): boolean {
    return this.isExcursion && !!this.inclusive;
  }

  get typeToUrlMappingKey(): string {
    if (this.displayType === PACKAGE_DISPLAY_TYPE_SPA_INCLUSIVE) {
      return PACKAGE_SUB_TYPE_SPA_INCLUSIVE;
    } else if (this.isIncludedExcursion) {
      return PACKAGE_SUB_TYPE_SHORE_EX_INCLUSIVE;
    } else {
      return this.type || '';
    }
  }

  get extendedDisplayType(): string {
    const { displayType, displaySubType, prelimenary } = this;
    const prelimenaryState = prelimenary ? ' (in Bearbeitung)' : '';
    return `${displayType}${displaySubType}${prelimenaryState}`;
  }

  get bookingRoute(): string {
    // TUICUNIT-1006 & TUICUNIT-1225 hack
    const key = this.typeToUrlMappingKey;

    let bookingRoute: string = TYPE_TO_URL_MAPPING[key];
    if (!bookingRoute) {
      logger.error('No Booking router for type', key);
    }

    return bookingRoute;
  }

  get bookingRouteId(): string {
    if (this.isIncludedExcursion && this.masterExcursionId) {
      return `${this.masterExcursionId}`;
    }

    return this.typeId;
  }

  get startDateTime(): ?Date {
    if (!this.startDate || !this.startTime) return null;
    return parse(`${this.startDate}T${this.startTime}:00`);
  }

  get endDateTime(): ?Date {
    if (!this.endDate || !this.endTime) return null;
    return parse(`${this.endDate}T${this.endTime}:00`);
  }
}
