// @flow
import Bookable from './Bookable';
import parse from 'date-fns/parse';

import type ItineraryDay from './ItineraryDay';
import type MasterStore from '../stores/MasterStore';

export default class DailyEvent extends Bookable {
  eventId: number;
  categoryId: number;
  title: string;
  image: ?string;
  eventImageUri: string;
  duration: number;
  startTime: ?string;
  startDate: ?string;
  timeFrom: string;
  endTime: ?string;
  timeTo: string;
  itinerary: ?{ id: number };
  priceChild: ?number;
  priceAdult: number;
  retailPrice: ?number;
  highlight: boolean;
  location: string;
  venue: string;
  generalInformation: ?string;

  // badgeText: ?string;

  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);
    this.eventId = this.eventId || this.id;
    this.name = this.title || this.name;
  }

  get imageSrc(): string {
    return this.image || this.eventImageUri;
  }

  get displayDuration(): string {
    return `ca. ${this.duration} Minuten`;
  }

  get displayTime(): string {
    // It is not entirely clear, why startTime is sometimes empty…
    return `${this.startTime || this.timeFrom} Uhr`;
  }

  get startDateTime(): ?Date {
    if (!this.date || !this.startTime) return null;
    return parse(`${this.date}T${this.startTime}:00`);
  }

  get endDateTime(): ?Date {
    if (!this.date || !this.endTime) return null;
    return parse(`${this.date}T${this.endTime}:00`);
  }

  get day(): ?ItineraryDay {
    if (!this.itinerary) {
      return null;
    }
    return this.masterStore.itinerary.dayById(this.itinerary.id);
  }

  get badgeText(): string {
    // TODO Determine which type of status is used (string or number)
    if (typeof this.status === 'number') {
      return this.status > 0 ? this.statusMessage : '';
    }

    return this.status;
  }
}
