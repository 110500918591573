// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import { observable, computed, action } from 'mobx';
import { observer, inject } from 'mobx-react';

import isEqual from 'lodash/isEqual';

import CheckboxGroup from '../../../components/CheckboxGroup';
import ErrorMessage from '../../../components/form/ErrorMessage';
import FormSelect from '../../../components/form/Select';
import FormTime from '../../../components/form/Time';

import type { SelectedMemberType } from '../../../models/CheckInRequest';
import type PartyMember from '../../../models/PartyMember';
import type MasterData from '../../../models/MasterData';

import type TimeSloteStore from '../../../stores/TimeSloteStore';

type Props = {
  name: string,
  selectedMembers: SelectedMemberType[],
  members: PartyMember[],
  onChange: (SelectedMemberType[]) => void,
  masterData: MasterData,
  timeSloteStore: TimeSloteStore,
};

@inject('timeSloteStore')
@observer
export default class MembersSelect extends React.Component<Props> {
  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  static childContextTypes = {
    registerFormElement: PropTypes.func,
  };

  @observable selectedMembers = [];
  @observable localError = null;

  constructor(props: Props) {
    super(props);
    this.selectedMembers = props.selectedMembers
      ? [...props.selectedMembers]
      : [];
  }

  getChildContext() {
    return {
      // prohibt leakage of arrivalAt values to form
      registerFormElement: () => {},
    };
  }

  componentDidMount() {
    if (this.context.registerFormElement && this.props.name) {
      this.context.registerFormElement(this.props.name, this);
    }
    const { timeSloteStore } = this.props;
    timeSloteStore.fetch();
  }

  @action
  componentDidUpdate(oldProps: Props) {
    if (oldProps.selectedMembers !== this.props.selectedMembers) {
      this.selectedMembers = this.props.selectedMembers
        ? [...this.props.selectedMembers]
        : [];
    }
  }

  @computed
  get value(): SelectedMemberType[] {
    return [...this.selectedMembers];
  }

  get hasChanged(): boolean {
    return isEqual(this.value, this.props.selectedMembers);
  }

  @action
  validate() {
    const { validateField } = this.context;

    if (validateField) {
      this.localError = validateField(this.props.name, this.value);
    }
  }

  @action
  clearLocalError() {
    this.localError = null;
  }

  @action
  toggleMember(mpi: number) {
    const { onChange, timeSloteStore, masterData } = this.props;
    const partyMember = this.getPartyMember(mpi);
    if (!partyMember) return;
    const hasBooked = timeSloteStore.getBookingList.find(
      (tsm) => tsm.mpi === mpi
    );

    if (this.getSelectedMember(mpi)) {
      this.selectedMembers = this.selectedMembers.filter(
        (member) => member.mpi !== mpi
      );
    } else {
      this.selectedMembers = [
        ...this.selectedMembers,
        {
          mpi,
          arrivalAt: !timeSloteStore.hasTimeSlots
            ? partyMember.arrivalAt
            : partyMember.hasSuite || partyMember.hasVip
            ? partyMember.arrivalAt
            : null,
          timeSlote: timeSloteStore.hasTimeSlots
            ? hasBooked
              ? hasBooked
              : ''
            : null,
          dontCheck:
            partyMember.hasTuicTravelToShip || masterData.isGroupBooking,
        },
      ];
    }

    if (onChange) onChange(this.value);
    if (this.localError) this.validate();
  }

  @action
  setArrivalAt(value: ?string, key: string, mpi: number) {
    const { onChange } = this.props;
    const selectedMember = this.getSelectedMember(mpi);
    if (!selectedMember) return;
    this.selectedMembers = [
      ...this.selectedMembers.filter((member) => member.mpi !== mpi),
      {
        mpi,
        arrivalAt: {
          ...selectedMember.arrivalAt,
          [key]: value,
        },
      },
    ];
    if (onChange) onChange(this.value);
    this.validate();
  }

  // TUICUNIT-1993
  @action
  setTimeSlote(mpi: number, value: ?string) {
    const { onChange, timeSloteStore } = this.props;
    const selectedMember = this.getSelectedMember(mpi);
    if (!selectedMember) return;

    const findSlote = timeSloteStore.getAvailableTimeSlots.find(
      (e) => e.value === value
    );
    this.selectedMembers = [
      ...this.selectedMembers.filter((member) => member.mpi !== mpi),
      {
        mpi,
        timeSlote: {
          state: !!value,
          data: {
            ...selectedMember.timeSlote,
            participants: [mpi],
            type: 'event',
            vacancyId: value,
          },
          display: findSlote ? findSlote.displayLabel : null,
          startDate: findSlote ? findSlote.startDate : null,
          endDate: findSlote ? findSlote.endDate : null,
        },
      },
    ];

    if (onChange) onChange(this.value);
    this.validate();
  }

  getPartyMember(mpi: number): ?PartyMember {
    return this.props.members.find((member) => member.mpi === mpi);
  }

  getSelectedMember(mpi: number): ?SelectedMemberType {
    return this.selectedMembers.find(
      (selectedMember) => selectedMember.mpi === mpi
    );
  }

  renderArrivalAt(partyMember: PartyMember) {
    const { masterData, timeSloteStore } = this.props;
    const selectedMember = this.getSelectedMember(partyMember.mpi);

    if (!selectedMember) return null;

    /* const logState = {
      state: {
        'Timeslots 500': ~~timeSloteStore.hasError500,
        Timeslots: ~~timeSloteStore.hasTimeSlots,
        Travelarrangement: ~~!partyMember.arrivalAt,
        'VIP1 / VIP2': ~~partyMember.hasVip,
        Suit: ~~partyMember.hasSuite,
      },
    };
    console.table(logState); */
    /* TUICUNIT-3368: Check-in for Groups (@_@) */
    if (masterData.isGroupBooking) {
      return null;
    }
    // selectedMember is maybe VIP or TuicTravelToShip or hasSuite
    if (!partyMember.arrivalAt) {
      return <p>{partyMember.arrivalAtDisplay}</p>;
    }

    // TUICUNIT-1993: go to time slote view
    // TUICUNIT-2056: very stupid requirement
    // TUICUNIT-2067: more stupid than before
    // TUICUNIT-2096: without words
    if (!partyMember.hasSuite && !partyMember.hasVip) {
      if (timeSloteStore.hasTimeSlots) {
        return this.renderTimeslote(partyMember);
      }
      if (timeSloteStore.hasError500) {
        return (
          <ErrorMessage error="Es tut uns leid, die verfügbaren Online Check-in Zeitfenster konnten nicht ermittelt werden. Bitte versuchen Sie es später noch einmal." />
        );
      }
    }

    const options = [
      {
        value: 'port',
        label: 'Hafen',
      },
      {
        value: 'airport',
        label: 'Flughafen',
      },
    ];

    const selectedArrivalAt = selectedMember.arrivalAt || {};
    const arrivalAt = {
      where: selectedArrivalAt.where || partyMember.arrivalAt.where,
      time: selectedArrivalAt.time || partyMember.arrivalAt.time,
    };

    const { earliestBoardingTime } = masterData;

    return (
      <div className="form-checkin-arrivalat__block">
        <div className="form-checkin-arrivalat__place">
          <FormSelect
            mandatory
            disabled={partyMember.checkinCompleted}
            name={`arrival_where_${partyMember.mpi}`}
            value={arrivalAt.where}
            placeholder="Ankunftsort auswählen…"
            options={options}
            onChange={(value) =>
              this.setArrivalAt(value || null, 'where', partyMember.mpi)
            }
          />
        </div>
        {arrivalAt.where && (
          <div className="form-checkin-arrivalat__time">
            <FormTime
              mandatory
              disabled={partyMember.checkinCompleted}
              name={`arrival_time_${partyMember.mpi}`}
              value={arrivalAt.time}
              startTime={
                arrivalAt.where === 'port' ? earliestBoardingTime : null
              }
              onChange={(value) =>
                this.setArrivalAt(
                  !value || value === ':' ? null : value,
                  'time',
                  partyMember.mpi
                )
              }
            />
            <div style={{ marginLeft: 5 }}>Uhr</div>
          </div>
        )}
      </div>
    );
  }

  renderTimeslote(partyMember: PartyMember) {
    const { timeSloteStore } = this.props;
    const selectedMember = this.getSelectedMember(partyMember.mpi);

    const hasBooked = timeSloteStore.getBookingList.find(
      (tsm) => tsm.mpi === partyMember.mpi
    );

    let selectedTimeSlote =
      (selectedMember.timeSlote && selectedMember.timeSlote.data) || {};
    if (hasBooked) {
      selectedTimeSlote = hasBooked;
    }
    return (
      <div className={`form-checkin-timeslot__block`}>
        <FormSelect
          mandatory
          name={`timeslote-${partyMember.mpi}`}
          value={selectedTimeSlote ? selectedTimeSlote.vacancyId : null}
          disabled={partyMember.checkinCompleted || !!hasBooked}
          placeholder={`${
            hasBooked ? hasBooked.label : 'Check-in Zeitfenster auswählen…'
          }`}
          options={timeSloteStore.getAvailableTimeSlots}
          onChange={(value) => {
            this.setTimeSlote(partyMember.mpi, value || null);
          }}
        />
      </div>
    );
  }

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  render() {
    const { members, masterData } = this.props;

    return (
      <CheckboxGroup className="form-fieldset">
        <div className="form-checkin-arrivalat">
          {members.map((member) => {
            const partyMember = this.getPartyMember(member.mpi);
            return (
              <div key={`${member.mpi}`}>
                <CheckboxGroup.Item
                  key={member.mpi}
                  value={member.mpi}
                  checked={!!this.getSelectedMember(member.mpi)}
                  onChange={() => this.toggleMember(member.mpi)}
                >
                  {member.displayName}
                </CheckboxGroup.Item>
                {partyMember &&
                  !!this.getSelectedMember(member.mpi) &&
                  this.renderArrivalAt(partyMember)}
              </div>
            );
          })}
          {masterData.isGroupBooking ? (
            <div>{masterData.groupBooking.content}</div>
          ) : null}
        </div>
        <ErrorMessage error={this.localError} />
      </CheckboxGroup>
    );
  }
}
