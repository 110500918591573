// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';

import logger from './../../utils/logger';

class Block extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    // You should add a name if you put it within another Block or Form component
    name: PropTypes.string,
    alwaysPretendToHaveChanged: PropTypes.bool,
  };

  static childContextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  static contextTypes = {
    registerFormElement: PropTypes.func,
    validateField: PropTypes.func,
  };

  fields = {};

  componentDidMount() {
    this.context.registerFormElement &&
      this.context.registerFormElement(this.props.name, this);
  }

  validate(keys) {
    if (!keys) {
      keys = Object.keys(this.fields);
    }
    keys.forEach(id => {
      if (isArray(this.fields[id])) {
        this.fields[id].forEach(field => {
          field.validate && field.validate();
        });
      } else {
        this.fields[id].validate && this.fields[id].validate();
      }
    });
  }

  reset(keys, skip) {
    if (!keys) {
      keys = Object.keys(this.fields);
    }
    keys.forEach(id => {
      if (skip && (skip === id || (isArray(skip) && skip.indexOf(id) >= 0))) {
        return;
      }
      if (isArray(this.fields[id])) {
        this.fields[id].forEach(field => {
          field.reset && field.reset();
        });
      } else {
        this.fields[id].reset && this.fields[id].reset();
      }
    });
  }

  clearLocalError() {
    Object.keys(this.fields).forEach(id => {
      if (isArray(this.fields[id])) {
        this.fields[id].forEach(field => {
          field.clearLocalError && field.clearLocalError();
        });
      } else {
        this.fields[id].clearLocalError && this.fields[id].clearLocalError();
      }
    });
  }

  getChildContext() {
    return {
      registerFormElement: (id, ref, grouped = false) => {
        if (grouped) {
          if (!this.fields[id]) this.fields[id] = [];
          this.fields[id].push(ref);
        } else {
          if (this.fields[id]) {
            logger.warn(
              `Overriding a field with name "${id}" with a different field. Maybe it needs a "grouped" flag?`
            );
          }
          this.fields[id] = ref;
        }
      },
      validateField: (id, value) => {
        const name = this.props.name
          ? [this.props.name, ...(typeof id === 'string' ? [id] : id)]
          : id;
        return this.context.validateField(name, value);
      },
    };
  }

  get value() {
    return mapValues(
      this.fields,
      field => (isArray(field) ? field.find(f => f.value).value : field.value)
    );
  }

  get changedValue() {
    if (!this.hasChanged) {
      return null;
    }
    return Object.keys(this.fields).reduce((changedValues, key) => {
      const field = this.fields[key];
      const fieldHasChanged = isArray(field)
        ? field.some(f => f.hasChanged)
        : field.hasChanged;
      if (!fieldHasChanged) {
        return changedValues;
      }
      const value = isArray(field)
        ? field.find(f => f.value).value
        : field.value;
      changedValues[key] = value;
      return changedValues;
    }, {});
  }

  get hasChanged() {
    if (this.props.alwaysPretendToHaveChanged) return true;
    return Object.keys(this.fields).some(key => this.fields[key].hasChanged);
  }

  render() {
    return this.props.children;
  }
}

export default Block;
