// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../utils/date';

import Booking from '../../models/Booking';

import BaseList from './BaseList';

const BookedRetaurantList = ({ header, bookings, onCancelBooking }) => (
  <BaseList
    header={header}
    bookings={bookings}
    onCancelBooking={onCancelBooking}
    title={booking => `Tisch für ${booking.seatCount}`}
    info={booking =>
      `${formatDate(booking.startDate)}, ${booking.startTime} Uhr`
    }
  />
);

BookedRetaurantList.propTypes = {
  header: PropTypes.string,
  bookings: PropTypes.arrayOf(PropTypes.instanceOf(Booking)).isRequired,
  onCancelBooking: PropTypes.func.isRequired,
};

export default BookedRetaurantList;
