// @flow

import { observable, action } from 'mobx';
import type MasterStore from './MasterStore';
import type { ApiType } from '../api/route';

import Route from '../models/Route';

export default class ArrivalDepartureStore {
  @observable route: ?Route = null;
  @observable loading = true;

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  @action
  setLoading(state: boolean) {
    this.loading = state;
  }

  fetch(params: string): Promise<void> {
    this.setLoading(true);
    return this.api.get(this.masterStore.user, params).then(
      (route: Route) => {
        this.receiveRoute(route);
        this.setLoading(false);
      },
      () => {
        this.setLoading(false);
      }
    );
  }

  @action
  receiveRoute(route: Route) {
    this.route = new Route(route);
  }
}
