// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import LoadingIndicator from '../LoadingIndicator';
import CatalogTile from '../tiles/CatalogTile';
import Headline from '../Headline';
import HeroImage from '../HeroImage';

import type PackageStore from '../../stores/PackageStore';
import type TypeStore from '../../stores/OfferOrPackageTypeStore';
import type Package from '../../models/Package';
import type { TrackingExcursion } from '../../types/tracking';
import { tealiumDateFromDateObject, tealiumValue } from '../../utils/tracking';
import tracking from '../../tracking';

type Props = {
  packageStore: PackageStore,
};

@inject('packageStore', 'breadcrumbRouterStore')
@observer
export default class PagePackages extends Component<Props> {
  static breadcrumb = 'Pakete';

  @observable isLoading = true;

  componentDidMount() {
    this.store.fetchList();
    this.props.packageStore.fetchPage();
  }

  @computed get store(): TypeStore {
    const { packageStore } = this.props;
    return packageStore.storeForType('package');
  }

  badgeFor(pkg: Package): ?string {
    return pkg.isBookable ? null : pkg.bookingStatus;
  }

  triggered = false;

  render() {
    const { page } = this.props.packageStore;
    if (this.store.isLoading || !page) return <LoadingIndicator />;

    if (this.triggered === false && this.store.items) {
      this.triggered = true;
      const excursions: TrackingExcursion[] = this.store.items.map((item: Package) => ({
        code: item.code,
        name: item.name,
        discount: '0.00',
        startDate: tealiumDateFromDateObject(new Date()),
        value: tealiumValue(String(item.price)),
        quantity: '1',
        operator: {
          code: 'intern',
          name: 'intern-meinschiff',
        },
      }));

      tracking.viewListItem(this.props.breadcrumbRouterStore, excursions);
    }

    return (
      <div className="page">
        {page.headline && <Headline title={page.headline} />}
        {page.imageSrc && <HeroImage src={page.imageSrc} alt="" className="l-mod-sub" />}
        <div className="l-row">
          <div className="l-col double">
            <p>{page.text}</p>
          </div>
        </div>
        <h1>Alle Pakete im Überblick</h1>
        {this.store.items.length > 0 ? (
          <div className="l-tiles">
            {this.store.items.map((pkg, index) => (
              <div
                key={pkg.id}
                onClick={() => tracking.setCurrentListItem(index + 1, this.props.breadcrumbRouterStore, {})}
              >
                <CatalogTile
                  booking={pkg.isBooked && pkg}
                  link={`/pakete-und-zeitungen/pakete/${pkg.typeId}`}
                  imageSrc={pkg.imageSrc}
                  title={pkg.headline}
                  description={pkg.text}
                  price={pkg.price}
                  unit={pkg.priceLabel}
                  unavailableBadge={this.badgeFor(pkg)}
                />
              </div>
            ))}
          </div>
        ) : (
          <p>Es stehen keine Paketangebote zur gewünschten Auswahl zur Verfügung.</p>
        )}
      </div>
    );
  }
}
