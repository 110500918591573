// @flow
import { computed } from 'mobx';

import formatDate from 'date-fns/format';
import values from 'lodash/values';
import flatten from 'lodash/flatten';

import { SURVEY_NOT_AVAILABLE, SURVEY_NOT_YET_OPENED } from '../config/constants';

import { assign, defaulted, required, type } from '../utils/model';
import { adjustedDate, gmtZoneToMilliseconds } from '../utils/date';

import Booking from '../models/Booking';
import PartyMember from '../models/PartyMember';
import BookedPackage from '../models/BookedPackage';

import type Itinerary from '../models/Itinerary';
import type { IVipStatus } from '../types/bookable';

export default class MasterData {
  ship: string;
  shipId: string;
  rerouting: ?boolean;
  manifest: { enabled: boolean, allowPartialSave: boolean, reason: ?string };
  payment: { available: boolean, enabled: boolean, reason: ?string };
  checkin: { available: boolean, enabled: boolean, reason: ?string };
  creationDate: number;
  embarkDate: number;
  debarkDate: number;
  ed: number;
  dd: number;
  email: string;
  region: string;
  tripName: string;
  processNumber: number;
  startOfJourney: number;
  journeyOngoing: boolean;
  travelParty: PartyMember[];
  bookings: Object;
  packages: BookedPackage[];
  itinerary: Itinerary;
  tripCode: string;
  edFull: string;
  ddFull: string;
  earliestBoarding: ?number;
  earliestBoardingTimezone: ?string;
  inclusiveExcursions: boolean;
  vip: boolean;
  ssiHash: ?string;
  flightInfo: {
    inboundPublished: ?boolean,
    outboundPublished: ?boolean,
  };
  groupBooking: {
    status: Boolean,
    content: String,
    arrivalInputRequirement: ?string,
  };

  constructor(data: { packages: any[] }, itinerary: Itinerary) {
    assign(this, data, {
      ship: required(String),
      shipId: required(String),
      rerouting: Boolean,
      manifest: required(Object),
      payment: required(Object),
      checkin: required(Object),
      creationDate: defaulted(Number, 0),
      embarkDate: required(Number),
      debarkDate: required(Number),
      ed: required(Number),
      dd: required(Number),
      email: required(String),
      region: defaulted(String, ''),
      tripName: required(String),
      processNumber: required(Number),
      startOfJourney: required(Number),
      journeyOngoing: Boolean,
      travelParty: type.arrayOf(type.instance(PartyMember)),
      bookings: Object,
      packages: required(Array),
      tripCode: required(String),
      edFull: required(String),
      ddFull: required(String),
      earliestBoarding: Number,
      earliestBoardingTimezone: String,
      inclusiveExcursions: Boolean,
      vip: Boolean,
      ssiHash: String,
      flightInfo: required(Object),
      groupBooking: Object,
    });
    this.packages = data.packages.map((pkg) => new BookedPackage(pkg));
    this.itinerary = itinerary;
  }

  get requestor(): ?PartyMember {
    return this.travelParty.find((p) => p.requestor);
  }

  getPartyMember(mpi: number): ?PartyMember {
    return this.travelParty.find((p) => p.mpi === mpi);
  }

  getPartyMemberByIndex(index: number): ?PartyMember {
    return this.travelParty[index];
  }

  get cabinNumberOfRequestor(): ?number {
    return this.requestor ? parseInt(this.requestor.cabinNumber, 10) : null;
  }

  cabinNumberFor(user: Object) {
    const userData = this.findUserInfoFor(user);
    return userData ? parseInt(userData.cabinNumber, 10) : null;
  }

  findUserInfoFor(user: { firstName?: string, lastName?: string }): ?PartyMember {
    return this.travelParty.find((p) => p.firstName === user.firstName && p.lastName === user.lastName) || null;
  }

  getAllBookings(): { [date: string]: Booking[] } {
    return Object.keys(this.bookings).reduce(
      (memo, date) => ({
        ...memo,
        [date]: this.bookings[date].reduce((bookings, data) => {
          if (this.itinerary.dayByDate(date)) {
            bookings.push(new Booking(data, this.itinerary.dayByDate(date)));
          }
          return bookings;
        }, []),
      }),
      {}
    );
  }

  getBookingsFor(mpis: number[]): { [date: string]: Booking[] } {
    return Object.keys(this.bookings).reduce(
      (memo, date) => ({
        ...memo,
        [date]: this.bookings[date]
          .filter(
            (booking) => mpis.length === 0 || booking.seatCount || booking.mpis.find((mpi) => mpis.indexOf(mpi) !== -1)
          )
          .reduce((bookings, data) => {
            if (this.itinerary.dayByDate(date)) {
              bookings.push(new Booking(data, this.itinerary.dayByDate(date)));
            }
            return bookings;
          }, []),
      }),
      {}
    );
  }

  getBookings(date: string, typeId: ?string = null): Booking[] {
    const bookings = (this.bookings[date] || []).reduce((memo, data) => {
      if (this.itinerary.dayByDate(date)) {
        memo.push(new Booking(data, this.itinerary.dayByDate(date)));
      }
      return memo;
    }, []);
    if (typeId !== null) {
      return bookings.filter((b) => b.typeId === typeId);
    }
    return bookings;
  }

  getBookingsByTypeId(typeId: string): Booking[] {
    return flatten(values(this.getAllBookings())).filter((b) => b.typeId === typeId);
  }

  @computed get manifestEnabled(): boolean {
    return this.manifest.enabled;
  }

  @computed get manifestAllowsPartialSave(): boolean {
    return this.manifest.allowPartialSave;
  }

  @computed get manifestStatusReason(): string {
    return this.manifest.reason || '';
  }

  @computed get paymentAvailable(): boolean {
    return this.payment.available;
  }

  @computed get paymentEnabled(): boolean {
    return this.payment.enabled;
  }

  @computed get paymentStatusReason(): string {
    return this.payment.reason || '';
  }

  @computed get checkinAvailable(): boolean {
    return this.checkin.available;
  }

  @computed get checkinEnabled(): boolean {
    return this.checkin.enabled;
  }

  @computed get checkinStatusReason(): string {
    return this.checkin.reason || '';
  }

  @computed get digitalHealthAvailable(): boolean {
    return this.travelParty.some(
      (v) => v.questionnaire && v.questionnaire.tile && v.questionnaire.tile.visible === true
    );
  }

  @computed get digitalHealthStateNoYetAvailable(): boolean {
    return this.travelParty.every((v) => v.questionnaire && v.questionnaire.status === SURVEY_NOT_YET_OPENED);
  }

  @computed get digitalHealthStateNotAvailable(): boolean {
    return this.travelParty.some((v) => v.questionnaire && v.questionnaire.status === SURVEY_NOT_AVAILABLE);
  }

  get cruiseIsRunning(): boolean {
    return this.embarkDate < new Date().getTime();
  }

  get isSuite(): boolean {
    return !!(this.requestor && this.requestor.cabinInfo && this.requestor.cabinInfo.isSuite);
  }

  get isJuniorSuite(): boolean {
    return !!(this.requestor && this.requestor.cabinInfo && this.requestor.cabinInfo.isJuniorSuite);
  }

  get isRegularSuite(): boolean {
    return !!(this.requestor && this.requestor.cabinInfo && this.requestor.cabinInfo.isRegularSuite);
  }

  // TUICRUISES-1630 & SUPTUICMRS-2338
  get getsFreeInternet(): boolean {
    return this.isSuite && (this.isRegularSuite || this.isJuniorSuite);
  }

  // TUICRUISES-1630 & SUPTUICMRS-2338
  get getsFreeNewspaper(): boolean {
    return this.isSuite && this.isRegularSuite && !this.isJuniorSuite;
  }

  get period(): string {
    return `${formatDate(adjustedDate(this.embarkDate), 'DD.MM.')} bis ${formatDate(
      adjustedDate(this.debarkDate),
      'DD.MM.YYYY'
    )}`;
  }

  get subtitleLine(): string {
    const cabinNumber = this.cabinNumberOfRequestor;
    const cabinString = cabinNumber ? ` (Kabine ${cabinNumber})` : '';
    return `${this.tripName} vom ${this.period}${cabinString}`;
  }

  get earliestBoardingTime(): string | null {
    if (!this.earliestBoarding || !this.earliestBoardingTimezone) return null;

    try {
      const eb = new Date(this.earliestBoarding * 1000);
      const tz = eb.getTimezoneOffset() * 60 * 1000;
      const gmtMS = gmtZoneToMilliseconds(this.earliestBoardingTimezone);
      return formatDate(new Date(eb.getTime() + gmtMS + tz), 'HH:mm');
    } catch (exc) {
      return null;
    }
  }

  get bonusText(): string | null {
    let percent = 0;

    percent = this.requestorHasPlusTariff ? 10 : percent;
    percent = this.requestorHasFeelGoodPlusTariff || this.requestorHasProTariff ? 20 : percent;

    return percent ? `${percent} % Vorteil` : null;
  }

  get requestorHasFeelGoodPlusTariff(): boolean {
    const r = this.requestor;
    return !!(r && r.hasFeelGoodPlusTariff);
  }

  get requestorHasProTariff(): boolean {
    const r = this.requestor;
    return !!(r && r.hasProTariff);
  }

  get requestorHasPlusTariff(): boolean {
    const r = this.requestor;
    return !!(r && r.hasPlusTariff);
  }

  get someoneHasVipOption1(): boolean {
    const t = this.travelParty;
    return !!(t && t.find((p) => p.hasVipOption1));
  }

  get someoneHasVipOption2(): boolean {
    const t = this.travelParty;
    return !!(t && t.find((p) => p.hasVipOption2));
  }

  get requestorHasVipOption1(): boolean {
    const r = this.requestor;
    return !!(r && r.hasVipOption1);
  }

  get requestorHasVipOption2(): boolean {
    const r = this.requestor;
    return !!(r && r.hasVipOption2);
  }

  get requestorVipOption(): IVipStatus {
    if (this.requestorHasVipOption1) {
      return 1;
    } else if (this.requestorHasVipOption2) {
      return 2;
    } else {
      return 0;
    }
  }

  get requestorHasVipOption(): boolean {
    return this.requestorHasVipOption1 || this.requestorHasVipOption2;
  }

  get vipTariffName(): string {
    return this.requestorHasVipOption ? `VIP-Tarifoption ${this.vipTariffId || ''}` : '';
  }

  get vipTariffId(): number | null {
    return this.requestorHasVipOption ? (this.requestorHasVipOption1 ? 1 : 2) : null;
  }

  get requestorHasTuicTravelToShip(): boolean {
    const r = this.requestor;
    return !!(r && r.hasTuicTravelToShip);
  }

  get hasFlightInfo(): boolean {
    return !!(this.flightInfo && (this.flightInfo.inboundPublished || this.flightInfo.outboundPublished));
  }

  // TUICUNIT-2044
  get requestorHasVip(): boolean {
    return !!(this.requestorHasVipOption1 || this.requestorHasVipOption2);
  }

  /* TUICUNIT-3368: Check-in for Groups (@_@) */
  get isGroupBooking(): boolean {
    return (this.groupBooking && this.groupBooking.status) || false;
  }

  get isGroupBookingBlocked(): boolean {
    console.log(this.groupBooking);
    return (
      (this.groupBooking &&
        this.groupBooking.status &&
        this.groupBooking.arrivalInputRequirement &&
        this.groupBooking.arrivalInputRequirement === 'blocked') ||
      false
    );
  }
}
