// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ListItem from './ListItem';

const List = ({ children, className, disabled }) => (
  <div
    className={classNames({
      list: true,
      [className]: !!className,
      disabled,
    })}
  >
    {children}
  </div>
);

List.propTypes = {
  // List content should only contain List.Item components
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

List.Item = ListItem;

export default List;
