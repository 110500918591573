// @flow
import { computed } from 'mobx';

import { PACKAGE_SUB_TYPE_INTERNET } from '../config/constants';
import Bookable from '../models/Bookable';

import type MasterStore from '../stores/MasterStore';

export default class InternetPackage extends Bookable {
  text: string;
  image: string;
  typeId: string;
  priceText: string;
  category: string;

  constructor(data: Object, masterStore: MasterStore) {
    super(data, masterStore);
    this.name = this.name || this.headline;
  }

  @computed
  get fromData() {
    return this.masterStore.packagesOfUser.find(
      pkg => pkg.typeId === this.bookingId
    );
  }

  get isBooked(): boolean {
    return !!this.fromData;
  }

  get prelimenary(): boolean {
    return this.fromData && this.fromData.prelimenary;
  }

  get packageType(): string {
    return PACKAGE_SUB_TYPE_INTERNET;
  }

  get description(): string {
    return this.text;
  }

  get imageSrc(): string {
    return this.image;
  }

  get bookingName(): string {
    return 'Internetpaket';
  }

  get isBookable(): boolean {
    return this.typeId !== '';
  }

  get price(): number {
    return parseFloat(this.priceText.replace(',', '.'));
  }

  @computed
  get analyticsCategory(): string {
    return this.category;
  }
}
