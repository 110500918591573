// @flow
import {
  ITINERARY_TYPE_SEA,
  ITINERARY_TENSE_PAST,
  ITINERARY_TENSE_PRESENT,
  ITINERARY_TENSE_FUTURE,
} from '../config/constants';

import { formatCalendar, formatDate } from '../utils/date';
import Booking from '../models/Booking';

type ItineraryPort = {
  name: string,
  alias: string,
  description: string,
  tender: boolean,
  country: number,
};

export default class ItineraryDay {
  type: string;
  tense: string;
  day: number;
  // might be derived from index of itinerary list
  dayNumber: number;
  // might be derived from length of itinerary list
  numberDays: number;
  // isDepartureDay: boolean;
  date: string;
  displayDay: string;
  displayDateShort: string;
  port: ItineraryPort;
  arrivalTime: ?string;
  departureTime: ?string;
  publishedArrivalTime: ?string;
  publishedDepartureTime: ?string;
  hint: ?string;
  id: number;
  image: string;
  // might be derived from masterData
  journeyOngoing: boolean;
  includedShoreEx: ?any;
  locationId: number | string;

  constructor(
    data: Object,
    dayNumber: number,
    numberDays: number,
    journeyOngoing: boolean
  ) {
    Object.assign(this, data, {
      dayNumber,
      numberDays,
      journeyOngoing,
    });
  }

  get displayArrivalTime(): ?string {
    return this.journeyOngoing
      ? this.arrivalTime || this.publishedArrivalTime
      : this.publishedArrivalTime || this.arrivalTime;
  }

  get displayDepartureTime(): ?string {
    return this.journeyOngoing
      ? this.departureTime || this.publishedDepartureTime
      : this.publishedDepartureTime || this.departureTime;
  }

  get isPast(): boolean {
    return this.tense === ITINERARY_TENSE_PAST;
  }

  get isToday(): boolean {
    return this.tense === ITINERARY_TENSE_PRESENT;
  }

  get isFuture(): boolean {
    return this.tense === ITINERARY_TENSE_FUTURE;
  }

  get isArrival(): boolean {
    return this.dayNumber === 0;
  }

  get isDeparture(): boolean {
    return this.dayNumber === this.numberDays - 1;
  }

  get isSeaDay(): boolean {
    return this.type === ITINERARY_TYPE_SEA;
  }

  get isLandDay(): boolean {
    return !this.isSeaDay;
  }

  get subtitleLine(): string {
    let dayPart = `Tag ${this.day}`;
    if (this.isArrival) {
      dayPart = 'Anreisetag';
    } else if (this.isDeparture) {
      dayPart = 'Abreisetag';
    }
    return `${dayPart} - ${formatCalendar(this.date)}`;
  }

  get formattedDate(): string {
    return formatDate(this.date);
  }

  get displayItineraryDate(): string {
    return `${this.displayDay}., ${this.displayDateShort}`;
  }

  get breadcrumbTitle(): string {
    if (this.isArrival) {
      return `Anreise - ${this.port.name}`;
    }
    if (this.isDeparture) {
      return `Abreise - ${this.port.name}`;
    }
    return `Tag ${this.day} - ${this.port.name}`;
  }

  // TUICUNIT-2910: location information comes from backend (TUICUNIT-2938)
  getBookingsForItinerary(bookings): Booking[] | [] {
    if (!bookings) {
      return [];
    }
    if (this.locationId) {
      const locationBookings = bookings.filter(
        item => item.locationId === this.locationId || !item.locationId
      );
      return locationBookings;
    } else {
      return bookings;
    }
  }
}
