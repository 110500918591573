// @flow
import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mock = false;
const getBeautyDetailsUrl = (
  masterData: MasterData,
  id: number | string
): string =>
  (mock || mockAll) && isLocal
    ? `/mock/land/spa-detail.json`
    : `/api/land/spa/detail/${id}/${masterData.ed}/${masterData.dd}/${
        masterData.shipId
      }`;
const getSpaBalconyCabinDetailsUrl = (id: number | string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/land/spa-detail.json`
    : `/api/package/priv/spa/detail/${id}`;

export const sanitizers = {
  beautyDetails: data => camelCaseKeys(data, true),
  spaBalconyCabinDetails: data => camelCaseKeys(data, true),
};

export default sanitized(
  {
    beautyDetails: (user: User, masterData: MasterData, id: number | string) =>
      fetchForUser(user, getBeautyDetailsUrl(masterData, id)),
    spaBalconyCabinDetails: (
      user: User,
      masterData: MasterData,
      id: number | string
    ) => fetchForUser(user, getSpaBalconyCabinDetailsUrl(id)),
  },
  sanitizers
);
