// @flow
import fetchForUser from '../utils/fetchForUser';

import type User from '../models/User';
import type CheckInRequest from '../models/CheckInRequest';

const ENDPOINT = '/api/land/checkin';

/* TUICUNIT-3368: Check-in for Groups (@_@) */
export default {
  save: (user: User, checkInRequest: CheckInRequest) =>
    fetchForUser(user, `${ENDPOINT}/checkin`, {
      method: 'post',
      body: JSON.stringify({
        email: checkInRequest.requestData.email,
        mobile: checkInRequest.requestData.mobile,
        checkInFor: checkInRequest.requestData.selectedMembers,
        isGroupBooking: checkInRequest.requestData.isGroupBooking,
      }),
    }),
};

// export const mockApi = {
//   save: () =>
//     new Promise(function(resolve) {
//       resolve();
//     }),
// };

export type ApiType = {
  save: (
    user: User,
    checkInRequest: CheckInRequest
  ) => Promise<{
    errors: ?Object,
    data: { isCheckedIn: boolean, mpi: number }[],
  }>,
};
