// @flow

import fetchForUser from '../utils/fetchForUser';
import SpaCategory from '../models/SpaCategory';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (part: string) =>
  (mock || mockAll) && isLocal
    ? `/mock/content/${part.replace('/', '-')}.json`
    : `/api/land/content/${part}`;

export default {
  spaCategories: masterStore =>
    fetchForUser(
      masterStore.user,
      getUrl(
        `spa-and-sport-categories/list/${masterStore.masterData.ed}/${
          masterStore.masterData.dd
        }/${masterStore.masterData.shipId}`
      )
    ).then(categories => categories.map(category => new SpaCategory(category))),
};
