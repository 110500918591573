// @flow

/**
 * TUICMA-55
 * */

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import type RouteStore from '../../../stores/RouteStore';
import LoadingIndicator from '../../LoadingIndicator';
import { serviceUrlMapping } from './maps/serviceUrlMap';

type Props = {
  location: any,
  routeStore: RouteStore,
};

const navigate = (path: string) => {
  window.location.href = path;
  return null;
};

@inject('routeStore')
@observer
export default class PageAncillaryDetails extends Component<Props> {
  static breadcrumb = 'Redirect';

  componentDidMount() {
    const { location, routeStore } = this.props;
    routeStore.fetch(`ancillaryDetails${location.search}`);
  }

  render() {
    const { routeStore } = this.props;
    const { route, loading } = routeStore;

    if (loading) {
      return <LoadingIndicator />;
    }

    return route ? navigate(serviceUrlMapping(route)) : navigate('/');
  }
}
