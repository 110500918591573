
export function detectOS(): ?string {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;   // @todo: Update solution.
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  }
  if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  }
  if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  }
  if (/Android/.test(userAgent)) {
    os = 'Android';
  }
  if (null === os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export const isIOS = detectOS() === 'iOS';
export const isAndroid = detectOS() === 'Android';
