//@flow
import React from 'react';
import classNames from 'classnames';

import Picture from '../../Picture';

type Props = {
  // Source of image for big screens
  src: string,
  // Source of image for small screens
  smallSrc?: ?string,
};

const TileImage = ({ src, smallSrc }: Props) => (
  <div
    className={classNames({
      tile__image: true,
    })}
  >
    <Picture src={src} smallSrc={smallSrc} />
  </div>
);

export default TileImage;
