// @flow

import { observable, action } from 'mobx';
import type MasterStore from '../stores/MasterStore';
import type {
  ApiType,
  IIncludedShoreExListing,
  IIncludedShoreExDetail,
  IInclusiveBookingStatusList,
  IInclusiveBookingDetailStatus,
} from '../api/includedShoreEx';

import IncludedShoreExDetail from '../models/IncludedShoreEx/IncludedShoreExDetail';
import IncludedShoreExListing from '../models/IncludedShoreEx/IncludedShoreExListing';
import IncludedShoreExDetailStatus from '../models/IncludedShoreEx/IncludedShoreExDetailStatus';
import IncludedShoreExListingStatus from '../models/IncludedShoreEx/IncludedShoreExListingStatus';
import type IncludedShoreExBookingRequest from '../models/BookingRequest/IncludedShoreExBookingRequest';
import {
  FAILED,
  FALLBACK_ERROR_MESSAGE,
} from '../models/IncludedShoreEx/constants';

// TODO check flow types
export default class IncludedShoreExStore {
  @observable listing: ?IncludedShoreExListing = null;
  @observable details: ?IncludedShoreExDetail = null;
  @observable detailStatus: ?IncludedShoreExDetailStatus = null;
  @observable listingStatus: ?IncludedShoreExListingStatus = null;
  @observable loading = false;

  fetchDetailsPromise: ?Promise<void>;
  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
    this.fetchDetailsPromise = null;
  }

  @action
  fetchListing(): Promise<void> {
    this.loading = true;
    return this.api
      .getListing(this.masterStore.user, this.masterStore.masterData)
      .then((listing: ?IIncludedShoreExListing): ?IncludedShoreExListing => {
        return this.receiveListing(listing);
      });
  }

  @action
  receiveListing(listing: ?IIncludedShoreExListing): ?IncludedShoreExListing {
    this.loading = false;
    if (listing) {
      this.listing = new IncludedShoreExListing(listing);
      return this.listing;
    }
  }

  @action
  fetchDetails(masterExcursionId: number): Promise<void> {
    this.loading = true;

    if (!this.fetchDetailsPromise) {
      this.fetchDetailsPromise = this.api
        .getDetails(this.masterStore.user, masterExcursionId)
        .then((detail: ?IIncludedShoreExDetail): ?IncludedShoreExDetail => {
          this.fetchDetailsPromise = null;
          return this.receiveDetails(detail);
        });
    }

    return this.fetchDetailsPromise;
  }

  @action
  receiveDetails(detail: ?IIncludedShoreExDetail): ?IncludedShoreExDetail {
    this.loading = false;
    if (detail) {
      this.details = new IncludedShoreExDetail(detail);
      return this.details;
    }
  }

  @action
  fetchDetailStatus(masterExcursionId: number): Promise<void> {
    this.loading = true;
    return this.api
      .getDetailStatus(this.masterStore.user, masterExcursionId)
      .then(
        (
          status: ?IInclusiveBookingDetailStatus
        ): ?IncludedShoreExDetailStatus => {
          return this.receiveDetailStatus(status);
        }
      );
  }

  @action
  receiveDetailStatus(
    status: ?IInclusiveBookingDetailStatus
  ): ?IncludedShoreExDetailStatus {
    this.loading = false;
    if (status) {
      this.detailStatus = new IncludedShoreExDetailStatus(status);
      return this.detailStatus;
    }
  }

  @action
  fetchListingStatus(): Promise<void> {
    this.loading = true;
    return this.api
      .getListingStatus(this.masterStore.user)
      .then(
        (
          status: ?IInclusiveBookingStatusList
        ): ?IncludedShoreExListingStatus => {
          return this.receiveListingStatus(status);
        }
      );
  }

  @action
  receiveListingStatus(
    status: ?IInclusiveBookingStatusList
  ): ?IncludedShoreExListingStatus {
    this.loading = false;
    if (status) {
      this.listingStatus = new IncludedShoreExListingStatus(status);
      return this.listingStatus;
    }
  }

  handleBookingRequest(bookingRequest: IncludedShoreExBookingRequest) {
    bookingRequest.cleanErrors();
    bookingRequest.markAsRequesting(true);

    return this.api
      .setBookingStatus(
        this.masterStore.user,
        bookingRequest.bookable.masterExcursionId,
        bookingRequest.bookingRequestData
      )
      .then(
        response => {
          bookingRequest.markAsRequesting(false);
          this.masterStore.reloadMasterData();

          if (!response || response.error) {
            bookingRequest.setErrors(
              response ? response.error : FALLBACK_ERROR_MESSAGE
            );
            return;
          }

          if (!response.list) {
            bookingRequest.setErrors(FALLBACK_ERROR_MESSAGE);
            return;
          }

          bookingRequest.responseState = response.list;
          if (response.list.find(r => r.result === FAILED)) {
            bookingRequest.setErrors(
              `Folgende Anfragen sind fehlgeschlagen: <br /> ${FALLBACK_ERROR_MESSAGE}`
            );
            return;
          }

          bookingRequest.markAsSuccessful();
        },
        () => {
          bookingRequest.markAsRequesting(false);
          bookingRequest.setErrors(FALLBACK_ERROR_MESSAGE);
        }
      );
  }
}
