// @flow

import { observable, action, computed } from 'mobx';

import type MasterStore from './MasterStore';

import type { ApiType, ApiResponse } from '../api/flightPreference';
import { l10n } from '../components/BookingDetails/FlightPreference/l10n';
import { jsLocalDateTime } from '../utils/timezone';

const DEFAULT_ERROR_MESSAGE =
  'Die Flugwünsche steht zur Zeit nicht zur Verfügung. Bitte versuchen Sie es später erneut.';

const INPUT_RADIO = 'RADIO_BUTTON';
const INPUT_CHECKBOX = 'CHECKBOX';

export type AnswerType = {
  id: number,
  questionId: number,
  isCorrect: boolean,
  text: {
    de: string,
    en: string,
  },
  answerText: {
    de: string,
    en: string,
  },
  message: {
    state: boolean,
    de: string,
    en: string,
  },
};

export type QuestionType = {
  id: number,
  isRadio: boolean,
  isCheckBox: boolean,
  questionText: {
    de: string,
    en: string,
  },
  hint: {
    de: string,
    en: string,
  } | null,
  answers: [AnswerType],
};

export type ErrorType = {
  status?: boolean,
  text: string,
};

export type SurveyMetaType = {
  id: number,
  title: {
    de: string,
    en: string,
  },
  text: {
    de: string,
    en: string,
  },
  submitted: string,
  status: string,
};

export type VipPartyMemberType = {
  mpi: number,
  openFrom: string,
  openUntil: string,
  reason: string,
  status: string,
  surveyId: Number,
  tile: {
    active: boolean,
    visible: boolean,
  },
};

export type VipTexteType = {
  headline: string,
  subline: string,
};

export default class FlightPreferenceStore {
  @observable isSending: boolean = false;
  @observable loading: boolean = false;
  @observable questions: ?ApiResponse = null;
  @observable surveyMeta: ?SurveyMetaType = null;
  @observable travelparty: ?[VipPartyMemberType] = null;
  @observable vipTexte: ?VipTexteType = null;
  @observable
  error: ?ErrorType = {
    text: DEFAULT_ERROR_MESSAGE,
  };

  masterStore: MasterStore;
  api: ApiType;

  constructor(api: ApiType, masterStore: MasterStore) {
    this.api = api;
    this.masterStore = masterStore;
  }

  fetch(currentMemberMpi: number) {
    this.resetStates();

    if (currentMemberMpi) {
      return this.api
        .get(this.masterStore.user, currentMemberMpi)
        .then((result: ApiResponse) => {
          this.receiveSections(result);
        });
    } else {
      this.setError({ status: true });
    }
  }

  status() {
    this.resetStates();
    return this.api
      .status(this.masterStore.user)
      .then((result: ApiResponse) => {
        this.receiveStatusData(result);
      });
  }

  setupAfterReload(index) {
    this.resetStates();
    return this.api
      .status(this.masterStore.user)
      .then((resultMeta: ApiResponse) => {
        let thisUser = {};
        const thisUserList = this.makeSureItsAnArray(
          resultMeta.status.participants
        );
        thisUser = thisUserList[index];
        if (thisUser) {
          this.api
            .get(this.masterStore.user, thisUser.mpi)
            .then((result: ApiResponse) => {
              this.receiveStatusData(resultMeta);
              this.receiveSections(result);
            });
        }
      });
  }

  @action
  saveData(data, currentMemberMpi: number) {
    this.startRequest();

    const answers = Object.keys(data).map((index: number) => {
      return { id: parseInt(data[index], 10) };
    });
    const submitData = {
      questionnaireAnswers: [
        {
          mpi: currentMemberMpi,
          // surveyId: this.surveyMeta.id,
          answers: answers,
        },
      ],
    };
    this.api.save(this.masterStore.user, submitData).then(result => {
      if (result.status === 500) {
        this.setError(result);
      } else {
        this.fetch(currentMemberMpi);
      }
    });
  }

  @action
  confirmData(currentMemberMpi) {
    this.startRequest();
    const submitData = {
      mpi: currentMemberMpi,
    };
    this.api.confirm(this.masterStore.user, submitData).then(result => {
      if (result.status === 500) {
        this.setError(result);
      } else {
        this.fetch(currentMemberMpi);
      }
    });
  }

  @action
  receiveStatusData(result: ApiResponse) {
    if (result.status && result.status.participants) {
      this.travelparty = this.makeSureItsAnArray(result.status.participants);
      this.loading = false;
    } else {
      this.setError(result);
    }

    if (result.headline && result.subline) {
      this.vipTexte = {
        headline: result.headline,
        subline: result.subline,
      };
      this.loading = false;
    }
  }

  @action
  receiveSections(result: ApiResponse) {
    if (result && result.surveyId) {
      this.questions = result.questions;
      this.surveyMeta = this.setSurveyMeta(result);
      this.loading = !result.status;
      // reset loader after submit
      if (this.isSending) {
        this.setDone();
      }
    } else {
      this.setError(result);
    }
  }

  @action
  setError(result) {
    this.error = {
      status: result ? !!result.status : true,
      text: DEFAULT_ERROR_MESSAGE,
    };
    this.questions = false;
    this.loading = false;
  }

  @action
  resetStates() {
    this.loading = true;
    this.error = {
      status: false,
    };
  }

  @action
  startRequest() {
    this.isSending = true;
  }

  @action
  setDone() {
    this.isSending = false;
  }

  @computed
  get isLoading(): boolean {
    return this.loading;
  }
  @computed
  get inProgress(): boolean {
    return this.isSending;
  }

  @computed
  get hasError(): boolean {
    return this.error.status || false;
  }

  @computed
  get getQuestions(): [] | [QuestionType] {
    return this.questions ? this.prepareQuestions(this.questions) : [];
  }

  @computed
  get getSurveyMeta(): {} | SurveyMetaType {
    return this.surveyMeta || {};
  }

  @computed
  get getVipTravelParty(): [] | [VipPartyMemberType] {
    return this.travelparty || [];
  }

  @computed
  get getVipTexte(): {} | VipTexteType {
    return this.vipTexte || {};
  }

  setSurveyMeta(data) {
    let result = {};
    if (data) {
      result = {
        id: data.surveyId,
        title: this.prepareStructur(data.title),
        text: this.prepareStructur(data.welcomeText),
        submitted: data.submitted ? this.prepareTime(data.submitted) : null,
        status: data.status,
      };
    }
    return result;
  }

  prepareQuestions(questions) {
    let result = [];
    if (Array.isArray(questions)) {
      questions.forEach(question => {
        let answers = [];
        if (Array.isArray(question.answers)) {
          question.answers.forEach(answer => {
            answers.push({
              id: answer.id,
              questionId: question.id,
              isCorrect: answer.isCorrect,
              text: this.prepareStructur(answer.text),
              answerText:
                question.visualControl === INPUT_RADIO
                  ? this.prepareStructur(answer.text)
                  : {
                      de: l10n.surveyResult.de.answerConfirm,
                      en: l10n.surveyResult.en.answerConfirm,
                    },
              message: {
                state: answer.hint !== null,
                ...this.prepareStructur(answer.hint),
              },
            });
          });
        }
        result.push({
          id: question.id,
          isRadio: question.visualControl === INPUT_RADIO,
          isCheckBox: question.visualControl === INPUT_CHECKBOX,
          questionText: this.prepareStructur(question.text),
          hint:
            question.popupText && question.popupText !== null
              ? this.prepareStructur(question.popupText)
              : null,
          answers: answers,
          givenAnswer: question.givenAnswerId || null,
        });
      });
    }

    return result;
  }

  prepareStructur(textBlock: [{ language: string, value: string }]) {
    const result = {};
    if (Array.isArray(textBlock)) {
      textBlock.forEach(value => {
        result[value.language] = this.prepareText(value.value);
      });
    }
    return result;
  }

  prepareTime(time: string) {
    const t = jsLocalDateTime(time);

    const h = t.getHours() < 10 ? `0${t.getHours()}` : t.getHours();
    const m = t.getMinutes() < 10 ? `0${t.getMinutes()}` : t.getMinutes();
    const dD = t.getDate() < 10 ? `0${t.getDate()}` : t.getDate();
    const dM =
      t.getMonth() + 1 < 10 ? `0${t.getMonth() + 1}` : t.getMonth() + 1;
    const dY = t.getFullYear();
    // 24.08.2020 | 14:30 Uhr
    return `${dD || '--'}.${dM || '--'}.${dY || '--'} | ${h || '--'}:${m ||
      '--'}`;
  }

  /**
   * remove all html tags, only -> ul, li, b, a are allowed
   *
   * @deprecated Use utilfunction prepareText instead
   * @param {string} text
   * @returns clean String
   */
  prepareText(text: ?string) {
    let cText = text || '';

    cText = cText.replace(/(<b>)/gi, '[b]').replace(/(<\/b>)/gi, '[/b]');
    cText = cText.replace(/(<ul>)/gi, '[ul]').replace(/(<\/ul>)/gi, '[/ul]');
    cText = cText.replace(/(<li>)/gi, '[li]').replace(/(<\/li>)/gi, '[/li]');
    cText = cText.replace(/(<a href)/gi, '[a]').replace(/(<\/a>)/gi, '[/a]');

    cText = cText.replace(/(<([^>]+)>)/gi, '');
    cText = cText.replace(/([\n\t])/gi, ' ');
    cText = cText.split('  ').join(' ');

    cText = cText.split('[b]').join('<span class="bold">');
    cText = cText.split('[/b]').join('</span>');
    cText = cText.split('[ul]').join('<ul>');
    cText = cText.split('[/ul]').join('</ul>');
    cText = cText.split('[li]').join('<li><span>');
    cText = cText.split('[/li]').join('</span></li>');
    cText = cText.split('[a]').join(' <a href');
    cText = cText.split('[/a]').join('</a>');
    return cText;
  }

  // TUICUNIT-2644: sometime this participants Array is not an Arrray :(
  makeSureItsAnArray(list: ?[] | ?{}): [] {
    let result = [];
    if (!list) {
      return result;
    }

    if (Array.isArray(list)) {
      result = list;
    } else if (typeof list === 'object') {
      Object.keys(list).map(item => {
        result.push(list[item]);
      });
    }
    return result;
  }
}
