// @flow

import React, { Component, type Node } from 'react';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import Link from 'react-router/lib/Link';
import sortBy from 'lodash/sortBy';

import { itsUrlWithSize } from '../utils/image';

import Booking from '../models/Booking';
import ItineraryDay from '../models/ItineraryDay';

import Badge from './Badge';

type Props = {
  // Array of Bookings for that day directly from the MasterData Feed
  services?: Booking[],
  // ItineraryList Data from itinerary API
  data: {
    date: string,
    title: string,
    description?: Node,
    imageSrc?: string | null,
    imageAlt?: string | null,
    highlight?: boolean,
  },
  link?: string,
  onClick?: () => void,
  badge?: string,
  // Add this if you need an alternative text if there are no services
  emptyText?: string,
  disabled?: boolean,
  small?: boolean,
  // Should the Empty string be shown if services are empty?
  showEmpty?: boolean,
  // Items that show booked offers look slightly different
  offerItem?: boolean,
  day?: ItineraryDay,
  // This means, we don't show a colored background
  plain?: boolean,
  journeyOngoing?: boolean,
};

export default class ItineraryListItem extends Component<Props> {
  get extendedServices(): Booking[] {
    const { day, services } = this.props;

    return day && !day.isSeaDay
      ? sortBy(
          [
            !day.isArrival && day.displayArrivalTime
              ? {
                  startTime: day.displayArrivalTime,
                  displayTime: `${day.displayArrivalTime} Uhr`,
                  extendedDisplayType: 'Ankunft',
                }
              : null,
            day.displayDepartureTime
              ? {
                  startTime: day.displayDepartureTime,
                  displayTime: `${day.displayDepartureTime} Uhr`,
                  extendedDisplayType: 'Abfahrt',
                }
              : null,
            ...(services || []),
          ].filter(x => x),
          ['startTime']
        )
      : services || [];
  }

  renderEmptyState(): Node[] {
    const { day, emptyText = 'Sie haben noch nichts vor.' } = this.props;
    if (day && !day.isSeaDay) {
      return [
        <li key="empty">{emptyText}</li>,
        !day.isArrival && day.displayArrivalTime ? (
          <li key="arrival">
            <span className="itinerary-service__time time">{`${
              day.displayArrivalTime
            } Uhr`}</span>Ankunft
          </li>
        ) : null,
        day.displayDepartureTime ? (
          <li key="departure">
            <span className="itinerary-service__time time">{`${
              day.displayDepartureTime
            } Uhr`}</span>Abfahrt
          </li>
        ) : null,
      ].filter(x => x);
    } else {
      return [<li key="empty">{emptyText}</li>];
    }
  }

  @autobind
  handleClick(event: any) {
    const { disabled, onClick } = this.props;
    if (disabled) {
      event.preventDefault();
    } else if (onClick) {
      onClick();
    }
  }

  // TUICUNIT-2638: waiting list
  render() {
    const {
      services,
      data,
      link,
      disabled,
      badge,
      small,
      showEmpty,
      offerItem,
      plain,
      journeyOngoing,
      isWaitListed,
    } = this.props;

    return (
      <Link
        to={disabled ? '' : link}
        className={classNames({
          'itinerary-item': true,
          'offer-item': offerItem,
          highlight: data.highlight,
          services: !plain,
          disabled,
          small,
        })}
        onClick={this.handleClick}
      >
        <div className="itinerary-item__date">{data.date}</div>
        <div className="itinerary-item__title">{data.title}</div>
        {isWaitListed ? (
          <div className="wait-listed">
            <span className="bold">Hinweis:</span> Sie stehen für diesen Ausflug
            auf unserer Warteliste
          </div>
        ) : null}
        {data.description}
        {data.imageSrc ? (
          <div className="itinerary-item__image">
            <img src={itsUrlWithSize(data.imageSrc, 142, 85)} alt="" />
          </div>
        ) : null}
        {services && services.length > 0 ? (
          services.some(item => item.isWaitListed && item.isExcursion) ===
          true ? (
            <div className="wait-listed">
              <span className="bold">Hinweis:</span> Bei den markierten
              Ausflügen stehen Sie auf unserer Warteliste
            </div>
          ) : null
        ) : null}
        <ul className="itinerary-item__event-list">
          {services && services.length > 0
            ? this.extendedServices.map((service: Booking, index: number) => {
                const {
                  displayTime,
                  extendedDisplayType,
                  inclusive,
                  meetingPoint,
                  isExcursion,
                  isWaitListed,
                  excursionDisplayTime,
                } = service;
                return (
                  <li className="itinerary-service__item" key={index}>
                    <span className="itinerary-service__time time">
                      {isExcursion
                        ? excursionDisplayTime
                        : displayTime}
                    </span>
                    <span
                      className={`${
                        isWaitListed && isExcursion ? `wait-listed-point` : ''
                      }`}
                    >
                      {`${extendedDisplayType}${
                        inclusive ? ' | Inklusivleistung' : ''
                      }`}
                      {isExcursion && meetingPoint
                        ? ', Treffpunkt: ' + meetingPoint
                        : ''}
                    </span>
                  </li>
                );
              })
            : showEmpty ? this.renderEmptyState() : null}
        </ul>
        {badge ? (
          <Badge booked inset right>
            {badge}
          </Badge>
        ) : null}
      </Link>
    );
  }
}
