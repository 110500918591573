// @flow
import { assign, required, optional } from '../utils/model';
import type Bookable from './Bookable';
import type GuestType from './GuestType';
import { PRICE_MODELS } from './GuestType';
import { adjustedDate } from '../utils/date';
import { computed } from 'mobx';

export type ArrivalAtType = {
  time: ?string,
  where: ?string,
};

export default class PartyMember {
  mpi: number;
  firstName: string;
  lastName: string;
  email: ?string;
  cabinNumber: ?string;
  dateOfBirth: ?string;
  age: number;
  requestor: boolean;
  simpleGender: string;
  userState: string;
  userStateText: string;
  schengenResident: ?boolean;
  personHeight: ?string;
  cabinInfo: {
    cabin: string,
    deck: number,
    type: string,
    isJuniorSuite: boolean,
    isRegularSuite: boolean,
    isSuite: boolean,
  };
  manifest: {
    enabled: boolean,
    pinAvailable: boolean,
    preliminary: boolean,
    completed: boolean,
    allowPartialSave: boolean,
    isBirthdateUpdateAllowed: boolean,
    isTitleUpdateAllowed: boolean,
    reason: ?string,
  };
  payment: {
    available: boolean,
    enabled: boolean,
    preliminary: boolean,
    completed: boolean,
    reason: ?string,
  };
  checkin: {
    available: boolean,
    enabled: boolean,
    preliminary: boolean,
    completed: boolean,
    reason: ?string,
  };
  questionnaire: ?{
    mpi: number,
    status: string,
    tile: {
      active: boolean,
      visible: boolean,
    },
    openFrom?: string,
    openUntil?: string,
    reason?: string,
    surveyId?: number,
  };
  arrivalAt: ?ArrivalAtType;
  guestType: GuestType;

  constructor(data: Object) {
    assign(this, data, {
      mpi: required(Number),
      firstName: required(String),
      lastName: required(String),
      email: String,
      cabinNumber: String,
      dateOfBirth: String,
      age: required(Number),
      requestor: Boolean,
      simpleGender: optional(String),
      userState: required(String),
      userStateText: optional(String),
      schengenResident: optional(Boolean),
      manifest: required(Object),
      payment: required(Object),
      checkin: required(Object),
      cabinInfo: required(Object),
      arrivalAt: optional(Object),
      personHeight: optional(String),
      guestType: required(Object),
      questionnaire: optional(Object),
    });
  }

  get isBaby(): boolean {
    return this._isBaby(this.age);
  }

  get isChild(): boolean {
    return this._isChild(this.age);
  }

  get isFullAged(): boolean {
    return this._isFullAged(this.age);
  }

  get isAdult(): boolean {
    return this._isAdult(this.age);
  }

  /**
   * Children don't have a simpleGender, so we just assume they are all male. Just for sanity of coding
   */
  get isMale(): boolean {
    return !this.simpleGender || this.simpleGender === 'M';
  }

  get isFemale(): boolean {
    return this.simpleGender === 'F';
  }

  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get displayNameShortend(): string {
    return `${this.firstName} ${this.lastName.substr(0, 1)}.`;
  }

  get arrivalAtDisplay(): ?string {
    if (!this.arrivalAt) return 'Anreise findet mit TUI Cruises statt';
    if (!this.arrivalAt.where || !this.arrivalAt.time) {
      return null;
    }
    const mapping = {
      airport: 'Flughafen',
      port: 'Hafen',
    };
    const where = mapping[this.arrivalAt.where];
    if (!where) return null;
    return `Ankunft am ${where}: ${this.arrivalAt.time} Uhr`;
  }

  get displayAge(): string {
    return this._displayAge(this.age);
  }

  get manifestEnabled(): boolean {
    return this.manifest.enabled;
  }

  get manifestCompleted(): boolean {
    return this.manifest.completed;
  }

  get manifestPreliminary(): boolean {
    return this.manifest.preliminary;
  }

  get manifestAllowsPartialSave(): boolean {
    return this.manifest.allowPartialSave;
  }

  get isBirthdateUpdateAllowed(): boolean {
    return this.manifest.isBirthdateUpdateAllowed;
  }

  get isTitleUpdateAllowed(): boolean {
    return this.manifest.isTitleUpdateAllowed;
  }

  get manifestStatusReason(): ?string {
    return this.manifest.reason;
  }

  get paymentAvailable(): boolean {
    return this.payment.available;
  }

  get paymentEnabled(): boolean {
    return this.payment.enabled;
  }

  get paymentPreliminary(): boolean {
    return this.payment.preliminary;
  }

  get paymentCompleted(): boolean {
    return this.payment.completed;
  }

  get paymentStatusReason(): ?string {
    return this.payment.reason;
  }

  get pinAvailable(): boolean {
    return this.manifest.pinAvailable;
  }

  get checkinAvailable(): boolean {
    return this.checkin.available;
  }

  get checkinEnabled(): boolean {
    return this.checkin.enabled;
  }

  get checkinPreliminary(): boolean {
    return this.checkin.preliminary;
  }

  get checkinCompleted(): boolean {
    return this.checkin.completed;
  }

  get checkinStatusReason(): ?string {
    return this.checkin.reason;
  }

  get digitalHealthAvailable(): boolean {
    return this.questionnaire && this.questionnaire.tile
      ? this.questionnaire.tile.visible
      : false;
  }

  get digitalHealthState(): boolean {
    return this.questionnaire ? this.questionnaire.status : false;
  }

  get digitalHealthReason(): ?string {
    return this.questionnaire ? this.questionnaire.reason : '';
  }

  get digitalHealthSurveyId(): number {
    return this.questionnaire ? this.questionnaire.surveyId : null;
  }

  isOldEnoughFor(bookable: ?Bookable): boolean {
    return !!bookable || true;
    // Obviously we let people book that stuff and they are just not allowed on board
    // return !bookable.minimumAge || bookable.minimumAge <= this.age;
  }

  /**
   * determine the age of the PartyMember on a given date
   *
   * See: TUICMRL-1178
   */
  // TODO: write unit test for this
  ageOnDate(date: string): number {
    if (!date || !this.dateOfBirth) return 218;

    const givenDate = adjustedDate(new Date(date));
    const dateOfBirth = adjustedDate(new Date(this.dateOfBirth || 0));
    // calculate age in the year of the given date
    const age = givenDate.getFullYear() - dateOfBirth.getFullYear();
    // calc if birthday has already occurred until & including the given date
    const calcCorrection = (diffMonths, diffDays) => {
      if (diffMonths < 0) return 1; //birth-month later than date
      if (diffMonths > 0) return 0; //birth-month earlier than date
      //birth-month equal to date, check birth-day
      if (diffMonths === 0) {
        if (diffDays < 0) return 1; //birth-day later than date
        if (diffDays >= 0) return 0; //birth-day earlier than date
      }
    };
    const correction = calcCorrection(
      givenDate.getMonth() - dateOfBirth.getMonth(),
      givenDate.getDate() - dateOfBirth.getDate()
    );

    // assume adult in case of failure, but make it obvious
    if (Number.isNaN(age)) return 218;

    return age - (correction || 0);
  }

  displayAgeOnDate(date: string): string {
    return this._displayAge(this.ageOnDate(date));
  }

  _isBaby(age: number): boolean {
    return age < 4;
  }

  _isChild(age: number): boolean {
    return age <= 14;
  }

  _isFullAged(age: number): boolean {
    return age >= 18;
  }

  _isAdult(age: number): boolean {
    return !this._isBaby(age) && !this._isChild(age);
  }

  _displayAge(age: number): string {
    let ageCategory = 'Erwachsener';
    if (this._isChild(age)) {
      ageCategory = this._isBaby(age) ? 'Baby' : 'Kind';
    }
    return ageCategory;
  }

  @computed
  get priceModel(): string {
    return this.guestType && this.guestType.priceModel
      ? this.guestType.priceModel.toLowerCase()
      : '';
  }

  get hasFeelGoodTariff(): boolean {
    return this.priceModel === PRICE_MODELS.feelGood.toLowerCase();
  }

  get hasFeelGoodPlusTariff(): boolean {
    return this.priceModel === PRICE_MODELS.feelGoodPlus.toLowerCase();
  }

  get hasFlexTariff(): boolean {
    return this.priceModel === PRICE_MODELS.flex.toLowerCase();
  }

  get hasPlusTariff(): boolean {
    return this.priceModel === PRICE_MODELS.plus.toLowerCase();
  }

  get hasProTariff(): boolean {
    return this.priceModel === PRICE_MODELS.pro.toLowerCase();
  }

  get hasPurTariff(): boolean {
    return this.priceModel === PRICE_MODELS.pur.toLowerCase();
  }

  get hasVipOption1(): boolean {
    return !!(this.guestType && this.guestType.vipPackage1);
  }

  get hasVipOption2(): boolean {
    return !!(this.guestType && this.guestType.vipPackage2);
  }

  get hasTuicTravelToShip(): boolean {
    return !!(this.guestType && this.guestType.includesTuicTravelToShip);
  }

  // TUICUNIT-1993
  get hasVip(): boolean {
    return !!(
      this.guestType &&
      (this.guestType.vipPackage1 || this.guestType.vipPackage2)
    );
  }

  get hasSuite(): boolean {
    return !!(this.cabinInfo && this.cabinInfo.isSuite);
  }
}
