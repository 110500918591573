// @flow
import React, { type Node } from 'react';
import { USER_PIN_LENGTH } from '../../config/constants';
import IdCardIcon from '../../../assets/svg/inline/reisepass.inline.svg';

const keys = ['manifest', 'payment', 'health'];

const submitButtonText = {
  manifest: 'Weiter zum Schiffsmanifest',
  payment: 'Weiter zu den Zahlungsmitteln',
  health: 'Weiter zum Gesundheitsfragebogen',
  fallback: 'Weiter',
};

const submitText = (url: string): string => {
  let text = submitButtonText.fallback;
  keys.forEach((key) => {
    text = url && url.indexOf(key) >= 0 ? submitButtonText[key] : text;
  });
  return text;
};

const Headline = ({ partyMember }: Object): Node => {
  const name = partyMember ? partyMember.displayName : 'die ausgewählte Person';

  return <h2>Bitte geben Sie die Sicherheits-PIN für {name} ein</h2>;
};

const InfoText = (): Node => {
  return (
    <p>
      Dieser Bereich ist aus datenschutzrechtlichen Gründen mit einer
      Sicherheits-PIN geschützt.
    </p>
  );
};

const ErrorMessage = (): Node => {
  return (
    <p className="error-message">
      Leider stimmt Ihre Eingabe nicht mit Ihrer Sicherheits-PIN überein. Bitte
      überprüfen Sie Ihre Eingabe. Sollten Sie Probleme mit der Eingabe der
      Sicherheits-PIN haben, wenden Sie sich bitte an unser Serviceteam.
    </p>
  );
};

const MainButtons = ({ pin, onSubmit, onClose, url }: Object): Node => {
  return (
    <fieldset className="modal-buttons">
      <button
        disabled={pin.length < USER_PIN_LENGTH}
        className="dark block"
        onClick={onSubmit}
      >
        {submitText(url)}
      </button>
      <button className="link" onClick={onClose}>
        Abbrechen
      </button>
    </fieldset>
  );
};

const InfoHeadline = ({ onClick }: Object): Node => {
  return <h3 onClick={onClick}>Wo finde ich meine Sicherheits-PIN?</h3>;
};

const TogglePinButton = ({ onClick, showPin, disabled }: Object): Node => {
  return (
    <button disabled={disabled} className="toggle-pin" onClick={onClick}>
      {showPin ? 'Verbergen' : 'Anzeigen'}
    </button>
  );
};

const BackButton = ({ onClick }: Object): Node => {
  return (
    <fieldset className="modal-buttons" onClick={onClick}>
      <button className="link">Zurück</button>
    </fieldset>
  );
};

const PinInfoHeadline = (): Node => {
  return (
    <h2>
      <i className="icon info" /> Wo finde ich meine Sicherheits-PIN?
    </h2>
  );
};

const PinInfoText = (): Node => {
  return (
    <p>
      Die Sicherheits-PIN ergibt sich aus den letzten 4 Zeichen{' '}
      <strong className="red">XXXX</strong> Ihrer angegebenen Personalausweis-
      oder Reisepassnummer.
    </p>
  );
};

const PinInfoImage = (): Node => {
  return (
    <span className="svg id-card">
      <IdCardIcon className="inline-svg" />
    </span>
  );
};

export {
  Headline,
  InfoText,
  ErrorMessage,
  MainButtons,
  InfoHeadline,
  TogglePinButton,
  BackButton,
  PinInfoHeadline,
  PinInfoText,
  PinInfoImage,
};
