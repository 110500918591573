// @flow

import React, { Component, type Node } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { loadImg } from '../../../utils/image';
import classNames from 'classnames';
import Headline from '../../Headline';

import LoadingIndicator from '../../LoadingIndicator';

import type MasterStore from '../../../stores/MasterStore';
import type ArrivalDepartureStore from '../../../stores/ArrivalDepartureStore';
import ArrivalDepartureList from '../../ArrivalDeparture/ArrivalDepartureList';
import TravelPartyFilter from '../../ArrivalDeparture/TravelPartyFilter';
import Faq from '../../ArrivalDeparture/Faq';
import operatorInfo from '../../../models/ArrivalDeparture/static-data/operatorInfo';
import RailVoucherView from '../../ArrivalDeparture/RailVoucher';

import PhoneBlock from '../../ArrivalDeparture/PhoneBlock';
import MailBlock from '../../ArrivalDeparture/MailBlock';
import LinkAsButton from '../../LinkAsButton';
import isInAppView from '../../../utils/isInAppView';
import Button from '../../Button';

type Props = {
  masterStore: MasterStore,
  arrivalDepartureStore: ArrivalDepartureStore,
};

type State = {
  mpi: ?number,
};

@inject('masterStore', 'arrivalDepartureStore')
@observer
export default class ArrivalDeparture extends Component<Props, State> {
  static breadcrumb = 'An- & Abreise';

  constructor(props: Props) {
    super(props);
    this.state = {
      mpi: null,
    };
  }

  componentDidMount() {
    this.props.arrivalDepartureStore.fetch();
  }

  @autobind personFilterChanged(mpi: number) {
    this.setState({ mpi });
  }

  renderSleepAndPark(): Node {
    const link = 'https://www.holidayextras.de/partner-wl/tuicruises.html?custref=login';

    return (
      <div
        key={`teaser-park-and-fly`}
        onClick={(e) => {
          e.preventDefault();

          if (isInAppView) {
            window.location.href = link;
          } else {
            window.open(link, '_blank');
          }
        }}
        className={classNames({
          'sleep-and-park-teaser': true,
        })}
      >
        <img src={loadImg('tiles/park-and-fly-teaser-616x155.jpg')} alt={'teaser'} />
        <div className="sleep-and-park-teaser__title">
          Hotel & Parken, Airport Lounges und weitere Services für Ihre An- und Abreise
        </div>
        <p />
        <LinkAsButton link={link} newPage={!isInAppView} asAnchor={isInAppView} dark={false}>
          Mehr Informationen
        </LinkAsButton>
      </div>
    );
  }

  renderFaq(types: Array<string>): Node {
    const { arrivalDepartureStore } = this.props;
    const { railVoucher } = arrivalDepartureStore;

    if (!railVoucher && types.some((i) => i === 'train')) {
      const temp = types || [];
      types = null;
      types = temp.filter((i) => i !== 'train');
    }

    return (
      <div className="faq-list l-col double">
        <div className="l-mod l-block">
          <h1>Häufige Fragen</h1>
          <div className="l-block">
            <Faq types={types} />
          </div>
        </div>
      </div>
    );
  }

  //TUICUNIT-3337
  renderTrainOnly(): Node {
    const { arrivalDepartureStore } = this.props;
    const { arrivalDeparture, railVoucher } = arrivalDepartureStore;

    const { mpi } = this.state;
    let selectedMpi = mpi || null;
    const { participantShortList } = arrivalDeparture;
    const persons = participantShortList ? participantShortList.slice() : null;
    if (!selectedMpi) {
      selectedMpi = (persons && persons[0] && persons[0].mpi) || null;
    }

    return (
      <div className="page arrival-departure">
        <Headline title="Ihre An- & Abreise" />

        <div className="l-row group">
          <div className="travel-party-options l-col right l-mod-sub no-print">
            <h2>Teilnehmer auswählen:</h2>
            {persons && <TravelPartyFilter value={selectedMpi} persons={persons} onChange={this.personFilterChanged} />}
          </div>
          <RailVoucherView railVoucher={railVoucher} />
        </div>
        {this.renderFaq('train')}
      </div>
    );
  }

  //TUICUNIT-3337: maybe deprecated
  renderNoTrainYet(): Node {
    const { arrivalDepartureStore } = this.props;
    const { arrivalDeparture } = arrivalDepartureStore;
    const operator = arrivalDeparture ? arrivalDeparture.operatorInfo : operatorInfo.unknown;
    const agencyPhoneNumber = arrivalDeparture ? arrivalDeparture.agencyPhoneNumber : null;
    const callableAgencyPhoneNumber = arrivalDeparture ? arrivalDeparture.callableAgencyPhoneNumber : null;
    const bookingDataProcessing =
      arrivalDeparture && arrivalDeparture.train ? arrivalDeparture.train.bookingDataProcessing : null;

    return (
      <div className="page arrival-departure">
        <Headline title="Ihre An- & Abreise" />
        {bookingDataProcessing ? (
          <div className="no-arrival-departure">{bookingDataProcessing}</div>
        ) : (
          <span>
            <div className="no-arrival-departure">
              Details zu Ihrer gebuchten An- und Abreise werden hier zur Verfügung gestellt, sobald sie freigeschaltet
              sind. Nach der Freischaltung können Sie hier Ihren Fahrtverlauf einsehen und ihre Bahntickets als PDF
              Datei herunterladen.
            </div>
            <div className="no-arrival-departure">
              Buchungsdetails zu ihrer An- und Abreise mit der Bahn finden Sie bereits in ihren Reiseunterlagen und auf
              Buchungsbestätigung.
            </div>
          </span>
        )}

        <div style={{ marginBottom: '20px' }}>
          <LinkAsButton
            link={'https://www.meinschiff.com/an-und-abreise'}
            newPage={!isInAppView}
            asAnchor={isInAppView}
            dark={true}
          >
            Allgemeine Informationen
          </LinkAsButton>
        </div>

        <br />
        {callableAgencyPhoneNumber && (
          <div className="contact-block">
            {operator.contactHeadline && <h2>{operator.contactHeadline}</h2>}

            {operator.viewMail ? (
              <MailBlock />
            ) : (
              <PhoneBlock phoneNumber={callableAgencyPhoneNumber} displayPhoneNumber={agencyPhoneNumber} />
            )}

            {operator.openingTimes && (
              <div className="opening-times" dangerouslySetInnerHTML={{ __html: operator.openingTimes }} />
            )}
          </div>
        )}
        <br />
        <br />
      </div>
    );
  }

  renderWithoutData(): Node {
    const { arrivalDepartureStore } = this.props;
    const { arrivalDeparture } = arrivalDepartureStore;
    const operator = arrivalDeparture ? arrivalDeparture.operatorInfo : operatorInfo.unknown;
    const agencyPhoneNumber = arrivalDeparture ? arrivalDeparture.agencyPhoneNumber : null;
    const callableAgencyPhoneNumber = arrivalDeparture ? arrivalDeparture.callableAgencyPhoneNumber : null;
    return (
      <div className="page arrival-departure empty">
        <Headline title={operator.headline} />

        <div className="no-arrival-departure" dangerouslySetInnerHTML={{ __html: operator.text }} />
        {/*<div className="no-arrival-departure" dangerouslySetInnerHTML={{ __html: operatorInfo.defaultLink.link }} />*/}

        <div style={{ marginBottom: '20px' }}>
          <LinkAsButton
            link={'https://www.meinschiff.com/an-und-abreise'}
            newPage={!isInAppView}
            asAnchor={isInAppView}
            dark={true}
          >
            Allgemeine Informationen
          </LinkAsButton>
        </div>

        <br />
        {callableAgencyPhoneNumber && (
          <div className="contact-block">
            {operator.contactHeadline && <h2>{operator.contactHeadline}</h2>}

            {operator.viewMail ? (
              <MailBlock />
            ) : (
              <PhoneBlock phoneNumber={callableAgencyPhoneNumber} displayPhoneNumber={agencyPhoneNumber} />
            )}

            {operator.openingTimes && (
              <div className="opening-times" dangerouslySetInnerHTML={{ __html: operator.openingTimes }} />
            )}
          </div>
        )}
        <br />
        <div className="l-row group">
          <div className="l-col double arrival-departure-items">
            <div className="l-mod l-block">
              <div className="arrival-departure-list">{this.renderSleepAndPark()}</div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    );
  }

  renderWithData(): Node {
    const { arrivalDepartureStore, masterStore } = this.props;
    const { masterData } = masterStore;

    const { arrivalDeparture, railVoucher } = arrivalDepartureStore;
    if (!arrivalDeparture) return null;
    const { mpi } = this.state;
    let selectedMpi = mpi || null;

    const { participantShortList } = arrivalDeparture;
    const persons = participantShortList ? participantShortList.slice() : null;
    if (!selectedMpi) {
      selectedMpi = (persons && persons[0] && persons[0].mpi) || null;
    }

    return (
      <div className="page arrival-departure">
        <Headline className="l-mod" title="Ihre An- & Abreise" subtitle={masterData.subtitleLine} />

        <div className="l-row group">
          <div className="travel-party-options l-col right l-mod-sub no-print">
            <h2>Teilnehmer auswählen:</h2>
            {persons && <TravelPartyFilter value={selectedMpi} persons={persons} onChange={this.personFilterChanged} />}
          </div>
          <ArrivalDepartureList
            selectedMpi={selectedMpi}
            arrivalDeparture={arrivalDeparture}
            railVoucher={railVoucher}
          />
          <RailVoucherView railVoucher={railVoucher} />
          {this.renderFaq(arrivalDeparture.transportTyps)}
        </div>
      </div>
    );
  }

  render() {
    const { arrivalDepartureStore } = this.props;
    if (arrivalDepartureStore.isLoading) return <LoadingIndicator />;
    const { arrivalDeparture, railVoucher } = arrivalDepartureStore;

    // TUICUNIT-3337: hastrain  only and railVoucher
    if (arrivalDeparture && !arrivalDeparture.hasData && arrivalDeparture.hasTrain && railVoucher) {
      return this.renderTrainOnly();
    }

    //TUICUNIT-3337: maybe deprecated
    /*if (
      arrivalDeparture &&
      !arrivalDeparture.hasData &&
      arrivalDeparture.train &&
      (arrivalDeparture.train.numberOfBookings !== 0 || arrivalDeparture.train.bookingDataProcessing !== null)
    ) {
      // return this.renderNoTrainYet();
    }*/

    return arrivalDeparture && arrivalDeparture.hasData ? this.renderWithData() : this.renderWithoutData();
  }
}
