// @flow
import React, { useState, useCallback, useEffect } from 'react';
import ArrowIcon16 from '../../../../assets/svg/inline/arrow-icon-16.inline.svg';
import HintIcon from '../../../../assets/svg/inline/hint-icon.inline.svg';
import ConfirmIcon from '../../../../assets/svg/inline/ic-confirm.inline.svg';
import LoadingIndicator from '../../../components/LoadingIndicator';
import storage from '../../../utils/storage';
import Headline from '../../Headline';
import { l10n } from './l10n';

import {
  SURVEY_MPI_OK,
  SURVEY_MPI_NOT_OK,
  SURVEY_MPI_CONFIRMATION_REQUIRED,
  SURVEY_CLOSED,
  SURVEY_SINGLECHOICE,
  SURVEY_MULTICHOICE,
} from '../../../config/constants';

import type {
  DigitalHealthStore,
  QuestionType,
  AnswerType,
  SurveyMetaType,
} from '../../../stores/DigitalHealthStore';

import type PartyMember from '../../../models/PartyMember';

type Props = {
  currentMember: PartyMember,
  digitalHealthStore: DigitalHealthStore,
  inProgress: boolean,
  viewResult: boolean,
  lang: string,
};

export default function Survey(props: Props) {
  const [lang, setLang] = useState(props.lang);
  const [hintView, setHintView] = useState(false);
  const [currentView, setCurrentView] = useState(0);
  const [answerStore, setAnswerStore] = useState({});
  const [allowNext, setAllowNext] = useState(false);
  const [viewResult, setViewResult] = useState(props.viewResult);
  const { currentMember, digitalHealthStore, inProgress } = props;

  const questions: [QuestionType] = digitalHealthStore.getQuestions;
  const surveyMeta: SurveyMetaType = digitalHealthStore.getSurveyMeta;
  useEffect(
    () => {
      if (viewResult === false) {
        if (surveyMeta.status === SURVEY_MPI_OK) {
          setViewResult(true);
        }
        if (surveyMeta.status === SURVEY_MPI_NOT_OK) {
          setViewResult(true);
        }
        if (surveyMeta.status === SURVEY_MPI_CONFIRMATION_REQUIRED) {
          setViewResult(true);
        }
        if (surveyMeta.status === SURVEY_CLOSED) {
          setViewResult(true);
        }
      }
    },
    [surveyMeta]
  );

  const toggle = useCallback(
    e => {
      e.preventDefault();
      setLang(lang === 'de' ? 'en' : 'de');
      storage.set('language', lang === 'de' ? 'en' : 'de');
    },
    [lang]
  );

  const toggleHint = useCallback(
    e => {
      e.preventDefault();
      setHintView(!hintView);
    },
    [hintView]
  );

  const nextQ = useCallback(
    e => {
      e.preventDefault();
      if (questions.length > currentView + 1) {
        setCurrentView(currentView + 1);

        // TUICUNIT-2681: changed internal save structure
        let next = false;
        if (answerStore[questions[currentView + 1].id]) {
          next = Object.keys(answerStore[questions[currentView + 1].id]).some(
            (index: number) =>
              answerStore[[questions[currentView + 1].id]][index] !== null
          );
        }
        if (!next) {
          setAllowNext(false);
        }
      }
    },
    [currentView]
  );

  const prevQ = useCallback(
    e => {
      e.preventDefault();
      if (currentView >= 0) {
        setCurrentView(currentView - 1);
      }
      setAllowNext(true);
    },
    [currentView]
  );

  // TUICUNIT-2681: changed internal save structure
  const selectStore = useCallback(
    (qId, aId, value, type) => {
      setAnswerStore(answerStore);
      if (!answerStore[qId]) {
        answerStore[qId] = {};
      }
      if (type === SURVEY_SINGLECHOICE) {
        answerStore[qId] = {};
      }
      answerStore[qId][aId] = value;
      setAnswerStore(answerStore);
      const next = Object.keys(answerStore[qId]).some(
        (index: number) => answerStore[qId][index] !== null
      );

      if (next) {
        setAllowNext(true);
      } else {
        setAllowNext(false);
      }
    },
    [answerStore]
  );

  const saveDate = useCallback(
    e => {
      e.preventDefault();
      digitalHealthStore.saveData(answerStore, currentMember);
    },
    [answerStore]
  );

  const editData = useCallback(
    e => {
      e.preventDefault();
      // TUICUNIT-2681: changed internal save structure, changed api structure
      questions.map((value: QuestionType) => {
        value.answers.map((valueAnswer: AnswerType) => {
          if (!answerStore[valueAnswer.questionId]) {
            answerStore[valueAnswer.questionId] = {};
          }
          if (valueAnswer.isGiven) {
            if (value.isText) {
              answerStore[valueAnswer.questionId][valueAnswer.id] = `${
                valueAnswer.answerText
              }`;
            }
            if (value.isRadio) {
              answerStore[valueAnswer.questionId][valueAnswer.id] = `${
                valueAnswer.id
              }`;
            }
            if (value.isCheckBox && value.questionType === SURVEY_MULTICHOICE) {
              answerStore[valueAnswer.questionId][valueAnswer.id] = `${
                valueAnswer.id
              }`;
            }
          }
        });
      });

      setCurrentView(0);
      setAnswerStore(answerStore);
      setAllowNext(true);

      setViewResult(false);
    },
    [viewResult, answerStore]
  );

  const confirmData = useCallback(e => {
    e.preventDefault();
    digitalHealthStore.confirmData(currentMember);
  }, []);

  return (
    <span>
      <Headline title={`${surveyMeta.title[lang]}`} />
      <div className="l-row">
        <div className="l-col double">
          <div
            className="intro"
            dangerouslySetInnerHTML={{
              __html: surveyMeta.text[lang],
            }}
          />
          <div className="survey-wrapper">
            <h2>
              <i className="icon healthy" />
              <span className="name">
                {currentMember.firstName} {currentMember.lastName}
              </span>
              <span className="lang">
                <span className="lang-inner" onClick={toggle}>
                  <span className={lang === 'de' ? 'bold' : ''}>DEU</span> |{' '}
                  <span className={lang === 'en' ? 'bold' : ''}>ENG</span>
                </span>
              </span>
            </h2>
            <div className="box">
              {viewResult === false ? (
                <div className="questions">
                  <div className="count">
                    {currentView + 1}/{questions.length}
                  </div>
                  <div className="question">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: questions[currentView].questionText[lang],
                      }}
                    />
                    {questions[currentView].hint !== null ? (
                      <span className="hint-icon" onClick={toggleHint}>
                        <HintIcon viewBox="0 0 30 30" />
                      </span>
                    ) : null}
                  </div>
                  {questions[currentView].hint !== null ? (
                    <HintElement
                      hintView={hintView}
                      toggleHint={toggleHint}
                      lang={lang}
                      text={questions[currentView].hint[lang]}
                    />
                  ) : null}
                  <div className="answer">
                    {questions[currentView].isRadio ? (
                      <RadioElement
                        lang={lang}
                        answers={questions[currentView].answers}
                        callback={selectStore}
                        answerStore={answerStore}
                      />
                    ) : null}
                    {questions[currentView].isCheckBox ? (
                      <CheckboxElement
                        lang={lang}
                        answers={questions[currentView].answers}
                        callback={selectStore}
                        answerStore={answerStore}
                      />
                    ) : null}
                    {questions[currentView].isText ? (
                      <TextElement
                        lang={lang}
                        answers={questions[currentView].answers}
                        callback={selectStore}
                        answerStore={answerStore}
                      />
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className="result">
                  {surveyMeta.status === SURVEY_MPI_OK ? (
                    <div className="headline ok">
                      {l10n.surveyResult[lang].headlineOk}
                    </div>
                  ) : null}
                  {surveyMeta.status === SURVEY_MPI_NOT_OK ? (
                    <span>
                      <div
                        className={`headline no ${surveyMeta.attentionLevel}`}
                      >
                        {surveyMeta.resultInfos
                          ? surveyMeta.resultInfos.headline[lang]
                          : surveyMeta.resultInfo
                            ? surveyMeta.resultInfo.headline
                            : l10n.surveyResult[lang].headlineNotOk}
                      </div>
                      <div className="r-wrong-info">
                        {surveyMeta.resultInfos
                          ? surveyMeta.resultInfos.description[lang]
                          : surveyMeta.resultInfo
                            ? surveyMeta.resultInfo.description
                            : l10n.surveyResult[lang].notOkText}
                      </div>
                      <div className="wrong-line" />
                    </span>
                  ) : null}
                  {surveyMeta.status === SURVEY_MPI_CONFIRMATION_REQUIRED ? (
                    <div className="headline confirm">
                      <ConfirmIcon />
                      {l10n.confirm[lang].headline}
                    </div>
                  ) : null}
                  <ol>
                    {questions.map((value: QuestionType, key: number) => {
                      return (
                        <li key={key}>
                          <div className={`part next-line`}>
                            <div
                              className={`r-question next-line`}
                              dangerouslySetInnerHTML={{
                                __html: value.questionText[lang],
                              }}
                            />
                            {value.answers.map(
                              (answer: AnswerType, answerKey: number) => {
                                if (answer.isGiven) {
                                  return (
                                    <span key={answerKey}>
                                      {value.isText === true ? (
                                        <div
                                          className={`r-answer${
                                            !answer.isCorrect
                                              ? ` no ${answer.attentionLevel ||
                                                  ''}`
                                              : ''
                                          }`}
                                        >
                                          {answer.answerText}
                                        </div>
                                      ) : (
                                        <div
                                          className={`r-answer${
                                            !answer.isCorrect
                                              ? ` no ${answer.attentionLevel ||
                                                  ''}`
                                              : ''
                                          }`}
                                          dangerouslySetInnerHTML={{
                                            __html: answer.answerChoise[lang],
                                          }}
                                        />
                                      )}
                                    </span>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </div>
                          {value.allWorngAnswers &&
                            value.allWorngAnswers.map(
                              (value: AnswerType, key: number) => {
                                return (
                                  <div
                                    key={key}
                                    className={`r-wrong ${value.attentionLevel ||
                                      ''}`}
                                    dangerouslySetInnerHTML={{
                                      __html: value.message[lang],
                                    }}
                                  />
                                );
                              }
                            )}
                        </li>
                      );
                    })}
                  </ol>
                  <div className="timestamp">
                    <span className="bold">
                      {l10n.surveyResult[lang].submitted}:
                    </span>{' '}
                    {surveyMeta.submitted} {l10n.surveyResult[lang].timeUnit}
                  </div>
                  {surveyMeta.status !== SURVEY_CLOSED ? (
                    <div className="line" />
                  ) : (
                    <br />
                  )}
                </div>
              )}

              {viewResult === false ? (
                <div className="navigation">
                  {currentView !== 0 ? (
                    <div className="prev" onClick={prevQ}>
                      <ArrowIcon16 viewBox="0 0 16 16" />
                      <span>{l10n.survey[lang].prev}</span>
                    </div>
                  ) : (
                    <div />
                  )}
                  {questions.length > currentView + 1 ? (
                    <div
                      className={`next${allowNext ? '' : ' not-yet'}`}
                      onClick={nextQ}
                    >
                      <span>{l10n.survey[lang].next}</span>
                      <ArrowIcon16 viewBox="0 0 16 16" />
                    </div>
                  ) : inProgress ? (
                    <LoadingIndicator />
                  ) : (
                    <div
                      className={`button dark${allowNext ? '' : ' not-yet'}`}
                      onClick={saveDate}
                    >
                      <span>{l10n.survey[lang].save}</span>
                    </div>
                  )}
                </div>
              ) : (
                <span>
                  {surveyMeta.status === SURVEY_MPI_CONFIRMATION_REQUIRED ? (
                    <div className="navigation result-view confirm">
                      <div className="text">{l10n.confirm[lang].text}</div>
                      {inProgress ? (
                        <LoadingIndicator />
                      ) : (
                        <div
                          className={`button dark`}
                          onClick={confirmData}
                          dangerouslySetInnerHTML={{
                            __html: l10n.confirm[lang].buttonYes,
                          }}
                        />
                      )}
                      <div
                        className={`button dark`}
                        onClick={editData}
                        dangerouslySetInnerHTML={{
                          __html: l10n.confirm[lang].buttonNo,
                        }}
                      />
                    </div>
                  ) : surveyMeta.status !== SURVEY_CLOSED ? (
                    <div className="navigation result-view">
                      <div />
                      <div className={`button dark`} onClick={editData}>
                        {l10n.surveyResult[lang].edit}
                      </div>
                    </div>
                  ) : null}
                </span>
              )}
            </div>

            {viewResult === false ? (
              <div className="navigation small">
                {currentView !== 0 ? (
                  <div className="prev button full-width" onClick={prevQ}>
                    {l10n.survey[lang].prev}
                  </div>
                ) : null}
                {questions.length > currentView + 1 ? (
                  <div
                    className={`next button dark full-width${
                      allowNext ? '' : ' not-yet'
                    }`}
                    onClick={nextQ}
                  >
                    {l10n.survey[lang].next}
                  </div>
                ) : inProgress ? (
                  <LoadingIndicator />
                ) : (
                  <div
                    className={`button dark${allowNext ? '' : ' not-yet'}`}
                    onClick={saveDate}
                  >
                    <span>{l10n.survey[lang].save}</span>
                  </div>
                )}
              </div>
            ) : (
              <span>
                {surveyMeta.status === SURVEY_MPI_CONFIRMATION_REQUIRED ? (
                  <div className="navigation small result-view confirm">
                    <div className="text">{l10n.confirm[lang].text}</div>
                    {inProgress ? (
                      <LoadingIndicator />
                    ) : (
                      <div
                        className={`button dark`}
                        onClick={confirmData}
                        dangerouslySetInnerHTML={{
                          __html: l10n.confirm[lang].buttonYes,
                        }}
                      />
                    )}
                    <div
                      className={`button dark`}
                      onClick={editData}
                      dangerouslySetInnerHTML={{
                        __html: l10n.confirm[lang].buttonNo,
                      }}
                    />
                  </div>
                ) : surveyMeta.status !== SURVEY_CLOSED ? (
                  <div className="navigation small result-view">
                    <div />
                    <div className={`button dark`} onClick={editData}>
                      {l10n.surveyResult[lang].edit}
                    </div>
                  </div>
                ) : null}
              </span>
            )}
          </div>
        </div>
      </div>
    </span>
  );
}

type HintProps = {
  hintView: boolean,
  toggleHint: Function,
  lang: string,
  text: string,
};

const HintElement = (props: HintProps) => {
  const { hintView, toggleHint, lang, text } = props;
  return (
    <div className={`hint-view ${hintView ? ' show' : ''}`}>
      <div className="modal-close" onClick={toggleHint} />
      <div className="head">
        <HintIcon viewBox="0 0 30 30" />
        {l10n.survey[lang].hintHeadline}
      </div>
      <div
        className="text"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
};

type RadioProps = {
  lang: string,
  answers: [AnswerType],
  callback: Function,
  answerStore: {},
};

const RadioElement = (props: RadioProps) => {
  const { lang, answers, callback, answerStore } = props;

  const [updateView, setUpdateView] = useState(false);

  const select = useCallback(
    e => {
      callback(
        e.target.dataset.qid,
        e.target.dataset.id,
        e.target.dataset.id,
        SURVEY_SINGLECHOICE
      );
      setUpdateView(!updateView);
    },
    [updateView]
  );

  return (
    <span>
      {answers.map((value: AnswerType, key: number) => {
        let wrongAnswer = false;
        let viewMessage = false;
        if (
          answerStore &&
          answerStore[value.questionId] &&
          answerStore[value.questionId][value.id] === `${value.id}`
        ) {
          if (value.isCorrect === false) {
            wrongAnswer = true;
          }
          viewMessage = value.message.state;
        }
        return (
          <span key={key}>
            <input
              name={`q${value.questionId}`}
              type="radio"
              id={`a${value.id}`}
              data-id={value.id}
              data-qid={value.questionId}
              onChange={select}
              checked={`${
                answerStore &&
                answerStore[value.questionId] &&
                answerStore[value.questionId][value.id] === `${value.id}`
                  ? 'checked'
                  : ''
              }`}
            />
            <label htmlFor={`a${value.id}`} className="input-radio">
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: value.text[lang],
                }}
              />
              {(!value.isCorrect && wrongAnswer) || viewMessage ? (
                <span
                  className={`${value.message[lang] ? 'wrong-answer' : ''}`}
                  dangerouslySetInnerHTML={{
                    __html: value.message[lang],
                  }}
                />
              ) : null}
            </label>
            {updateView}
          </span>
        );
      })}
      <div className="line" />
    </span>
  );
};

// TUICUNIT-2681: extended to multichoice
type CheckboxProps = {
  lang: string,
  answers: [AnswerType],
  callback: Function,
  answerStore: {},
};

const CheckboxElement = (props: CheckboxProps) => {
  const { lang, answers, callback, answerStore } = props;
  const [updateView, setUpdateView] = useState(false);

  const select = useCallback(
    e => {
      callback(
        e.target.dataset.qid,
        e.target.dataset.id,
        e.target.checked ? e.target.dataset.id : null,
        e.target.dataset.qtype
      );
      setUpdateView(!updateView);
    },
    [updateView]
  );

  return (
    <span>
      {answers.map((value: AnswerType, key: number) => {
        let wrongAnswer = false;
        let viewMessage = false;
        if (
          answerStore &&
          answerStore[value.questionId] &&
          answerStore[value.questionId][value.id] === `${value.id}`
        ) {
          if (value.isCorrect === false) {
            wrongAnswer = true;
          }
          viewMessage = value.message.state;
        }
        return (
          <span key={key}>
            <input
              name={`q${value.questionId}`}
              type="checkbox"
              id={`a${value.id}`}
              data-id={value.id}
              data-qid={`${value.questionId}`}
              data-qtype={`${value.questionType}`}
              onChange={select}
              checked={`${
                answerStore[value.questionId] &&
                answerStore[value.questionId][value.id] === `${value.id}`
                  ? 'checked'
                  : ''
              }`}
            />
            <label htmlFor={`a${value.id}`} className="input-checkbox">
              <span
                className={`text${
                  (!value.isCorrect && wrongAnswer) || viewMessage
                    ? value.message[lang] ? ' w' : ''
                    : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: value.text[lang],
                }}
              />
              {(!value.isCorrect && wrongAnswer) || viewMessage ? (
                <span
                  className={`${value.message[lang] ? 'wrong-answer' : ''}`}
                  dangerouslySetInnerHTML={{
                    __html: value.message[lang],
                  }}
                />
              ) : null}
            </label>
          </span>
        );
      })}
      <div className="line" />
    </span>
  );
};

// TUICUNIT-2681
type TextProps = {
  lang: string,
  answers: [AnswerType],
  callback: Function,
  answerStore: {},
};

const TextElement = (props: TextProps) => {
  const { lang, answers, callback, answerStore } = props;
  const [updateView, setUpdateView] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(
    () => {
      setInputValue('');
    },
    [answers]
  );

  const select = useCallback(
    e => {
      callback(
        e.target.dataset.qid,
        e.target.dataset.id,
        e.target.value.trim() === '' ? null : e.target.value.trim(),
        SURVEY_SINGLECHOICE
      );
      setUpdateView(!updateView);
    },
    [updateView]
  );

  const cleanText = (value: string) => {
    value = value
      .replace(/<script>/g, '')
      .replace(/<\/script>/g, '')
      .replace(/</g, '')
      .replace(/>/g, '')
      .replace(/&/g, '')
      .replace(/%/g, '')
      .replace(/#/g, '')
      .replace(/"/g, '')
      .replace(/\//g, '')
      .replace(/\$/g, '');
    setInputValue(value);
  };

  return (
    <span>
      {answers.map((value: AnswerType, key: number) => {
        if (
          !inputValue &&
          answerStore &&
          answerStore[value.questionId] &&
          answerStore[value.questionId][value.id]
        ) {
          setInputValue(answerStore[value.questionId][value.id]);
        }
        return (
          <span key={key} className="input-text">
            <label htmlFor={`a${value.id}`}>
              <span
                className="text"
                dangerouslySetInnerHTML={{
                  __html: value.text[lang],
                }}
              />
            </label>
            <textarea
              name={`q${value.questionId}`}
              type="text"
              id={`a${value.id}`}
              data-id={value.id}
              data-qid={`${value.questionId}`}
              value={inputValue}
              onBlur={e => (e.target.value.length !== 0 ? select(e) : null)}
              onChange={e => {
                cleanText(e.target.value);
                select(e);
              }}
              rows="4"
              maxLength="360"
            />
          </span>
        );
      })}
      <div className="line" />
    </span>
  );
};
