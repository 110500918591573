// @flow

import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import { observable, computed, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import track from '../../tracking';

import preload from '../../components/pages/decorators/preload';
import Headline from '../Headline';
import HeroImage from '../HeroImage';
import InfoBox from '../InfoBox';
import LoadingIndicator from '../LoadingIndicator';
import ExcursionList from '../Excursion/ExcursionList';
import FormSelect from '../form/Select';
import BreadcrumbLink from '../breadcrumbs/BreadcrumbLink';
import LevelFilter from '../LevelFilter';

import InfoBoxSvg from '../InfoBoxSvg';
import BonusIcon from '../../../assets/svg/inline/angebote.inline.svg';
import InfoIcon from '../../../assets/svg/inline/ic-info.inline.svg';
import VipTariffIcon from '../../../assets/svg/inline/vip-tariff.inline.svg';

import type ItineraryDay from '../../models/ItineraryDay';
import type MasterStore from '../../stores/MasterStore';
import type ExcursionStore from '../../stores/ExcursionStore';
import type ExcursionDayStore from '../../stores/ExcursionDayStore';
import type Excursion from '../../models/Excursion';

@preload({ day: 'ItineraryDay' }, false)
class ExcursionDayBreadcrumb extends Component<{
  day: ?ItineraryDay,
}> {
  getTitle() {
    const { day } = this.props;
    if (!day) return '';

    if (day.isArrival) {
      return `Anreise - ${day.port.name}`;
    }
    if (day.isDeparture) {
      return `Abreise - ${day.port.name}`;
    }
    return `Tag ${day.day} - ${day.port.name}`;
  }

  render() {
    return <BreadcrumbLink {...this.props} text={this.getTitle()} />;
  }
}

type Props = {
  masterStore: MasterStore,
  excursionStore: ExcursionStore,
  day: ItineraryDay,
};

@inject('masterStore', 'excursionStore')
@preload({
  day: 'ItineraryDay',
})
@observer
class PageExcursionDay extends Component<Props> {
  static breadcrumb = ExcursionDayBreadcrumb;

  store;
  @observable selectedCategoryId: ?number = null;
  @observable selectedDifficulties: string[] = [];

  componentDidMount() {
    if (!this.store) {
      return;
    }
    this.store.fetchList();
  }

  @computed get store(): ExcursionDayStore {
    const { day, excursionStore } = this.props;
    if (!excursionStore) {
      return null;
    }
    return excursionStore.storeForDay(day);
  }

  get subtitle(): string {
    const { day } = this.props;
    const date = day.formattedDate;
    if (day.isArrival) {
      return `Anreise - ${date}`;
    }
    if (day.isDeparture) {
      return `Abreise - ${date}`;
    }
    return `Tag ${day.day} - ${date}`;
  }

  @computed get filteredExcursions(): Excursion[] {
    if (!this.store) {
      return [];
    }
    return this.store.list.filteredItems({
      categoryId: this.selectedCategoryId,
      difficulties: this.selectedDifficulties,
    });
  }

  @autobind @action selectCategoryId(categoryId: string, label: string) {
    track.event('Landausflüge', 'Filter', 'Kategorie', label);
    this.selectedCategoryId = parseInt(categoryId, 10);
  }

  @autobind @action selectDifficulties(difficulties: string[]) {
    this.selectedDifficulties = difficulties;
  }

  render() {
    const { day, masterStore } = this.props;

    if (!this.store || !day) {
      return null;
    }

    const showArrivalInfo =
      !this.store.isLoadingList &&
      this.filteredExcursions.some(
        (excursion) => excursion.hasStatusTag('short-text') || excursion.hasStatusTag('long-text')
      );

    const { masterData } = masterStore;
    const { percentText, vipHint } = this.store;

    return (
      <div className="page page-excursion-day">
        <Headline title={day.port.name} subtitle={this.subtitle} />

        <HeroImage src={day.image} alt={day.hint} className="l-mod-sub" />

        <div className="l-row">
          <div className="l-col double">
            {day.isSeaDay ? (
              <p>
                Heute erwartet Sie ein Seetag an Bord Ihres Wohlfühlschiffes. Starten Sie gemütlich in den Tag und
                genießen Sie die frische Meeresbrise entspannt an Ihrem persönlichen Lieblingsplatz.
              </p>
            ) : (
              <p>{day.port.description}</p>
            )}
          </div>
        </div>
        {showArrivalInfo && (
          <div className="l-row">
            <div className="l-col double">
              <InfoBox noIcon>
                <strong>Hinweis:</strong> Bitte beachten Sie unbedingt Ihre aktuellen Flugzeiten für die Reservierung
                eines Landausfluges.
              </InfoBox>
            </div>
          </div>
        )}
        {day.isLandDay ? (
          <div>
            <h2>Übersicht aller Landausflüge</h2>
            <InfoBoxSvg
              className={!!percentText || vipHint ? 'dual' : ''}
              icon={<InfoIcon className="inline-svg" />}
              text="Die hier aufgelisteten Landausflüge werden von TUI Cruises zum Teil vermittelt und zum Teil veranstaltet."
            />

            {!!percentText && (
              <InfoBoxSvg
                className={vipHint ? 'dual' : ''}
                icon={<BonusIcon className="inline-svg" />}
                text={percentText}
              />
            )}

            {vipHint && <InfoBoxSvg className="vip" icon={<VipTariffIcon className="inline-svg" />} text={vipHint} />}

            <div className="l-row l-mod-sub">
              <div className="l-col">
                <FormSelect
                  name="category"
                  label="Nach Kategorie filtern:"
                  placeholder="Alle Angebote"
                  options={this.store.categoryFilterOptions}
                  onChange={this.selectCategoryId}
                />
              </div>
              <div className="l-col">
                <LevelFilter
                  label="Nach Level filtern:"
                  triggerLabel="Alle Level"
                  options={this.store.difficultyFilterOptions}
                  defaultValue={this.selectedDifficulties.slice()}
                  onChange={this.selectDifficulties}
                />
              </div>
            </div>
            {this.store.isLoadingList ? (
              <LoadingIndicator />
            ) : (
              <ExcursionList
                vip={masterData.requestorVipOption}
                urlBase={`/landausfluege/${day.dayNumber}`}
                excursions={this.filteredExcursions}
                bookings={masterStore.masterData.getBookings(day.date)}
              />
            )}
          </div>
        ) : null}

        <div className="l-row">
          <div className="l-col double">
            {!this.store.isLoadingList && !this.filteredExcursions.length && (day.isArrival || day.isDeparture) ? (
              <InfoBox>
                <h2>Wichtiger Hinweis</h2>

                <p>An Ihrem An- bzw. Abreisetag stehen keine Landausflüge zur Verfügung.</p>
              </InfoBox>
            ) : null}
            {day.isSeaDay ? (
              <InfoBox>
                <h2>Wichtiger Hinweis</h2>

                <p>Aufgrund der aktuellen Liegezeiten stehen zum gewünschten Zeitpunkt keine Ausflüge zur Verfügung.</p>
              </InfoBox>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default PageExcursionDay;
