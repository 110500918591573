// TODO: add flow types

import { observable, action, computed } from 'mobx';

import ExcursionList from '../models/ExcursionList';

export default class ExcursionDayStore {
  @observable list = null;
  @observable categoryFilter = null;
  @observable difficultyFilter = null;

  masterStore = null;
  api = null;
  itineraryDay = null;
  request = null;

  constructor(api, masterStore, itineraryDay) {
    this.api = api;
    this.masterStore = masterStore;
    this.itineraryDay = itineraryDay;
  }

  @computed
  get isLoadingList() {
    return this.list === null;
  }

  @computed
  get categoryFilterOptions() {
    return this.categoryFilter
      ? this.categoryFilter.filter(cat => cat.id).map(cat => ({
          value: `${cat.id}`,
          label: cat.name,
        }))
      : [];
  }

  @computed
  get difficultyFilterOptions() {
    return this.difficultyFilter
      ? this.difficultyFilter.map(level => ({
          value: level.id,
          label: level.name,
        }))
      : [];
  }

  @action
  fetchList() {
    if (!this.request) {
      this.request = this.api
        .getList(
          this.masterStore.user,
          this.itineraryDay,
          this.masterStore.masterData
        )
        .then(
          action(data => {
            if (data) {
              this.list = new ExcursionList(data.excursions, this.masterStore);
              this.categoryFilter = data.filter.categories;
              this.difficultyFilter = data.filter.difficulties;
              this.text10PercentPlus = data['10PercentTextPlus'];
              this.text20Percent = data['20PercentText'];
              this.text20PercentPro = data['20PercentTextPro'];
              this.textVip1 = data.textVip1;
            }
            this.request = null;
          })
        );
    }
    return this.request;
  }

  get percentText() {
    if (!this.masterStore || !this.masterStore.masterData) return null;

    const {
      requestorHasFeelGoodPlusTariff,
      requestorHasProTariff,
      requestorHasPlusTariff,
    } = this.masterStore.masterData;

    const text = requestorHasFeelGoodPlusTariff && this.text20Percent;
    const textPro = requestorHasProTariff && this.text20PercentPro;
    const textPlus = requestorHasPlusTariff && this.text10PercentPlus;

    return text || textPro || textPlus;
  }

  get vipHint() {
    const { requestorHasVipOption1 } = this.masterStore.masterData;
    return requestorHasVipOption1 && this.textVip1;
  }
}
