// @flow

/**
 * Generates the tracking category value ('page_category').
 */
export function generatePageCategory(path: string, title: string, hash: ?string = null): string {
  if (path === '/') {
    return 'home';
  }

  if (path === '/login' || path.startsWith('/login/')) {
    return 'account';
  }

  if (
    path === '/highlights' ||
    path.startsWith('/highlights/') ||
    path === '/fragebogen' ||
    path.startsWith('/fragebogen/')
  ) {
    return 'content';
  }

  if (
    path === '/schoenheit-und-gesundheit' ||
    path === '/fitness' ||
    path === '/landausfluege' ||
    path === '/inklusiv-landausfluege' ||
    path === '/parkservice' ||
    path === '/reisemedien' ||
    path === '/internet-an-bord' ||
    path === '/tischreservierung' ||
    path === '/pakete-und-zeitungen' ||
    (path === '/programm' && null === hash) ||
    path === '/spa-balkonkabine'
  ) {
    return 'category';
  }

  if (
    path.startsWith('/schoenheit-und-gesundheit/') ||
    path.startsWith('/fitness/') ||
    path.startsWith('/landausfluege/') ||
    path.startsWith('/inklusiv-landausfluege/') ||
    (path === '/programm' && null !== hash) ||
    path === '/pakete-und-zeitungen/pakete'
  ) {
    return 'sub-category';
  }

  if (
    path === '/manifest' ||
    path.startsWith('/manifest/') ||
    path === '/checkin' ||
    path.startsWith('/checkin/') ||
    path === '/reiseplan' ||
    path.startsWith('/reiseplan/') ||
    path === '/buchungsdetails' ||
    path.startsWith('/buchungsdetails/') ||
    path === '/weitere-informationen' ||
    path.startsWith('/weitere-informationen/')
  ) {
    return 'customer-service';
  }

  if (
    path.startsWith('/reisemedien/') ||
    path.startsWith('/internet-an-bord/') ||
    path.startsWith('/tischreservierung/') ||
    path.startsWith('/pakete-und-zeitungen/zeitungen') ||
    path.startsWith('/pakete-und-zeitungen/pakete/') ||
    path.startsWith('/spa-balkonkabine/') ||
    path.startsWith('/parkservice/')
  ) {
    return 'product';
  }

  return 'other';
}
