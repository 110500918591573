// @flow

import React from 'react';

import MultiSelect from '../form/MultiSelect';

import type PartyMember from '../../models/PartyMember';

type Props = {
  party: PartyMember[],
  selected?: number[],
  onChange: (mpi: number, value: boolean, filterMPIs: number[]) => void,
  // This can be a function that checks if the user cannot be selected
  disabledWhen?: PartyMember => boolean,
};

const TravelPartyFilter = ({
  party,
  selected,
  disabledWhen,
  onChange,
}: Props) => (
  <MultiSelect
    options={party.map(person => ({
      label: person.displayName,
      value: person.mpi,
      disabled:
        typeof disabledWhen === 'function' ? disabledWhen(person) : false,
    }))}
    value={selected}
    onChange={onChange}
  />
);

export default TravelPartyFilter;
