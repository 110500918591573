// @flow
import React from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import Price from '../Price';
import Duration from '../Duration';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import FormSelect from '../form/Select';
import MultiSelect from '../form/MultiSelect';
import VacancyBookingAssistant from './VacancyBookingAssistant';

import type SpaBookingRequest from '../../models/BookingRequest/SpaBookingRequest';
import type VacancyStore from '../../stores/VacancyStore';
import type SpaOffer from '../../models/SpaOffer';
import type SpaTreatmentStatusList from '../../models/SpaBalconyCabin/SpaTreatmentStatusList';

type TravelParty = {
  mpi: number,
  displayName: string,
};

type Props = {
  travelParty: TravelParty[],
  bookable: SpaOffer,
  bookingRequest: SpaBookingRequest,
  vacancyStore?: VacancyStore,
  onCancel?: ?Function,
  onBooking?: ?Function,
  spaTreatmentStatusList: SpaTreatmentStatusList,
  cruiseIsRunning?: boolean,
};

/**
 * This is a clone of SpaBookingAssistant.
 * SpaBalconyCabinBookingAssistant has some extra logic.
 * It was not possible to do that through inheritance.
 */
@inject('vacancyStore')
@observer
export default class SpaBalconyCabinBookingAssistant extends VacancyBookingAssistant<
  Props
> {
  get vacancyType(): string {
    return 'spa';
  }

  @autobind
  handleSelectParticipant(mpi: string) {
    this.props.bookingRequest.chooseParticipant(mpi ? parseInt(mpi, 10) : null);
  }

  @autobind
  handleChangeInParty(mpi: string, value: boolean) {
    this.props.bookingRequest.selectParticipants(parseInt(mpi, 10), value);
  }

  @autobind
  handleTimeSlotSelection(timeSlot: string) {
    this.props.bookingRequest.chooseTime(timeSlot);
  }

  @autobind
  changedCheckbox(value: boolean) {
    this.props.bookingRequest.setAcceptation(value);
  }

  @autobind
  handleBookingClick() {
    const { onBooking, bookingRequest } = this.props;

    if (onBooking) onBooking(bookingRequest);
  }

  @autobind
  handleCancelClick() {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  }

  @autobind
  handleBackClick() {
    this.props.bookingRequest.removeDay();
  }

  renderBaseInfo(backbutton?: boolean) {
    const { bookable } = this.props;
    const { price } = bookable;

    return (
      <div className="l-right l-mod-sub">
        <Price label={price > 0 ? '' : 'Inklusivleistung'} price={price} />
        <Duration duration={bookable.duration} big />
        {backbutton && (
          <div>
            <br />
            <div className="button" onClick={() => window.history.back()}>
              zurück
            </div>
          </div>
        )}
      </div>
    );
  }

  renderParticipantChooser() {
    const { bookingRequest, spaTreatmentStatusList, bookable } = this.props;

    const statusList =
      spaTreatmentStatusList && spaTreatmentStatusList.list
        ? spaTreatmentStatusList.list
        : [];

    const options = bookingRequest.travelParty.map(member => ({
      value: member.mpi,
      label: member.displayName,
      disabled:
        statusList.filter(status => {
          return (
            status.mpi === member.mpi &&
            status.statusCode === 0 &&
            member.isOldEnoughFor(bookable)
          );
        }).length === 0,
    }));

    if (bookingRequest.needsMultipleParticipants) {
      return (
        <MultiSelect
          options={options}
          value={bookingRequest.selectedMpis}
          onChange={this.handleChangeInParty}
        />
      );
    }

    return (
      <FormSelect
        name="participant"
        placeholder="Teilnehmer auswählen…"
        options={options}
        onChange={this.handleSelectParticipant}
        value={
          bookingRequest.selectedMpis.length > 0
            ? bookingRequest.selectedMpis[0]
            : null
        }
      />
    );
  }

  renderTimeSlotChooser() {
    const { vacancyStore, bookingRequest } = this.props;
    if (vacancyStore.isLoading) return <LoadingIndicator />;

    const options = bookingRequest.availableTimeSlots.map(timeSlot => ({
      value: timeSlot,
      label: timeSlot,
    }));

    return (
      <FormSelect
        name="timeslots"
        placeholder="Uhrzeit auswählen…"
        options={options}
        onChange={this.handleTimeSlotSelection}
        value={bookingRequest.selectedTime}
      />
    );
  }

  renderDayChosenView() {
    const { bookingRequest, cruiseIsRunning } = this.props;

    if (cruiseIsRunning) {
      return this.renderBaseInfo(true);
    }

    return (
      <div>
        <h2>Bitte wählen Sie Teilnehmer und Uhrzeit aus:</h2>

        <div className="l-mod-sub">
          {this.renderParticipantChooser()}
          {bookingRequest.isPersonSelected
            ? this.renderTimeSlotChooser()
            : null}
          <p>Nur reservierbar für Gäste der SPA Balkonkabine.</p>
        </div>

        {this.renderBaseInfo()}

        <div className="l-right">
          <p>
            <Button
              dark
              big
              disabled={!bookingRequest.isReady}
              onClick={this.handleBookingClick}
            >
              Zur Reservierung
            </Button>
          </p>
          <p>
            <Button onClick={this.handleBackClick}>zurück</Button>
          </p>
        </div>
      </div>
    );
  }
}
