// @flow

import fetchForUser from '../utils/fetchForUser';
import { camelCaseKeys } from '../utils/hash';
import sanitized from '../utils/apiSanitizer';
import type User from '../models/User';
import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (part: string) =>
  part === 'addons' && (mock || mockAll) && isLocal
    ? `/mock/boardAccount/${part}.json`
    : `/api/land/boardaccount/${part}`;

export const sanitizers = {
  details: (response: Object) => camelCaseKeys(response, true),
  travel: (response: Object) => camelCaseKeys(response.travelprice, true),
  addons: (response: Object) => camelCaseKeys(response, true),
};

export default sanitized(
  {
    details: (user: User): Promise<BoardAccountDetails> =>
      fetchForUser(user, getUrl('bookingdetail')),
    travel: (user: User): Promise<BoardAccountTravelprice> =>
      fetchForUser(user, getUrl('travelprice')),
    addons: (user: User): Promise<BoardAccountAddons> =>
      fetchForUser(user, getUrl('addons')),
  },
  sanitizers
);

export type CabinInfo = {
  cabin: string,
  deck: number,
  type: string,
  isJuniorSuite: boolean,
  isRegularSuite: boolean,
  isSuite: boolean,
};

export type BookingDetail = {
  mpi: number,
  name: string,
  cabinInfo: CabinInfo,
  // arrivalDepartureInfo: any,
};

export type BoardAccountDetails = {
  type: string,
  headline: string,
  travelName: string,
  travelPeriod: string,
  bookingDetails: BookingDetail[],
};

export type DiscountPrice = {
  type: string,
  label: string,
  amount: number,
  amountText: string,
};

export type TravelpriceServiceItem = {
  date: string,
  name: string,
  description: ?string, // obsolete?
  date: string,
  mpis: number[],
  amount: ?number,
  amountText: string,
  options: any[],
  type: string,
  prices: DiscountPrice[],
  tourCode: string,
  operatorCode: string,
  operatorName: string,
  operatorAddress1: string,
  operatorAddress2: string,
  operatorCity: string,
  operatorCountry: string,
  operatorPhone: string,
  operatorEmail: string,
  showAmount: boolean,
};

export type BoardAccountTravelprice = {
  amountTotal: number,
  amountTotalText: string,
  serviceList: TravelpriceServiceItem[],
};

export type BoardAccountAddonsBookings = {
  amountTotal: number,
  amountTotalText: string,
  link: null,
  balance: { mpi: number, amount: number, amount_text: string }[],
  fineprint: string[],
  serviceList: TravelpriceServiceItem[],
};

export type BoardAccountAddons = {
  type: string,
  title: string,
  link: string,
  headline: string,
  includedHeadline: string,
  includedBookings: BoardAccountAddonsBookings,
  additionalBookings: BoardAccountAddonsBookings,
};

export type ApiType = {
  details: User => Promise<BoardAccountDetails>,
  travel: User => Promise<BoardAccountTravelprice>,
  addons: User => Promise<BoardAccountAddons>,
};
