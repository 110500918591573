// @flow

import { PRICE_MODELS } from './GuestType';
import type PartyMember from './PartyMember';

const bestLabel = 'Veranstalterpreis';
const betterLabel = 'Veranstalterpreis';
const bestRefundText = '20% Vorteil von TUI Cruises';
const betterRefundText = '10% Vorteil von TUI Cruises';

const priceProText = 'Ihr Preis im PRO-Tarif';
const pricePlusText = 'Ihr Preis im PLUS-Tarif';
const pricePurText = 'Ihr Preis im PUR-Tarif';
const priceFeelGoodPlusText = 'Ihr Preis im Wohlfühlpreis';
const priceFeelGoodText = 'Ihr Preis im Wohlfühlpreis';
const priceFlexText = 'Ihr Preis im Flex-Preis';

export const PRICE_TYPES = {
  adult: 'adult',
  child: 'child',
  bulk: 'bulk',
};

export type PriceType = {
  type: string,
  original: ?number,
  refund: ?number,
  userPrice: number,
};

export default class PriceTypes {
  good: PriceType[];
  better: PriceType[];
  best: PriceType[];

  constructor(data: Object) {
    Object.assign(this, data);
  }

  getType(isChild: boolean, isBulk: ?boolean): string {
    let type = PRICE_TYPES.adult;
    type = isChild ? PRICE_TYPES.child : type;
    return isBulk ? PRICE_TYPES.bulk : type;
  }

  getPriceTypesByPriceModel(priceModel: string): PriceType[] {
    switch (priceModel) {
      case PRICE_MODELS.pro:
      case PRICE_MODELS.feelGoodPlus:
        return this.best;
      case PRICE_MODELS.plus:
        return this.better;
      default:
        return this.good;
    }
  }

  getPriceType(
    priceModel: string,
    isChild: boolean,
    isBulk: ?boolean
  ): ?PriceType {
    const priceTypes = this.getPriceTypesByPriceModel(priceModel);
    const type = this.getType(isChild, isBulk);
    const priceType = priceTypes.find(p => p.type === type);
    return priceType || null;
  }

  getPrice(priceModel: string, isChild: boolean, isBulk: boolean): number {
    const priceType = this.getPriceType(priceModel, isChild, isBulk);
    return priceType ? priceType.userPrice : 0;
  }

  getBestPrice(type: string, members: PartyMember[]): Object {
    const hasFeelGoodPlusTariff =
      members.filter(t => t.hasFeelGoodPlusTariff).length > 0;
    const hasProTariff = members.filter(t => t.hasProTariff).length > 0;

    return hasFeelGoodPlusTariff || hasProTariff
      ? {
          ...this.best.find(p => p.type === type),
          originalLabel: bestLabel,
          refundText: bestRefundText,
          priceLabels: [
            hasProTariff ? priceProText : null,
            hasFeelGoodPlusTariff ? priceFeelGoodPlusText : null,
          ].filter(l => !!l),
        }
      : null;
  }

  getBetterPrice(type: string, members: PartyMember[]): Object {
    const hasPlusTariff = members.filter(t => t.hasPlusTariff).length > 0;

    return hasPlusTariff
      ? {
          ...this.better.find(p => p.type === type),
          originalLabel: betterLabel,
          refundText: betterRefundText,
          priceLabels: [pricePlusText],
        }
      : null;
  }

  getGoodPrice(type: string, members: PartyMember[]): Object {
    const hasPurTariff = members.filter(t => t.hasPurTariff).length > 0;
    const hasFeelGoodTariff =
      members.filter(t => t.hasFeelGoodTariff).length > 0;
    const hasFlexTariff = members.filter(t => t.hasFlexTariff).length > 0;

    return hasPurTariff || hasFeelGoodTariff || hasFlexTariff
      ? {
          ...this.good.find(p => p.type === type),
          priceLabels: [
            hasPurTariff ? pricePurText : null,
            hasFeelGoodTariff ? priceFeelGoodText : null,
            hasFlexTariff ? priceFlexText : null,
          ].filter(l => !!l),
        }
      : null;
  }

  getPriceInfos(type: string, members: PartyMember[]): Object[] {
    return [
      this.getBestPrice(type, members),
      this.getBetterPrice(type, members),
      this.getGoodPrice(type, members),
    ].filter(p => !!p);
  }
}
