// @flow

import fetchForUser from '../utils/fetchForUser';
import type User from '../models/User';
import type MasterData from '../models/MasterData';
import { isLocal, mockAll } from './utils';

const mock = false;
const getListUrl = (masterData: MasterData): string => {
  const { ed, dd, shipId } = masterData;
  return (mock || mockAll) && isLocal
    ? `/mock/kosmetikstudio.json`
    : `/api/land/spa/kosmetikstudio/${ed}/${dd}/${shipId}`;
};

export default {
  getList: (user: User, masterData: MasterData, type: string) =>
    fetchForUser(user, getListUrl(masterData, type), {
      differentErrorCodes: true,
    })
      .then(response => {
        if (response.spaOffers) {
          return response;
        }
        return { error: true };
      })
      .catch(() => {
        return { error: true };
      }),
};

export type SpaOffersType = {
  category: string,
  duration: number,
  image: string,
  isStartingPrice: boolean,
  price: number,
  spaId: number,
  title: string,
};
export type ApiResponse = {
  badge: string | null,
  filter: {
    categories: [string],
  },
  hint: string | null,
  spaOffers: [SpaOffersType],
  error?: boolean,
};
