// @flow
/**
 * TUICMRL-263
 *
 * Papagena Testform:
 *  https://security.papagena-payment.de/register/test/formular-pre-reg.html
 *
 * Papagena Response Example:
 BIN: "401288"
 CC_brand: "VISA"
 CC_cardowner: "testA+testA"
 CC_nr: "0881921404347881"
 CC_valid: "202009"
 Description: "Register+fehlerhaft%3A+Routingfehler"
 Hash: ""
 Returncode: "22050392"
 Status: "Error"
 Token: ""
 TransID: "trans1601450857732"
 auth_time: "2020-09-30T09%3A28%3A10"
 */
import { obfuscateCreditCardNumber } from '../utils/payment';
import type PartyMember from '../models/PartyMember';

type PapagenaResponseDataType = {
  Status: string,
  BIN: string,
  Code: string,
  Token: string,
  PCNr: string,
  CCTyp: string,
  UD: string,
  CCMonth: string,
  CCYear: string,
};

type ResponseDataType = {
  status: string,
  message: string,
  statusCode: number,
  token: string,
  ccExpirationDate: string,
  ccCustomer: string,
  ccNumber: string,
  ccBrand: string,
  ccExpirationMonth: string,
  ccExpirationYear: string,
};
/*
{
  "type":"CreditCard",
  "status":"OK",
  "message":"Register erfolgreich",
  "statusCode":0,
  "token":"51eb8835-06e5-4be5-be52-b593d2c9f08e",
  "ccExpirationDate":"202212",
  "ccCustomer":"Svenja Rueckerl",
  "ccNumber":"0901108023181524",
  "ccBrand":"MASTERCARD",
  "customer":"Svenja Rueckerl",
  "mpis":[33424380]
}
* */

function mapStatusCodeToErrorMessage(statusCode: number): string {
  switch (statusCode) {
    case 20110015: // CCNr
    case 20110019: // CCCVC
    case 20110024: // Eingabefehler (alt)
    case 20110026: // Eingabefehler (alt)
    case 21000102: // Die angegebene Kartennummer ist ungültig
    case 21100502: // Eingabefehler (alt)
      return 'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie die Eingabe Ihrer Karten- und Prüfnummer.';
    case 20110094: // Das Ablaufdatum der Karte ist überschritten
    case 22060333: // Verfalldatum der Karte überschritten
      return 'Es ist ein Fehler aufgetreten. Bitte prüfen Sie die Gültigkeit Ihrer Kreditkarte oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 20400045: // Temporärer Fehler im Bankensystem (alt)
    case 21000130: // Temporärer Fehler im Bankensystem (alt)
    case 22050040: // Temporärer Fehler im Bankensystem (alt)
    case 22050392: // Temporärer Fehler im Bankensystem (alt)
      return 'Es ist ein Fehler aufgetreten: Temporärer Fehler im Bankensystem. Bitte versuchen Sie es erneut.';
    case 21200091: // Die Kreditkarte des Kunden ist noch nicht für Verified by Visa oder MasterCard SecureCode registriert.
      return 'Es ist ein Fehler aufgetreten. Die Kreditkarte ist noch nicht für Verified by Visa oder MasterCard SecureCode registriert. Bitte kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 21200200: // Die Authentisierung mit Verified by Visa und MasterCard SecureCode ist fehlgeschlagen
    case 22140200: // Die Authentisierung mit Verified by Visa und MasterCard SecureCode ist fehlgeschlagen
      return 'Es ist ein Fehler aufgetreten. Die Authentisierung mit Verified by Visa und MasterCard SecureCode ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Kreditkartenanbieter.';
    case 22050304: // Ablehnung von der Bank (alt)
    case 22050305: // Ablehnung von der Bank (alt)
    case 22050334: // Ablehnung von der Bank (alt)
      return 'Es ist ein Fehler aufgetreten: Ablehnung von der Bank. Bitte versuchen Sie es erneut.';
    case 22060051: // Transaktionsfehler durch eine Zeitüberschreitung
    case 22070051: // Transaktionsfehler durch eine Zeitüberschreitung
      return 'Es ist ein Transaktionsfehler durch eine Zeitüberschreitung aufgetreten. Bitte versuchen Sie es erneut.';
    case 22060200: // Die Authentisierung mit Verified by Visa und MasterCard SecureCode ist fehlgeschlagen
      return 'Es ist ein Fehler aufgetreten. Die Authentisierung mit Verified by Visa ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Kreditkartenanbieter.';
    case 22060304: // Die verwendete Karte ist nicht zulässig
      return 'Es ist ein Fehler aufgetreten. Die verwendete Karte ist nicht zulässig. Bitte kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 22060305: // Die Anfrage wurde vom Autorisierungssystem abgelehnt. Den Grund dafür kennt nur die Bank des Kunden. Die Information fällt unter Datenschutz.
    case 22070305: // Die Anfrage wurde vom Autorisierungssystem abgelehnt. Den Grund dafür kennt nur die Bank des Kunden. Die Information fällt unter Datenschutz.
      return 'Es ist ein Fehler aufgetreten. Die Anfrage wurde vom Autorisierungssystem abgelehnt. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank.';
    case 22060312: // Transaktion ungültig
    case 22070312: // Transaktion ungültig
      return 'Es ist ein Fehler aufgetreten. Die Transaktion ist ungültig. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 22060314: // Die verwendete Karte ist ungültig
      return 'Es ist ein Fehler aufgetreten. Die verwendete Karte ist ungültig.';
    case 22060343: // Karte gestohlen, bitte einziehen
      return 'Es ist ein Fehler aufgetreten. Die Karte ist als gestohlen gemeldet. Bitte kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 22064403: // Authentifizierung fehlgeschlagen
    case 22074101: // Authentifizierung fehlgeschlagen
    case 22074403: // Authentifizierung fehlgeschlagen
    case 22144201: // Authentifizierung fehlgeschlagen
      return 'Es ist ein Fehler aufgetreten. Die Authentisierung ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 22070200: // Die Authentisierung mit Verified by Visa und MasterCard SecureCode ist fehlgeschlagen
      return 'Es ist ein Fehler aufgetreten. Die Authentisierung mit MasterCard SecureCode ist fehlgeschlagen. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihren Kreditkartenanbieter.';
    case 22070391: // Der Kartenherausgeber oder das Banknetzwerk sind im Moment nicht verfügbar
      return 'Es ist ein Fehler aufgetreten. Der Kartenherausgeber oder das Banknetzwerk sind im Moment nicht verfügbar. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    case 22140051: // Transaktionsfehler durch eine Zeitüberschreitung
      return 'Es ist ein Fehler aufgetreten. Transaktionsfehler durch eine Zeitüberschreitung. Bitte versuchen Sie es erneut.';
    case 22140305: // Die Anfrage wurde vom Autorisierungssystem abgelehnt. Den Grund dafür kennt nur die Bank des Kunden. Die Information fällt unter Datenschutz.
      return 'Es ist ein Fehler aufgetreten. Die Anfrage wurde vom Autorisierungssystem abgelehnt. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
    // Generische Fehlermeldung
    default:
      return 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie Ihre Bank bzw. Ihren Kreditkartenanbieter.';
  }
}

export default class PapagenaResponse {
  data: ResponseDataType;

  constructor(response: PapagenaResponseDataType, member: PartyMember) {
    this.data = {
      status: response.Status,
      message: '', //decodeURIComponent(response.Description).replace(/\+/g, ' '),
      statusCode: parseInt(response.Code, 10),
      token: response.Token,
      ccExpirationDate: `${response.CCYear}${response.CCMonth}`,
      ccExpirationYear: response.CCYear,
      ccExpirationMonth: response.CCMonth,
      ccCustomer: member.displayName,
      ccNumber: response.PCNr,
      ccBrand: response.CCTyp,
    };
  }

  get displayCustomer(): string {
    return this.data.ccCustomer;
  }

  get displayCardNumber(): string {
    return obfuscateCreditCardNumber(this.data.ccNumber);
  }

  get displayValidUntilMonth(): string {
    return this.data.ccExpirationMonth;
  }

  get displayValidUntilYear(): string {
    return this.data.ccExpirationYear;
  }

  get isSuccess(): boolean {
    return this.data.status === 'OK';
  }

  get errorMessage(): string {
    if (this.isSuccess) return null;

    return mapStatusCodeToErrorMessage(this.data.statusCode);
  }

  get token(): string {
    return this.data.token;
  }
}
