// @flow

import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import TravelPartyFilter from './TravelPartyFilter';
import ItineraryListItem from '../ItineraryListItem';

import type MasterData from '../../models/MasterData';
import type ItineraryDayModel from '../../models/ItineraryDay';
import type Booking from '../../models/Booking';
import type BookingImageStore from '../../stores/BookingImageStore';

type Props = {
  // Array of Bookings for that day directly from the MasterData Feed
  masterData: MasterData,
  bookingImageStore: BookingImageStore | any,
  day: ItineraryDayModel,
  // If true, the days will all be disabled
  disabled?: boolean,
};

@inject('bookingImageStore')
@observer
export default class ItineraryDay extends Component<Props> {
  static defaultProps = {
    bookingImageStore: null,
  };

  @observable filterMPIs = [];

  componentDidMount() {
    this.loadImagesForBookings();
  }

  loadImagesForBookings() {
    const { masterData, day, bookingImageStore } = this.props;
    const bookings = masterData.getBookings(day.date);
    bookingImageStore.fetchImages(bookings);
  }

  @action.bound
  togglePersonFilter(mpi: number, value: boolean, filterMPIs: number[]) {
    this.filterMPIs = filterMPIs;
  }

  getItemDataFromBooking(booking: Booking) {
    const { masterData } = this.props;
    const { journeyOngoing } = masterData;
    const {
      displayTime,
      extendedDisplayType,
      meetingPoint,
      isExcursion,
      displayName,
      seatCount,
      typeId,
      highlight,
      excursionDisplayTime,
    } = booking;
    const participants = seatCount
      ? `Tisch für ${seatCount}`
      : this.getParticipantNamesOfBooking(booking).join(', ');
    const imageUrl = this.props.bookingImageStore.imageFor(typeId);
    return {
      date: `
        ${
          isExcursion
            ? excursionDisplayTime
            : displayTime
        }${isExcursion && meetingPoint ? ', Treffpunkt: ' + meetingPoint : ''}
      `,
      title: extendedDisplayType,
      description: (
        <div>
          {displayName}
          <br />
          {participants}
        </div>
      ),
      imageSrc: imageUrl,
      imageAlt: '',
      highlight: !!highlight,
    };
  }

  getBookingLink(booking: Booking) {
    return `/reiseplan/${this.props.day.dayNumber}/${booking.bookingRoute}/${
      booking.bookingRouteId
    }`;
  }

  getParticipantNamesOfBooking(booking: Booking): string[] {
    const { masterData } = this.props;
    const participants = masterData.travelParty.reduce(
      (memo, p) => ({
        ...memo,
        [p.mpi]: p.displayName,
      }),
      {}
    );

    return booking.mpis.map(mpi => participants[mpi]);
  }

  // The bookings might appear before arrival time or after depature time, to show them
  // after arrival or departure we have to split them up
  splitBookingsForDay(
    bookings: Booking[],
    itineraryDay: ItineraryDayModel
  ): Booking[][] {
    const createTime = (time: string) =>
      new Date(`1970-01-01T${time}`).getTime();
    const arrivalTime = createTime(itineraryDay.displayArrivalTime || '00:00');
    const departureTime = createTime(
      itineraryDay.displayDepartureTime || '23:59'
    );

    return [
      bookings.filter(booking => createTime(booking.startTime) < arrivalTime),
      bookings.filter(
        booking =>
          createTime(booking.startTime) >= arrivalTime &&
          createTime(booking.startTime) < departureTime
      ),
      bookings.filter(
        booking => createTime(booking.startTime) >= departureTime
      ),
      bookings.filter(
        booking => booking.prelimenary === true && booking.startTime === null
      ),
    ];
  }

  // TUICUNIT-2638: waiting list
  render() {
    const { masterData, day, disabled } = this.props;
    const bookings = masterData.getBookingsFor(this.filterMPIs)[day.date] || [];
    const itineraryBookings = day.getBookingsForItinerary(bookings);
    const [
      bookingsBefore,
      bookingsBetween,
      bookingsAfter,
      bookingsPrelimenary,
    ] = this.splitBookingsForDay(itineraryBookings, day);
    return (
      <div className="l-row">
        <div className="l-col right l-mod-sub no-print">
          <h2>Nach Personen filtern</h2>
          <TravelPartyFilter
            party={masterData.travelParty}
            onChange={this.togglePersonFilter}
          />
        </div>
        <div className="l-col double">
          <div className="l-mod l-block">
            {bookingsBefore.map((booking, index) => (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                offerItem
                disabled={disabled}
                key={index}
                link={this.getBookingLink(booking)}
                data={this.getItemDataFromBooking(booking)}
                isWaitListed={booking.isWaitListed}
              />
            ))}
            {!day.isArrival && day.displayArrivalTime ? (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                plain
                data={{
                  date: `${day.displayArrivalTime} Uhr`,
                  title: 'Ankunft',
                }}
              />
            ) : null}
            {bookings.length > 0 ? (
              bookingsBetween.map((booking, index) => (
                <ItineraryListItem
                  journeyOngoing={masterData.journeyOngoing}
                  offerItem
                  disabled={disabled}
                  key={index}
                  link={this.getBookingLink(booking)}
                  data={this.getItemDataFromBooking(booking)}
                  isWaitListed={booking.isWaitListed}
                />
              ))
            ) : (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                disabled
                small
                data={{
                  date: day.displayItineraryDate,
                  title: day.port.name,
                  imageSrc: day.image,
                  imageAlt: day.hint,
                }}
              />
            )}
            {day.displayDepartureTime ? (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                plain
                data={{
                  date: `${day.displayDepartureTime} Uhr`,
                  title: 'Abfahrt',
                }}
              />
            ) : null}
            {bookingsAfter.map((booking, index) => (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                offerItem
                disabled={disabled}
                key={index}
                link={this.getBookingLink(booking)}
                data={this.getItemDataFromBooking(booking)}
                isWaitListed={booking.isWaitListed}
              />
            ))}
            {bookingsPrelimenary.map((booking, index) => (
              <ItineraryListItem
                journeyOngoing={masterData.journeyOngoing}
                offerItem
                disabled={disabled}
                key={index}
                link={this.getBookingLink(booking)}
                data={this.getItemDataFromBooking(booking)}
                isWaitListed={booking.isWaitListed}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
