// @flow

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import track from '../../tracking';
import isInAppView from '../../utils/isInAppView';

import Headline from '../../components/Headline';
import Button from '../../components/Button';
import ItineraryListItem from '../../components/ItineraryListItem';
import TravelPartyFilter from '../../components/TravelPlan/TravelPartyFilter';
import ItineraryReportRequestForm from '../../components/TravelPlan/ItineraryReportRequestForm';
import PackagesList from '../../components/TravelPlan/PackagesList';

import type MasterStore from '../../stores/MasterStore';
import type EmailStore from '../../stores/EmailStore';

type Props = {
  masterStore: MasterStore,
  emailStore: EmailStore,
};
type State = {
  filterMPIs: number[],
};

@inject('masterStore', 'emailStore')
@observer
export default class PageTravelPlan extends Component<Props, State> {
  static breadcrumb = 'Mein Reiseplan';

  state = {
    filterMPIs: [],
  };

  componentDidMount() {
    this.props.masterStore.reloadMasterData();
    this.props.masterStore.reloadItinerary();
  }

  @autobind handlePrint() {
    track.event('Reiseplan', 'Button', 'Drucken');
    window.print();
  }

  @autobind togglePersonFilter(mpi: number, value: boolean, filterMPIs: number[]) {
    this.setState({ filterMPIs });
  }

  render() {
    const { masterStore, emailStore } = this.props;
    const { masterData, itinerary } = masterStore;
    const itineraryList = itinerary.list;
    const bookings = masterData.getBookingsFor(this.state.filterMPIs);

    return (
      <div className="page">
        <Headline title="Ihr Reiseplan im Überblick" subtitle={masterData.subtitleLine} />

        <div className="l-row group">
          <div className="l-col right l-mod-sub no-print">
            <h2>Nach Personen filtern</h2>
            <TravelPartyFilter party={masterData.travelParty.slice()} onChange={this.togglePersonFilter} />
          </div>

          <div className="l-col double">
            <div className="l-mod l-block">
              {itineraryList.map((day, index) => {
                return (
                  <ItineraryListItem
                    journeyOngoing={masterData.journeyOngoing}
                    showEmpty
                    day={day}
                    services={day.getBookingsForItinerary(bookings[day.date])}
                    data={{
                      date: day.displayItineraryDate,
                      title: day.port.name,
                      imageSrc: day.image,
                      imageAlt: day.hint,
                    }}
                    link={`/reiseplan/${index}`}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {masterData.packages.length ? (
          <div className="l-row l-mod-sub">
            <div className="l-col double">
              <PackagesList packages={masterData.packages} />
            </div>
          </div>
        ) : null}

        <div className="l-row no-print l-mod-sub">
          <div className="l-col double">
            <ItineraryReportRequestForm email={masterData.email} emailStore={emailStore} />
          </div>
        </div>

        {!isInAppView && (
          <div className="l-mod l-right">
            <Button big dark onClick={this.handlePrint}>
              Drucken
            </Button>
          </div>
        )}
      </div>
    );
  }
}
