// @flow

import React, { Component, type Node } from 'react';
import { inject, observer } from 'mobx-react';
import { routerShape } from 'react-router/lib/PropTypes';
import LoadingIndicator from './components/LoadingIndicator';

import logger from './utils/logger';
import onVisibilityChange from './utils/visibility';
import track from './tracking';

import type MasterStore from './stores/MasterStore';
import type AuthStore from './stores/AuthenticationStore';

type Props = {
  children: Node,
  masterStore: MasterStore | any,
  authStore: AuthStore | any,
};

@inject('masterStore', 'authStore')
@observer
export default class App extends Component<Props> {
  static defaultProps = {
    masterStore: null,
  };

  static contextTypes = {
    router: routerShape,
  };

  changeListener: ?{ stop: () => void };

  constructor(props: Props) {
    super(props);
    const { masterStore } = this.props;

    masterStore
      .fetch()
      .then(() => {
        // track.login(masterStore.user, masterStore.masterData);
        track.setDefaultParameter(masterStore);

        masterStore.startAutoReload();
        this.changeListener = onVisibilityChange(
          () => {
            masterStore.stopAutoReload();
          },
          () => {
            masterStore.reloadMasterData();
            masterStore.startAutoReload();
          }
        );
      })
      .catch(err => {
        // login obviously failed
        if (masterStore.loginError) {
          this.context.router.replace('/404');
          logger.warn(
            'User could not login, due to failed fetch routine of masterData',
            masterStore.loginError
          );
        } else if (masterStore.masterDataError) {
          this.props.authStore.logout().then(() => {
            // We make a hard reset here, to be sure, we don't have any states saved in some stores
            // or anywhere else
            window.location.href = '/login';
          });
        } else {
          logger.error('Something really unexpected happened.', err);
        }
      });
  }

  componentWillUnmount() {
    const { masterStore } = this.props;
    this.changeListener && this.changeListener.stop();
    masterStore.stopAutoReload();
  }

  render() {
    const { masterStore } = this.props;

    return masterStore.loading ? <LoadingIndicator /> : this.props.children;
  }
}
