// @flow
import * as React from 'react';

import Tile from '../../../components/tiles/Tile';
import PdfTile from '../../../components/tiles/PdfTile';

import type BoardingPass from '../../../models/BoardingPass';
import type PartyMember from '../../../models/PartyMember';

type Props = {
  boardingPass: ?BoardingPass,
  user: Object,
  partyMember: PartyMember,
  travelParty: PartyMember[],
  onClick: (partyMember: PartyMember, url: string, noPinNeeded: boolean) => void,
  disabled: boolean,
};

const CheckedInMember = ({ boardingPass, user, partyMember, travelParty, onClick, disabled }: Props) => {
  return (
    <div className="l-tiles">
      <PdfTile
        title="Schiffspass drucken"
        link={!disabled && boardingPass ? boardingPass.pdfLinkPrint : ''}
        print
        icon="print fixed"
        user={user}
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'pdfLinkPrint', true);
              }
            : null
        }
      />
      <PdfTile
        title="Schiffspass als PDF speichern"
        noPadding
        link={!disabled && boardingPass ? boardingPass.pdfLink : ''}
        icon="pdf fixed"
        user={user}
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'pdfLink', true);
              }
            : null
        }
      />
      <Tile
        title="In Apple Wallet (Smart&shy;phone) öffnen"
        link={!disabled && boardingPass ? boardingPass.walletLink : ''}
        linkIsExternal
        icon="mobile-download fixed"
        disabled={disabled}
        onClick={
          !disabled
            ? (e) => {
                e && e.preventDefault();
                onClick && onClick(partyMember, 'walletLink', true);
              }
            : null
        }
      />
      <div style={{ display: 'none' }}>
        <Tile
          title="In Google Wallet (Smart&shy;phone) öffnen"
          link={!disabled && boardingPass ? boardingPass.walletLink : ''}
          linkIsExternal
          icon="mobile-download fixed"
          disabled={disabled}
          onClick={
            !disabled
              ? (e) => {
                  e && e.preventDefault();
                  onClick && onClick(partyMember, 'walletLinkGoogle', true);
                }
              : null
          }
        />
      </div>
    </div>
  );
};

export default CheckedInMember;
