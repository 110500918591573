// TODO: add flow types

import { observable, action, computed } from 'mobx';

import VacancyTimeSlots from '../models/VacancyTimeSlots';

export default class VacancyStore {
  @observable vacancies = null;
  @observable users = {};

  masterStore = null;
  api = null;
  vacancy = null;
  requests = {};

  constructor(api, masterStore, vacancy) {
    this.api = api;
    this.masterStore = masterStore;
    this.vacancy = vacancy;
  }

  @computed
  get isLoading() {
    return this.vacancies === null;
  }

  @action
  fetch(vacancy, vacancyType) {
    const key = `${vacancyType}_${vacancy.vacancyId}`;
    if (!this.requests[key]) {
      this.requests[key] = this.api
        .getList(
          this.masterStore.user,
          this.masterStore.masterData,
          vacancy,
          vacancyType
        )
        .then(
          action(list => {
            this.vacancies = new VacancyTimeSlots(list);
            this.requests[key] = null;
            return this.vacancies;
          }),
          action(() => {
            this.vacancies = new VacancyTimeSlots();
            this.requests[key] = null;
          })
        );
    }
    return this.requests[key];
  }
}
