// @flow

import React, { Component } from 'react';

import RadioGroup from './RadioGroup';
import SelectGroup from './SelectGroup';
import type Person from '../../../models/ArrivalDeparture/Person';

type Props = {
  persons: Person[],
  onChange: number => void,
  value: ?number | null,
};

export default class TravelPartyFilter extends Component<Props> {
  render() {
    const { persons, onChange, value } = this.props;

    return (
      <div>
        <RadioGroup value={value} persons={persons} onChange={onChange} />
        <SelectGroup value={value} persons={persons} onChange={onChange} />
      </div>
    );
  }
}
