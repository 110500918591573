// @flow

import MasterData from './MasterData';

export default class User {
  id: number;
  firstName: string;
  lastName: string;
  apiToken: string;
  age: number;
  backLink: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  enhanceWithMasterData(masterData: MasterData) {
    const user = masterData.requestor;
    if (user) {
      this.id = user.mpi;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.age = user.age;
    }
  }
}
