// @flow

import React, { Component, type Node } from 'react';

// Read more about rc-collapse here: https://github.com/react-component/collapse

type Props = {
  children: Node,
  className?: ?string,
};

class Accordion extends Component<Props> {
  render() {
    const { children, className } = this.props;
    return <div className={`accordion ${className || ''}`}>{children}</div>;
  }
}

export default Accordion;
