// @flow

import React, { type Node } from 'react';
import classNames from 'classnames';

type Props = {
  children: Node,
  // Unavailable badges have a slightly different color
  unavailable?: boolean,
  // Booked badges also have a  different color
  booked?: boolean,
  // By default the badge is pushed a bit outside the box, this contains it within
  inset?: boolean,
  right?: boolean,
};

const Badge = ({ children, booked, unavailable, inset, right }: Props) => (
  <div
    className={classNames({
      badge: true,
      booked,
      unavailable,
      inset,
      right,
    })}
  >
    {children}
  </div>
);

export default Badge;
