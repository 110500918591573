// @flow
import { computed } from 'mobx';

import Bookable from './Bookable';

type NewspaperListParameterType = {
  price: number,
  headline: string,
  status: string,
  statusCode: number,
};

class Newspaper extends Bookable {
  get analyticsCategory() {
    return 'Zeitungen';
  }
}

export default class NewspaperList {
  packages: Newspaper[];
  price: number;
  title: string;
  status: string;
  statusCode: number;

  startDateTime: void;
  endDateTime: void;
  vacancyId: any;

  // flow
  isPriceBulk: any;
  date: any;
  day: any;
  tourSalesTermsId: any;
  requiresTourSalesTermsNotice: any;
  isBooked: any;
  booking: any;
  name: any;
  bookingId: any;
  priceAdult: any;
  priceChild: any;

  constructor(
    items: Object[],
    { price, headline, status, statusCode }: NewspaperListParameterType
  ) {
    this.packages = items ? items.map(item => new Newspaper(item)) : [];
    this.price = price;
    this.status = status;
    this.statusCode = statusCode;
    // For confirmation page
    this.title = headline;
  }

  get analyticsCategory(): string {
    return 'Zeitungen';
  }

  @computed
  get isAnythingBookable(): boolean {
    return !!this.packages.find(item => item.vacancies);
  }

  get isBookable(): boolean {
    return this.statusCode === 0;
  }

  get bookingStatus(): string {
    return this.status;
  }

  getPriceTypes(): any {}

  get bookedMpis(): number[] {
    return [];
  }
}
