//@flow
/* eslint-disable no-console */
export default {
  //TODO check if there is a construct without "any"
  debug: function debug(...args: any[]): void {
    console.debug.apply(console, args);
  },
  // eslint-disable-next-line no-shadow
  log: function log(...args: any[]): void {
    console.log.apply(console, args);
  },
  warn: function warn(...args: any[]): void {
    console.warn.apply(console, args);
  },
  error: function error(...args: any[]): void {
    console.error.apply(console, args);
  },
  info: function info(...args: any[]): void {
    console.info.apply(console, args);
  },
};
