// @flow
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import classNames from 'classnames';

import Headline from '../Headline';
import Button from '../Button';
import BookingTable from './BookingTable';
import LoadingIndicator from '../LoadingIndicator';
import BookingConfirmationSuccess from './Success';

import type ItineraryDay from '../../models/ItineraryDay';
import type MasterStore from '../../stores/MasterStore';
import type { BookingRequestType } from '../../types/booking';
import track from '../../tracking';
import PropTypes from 'prop-types';

type Props = {
  day?: ItineraryDay,
  bookingRequest: BookingRequestType,
  onConfirm: () => any,
  onCloseConfirmation: () => any,
  masterStore: MasterStore,
  breadcrumbRouterStore: PropTypes.object.isRequired,
};

@inject('masterStore', 'breadcrumbRouterStore')
@observer
export default class BookingConfirmation extends Component<Props> {
  get title(): string {
    return this.props.bookingRequest.getText('confirmation', 'title');
  }

  get text(): string {
    return this.props.bookingRequest.getText('confirmation', 'text');
  }

  get showChildInfo(): boolean {
    const { bookingRequest } = this.props;
    if (
      !bookingRequest.bookable ||
      bookingRequest.bookable.type !== 'excursionDetail'
    ) {
      return false;
    }
    return bookingRequest.travelParty.some(
      m => bookingRequest.selectedMpis.includes(m.mpi) && !m.isFullAged
    );
  }

  @autobind
  handleCloseConfirmationClick() {
    const { bookingRequest, onCloseConfirmation } = this.props;

    if (onCloseConfirmation) onCloseConfirmation();
    bookingRequest.cleanErrors();
    // patch to fix display of cancellation-page when reservation-page should
    // be displayed due to not resetting bookableToCancel on BookingRequest
    // after cancelling the cancellation-page of a partizipant
    // TODO this patch & API needs to be checked for all BookingRequest
    bookingRequest.reset();
  }

  get successButtonText(): string {
    const { bookingRequest } = this.props;
    return bookingRequest.isCancellation || bookingRequest.isChangeRequest
      ? 'Bestätigen'
      : 'Jetzt reservieren';
  }

  renderWithTable() {
    const { bookingRequest, masterStore } = this.props;
    const { hasErrors, errors } = bookingRequest;

    const conflicts = bookingRequest.getBookingConflicts(
      masterStore.masterData.getBookings(bookingRequest.date)
    );

    return (
      <div>
        {hasErrors ? (
          <p className="booking-confirmation__error">
            {typeof errors === 'string' ? (
              <span>
                Folgende Anfragen sind fehlgeschlagen:<br />
                {errors}
              </span>
            ) : (
              `Folgende Anfragen sind fehlgeschlagen:`
            )}
          </p>
        ) : (
          <p>{this.text}</p>
        )}

        <div className="l-block">
          <BookingTable
            bookingRequest={bookingRequest}
            conflicts={conflicts}
            showChildInfo={this.showChildInfo}
          />
        </div>
      </div>
    );
  }

  renderWithoutTable() {
    const { bookingRequest } = this.props;
    const errors = bookingRequest.errors;

    return (
      <div>
        <p>{this.text}</p>
        {errors ? (
          <p className="booking-confirmation__error">
            {typeof errors === 'string' ? (
              <span>
                Folgende Anfragen sind fehlgeschlagen:<br />
                {errors}
              </span>
            ) : (
              Object.values(errors)
            )}
          </p>
        ) : null}
      </div>
    );
  }

  get subTitle() {
    const { day, bookingRequest } = this.props;
    const subtitleDay =
      day ||
      (bookingRequest.bookingToCancel && bookingRequest.bookingToCancel.day) ||
      (bookingRequest.selectedDay ? bookingRequest.selectedDay : null);
    return subtitleDay ? subtitleDay.subtitleLine : null;
  }

  componentDidMount() {
    // Page View Tracking
    const titles = this.props.breadcrumbRouterStore.getWithoutFirst;
    if (titles.length) {
      track.pageView(
        window.location.pathname,
        [
          ...titles,
          'reservierungsanfrage',
        ],
        'checkout '
      );
    } else {
      const { day, bookingRequest } = this.props;
      if (day) {
        track.pageView(
          window.location.pathname,
          [
            'Programm',
            day?.port?.name,
            bookingRequest?.bookable?.title,
            'reservierungsanfrage',
          ],
          'checkout '
        );
      }
    }
  }

  render() {
    const { day, bookingRequest, onConfirm } = this.props;
    let { bookable } = bookingRequest;

    if (bookingRequest.requestSuccessful) {
      return (
        <BookingConfirmationSuccess bookingRequest={bookingRequest} day={day} />
      );
    }

    // TUICUNIT-560 temporary storno hack
    if (bookingRequest.isCancellation && bookingRequest.bookingToCancel) {
      bookable = bookingRequest.bookingToCancel;
      bookable.title = bookable.displayName;
    }

    return (
      <div className="booking-confirmation">
        <Headline title={this.title} subtitle={this.subTitle} />

        {bookable.title && (
          <h2 dangerouslySetInnerHTML={{ __html: bookable.title }} />
        )}

        {bookingRequest.showBookingTable
          ? this.renderWithTable()
          : this.renderWithoutTable()}

        {bookingRequest.isRequesting ? (
          <LoadingIndicator />
        ) : (
          <div
            className={classNames({
              'booking-confirmation__buttons': true,
              'booking-confirmation__warning': this.showChildInfo,
            })}
          >
            <p>
              <Button dark big onClick={onConfirm}>
                {this.successButtonText}
              </Button>
            </p>
            <p>
              <Button onClick={this.handleCloseConfirmationClick}>
                zurück
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }
}
