// @flow

import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  ${p => css`
    min-width: ${p.size || 50}px;
    width: ${p.size || 50}px;
    height: ${p.size || 50}px;
  `} display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  icon: any;
  size?: number;
}

export default function IconWrapper({ icon, size }: Props) {
  return <Wrapper size={size}>{icon}</Wrapper>;
}
