// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';
import classNames from 'classnames';

const BlockListItem = ({ children, readOnly, link, onClick }) => (
  <li
    className={classNames({
      'block-list__item': true,
      'read-only': readOnly,
    })}
  >
    {link && !readOnly ? (
      <Link to={link} className="block-list__item__content">
        {children}
      </Link>
    ) : (
      <span
        className={classNames({
          'block-list__item__content': true,
          'has-click': !!onClick && !readOnly,
        })}
        onClick={readOnly ? null : onClick}
      >
        {children}
      </span>
    )}
  </li>
);

BlockListItem.propTypes = {
  // Content of List.Item should only include component that start with 'List.'
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  // If this is a link, it will be shown as a link
  link: PropTypes.string,
};

export default BlockListItem;
