// @flow
import { computed } from 'mobx';

import Bookable from './Bookable';

import type ItineraryDay from './ItineraryDay';
import type { IVacancy } from '../types/vacancies';

export default class RestaurantOffer extends Bookable {
  venueImage: string;
  image: ?string;
  venueId: string;
  title: string;

  @computed
  get isBooked(): boolean {
    return !!this.masterStore.packagesOfUser.find((pkg) => pkg.typeId === this.typeId);
  }

  get imageSrc(): string {
    return this.image || this.venueImage;
  }

  vacancyOfDay(itineraryDay: ItineraryDay): ?IVacancy {
    return this.vacancies.find((d) => d.date === itineraryDay.date);
  }

  isBookableOnDay(itineraryDay: ItineraryDay) {
    const vacancy = this.vacancyOfDay(itineraryDay);

    if (!vacancy) return false;

    return vacancy.vacancies > 0;
  }

  getBookableStatusForDay(itineraryDay: ?ItineraryDay): string {
    if (!this.isBookable) return this.bookingStatus;
    if (!this.isBookableOnDay(itineraryDay)) return 'Derzeit nicht reservierbar';
    return '';
  }

  get analyticsCategory(): string {
    return 'Tischreservierung';
  }
}
