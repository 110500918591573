// @flow

import React from 'react';
import classNames from 'classnames';
import Shiitake from 'shiitake'; // https://github.com/bsidelinger912/shiitake

type Props = {
  children: string,
  // Is it a full height tile?
  full?: ?boolean,
  // Does the Tile have an icon?
  hasIcon?: ?boolean,
  small?: ?boolean,
  lineClamp?: ?number,
};

const TileText = ({ children, full, hasIcon, small, lineClamp }: Props) => (
  <div
    className={classNames({
      tile__text: true,
      full,
      small,
    })}
  >
    {!lineClamp && (
      <div
        className={classNames({
          tile__clamp: true,
          'has-icon': hasIcon,
          full,
        })}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    )}

    {lineClamp && (
      <Shiitake
        className={classNames({
          tile__clamp: true,
          'has-icon': hasIcon,
          full,
        })}
        lines={lineClamp}
      >
        {children}
      </Shiitake>
    )}
  </div>
);

export default TileText;
