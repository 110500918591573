// @flow

import { isLocal, mockAll } from './utils';

const mock = false;
const getUrl = (part: string): string =>
  (mock || mockAll) && isLocal
    ? `/mock/content/${part.replace(new RegExp('/', 'g'), '-')}.json`
    : `/api/land/content/${part}`;

const api = {
  internet: (): Promise<*> =>
    fetch(getUrl('global/internet')).then(response => response.json()),
  healthAndBeauty: (): Promise<*> =>
    fetch(getUrl('global/beauty/beauty')).then(response => response.json()),
  healthAndBeautyTeasers: (): Promise<*> =>
    fetch(getUrl('global/beauty-and-health-teaser')).then(response =>
      response.json()
    ),
};

export default api;
export type StaticContentApi = typeof api;
export type StaticContentTypes = $Keys<StaticContentApi>;
