// @flow

import React, { Node } from 'react';
import { prepareText, allowedTag } from '../utils/prepareText';

type Props = {
  className?: string,
  text: string,
  icon: Node,
  allowedTags?: Array<allowedTag>,
};

const InfoBoxSvg = ({ text, className, icon, allowedTags }: Props) => {
  allowedTags = Array.isArray(allowedTags) ? allowedTags : [];
  return (
    <div className={`infobox svg ${className || ''}`}>
      <div className="icon-wrapper">{icon}</div>
      <strong
        dangerouslySetInnerHTML={{
          __html: prepareText(text, allowedTags || []),
        }}
      />
    </div>
  );
};

export default InfoBoxSvg;
