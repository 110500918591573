// @flow
import React from 'react';
import { inject, observer } from 'mobx-react';
import { autobind } from 'core-decorators';

import ParticipantList from './ParticipantList';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import FormSelect from '../form/Select';
import FormTextarea from '../form/Textarea';
import VacancyBookingAssistant from './VacancyBookingAssistant';

import type RestaurantBookingRequest from '../../models/BookingRequest/RestaurantBookingRequest';
import type VacancyStore from '../../stores/VacancyStore';
import type RestaurantOffer from '../../models/SpaOffer';

type TravelParty = {
  mpi: number,
  displayName: string,
};

type Props = {
  bookedMpis: number[],
  travelParty: TravelParty[],
  onChangeBooking: ?Function,
  bookingRequest: RestaurantBookingRequest,
  vacancyStore: VacancyStore,
  bookable: RestaurantOffer,
  onCancel: ?Function,
  onBooking: ?Function,
};

@inject('vacancyStore')
@observer
export default class RestaurantBookingAssistant extends VacancyBookingAssistant<
  Props
> {
  get vacancyType(): string {
    return 'restaurant';
  }

  @autobind
  handleChangeInParticiantCount(count: string) {
    this.props.bookingRequest.chooseParticipantCount(parseInt(count, 10));
  }

  @autobind
  handleTimeSlotSelection(timeSlot: string) {
    this.props.bookingRequest.chooseTime(timeSlot);
  }

  @autobind
  handleChangedComment(comment: string) {
    this.props.bookingRequest.setComment(comment);
  }

  @autobind
  handleBookingClick() {
    const { onBooking, bookingRequest } = this.props;

    if (onBooking) onBooking(bookingRequest);
  }

  @autobind
  handleCancelClick() {
    const { onCancel } = this.props;
    if (onCancel) onCancel();
  }

  @autobind
  handleBackClick() {
    this.props.bookingRequest.removeDay();
  }

  renderBookedView() {
    const { travelParty, bookable } = this.props;

    return (
      <div className="l-right">
        <ParticipantList travelParty={travelParty} bookable={bookable} />
        {bookable.isBookable ? (
          <Button
            className="l-mod-sub"
            dark
            big
            onClick={this.openParticipants}
            disabled={bookable.booking.prelimenary}
          >
            Reservierung ändern
          </Button>
        ) : (
          <Button className="l-mod-sub" disabled dark big>
            {bookable.bookingStatus}
          </Button>
        )}
        <Button
          onClick={this.handleCancelClick}
          disabled={bookable.booking.prelimenary}
        >
          Reservierung stornieren
        </Button>
      </div>
    );
  }

  renderParticipantChooser() {
    const { vacancyStore, bookingRequest } = this.props;
    if (vacancyStore.isLoading) return <LoadingIndicator />;

    return (
      <FormSelect
        name="participant"
        placeholder="Personenzahl auswählen…"
        options={vacancyStore.vacancies.personCountOptions}
        onChange={this.handleChangeInParticiantCount}
        value={String(bookingRequest.selectedCount)}
      />
    );
  }

  renderTimeSlotChooser() {
    const { vacancyStore, bookingRequest } = this.props;
    if (vacancyStore.isLoading) return null;

    const options = vacancyStore.vacancies
      .timeSlotsFor(bookingRequest.selectedCount)
      .map(timeSlot => ({
        value: timeSlot,
        label: timeSlot,
      }));

    return (
      <div>
        <FormSelect
          name="timeslots"
          placeholder="Uhrzeit auswählen…"
          options={options}
          onChange={this.handleTimeSlotSelection}
          value={bookingRequest.selectedTime}
        />
        <FormTextarea
          className="l-mod-sub"
          name="comment"
          label="Zusatzbemerkung (max. 100 Zeichen):"
          maxLength={100}
          showRemaining
          onChange={this.handleChangedComment}
          value={bookingRequest.comment}
        />
        <p className="annotation">
          Wenn Sie mit anderen Reisenden zusammensitzen möchten, geben Sie hier
          bitte die entsprechenden Namen/Kabinen/Vorgangsnummer an.
        </p>
      </div>
    );
  }

  renderDayChosenView() {
    const { bookingRequest } = this.props;

    return (
      <div>
        <h2>Bitte wählen Sie Teilnehmer und Uhrzeit aus:</h2>

        <div className="l-mod-sub">
          {this.renderParticipantChooser()}
          {bookingRequest.selectedCount ? this.renderTimeSlotChooser() : null}
        </div>

        <div className="l-right">
          <p>
            <Button
              dark
              big
              disabled={!bookingRequest.isReady}
              onClick={this.handleBookingClick}
            >
              Zur Reservierung
            </Button>
          </p>
          <p>
            <Button onClick={this.handleBackClick}>zurück</Button>
          </p>
        </div>
      </div>
    );
  }

  renderBaseInfo() {
    return null;
  }
}
