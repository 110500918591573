// @flow

import { observable, action, computed } from 'mobx';
import Cookie from 'js-cookie';

import authApi, {
  type ApiType,
  type ApiResponse,
  type IdentityType,
  type JsonInternalServerError,
} from '../api/authentication';

import User from '../models/User';
import Identity from '../models/Identity';
import storage from '../utils/storage';
import { clear } from '../utils/sessionStorage';
import { parseJWT } from '../utils/token';
import config from '../config';
import { getUrlParameterByName } from '../utils/url';

export default class AuthenticationStore {
  @observable user: ?User = null;
  @observable identity: ?Identity = null;
  @observable loading: boolean = false;

  api: ApiType;

  constructor(api: ApiType) {
    this.api = api;
    this.initiallyLoadUser();
  }

  @action
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  /**
   * @see SUPTUICMRS-2227 & TUICMRL-827
   *
   * - If stayLoggedIn is true, the user remains logged in, regardless of whether he closes the browser or not
   * - If stayLoggedIn is false, but the browser stays open, the session will expire after 18 hours
   * - If stayLoggedIn is false and the browser is closed, the user must log in again the next time he opens it
   * */
  @action
  initiallyLoadUser() {
    let user;

    try {
      user = JSON.parse(Cookie.get(config.cookieName));
    } catch (exc) {
      user = null;
    }

    if (user && !Cookie.get(config.sessionCookieName)) {
      // browser was closed. A valid user was found
      if (!user.stayLoggedIn) {
        // stayLoggedIn-checkbox was not set
        Cookie.remove(config.cookieName, { domain: config.cookieDomain });
      } else {
        // stayLoggedIn-checkbox was set
        this.user = new User(user); // recreate user
        Cookie.set(config.sessionCookieName, true); // recreate session cookie
      }
    } else if (user) {
      // browser was reloaded. We have a user and a session cookie
      this.user = new User(user); // recreate user
    } else {
      // session timeout / No valid user found.
      Cookie.remove(config.sessionCookieName); // remove session cookie
    }
  }

  @computed
  get isAuthenticated(): boolean {
    return this.user !== null;
  }

  fetchAuthenticatedUser(): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this.user) {
        resolve(this.user);
      } else {
        reject();
      }
    });
  }

  handleLoginResponse(stayLoggedIn: boolean, response: ApiResponse): ?User {
    const apiToken = response.token;
    const jwt = apiToken ? parseJWT(apiToken) : null;
    if (jwt) {
      const { cookieName, cookieDomain, sessionCookieName } = config;
      const user = { apiToken, stayLoggedIn };
      let backLink = getUrlParameterByName('backLink');
      if (backLink) user.backLink = backLink;

      let expires = jwt.exp ? new Date(jwt.exp * 1000) : null;
      expires = stayLoggedIn ? null : expires;

      // save into cookie to enable the kundenkonto to logout the user
      Cookie.set(cookieName, JSON.stringify(user), {
        domain: cookieDomain,
        expires,
      });

      // if the third argument ( { expires } ) is omitted, the cookie becomes a session cookie.
      Cookie.set(sessionCookieName, true); // required to determine if the browser was closed

      storage.remove('login-locked');

      // TUICUNIT-1994: forceCheckin : first view (flag)
      storage.set('force-checkin', true);

      // TUICUNIT-2138: forceDigitalHealth : first view (flag)
      storage.set('force-digitalHealth', true);
      return new User(user);
    }
  }

  getRejectData(response: ApiResponse) {
    return {
      detail: response.detail,
      lockoutStillSeconds: response.lockout_still_seconds,
      lockoutType: response.lockout_type,
      lockoutUntilTimestamp: response.lockout_until_timestamp,
      method: response.method,
      status: response.status,
      title: response.title,
      type: response.type,
      uri: response.uri,
    };
  }

  login(
    firstName: string,
    lastName: string,
    processNumber: number,
    stayLoggedIn: ?boolean,
    useNewLogin: ?boolean
  ): Promise<User> {
    return new Promise((resolve, reject) => {
      this.api
        .login({
          firstname: firstName,
          lastname: lastName,
          processNumber: useNewLogin ? processNumber : parseInt(processNumber, 10),
          stayLoggedIn: stayLoggedIn,
          useNewLogin: useNewLogin,
        })
        .then(
          action((response: ApiResponse) => {
            const user = this.handleLoginResponse(stayLoggedIn, response);
            if (user) {
              this.user = user;
              resolve(this.user);
            } else {
              reject(this.getRejectData(response));
            }
          })
        );
    });
  }

  token(tripCode: string): Promise<User> {
    return new Promise((resolve, reject) => {
      this.api
        .token({
          tripCode,
        })
        .then(
          action((response: ApiResponse) => {
            const user = this.handleLoginResponse(true, response);
            if (user) {
              this.user = user;
              resolve(this.user);
            } else {
              reject(this.getRejectData(response));
            }
          })
        );
    });
  }

  tokenLegacy(firstName: string, lastName: string, processNumber: number, stayLoggedIn: ?boolean): Promise<User> {
    return new Promise((resolve, reject) => {
      this.api
        .tokenLegacy({
          firstname: firstName,
          lastname: lastName,
          processNumber: parseInt(processNumber, 10),
          stayLoggedIn: stayLoggedIn,
        })
        .then(
          action((response: ApiResponse) => {
            const user = this.handleLoginResponse(stayLoggedIn, response);
            if (user) {
              this.user = user;
              resolve(this.user);
            } else {
              reject(this.getRejectData(response));
            }
          })
        );
    });
  }

  logout(): Promise<void> {
    return new Promise((resolve) => {
      Cookie.remove(config.cookieName, { domain: config.cookieDomain });
      Cookie.remove(config.sessionCookieName);
      clear(); // clear sessionStorage
      // TUICUNIT-1994: forceCheckin clear flag
      storage.remove('force-checkin');

      // TUICUNIT-2138: forceDigitalHealth : clear flag)
      storage.remove('force-digitalHealth');
      storage.remove('tracking');
      storage.remove('language');
      resolve();
    });
  }

  getIdentity(): Promise<Identity> {
    this.setLoading(true);
    return new Promise((resolve, reject) => {
      this.api
        .fetchIdentity()
        .then(
          action((response: IdentityType | JsonInternalServerError) => {
            this.setLoading(false);
            if (response && !response.error) {
              this.identity = new Identity(response);
              resolve(this.identity);
            } else {
              reject(response);
            }
          })
        )
        .catch(
          action((response: IdentityType | JsonInternalServerError) => {
            this.setLoading(false);
            reject(response);
          })
        );
    });
  }
}

export const authStore = new AuthenticationStore(authApi);
