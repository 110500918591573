// @flow
import User from '../models/User';
import pinStorage from '../utils/pinStorage';

class ResponseError {
  status: number;
  response: { error: string };

  constructor(status, json) {
    this.status = status;
    this.response = json;
  }
}

/**
 * Fetches the request using isomorphic fetch and uses the given user to
 * set authorization headers
 * @param {User} user Loggedin User to use in authorization
 * @param {string} url URL to call
 * @param {object} options Passed directly to `fetch`
 * @throws ResponseError for Requests with status code >= 400
 */
export default (user: User, url: string, options: Object = {}): Promise<*> =>
  fetch(url, {
    ...options,

    headers: {
      ...options.headers,
      Authorization: `Bearer ${user.apiToken}`,
      'Content-Type': 'application/json',
      cache: 'no-cache',
    },
  })
    .then(response => {
      if (response.status >= 400) {
        return response.json().then(
          json => {
            throw new ResponseError(response.status, json);
          },
          () => {
            // It's not a json response, e.g. a 500er directly from server
            throw new ResponseError(response.status, {
              error: response.statusText,
            });
          }
        );
      }
      return response.json();
    })
    .catch(response => {
      if (options.pinRequired && response.status === 403) {
        try {
          pinStorage.removePin(options.mpi);
          pinStorage.setUrl(options.mpi, window.location.pathname);
          pinStorage.setApiPinError({
            mpi: options.mpi,
            url: response.response.uri,
            pinCheckErrorCode: response.response.pin_check_error_code,
            pinCheckErrorMessage: response.response.pin_check_error_message,
            status: response.status,
          });
        } catch (exc) {
          //console.log(exc);
        }
        return;
      }
      if (options.getErrorCode && response.status === options.getErrorCode) {
        return response;
      }
      if (options && options.differentErrorCodes === true) {
        return response;
      }
    });
