// @flow

import React from 'react';
import classNames from 'classnames';

type Props = {
  // className of icon to show here
  type?: string,
  // Is it a full height tile?
  full?: ?boolean,
};

const TileIcon = ({ type = '', full }: Props) => (
  <div
    className={classNames({
      tile__icon: true,
      full,
    })}
  >
    <i className={`icon ${type}`} />
  </div>
);

export default TileIcon;
