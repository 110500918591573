// @flow
import {
  PACKAGE_SUB_TYPE_MEDIA,
  PACKAGE_SUB_TYPE_INTERNET,
  PACKAGE_SUB_TYPE_NEWSPAPER,
  PACKAGE_SUB_TYPE_PARKING,
  PACKAGE_SUB_TYPE_SPA_INCLUSIVE,
} from '../config/constants';

export const DISPLAY_SUB_TYPE_MAPPING = {
  [PACKAGE_SUB_TYPE_INTERNET]: 'Internet:',
  [PACKAGE_SUB_TYPE_NEWSPAPER]: 'Zeitungspaket',
};

export const LINK_MAPPING = {
  [PACKAGE_SUB_TYPE_MEDIA]: (pkg: BookedPackage) =>
    `/reisemedien/${pkg.contentId}`,
  [PACKAGE_SUB_TYPE_INTERNET]: (pkg: BookedPackage) =>
    `/internet-an-bord/${pkg.contentId}`,
  [PACKAGE_SUB_TYPE_NEWSPAPER]: () => '/pakete-und-zeitungen/zeitungen',
  [PACKAGE_SUB_TYPE_PARKING]: (pkg: BookedPackage) =>
    `/parkservice/${pkg.contentId}`,
  [PACKAGE_SUB_TYPE_SPA_INCLUSIVE]: (pkg: BookedPackage) =>
    `/spa-balkonkabine/${pkg.contentId}`,
  default: (pkg: BookedPackage) =>
    `/pakete-und-zeitungen/pakete/${pkg.contentId}`,
};

export default class BookedPackage {
  typeId: string;
  contentId: string;
  subType: ?string;
  displayName: string;
  displayTextPrefix: ?string;
  prelimenary: boolean;
  excludesFirstDay: ?boolean;
  excludesLastDay: ?boolean;
  itemCount: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get displayText(): string {
    const prefix = this.displayTextPrefix ? `${this.displayTextPrefix} ` : '';
    const postfix = this.prelimenary ? ' (in Bearbeitung)' : '';

    return `${prefix}${this.displayName}${postfix}`;
  }

  get displayTextPrefix(): string {
    return (this.subType && DISPLAY_SUB_TYPE_MAPPING[this.subType]) || '';
  }

  get link(): string {
    let getLink = this.subType && LINK_MAPPING[this.subType];
    if (!getLink) {
            console.info('Using default link for subType:', this.subType); // eslint-disable-line
      getLink = LINK_MAPPING.default;
    }
    return getLink(this);
  }

  isType(type: string): boolean {
    return this.subType === type;
  }

  // Stuff that is used in Newspaper lists
  get includeFirstLastDay(): boolean {
    return !this.excludesFirstDay && !this.excludesLastDay;
  }

  get includeFirstDayOnly(): boolean {
    return !this.excludesFirstDay && !!this.excludesLastDay;
  }

  get includeLastDayOnly(): boolean {
    return !!this.excludesFirstDay && !this.excludesLastDay;
  }
}
