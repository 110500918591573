// TODO: add flow types

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormButton = ({ children, disabled, dark, big, ...props }) => (
  <button
    type="submit"
    className={classNames({
      button: true,
      disabled,
      dark,
      big,
    })}
    {...props}
  >
    {children}
  </button>
);

FormButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  big: PropTypes.bool,
};

export default FormButton;
